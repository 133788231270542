<template>
  <div class="jobSeekerItem_warp">

    <div class="lastLoginTime">
      {{lastLoginTime}}
    </div>

    <el-row :gutter="0" class="main" :style="statusIsShow?'height: 80%;':'height: 100%;'">

      <el-col :span="3" :style="!newIsFirstCommunication && isShowCommunicatedStatus?'opacity: 0.6;':''">
        <div class="avatar" @click="clickLeft">
          <div class="avatar-warp">
            <el-avatar v-if="!coverWithMosaics" size="large" :src="newAvatarUrl"></el-avatar>
            <div v-if="coverWithMosaics" style="width: 60px; height: 60px;"></div>
            <img v-if="coverWithMosaics && gender === 'female'" class="cover-with-mosaics-img" src="@/assets/icon/cover-with-mosaics-avatar.png">
            <img v-if="coverWithMosaics && gender === 'male'" class="cover-with-mosaics-img" src="@/assets/icon/cover-with-mosaics-avatar2.png">
          </div>
          <div class="gender">
            <img src="@/assets/icon/female.png" v-if="gender === 'female'">
            <img src="@/assets/icon/male.png" v-if="gender === 'male'">
          </div>

        </div>
      </el-col>

      <el-col :span="13" :style="!newIsFirstCommunication && isShowCommunicatedStatus?'opacity: 0.6;':''">
        <div class="information" @click="clickLeft">
          <div class="name">
            <div v-if="!coverWithMosaics" class="name-text">
              {{name}}
            </div>
            <div v-if="coverWithMosaics" class="name-text">
              ****
            </div>
            <span class="span">
              <span>{{age}}岁</span>
              <span>{{academicLevel}}</span>
              <span>{{jobSearchingStatusStr}}</span>
            </span>
          </div>
          <div class="tag">
            <div class="tag-item" v-if="expectedJobAddressList">
              {{expectedJobAddressList}}
            </div>
            <span v-if="expectedJobAddressList && expectedJobList" style="margin-right: 5px;">·</span>
            <div class="tag-item" v-if="expectedJobList">
             {{expectedJobList}}
            </div>
            <span v-if="expectedJobList && expectedSalary" style="margin-right: 5px;">·</span>
            <div class="tag-item">
             {{expectedSalary}}
            </div>

          </div>
          <div class="detailed">
            <template v-if="personalHighLightList.length > 0">
              <div v-if="index < 3" v-for="(item, index) in personalHighLightList" :key="'personalHighLightList' + index">
                {{item}}
              </div>
            </template>
          </div>
          <div class="self-introduction">
            {{selfIntroduction}}
          </div>
        </div>
      </el-col>

      <el-col :span="8" class="btn-list">
        <div v-if="!isRedEnvelopeApplication" class="btn">
          <el-button v-if="newIsFirstCommunication" @click="clickRight">
            打个招呼
          </el-button>
          <el-button v-if="!newIsFirstCommunication" @click="clickRight">
            继续沟通
          </el-button>
        </div>
        <div v-if="isRedEnvelopeApplication" class="btn-wrap">
          <div class="btn-item" v-if="redEnvelopeProcessStatus === 1">
            <div class="redEnvelopeProcessStatus1">
              <el-button size="medium" @click="agreeApply">同 意</el-button>
              <el-button size="medium" @click="rejectApply">拒 绝</el-button>
            </div>
          </div>
          <div class="btn-item" v-if="redEnvelopeProcessStatus === 2">
            <div class="redEnvelopeProcessStatus2">
              已拒绝
            </div>
          </div>
          <div class="btn-item" v-if="redEnvelopeProcessStatus === 3">
            <div class="redEnvelopeProcessStatus3">
              <div class="show">
                <span>距离该红包到账</span>
                <span class="date">{{remainDaysStr}}</span>
              </div>
              <div class="hidden" @click="terminate">
                中断该红包
              </div>
            </div>
          </div>
          <div class="btn-item" v-if="redEnvelopeProcessStatus === 4">
            <div class="redEnvelopeProcessStatus4">
              已中断
            </div>
          </div>
          <div class="btn-item" v-if="redEnvelopeProcessStatus === 5">
            <div class="redEnvelopeProcessStatus5">
              已领完
            </div>
          </div>
          <div class="btn-item" v-if="redEnvelopeProcessStatus === 6">
            <div class="redEnvelopeProcessStatus5">
              冲突中
            </div>
          </div>
        </div>
      </el-col>

    </el-row>

    <el-row :gutter="0" class="status" v-if="statusIsShow">
      <el-col :span="3">
        <div> </div>
      </el-col>
      <el-col :span="21">
        <div class="status-information">
          <i class="el-icon-view"></i>
          <span v-if="currNavPage === '1'">看过您的岗位：</span>
          <span v-if="currNavPage === '2'">用：</span>
          <span v-if="currNavPage === '3'">收藏了您的岗位：</span>
          <span v-if="currNavPage === '7'">和您沟通过：</span>
          <span v-if="currNavPage === '5'">用：</span>
          <span v-if="currNavPage === '6'">申请的岗位：</span>
          <span>{{city}} · {{jobName}}</span>
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import {jobSearchingStatus} from '@/share/shareData'
import {HrGreetings} from "@/share/greetings";
import {HrGetMyCommunicationInfoApi} from "@/request/api";
import share from "@/share/share";
export default {
  name: "jobSeekerItem",
  props: {
    statusIsShow: {
      type: Boolean,
      default: false,
    },
    currNavPage: {
      type: [Number, String],
      default: '',
    },
    avatar: {  // 头像
      type: String,
      default: ''
    },
    name: {  // 名字
      type: String,
      default: ''
    },
    lastLoginTime: {  // 最后活跃时间
      type: String,
      default: ''
    },
    age: {  // 年龄
      type: [String, Number],
      default: ''
    },
    gender: {  // 性别
      type: String,
      default: ''
    },
    personalHighLightList: {  // 个人亮点
      type: Array,
      default: () => []
    },
    academicLevel: {  // 学历
      type: String,
      default: ''
    },
    jobSearchingStatus: {  // 求职状态
      type: [String, Number],
      default: 1
    },
    expectedJobList: {  // 求职期望岗位
      type: String,
      default: ''
    },
    expectedJobAddressList: {  // 期望工作地点
      type: String,
      default: ''
    },
    expectedSalaryRange: {  // 求职期望薪资
      type: Array,
      default: () => []
    },
    selfIntroduction: {  // 个人介绍
      type: String,
      default: ''
    },
    employeeId: {  // 求职者id
      type: [String, Number],
      default: ''
    },
    isRedEnvelopeApplication: {  // 是否是红包岗位
      type: Boolean,
      default: false
    },
    redEnvelopeProcessStatus: {  // 红包岗位状态
      type: [String, Number],
      default: 1,
    },
    recordId: {
      type: [String, Number],
      default: '',
    },
    remainDays: {
      type: [String, Number],
      default: 0,
    },
    jobId: {
      type: [String, Number],
      default: '',
    },
    workExperienceYears: {
      type: [String, Number],
      default: '',
    },
    jobName: {
      type: String,
      default: ''
    },
    city: {
      type: String,
      default: ''
    },
    schoolName: {
      type: String,
      default: ''
    },
    isFirstCommunication: {    // 是否是第一次沟通
      type: Boolean,
      default: true
    },
    searchValue: {    // 搜索内容
      type: String,
      default: ''
    },
    isShowCommunicatedStatus: {    // 是否展示沟通过的灰色状态
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      newIsFirstCommunication: true,  // 是否是第一次沟通
    }
  },
  computed: {
    userType() {
      return this.$store.state.userInfo.userType || ''
    },
    defAvatarUrl() {
      if (this.userType === 'hr') {
        return 'https://cdn.jiaxinzp.com/jxzp/hr_avatar_male.png'
      }else {
        return 'https://cdn.jiaxinzp.com/jxzp/employee_avatar_male.png'
      }
    },
    newAvatarUrl() {
      if (this.avatar) {
        return share.initImageUrl(this.avatar)
      }else {
        return this.defAvatarUrl
      }
    },
    coverWithMosaics() {  // 是否有马赛克
      let state = false
      if (this.searchValue && this.newIsFirstCommunication) {
        state = true
      }
      return state
    },
    jobSearchingStatusStr() {
      let str = ''
      jobSearchingStatus.forEach((item, index) => {
        if (item.value === this.jobSearchingStatus) {
          str = item.label
        }
      })
      return str
    },
    expectedSalary() {
      let str = ''
      if (this.expectedSalaryRange && this.expectedSalaryRange.length > 0) {
        // str = this.expectedSalaryRange[0] + '-' + this.expectedSalaryRange[1]
        str = parseInt(this.expectedSalaryRange[0])/1000 + 'k-' + parseInt(this.expectedSalaryRange[1])/1000 + 'k'
      }else {
        str = '面议'
      }
      return str
    },
    remainDaysStr() {
      let str = ''
      if (this.remainDays === 0) {
        str = '不足一天'
      }else {
        str = '剩余' +  this.remainDays + '天'
      }
      return str
    }
  },
  watch: {
    isFirstCommunication: {
      handler: function () {
        this.newIsFirstCommunication = this.isFirstCommunication
      },
      immediate: true
    },
  },
  mounted() {
  },
  methods: {
    clickLeft() {
      this.$emit('clickLeft', this.employeeId, this.schoolName, this.recordId, this.jobId, this.jobName, this.coverWithMosaics)
    },
    clickRight() {
      let toSessionId = 'employee-' + this.employeeId
      this.$emit('clickRight', toSessionId, this.jobId, this.jobName, this.employeeId, this.newIsFirstCommunication, this.coverWithMosaics, () => {
        this.newIsFirstCommunication = false
      })
    },
    agreeApply() {
      this.$emit('agreeApply', this.employeeId, this.recordId)
    },
    rejectApply() {
      this.$emit('rejectApply', this.employeeId, this.recordId)
    },
    terminate() {
      this.$emit('terminate', this.employeeId, this.recordId)
    }
  }
}
</script>

<style lang="less" scoped>
.jobSeekerItem_warp {
  width: 100%;
  height: 100%;
  color: #2e2e2e;
  position: relative;

  .lastLoginTime {
    position: absolute;
    left: 12px;
    top: 0px;
    font-size: 12px;
    color: #7e7e7e;
  }

  .main {
    width: 100%;
    .el-col {
      height: 100%;
    }
  }

  .status {
    width: 100%;
    height: 20%;
    .el-col {
      height: 100%;
      .status-information {
        border-top: 1px solid #dcdcdc;
        height: 100%;
        color: #969696;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        i {
          margin-right: 5px;
        }
      }
    }
  }

  .avatar {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    .avatar-warp {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .cover-with-mosaics-img {
        width: 100%;
        position: absolute;
      }
    }

    .gender {
      position: absolute;
      right: 10%;
      bottom: 25%;

      img {
        width: 18px;
        height: 18px;
      }
    }

    .el-avatar--large {
      width: 60px;
      height: 60px;
    }

  }

  .information {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
    padding-right: 10px;
    box-sizing: border-box;

    .name {
      font-size: 16px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      margin-bottom: 1px;

      .name-text {
        display: inline-block;
        max-width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
      }

      .span {
        font-size: 14px;
        margin-left: 10px;
        color: #7e7e7e;

        span {
          margin-right: 10px;
        }
      }
    }

    .tag {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      font-weight: bold;
      color: #6e6e6e;
      margin-bottom: 5px;

      .tag-item {
        margin-right: 5px;
      }
    }

    .detailed {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;

      >div {
        padding: 0 8px;
        color: #e20000;
        box-sizing: border-box;
        border: 1px solid #e20000;
        margin-right: 5px;
        border-radius: 8px;
      }

    }

    .self-introduction {
      margin-top: 5px;
      width: 100%;
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      //display:-webkit-box;
      //-webkit-box-orient:vertical;
      //-webkit-line-clamp:2;
      color: #7e7e7e;
    }

    //.self-introduction{position: relative; line-height: 20px; max-height: 40px;overflow: hidden;}
    //.self-introduction::after{content: "..."; position: absolute; bottom: 0; right: 0; padding-left: 40px;
    //  background: -webkit-linear-gradient(left, transparent, #fff 55%);
    //  background: -o-linear-gradient(right, transparent, #fff 55%);
    //  background: -moz-linear-gradient(right, transparent, #fff 55%);
    //  background: linear-gradient(to right, transparent, #fff 55%);
    //}

  }

  .expect {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

     >div {
       height: 80px;
       font-size: 14px;
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: flex-start;
       cursor: pointer;
       box-sizing: border-box;

       &:first-child {
         align-items: flex-end;
       }

       &:last-child {
         align-items: flex-start;
       }
     }

  }

  .btn-list {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .btn {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .el-button {
      color: #e20000;
      border: 1px solid #e20000;
    }

  }

  .btn-wrap {
    height: 100%;
    width: 100%;

    .btn-item {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 20px;
      box-sizing: border-box;

      .redEnvelopeProcessStatus1 {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .el-button {
          color: #333333;
          background-color: #ffffff;

          &:hover {
            color: #ffffff;
            background-color: #e20000;
          }
        }
      }


      .redEnvelopeProcessStatus2 {
        background-color: #d2d2d2;
        padding: 5px 20px;
        box-sizing: border-box;
        color: #676767;
        border: 1px solid #a0a0a0;
      }

      .redEnvelopeProcessStatus3 {
        width: 100px;
        height: 50px;
        box-sizing: border-box;
        color: #676767;
        font-size: 12px;
        background-color: #ffffff;
        border: 1px solid #a0a0a0;

        &:hover {
          .show {
            display: none;
          }

          .hidden {
            display: inline-block;
          }
        }

        .show {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .date {
            color: #e20000;
          }
        }

        .hidden {
          height: 100%;
          width: 100%;
          text-align: center;
          line-height: 50px;
          display: none;
          cursor: pointer;
          color: #e20000;
        }

      }

      .redEnvelopeProcessStatus4, .redEnvelopeProcessStatus5 {
        background-color: #d2d2d2;
        padding: 5px 20px;
        box-sizing: border-box;
        color: #676767;
        border: 1px solid #a0a0a0;
      }

    }
  }

}
</style>
