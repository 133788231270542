<template>
  <div class="resumeDetails_warp">

    <div class="page-header">
      <page-header :curr-page="currHeaderMenu" @changeCurrPage="headerChangeCurrPage"></page-header>
    </div>

    <div class="content">

      <div class="left">
        <router-view :actionIsShow="false" :old-my-resume-data="myResumeData" :new-cover-with-mosaics="coverWithMosaics"></router-view>
      </div>

      <div class="right">

        <div class="actions">
          <div class="top" v-if="!coverWithMosaics">
            <div class="action-item" @click="collectJobSeeker">
              <img v-if="!collectFlag" src="@/assets/icon/collection.png">
              <img v-if="collectFlag" src="@/assets/icon/collection-choose.png">
              收藏
            </div>
            <div class="action-item" @click="reportJobSeeker">
              <img src="@/assets/icon/complaint.png">举报
            </div>
          </div>
          <div class="bottom">
            <el-button v-if="isFirstCommunication" @click="goCommunication">打个招呼</el-button>
            <el-button v-if="!isFirstCommunication" @click="goCommunication">继续沟通</el-button>
          </div>
        </div>

        <div class="summary" v-if="myResumeData.workExperienceList && myResumeData.workExperienceList.length > 0">
          <div class="title">
            简历摘要
          </div>
          <div class="work-experience item" v-for="(item, index) in myResumeData.workExperienceList" :key="'workExperienceList'+index">
            <div class="name">
              {{item.enterpriseName}}
            </div>
            <div class="time">
              {{item.startDate}} 至 {{item.endDate}}
            </div>
            <div class="level">
              {{item.jobName}}
            </div>
          </div>
          <div class="educational-experience item" v-if="myResumeData.educationExperienceList" v-for="(item2, index2) in myResumeData.educationExperienceList" :key="'educationExperienceList'+index2">
            <div class="name">
              {{item2.schoolName}}
            </div>
            <div class="time">
              {{item2.startDate}} 至 {{item2.endDate}}
            </div>
            <div class="level">
              {{academicLevel(item2.academicLevel)}}
            </div>
          </div>
        </div>

      </div>

    </div>

    <el-dialog :title="dialogTitle" custom-class="job-detailed-tip-off-dialog" :visible.sync="detailedTipOffDialogShow" :before-close="jobDetailedTipOffDialogClose" width="500px">

      <div class="tip-off-detailed" v-if="isShowTipOffDetailed">

        <el-form ref="tipOffDetailedFormRef" :model="tipOffDetailedForm" :rules="formRules" label-width="80px">

          <el-form-item label="举报类型" prop="type">
            <el-select v-model="tipOffDetailedForm.type" placeholder="请选择活动区域">
              <el-option :label="item.label" :value="item.value" v-for="(item, index) in reportTypeOptions" :key="'reportTypeOptions' + index"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="举报详情" prop="detailed">
            <el-input type="textarea" v-model="tipOffDetailedForm.detailed"></el-input>
          </el-form-item>

          <el-upload
              name="image"
              :action="uploadAction"
              list-type="picture-card"
              :limit="1"
              :on-success="handleExceedSuccess"
              :before-upload="beforeLegalPersonUpload"
              :on-exceed="handleExceed"
              :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <!--          <el-dialog :visible.sync="tipOffImgVisible">-->
          <!--            <img width="100%" :src="tipOffImgUrl" alt="">-->
          <!--          </el-dialog>-->

          <div class="tip-off-detailed-form-submit-btn">
            <el-button size="mini" @click="jobDetailedTipOffDialogClose">取消</el-button>
            <el-button size="mini" @click="jobDetailedTipOffDialogSubmit">确定</el-button>
          </div>

        </el-form>

      </div>

    </el-dialog>

    <buy-additional-communicate-time ref="buyAdditionalCommunicateTimeRef"
                                     :job-id="jobId"
                                     :employee-id="employeeId"
                                     @success="purchasedSuccessfully"
    ></buy-additional-communicate-time>

    <buy-search-additional-communicate-time ref="buySearchAdditionalCommunicateTimeRef"
                                            :job-id="jobId"
                                            :employee-id="employeeId"
                                            @success="purchasedSuccessfullySearchJob"></buy-search-additional-communicate-time>

  </div>
</template>

<script>
import {
  GetHrEmployeeDetailApi,
  HrAddOrDeleteEmployeeRecordApi, JobHrSeeJobDetailApi, MyPublishedJobApi,
  PostHrCancelEmployeeCollectByEmployeeIdApi,
  PostSubmitReportApi
} from '@/request/api'
import PageHeader from "@/components/pageHeader/pageHeader";
import {education, reportTypeOptions} from '@/share/shareData'
import {BASE_URL} from "@/config/baseURL";
import {HrGreetings} from "@/share/greetings";
import {mapMutations} from "vuex";
import share from "@/share/share";
import BuyAdditionalCommunicateTime from "@/components/buyAdditionalCommunicateTime/buyAdditionalCommunicateTime";
import BuySearchAdditionalCommunicateTime from "@/components/buySearchAdditionalCommunicateTime/buySearchAdditionalCommunicateTime";
export default {
  name: "resumeDetails",
  components: {BuyAdditionalCommunicateTime, BuySearchAdditionalCommunicateTime, PageHeader},
  data() {
    return {
      employeeId: '',
      jobId: '',
      myResumeData: {},
      collectFlag: false,
      currHeaderMenu: 0,

      dialogTitle: '选择举报类型',
      detailedTipOffDialogShow: false,
      isShowTipOffDetailed: true,
      reportTypeOptions: reportTypeOptions,
      uploadAction: BASE_URL + '/user-files/upload-image/',
      formRules: {
        type: [
          {required: true, message: '请选择举报类型'},
        ],
        detailed: [
          {required: true, message: '请输入详情'},
        ]
      },
      tipOffDetailedForm: {
        type: '',
        detailed: '',
        imageUrl: '',
      },
      tipOffImgVisible: false,
      tipOffImgUrl: '',
      academicLevelOptions: education,
      jobName: '',
      schoolName: '',

      hrInfo: {
        hrName: '',
        enterpriseName: '',
        salaryRange: [],
        jobAddressList: '',
      },
      enterpriseInfo: {
        age: '',  // 求职者年龄
        education: '',  // 求职者学历
        expectedJobName: '',  // 求职者期望职位
        expectedPlace: '',  // 求职期望地区
        expectedSalaryRange: '',  // 求职者期望薪资
        jobSeekerName: '',  // 求职者姓名
        schoolName: '',  // 求职者教育经历的学校
        workExperience: '',  // 求职者工作经验
      },

      coverWithMosaics: true,  // 是否打码
      isFirstCommunication: true,  // 是否是第一次沟通

      currentPositionInformationDetails: null,  // 当前hr代表的的岗位信息
    }
  },
  computed: {
    hrCurrentRepresentative() {
      return this.$store.state.hrCurrentRepresentative
    },
  },
  mounted() {
    this.employeeId = this.$route.query.id || ''
    this.jobId = this.$route.query.jobId || ''
    this.jobName = this.$route.query.jobName || ''
    if (this.$route.query.coverWithMosaics === true || this.$route.query.coverWithMosaics === 'true') {
      this.coverWithMosaics = true
    }else {
      this.coverWithMosaics = false
    }
    // this.schoolName = this.$route.query.schoolName || ''
    this.loadData()
    // this.getHrEmployeeDetail()
  },
  methods: {
    ...mapMutations(['setCurrentSessionId']),
    async loadData() {
      const res = await Promise.all([this.loadJobHrSeeJobDetail(), this.getHrEmployeeDetail()])
    },
    async loadJobHrSeeJobDetail() {
      if (this.hrCurrentRepresentative.value !== undefined && this.hrCurrentRepresentative.value !== '') {
        const res = await JobHrSeeJobDetailApi({
          jobId: this.hrCurrentRepresentative.value,
        })
        if (res && res.jobInformation && !res.jobInformation.isClose && res.jobInformation.jobStatus === 3) {
          let salary = ''
          if (res.jobInformation.salaryRange.length > 0) {
            salary = parseInt(res.jobInformation.salaryRange[0])/1000 + 'k-' + parseInt(res.jobInformation.salaryRange[1])/1000 + 'k'
          }else {
            salary = '面议'
          }
          let address = res.jobInformation.jobAddress[1] + res.jobInformation.jobAddress[2]
          let obj = {
            jobName: res.jobInformation.jobName,
            salary: salary,
            address: address,
          }
          this.currentPositionInformationDetails = obj
        }
      }
    },
    async getHrEmployeeDetail() {
      let ignoreRecord = ''
      if (this.coverWithMosaics) {
        ignoreRecord = '1'
      }
      const res = await GetHrEmployeeDetailApi({
        employeeId: this.employeeId,
        jobId: this.jobId,
        ignoreRecord: ignoreRecord,
      })
      if (res) {
        this.myResumeData = res.employeeInformation
        this.isFirstCommunication = !res.communicateFlag
        this.collectFlag = res.collectFlag
      }
    },
    async collectJobSeeker() {
      this.collectFlag = !this.collectFlag
      let action = ''
      let res = ''
      if (this.collectFlag) {
        action = 'add'
        res = await HrAddOrDeleteEmployeeRecordApi({
          action: action,
          employeeId: this.employeeId,
          jobId: this.jobId,
        })
      }else {
        action = 'del'
        res = await PostHrCancelEmployeeCollectByEmployeeIdApi({
          employeeId: this.employeeId,
          jobId: this.jobId,
        })
      }

      if (res) {
        share.message.success('操作成功')
      }
    },
    reportJobSeeker() {
      this.detailedTipOffDialogShow = true
    },
    headerChangeCurrPage(num) {
      this.currHeaderMenu = num
    },
    jobDetailedTipOffDetailed(id) {
      this.dialogTitle = '补充举报证据'
      this.isShowTipOffDetailed = true
    },
    handleRemove(file, fileList) {
      this.tipOffDetailedForm.imageUrl = ''
    },
    handleExceed() {
      share.message.error('只允许上传一张图片')
    },
    beforeLegalPersonUpload(file) {
      const fileType = file.type
      const isLt20M = file.size / 1024 / 1024 < 20;

      let isJPGOrPng = true
      if (fileType !== 'image/jpeg' && fileType !== 'image/png') {
        share.message.error('上传图片只能是 JPG/JPEG 或 PGN 格式!')
        isJPGOrPng = false
      }
      if (!isLt20M) {
        share.message.error('上传图片大小不能超过 20MB!')
      }

      return isJPGOrPng && isLt20M
    },
    handleExceedSuccess(file, fileList) {
      this.tipOffDetailedForm.imageUrl = file.data.path
    },

    handlePictureCardPreview(file) {
      this.tipOffImgUrl = file.url;
      this.tipOffImgVisible = true;
    },
    jobDetailedTipOffDialogClose() {
      this.detailedTipOffDialogShow = false
      // setTimeout(() => {
      //   this.isShowTipOffDetailed = false
      // }, 200)
    },
    jobDetailedTipOffDialogSubmit() {
      this.$refs.tipOffDetailedFormRef.validate(async res => {
        if (res) {

          const res2 = await PostSubmitReportApi({
            subject: this.tipOffDetailedForm.type,
            context: this.tipOffDetailedForm.detailed,
            image: this.tipOffDetailedForm.imageUrl,
          })
          if (res2) {
            share.message.success('举报提交成功')
          }

          this.detailedTipOffDialogShow = false
          // setTimeout(() => {
          // this.isShowTipOffDetailed = false
          // },200)
        }
      })
    },
    academicLevel(val) {
      let str = ''
      this.academicLevelOptions.forEach((item, index) => {
        if (item.value === val) {
          str = item.label
        }
      })
      return str
    },
    goCommunication() {
      if (this.coverWithMosaics) {
        this.$nextTick(() => {
          this.$refs.buySearchAdditionalCommunicateTimeRef.init(true)
        })
      }else {
        this.$nextTick(() => {
          this.$refs.buyAdditionalCommunicateTimeRef.init()
        })
      }
    },
    /**
     * 主动沟通购买成功（搜索）
     * */
    purchasedSuccessfullySearchJob() {
      this.coverWithMosaics = false
      let toSessionId = 'employee-' + this.employeeId
      let obj = {
        jobId: this.jobId,
        jobName: this.jobName,
        currHeaderMenu: 2
      }
      let notTaken = this.isFirstCommunication
      this.isFirstCommunication = false
      share.toChatWindow(toSessionId, obj, '/chatSectionHomeHr', notTaken, this.currentPositionInformationDetails)
    },
    /**
     * 主动沟通购买成功（正常）
     * */
    purchasedSuccessfully() {
      this.coverWithMosaics = false
      let toSessionId = 'employee-' + this.employeeId
      let obj = {
        jobId: this.jobId,
        jobName: this.jobName,
        currHeaderMenu: 2
      }
      let notTaken = this.isFirstCommunication
      this.isFirstCommunication = false
      share.toChatWindow(toSessionId, obj, '/chatSectionHomeHr', notTaken, this.currentPositionInformationDetails)
    },

  }
}
</script>

<style lang="less" scoped>
.resumeDetails_warp {
  width: 100%;
  height: 100%;
  //min-height: 100vh;
  min-width: 1400px;
  background-color: #f4f2f3;
  box-sizing: border-box;

  .page-header {
    width: 100%;
    height: 61px;
    margin-bottom: 40px;
  }

  .content {
    width: 1050px;
    margin: 0px auto;
    display: flex;
    box-sizing: border-box;
    background-color: #eeeeee;

    .right {
      flex: 1;
      padding: 0px 10px;
      box-sizing: border-box;
      background-color: #eeeeee;

      .actions {
        margin: 0 auto;
        font-size: 16px;
        border-bottom: 3px solid #e20000;
        padding: 20px 30px 40px 30px;
        box-sizing: border-box;

        .top {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
          color: #737373;

          .action-item {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            img {
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }

          }
        }

        .bottom {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .el-button {
            width: 100%;
            background-color: #e20000;
            color: #ffffff;
          }
        }
      }

      .summary {
        margin-top: 10px;

        .title {
          color: #5b5b5b;
          font-size: 14px;
          margin-bottom: 10px;
        }

        .item {
          font-size: 14px;
          margin-bottom: 20px;

          .name {
            color: #353535;
          }

          .time {
            color: #838383;
          }

          .level {
            color: #838383;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }

        }

      }

    }

  }

}
</style>

<style lang="less">
.job-seeker-detailed-tip-off-dialog {

  .job-detailed-tip-off-item {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 30px;
    box-sizing: border-box;
    border-bottom: 1px solid #e2e4ec;
    cursor: pointer;

    .job-detailed-tip-off-item-left {

      .title {
        color: #464f65;
      }

      .msg {
        color: #898f9e;
      }

    }

  }

  .tip-off-detailed {
    .tip-off-detailed-form-submit-btn {
      height: 60px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

}
</style>
