<template>
  <div class="hotJobList_warp">
    <div class="postJob-left">

      <div class="header">
        <div class="title">
          普通岗位列表
        </div>
        <div class="actions">
<!--          <el-input placeholder="请输入内容" v-model="searchValue" size="mini">-->
<!--            <el-button slot="append" icon="el-icon-search" @click="searchSubmit"></el-button>-->
<!--          </el-input>-->

          <el-select v-model="filterJobState" placeholder="请选择" size="mini" @change="changeFilterJobState">
            <el-option
                v-for="(item, index) in filterJobStateOptions"
                :key="'filterJobState'+ index"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="content" v-loading="pageLoading">
        <div class="job-item" v-for="(item, index) in jobList" :key="'jobList' + index">
          <warehouse-job-item
              :community-count="item.communityCount"
              :experience-requirement-range="item.experienceRequirementRange"
              :is-close="item.isClose"
              :job-address="item.jobAddress"
              :job-id="item.jobId"
              :job-name="item.jobName"
              :job-status="item.jobStatus"
              :salary-range="item.salaryRange"
              :see-count="item.seeCount"
              :collect-count="item.collectCount"
              :academic-requirement="item.academicRequirement"
              :is-red-envelope="false"
              :expire-date="item.expireDate"
              :editing-under-review="item.editingUnderReview"
              :job-promote-information="item.jobPromoteInfo"
              :hr-vip-remain-job-num="hrVipRemainJobNum"
              :have-valid-duration-package="item.haveValidDurationPackage"
              @goJobDetailed="goJobDetailed"
              @clickAction="(id, jobId2) => clickAction(id, jobId2, item)"
          ></warehouse-job-item>
        </div>

        <div v-if="jobList.length <= 0" class="empty">
          空
        </div>

        <div v-if="jobList && jobList.length > 0" class="pagination">
          <el-pagination
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
        </div>

      </div>

    </div>

    <div class="postJob-right">
      <dynamic-entry @goEnterpriseDynamic="goEnterpriseDynamic"></dynamic-entry>
    </div>

    <!--  升级为红包岗位  -->
    <el-dialog
        title="升级为红包岗位"
        :visible.sync="upgradeDialog"
        width="800px"
        custom-class="upgrade-dialog-wrap"
        :before-close="upgradeDialogClose"
    >
      <div class="pay-dialog-content">

        <div class="form-warp">

          <el-form ref="upgradeDialogFormRef" :rules="formRules" :model="upgradeDialogForm" label-width="80px" key="upgradeDialogForm">

            <el-form-item label="红包金额" prop="redEnvelopeAmount">

              <el-popover
                  width="400"
                  v-model="popoverIsShow"
                  trigger="click"
              >

                <div class="red-envelope-amount-popover-content" v-if="!isModify">
                  <div class="header">
                    <el-steps :space="200" :active="stagActive" finish-status="success" align-center>
                      <el-step title="分期选择"></el-step>
                      <el-step title="分期间隔"></el-step>
                      <el-step title="红包金额"></el-step>
                    </el-steps>
                  </div>
                  <div class="content" v-if="stagActive === 0">
                    <el-radio-group v-model="stageSelection">
                      <el-row :gutter="20">
                        <el-col :span="8">
                          <el-radio-button label="不分期"></el-radio-button>
                        </el-col>
                        <el-col :span="8">
                          <el-radio-button label="分2期"></el-radio-button>
                        </el-col>
                        <el-col :span="8">
                          <el-radio-button label="分3期"></el-radio-button>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="8">
                          <el-radio-button label="分4期"></el-radio-button>
                        </el-col>
                        <el-col :span="8">
                          <el-radio-button label="分5期"></el-radio-button>
                        </el-col>
                        <el-col :span="8">
                          <el-radio-button label="分6期"></el-radio-button>
                        </el-col>
                      </el-row>
                    </el-radio-group>
                  </div>

                  <div class="content" v-if="stagActive === 1">

                    <el-form ref="stagingIntervalDetailsFormRef" key="stagingIntervalDetailsForm2"
                             :model="stagingIntervalDetailsForm" :rules="formRules" label-width="100px">
                      <el-form-item
                          v-for="(item3, index3) in stageSelectionNum"
                          :label="'第'+ (index3 + 1) + '期间隔'"
                          :key="'stagingIntervalDetailsForm' + index3"
                          :prop="'stagingInterval' + (parseInt(index3) + 1)"
                      >
                        <el-input type="number" v-model="stagingIntervalDetailsForm['stagingInterval' + (parseInt(index3) + 1)]">
                          <template slot="suffix">
                            <div
                                style="width: 100%;height: 100%; display: flex; align-items: center; justify-content: center">
                              天
                            </div>
                          </template>
                        </el-input>
                      </el-form-item>
                    </el-form>
                  </div>

                  <div class="content" v-if="stagActive === 2">
                    <el-form ref="installmentDetailsFormRef" key="installmentDetailsForm2"
                             :model="installmentDetailsForm" :rules="formRules" label-width="100px">
                      <el-form-item
                          v-for="(item2, index2) in stageSelectionNum"
                          :label="'第'+ (index2 + 1) + '期金额'"
                          :key="'installmentDetailsForm' + index2"
                          :prop="'amount' + (parseInt(index2) + 1)"
                      >
                        <el-input type="number" v-model="installmentDetailsForm['amount' + (parseInt(index2) + 1)]" :min="100">
                          <template slot="prepend">¥</template>
                          <template slot="suffix">
                            <div
                                style="width: 100%;height: 100%; display: flex; align-items: center; justify-content: center">
                              元
                            </div>
                          </template>
                        </el-input>
                      </el-form-item>
                    </el-form>
                  </div>

                  <div class="btn">
                    <el-button v-if="stagActive > 0" @click="stagActive--">上一步</el-button>
                    <el-button v-if="stagActive < 2" @click="installmentDetailsFormNext">下一步</el-button>
                    <el-button v-if="stagActive === 2" @click="installmentDetailsFormSubmit">完 成
                    </el-button>
                  </div>
                </div>

                <div slot="reference">
                  <el-input :disabled="Boolean(isModify)" v-model="upgradeDialogForm.redEnvelopeAmount" readonly clearable placeholder="请输入">
                    <template slot="prepend">
                          <span class="RMB_class">
                            ¥
                          </span>
                    </template>
                    <template slot="append">
                          <span class="RMB_class">
                            元
                          </span>
                    </template>
                  </el-input>
                </div>
              </el-popover>


            </el-form-item>

            <el-form-item label="需招人数" prop="peopleNumber">
              <el-input :disabled="Boolean(isModify)" type="number" min="5" v-model="upgradeDialogForm.peopleNumber" placeholder="请输入"
                        clearable></el-input>
            </el-form-item>

            <el-form-item label="需付总额">
              <span style="font-size: 20px; color: #e20000;">{{totalRenewal}}</span>
            </el-form-item>

          </el-form>

        </div>

      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="upgradeDialogClose">取 消</el-button>
        <el-button type="primary" :loading="payLoading" @click="upgradeDialogSubmit">确定支付</el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="delJobDialogVisible"
        width="400px">
      <span>确定要删除此岗位？</span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="delJobDialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="delJobDialogBtnLoading" @click="delJobDialogSubmit">确 定</el-button>
      </div>
    </el-dialog>

    <all-package-purchase ref="allPackagePurchaseRef" @successfulPurchase="successfulPurchase"></all-package-purchase>

  </div>
</template>

<script>
import DynamicEntry from "@/components/dynamicEntry/dynamicEntry";
import WarehouseJobItem from "@/components/warehouseJobItem/warehouseJobItem";
import {filterJobStateOptions} from '@/share/shareData'
import {
  HrMyPublishedNormalJobApi,
  OpenJobApi,
  CloseJobApi,
  UpdateRedPocketJobApi, PostJobDeleteJobApi, PostJobPayJobApi
} from '@/request/api'
import {formRules} from "@/share/formRules";
import share from "@/share/share";
import AllPackagePurchase from "@/components/allPackagePurchase/allPackagePurchase";
import {Loading} from "element-ui";
export default {
  name: "hotJobList",
  components: {AllPackagePurchase, WarehouseJobItem, DynamicEntry},
  data() {
    return {
      pageLoading: true,
      // searchValue: '',
      filterJobState: '',
      filterJobStateOptions: filterJobStateOptions,

      jobList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      jobId: '',
      actionLoading: null,
      upgradeDialog: false,
      upgradeDialogForm: {
        peopleNumber: '',  // 需招人数
        redEnvelopeAmount: '',  // 红包金额
        numberOfInstallments: '',  // 红包分期数
        installmentInterval: [],  // 每期间隔
        redPocketPerInstallmentList: [],  // 每期红包
      },
      formRules: {
        ...formRules,

        stagingInterval1: [
          {required: true, validator: this.stagingIntervalRules}
        ],
        stagingInterval2: [
          {required: true, validator: this.stagingIntervalRules}
        ],
        stagingInterval3: [
          {required: true, validator: this.stagingIntervalRules}
        ],
        stagingInterval4: [
          {required: true, validator: this.stagingIntervalRules}
        ],
        stagingInterval5: [
          {required: true, validator: this.stagingIntervalRules}
        ],
        stagingInterval6: [
          {required: true, validator: this.stagingIntervalRules}
        ],

        amount1: [
          {required: true, validator: this.amountRules}
        ],
        amount2: [
          {required: true, validator: this.amountRules}
        ],
        amount3: [
          {required: true, validator: this.amountRules}
        ],
        amount4: [
          {required: true, validator: this.amountRules}
        ],
        amount5: [
          {required: true, validator: this.amountRules}
        ],
        amount6: [
          {required: true, validator: this.amountRules}
        ],
      },
      popoverIsShow: false,
      isModify: false,
      stagActive: 0,
      stageSelection: '不分期',
      stagingIntervalDetailsForm: {
        stagingInterval1: '',
        stagingInterval2: '',
        stagingInterval3: '',
        stagingInterval4: '',
        stagingInterval5: '',
        stagingInterval6: '',
      },
      stageSelectionNum: 1,
      installmentDetailsForm: {
        amount1: '',
        amount2: '',
        amount3: '',
        amount4: '',
        amount5: '',
        amount6: '',
      },

      newJobId: '',

      payLoading: false,

      delJobDialogVisible: false,
      delJobId: '',
      delJobDialogBtnLoading: false,

      hrVipRemainJobNum: 0,  // vip套餐是否还有剩余可在线的岗位次数
    }
  },
  computed: {
    totalRenewal() {
      let allRedPocketPer = 0
      this.upgradeDialogForm.redPocketPerInstallmentList.forEach((item, index) => {
        allRedPocketPer += item
      })
      return this.upgradeDialogForm.peopleNumber * allRedPocketPer
    }
  },
  watch: {
    stageSelection() {
      if (this.stageSelection === '不分期') {
        this.stageSelectionNum = 1
      } else {
        let str = this.stageSelection.slice(1, this.stageSelection.length - 1)
        this.stageSelectionNum = parseInt(str)
      }
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.pageLoading = true
      let includeEditCheck = ''
      if (this.filterJobState === 2) {
        includeEditCheck = 'includeEditCheck'
      }
      const res = await HrMyPublishedNormalJobApi({
        jobStatus: this.filterJobState,
        includeEditCheck: includeEditCheck,
        pageNum: this.currentPage,
        pageSize: this.pageSize
      })
      this.hrVipRemainJobNum = res.hrVipRemainJobNum
      if (res) {
        this.total = res.numOfJobs
        this.jobList = res.jobList
      }
      this.pageLoading = false
    },
    /* 天数验证 */
    stagingIntervalRules(rule, value, callback) {
      let re = /^[1-9]+[0-9]*]*$/
      if (value === '') {
        callback(new Error('请输入天数'))
      } else if (!re.test(value)){
        callback(new Error('天数只能为正整数'))
      } else {
        callback()
      }
    },
    /* 金额验证 */
    amountRules(rule, value, callback) {
      let re = /^[1-9]+[0-9]*]*$/
      if (value === '') {
        callback(new Error('请输入金额'))
      } else if (parseInt(value) < 100) {
        callback(new Error('金额不能小于100元'))
      }else if (!re.test(value)) {
        callback(new Error('金额只能为正整数'))
      } else {
        callback()
      }
    },
    changeFilterJobState() {
      this.currentPage = 1
      this.loadData()
    },
    upgradeDialogClose() {
      this.$refs.upgradeDialogFormRef.resetFields()
      this.upgradeDialog = false
    },
    clickAction(id, jobId, jobInfo) {
      console.log('id', id)
      // 支付 或 续费
      // if (id === 1 || id === 5) {
      //   let param = {
      //     jobType: 0,
      //   }
      //   this.jobId = jobId
      //   this.$nextTick(() => {
      //     this.$refs.allPackagePurchaseRef.checkVipPackageStatus(param)
      //   })
      // }
      if (id === 1) {
        let param = {
          jobType: 0,
        }
        this.jobId = jobId
        this.$nextTick(() => {
          this.$refs.allPackagePurchaseRef.checkVipPackageStatus(param)
        })
      }
      if (id === 5) {
        this.openJob(jobId)
      }
      // 重新审核
      if (id === 6) {
        this.$router.push({
          path: '/postJob',
          query: {
            jobId: jobId,
            isModify: 'yes',
            editType: 'recheck',
            jobType: '0'
          }
        })
      }
      // 关闭
      if (id === 7) {
        this.closeJob(jobId)
      }
      // 开放
      if (id === 8) {
        this.openJob(jobId)
      }
      // 升级为红包岗位
      if (id === 9) {
        this.newJobId = jobId
        this.upgradeDialog = true
      }
      // 编辑
      if (id === 10) {
        this.$router.push({
          path: '/postJob',
          query: {
            jobId: jobId,
            isModify: 'yes',
            editType: 'edit',
            jobType: '0'
          }
        })
      }
      // 删除
      if (id === 11) {
        this.delJobId = jobId
        this.delJobDialogVisible = true
      }
      // 设置推广
      if (id === 12) {
        this.$router.push({
          path: '/newJobPromotion',
          query: {
            isModifyStatus: 'yes',
            jobPromoteId: jobInfo.jobPromoteInfo.id
          }
        })
      }
      // 推广
      if (id === 13) {
        this.$router.push({
          path: '/newJobPromotion',
          query: {
            jobId: jobId,
          }
        })
      }
    },
    async successfulPurchase(obj) {
      this.confirmPublish(obj)
    },
    /**
     * 确认续费
     * */
    async confirmPublish(obj) {
      let loadingInstance = Loading.service({background: 'hsla(0,0%,100%,.9)'});
      let params = {
        jobId: this.jobId
      }
      // 没有会员套餐
      if (obj.state === 0) {
        // 买的时长包
        if (obj.type === 1) {
          params = {
            ...params,
            durationPackageTemplateId: obj.templateId
          }
        }
        // 买的会员
        if (obj.type === 0) {
          params = {
            ...params,
            hrVipTemplateId: obj.templateId
          }
        }
      }
      // 有会员套餐没有剩余次数
      if (obj.state === 2)  {
        params = {
          ...params,
          durationPackageTemplateId: obj.templateId
        }
      }
      const res2 = await PostJobPayJobApi({
        ...params
      })
      if (res2) {
        // 没有会员套餐
        if (obj.state === 0) {
          share.message.success('支付成功已开启')
        }
        // 有会员套餐有剩余次数
        if (obj.state === 1) {
          share.message.success('已开启')
        }
        // 有会员套餐没有剩余次数
        if (obj.state === 2) {
          share.message.success('支付成功已开启')
        }
        loadingInstance.close();
        this.loadData()
      }else {
        loadingInstance.close();
      }
    },
    async delJobDialogSubmit() {
      this.delJobDialogBtnLoading = true
      const res = await PostJobDeleteJobApi({
        jobId: this.delJobId
      })

      if (res) {
        share.message.success('操作成功')
      }
      await this.loadData()
      this.delJobDialogBtnLoading = false
      this.delJobDialogVisible = false
    },
    async upgradeDialogSubmit() {
      this.$refs.upgradeDialogFormRef.validate(async res => {
        if (res) {
          if (this.payLoading) {
            return
          }
          this.payLoading = true
          const res2 = await UpdateRedPocketJobApi({
            jobId: this.newJobId,
            numberOfInstallments: this.upgradeDialogForm.numberOfInstallments,
            installmentInterval: JSON.stringify(this.upgradeDialogForm.installmentInterval),
            redPocketPerInstallment: JSON.stringify(this.upgradeDialogForm.redPocketPerInstallmentList),
            needEmployee: this.upgradeDialogForm.peopleNumber,
          })

          if (res) {
            share.message.success('升级成功')
          }
          await this.loadData()
          this.upgradeDialogClose()
          this.payLoading = false
        }
      })
    },
    async closeJob(jobId) {
      this.actionLoading = this.$message({
        message: '关闭中',
        type: 'warning',
        duration: 0,
        customClass: 'message-class',
        iconClass: 'el-icon-loading',
      })
      const res = await CloseJobApi({
        jobId: jobId
      })
      this.actionLoading.close()

      if (res) {
        share.message.success('关闭成功')
        await this.loadData()
      }
    },
    async openJob(jobId) {
      this.actionLoading = this.$message({
        message: '开启中',
        type: 'warning',
        duration: 0,
        customClass: 'message-class',
        iconClass: 'el-icon-loading',
      })
      const res = await OpenJobApi({
        jobId: jobId
      })
      this.actionLoading.close()

      if (res && res.needBuy === false) {
        share.message.success('开启成功')
        this.loadData()
      }else {
        this.jobId = jobId
        let param = {
          jobType: 0,
        }
        this.$nextTick(() => {
          this.$refs.allPackagePurchaseRef.checkVipPackageStatus(param)
        })
      }
    },
    goJobDetailed(id) {
      this.$router.push({
        path: '/hrSeeJobDetailed',
        query: {
          id: id
        }
      })
    },
    goEnterpriseDynamic(key, index) {
      this.$router.push({
        path: key,
        query: {
          index: index
        }
      })
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.loadData()
    },

    installmentDetailsFormNext() {
      if (this.$refs.stagingIntervalDetailsFormRef) {
        this.$refs.stagingIntervalDetailsFormRef.validate(res => {
          if (res) {
            this.stagActive++
          }
        })
      } else {
        this.stagActive++
      }

    },
    installmentDetailsFormSubmit() {
      this.$refs.installmentDetailsFormRef.validate(res => {
        if (res) {
          this.initEnvelopeAmount()

          this.popoverIsShow = false
          setTimeout(() => {
            this.stagActive = 0
          }, 200)
        }
      })
    },
    initEnvelopeAmount() {

      let stagingIntervalStr = ''
      let stagingIntervalArr = []
      for (let j = 0; j < this.stageSelectionNum; j++) {
        stagingIntervalStr += this.stagingIntervalDetailsForm['stagingInterval' + (j + 1)] + '天'
        stagingIntervalArr.push(this.stagingIntervalDetailsForm['stagingInterval' + (j + 1)])
        if (j < this.stageSelectionNum - 1) {
          stagingIntervalStr += '、'
        }
      }

      let amountStr = ''
      let amountArr = []
      for (let i = 0; i < this.stageSelectionNum; i++) {
        let num = parseInt(this.installmentDetailsForm['amount' + (i + 1)])
        amountStr += num + '元'
        amountArr.push(num)
        if (i < this.stageSelectionNum - 1) {
          amountStr += '、'
        }
      }

      let str2 = `分${this.stageSelectionNum}期/每期间隔分别为${stagingIntervalStr}/每期红包额分别为：` + amountStr

      this.upgradeDialogForm.redEnvelopeAmount = str2
      this.upgradeDialogForm.numberOfInstallments = this.stageSelectionNum
      this.upgradeDialogForm.installmentInterval = stagingIntervalArr
      this.upgradeDialogForm.redPocketPerInstallmentList = amountArr

    },

  }
}
</script>

<style lang="less" scoped>
.hotJobList_warp {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #f4f2f3;

  .postJob-left {
    width: 780px;
    height: 100%;
    background-color: #ffffff;

    .header {
      height: 40px;
      width: 100%;
      font-size: 16px;
      color: #e20000;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      box-sizing: border-box;
      border-bottom: 1px solid #bfbfbf;

      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .el-select {
          margin-left: 20px;
        }
      }
    }

    .content {
      width: 100%;
      padding: 20px 20px;
      box-sizing: border-box;

      .job-item {
        width: 100%;
        height: 70px;
        margin-bottom: 10px;
      }

      .pagination {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .empty {
        width: 100%;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: bold;
        color: #e1e1e1;
      }
    }

  }

  .postJob-right {
    flex: 1;
    //min-height: 600px;
    margin-left: 10px;
    background-color: #f4f2f3;
  }
}
</style>

<style lang="less">
.hot-job-pay-dialog-wrap {

  .el-dialog__body {
    padding: 0 20px;
  }

  .pay-dialog-content {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      width: 500px;
      height: 100%;
      margin-right: 20px;

      .title {
        font-size: 18px;
        color: #e20000;
        height: 30px;
        line-height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .tag {
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          padding: 0px 10px;
          box-sizing: border-box;
          border-radius: 3px;
          background-color: #ffcbbf;
          margin-left: 20px;
        }
      }

      .content {
        border: 1px solid #b1b1b1;
        border-radius: 10px;
        padding: 0 10px 10px 10px;
        box-sizing: border-box;

        .table {

          .el-row {
            height: 100%;
            .el-col {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .header {
            width: 100%;
            height: 30px;
            border-bottom: 1px solid #bfbfbf;
          }

          .row {
            height: 50px;
            color: #343434;

            .row-item-first {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }

            span {
              font-weight: bold;
              color: #343434;
            }
          }
        }

      }

      .desc {
        margin-top: 10px;
        font-size: 12px;
        color: #969696;
      }

    }

    .right {
      flex: 1;
      height: 100%;
      background-color: #eeeeee;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 0 30px 30px 30px;
      box-sizing: border-box;

      >div {
        width: 100%;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #a9a9a9;
        font-size: 12px;
      }

      .payAble {
        margin-top: 15px;
        font-size: 14px;
        color: #5c5c5c;
        align-items: flex-end;
        padding-bottom: 2px;
        box-sizing: border-box;
        margin-bottom: 5px;
        border-bottom: 1px solid #ffffff;

        span {
          font-size: 24px;
          color: #e20000;
          margin-right: 5px;
        }
      }

      .desc {
        margin-top: 10px;
      }

      .order-number {
        color: #5c5c5c;
        margin-top: 10px;
        font-size: 12px;

        .num {
          color: #323232;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }

  }
}
</style>
<style lang="less">
.red-envelope-amount-popover-content {
  width: 100%;

  .header {
    width: 100%;
    height: 70px;
    margin-top: 10px;
  }

  .content {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    max-height: 250px;
    overflow: auto;
    margin-bottom: 10px;

    .el-radio-group {
      width: 100%;
      text-align: center;
    }

    .el-row {
      width: 100%;
      margin-bottom: 10px;
      margin-right: 0px !important;
      margin-left: 0px !important;

      .el-col {
        text-align: center;
      }
    }
  }

  .btn {
    padding: 0 30px;
    box-sizing: border-box;
    text-align: right;
  }
}
</style>
