<template>
  <div class="editEnterpriseInformation_warp">

    <div class="actions">
      <div class="title">
        在线编辑企业信息
      </div>
    </div>

    <div class="content">

      <el-form ref="enterpriseDataFormRef" :rules="formRules" :model="enterpriseData" label-width="100px">

        <div class="form-card">

          <div class="title">
            *上传企业logo
          </div>

          <div class="main">
            <el-form-item>
              <div class="upload-btn" @click="uploadHeadPortrait">
                <img v-if="enterpriseData.logo !== ''"
                     :src="enterpriseData.logo">
                <div v-else>
                  <i class="el-icon-plus"></i>
                </div>
              </div>

              <my-upload field="image"
                         :width="120"
                         :height="120"
                         img-format="jpg"
                         :max-size="1024 * 20"
                         :url="uploadLogoUrl"
                         :headers="headers"
                         :noCircle="false"
                         :modelValue.sync="show"
                         @src-file-set="srcFileSet"
                         @crop-success="cropSuccess"
                         @crop-upload-success="cropUploadSuccess"
                         @crop-upload-fail="cropUploadFail"
              ></my-upload>

            </el-form-item>
          </div>

        </div>

        <div class="form-card">

          <div class="title">
            企业信息
          </div>

          <div class="main">

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="企业名称" prop="enterpriseName">
                  <el-input
                      placeholder="企业全称"
                      v-model.trim="enterpriseData.enterpriseName" clearable
                      :disabled="!isCreate"
                      @blur="checkEnterpriseName"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="法定代表人" prop="legalPerson">
                  <el-input
                      placeholder="法定代表人"
                      v-model.trim="enterpriseData.legalPerson" clearable
                      :disabled="!isCreate"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="企业性质" prop="enterpriseNature">
                  <el-select v-model="enterpriseData.enterpriseNature" :disabled="!isCreate">

                    <el-option
                        v-for="(item, index) in enterpriseNatureOptions"
                        :key="'enterpriseNatureOptions' + index"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>

                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="企业规模" prop="enterpriseSize">
                  <el-select v-model="enterpriseData.enterpriseSize">

                    <el-option
                        v-for="(item2, index2) in enterpriseScaleOptions"
                        :key="'enterpriseScaleOptions' + index2"
                        :label="item2.label"
                        :value="item2.value"
                    >
                    </el-option>

                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="融资状态" prop="financingStatus">
                  <el-select
                      v-model="enterpriseData.financingStatus"
                  >

                    <el-option
                        v-for="(item2, index2) in financingStatusOptions"
                        :key="'financingStatusOptions' + index2"
                        :label="item2.label"
                        :value="item2.value"
                    >
                    </el-option>

                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所属行业" prop="industry">

                  <el-cascader
                      v-model="enterpriseData.industry"
                      :disabled="!isCreate"
                      :options="workDutiesOptions"
                      :show-all-levels="false"
                      popper-class="work_duties_item"
                      :props="{
                      expandTrigger: 'hover',
                      multiple: false
                    }"
                      clearable
                  ></el-cascader>

                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="注册资金" prop="registeredCapital">

                  <el-input
                      v-model.trim="enterpriseData.registeredCapital"
                      :disabled="!isCreate"
                      type="number"
                      clearable
                  >
                    <template slot="prepend">
                      <span class="RMB_class">
                        ¥
                      </span>
                    </template>
                    <template slot="append">
                      <span class="RMB_class">
                        万
                      </span>
                    </template>
                  </el-input>

                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="成立日期" prop="establishmentDate">

<!--                  <el-input-->
<!--                      v-model="enterpriseData.establishmentDate"-->
<!--                      :disabled="!isCreate"-->
<!--                      clearable-->
<!--                  >-->
<!--                  </el-input>-->

                  <el-date-picker
                      value-format="yyyy-MM-dd"
                      :disabled="!isCreate"
                      v-model="enterpriseData.establishmentDate"
                      type="date"
                      clearable
                      :picker-options="pickerOptions"
                      placeholder="选择日期">
                  </el-date-picker>

                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="工作省" prop="province">
                  <el-select v-model="enterpriseData.province" placeholder="请选择" @change="changeProvince" :disabled="!isCreate">
                    <el-option
                        v-for="(item, index) in provinceOptions"
                        :key="'provinceOptions' + index"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="工作市" prop="city">
                  <el-select v-model="enterpriseData.city" placeholder="请选择" @change="changeCity" :disabled="!isCreate">
                    <el-option
                        v-for="(item, index) in cityOptions"
                        :key="'cityOptions' + index"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="工作区" prop="area">
                  <el-select v-model="enterpriseData.area" placeholder="请选择" @change="changeArea" :disabled="!isCreate">
                    <el-option
                        v-for="(item, index) in areaOptions"
                        :key="'areaOptions' + index"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="详细地址" prop="detailAddress">
                  <el-input
                      :disabled="!isCreate"
                      type="text"
                      clearable
                      placeholder="请输入内容"
                      v-model.trim="enterpriseData.detailAddress"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

          </div>

        </div>

        <div class="form-card">

          <div class="title">
            企业介绍
          </div>

          <div class="main">

            <el-row :gutter="20">
              <el-col :span="24">

                <el-form-item>

                  <el-input
                      type="textarea"
                      :rows="4"
                      v-model="enterpriseData.companyProfile"
                  >
                  </el-input>

                </el-form-item>

              </el-col>
            </el-row>

          </div>

        </div>

        <div class="form-card" v-if="isCreate">

          <div class="title">
            * 企业营业执照
          </div>

          <div class="main">

            <el-row :gutter="20">
              <el-col :span="24">

                <el-form-item>
                  <el-upload
                      :action="businessLicenseUrl"
                      list-type="picture-card"
                      name="image"
                      :on-preview="handleLegalPersonPreview"
                      :on-remove="handleLegalPersonRemove"
                      :before-upload="beforeLegalPersonUpload"
                      :limit="1"
                      :on-exceed="handleExceed"
                      :file-list="legalPersonFileList"
                      :on-success="handleLegalPersonUploadSuccess"
                  >
                    <i class="el-icon-picture-outline"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogLegalPersonVisible" custom-class="previewAlbumImgDialog">
                    <img width="100%" :src="dialogLegalPersonUrl" alt="">
                  </el-dialog>
                </el-form-item>

              </el-col>
            </el-row>

          </div>

        </div>

        <div class="form-card">

          <div class="title">
            企业照片和视频
          </div>

          <div class="main">

            <el-row :gutter="20">
              <el-col :span="12">

                <el-form-item>
                  <el-upload
                      :action="uploadImgUrl"
                      list-type="picture-card"
                      name="image"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove"
                      :before-upload="beforeImageUpload"
                      :multiple="false"
                      :limit="9"
                      :on-exceed="handleExceed"
                      :file-list="imageFileList"
                      :on-success="handleImageUploadSuccess"
                  >
                    <i class="el-icon-picture-outline"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogImageVisible" custom-class="previewAlbumImgDialog">
                    <img width="100%" :src="dialogImageUrl" alt="">
                  </el-dialog>
                </el-form-item>

              </el-col>
              <el-col :span="12">
                <el-upload
                    name="video"
                    :on-success="handleVodUploadSuccess"
                    :before-upload="beforeVodUpload"
                    :on-exceed="handleExceed"
                    list-type="picture-card"
                    :show-file-list="true"
                    :file-list="videoFileList"
                    :action="uploadVideoUrl"
                    :limit="1"
                >
                  <div slot="file" slot-scope="{file}">
                    <video class="el-upload-list__item-thumbnail" width="148" height="148" :src="file.url">
                      您的浏览器不支持 video 标签。
                    </video>
                    <span class="el-upload-list__item-actions">
                      <span
                          class="el-upload-list__item-preview"
                          @click="handlePictureCardVodPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                          class="el-upload-list__item-delete"
                          @click="handleVodRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                  <i slot="default" class="el-icon-video-camera"></i>
                </el-upload>

                <el-dialog title="相册" :visible.sync="dialogVideoVisible" custom-class="previewAlbumImgDialog">
                  <video style="max-width: 100%; max-height: 100%;" controls autoplay :src="dialogVideoUrl">
                    您的浏览器不支持 video 标签。
                  </video>
                </el-dialog>
              </el-col>
            </el-row>

          </div>

        </div>

        <div class="form-card">
          <div class="btn">
            <el-button @click="cleanForm">取 消</el-button>
            <el-button @click="submitForm" :loading="submitLoading ||uploadVideoLoading || uploadImageLoading">提 交</el-button>
          </div>
        </div>


      </el-form>

    </div>

  </div>
</template>

<script>
import myUpload from 'vue-image-crop-upload'
import {
  industry,
  enterpriseScale,
  enterpriseNature, areas,
  financingSituation,
  enterpriseIndustry
} from '@/share/shareData'
import {formRules} from '@/share/formRules'
import {BASE_URL} from '@/config/baseURL'
import share from '@/share/share'
import {
  EditInformationApi,
  GetEditInformationApi,
  EnterprisePhotoApi,
  EnterpriseVideoApi,
  EnterpriseLogoApi,
  EnterpriseRegisterApi,
  GetEnterpriseCheckEnterpriseNameApi
} from '@/request/api'

export default {
  name: "editEnterpriseInformation",
  components: {
    myUpload
  },
  data() {
    return {
      uploadImgUrl: BASE_URL + '/user-files/upload-image/',
      uploadVideoUrl: BASE_URL + '/user-files/upload-video/',
      isCreate: true,
      uploadLogoUrl: BASE_URL + '/user-files/upload-image/',
      businessLicenseUrl: BASE_URL + '/user-files/upload-image/',
      headers: {},
      show: false,

      workDutiesOptions: enterpriseIndustry,  // 行业选项列表
      enterpriseScaleOptions: enterpriseScale,  // 企业规模选项列表
      enterpriseNatureOptions: enterpriseNature,  // 企业性质
      financingStatusOptions: financingSituation,  // 融资状态

      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],

      formRules: {
        ...formRules,
        registeredCapital: [
          {required: true, validator: this.registeredCapitalRul}
        ]
      },
      enterpriseData: {
        logo: '',  // 公司logo
        enterpriseName: '',  // 公司名
        legalPerson: '',  // 负责人
        enterpriseNature: '',  // 公司性质
        enterpriseSize: '',  // 公司规模
        industry: '',  // 行业
        registeredCapital: '',  // 注册资本
        establishmentDate: '',  // 成立日期
        province: '',  // 企业所在省
        city: '',  // 企业所在市
        area: '',  // 企业所在区
        detailAddress: '',  // 企业详细地址
        companyProfile: '',  // 企业介绍
        financingStatus: '',  // 融资状态
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      dialogImageUrl: '',
      dialogImageVisible: false,
      dialogVideoUrl: '',
      dialogVideoVisible: false,
      dialogLegalPersonUrl: '',
      dialogLegalPersonVisible: false,


      legalPersonFileList: [],  // 营业执照
      imageFileList: [],//上传文件列表
      videoFileList: [],//上传文件列表
      video: { // 课时对象
        title: '',
        sort: 0,
        free: false,
        videoSourceId: '',
        videoOriginalName: ''
      },
      uploadVideoLoading: false,
      uploadImageLoading: false,
      uploadVideoMessage: null,
      uploadImageMessage: null,
      submitLoading: false,

      editingUnderReview: false,
    }
  },
  created() {
    areas.forEach(item => {
      let obj = {}
      obj.label = item.label
      obj.value = item.value
      obj.children = item.children || []
      this.provinceOptions.push(obj)
    })
  },
  async mounted() {
    let state = this.$route.query.isCreate
    if (state === 'true') {
      this.isCreate = true
    } else {
      this.isCreate = false
    }
    if (!this.isCreate) {
      await this.loadData()
      if (this.editingUnderReview) {
        share.message.warning('当前已有处在审核中的修改申请，不能重复提交')
      }
    }
  },
  methods: {
    async loadData() {
      const res = await GetEditInformationApi()

      if (res) {
        this.enterpriseData.logo = res.enterpriseInformation.logo
        this.enterpriseData.enterpriseName = res.enterpriseInformation.enterprise_name
        this.enterpriseData.enterpriseNature = res.enterpriseInformation.enterpriseProperty
        this.enterpriseData.enterpriseSize = res.enterpriseInformation.enterpriseSizeRange[0] + '-' + res.enterpriseInformation.enterpriseSizeRange[1]
        this.enterpriseData.industry = res.enterpriseInformation.industry
        // this.enterpriseData.industry = '软件开发'
        this.enterpriseData.registeredCapital = res.enterpriseInformation.registeredCapital
        this.enterpriseData.establishmentDate = res.enterpriseInformation.establishDate
        this.enterpriseData.province = res.enterpriseInformation.province
        this.enterpriseData.city = res.enterpriseInformation.city
        this.enterpriseData.area = res.enterpriseInformation.town
        this.enterpriseData.detailAddress = res.enterpriseInformation.detailAddress
        this.enterpriseData.financingStatus = res.enterpriseInformation.financingStatus
        this.enterpriseData.companyProfile = res.enterpriseInformation.enterpriseIntroduction
        this.enterpriseData.legalPerson = res.enterpriseInformation.legalPerson

        this.editingUnderReview = res.enterpriseInformation.editingUnderReview

        let enterprisePhotoList = res.enterpriseInformation.enterprisePhotoList
        let newEnterprisePhotoList = []
        let enterpriseVideoList = res.enterpriseInformation.enterpriseVideoList
        let newEnterpriseVideoList = []

        enterprisePhotoList.forEach((item, index) => {
          let obj = {
            name: item.photoId,
            url: item.photo,
            id: item.photoId,
            response: {
              data: {
                url: item.photo,
              }
            }
          }
          newEnterprisePhotoList.push(obj)
        })

        enterpriseVideoList.forEach((item, index) => {
          let obj = {
            name: item.videoId,
            url: item.video,
            id: item.videoId,
            response: {
              data: {
                url: item.video,
              }
            }
          }
          newEnterpriseVideoList.push(obj)
        })
        this.imageFileList = newEnterprisePhotoList
        this.videoFileList = newEnterpriseVideoList
      }
    },

    changeProvince() {
      this.cityOptions = []
      this.enterpriseData.city = ''
      this.areaOptions = []
      this.enterpriseData.area = ''

      let key = this.enterpriseData.province
      let arr = []
      this.provinceOptions.forEach((item, index) => {
        if (item.value === key) {
          if (item.children && item.children.length > 0) {
            arr = item.children
          }
        }
      })
      let cityOptions = []
      arr.forEach(item => {
        let obj = {}
        obj.label = item.label
        obj.value = item.value
        obj.children = item.children || []
        cityOptions.push(obj)
      })
      this.cityOptions = cityOptions
    },
    changeCity() {
      this.areaOptions = []
      this.enterpriseData.area = ''
      let key = this.enterpriseData.city
      let arr = []
      this.cityOptions.forEach((item, index) => {
        if (item.value === key) {
          if (item.children && item.children.length > 0) {
            arr = item.children
          }
        }
      })
      let areaOptions = []
      arr.forEach(item => {
        let obj = {}
        obj.label = item.label
        obj.value = item.value
        obj.children = item.children || []
        areaOptions.push(obj)
      })
      this.areaOptions = areaOptions
    },
    changeArea() {

    },

    uploadHeadPortrait() {
      this.show = !this.show
    },
    srcFileSet(fileName, fileType, fileSize) {
    },
    cropSuccess(imgDataUrl, field) {
    },
    cropUploadSuccess(jsonData, field) {
      this.enterpriseData.logo = jsonData.data.url
    },
    cropUploadFail(status, field) {
    },

    /* 图片删除时回调 */
    handleRemove(file, fileList) {
      this.imageFileList = fileList

    },
    /* 上传文件超出上限时回调 */
    handleExceed(files, fileList) {
      share.message.warning('上传文件超出上限')
    },
    /* 图片上传前回调 */
    beforeImageUpload(file) {
      const fileType = file.type
      const isLt20M = file.size / 1024 / 1024 < 20;

      let isJPGOrPng = true
      if (fileType !== 'image/jpeg' && fileType !== 'image/png') {
        share.message.error('上传图片只能是 JPG/JPEG 或 PNG 格式!')
        isJPGOrPng = false
      }
      if (!isLt20M) {
        share.message.error('上传图片大小不能超过 20MB!')
      }

      if (isJPGOrPng && isLt20M) {
        this.uploadImageLoading = true

        this.uploadImageMessage = this.$message({
          message: '图片上传中，请不要刷新或关闭页面',
          type: 'warning',
          duration: 0,
          customClass: 'message-class',
          iconClass: 'el-icon-loading',
        })

      }

      return isJPGOrPng && isLt20M
    },
    /* 图片预览时回调 */
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogImageVisible = true;
    },
    /* 视频预览时回调 */
    handlePictureCardVodPreview(file) {
      this.dialogVideoUrl = file.url;
      this.dialogVideoVisible = true;
    },

    /* 图片上传成功回调 */
    handleImageUploadSuccess(response, file, fileList) {
      let newFileList = fileList
      newFileList.forEach((item, index) => {
        if (file.uid === item.uid) {
          this.$set(item, 'id', 0)
        }
      })
      this.imageFileList = newFileList
      this.uploadImageMessage.close()
      this.uploadImageLoading = false

    },

    /* 视频上传成功回调 */
    handleVodUploadSuccess(response, file, fileList) {
      let newFileList = fileList
      newFileList.forEach((item, index) => {
        if (file.uid === item.uid) {
          this.$set(item, 'id', 0)
        }
      })
      this.videoFileList = newFileList
      this.uploadVideoMessage.close()
      this.uploadVideoLoading = false

    },

    /* 视频上传前回调 */
    beforeVodUpload(file) {
      const fileType = file.type
      const isLt200M = file.size / 1024 / 1024 < 200;

      let isMP4OrAVI = true
      if (fileType !== 'video/mp4' && fileType !== 'video/avi') {
        share.message.error('上传视频只能是 MP4 或 AVI 格式!')
        isMP4OrAVI = false
      }
      if (!isLt200M) {
        share.message.error('上传视频大小不能超过 200MB!')
      }
      if (isMP4OrAVI && isLt200M) {
        this.uploadVideoLoading = true

        this.uploadVideoMessage = this.$message({
          message: '视频上传中，请不要刷新或关闭页面',
          type: 'warning',
          duration: 0,
          customClass: 'message-class',
          iconClass: 'el-icon-loading',
        })

      }

      return isMP4OrAVI && isLt200M
    },

    /* 视频删除时回调 */
    handleVodRemove(file) {
      this.videoFileList = []
    },

    /* 取消 */
    cleanForm() {
      this.$router.push('/enterpriseInformation')
    },

    /* 提交 */
    submitForm() {
      if (this.editingUnderReview) {
        share.message.warning('当前已有处在审核中的修改申请，不能重复提交')
        return;
      }
      this.$refs.enterpriseDataFormRef.validate(async (res) => {
        if (res) {

          if (this.enterpriseData.logo === '') {
            share.message.error('请上传企业营logo')
            return
          }

          let enterpriseSizeRange = this.enterpriseData.enterpriseSize.split('-')  // 格式化企业规模

          let logoPath = share.initImageUrlPath(this.enterpriseData.logo)  // 格式化企业logo

          let industry = this.enterpriseData.industry[this.enterpriseData.industry.length - 1]  // 格式化企业行业

          let legalPersonFileList = JSON.parse(JSON.stringify(this.legalPersonFileList))  // 格式化企业营业执照
          let newLegalPersonFileList = []
          legalPersonFileList.forEach((item, index) => {
            let obj = {
              id: item.id,
              path: share.initImageUrlPath(item.response.data.url)
            }
            newLegalPersonFileList.push(obj)
          })

          let imageFileList = JSON.parse(JSON.stringify(this.imageFileList))  // 格式化企业相册图片
          let newImageFileList = []

          imageFileList.forEach((item, index) => {
            let url = share.initImageUrlPath(item.response.data.url)
            let obj = {
              id: item.id,
              path: url
            }
            newImageFileList.push(obj)
          })

          let videoFileList = JSON.parse(JSON.stringify(this.videoFileList))  // 格式化企业相册视频
          let newVideoFileList = []
          videoFileList.forEach((item, index) => {
            let url = share.initImageUrlPath(item.response.data.url)
            let obj = {
              id: item.id,
              path: url
            }
            newVideoFileList.push(obj)
          })

          let financingStatus = this.enterpriseData.financingStatus
          this.financingStatusOptions.forEach((item, index) => {
            if (item.label === financingStatus) {
              financingStatus = item.value
            }
          })

          if (this.isCreate) {
            if (this.legalPersonFileList.length <= 0) {
              share.message.error('请上传企业营业执照')
              return
            }
            this.submitLoading = true
            const res6 = await EnterpriseRegisterApi({
              logo: logoPath,
              enterpriseName: this.enterpriseData.enterpriseName,
              enterpriseProperty: this.enterpriseData.enterpriseNature,
              financingStatus: financingStatus,
              enterpriseSize: JSON.stringify(enterpriseSizeRange),
              industry: industry,
              registeredCapital: this.enterpriseData.registeredCapital,
              legalPerson: this.enterpriseData.legalPerson,
              businessLicense: JSON.stringify(newLegalPersonFileList),
              establishDate: this.enterpriseData.establishmentDate,
              enterpriseIntroduction: this.enterpriseData.companyProfile,
              province: this.enterpriseData.province,
              city: this.enterpriseData.city,
              town: this.enterpriseData.area,
              detailAddress: this.enterpriseData.detailAddress,
            })
            if (res6) {
              const res7 = await EnterprisePhotoApi({
                photoInfoList: JSON.stringify(newImageFileList)
              })

              const res8 = await EnterpriseVideoApi({
                videoInfoList: JSON.stringify(newVideoFileList)
              })
              share.message.success('创建成功，已提交审核')
              await this.$router.push('/chatSectionHomeHr')
            }else {
              this.submitLoading = false
            }

          }else {
            this.submitLoading = true
            const res2 = await EditInformationApi({
              enterpriseSizeRange: JSON.stringify(enterpriseSizeRange),
              enterpriseIntroduction: this.enterpriseData.companyProfile,
              financingStatus: financingStatus,
              photoInfoList: JSON.stringify(newImageFileList),
              videoInfoList: JSON.stringify(newVideoFileList),
              logoPath: logoPath,
            })

            if (res2) {
              share.message.success('修改申请提交成功')
              await this.$router.push('/enterpriseInformation')
            }else {
              this.submitLoading = false
            }

          }

        }
      })
    },

    /* 营业执照预览 */
    handleLegalPersonPreview(file) {
      this.dialogLegalPersonUrl = file.url
      this.dialogLegalPersonVisible = true
    },

    /* 营业执照删除 */
    handleLegalPersonRemove(file, fileList) {
      this.legalPersonFileList = fileList
    },

    /* 营业执照上传前 */
    beforeLegalPersonUpload(file) {
      const fileType = file.type
      const isLt10M = file.size / 1024 / 1024 < 10;

      let isJPGOrPng = true
      if (fileType !== 'image/jpeg' && fileType !== 'image/png') {
        share.message.error('上传图片只能是 JPG/JPEG 或 PGN 格式!')
        isJPGOrPng = false
      }
      if (!isLt10M) {
        share.message.error('上传图片大小不能超过 10MB!')
      }

      if (isJPGOrPng && isLt10M) {
        this.uploadImageLoading = true

        this.uploadImageMessage = this.$message({
          message: '图片上传中，请不要刷新或关闭页面',
          type: 'warning',
          duration: 0,
          customClass: 'message-class',
          iconClass: 'el-icon-loading',
        })

      }

      return isJPGOrPng && isLt10M
    },

    /* 营业执照上传成功 */
    handleLegalPersonUploadSuccess(response, file, fileList) {
      let newFileList = fileList
      newFileList.forEach((item, index) => {
        if (file.uid === item.uid) {
          this.$set(item, 'id', 0)
        }
      })
      this.legalPersonFileList = newFileList
      this.uploadImageMessage.close()
      this.uploadImageLoading = false

    },
    registeredCapitalRul(rule, value, callback) {
      let re = /^[1-9]+[0-9]*]*$/
      if (value === '') {
        callback(new Error('请输入注册资金'))
      } else if (!re.test(value)) {
        callback(new Error('注册资金只能为正整数'))
      } else {
        callback()
      }
    },
    /**
    * 检查企业名是否重复
    * */
    async checkEnterpriseName() {
      if (this.isCreate && this.enterpriseData.enterpriseName !== '') {
        const res = await GetEnterpriseCheckEnterpriseNameApi({
          enterpriseName: this.enterpriseData.enterpriseName
        })
        if (res && res.isExists) {
          share.message.warning('企业名称已存在，不可重复创建')
        }
      }

    },
  }
}
</script>

<style lang="less" scoped>
.editEnterpriseInformation_warp {
  width: 100%;
  //height: 100%;
  background-color: #ffffff;
  padding-bottom: 40px;
  box-sizing: border-box;

  .actions {
    width: 1000px;
    height: 40px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #c9c7c8;

    .title {
      color: #707070;
      font-weight: bold;
      padding-left: 15px;
      font-size: 16px;
    }

    .el-button {
      color: #707070;
      font-size: 18px;

      & /deep/ span {
        width: 100%;
        height: 25px;
        line-height: 25px;

        img {
          width: 25px;
          height: 25px;
          position: relative;
          top: 5px;
          margin-right: 5px;
        }
      }

    }
  }

  .content {
    width: 1000px;
    margin: 0 auto;

    .el-form {

      .form-card {
        border-bottom: 1px solid #c9c7c8;
        padding: 20px 0;
        box-sizing: border-box;

        &:hover {
          .title {
            .form-card-actions {
              visibility: visible;
            }
          }
        }

        .title {
          color: #e20000;
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .form-card-actions {
            visibility: hidden;
          }

        }

        .main {

          & /deep/ .el-form-item {

            .upload-btn {
              width: 120px;
              height: 120px;
              border: 1px dashed #d9d9d9;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              &:hover {
                border-color: #409EFF;

                > div {
                  color: #409EFF;
                }
              }

              > img {
                width: 100%;
                height: 100%;
              }

              > div {
                font-size: 30px;
                color: #8c939d;
              }

            }

            .el-form-item__label {
              color: #3f3f3f;
            }

          }

        }
        .btn {
          width: 100%;
          text-align: right;
        }

        &:last-child {
          border-bottom: 0px;
        }

      }

    }

  }
}
</style>
<style lang="less">
.work_duties_item {
  .el-cascader-menu {
    height: 200px !important;
  }
}

.job-intention-place {
  .el-cascader-menu {
    height: 400px !important;
  }
}

.message-class {
  border: 1px solid #faecd8;
  color: #e6a23c;
  background-color: #fdf6ec;
}
</style>
