<template>
  <div class="personalInformationJobItem_warp">

    <div class="red-envelopes-icon" v-if="jobType === 1">
      <red-envelopes :number="redPocketPerPerson"></red-envelopes>
    </div>

    <div class="left" @click="clickLeft">

      <div class="top">
        <div class="title">
          {{jobName}}
        </div>
        <div class="place">
<!--          [{{jobAddress[0]}} · {{jobAddress[1]}} · {{jobAddress[2]}}]-->
          [{{jobAddress[2]}}]
        </div>
      </div>

      <div class="center">
        <div class="salary">
          {{salary}}
        </div>
        <div class="education">
          {{academicRequirement}}
        </div>
        <div class="experience">
          {{oldExperienceRequirementRange}}
        </div>
        <div class="contacts" @click.stop="clickCenter">
          <div class="icon">
            <img src="@/assets/icon/contact.png">
          </div>
          <div class="name">
            {{hrName}}
          </div>
          <div class="position" v-if="onlineStatus">
            <span style="color: #67C23A;" v-if="onlineStatus === '在线'">
              <span style="display: inline-block; width: 5px; height: 5px; border-radius: 50%; background-color: #67C23A; margin-right: 4px; margin-bottom: 2px;"></span>{{onlineStatus}}
            </span>
            <span v-if="onlineStatus !== '在线'">{{onlineStatus}}</span>
          </div>
        </div>
      </div>

      <div class="bottom">
        <template v-if="jobWelfare && jobWelfare.length > 0">
          <span v-if="index < 5" v-for="(item, index) in jobWelfare" :key="'jobWelfare' + index">
            {{item}}<span v-if="index < jobWelfare.length-1 && index < 4">，</span>
          </span>
        </template>
      </div>

    </div>

    <div class="right" @click="clickRight">

      <div class="companyInformation">
        <div class="title">
          {{enterpriseName}}
        </div>
        <div class="information">
          <div class="industry">{{industry}}</div>
          <div class="listed">{{financingStatus}}</div>
          <div class="scale" v-if="enterpriseSizeRange">{{newEnterpriseSizeRange}}</div>
        </div>
      </div>

      <div class="icon">
        <el-button @click.stop="clickCenter">立即沟通</el-button>
      </div>

    </div>

  </div>
</template>

<script>
import RedEnvelopes from "@/components/redEnvelopes/redEnvelopes";
import {JobSeekerGreetings} from "@/share/greetings";
import {GetMyCommunicationInfoApi} from "@/request/api";
import {experience, enterpriseScale} from "@/share/shareData";
export default {
  name: "personalInformationJobItem",
  components: {RedEnvelopes},
  props: {
    jobId: {
      type: [String, Number],
      default: ''
    },
    enterpriseId: {
      type: [String, Number],
      default: ''
    },
    jobType: {
      type: [String, Number],
      default: 0
    },
    redPocketPerPerson: {
      type: [Number, String],
      default: 0
    },
    academicRequirement: {
      type: String,
      default: '',
    },
    jobName: {
      type: String,
      default: '',
    },
    salaryRange: {
      type: Array,
      default: () => [],
    },
    jobWelfare: {
      type: Array,
      default: () => [],
    },
    jobAddress: {
      type: Array,
      default: () => [],
    },
    experienceRequirementRange: {
      type: Array,
      default: () => [],
    },
    hrName: {
      type: String,
      default: '',
    },
    enterpriseName: {
      type: String,
      default: '',
    },
    industry: {
      type: String,
      default: ''
    },
    financingStatus: {
      type: String,
      default: ''
    },
    enterpriseSizeRange: {
      type: Array,
      default: () => [],
    },
    hrId: {
      type: [String, Number],
      default: ''
    },
    onlineStatus: {
      type: String,
      default: ''
    },
  },
  computed: {
    oldExperienceRequirementRange() {
      let str = ''

      if (this.experienceRequirementRange.length > 0) {
        let str2 = this.experienceRequirementRange[0] + '-' + this.experienceRequirementRange[1]

        experience.forEach((item, index) => {
          if (item.value === str2) {
            str = item.label + '经验'
          }
        })

      }else {
        str = '经验不限'
      }
      return str
    },
    salary() {
      let str = ''
      if (this.salaryRange && this.salaryRange.length > 0) {
        // str = this.salaryRange[0] + '-' + this.salaryRange[1]
        str = parseInt(this.salaryRange[0])/1000 + 'k-' + parseInt(this.salaryRange[1])/1000 + 'k'
      }else {
        str = '面议'
      }
      return str
    },
    isLogin() {
      let token = this.$store.state.token
      let state = false
      if (token && token !== '' && token !== null && token !== 'null') {
        state = true
      }
      return state
    },
    newEnterpriseSizeRange() {
      let str = this.enterpriseSizeRange[0] + '-' + this.enterpriseSizeRange[1]

      let label = ''
      enterpriseScale.forEach((item, index) => {
        if (item.value === str) {
          label = item.label
        }
      })

      return label
    }
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    clickLeft() {
      this.$emit('clickLeft', this.jobId)
    },
    clickRight() {
      this.$emit('clickRight', this.enterpriseId)
    },
    clickCenter() {

      let toSessionId = 'hr-' + this.hrId

      this.$emit('clickCenter', toSessionId)
    },

  }
}
</script>

<style lang="less" scoped>
.personalInformationJobItem_warp {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;

  .red-envelopes-icon {
    width: 60px;
    height: 60px;
    position: absolute;
    right: 5px;
    top: 20px;
    z-index: 998;
    transform:rotate(-25deg);
    cursor: pointer;
  }

  .left {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    padding: 20px 0 20px 20px;
    box-sizing: border-box;

    >div {
      display: flex;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .top {
      display: flex;
      align-items: center;
      .title {
        font-size: 16px;
        color: #0b0b0b;
        margin-right: 30px;
      }
      .place {
        font-size: 16px;
        color: #7f7f7f;
      }
    }

    .center {
      font-size: 14px;
      display: flex;
      align-items: center;
      .salary {
        color: #e72727;
        line-height: 100%;
        margin-right: 10px;
      }

      .education {
        color: #7f7f7f;
        margin-right: 10px;
      }

      .experience {
        color: #7f7f7f;
        margin-right: 10px;
      }

      .contacts {
        display: flex;
        align-items: center;
        padding: 3px 6px;
        box-sizing: border-box;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        font-size: 12px;
        color: #7f7f7f;
        cursor: pointer;

        .icon {
          height: 15px;
          width: 15px;
          margin-right: 5px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .name {
          height: 12px;
          line-height: 12px;
          border-right: 1px solid #e5e5e5;
          padding-right: 5px;
          max-width: 100px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }

        .position {
          height: 12px;
          line-height: 12px;
          padding-left: 5px;
        }

      }
    }

    .bottom {
      font-size: 10px;
      color: #7f7f7f;
    }

  }

  .right {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 20px 20px 20px 0;
    box-sizing: border-box;

    .icon {
      height: 80px;
      width: 120px;
      margin-left: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      & /deep/ .el-button {
        border: 1px solid #e20000;
        color: #e20000;
      }

    }

    .companyInformation {
      height: 100%;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 18px;
        color: #0b0b0b;
        width: 270px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }

      .information {
        display: flex;
        color: #7f7f7f;
        font-size: 12px;
        height: 20px;
        align-items: center;
        justify-content: flex-start;

        >div {
          height: 12px;
          display: flex;
          align-items: center;
          border-right: 1px solid #7f7f7f;
          padding: 0px 5px;
          box-sizing: border-box;

          &:first-child {
            padding-left: 0px;
          }

          &:last-child {
            border-right: 0px;
          }
        }
      }

    }

  }

}
</style>
