<template>
  <div class="companyPublishedJob_warp">
    <div class="filter">
      <div class="title">{{ title }}</div>
      <el-cascader
          size="mini"
          popper-class="login-page-header-left-choose-area"
          expand-trigger="hover"
          placeholder="地区"
          :options="areasOptions"
          :props="{ checkStrictly: true, value: 'value' }"
          v-model="jobAddress"
          @change="jobAddressChange"
          clearable
      >
      </el-cascader>
    </div>

    <div class="content">

      <div class="left">

        <div class="main" v-loading="jobListLoading">

          <div class="item" v-for="(item, index) in jobList" :key="'company-published-job' + index">
            <company-published-job-item
                :enterprise-name="item.enterpriseName"
                :hr-name="item.hrName"
                :amount="item.redPocketPerPerson"
                :is-red-envelopes-job="item.jobType"
                :salary-range="item.salaryRange"
                :job-address="item.jobAddress"
                :experience-requirement-range="item.experienceRequirementRange"
                :academic-requirement="item.academicRequirement"
                :job-name="item.jobName"
                :publish-time="item.publishTime"
                :job-id="item.jobId"
                :hr-id="item.hrId"
                @goJobDetailed="goJobDetailed"
                @toCommunicate="(toSessionId) =>  toCommunicate(toSessionId, item.jobId, item.jobName)"
            ></company-published-job-item>
          </div>


          <div class="empty" v-if="!jobList || jobList.length <= 0">
            空
          </div>

        </div>

        <div class="paging">
          <el-pagination v-if="currPage === '2'"
                         background
                         layout="prev, pager, next"
                         :page-size="pageSize"
                         :current-page="currentPage"
                         :total="total"
                         @current-change="currentChange"
          >
          </el-pagination>
          <el-pagination v-if="currPage === '3'"
                         background
                         layout="prev, pager, next"
                         :page-size="pageSize2"
                         :current-page="currentPage2"
                         :total="total2"
                         @current-change="currentChange"
          >
          </el-pagination>
          <el-pagination v-if="currPage === '4'"
                         background
                         layout="prev, pager, next"
                         :page-size="pageSize3"
                         :current-page="currentPage3"
                         @current-change="currentChange"
                         :total="total3"
          >
          </el-pagination>
        </div>

      </div>

      <div class="right">

        <div class="card">
          <el-button @click="goMyResume">在线编辑简历</el-button>
        </div>

        <div class="card">

          <div class="title" @click="goDynamic(2)">
            我看过的岗位<i class="el-icon-arrow-right"></i>
          </div>

          <div class="main">
            <div class="item" v-for="(item, index) in historyJobList" :key="'item' + index">
              <job-card-item
                  :job-name="item.jobName"
                  :job-address="item.jobAddressList"
                  :enterprise-name="item.enterpriseName"
                  :salary-range="item.salaryRange"
                  :job-id="item.job_id"
                  :is-close="item.status !== 1"
                  @goJobDetailed="goJobDetailed"
              ></job-card-item>
            </div>
          </div>

        </div>

      </div>

    </div>

    <el-dialog
        title="提示"
        :visible.sync="toMyResumeDialogVisible"
        width="350px">
      <div style="text-align: center">
        <p style="font-size: 20px; font-weight: bold; color: #3a3a3a;">请先去完善简历</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="toMyResumeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toMyResume">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import areas from "@/share/areas.json";
import CompanyPublishedJobItem from "@/components/jobItem/companyPublishedJobItem";
import JobCardItem from "@/components/jobCard/jobCardItem";
import {GetAllJobListApi, GetNormalJobListApi, GetRedPocketJobListApi, PostEmployeeContactHrApi} from '@/request/api'
import {mapMutations} from "vuex";
import share from "@/share/share";

export default {
  name: "companyPublishedJob",
  components: {JobCardItem, CompanyPublishedJobItem},
  props: {
    title: {
      type: String,
      default: ''
    },
    historyJobList: {
      type: Array,
      default: () => []
    },
    companyId: {
      type: [Number, String],
      default: ''
    },
    currPage: {
      type: String,
      default: ''
    }

  },
  data() {
    return {
      areasOptions: areas,
      jobAddress: '',
      jobList: [],

      total: 0,
      pageSize: 5,
      currentPage: 1,

      total2: 0,
      pageSize2: 5,
      currentPage2: 1,

      total3: 0,
      pageSize3: 5,
      currentPage3: 1,
      jobListLoading: true,
      toMyResumeDialogVisible: false,
    }
  },
  watch: {
    companyId() {
      this.loadData()
    },
    currPage() {
      this.loadData()
    }
  },
  computed: {
    isEditedResume() {
      return this.$store.state.isEditedResume || ''
    },
    isLogin() {
      let token = this.$store.state.token
      let state = false
      if (token && token !== '' && token !== null && token !== 'null') {
        state = true
      }
      return state
    },
  },
  mounted() {
    if (this.companyId) {
      this.loadData()
    }
  },
  methods: {
    ...mapMutations([]),
    async loadData() {
      this.jobListLoading = true
      let res = {}
      if (this.currPage === '2') {
        res = await GetAllJobListApi({
          enterpriseId: this.companyId,
          jobAddress: this.jobAddress[this.jobAddress.length - 1] || '',
          pageNum: this.currentPage,
          pageSize: this.pageSize
        })

        if (res) {
          this.jobList = res.jobList
          this.total = res.numOfJobs
        }
      } else if (this.currPage === '3') {

        res = await GetRedPocketJobListApi({
          enterpriseId: this.companyId,
          jobAddress: this.jobAddress[this.jobAddress.length - 1] || '',
          pageNum: this.currentPage3,
          pageSize: this.pageSize3
        })

        if (res) {
          this.jobList = res.jobList
          this.total3 = res.numOfJobs
        }

      } else {

        res = await GetNormalJobListApi({
          enterpriseId: this.companyId,
          jobAddress: this.jobAddress[this.jobAddress.length - 1] || '',
          pageNum: this.currentPage2,
          pageSize: this.pageSize2
        })

        if (res) {
          this.jobList = res.jobList
          this.total2 = res.numOfJobs
        }

      }
      this.jobListLoading = false

    },
    jobAddressChange() {
      this.loadData()
    },
    currentChange(num) {
      if (this.currPage === '2') {
        this.currentPage = num
      } else if (this.currPage === '3') {
        this.currentPage2 = num
      } else {
        this.currentPage3 = num
      }
      this.loadData()
    },
    goJobDetailed(id) {
      this.$router.push({
        path: '/jobDetailed',
        query: {
          id: id
        }
      })
    },
    toMyResume() {
      this.$router.push('/myResume')
    },
    toCommunicate(toSessionId, jobId, jobName) {

      if (!this.isLogin) {
        share.message.success('请先去登录')
        return;
      }

      if (!this.isEditedResume) {
        this.toMyResumeDialogVisible = true
        return
      }

      PostEmployeeContactHrApi({
        jobId: jobId
      })

      share.toChatWindow(toSessionId, {jobId: jobId, jobName: jobName}, '/chatSectionHome')

    },
    goMyResume() {
      this.$router.push({
        path: '/myResume',
      })
    },
    goDynamic(index) {
      this.$router.push({
        path: '/personalDynamic',
        query: {
          index: index
        }
      })
    },
  }


}
</script>

<style lang="less" scoped>
.companyPublishedJob_warp {
  width: 100%;
  height: 100%;

  .filter {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .title {
      font-size: 16px;
      font-weight: bold;
      border-left: 2px solid #e20000;
      height: 18px;
      line-height: 18px;
      padding-left: 2px;
      margin-right: 20px;
    }

  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;

    .left {
      width: 800px;
      height: 100%;

      .main {
        width: 100%;
        background-color: #ffffff;
        box-shadow: 0 0 15px #e4e2e3;
        padding: 20px 20px;
        box-sizing: border-box;

        .item {
          width: 100%;
          height: 130px;
          background-color: #ffffff;
          padding: 20px 20px;
          box-sizing: border-box;
          border-bottom: 1px solid #d3d3d3;
        }

        .empty {
          width: 100%;
          height: 400px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          font-weight: bold;
          color: #e1e1e1;
        }

      }

      .paging {
        width: 100%;
        text-align: center;
        margin-top: 20px;
      }

    }

    .right {
      width: 380px;
      height: 100%;


      .card {
        background-color: #ffffff;
        margin-bottom: 20px;

        &:first-child {
          height: 100px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          & /deep/ .el-button {
            color: #e20000;
            border: 1px solid #e20000;
          }

        }

        &:last-child {
          padding: 10px 20px;
          box-sizing: border-box;

          .title {
            font-size: 16px;
            font-weight: bold;
            border-bottom: 1px solid #e7e7e7;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 30px;
            line-height: 30px;
            cursor: pointer;
          }

          .main {
            .item {
              margin: 20px 0;
            }
          }

        }

      }

    }

  }

}
</style>
