<template>
  <div class="jobHunting_warp">

    <div class="red-envelopes-job-list" id="hunting-red-envelopes-job" v-loading="pageLoading">
      <div class="title">
        <span class="text" @click="moreJob(2)">红包岗位</span>
        <span class="desc" @click="moreJob(2)">查看更多</span>
      </div>
      <div class="red-envelopes-job-item" v-for="(item, index) in redEnvelopesJobList"
           :key="'red-envelopes-job-item' + index"
      >
        <job-item @clickLeft="openJobDetailed(item.jobInformation.jobId)"
                  @clickRight="openCompanyDetailed(item.enterpriseInformation.enterpriseId)"
                  @clickCenter="(toSessionId) => openContacts(toSessionId, item.jobInformation.jobId, item.jobInformation.jobName)"
                  :job-id="item.jobInformation.jobId"
                  :hr-id="item.hrInformation.hrId"
                  :job-type="item.jobInformation.jobType"
                  :red-pocket-per-person="item.jobInformation.redPocketPerPerson"
                  :job-name="item.jobInformation.jobName"
                  :job-address="item.jobInformation.jobAddress"
                  :salary-range="item.jobInformation.salaryRange"
                  :academic-requirement="item.jobInformation.academicRequirement"
                  :experience-requirement-range="item.jobInformation.experienceRequirementRange"
                  :job-welfare="item.jobInformation.jobWelfare"
                  :hr-name="item.enterpriseInformation.hrName"
                  :enterprise-name="item.enterpriseInformation.enterpriseName"
                  :industry="item.enterpriseInformation.industry"
                  :enterprise-size-range="item.enterpriseInformation.enterpriseSizeRange"
                  :financing-status="item.enterpriseInformation.financingStatus"
                  :enterprise-logo="item.enterpriseInformation.logo"
                  :online-status="item.hrInformation.onlineStatus"
        ></job-item>
      </div>

      <div v-if="redEnvelopesJobList.length <= 0" class="empty">
        空
      </div>

      <div v-if="redEnvelopesJobList.length > 0" class="more-btn" @click="moreJob(2)">更多</div>

    </div>

    <div class="hot-job-list" id="hunting-hot-job-job" v-loading="pageLoading2">
      <div class="title">
        <span class="text" @click="moreJob(1)">普通岗位</span>
        <span class="desc" @click="moreJob(1)">查看更多</span>
      </div>
      <div class="hot-job-item" v-for="(item2, index2) in hotJobList"
           :key="'hot-job-item' + index2"
      >
        <job-item @clickLeft="openJobDetailed(item2.jobInformation.jobId)"
                  @clickRight="openCompanyDetailed(item2.enterpriseInformation.enterpriseId)"
                  @clickCenter="(toSessionId) => openContacts(toSessionId, item2.jobInformation.jobId, item2.jobInformation.jobName)"
                  :job-id="item2.jobInformation.jobId"
                  :hr-id="item2.hrInformation.hrId"
                  :job-type="item2.jobInformation.jobType"
                  :red-pocket-per-person="item2.jobInformation.redPocketPerPerson"
                  :job-name="item2.jobInformation.jobName"
                  :job-address="item2.jobInformation.jobAddress"
                  :salary-range="item2.jobInformation.salaryRange"
                  :academic-requirement="item2.jobInformation.academicRequirement"
                  :experience-requirement-range="item2.jobInformation.experienceRequirementRange"
                  :job-welfare="item2.jobInformation.jobWelfare"
                  :hr-name="item2.enterpriseInformation.hrName"
                  :enterprise-name="item2.enterpriseInformation.enterpriseName"
                  :industry="item2.enterpriseInformation.industry"
                  :enterprise-size-range="item2.enterpriseInformation.enterpriseSizeRange"
                  :financing-status="item2.enterpriseInformation.financingStatus"
                  :enterprise-logo="item2.enterpriseInformation.logo"
                  :online-status="item2.hrInformation.onlineStatus"
        ></job-item>
      </div>

      <div v-if="hotJobList.length <= 0" class="empty">
        空
      </div>

      <div v-if="redEnvelopesJobList.length > 0" class="more-btn" @click="moreJob(1)">更多</div>

    </div>

    <el-dialog
        title="提示"
        :visible.sync="toMyResumeDialogVisible"
        width="350px">
      <div style="text-align: center">
        <p style="font-size: 20px; font-weight: bold; color: #3a3a3a;">请先去完善简历</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="toMyResumeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toMyResume">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import JobItem from "@/components/jobItem/jobItem";
import share from '@/share/share'
import {isEditedResumeApi, PostEmployeeContactHrApi, SearchJobApi} from "@/request/api";
import {mapMutations} from "vuex";

export default {
  name: "jobHunting",
  components: {JobItem},
  props: {
    jobFilter: {
      type: [Array, Object],
      default: () => []
    },
    searchValue: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      pageLoading: true,
      pageLoading2: true,
      redEnvelopesJobList: [],
      hotJobList: [],
      toMyResumeDialogVisible: false,
    }
  },
  computed: {
    place() {
      return this.$store.state.userInfo.place
    },
    isEditedResume() {
      return this.$store.state.isEditedResume || ''
    },
    isLogin() {
      let token = this.$store.state.token
      let state = false
      if (token && token !== '' && token !== null && token !== 'null') {
        state = true
      }
      return state
    },
  },
  mounted() {
    this.loadIsEditedResume()
    this.redJobLoadData()
    this.hotJobLoadData()
  },
  methods: {
    ...mapMutations(['setCurrentSessionId', 'setIsEditedResume']),
    async loadIsEditedResume() {
      const res = await isEditedResumeApi()

      if (res) {
        this.setIsEditedResume({
          isEditedResume: res.isEditedResume
        })
      }
    },
    async redJobLoadData() {
      // let loading = share.loadingShow({
      //   id: '#hunting-red-envelopes-job',
      //   background: '#f4f2f3',
      // })
      this.pageLoading = true
      let res = await this.loadData([1])
      if (res.jobs) {
        this.redEnvelopesJobList = res.jobs
      }
      this.pageLoading = false
      // loading.close()
    },

    async hotJobLoadData() {
      // let loading = share.loadingShow({
      //   id: '#hunting-hot-job-job',
      //   background: '#f4f2f3',
      // })
      this.pageLoading2 = true
      let res = await this.loadData([0])
      if (res.jobs) {
        this.hotJobList = res.jobs
      }
      this.pageLoading2 = false
      // loading.close()
    },

    submit() {
      sessionStorage.setItem('searchJobFilter', JSON.stringify(this.jobFilter))
      sessionStorage.setItem('searchJobSearchValue', this.searchValue)

      this.redJobLoadData()
      this.hotJobLoadData()
    },

    async loadData(jobType) {
      let financingStatus = this.jobFilter.financingStatus
      if (financingStatus && financingStatus.length > 0) {
        financingStatus = financingStatus[0]
      }else {
        financingStatus = ''
      }

      let address = ''
      if (this.jobFilter.address) {
        let jobFilterAddressArr = JSON.parse(this.jobFilter.address)

        address = this.jobFilter.address
        if (!jobFilterAddressArr || jobFilterAddressArr.length <= 0) {
          if (this.place.value) {
            let arr = []
            arr.push(this.place.value)
            address = JSON.stringify(arr)
          }
        }
      }else {
        if (this.place.value) {
          let arr = []
          arr.push(this.place.value)
          address = JSON.stringify(arr)
        }
      }


      const res = await SearchJobApi({
        jobType: JSON.stringify(jobType),  // 岗位类型
        searchInput: this.searchValue,  // 搜索内容
        address: address,  // 地点
        jobCategory: JSON.stringify(this.jobFilter.jobCategory),  // 岗位类别
        jobProperty: JSON.stringify(this.jobFilter.jobProperty),  // 岗位性质
        salaryRange: JSON.stringify(this.jobFilter.salaryRangeList),  // 薪资范围
        welfare: JSON.stringify(this.jobFilter.welfare),  // 福利待遇
        academicRequirement: JSON.stringify(this.jobFilter.academicRequirement),  // 学历要求
        experienceRequirementList: JSON.stringify(this.jobFilter.experienceRequirementList),  // 经验要求
        enterpriseSize: JSON.stringify(this.jobFilter.enterpriseSize),  // 企业规模
        financingStatus: financingStatus,
        pageNum: 1,
        pageSize: 5,
      })

      return res
    },
    moreJob(num) {
      sessionStorage.setItem('activePageIndex', num)
      this.$router.push({
        path: '/unfoldJobHunting',
        // query: {
        //   jobType: num
        // }
      })
    },
    openJobDetailed(id) {
      this.$router.push({
        path: '/jobDetailed',
        query: {
          id: id
        }
      })
    },
    openCompanyDetailed(id) {
      this.$router.push({
        path: '/companyDetailed',
        query: {
          id: id
        }
      })
    },
    toMyResume() {
      this.$router.push('/myResume')
    },
    openContacts(toSessionId, jobId, jobName) {

      if (!this.isLogin) {
        share.message.warning('请先去登录')
        return
      }

      if (!this.isEditedResume) {
        this.toMyResumeDialogVisible = true
        return
      }

      PostEmployeeContactHrApi({
        jobId: jobId
      })

      share.toChatWindow(toSessionId, {jobId: jobId, jobName: jobName}, '/chatSectionHome')

    }
  }
}
</script>

<style lang="less" scoped>
.jobHunting_warp {
  width: 100%;
  padding-left: 70px;
  box-sizing: border-box;

  > div {
    padding-bottom: 30px;
    box-sizing: border-box;
  }

  .red-envelopes-job-list, .hot-job-list {
    width: 100%;
    min-height: 400px;

    .title {
      height: 35px;
      margin-bottom: 3px;
      display: flex;
      align-items: flex-end;

      .text {
        color: #e80003;
        border-bottom: 3px solid #e80003;
        cursor: pointer;
      }
      .desc {
        color: #969696;
        font-size: 12px;
        padding-left: 10px;
        box-sizing: border-box;
        line-height: 16px;
        cursor: pointer;
        border-bottom: 3px solid #f4f2f3;
      }
    }

    .red-envelopes-job-item, .hot-job-item {
      width: 100%;
      height: 120px;
      background-color: #ffffff;
      border-bottom: 1px solid #e1e1e1;
    }

    .more-btn {
      width: 100%;
      height: 30px;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #8f8f8f;
      font-size: 12px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .empty {
      width: 100%;
      height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: bold;
      color: #e1e1e1;
    }

  }
}
</style>
