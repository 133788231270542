<template>
  <div class="list" v-loading="pageLoading">
    <ul>

      <template v-for="(item, index) in sessionList">
        <li :key="item.session_id"
            v-if="!item.set_block"
            :class="liClass(item)"
            @click="changeSession(item.session_id, item.type, item.session_info, item)">
          <div class="user-item">

            <div class="user-item-content">
              <img class="avatar" :alt="item.session_info.name" :src="initAvatarUrl(item.session_info.avatar)">
              <div class="session-info">
                <p class="name">{{ item.session_info.name }} <span v-if="userType === 'hr'">{{ item.session_info.job_information.jobName }}</span></p>
                <p class="message" v-if="item.recently_chat_log && !item.recently_chat_log.is_recall">
                  <span class="is-have-raed" v-if="item.recently_chat_log && item.recently_chat_log.sender === myChatUserId && item.recently_chat_log.have_read">[已读]</span>
                  <span class="is-have-raed" v-if="item.recently_chat_log && item.recently_chat_log.sender === myChatUserId && !item.recently_chat_log.have_read">[未读]</span>
                  <span class="is-have-raed" v-if="item.unread_count && item.unread_count > 0">[新消息]</span>
                  <span v-if="item.recently_chat_log && item.recently_chat_log.content && item.recently_chat_log.content.type === 'text'">{{ item.recently_chat_log.content.text }}</span>
                  <span v-if="item.recently_chat_log && item.recently_chat_log.content && item.recently_chat_log.content.type === 'apply-red-envelope-text'">{{ item.recently_chat_log.content.data }}</span>
                  <span v-if="item.recently_chat_log && item.recently_chat_log.content && (item.recently_chat_log.content.type === 'call_human_customer_service' || item.recently_chat_log.content.type === 'call_human_customer_service_timed_out')">{{ item.recently_chat_log.content.text }}</span>
                  <span v-if="item.recently_chat_log && item.recently_chat_log.content && item.recently_chat_log.content.type === 'image'">[图片]</span>
                  <span v-if="item.recently_chat_log && item.recently_chat_log.content && item.recently_chat_log.content.type === 'voice'">[语音]</span>
                  <span v-if="item.recently_chat_log && item.recently_chat_log.content && item.recently_chat_log.content.type === 'resume-card'">[简历]</span>
                  <span v-if="item.recently_chat_log && item.recently_chat_log.content && item.recently_chat_log.content.type === 'job-card'">[岗位]</span>
                  <span v-if="item.recently_chat_log && item.recently_chat_log.content &&  (item.recently_chat_log.content.type === 'image-or-text-card' || item.recently_chat_log.content.type === 'text-card' || item.recently_chat_log.content.type === 'hr-no-action-card')">
                    <span v-if="item.session_info.user_id == '000002' && item.recently_chat_log.content.data && item.recently_chat_log.content.data.title">
                      {{item.recently_chat_log.content.data.title}}
                    </span>
                    <span v-else>[卡片]</span>
                  </span>
                  <span v-if="item.recently_chat_log && item.recently_chat_log.content &&  (item.recently_chat_log.content.type === 'questions-card' || item.recently_chat_log.content.type === 'answer-card')">[卡片]</span>
                </p>
                <p class="message" v-if="item.recently_chat_log && item.recently_chat_log.is_recall">
                  <span class="is-have-raed" v-if="item.unread_count && item.unread_count > 0">[新消息]</span>
                  <span v-if="item.recently_chat_log && item.recently_chat_log.sender === myChatUserId">消息已撤回</span>
                  <span v-if="item.recently_chat_log && item.recently_chat_log.sender !== myChatUserId">对方消息已撤回</span>
                </p>
              </div>
            </div>

            <div class="badge" v-if="item.unread_count > 0">
              {{item.unread_count | badgeNumber}}
            </div>
            <div class="action">

              <div class="create-time" v-if="item">
                {{item | chatLogTime}}
              </div>
              <div class="action-more" @click.stop="">
                <el-popover
                    placement="right"
                    trigger="hover"
                    popper-class="action-more-content-popper-warp"
                >
                  <i class="el-icon-more" slot="reference"></i>

                  <div class="action-more-content">
                    <div class="btn" @click.stop="whetherSetTop(item)">
                      {{item.set_top?'取消置顶':'置顶'}}
                    </div>
                    <div class="btn" @click.stop="whetherBlock(item)">
                      {{item.set_block?'取消拉黑':'拉黑'}}
                    </div>
                    <div class="btn" @click.stop="whetherDelete(item)">
                      删除
                    </div>
                  </div>

                </el-popover>
              </div>

  <!--            <el-button-group>-->
  <!--              <el-tooltip effect="dark" :content="item.set_top?'取消置顶':'置顶'" placement="top-start" :enterable="false">-->
  <!--                <el-button type="warning" size="mini" @click.stop="whetherSetTop(item)" circle>-->
  <!--                  <img v-if="!item.set_top" src="../../assets/icon/set-top.png" style="width: 20px; height: 20px;">-->
  <!--                  <img v-if="item.set_top" src="../../assets/icon/cancel-top.png" style="width: 20px; height: 20px;">-->
  <!--                </el-button>-->
  <!--              </el-tooltip>-->
  <!--              <el-tooltip effect="dark" :content="item.set_block?'取消拉黑':'拉黑'" placement="top-start" :enterable="false">-->
  <!--                <el-button type="warning" size="mini" @click.stop="whetherBlock(item)" circle>-->
  <!--                  <img v-if="!item.set_block" src="../../assets/icon/shielding.png" style="width: 20px; height: 20px;">-->
  <!--                  <img v-if="item.set_block" src="../../assets/icon/cancel-shielding.png" style="width: 20px; height: 20px;">-->
  <!--                </el-button>-->
  <!--              </el-tooltip>-->
  <!--              <el-tooltip effect="dark" content="删除" placement="top-start" :enterable="false">-->
  <!--                <el-button type="warning" size="mini" @click.stop="whetherDelete(item)" circle>-->
  <!--                  <img src="../../assets/icon/shielding.png" style="width: 20px; height: 20px;">-->
  <!--                </el-button>-->
  <!--              </el-tooltip>-->
  <!--            </el-button-group>-->
            </div>
          </div>
        </li>
      </template>

      <li v-if="sessionList.length - hypothesisSessionList.length < totalSize" @click="moreData">
        <div class="more">
          {{moreStr}}
        </div>
      </li>
      <div v-if="sessionList.length <= 0" class="empty">
        空
      </div>
    </ul>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import share from "@/share/share";

let defaultProfilePhoto = require('@/assets/icon/profile-photo.png')
export default {
  name: 'list',
  props: {
    filterUnread: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pageLoading: false,
      defaultProfilePhoto: defaultProfilePhoto,
      sessionList: [],
      page: {
        length: 50,
      },
      totalSize: 0,
      // num_of_sessions: 0,
      moreStr: '点击加载更多...',

      /**
       * pc端特殊情况，用来保存临时创建的会话列表
       * */
      hypothesisSessionList: [],
    }
  },
  computed: {
    // currentSessionId() {
    //   return this.$store.state.currentSessionId || ''
    // },
    // sessionList() {
    //   return this.$store.state.sessionList || []
    // }
    chatSessionId() {
      return this.$store.state.chatSessionId
    },
    aonuoChat() {
      return this.$store.state.aonuoChat
    },
    chatSession() {
      return this.$store.state.chatSession
    },
    chatSessionInfo() {
      return this.$store.state.chatSessionInfo
    },
    aonuoChatReady() {
      return this.$store.state.aonuoChatReady
    },
    myChatUserId() {
      return this.$store.state.myChatUserId
    },
    userType() {
      return this.$store.state.userInfo.userType
    },
    defAvatarUrl() {
      if (this.userType === 'hr') {
        return 'https://cdn.jiaxinzp.com/jxzp/hr_avatar_male.png'
      }else {
        return 'https://cdn.jiaxinzp.com/jxzp/employee_avatar_male.png'
      }
    },
  },
  watch: {
    aonuoChatReady: {
      handler: async function() {
        if (this.aonuoChatReady) {
          await this.loadSessionList()

          let state = false
          this.sessionList.forEach((item, index) => {
            if (item.session_id === this.chatSessionId) {
              item.unread_count = 0
              state = true
            }
          })

          if (!state && this.chatSessionId !== '' && this.chatSession !== null) {
            // this.sessionList.unshift(this.chatSession)
            this.listUnshift(this.chatSession)

            let isHave = false
            this.hypothesisSessionList.forEach((item, index) => {
              if (item === this.chatSessionId) {
                isHave = true
              }
            })
            if (!isHave) {
              this.hypothesisSessionList.push(this.chatSession.session_id)
            }
          }
        }
      },
    },
    chatSessionId: {
      handler: async function () {
        let state = false
        this.sessionList.forEach((item, index) => {
          if (item.session_id === this.chatSessionId) {
            item.unread_count = 0
            state = true
          }
        })
        if (!state && this.chatSessionId !== '' && this.chatSession !== null) {
          // this.sessionList.unshift(this.chatSession)
          this.listUnshift(this.chatSession)

          let isHave = false
          this.hypothesisSessionList.forEach((item, index) => {
            if (item === this.chatSessionId) {
              isHave = true
            }
          })
          if (!isHave) {
            this.hypothesisSessionList.push(this.chatSession.session_id)
          }
        }
      },
    }
  },
  async mounted() {
    this.pageLoading = true
    if (this.aonuoChatReady) {
      await this.loadSessionList()

      let state = false
      this.sessionList.forEach((item, index) => {
        if (item.session_id === this.chatSessionId) {
          item.unread_count = 0
          state = true
        }
      })

      /**
      * pc端特殊情况，当前会话不在当前的左侧会话列表内时的情况
      * */
      if (!state && this.chatSessionId !== '' && this.chatSession !== null) {
        // this.sessionList.unshift(this.chatSession)
        this.listUnshift(this.chatSession)

        let isHave = false
        this.hypothesisSessionList.forEach((item, index) => {
          if (item === this.chatSessionId) {
            isHave = true
          }
        })
        if (!isHave) {
          this.hypothesisSessionList.push(this.chatSession.session_id)
        }
      }
    }

    /**
     * 放置全局监听事件
     * */
    this.$bus.$on('MESSAGE_RECEIVED', this.messageReceived2)
    this.$bus.$on('MESSAGE_SEND_SUCCESS', this.messageSendSuccess2)
    this.$bus.$on('CONVERSATION_CREATED', this.conversationCreated2)
    this.$bus.$on('MESSAGE_READ_BY_PEER', this.messageReadByPeer2)
    this.$bus.$on('MESSAGE_REVOKED', this.messageRevoked2)

    this.pageLoading = false
  },
  destroyed() {
    this.$bus.$off('MESSAGE_RECEIVED', this.messageReceived2)
    this.$bus.$off('MESSAGE_SEND_SUCCESS', this.messageSendSuccess2)
    this.$bus.$off('CONVERSATION_CREATED', this.conversationCreated2)
    this.$bus.$off('MESSAGE_READ_BY_PEER', this.messageReadByPeer2)
    this.$bus.$off('MESSAGE_REVOKED', this.messageRevoked2)
  },
  methods: {
    ...mapMutations(['setChatSessionId', 'setChatSession', 'setChatSessionInfo', 'setCurrentSessionId', 'setToSessionUserId']),

    initAvatarUrl(avatar) {
      if (avatar) {
        return share.initImageUrl(avatar)
      }else {
        return this.defAvatarUrl
      }
    },

    liClass(obj) {
      if (obj.session_id === this.chatSessionId) {
        if (obj.set_top) {
          return 'active set-top'
        }else {
          return 'active'
        }
      }else {
        if (obj.set_top) {
          return 'set-top'
        }else {
          return ''
        }
      }
    },

    /**
     * 消息通道收到一条消息时触发
     * */
    messageReceived2(data) {
      console.log('消息通道收到了一条消息2', data)
      let state = false
      let newItem = {}
      let i = ''
      this.sessionList.forEach((item, index) => {
        if (item.session_id === data.session.session_id) {
          state = true
          item.recently_chat_log = data.session.recently_chat_log

          if (data.session.session_id === this.chatSessionId) {
            item.unread_count = 0
          }else {
            item.unread_count = data.session.unread_count
          }
          newItem = item
          i = index
        }
      })

      if (state) {
        this.sessionList.splice(i, 1)
        // this.sessionList.unshift(newItem)
        this.listUnshift(newItem)
      }else {
        // this.sessionList.unshift(data.session)
        this.listUnshift(data.session)

        // if (this.sessionList.length < this.num_of_sessions) {
        //   this.sessionList.splice(this.sessionList.length - 1, 1)
        // }
      }

    },

    /**
     * 消息通道发送一条消息成功时触发
     * */
    messageSendSuccess2(data) {
      console.log('消息通道发送一条消息成功2', data)
      let state = false
      let newItem = {}
      let i = ''
      this.sessionList.forEach((item, index) => {
        if (item.session_id === data.session.session_id) {
          state = true
          item.recently_chat_log = data.session.recently_chat_log

          if (data.session.session_id === this.chatSessionId) {
            item.unread_count = 0
          }else {
            item.unread_count = data.session.unread_count
          }
          newItem = item
          i = index
        }
      })

      if (state) {
        this.sessionList.splice(i, 1)
        // this.sessionList.unshift(newItem)
        this.listUnshift(newItem)
      }else {
        // this.sessionList.unshift(data.session)
        this.listUnshift(data.session)

        // if (this.sessionList.length < this.num_of_sessions) {
        //   this.sessionList.splice(this.sessionList.length - 1, 1)
        // }
      }

    },

    /**
     * 给列表头部新增一项又不影响置顶内容
     * */
    listUnshift(obj) {
      let i = ''
      try {
        this.sessionList.forEach((item, index) => {
          if (item.set_top === false) {
            i = index
            throw Error()
          }
        })
      }catch (e) {
      }
      if (obj.set_top) {
        this.sessionList.unshift(obj)
      }else {
        this.sessionList.splice(i, 0, obj)
      }
    },

    /**
     * 会话列表创建时触发
     * */
    conversationCreated2(data) {
      console.log('会话列表创建时触发2', data)

      // this.sessionList.unshift(data.session)
      this.listUnshift(data.session)

      // if (this.sessionList.length < this.num_of_sessions) {
      //   this.sessionList.splice(this.sessionList.length - 1, 1)
      // }
    },

    /**
     * 收到对端已读消息的通知
     * */
    messageReadByPeer2(data) {
      console.log('收到对端已读消息的通知2', data)
      let state = false
      let newItem = {}
      let i = ''
      this.sessionList.forEach((item, index) => {
        if (item.session_id === data.session.session_id) {
          state = true
          item.recently_chat_log = data.session.recently_chat_log

          if (data.session.session_id === this.chatSessionId) {
            item.unread_count = 0
          }else {
            item.unread_count = data.session.unread_count
          }
          newItem = item
          i = index
        }
      })

      if (state) {
        this.sessionList.splice(i, 1)
        // this.sessionList.unshift(newItem)
        this.listUnshift(newItem)
      }else {
        // this.sessionList.unshift(data.session)
        this.listUnshift(data.session)

        // if (this.sessionList.length < this.num_of_sessions) {
        //   this.sessionList.splice(this.sessionList.length - 1, 1)
        // }
      }
    },


    /**
     * 收到消息被撤回的通知
     * */
    async messageRevoked2(data) {
      console.log('收到消息被撤回的通知2', data)
      let state = false
      let newItem = {}
      let i = ''
      this.sessionList.forEach((item, index) => {
        if (item.session_id === data.session.session_id) {
          state = true
          item.recently_chat_log = data.session.recently_chat_log

          if (data.session.session_id === this.chatSessionId) {
            item.unread_count = 0
          }else {
            item.unread_count = data.session.unread_count
          }
          newItem = item
          i = index
        }
      })
      if (state) {
        this.sessionList.splice(i, 1)
        // this.sessionList.unshift(newItem)
        this.listUnshift(newItem)
      }else {
        // this.sessionList.unshift(data.session)
        this.listUnshift(data.session)

        // if (this.sessionList.length < this.num_of_sessions) {
        //   this.sessionList.splice(this.sessionList.length - 1, 1)
        // }
      }
    },

    /**
    * 获取会话列表
    * */
    async loadSessionList() {
      this.moreStr = '加载中...'

      let newOffset = this.sessionList.length - this.hypothesisSessionList.length
      let newLength = this.page.length

      const res = await this.aonuoChat.getConversationList(newOffset, newLength)
      let arr1 = [], arr2 = []

      this.sessionList = [...this.sessionList, ...res.session_info_list]

      this.hypothesisSessionList.forEach((item, index) => {
        let num = ''
        try{
          this.sessionList.forEach((item2, index2) => {
            if (item === item2.session_id) {
              if (num === '') {
                num = index2
              }else {
                arr1.unshift(index)
                arr2.unshift(num)
              }
            }
          })
        }catch(e){
        }
      })

      arr1.forEach((item, index) => {
        this.hypothesisSessionList.splice(item, 1)
      })

      arr2.forEach((item, index) => {
        this.sessionList.splice(item, 1)
      })

      this.totalSize = res.num_of_sessions

      this.moreStr = '点击加载更多...'
    },

    /**
     * 重新获取会话列表
     * */
    async reacquireLoadSessionList(length) {
      this.pageLoading = true
      this.moreStr = '加载中...'

      let newFilterUnread = ''
      if (this.filterUnread) {
        newFilterUnread = 'filterUnread'
      }

      let newLength = this.sessionList.length
      if (length && length > 0) {
        newLength = length
      }

      const res = await this.aonuoChat.getConversationList(0, newLength, '', newFilterUnread)
      let arr1 = [], arr2 = []
      this.sessionList = [...res.session_info_list]
      this.hypothesisSessionList.forEach((item, index) => {
        let num = ''
        try{
          this.sessionList.forEach((item2, index2) => {
            if (item === item2.session_id) {
              if (num === '') {
                num = index2
              }else {
                arr1.unshift(index)
                arr2.unshift(num)
              }
            }
          })
        }catch(e){
        }
      })
      arr1.forEach((item, index) => {
        this.hypothesisSessionList.splice(item, 1)
      })
      arr2.forEach((item, index) => {
        this.sessionList.splice(item, 1)
      })
      this.totalSize = res.num_of_sessions
      this.moreStr = '点击加载更多...'
      this.pageLoading = false
    },

    /**
    * 选择会话
    * */
    changeSession(sessionId, sessionType, sessionInfo, session) {

      let state = false

      this.sessionList.forEach((item, index) => {
        if (item.session_id === sessionId) {
          state = true
        }
      })

      if (state) {
        this.setChatSessionId({
          chatSessionId: sessionId
        })
        this.setChatSession({
          chatSession: session
        })
        this.setChatSessionInfo({
          chatSessionInfo: sessionInfo
        })

      }else {
        // this.sessionList.unshift(session)
        this.listUnshift(session)

        // if (this.sessionList.length < this.num_of_sessions) {
        //   this.sessionList.splice(this.sessionList.length - 1, 1)
        // }
      }

    },
    // /**
    //  * 选择会话
    //  * */
    // selectSession(currentSessionId, toSessionUserId) {
    //   this.setCurrentSessionId({
    //     currentSessionId: currentSessionId
    //   })
    //   this.setToSessionUserId({
    //     toSessionUserId: toSessionUserId
    //   })
    //   this.$emit('handleSelectSession', currentSessionId, toSessionUserId)
    // },

    /**
     * 获取更多会话
     * */
    async moreData() {
      await this.loadSessionList()
    },

    /**
    * 是否拉黑
    * */
    async whetherBlock (obj) {
      this.pageLoading = true
      let action = ''
      if (obj.set_block === true) {
        action = 'cancel_block'
      }else {
        action = 'set_block'
      }
      const res = await this.aonuoChat.postSetBlock(action, obj.session_id)

      if (res) {
        share.message.success('操作成功')
        await this.reacquireLoadSessionList()
      }
      this.$emit('cancelBlock')
      this.pageLoading = false
    },

    /**
     * 是否置顶
     * */
    async whetherSetTop (obj) {
      this.pageLoading = true
      let action = ''
      if (obj.set_top === true) {
        action = 'cancel_top'
      }else {
        action = 'set_top'
      }
      const res = await this.aonuoChat.postConversationSetTop(action, obj.session_id)

      if (res) {
        share.message.success('操作成功')
        await this.reacquireLoadSessionList()
      }
      this.pageLoading = false
    },

    async whetherDelete(obj) {
      this.pageLoading = true
      const res = await this.aonuoChat.deleteConversation(obj.session_id)

      if (res) {
        share.message.success('操作成功')
        await this.reacquireLoadSessionList()
      }
      this.pageLoading = false
    }

  },
  filters: {
    badgeNumber (date) {
      let str = ''
      if (parseInt(date) > 99) {
        str = '99+'
      }else {
        str = date
      }
      return str
    },
    chatLogTime(data) {
      let time = ''
      if (!data) {
        return ''
      }
      if (data.recently_chat_log && data.recently_chat_log.create_time) {
        time = data.recently_chat_log.create_time
      }else {
        time = data.create_time
      }
      let nowDate = new Date()
      let nowYear = nowDate.getFullYear()
      let nowMonth = nowDate.getMonth() + 1
      let nowDay = nowDate.getDate()

      let msgDate = new Date(time)
      let msgYear = msgDate.getFullYear()
      let msgMonth = msgDate.getMonth() + 1
      let msgDay = msgDate.getDate()
      let msgHours = msgDate.getHours()
      let msgMinutes = msgDate.getMinutes()

      let newDate = ''
      if (nowYear - msgYear > 0) {
        newDate = msgYear + '年'
      }else if (nowMonth - msgMonth > 0) {
        newDate = msgMonth + '月' + msgDay + '日'
      }else if (nowDay - msgDay > 0) {
        newDate = msgMonth + '月' + msgDay + '日'
      }else {
        if (msgHours < 10) {
          msgHours = '0' + msgHours
        }
        if (msgMinutes < 10) {
          msgMinutes = '0' + msgMinutes
        }
        newDate = msgHours + ':' + msgMinutes
      }
      return newDate
    }
  }
};
</script>

<style scoped lang="less">
.list {
  width: 100%;
  //height: 690px;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */


  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  ul {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  .empty {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #e1e1e1;
  }

  li {
    width: 100%;
    list-style: none;
    padding: 12px 15px;
    box-sizing: border-box;
    //border-bottom: 1px solid #292C33;
    border-bottom: 1px solid #eaeaea;
    cursor: pointer;
    transition: background-color .1s;

    &:hover {
      //background-color: rgba(255, 255, 255, 0.1);
      background-color: #efefef;
    }

    &.active {
      //background-color: rgba(217, 217, 217, 0.3);
      background-color: #d9d8d8;
    }

    .user-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;

      .user-item-content {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .avatar {
          border-radius: 50%;
          width: 50px;
          height: 50px;
          margin-right: 10px;
        }

        .session-info {
          .name {
            font-size: 16px;
            width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            span {
              font-size: 12px;
              color: #737373;
            }
          }
          .message {
            font-size: 10px;
            color: #969696;
            width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 5px;
            .is-have-raed {
              padding-right: 5px;
              color: #E82727;
            }
          }
        }
      }

      &:hover {
        .action {
          .create-time {
            display: none;
          }
          .action-more {
            display: block;
          }
        }
      }

      .action {
        & /deep/ button {
          padding: 2px;
        }

        .create-time {
          font-size: 12px;
          color: #989898;
          display: block;
        }

        .action-more {
          display: none;
          height: 20px;
          width: 20px;
          text-align: center;
          border-radius: 2px;

          &:hover {
            background-color: #969696;
          }
        }

      }

      .badge {
        position: absolute;
        right: 55px;
        top: 50%;
        transform: translateY(-50%);
        background-color: #f56c6c;
        border-radius: 10px;
        height: 18px;
        line-height: 18px;
        padding: 0 6px;
        text-align: center;
        white-space: nowrap;
        //border: 1px solid #ffffff;
        border: 1px solid #eaeaea;
        font-size: 12px;
        //color: #ffffff;
        color: #414a60;
      }
    }

  }

  .set-top {
    background-color: #e8e8e8 !important;

    &:hover {
      //background-color: #ce3636 !important;
      background-color: #efefef !important;
    }

    &.active {
      //background-color: #cd5353 !important;
      background-color: #d9d8d8 !important;
    }
  }

  .more {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #c6c6c6;
    cursor: pointer;
  }

  //.avatar, .name {
  //  vertical-align: middle;
  //}
  //
  //.avatar {
  //  border-radius: 50%;
  //  width: 30px;
  //  height: 30px;
  //}
  //
  //.name {
  //  flex: 1;
  //  margin: 0 0 0 10px;
  //  padding-right: 35px;
  //  box-sizing: border-box;
  //  overflow: hidden;
  //  text-overflow: ellipsis;
  //  white-space: nowrap;
  //}
}
</style>

<style lang="less">
.action-more-content-popper-warp {
  //padding: 0 !important;
  //margin: 0 !important;
  width: 100px !important;
  min-width: 100px !important;
  .action-more-content {
    width: 100%;
    height: 100%;

    .btn {
      width: 100%;
      line-height: 30px;
      text-align: center;
      border-bottom: 1px solid #efefef;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        background-color: #efefef;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

  }
}
</style>
