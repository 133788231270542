let base_url,web_base_url,upload_file_url;

// 开发环境
// base_url = 'http://jiaxinzp.com:8000/api'
// web_base_url = 'https://m.jiaxinzp.com/'
// upload_file_url = 'http://jiaxinzp.com:9999'
// 生产环境
base_url = 'https://jiaxinzp.com/api'
web_base_url = 'https://jiaxinzp.com/'
upload_file_url = 'https://chat.jiaxinzp.com'

// 接口地址
export const BASE_URL = base_url
// 前端部署域名
export const WEB_BASE_URL = web_base_url
// 聊天上传文件的地址
export const UPLOAD_FILE_URL = upload_file_url
// 协议部属域名
export const ALL_RULES = 'https://terms.jiaxinzp.com/'
