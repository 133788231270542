<template>
  <div id="app">
    <div class="app-content-warp">
      <router-view v-if="isReload"></router-view>
    </div>
    <div class="footer">
      <div class="copyright">
        <span>© 2021 <a href="https://honorservice.cn">江苏傲诺人力资源有限公司</a> 版权所有</span>|<span><a href="https://beian.miit.gov.cn">苏ICP备2021003642号-3</a></span>|
        <span class="a" @click="toRulesPage('User_Agreement')">《{{AllAgreement.User_Agreement}}》</span>|<span class="a" @click="toRulesPage('Privacy_Policy')">《{{AllAgreement.Privacy_Policy}}》</span>|

        <el-popover
            placement="top"
            title="扫码下载APP"
            width="174"
            trigger="click">
          <div class="qr-code">
            <el-image
                style="width: 100%;"
                src="https://cdn.jiaxinzp.com/jxzp/JXZP-APP-qrcode.png"
                fit="contain"></el-image>
          </div>
          <span class="a" style="cursor: pointer;" slot="reference">佳薪职聘APP</span>
        </el-popover>
<!--        |<span class="a" @click="toRulesPage('Log_Off_Accounts_Agreement')">《注销协议》</span>|<span class="a" @click="toRulesPage('Invoicing_Agreement')">《开票协议》</span>-->
<!--        |<span class="a" @click="toRulesPage('Service_Agreement')">《服务协议》</span>|<span class="a" @click="toRulesPage('Promotion_Description')">《推广说明》</span>-->
<!--        |<span class="a" @click="toRulesPage('Publish_Job_Agreement')">《发布岗位协议》</span>-->
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import {ALL_RULES, BASE_URL} from '@/config/baseURL'
import {
  GetHrGreetingMessageManagementApi,
  LoginWithUserTokenApi,
} from "@/request/api"
import {MP} from "@/components/pageHeader/loadBMap"
import share from './share/share'
import {ak_web, AllAgreement} from './share/shareData'
import {Open_Map} from '@/config/config'

export default {
  name: 'app',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      ak_web: ak_web,  //百度地图秘钥
      userId: '',
      loginChatServiceNum: 0,
      AllAgreement: AllAgreement,
      isReload: true,
    }
  },
  computed: {
    userType() {
      return this.$store.state.userInfo.userType
    }
  },
  watch: {

  },
  methods: {
    ...mapMutations(['setUserinfo', 'setToken', 'setBMap', 'setAonuoChatReady',
      'setAonuoChat', 'setMyChatUserId', 'setMessageUnreadCount', 'setTemporaryMyChatUserId',
      'setAutomaticGreetingIsOpen', 'setGreetingsList', 'setAutomaticGreetingsForSpecificPositions',
      'setCurrentAutomaticGreetings']),
    /**
     * 刷新页面
     *  */
    reload() {
      this.isReload = false
      this.$nextTick(() => {
        this.isReload = true
      })
    },

    /**
     * 初始化 h5 地图
     * */
    initMP() {
      MP(this.ak_web).then((BMap) => {
        this.setBMap({
          BMap: BMap
        })
        console.log('地图挂载成功', this.$store.state.BMap)
      })
    },

    /**
     * h5 端刷新页面时不丢失 vuex 中的数据
     * */
    initRefreshStore() {
      if (sessionStorage.getItem("store")) {

        this.$store.replaceState(
            Object.assign(
                {},
                this.$store.state,
                JSON.parse(sessionStorage.getItem("store"))
            )
        )
        sessionStorage.removeItem("store")
      }

      //在页面刷新时将vuex里的信息保存到sessionStorage里
      window.addEventListener("beforeunload", () => {
        this.setAonuoChatReady({
          aonuoChatReady: false
        })
        console.log('-------------------页面刷新----------------------')
        sessionStorage.setItem("store", JSON.stringify(this.$store.state))
      })
    },

    /**
     * 初始化聊天通道
     * */
    initChatSdk() {

      let AonuoChat = new $AonuoChatSdk()
      AonuoChat.on('SDK_READY', (data) => {
        console.log('消息通道准备好了', data)
        if (this.userId) {
          this.chatServiceLogin()
        }
        this.setAonuoChatReady({
          aonuoChatReady: true
        })
      })
      AonuoChat.on('LOGIN_SUCCESS', (data) => {
        console.log('聊天登录认证成功', data)
        if (this.userType === 'hr') {
          this.getGreetingMessageManagement()
        }
        this.getNoneReadMsg()
      })
      AonuoChat.on('LOGOUT_SUCCESS', (data) => {
        console.log('聊天登出成功', data)
        this.userId = ''
      })
      AonuoChat.on('MESSAGE_RECEIVED', (data) => {
        console.log('消息通道收到了一条消息', data)
        this.setMessageUnreadCount({
          messageUnreadCount: 1
        })
        this.$bus.$emit('MESSAGE_RECEIVED', data)
      })
      AonuoChat.on('MESSAGE_SEND_SUCCESS', (data) => {
        console.log('消息通道发送一条消息成功', data)
        this.$bus.$emit('MESSAGE_SEND_SUCCESS', data)
      })
      AonuoChat.on('CONVERSATION_CREATED', (data) => {
        console.log('会话列表创建时触发', data)
        this.$bus.$emit('CONVERSATION_CREATED', data)
      })
      AonuoChat.on('MESSAGE_BLOCKED_ERROR', (data) => {
        console.log('被拉黑导致消息发送失败的响应', data)
        this.$bus.$emit('MESSAGE_BLOCKED_ERROR', data)
      })
      AonuoChat.on('MESSAGE_READ_BY_PEER', (data) => {
        console.log('收到对端已读消息的通知', data)
        this.$bus.$emit('MESSAGE_READ_BY_PEER', data)
      })
      AonuoChat.on('MESSAGE_REVOKED', (data) => {
        console.log('收到消息被撤回的通知', data)
        this.$bus.$emit('MESSAGE_REVOKED', data)
      })
      AonuoChat.on('ERROR', (data) => {
        console.log('消息通道发生错误', data)
        this.setTemporaryMyChatUserId({
          temporaryMyChatUserId: ''
        })
        AonuoChat.connect()
      })
      AonuoChat.on('SDK_NOT_READY', (data) => {
        console.log('消息通道进入没准备好状态', data)
        this.setTemporaryMyChatUserId({
          temporaryMyChatUserId: ''
        })
        AonuoChat.connect()
        this.$bus.$emit('SDK_NOT_READY', data)
      })
      AonuoChat.on('KICKED_OUT', (data) => {
        console.log('收到被踢下线通知', data)
        // if (this.userType === 'hr') {
        this.setUserinfo({
          place: {
            value: '',
            label: ''
          },
        })
        // }
        AonuoChat.logout()
        share.loginOut()
        this.setUserinfo({
          userType: 'employee',
        })
        if (data.data.kick_type === 'ban_login') {
          share.message.warning('您的账号已被限制登录')
        }
        if (data.data.kick_type === 'unregister') {
          share.message.warning('账号已注销')
        }
        this.$router.push('/login')
        this.$bus.$emit('KICKED_OUT', data)
      })

      this.setAonuoChat({
        aonuoChat: AonuoChat
      })

      AonuoChat.create()
    },

    /**
     * 自动登录
     * */
    async autoLogin() {
      console.log('开始自动登录')
      const res = await LoginWithUserTokenApi()
      if (res) {
        this.setToken({
          token: res.userToken
        })

        localStorage.setItem('JXZP_User_Token', res.userToken)

        let url = share.initImageUrl(res.information.avatar)

        this.setUserinfo({
          username: res.information.name || '',
          userType: res.information.userType || '',
          gender: res.information.gender || '',
          profilePhoto: url
        })

        if (res.areaCoinInformation) {
          this.setUserinfo({
            place: {
              value: res.areaCoinInformation.code,
              label: res.areaCoinInformation.area
            }
          })
        }

        this.userId = res.information.sessionId
        this.setMyChatUserId({
          myChatUserId: this.userId
        })
      }else {
        share.loginOut()
        this.setUserinfo({
          userType: 'employee',
        })
      }
    },

    /**
     * 登录聊天服务
     * */
    async chatServiceLogin() {
      console.log('chatServiceLogin', this.userId)
      let aonuoChat = this.$store.state.aonuoChat
      await aonuoChat.login(this.userId)
    },

    /**
    * 获取维度消息数量
    * */
    async getNoneReadMsg() {

    },

    /**
     * 获取打招呼设置
     * */
    async getGreetingMessageManagement() {
      const res = await GetHrGreetingMessageManagementApi()
      console.log('GetHrGreetingMessageManagementApi', res)
      if (res) {
        this.setAutomaticGreetingIsOpen({
          automaticGreetingIsOpen: res.greetingMessageConf.isOpen
        })
        this.setGreetingsList({
          greetingsList: res.systemGreetingMessageList
        })
        this.setAutomaticGreetingsForSpecificPositions({
          automaticGreetingsForSpecificPositions: res.jobGreetingMessageList
        })
        this.setCurrentAutomaticGreetings({
          currentAutomaticGreetings: res.greetingMessageConf.optionId
        })
      }
    },

    toRulesPage(param) {
      window.open(ALL_RULES + '?type=' + param)
    },

  },
  async created() {

    /**
     * 注册百度地图
     * */

    if (Open_Map) {
      console.log('-----------开始挂载-----------')
      this.initMP()
    }

    window['selfRouter'] = (router, query = {}) => {
      this.$router.push({
        path: router,
        query: query
      })
    }

    this.initRefreshStore()

    let token = localStorage.getItem('JXZP_User_Token')

    console.log('JXZP_User_Token', token)

    if (token && token !== '' && token !== null && token !== 'null') {
      this.setToken({
        token: token
      })
      await this.autoLogin()
    }else {
      this.setUserinfo({
        userType: 'employee',
      })
    }

    this.initChatSdk()

  },
  destroyed() {
    this.$bus.$off('SDK_READY')
    this.$bus.$off('LOGIN_SUCCESS')
    this.$bus.$off('LOGOUT_SUCCESS')
    this.$bus.$off('MESSAGE_RECEIVED')
    this.$bus.$off('MESSAGE_SEND_SUCCESS')
    this.$bus.$off('CONVERSATION_CREATED')
    this.$bus.$off('MESSAGE_BLOCKED_ERROR')
    this.$bus.$off('MESSAGE_READ_BY_PEER')
    this.$bus.$off('ERROR')
    this.$bus.$off('SDK_NOT_READY')
    this.$bus.$off('KICKED_OUT')
  },
}
</script>

<style scoped>
#app {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
#app>.app-content-warp {
  width: 100%;
  flex: 1;
  /*min-width: 1400px;*/
  /*min-height: 800px;*/
}
#app>.footer {
  width: 100%;
  min-width: 1400px;
  height: 50px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(238, 238, 238);
  color: #999999;
  font-size: 12px;
}
#app>.footer>.copyright {
  width: 100%;
  text-align: center;
}
#app>.footer>.copyright>span {
  padding: 0 10px;
}
/*去除a标签下划线*/
#app>.footer>.copyright>span a {
  text-decoration: none;
  color: #999999;
}
/*去除未被访问的a标签的下划线*/
#app>.footer>.copyright>span a:link {
  text_decoration: none;
}
/*去除已经被访问过的a标签的下划线*/
#app>.footer>.copyright>span a:visited {
  text_decoration: none;
}
/*去除鼠标悬停时的a标签的下划线*/
#app>.footer>.copyright>span a:hover {
  text_decoration: none;
}
/*去除正在点击的a标签的下划线（鼠标按下，尚未松开）*/
#app>.footer>.copyright>span a:active {
  text_decoration: none;
}

/*去除获得焦点的a标签的下划线（被鼠标点击过）*/
a:focus {
  text_decoration: none;
}




#app>.footer>.copyright>.a {
  cursor: pointer;
}
</style>

<style lang="less">


.el-dialog__wrapper{
  &.dialog-fade-leave-active{
    -ms-animation:none !important;
  }
}

.el-input__prefix::-webkit-scrollbar {
  display: none;
}

.el-input__prefix {
  //IE
  -ms-overflow-style: none;
  //firefox
  scrollbar-width: none;
}

.el-cascader {

  .el-cascader__tags {
    .el-tag {
      span {
        flex: none;
      }
    }
  }

}

.el-table__header, .el-table__body{
  width:100% !important;
}

</style>
