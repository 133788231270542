<template>
  <div class="jobDetailed_warp">

    <div class="page-header">
<!--      <page-header :curr-page="currHeaderMenu" @actionClick="headerActionClick" @changeCurrPage="headerChangeCurrPage"-->
<!--                   backgroundColor="#f4f2f3"></page-header>-->
      <page-header :curr-page="currHeaderMenu" @changeCurrPage="headerChangeCurrPage"
                   backgroundColor="#f4f2f3"></page-header>
    </div>

    <div class="header">

      <div class="header-main" v-loading="pageLoading1">
        <div class="left">

          <div class="title">
            招聘岗位：<span class="span">{{ jobName }}</span> <span class="span2">{{ salary }}</span>
          </div>

          <div class="condition">
            [{{ jobAddress[0] }} · {{ jobAddress[1] }} · {{ jobAddress[2] }}] · {{ experienceRequirementRange }} ·
            {{ academicRequirement }} · {{ ageRequirementRange }}
          </div>

          <div class="tag">
            <el-tag v-if="jobWelfare" size="small" v-for="(item, index) in jobWelfare" :key="'jobWelfare' + index">{{item}}</el-tag>
          </div>

        </div>

        <div class="right">

          <div class="right-btn">

            <div class="red-envelopes-icon" v-if="jobType === 1 && !isClose">
              <red-envelopes :number="redPocketPerPerson"></red-envelopes>
            </div>

            <el-button-group class="to-chat" v-if="!isClose">
              <el-button :loading="btnLoading" @click="openContacts">立即沟通</el-button>
              <el-button v-if="jobType === 1" :loading="btnLoading" @click="toApplyRedEnvelope">申请红包</el-button>
            </el-button-group>

            <el-button type="text"  class="go-my-resume" @click="goMyResume">
              <div class="icon">
                <img src="@/assets/icon/resume2.png">完善在线简历
              </div>
            </el-button>
          </div>

          <div class="action" v-if="!isClose">

            <div class="icon" @click="actionClick(1, isCollected)">
              <img v-if="!isCollected" src="@/assets/icon/collection.png">
              <img v-if="isCollected" src="@/assets/icon/collection-choose.png">
            </div>

            <el-popover
                placement="bottom-end"
                title="分享链接"
                width="400"
                trigger="click"
                v-loading="invitationCodeLoading"
            >
              <div class="share_warp">
                <el-input type="text" readonly v-model="shareJobDetailedUrl">
                  <template slot="append">
                    <div style="width: 50px; height: 38px; display: flex; align-items: center; justify-content: center; cursor: pointer;" v-clipboard:copy="shareJobDetailedUrl" v-clipboard:success="onCopy" v-clipboard:error="onError">
                      点击复制
                    </div>
                  </template>
                </el-input>
              </div>

              <div class="icon" slot="reference" @click="actionClick(2)">
                <img src="@/assets/icon/share.png">
              </div>

            </el-popover>

            <div class="icon" @click="actionClick(3)">
              <img src="@/assets/icon/complaint.png">
            </div>

          </div>

        </div>
      </div>

    </div>

    <div class="content">

      <div class="card job-information" v-loading="pageLoading2">

        <div class="title" v-if="!isClose">
          岗位详细地址
        </div>

        <div class="msg" v-if="!isClose" style="white-space: pre-wrap; text-indent: -4px;">
          {{ detailAddress }}
        </div>

        <div class="title" v-if="!isClose">
          岗位描述
        </div>

        <div class="msg" v-if="!isClose" style="white-space: pre-wrap; text-indent: -4px;">
          岗位描述：{{ description }}
        </div>

        <div class="title" v-if="!isClose">
          企业介绍
        </div>

        <div class="msg" v-if="!isClose">

          <div class="description">
            <div class="content-detail">
              {{ companyProfile }}
              <el-button v-if="!isShowMordText && isCompanyProfileMordText" type="text" @click="showMoreText">
                展开 <i class="el-icon-arrow-down"></i>
              </el-button>
              <el-button v-if="isShowMordText && isCompanyProfileMordText" type="text" @click="closeMoreText">
                收起 <i class="el-icon-arrow-up"></i>
              </el-button>
            </div>
          </div>

        </div>

        <div class="title" v-if="isClose">
          岗位状态
        </div>

        <div class="msg" v-if="isClose">
          <span class="is-close">该岗位已关闭</span>
        </div>

        <div class="title">
          工商信息
        </div>

        <div class="msg table">
          <div class="name">
            {{ enterpriseInformation.enterprise_name }}
          </div>

          <div class="main">

            <div>
              法定代表人：<span>{{ enterpriseInformation.legalPerson }}</span>
            </div>

            <div>
              注册资本：<span>{{ enterpriseInformation.registeredCapital }}万人民币</span>
            </div>

            <div>
              成立日期：<span>{{ enterpriseInformation.establishDate }}</span>
            </div>

            <div>
              企业类型：<span>{{ enterpriseInformation.enterpriseProperty }}</span>
            </div>

            <!--            <div>-->
            <!--              经营状态：<span>存续</span>-->
            <!--            </div>-->

            <div>
              企业地址：<span
                v-if="enterpriseInformation.enterpriseAddress">{{ enterpriseInformation.enterpriseAddress[0] }}{{ enterpriseInformation.enterpriseAddress[1] }}{{ enterpriseInformation.enterpriseAddress[2] }}{{ enterpriseInformation.detailAddress }}</span>
            </div>

            <!--            <div>-->
            <!--              统一社会信用代码：<span>xxxxxxxxx</span>-->
            <!--            </div>-->

            <div>
              行业：<span>{{ enterpriseInformation.industry }}</span>
            </div>

          </div>

        </div>

      </div>

      <div class="card company-information">

        <div class="information" v-loading="pageLoading2">

          <div class="title" @click="toCompanyDetailed">
            企业基本信息 <i class="el-icon-arrow-right"></i>
          </div>

          <div class="name">
            <el-avatar shape="square" size="large" :src="newEnterpriseLogoUrl"></el-avatar>
            {{ enterpriseInformation.enterprise_name }}
          </div>

          <div class="info-item">
            <img src="@/assets/icon/financing.png">{{ financingStatus }}
          </div>

          <div class="info-item">
            <img src="@/assets/icon/team.png">
            <span
                v-if="enterpriseInformation.enterpriseSizeRange">{{enterpriseSizeRange}}</span>
          </div>

          <div class="info-item">
            <img src="@/assets/icon/label.png">{{ enterpriseInformation.industry }}
          </div>

        </div>

        <div class="more-job-list">

          <div class="action" @click="toCompanyDetailed">
            更多此企业的岗位<i class="el-icon-arrow-right"></i>
          </div>

          <div class="list" v-loading="pageLoading3">

            <div class="item" v-for="(item, index) in enterpriseJobList" :key="'enterpriseJobList'+index">
              <like-job-item :job-id="item.jobId" :job-name="item.jobName" :job-address="item.jobAddress"
                             :logo="enterpriseInformation.logo"
                             :salary-range="item.salaryRange" @goJobDetailed="goJobDetailed"></like-job-item>
            </div>

          </div>

        </div>

      </div>

    </div>

    <el-dialog :title="dialogTitle" custom-class="job-detailed-tip-off-dialog" :visible.sync="detailedTipOffDialogShow"
               :before-close="jobDetailedTipOffDialogClose" width="500px">

      <!--      <div class="job-detailed-tip-off-item" @click="jobDetailedTipOffDetailed(1)" v-if="!isShowTipOffDetailed">-->
      <!--        <div class="job-detailed-tip-off-item-left">-->
      <!--          <div class="title">-->
      <!--            人身攻击-->
      <!--          </div>-->
      <!--          <div class="msg">-->
      <!--            招聘中存在-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="job-detailed-tip-off-item-right">-->
      <!--          <i class="el-icon-arrow-right"></i>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="job-detailed-tip-off-item" @click="jobDetailedTipOffDetailed(2)" v-if="!isShowTipOffDetailed">-->
      <!--        <div class="job-detailed-tip-off-item-left">-->
      <!--          <div class="title">-->
      <!--            人身攻击-->
      <!--          </div>-->
      <!--          <div class="msg">-->
      <!--            招聘中存在-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="job-detailed-tip-off-item-right">-->
      <!--          <i class="el-icon-arrow-right"></i>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="tip-off-detailed" v-if="isShowTipOffDetailed">

        <el-form ref="tipOffDetailedFormRef" :model="tipOffDetailedForm" :rules="formRules" label-width="80px">

          <el-form-item label="举报类型" prop="type">
            <el-select v-model="tipOffDetailedForm.type" placeholder="请选择活动区域">
              <el-option :label="item.label" :value="item.value" v-for="(item, index) in reportTypeOptions"
                         :key="'reportTypeOptions' + index"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="举报详情" prop="detailed">
            <el-input type="textarea" v-model="tipOffDetailedForm.detailed"></el-input>
          </el-form-item>

          <el-upload
              name="image"
              :action="uploadAction"
              list-type="picture-card"
              :limit="1"
              :on-success="handleExceedSuccess"
              :before-upload="beforeLegalPersonUpload"
              :on-exceed="handleExceed"
              :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <!--          <el-dialog :visible.sync="tipOffImgVisible">-->
          <!--            <img width="100%" :src="tipOffImgUrl" alt="">-->
          <!--          </el-dialog>-->

          <div class="tip-off-detailed-form-submit-btn">
            <el-button size="mini" @click="jobDetailedTipOffDialogClose">取消</el-button>
            <el-button size="mini" @click="jobDetailedTipOffDialogSubmit">确定</el-button>
          </div>

        </el-form>

      </div>

    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="toMyResumeDialogVisible"
        width="350px">
      <div style="text-align: center">
        <p style="font-size: 20px; font-weight: bold; color: #3a3a3a;">请先去完善简历</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="toMyResumeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toMyResume">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import LikeJobItem from "@/components/jobItem/likeJobItem";
import PageHeader from "@/components/pageHeader/pageHeader";
import RedEnvelopes from "@/components/redEnvelopes/redEnvelopes";
import {
  JobDetailApi,
  GetEnterpriseDetailApi,
  CollectJobRecordApi,
  CancelJobCollectByJobIdApi,
  PostSubmitReportApi,
  GetAllJobListApi,
  EmployeeApplyRedPocketApi, GetEmployeeGetInviteCodeApi, PostEmployeeContactHrApi, isEditedResumeApi,
} from '@/request/api'
import {education, experience, ageRange2, enterpriseScale, financingSituation, reportTypeOptions} from '@/share/shareData'
import {BASE_URL, WEB_BASE_URL} from "@/config/baseURL";
import {mapMutations} from "vuex";
import {JobSeekerGreetings} from "@/share/greetings";
import share from "@/share/share";
import store from "@/store";

export default {
  name: "jobDetailed",
  components: {RedEnvelopes, PageHeader, LikeJobItem},
  data() {
    return {
      pageLoading1: true,
      pageLoading2: true,
      pageLoading3: true,

      currHeaderMenu: 2,
      oldCompanyProfile: '',
      isCompanyProfileMordText: false,
      isShowMordText: false,
      dialogTitle: '选择举报类型',
      detailedTipOffDialogShow: false,
      isShowTipOffDetailed: true,
      reportTypeOptions: reportTypeOptions,
      formRules: {
        type: [
          {required: true, message: '请选择举报类型'},
        ],
        detailed: [
          {required: true, message: '请输入详情'},
        ]
      },
      uploadAction: BASE_URL + '/user-files/upload-image/',
      tipOffDetailedForm: {
        type: '',
        detailed: '',
        imageUrl: '',
      },
      tipOffImgVisible: false,
      tipOffImgUrl: '',
      jobType: 0,
      redPocketPerPerson: 0,
      jobId: '',
      statusDisplay: '',
      academicRequirement: '',
      oldAgeRequirementRange: '',
      oldExperienceRequirementRange: [],
      jobName: '',
      jobAddress: [],
      description: '',
      enterpriseId: '',
      enterpriseInformation: {},
      oldFinancingStatus: '',
      isCollected: false,
      shareId: '',
      shareJobId: '',
      enterpriseJobList: '',
      salaryRange: [],
      hrId: '',
      hrName: '',
      enterpriseName: '',
      jobWelfare: [],
      isClose: false,  // 岗位是否已经关闭

      // myInfo: {
      //   age: '',  // 求职者年龄
      //   education: '',  // 求职者学历
      //   expectedJobName: '',  // 求职者期望职位
      //   expectedPlace: '',  // 求职期望职位
      //   expectedSalaryRange: '',  // 求职者期望薪资
      //   jobSeekerName: '',  // 求职者姓名
      //   schoolName: '',  // 求职者教育经历的学校
      //   workExperience: '',  // 求职者工作经验
      //   employeeId: '',  // 求职者id
      // },

      toMyResumeDialogVisible: false,
      detailAddress: '',
      toSessionId: '',

      invitationCode: '',
      invitationCodeLoading: false,

      btnLoading: false,
    }
  },
  computed: {
    aonuoChat() {
      return this.$store.state.aonuoChat
    },
    newCompanyProfile() {
      let str = ''
      if (this.oldCompanyProfile.length > 340) {
        str = this.oldCompanyProfile.slice(0, 340)
        str += '......'
        this.isCompanyProfileMordText = true
      } else {
        str = this.oldCompanyProfile
        this.isCompanyProfileMordText = false
      }
      return str
    },
    companyProfile() {
      let str = ''
      if (this.isShowMordText) {
        str = this.oldCompanyProfile
      } else {
        str = this.newCompanyProfile
      }
      return str
    },
    experienceRequirementRange() {
      let str = ''
      if (this.oldExperienceRequirementRange.length > 1) {
        let str2 = this.oldExperienceRequirementRange[0] + '-' + this.oldExperienceRequirementRange[1]

        experience.forEach((item, index) => {
          if (item.value === str2) {
            str = item.label + '经验'
          }
        })

      }else {
        str = '经验不限'
      }
      return str
    },
    ageRequirementRange() {
      let str = ''
      if (this.oldAgeRequirementRange && this.oldAgeRequirementRange.length > 1) {
        let str2 = this.oldAgeRequirementRange[0] + '-' + this.oldAgeRequirementRange[1]

        // ageRange2.forEach((item, index) => {
        //   if (item.value === str2) {
        //     str = item.label
        //   }
        // })

        str = this.oldAgeRequirementRange[0] + '岁-' + this.oldAgeRequirementRange[1] + '岁'
      }else {
        str = '年龄不限'
      }
      return str

    },
    financingStatus() {
      let str = ''
      financingSituation.forEach((item, index) => {
        if (item.value === this.oldFinancingStatus) {
          str = item.label
        }
      })
      return str
    },
    place() {
      return this.$store.state.userInfo.place
    },
    shareArea() {
      let placeCode = ''
      if (this.place && this.place.value && this.place.value !== '') {
        placeCode = this.place.value
      }

      if (placeCode === '' && this.areaOptions && this.areaOptions.length > 0) {
        placeCode = this.areaOptions[0].value
      }
      return placeCode
    },
    token() {
      return this.$store.state.token
    },
    shareJobDetailedUrl() {
      // return WEB_BASE_URL + '?share_id=' + encodeURIComponent(this.token) + '&job_id=' + this.jobId + '&share_area=' + this.shareArea + '&to_page=jobDetails'
      let shareId = encodeURIComponent(this.invitationCode)
      return WEB_BASE_URL + '?share_id=' + shareId + '&to_page=jobDetails' + '&job_id=' + this.jobId
    },
    salary() {
      let str = ''
      if (this.salaryRange && this.salaryRange.length > 0) {
        // str = this.salaryRange[0] + '-' + this.salaryRange[1]
        str = parseInt(this.salaryRange[0])/1000 + 'k-' + parseInt(this.salaryRange[1])/1000 + 'k'
      } else {
        str = '面议'
      }
      return str
    },
    enterpriseSizeRange() {
      let str = ''
      let str2 = this.enterpriseInformation.enterpriseSizeRange[0]+ '-' + this.enterpriseInformation.enterpriseSizeRange[1]
      enterpriseScale.forEach((item, index) => {
        if (item.value === str2) {
          str = item.label
        }
      })
      return str
    },
    userType() {
      return this.$store.state.userInfo.userType || ''
    },
    isEditedResume() {
      return this.$store.state.isEditedResume || ''
    },
    isLogin() {
      let state = false
      if (this.token && this.token !== 'null') {
        state = true
      }
      return state
    },
    newEnterpriseLogoUrl() {
      if (this.enterpriseInformation.logo) {
        return share.initImageUrl(this.enterpriseInformation.logo)
      } else {
        return this.enterpriseInformation.logo
      }
    },
  },
  watch: {
    place: {
      handler: function () {
        if (!this.place.value) {
          return
        }
        if (this.isLogin) {
          this.loadEmployeeGetInviteCode()
        }
      },
      immediate: true
    }
  },
  async mounted() {
    this.jobId = this.$route.query.id || ''
    this.statusDisplay = this.$route.query.statusDisplay || ''

    // let shareId = this.$route.query.share_id || ''
    // this.shareId = decodeURIComponent(shareId)
    // this.shareJobId = this.$route.query.share_job_id || ''
    // if (this.shareId && this.shareId !== '' && this.shareId !== null && this.shareId !== 'null') {
    //   this.setShareId({
    //     shareId: this.shareId
    //   })
    // }
    // if (this.shareJobId && this.shareJobId !== '' && this.shareJobId !== null && this.shareJobId !== 'null') {
    //   this.setShareJobId({
    //     shareJobId: this.shareJobId
    //   })
    // }

    this.loadIsEditedResume()
    await this.loadData()
    await this.loadEnterpriseData()
    await this.loadAllJobListData()

  },
  methods: {
    ...mapMutations(['setShareId', 'setShareJobId', 'setCurrentSessionId', 'setChatSessionId', 'setChatSessionInfo', 'setChatSession', 'setIsEditedResume']),
    async loadIsEditedResume() {
      const res = await isEditedResumeApi()

      if (res) {
        this.setIsEditedResume({
          isEditedResume: res.isEditedResume
        })
      }
    },
    async loadEmployeeGetInviteCode() {
      this.invitationCodeLoading = true
      const res = await GetEmployeeGetInviteCodeApi({
        areaCode: this.place.value,
      })

      if (res) {
        this.invitationCode = res.invite_code
      }
      this.invitationCodeLoading = false
    },

    async loadData() {
      this.pageLoading1 = true
      const res = await JobDetailApi({
        jobId: this.jobId
      })

      if (res) {
        this.jobName = res.jobInformation.jobName
        this.jobType = res.jobInformation.jobType
        this.jobAddress = res.jobInformation.jobAddress
        this.redPocketPerPerson = res.jobInformation.redPocketPerPerson
        this.academicRequirement = res.jobInformation.academicRequirement
        this.oldExperienceRequirementRange = res.jobInformation.experienceRequirementRange
        this.description = res.jobInformation.description
        this.enterpriseId = res.enterpriseInformation.enterpriseId
        this.isCollected = res.jobInformation.isCollected
        this.salaryRange = res.jobInformation.salaryRange
        this.hrId = res.hrInformation.hrId
        this.hrName = res.hrInformation.hrName
        this.jobWelfare = res.jobInformation.jobWelfare
        this.oldAgeRequirementRange = res.jobInformation.ageRequirementRange
        this.detailAddress = res.jobInformation.detailAddress
        this.toSessionId = res.hrInformation.sessionId

        this.isClose = false
        if (this.statusDisplay === 'none') {
          this.isClose = false
        }else {
          if (res.jobInformation.status !== 1) {
            this.isClose = true
          }
        }

      }
      this.pageLoading1 = false
    },
    async loadEnterpriseData() {
      this.pageLoading2 = true
      const res2 = await GetEnterpriseDetailApi({
        enterpriseId: this.enterpriseId
      })

      if (res2) {
        this.enterpriseInformation = res2.enterpriseInformation
        this.oldCompanyProfile = res2.enterpriseInformation.enterpriseIntroduction
        this.oldFinancingStatus = res2.enterpriseInformation.financingStatus
        this.enterpriseName = res2.enterpriseInformation.enterprise_name
      }
      this.pageLoading2 = false
    },
    async loadAllJobListData() {
      this.pageLoading3 = true
      const res = await GetAllJobListApi({
        enterpriseId: this.enterpriseId,
        pageNum: 1,
        pageSize: 5,
      })

      if (res) {
        this.enterpriseJobList = res.jobList
      }
      this.pageLoading3 = false
    },
    headerChangeCurrPage(num) {
      this.currHeaderMenu = num
    },
    async goJobDetailed(id) {
      this.jobId = id
      await this.loadData()
      await this.loadEnterpriseData()
      await this.loadAllJobListData()
    },

    showMoreText() {
      this.isShowMordText = true
    },
    closeMoreText() {
      this.isShowMordText = false
    },
    async actionClick(id) {

      if (!this.isLogin && id !== 2) {
        share.message.warning('请登录')
        return
      }

      if (id === 1) {
        this.isCollected = !this.isCollected
        let action = ''
        let res = ''
        if (this.isCollected) {
          action = 'add'
          res = await CollectJobRecordApi({
            action: action,
            jobId: this.jobId
          })
        } else {
          action = 'del'
          res = await CancelJobCollectByJobIdApi({
            action: action,
            jobId: this.jobId
          })
        }


        if(res) {
          share.message.success('操作成功')
        }

      }
      if (id === 2) {
      }
      if (id === 3) {
        this.dialogTitle = '选择举报类型'
        this.detailedTipOffDialogShow = true
      }
    },
    jobDetailedTipOffDetailed(id) {
      this.dialogTitle = '补充举报证据'
      this.isShowTipOffDetailed = true
    },

    handleRemove(file, fileList) {
      this.tipOffDetailedForm.imageUrl = ''
    },
    handleExceed() {
      share.message.error('只允许上传一张图片')
    },
    beforeLegalPersonUpload(file) {
      const fileType = file.type
      const isLt20M = file.size / 1024 / 1024 < 20;

      let isJPGOrPng = true
      if (fileType !== 'image/jpeg' && fileType !== 'image/png') {
        share.message.error('上传图片只能是 JPG/JPEG 或 PGN 格式!')
        isJPGOrPng = false
      }
      if (!isLt20M) {
        share.message.error('上传图片大小不能超过 20MB!')
      }

      return isJPGOrPng && isLt20M
    },
    handleExceedSuccess(file, fileList) {
      this.tipOffDetailedForm.imageUrl = file.data.path
    },

    // handlePictureCardPreview(file) {
    //   this.tipOffImgUrl = file.url;
    //   this.tipOffImgVisible = true;
    // },
    jobDetailedTipOffDialogClose() {
      this.detailedTipOffDialogShow = false
      // setTimeout(() => {
      //   this.isShowTipOffDetailed = false
      // }, 200)
    },
    async jobDetailedTipOffDialogSubmit() {
      let logoPath = share.initImageUrlPath(this.tipOffDetailedForm.imageUrl)

      this.$refs.tipOffDetailedFormRef.validate(async res => {
        if (res) {

          const res2 = await PostSubmitReportApi({
            subject: this.tipOffDetailedForm.type,
            context: this.tipOffDetailedForm.detailed,
            image: logoPath,
          })
          if (res2) {
            share.message.error('举报提交成功')
          }

          this.detailedTipOffDialogShow = false
          // setTimeout(() => {
          // this.isShowTipOffDetailed = false
          // },200)
        }
      })

    },
    goMyResume() {
      this.$router.push({
        path: '/myResume'
      })
    },
    toMyResume() {
      this.$router.push('/myResume')
    },
    async toApplyRedEnvelope() {
      if (!this.isLogin) {
        share.message.warning('请先去登录')
        return;
      }

      if (!this.isEditedResume) {
        this.toMyResumeDialogVisible = true
        return
      }

      this.btnLoading = true

      PostEmployeeContactHrApi({
        jobId: this.jobId
      })

      const res2 = await EmployeeApplyRedPocketApi({
        jobId: this.jobId
      })
      if (res2) {

        const res = await this.aonuoChat.createConversation({
          name: '单聊',
          toUserId: this.toSessionId,
          type: 1,
          jobInformation: JSON.stringify({
            jobId: this.jobId?Number(this.jobId):this.jobId,
            jobName: this.jobName,
          })
        })
        if (res) {
          this.setChatSessionId({
            chatSessionId: res.session.session_id
          })
          this.setChatSessionInfo({
            chatSessionInfo: res.session.session_info,
          })
          this.setChatSession({
            chatSession: res.session
          })

          let obj = {
            fileId: '',
            toSession: res.session.session_id,
            data: '我已向您发起红包申请，请及时处理',
            type: 'apply-red-envelope-text',
          }

          let content = this.aonuoChat.createCustomMessage(obj)

          this.aonuoChat.sendMessage(content)

          setTimeout(() => {
            this.btnLoading = false
            this.$router.push({
              path: '/chatSectionHome',
              query: ''
            })
          }, 500)
        }

      }else {
        this.btnLoading = false
      }
    },
    openContacts() {

      if (!this.isLogin) {
        share.message.warning('请先去登录')
        return;
      }

      if (!this.isEditedResume) {
        this.toMyResumeDialogVisible = true
        return
      }

      PostEmployeeContactHrApi({
        jobId: this.jobId
      })

      share.toChatWindow(this.toSessionId, {jobId: this.jobId, jobName: this.jobName}, '/chatSectionHome')

    },
    toCompanyDetailed() {
      this.$router.push({
        path: '/companyDetailed',
        query: {
          id: this.enterpriseId
        }
      })
    },
    onCopy(val) {
      share.message.success('复制成功')
    },
    onError(val) {
    }
  }
}
</script>

<style lang="less" scoped>
.jobDetailed_warp {
  width: 100%;
  min-height: 100%;
  //min-height: calc(100vh - 20px);
  background: #f4f2f3;
  padding-bottom: 20px;
  min-width: 1400px;

  .page-header {
    width: 100%;
    height: 61px;
  }

  .header {
    width: 100%;
    min-width: 1200px;
    height: 160px;
    background: #ffffff url("../../assets/icon/job-header-bg.png") no-repeat bottom right;
    background-size: 35% 100%;
    margin-bottom: 20px;
    box-shadow: 0 0 15px #dcdbdc;

    .header-main {
      width: 1100px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .title {
          font-size: 20px;
          display: flex;
          align-items: center;

          .span {
            font-size: 30px;
          }

          .span2 {
            font-size: 20px;
            color: #e20000;
            margin-left: 10px;
          }

        }

        .condition {
          font-size: 16px;
          height: 30px;
          line-height: 30px;
          margin-bottom: 5px;
        }

        .tag {
          width: 600px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;

          .el-tag {
            margin-right: 5px;
            margin-bottom: 5px;
            color: #fd1515;
            border-color: #fd1515;
            background-color: #ffffff;
          }

        }

      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .right-btn {
          height: 42px;
          position: relative;
          display: flex;

          .to-chat {
            box-shadow: 0 2px 5px #9a9a9a;
            border-radius: 4px;
          }

          .red-envelopes-icon {
            width: 60px;
            height: 60px;
            position: absolute;
            right: 140px;
            top: -20px;
            z-index: 998;
            transform: rotate(-30deg);
            cursor: pointer;
          }

          .go-my-resume {
            margin-left: 10px;
          }

          .el-button {
            width: 140px;
            font-size: 16px;
            color: #fd1515;

            &:first-child {
              //border: 1px solid #e20000;
              //box-shadow: 0 0 10px #afafb1;
            }

            &:last-child {
              color: #6f7478;
            }

            .icon {
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 30px;
                height: 30px;
                margin-right: 5px;
              }

              &:last-child {
                margin-right: 0px;
              }
            }

          }

        }

        .action {
          width: 130px;
          display: flex;
          justify-content: space-around;
          margin-top: 10px;
          margin-left: 20px;

          .icon {
            width: 20px;
            height: 20px;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
            }

            &:last-child {
              margin-right: 0px;
            }

          }

        }

      }

    }

  }

  .content {
    min-width: 1200px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .card {
      border-radius: 20px;
      background: #ffffff;
    }

    .job-information {
      width: 800px;
      //margin-right: 20px;
      color: #303030;
      padding: 30px 30px;
      box-sizing: border-box;

      .title {
        font-size: 16px;
        font-weight: bold;
        border-left: 2px solid #e20000;
        height: 18px;
        line-height: 18px;
        padding-left: 2px;
      }

      .msg {
        width: 100%;
        margin-top: 10px;
        box-sizing: border-box;
        font-size: 14px;
        margin-bottom: 20px;
        word-wrap: break-word;
        word-break: break-all;

        .description {
          & /deep/ .content-detail {
            line-height: 24px;
            padding: 0;
            white-space: pre-wrap;
            text-indent: -4px;

            .el-button {
              color: #b5b5b5;
              padding: 0;
            }

          }
        }

        .is-close {
          font-size: 18px;
          line-height: 80px;
          box-sizing: border-box;
        }

      }

      .table {
        width: 100%;
        border: 1px solid #b5b5b5;
        border-radius: 5px;
        padding: 20px 20px;
        box-sizing: border-box;

        .name {
          color: #303030;
          height: 40px;

        }

        .main {
          display: flex;
          flex-wrap: wrap;

          > div {
            color: #b5b5b5;
            height: 30px;
            width: 100%;
            display: flex;
            align-items: center;

            span {
              display: inline-block;
              color: #303030;
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
            }

            &:nth-child(1) {
              width: 30%;
              span {
                width: 100px;
              }
            }

            &:nth-child(2) {
              width: 30%;
              span {
                width: 120px;
              }
            }

            &:nth-child(3) {
              width: 30%;
            }

            &:nth-child(5) {
              span {
                width: 500px;
              }
            }

          }

        }

      }

    }

    .company-information {
      width: 380px;

      .information {
        width: 100%;
        padding: 30px 30px;
        box-sizing: border-box;
        color: #3c3c3e;

        .title {
          width: 100%;
          height: 40px;
          font-size: 16px;
          cursor: pointer;
        }

        .name {
          width: 100%;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 16px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;

          & /deep/ .el-avatar {
            height: 80px;
            width: 80px;
            margin-right: 20px;
            background: #ffffff;
          }

        }

        .info-item {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 14px;
          margin-bottom: 5px;

          img {
            max-height: 100%;
            margin-right: 10px;
          }
        }

      }

      .more-job-list {
        width: 100%;
        color: #707070;
        padding-bottom: 30px;

        .action {
          font-size: 14px;
          height: 25px;
          border-bottom: 1px solid #eeeeee;
          padding-left: 30px;
          cursor: pointer;
        }

        .list {
          width: 100%;

          .item {
            height: 100px;
            border-bottom: 1px solid #eeeeee;
            cursor: pointer;
          }

        }

      }

    }

  }

}
</style>
<style lang="less">
.job-detailed-tip-off-dialog {

  .job-detailed-tip-off-item {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 30px;
    box-sizing: border-box;
    border-bottom: 1px solid #e2e4ec;
    cursor: pointer;

    .job-detailed-tip-off-item-left {

      .title {
        color: #464f65;
      }

      .msg {
        color: #898f9e;
      }

    }

  }

  .tip-off-detailed {
    .tip-off-detailed-form-submit-btn {
      height: 60px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

}

</style>
