<template>
  <div class="recruitmentData_warp">

    <div class="recruitmentData-left">

      <div class="header">
        <span>招聘职位</span>
<!--        <el-select v-model="jobFilter" placeholder="请选择" size="mini" @change="jobFilterChange">-->
<!--          <el-option-->
<!--              v-for="(item, index) in jobValueOptions"-->
<!--              :key="'jobValueOptions' + index"-->
<!--              :label="item.label"-->
<!--              :value="item.value">-->
<!--          </el-option>-->
<!--        </el-select>-->
        <lazy-select :options="jobValueOptions" placeholder="请选择" :new-value="jobFilter" :no-more="jobOptionsNoMore" @loadData="loadMoreMyPublishedJob" @change="jobFilterChange"></lazy-select>
      </div>

      <div class="content">

        <div class="left">
          <div class="tab tab-checked">
            昨日数据
          </div>
        </div>

        <div class="right">

          <div class="card">
            <div class="header">
              昨日数据
            </div>

            <div class="main">

              <div class="main-item">
                <div class="main-item-warp">
                  <div class="main-item-title">
                    看过我
                  </div>
                  <div class="main-item-content">
                    <!--                    <div class="top">-->
                    <!--                      <img src="../../assets/icon/report-icon2.png">-->
                    <!--                      <span v-if="weekData.seeMeEmployeeCount.compareNumber>=0" class="red">↑</span>-->
                    <!--                      <span v-if="weekData.seeMeEmployeeCount.compareNumber<0" class="blue">↓</span>-->
                    <!--                      较上周<span-->
                    <!--                        :class="weekData.seeMeEmployeeCount.compareNumber>=0?'red':'blue'">{{ weekData.seeMeEmployeeCount.compareNumber }}</span>-->
                    <!--                    </div>-->
                    <div class="number">
                      {{ weekData.seeMeEmployeeCount.total }}
                    </div>
                  </div>
                  <!--                  <div class="main-item-action">-->
                  <!--                    <el-button size="mini">查看</el-button>-->
                  <!--                  </div>-->
                </div>
              </div>

              <div class="main-item">
                <div class="main-item-warp">
                  <div class="main-item-title">
                    我看过
                  </div>
                  <div class="main-item-content">
                    <!--                    <div class="top">-->
                    <!--                      <img src="../../assets/icon/report-icon1.png">-->
                    <!--                      <span v-if="weekData.seeEmployeeCount.compareNumber>=0" class="red">↑</span>-->
                    <!--                      <span v-if="weekData.seeEmployeeCount.compareNumber<0" class="blue">↓</span>-->
                    <!--                      较上周<span-->
                    <!--                        :class="weekData.seeEmployeeCount.compareNumber>=0?'red':'blue'">{{ weekData.seeEmployeeCount.compareNumber }}</span>-->
                    <!--                    </div>-->
                    <div class="number">
                      {{ weekData.seeEmployeeCount.total }}
                    </div>
                  </div>
                  <!--                  <div class="main-item-action">-->
                  <!--                    <el-button size="mini">查看</el-button>-->
                  <!--                  </div>-->
                </div>
              </div>

              <div class="main-item">
                <div class="main-item-warp">
                  <div class="main-item-title">
                    主动联系我
                  </div>
                  <div class="main-item-content">
<!--                    <div class="top">-->
<!--                      <img src="../../assets/icon/report-icon6.png">-->
<!--                      <span v-if="weekData.contactMeEmployeeCount.compareNumber>=0" class="red">↑</span>-->
<!--                      <span v-if="weekData.contactMeEmployeeCount.compareNumber<0" class="blue">↓</span>-->
<!--                      较上周<span-->
<!--                        :class="weekData.contactMeEmployeeCount.compareNumber>=0?'red':'blue'">{{ weekData.contactMeEmployeeCount.compareNumber }}</span>-->
<!--                    </div>-->
                    <div class="number">
                      {{ weekData.contactMeEmployeeCount.total }}
                    </div>
                  </div>
                  <!--                  <div class="main-item-action">-->
                  <!--                    <el-button size="mini">查看</el-button>-->
                  <!--                  </div>-->
                </div>
              </div>
              <div class="main-item">
                <div class="main-item-warp">
                  <div class="main-item-title">
                    沟通过
                  </div>
                  <div class="main-item-content">
<!--                    <div class="top">-->
<!--                      <img src="../../assets/icon/report-icon3.png">-->
<!--                      <span v-if="weekData.communityEmployeeCount.compareNumber>=0" class="red">↑</span>-->
<!--                      <span v-if="weekData.communityEmployeeCount.compareNumber<0" class="blue">↓</span>-->
<!--                      较上周<span-->
<!--                        :class="weekData.communityEmployeeCount.compareNumber>=0?'red':'blue'">{{ weekData.communityEmployeeCount.compareNumber }}</span>-->
<!--                    </div>-->
                    <div class="number">
                      {{ weekData.communityEmployeeCount.total }}
                    </div>
                  </div>
                  <!--                  <div class="main-item-action">-->
                  <!--                    <el-button size="mini">查看</el-button>-->
                  <!--                  </div>-->
                </div>
              </div>
              <div class="main-item">
                <div class="main-item-warp">
                  <div class="main-item-title">
                    面试邀请
                  </div>
                  <div class="main-item-content">
<!--                    <div class="top">-->
<!--                      <img src="../../assets/icon/report-icon4.png">-->
<!--                      <span v-if="weekData.inviteInterviewCount.compareNumber>=0" class="red">↑</span>-->
<!--                      <span v-if="weekData.inviteInterviewCount.compareNumber<0" class="blue">↓</span>-->
<!--                      较上周<span-->
<!--                        :class="weekData.inviteInterviewCount.compareNumber>=0?'red':'blue'">{{ weekData.inviteInterviewCount.compareNumber }}</span>-->
<!--                    </div>-->
                    <div class="number">
                      {{ weekData.inviteInterviewCount.total }}
                    </div>
                  </div>
                  <!--                  <div class="main-item-action">-->
                  <!--                    <el-button size="mini">查看</el-button>-->
                  <!--                  </div>-->
                </div>
              </div>

              <div class="main-item">
                <div class="main-item-warp">
                  <div class="main-item-title">
                    红包申请
                  </div>
                  <div class="main-item-content">
<!--                    <div class="top">-->
<!--                      <img src="../../assets/icon/report-icon5.png">-->
<!--                      <span v-if="weekData.applyRedPocketEmployeeCount.compareNumber>=0" class="red">↑</span>-->
<!--                      <span v-if="weekData.applyRedPocketEmployeeCount.compareNumber<0" class="blue">↓</span>-->
<!--                      较上周<span-->
<!--                        :class="weekData.applyRedPocketEmployeeCount.compareNumber>=0?'red':'blue'">{{ weekData.applyRedPocketEmployeeCount.compareNumber }}</span>-->
<!--                    </div>-->
                    <div class="number">
                      {{ weekData.applyRedPocketEmployeeCount.total }}
                    </div>
                  </div>
                  <!--                  <div class="main-item-action">-->
                  <!--                    <el-button size="mini">查看</el-button>-->
                  <!--                  </div>-->
                </div>
              </div>

            </div>
          </div>

          <div class="card2">
            <div class="header">
              <div class="title">
                近期趋势
              </div>
              <div class="filter">
                <el-checkbox v-model="nearlySevenDays" @change="nearlySevenDaysChange">近七天</el-checkbox>
                <el-checkbox v-model="nearlyThirtyDays" @change="nearlyThirtyDaysChange">近一个月</el-checkbox>
              </div>
              <div class="change-week">
                <el-date-picker
                    v-model="datePicker"
                    type="daterange"
                    size="medium"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                    :clearable="false"
                    @change="datePickerChange"
                >
                </el-date-picker>
              </div>
            </div>

            <div class="main" style="width: 670px; height:420px;" v-loading="myChartLoading">

              <div v-if="myChartIsShow" id="myChart" class="echarts-table-warp" style="width: 650px; height:400px;">
              </div>

            </div>
          </div>

        </div>

      </div>

    </div>

    <div class="recruitmentData-right">
      <dynamic-entry @goEnterpriseDynamic="goEnterpriseDynamic"></dynamic-entry>
    </div>

  </div>
</template>

<script>
import DynamicEntry from "@/components/dynamicEntry/dynamicEntry";
import {
  MyPublishedJobApi,
  GetHrRecruitmentStatisticsWeekDataApi,
  GetHrRecruitmentStatisticsRecentTrendApi,
  GetHrRecruitmentStatisticsMonthDataApi, GetHrRecruitmentStatisticsDayDataApi, GetJobEditJobApi
} from "@/request/api";
import LazySelect from "@/components/lazySelect/lazySelect";

export default {
  name: "recruitmentData",
  components: {LazySelect, DynamicEntry},
  data() {
    return {
      jobFilter: '',
      jobOptionsNoMore: true,
      jobValueOptions: [],
      jobOptionsPage: {
        pageNumber: 1,
        pageSize: 20,
      },
      jobList: [],
      myChartIsShow: false,
      myChartLoading: false,
      chartOption1: {  // eChart的Options
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['看过我', '我看过', '主动联系我', '沟通过', '面试邀请', '红包申请']
        },
        grid: {
          left: '20px',
          right: '40px',
          bottom: '20px',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          // data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            key: 'seeMeEmployeeCountList',
            name: '看过我',
            type: 'line',
            data: []
          },
          {
            key: 'seeEmployeeCountList',
            name: '我看过',
            type: 'line',
            data: []
          },
          {
            key: 'contactMeEmployeeCountList',
            name: '主动联系我',
            type: 'line',
            data: []
          },
          {
            key: 'communityEmployeeCountList',
            name: '沟通过',
            type: 'line',
            data: []
          },
          {
            key: 'inviteInterviewCountList',
            name: '面试邀请',
            type: 'line',
            data: []
          },
          {
            key: 'applyRedPocketEmployeeCountList',
            name: '红包申请',
            type: 'line',
            data: []
          }
        ]
      },
      weekData: {
        seeEmployeeCount: {  // 我看过的求职者数量
          total: '',  // 总数
          compareNumber: '',  // 较上周
        },
        seeMeEmployeeCount: {  // 看过我的求职者数量
          total: '',  // 总数
          compareNumber: '',  // 较上周
        },
        contactMeEmployeeCount: {  // 主动联系我的求职者数量
          total: '',  // 总数
          compareNumber: '',  // 较上周
        },
        communityEmployeeCount: {  // 沟通过的求职者数量
          total: '',  // 总数
          compareNumber: '',  // 较上周
        },
        inviteInterviewCount: {  // 发送面试邀请的求职者数量
          total: '',  // 总数
          compareNumber: '',  // 较上周
        },
        applyRedPocketEmployeeCount: {  // 申请了红包的求职者数量
          total: '',  // 总数
          compareNumber: '',  // 较上周
        }
      },
      datePicker: [],
      finDatePicker: [],
      nearlySevenDays: false,
      nearlyThirtyDays: false,
      disabledDate: Date.now(),
      enableDisableDate: false,
      pickerOptions: {},
    }
  },
  computed: {
  },
  async mounted() {
    await this.loadMyPublishedJob()
    if (this.jobValueOptions.length > 0) {
      this.jobFilter = this.jobValueOptions[0].value
    } else {
      this.jobFilter = ''
    }
    this.initDataRange()

    await this.loadBeforeDateJobData()
    await this.loadDateRangData()
    this.drawLine()
  },
  methods: {
    async loadMyPublishedJob() {
      const res = await MyPublishedJobApi({
        pageNum: this.jobOptionsPage.pageNumber,
        pageSize: this.jobOptionsPage.pageSize,
        jobStatus: 3,
      })
      if (res) {
        if (this.jobOptionsPage.pageNumber >= res.numOfPages) {
          this.jobOptionsNoMore = true
        }else {
          this.jobOptionsNoMore = false
        }
        this.jobList = res.jobList
        let newArr = []
        let arr = res.jobList
        arr.forEach((item, index) => {
          let obj = {
            label: item.jobName,
            value: item.jobId
          }
          newArr.push(obj)
        })
        this.jobValueOptions = newArr
      }
      this.jobOptionsPage.pageNumber++
    },
    async loadMoreMyPublishedJob(callback) {
      if (this.jobOptionsNoMore) {
        return;
      }
      const res = await MyPublishedJobApi({
        pageNum: this.jobOptionsPage.pageNumber,
        pageSize: this.jobOptionsPage.pageSize,
        jobStatus: 3,
      })
      if (res) {
        if (this.jobOptionsPage.pageNumber >= res.numOfPages) {
          this.jobOptionsNoMore = true
        }else {
          this.jobOptionsNoMore = false
        }
        this.jobList = this.jobList.concat(res.jobList)
        let newArr = []
        let arr = res.jobList
        arr.forEach((item, index) => {
          let obj = {
            label: item.jobName,
            value: item.jobId
          }
          newArr.push(obj)
        })
        this.jobValueOptions = this.jobValueOptions.concat(newArr)
      }
      this.jobOptionsPage.pageNumber++
      callback()
    },
    async initDataRange() {
      if (this.jobFilter !== undefined && this.jobFilter !== '') {
        let jobPromoteInfo = null

        const res = await GetJobEditJobApi({
          jobId: this.jobFilter
        })

        jobPromoteInfo = res.jobInformation

        if (jobPromoteInfo === null) {
          return
        }
        let nowDate = new Date()
        let endDateStr = nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getDate()
        let finStartDateStr = ''
        let startDate = new Date(jobPromoteInfo.publishTime)
        let startDateStr = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate()
        let startDate2 = new Date(nowDate.setDate(nowDate.getDate() - 30))
        let startDateStr2 = startDate2.getFullYear() + '-' + (startDate2.getMonth() + 1) + '-' + startDate2.getDate()
        if (startDate.getTime() > startDate2.getTime()) {
          finStartDateStr = startDateStr
        }else {
          finStartDateStr = startDateStr2
        }

        this.pickerOptions = {
          disabledDate: (time) => {
            if (time.getTime() > (Date.now())) {
              return true
            } else if (this.enableDisableDate) {
              return ((time.getTime() < (this.disabledDate - 30 * 24 * 60 * 60 * 1000)) || (time.getTime() > (this.disabledDate + 30 * 24 * 60 * 60 * 1000)))
            }else {
              return false
            }
          },
          onPick: ({minDate, maxDate}) => {
            if (minDate && maxDate) {
              this.disabledDate = Date.now()
              this.enableDisableDate = false
            }else {
              this.disabledDate = minDate.getTime()
              this.enableDisableDate = true
            }
          }
        }
        this.datePicker = [finStartDateStr, endDateStr]
        this.finDatePicker = [finStartDateStr, endDateStr]
      }else {
        this.pickerOptions = {}
        this.datePicker = []
        this.finDatePicker = []
      }
    },
    getBeforeDate() {
      let date = new Date()
      date.setDate(date.getDate() - 1)
      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    },
    async loadBeforeDateJobData() {
      let date = this.getBeforeDate()
      const res = await GetHrRecruitmentStatisticsDayDataApi({
        jobId: this.jobFilter,
        date: date,
      })

      if (res) {
        this.weekData.seeEmployeeCount = res.seeEmployeeCount
        this.weekData.seeMeEmployeeCount = res.seeMeEmployeeCount
        this.weekData.contactMeEmployeeCount = res.contactMeEmployeeCount
        this.weekData.communityEmployeeCount = res.communityEmployeeCount
        this.weekData.inviteInterviewCount = res.inviteInterviewCount
        this.weekData.applyRedPocketEmployeeCount = res.applyRedPocketEmployeeCount
      }
    },
    async nearlySevenDaysChange() {
      this.nearlyThirtyDays = false
      if (this.nearlySevenDays) {
        let nowDate = new Date()
        let endDateStr = nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getDate()
        let startDate = new Date(nowDate.setDate(nowDate.getDate() - 6))
        let startDateStr = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate()
        this.finDatePicker = [startDateStr, endDateStr]
      }else {
        this.finDatePicker = [...this.datePicker]
      }
      await this.loadDateRangData()
      this.$nextTick(() => {
        this.drawLine()
      })
    },
    async nearlyThirtyDaysChange() {
      this.nearlySevenDays = false
      if (this.nearlyThirtyDays) {
        let nowDate = new Date()
        let endDateStr = nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getDate()
        let startDate = new Date(nowDate.setDate(nowDate.getDate() - 31))
        let startDateStr = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate()
        this.finDatePicker = [startDateStr, endDateStr]
      }else {
        this.finDatePicker = [...this.datePicker]
      }
      await this.loadDateRangData()
      this.$nextTick(() => {
        this.drawLine()
      })
    },
    async datePickerChange() {
      this.nearlySevenDays = false
      this.nearlyThirtyDays = false
      this.finDatePicker = [...this.datePicker]
      await this.loadDateRangData()
      this.$nextTick(() => {
        this.drawLine()
      })
    },
    async loadDateRangData() {
      this.myChartLoading = true
      const res = await GetHrRecruitmentStatisticsRecentTrendApi({
        jobId: this.jobFilter,
        startDate: this.finDatePicker[0],
        endDate: this.finDatePicker[1],
      })

      this.myChartLoading = false
      if (res) {
        this.chartOption1.xAxis.data = res.dateList
        this.chartOption1.series.forEach((item, index) => {
          if (item.key === 'seeEmployeeCountList') {
            item.data = res.seeEmployeeCountList
          }
          if (item.key === 'seeMeEmployeeCountList') {
            item.data = res.seeMeEmployeeCountList
          }
          if (item.key === 'contactMeEmployeeCountList') {
            item.data = res.contactMeEmployeeCountList
          }
          if (item.key === 'communityEmployeeCountList') {
            item.data = res.communityEmployeeCountList
          }
          if (item.key === 'inviteInterviewCountList') {
            item.data = res.inviteInterviewCountList
          }
          if (item.key === 'applyRedPocketEmployeeCountList') {
            item.data = res.applyRedPocketEmployeeCountList
          }
        })
      }
    },
    drawLine() {
      this.myChartIsShow = false
      this.$nextTick(() => {
        this.myChartIsShow = true
        this.$nextTick(() => {
          // 基于准备好的dom，初始化echarts实例
          let myChart = this.$echarts.init(document.getElementById('myChart'))
          // 绘制图表
          myChart.setOption(this.chartOption1)
        })
      })
    },
    goEnterpriseDynamic(key, index) {
      this.$router.push({
        path: key,
        query: {
          index: index
        }
      })
    },
    async jobFilterChange(value) {
      this.jobFilter = value
      this.initDataRange()
      await this.loadBeforeDateJobData()
      await this.loadDateRangData()
      this.$nextTick(() => {
        this.drawLine()
      })
    },

  }
}
</script>

<style lang="less" scoped>
.recruitmentData_warp {
  width: 100%;
  //height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #f4f2f3;

  .recruitmentData-left {
    width: 780px;
    //height: 100%;

    .header {
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #efefef;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 20px;
      box-sizing: border-box;
      background-color: #ffffff;

      span {
        margin-right: 10px;
        color: #333333;
        font-size: 16px;
      }
    }

    .content {
      padding: 20px 20px;
      box-sizing: border-box;
      background-color: #f4f2f3;
      display: flex;
      align-items: flex-start;

      .left {
        width: 50px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;

        .tab {
          width: 50px;
          height: 100px;
          background-color: #f9f9f9;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          writing-mode: vertical-lr;
          color: #9f9f9f;
          cursor: pointer;
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
          font-weight: bold;
          z-index: 997;
        }

        .tab-checked {
          color: #e20000;
          background-color: #ffffff;

          z-index: 999;
        }

      }

      .right {
        flex: 1;
        z-index: 998;

        .card {
          margin-bottom: 20px;
          width: 100%;
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
          border-top-right-radius: 5px;
          background-color: #ffffff;
          z-index: 998;
          box-shadow: 0 5px 5px #c6c1c1;
          padding: 10px 10px;
          box-sizing: border-box;

          .header {
            border-bottom: 1px solid #e20000;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;

            .change-week {
              display: flex;
              align-items: center;
              justify-content: flex-end;

              > div {
                margin: 0 10px;
              }

              i {
                cursor: pointer;
              }
            }
          }

          .main {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 10px 10px;
            box-sizing: border-box;

            .main-item {
              width: 25%;
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;

              .main-item-warp {
                width: 120px;
                height: 120px;
                background-color: #ffffff;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .main-item-title {
                  font-size: 14px;
                  margin-bottom: 5px;
                }

                .main-item-content {
                  .top {
                    font-size: 12px;
                    color: #6c6c6c;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                      width: 20px;
                      height: 20px;
                    }

                    span {
                      padding: 0 2px;
                    }

                    .blue {
                      color: #51a7e6;
                    }

                    .red {
                      color: #e42525;
                    }
                  }

                  .number {
                    text-align: center;
                    color: #212121;
                    font-size: 14px;
                    font-weight: bold;
                    margin-top: 5px;
                  }
                }

                //.main-item-action {
                //  text-align: center;
                //  margin-top: 5px;
                //}


              }
            }
          }

        }

        .card2 {
          margin-bottom: 20px;
          width: 100%;
          //border-bottom-right-radius: 5px;
          //border-bottom-left-radius: 5px;
          //border-top-right-radius: 5px;
          //border-top-left-radius: 5px;
          border-radius: 5px;
          background-color: #ffffff;
          z-index: 998;
          box-shadow: 0 5px 5px #c6c1c1;
          padding: 10px 10px;
          box-sizing: border-box;

          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #e20000;

            .title {
              font-size: 16px;
            }

            .filter {
              font-size: 14px;
            }

            .change-week {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              font-size: 14px;

              > div {
                margin: 0 10px;
              }
            }
          }

          .main {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding: 10px 0px;
            box-sizing: border-box;
          }
        }

      }

    }

  }

  .recruitmentData-right {
    flex: 1;
    //min-height: 600px;
    margin-left: 10px;
    background-color: #f4f2f3;
  }
}
</style>
