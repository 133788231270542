<template>
  <div class="companyDetailed_warp">

    <div class="page-header">
<!--      <page-header :curr-page="currHeaderMenu" @actionClick="headerActionClick" @changeCurrPage="headerChangeCurrPage"-->
<!--                   backgroundColor="#f4f2f3"></page-header>-->
      <page-header :curr-page="currHeaderMenu" @changeCurrPage="headerChangeCurrPage"
                   backgroundColor="#f4f2f3"></page-header>
    </div>

    <div class="header">

      <div class="main">
        <div class="header-left">

          <div class="left">
            <el-avatar shape="square" size="large" :src="newEnterpriseLogoUrl"></el-avatar>
          </div>

          <div class="right">
            <div class="name">
              {{ enterpriseInformation.enterprise_name }}
            </div>
            <div class="desc">
              <span
                  v-if="enterpriseInformation.enterpriseSizeRange">{{enterpriseSizeRange}}</span>
              <span>{{ enterpriseInformation.industry }}</span>
            </div>
            <div class="tab">
              <el-tabs v-model="currPage" @tab-click="changePage">
                <el-tab-pane label="企业简介" name="1"></el-tab-pane>
                <el-tab-pane :label="'全部岗位(' + allJobListLength + ')'" name="2"></el-tab-pane>
                <el-tab-pane :label="'红包岗位(' + redEnvelopeJobListLength + ')'" name="3"></el-tab-pane>
<!--                <el-tab-pane :label="'普通岗位(' + hotJobListLength + ')'" name="4"></el-tab-pane>-->
              </el-tabs>
            </div>
          </div>

        </div>

        <div class="header-right">

          <div class="number">{{allJobListLength}}</div>
          <div class="text">在招岗位</div>

        </div>
      </div>

    </div>

    <!-- 企业详情-->
    <div class="content1" v-if="currPage === '1'">

      <div class="card company-information">

        <div class="title">
          企业介绍
        </div>

        <div class="msg">

          <div class="description">
            <div class="content-detail">
              {{ companyProfile }}
              <el-button v-if="!isShowMordText && isCompanyProfileMordText" type="text" @click="showMoreText">
                展开 <i class="el-icon-arrow-down"></i>
              </el-button>
              <el-button v-if="isShowMordText && isCompanyProfileMordText" type="text" @click="closeMoreText">
                收起 <i class="el-icon-arrow-up"></i>
              </el-button>
            </div>
          </div>

        </div>

        <div class="title">
          工商信息
        </div>

        <div class="msg table">
          <div class="name">
            {{ enterpriseInformation.enterprise_name }}
          </div>

          <div class="main">

            <div>
              法定代表人：<span class="legal-representative">{{ enterpriseInformation.legalPerson }}</span>
            </div>

            <div>
              注册资本：<span>{{ enterpriseInformation.registeredCapital }}万人民币</span>
            </div>

            <div>
              成立日期：<span>{{ enterpriseInformation.establishDate }}</span>
            </div>

            <div>
              企业类型：<span>{{ enterpriseInformation.enterpriseProperty }}</span>
            </div>

            <!--            <div>-->
            <!--              经营状态：<span>存续</span>-->
            <!--            </div>-->

            <div>
              企业地址：
              <span v-if="enterpriseInformation.enterpriseAddress">
                {{ enterpriseInformation.enterpriseAddress[0] }}{{ enterpriseInformation.enterpriseAddress[1] }}{{ enterpriseInformation.enterpriseAddress[2] }}{{ enterpriseInformation.detailAddress }}
              </span>
            </div>

            <!--            <div>-->
            <!--              统一社会信用代码：<span>xxxxxxxxx</span>-->
            <!--            </div>-->

            <div>
              行业：<span>{{ enterpriseInformation.industry }}</span>
            </div>

          </div>

        </div>

      </div>

      <div class="company-other">

        <div class="card address">
          <div class="title">
            企业地址<img src="@/assets/icon/landmark.png">
          </div>

          <div class="text" v-if="enterpriseInformation.enterpriseAddress">
            {{ enterpriseInformation.enterpriseAddress[0] }}{{ enterpriseInformation.enterpriseAddress[1] }}{{ enterpriseInformation.enterpriseAddress[2] }}{{ enterpriseInformation.detailAddress }}
          </div>

          <div class="map" v-if="Open_Map" id="company-address-map-container">
          </div>

        </div>

        <div class="card album">

          <div class="title">
            企业相册
          </div>

          <div class="main">
            <el-carousel trigger="click" height="150px">
              <el-carousel-item v-for="(item, index) in albumList" :key="'albumList' + index">
                <div class="img" @click="previewAlbumImg(item.src, item.type)">
                  <img v-if="item.type === 'photo'" :src="item.src"/>
                  <video width="320" height="150" v-if="item.type === 'video'" :src="item.src">
                    您的浏览器不支持 video 标签。
                  </video>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>

          <el-dialog title="相册" :visible.sync="previewAlbumImgIsShow" custom-class="previewAlbumImgDialog">
            <img v-if="previewType === 'photo'" width="100%" :src="previewAlbumImgUrl" alt="">
            <video v-if="previewType === 'video'" style="max-width: 100%;" controls autoplay :src="previewAlbumImgUrl">
              您的浏览器不支持 video 标签。
            </video>
          </el-dialog>

        </div>

      </div>

    </div>

    <div class="content2" v-if="currPage!=='1'">
      <company-published-job
          :history-job-list="historyJobList"
          :company-id="companyId"
          :curr-page="currPage"
          title="所有在招岗位"
      ></company-published-job>
    </div>

  </div>
</template>

<script>
import {MP} from "@/components/pageHeader/loadBMap";
import CompanyPublishedJob from "@/components/companyPublishedJob/companyPublishedJob";
import PageHeader from "@/components/pageHeader/pageHeader";
import {
  GetEnterpriseDetailApi,
  GetAllJobListApi,
  SeeJobRecordApi,
  GetNormalJobListApi,
  GetRedPocketJobListApi, isEditedResumeApi
} from "@/request/api";
import {enterpriseScale} from "@/share/shareData";
import {ak_web} from '@/share/shareData'
import {Open_Map} from "@/config/config";
import share from "@/share/share";
import {mapMutations} from "vuex";

export default {
  name: "companyDetailed",
  components: {PageHeader, CompanyPublishedJob},
  data() {
    return {
      Open_Map: Open_Map,
      currHeaderMenu: 2,
      ak_web: ak_web,  //秘钥
      currPage: '1',
      oldCompanyProfile: '',
      isCompanyProfileMordText: false,
      isShowMordText: false,

      point: '',
      marker: '',
      map: '', //地图实例
      mk: '', //Marker实例

      previewAlbumImgIsShow: false,
      previewAlbumImgUrl: '',
      albumList: [],

      allJobListLength: 0,
      redEnvelopeJobListLength: 0,
      hotJobListLength: 0,

      historyJobList: [],
      companyId: '',
      enterpriseInformation: {},
      previewType: 'photo',

    }
  },
  computed: {
    newEnterpriseLogoUrl() {
      if (this.enterpriseInformation.logo) {
        return share.initImageUrl(this.enterpriseInformation.logo)
      } else {
        return this.enterpriseInformation.logo
      }
    },
    enterpriseSizeRange() {
      let str = ''
      let str2 = this.enterpriseInformation.enterpriseSizeRange[0]+ '-' + this.enterpriseInformation.enterpriseSizeRange[1]
      enterpriseScale.forEach((item, index) => {
        if (item.value === str2) {
          str = item.label
        }
      })
      return str
    },
    newCompanyProfile() {
      let str = ''
      if (this.oldCompanyProfile.length > 340) {
        str = this.oldCompanyProfile.slice(0, 340)
        str += '......'
        this.isCompanyProfileMordText = true
      } else {
        str = this.oldCompanyProfile
        this.isCompanyProfileMordText = false
      }
      return str
    },
    companyProfile() {
      let str = ''
      if (this.isShowMordText) {
        str = this.oldCompanyProfile
      } else {
        str = this.newCompanyProfile
      }
      return str
    },
    isLogin() {
      let token = this.$store.state.token
      let state = false
      if (token && token !== '' && token !== null && token !== 'null') {
        state = true
      }
      return state
    },
    BMap() {
      return this.$store.state.BMap
    }
  },
  watch: {
    currPage() {
      if (Open_Map && this.currPage === '1') {
        this.$nextTick(() => {
          this.initMap()
        })
      }
    },
  },
  beforeCreate() {
    window._bd_share_main = ''
  },
  async mounted() {
    this.companyId = this.$route.query.id || ''
    this.loadIsEditedResume()
    await this.loadData()
    await this.loadHistoryJobData()
    if (Open_Map) {
      this.$nextTick(() => {
        this.initMap()
      })
    }
  },
  methods: {
    ...mapMutations(['setIsEditedResume']),
    async loadIsEditedResume() {
      const res = await isEditedResumeApi()

      if (res) {
        this.setIsEditedResume({
          isEditedResume: res.isEditedResume
        })
      }
    },
    async loadData() {
      const res = await GetEnterpriseDetailApi({
        enterpriseId: this.companyId
      })

      if (res) {
        this.enterpriseInformation = res.enterpriseInformation
        this.oldCompanyProfile = res.enterpriseInformation.enterpriseIntroduction
        let enterpriseVideoList = res.enterpriseInformation.enterpriseVideoList
        let enterprisePhotoList = res.enterpriseInformation.enterprisePhotoList
        enterpriseVideoList = this.inAlbumList(enterpriseVideoList, 'video')
        enterprisePhotoList = this.inAlbumList(enterprisePhotoList, 'photo')
        this.albumList = enterprisePhotoList.concat(enterpriseVideoList)
      }

      const res2 = await GetAllJobListApi({
        enterpriseId: this.companyId,
        jobAddress: '',
        pageNum: 1,
        pageSize: 5
      })

      if (res2) {
        this.allJobListLength = res2.numOfJobs
      }
      const res3 = await GetNormalJobListApi({
        enterpriseId: this.companyId,
        jobAddress: '',
        pageNum: 1,
        pageSize: 5
      })

      if (res3) {
        this.hotJobListLength= res3.numOfJobs
      }
      const res4 = await GetRedPocketJobListApi({
        enterpriseId: this.companyId,
        jobAddress: '',
        pageNum: 1,
        pageSize: 5
      })
      if (res4) {
        this.redEnvelopeJobListLength = res4.numOfJobs
      }
    },
    async loadHistoryJobData() {

      if (!this.isLogin) {
        return
      }

      const res5 = await SeeJobRecordApi({
        pageNum: 1,
        pageSize: 5
      })
      if (res5) {
        this.historyJobList = res5.records
      }
    },
    inAlbumList(arr, type) {
      let newArr = JSON.parse(JSON.stringify(arr))
      if (type === 'video') {
        newArr.forEach((item, index) => {
          item.src = item.video
          item.type = type
        })
      }
      if (type === 'photo') {
        newArr.forEach((item, index) => {
          item.src = item.photo
          item.type = type
        })
      }
      return newArr
    },
    headerChangeCurrPage(num) {
      this.currHeaderMenu = num
    },

    async initMap() {
      let address = this.enterpriseInformation.enterpriseAddress[0] + this.enterpriseInformation.enterpriseAddress[1] + this.enterpriseInformation.enterpriseAddress[2] + this.enterpriseInformation.detailAddress

      if (this.BMap) {
        this.map = new this.BMap.Map("company-address-map-container", {enableMapClick: false, mapType: BMAP_NORMAL_MAP})  //新建地图实例，enableMapClick:false ：禁用地图默认点击弹框
        this.map.enableScrollWheelZoom(false)
        let point = new this.BMap.Point(120.127401, 30.288469)
        this.map.centerAndZoom(point, 18)
        let myGeo = new this.BMap.Geocoder()
        myGeo.getPoint(address, (point) => {
          this.point = point
          this.marker = new this.BMap.Marker(point)
          // this.createMap()
          this.map.centerAndZoom(this.point, 19)
          this.map.addOverlay(this.marker)
        })
      }else {
        MP(this.ak_web)
          .then(BMap => {
            // this.map = new BMap.Map("company-address-map-container", {enableMapClick: false})  //新建地图实例，enableMapClick:false ：禁用地图默认点击弹框
            this.map = new BMap.Map("company-address-map-container", {enableMapClick: false, mapType: BMAP_NORMAL_MAP})  //新建地图实例，enableMapClick:false ：禁用地图默认点击弹框
            this.map.enableScrollWheelZoom(false)  // 开启鼠标滚轮缩放

            let point = new BMap.Point(120.127401, 30.288469)
            this.map.centerAndZoom(point, 18)   // 初始化地图,设置中心点坐标和地图级别

            let myGeo = new BMap.Geocoder()
            myGeo.getPoint(address, (point) => {
              this.point = point
              this.marker = new BMap.Marker(point)
              // this.createMap()
              this.map.centerAndZoom(this.point, 19)
              this.map.addOverlay(this.marker)
            })

          })
      }

    },
    createMap() {
      this.map.centerAndZoom(this.point, 19)
      this.map.addOverlay(this.marker)
    },

    showMoreText() {
      this.isShowMordText = true
    },
    closeMoreText() {
      this.isShowMordText = false
    },

    previewAlbumImg(url, type) {
      this.previewType = type
      this.previewAlbumImgUrl = url
      this.previewAlbumImgIsShow = true
    },
    changePage() {

    }

  }
}
</script>

<style lang="less" scoped>
.companyDetailed_warp {
  width: 100%;
  min-height: 100%;
  background-color: #f4f2f3;
  //min-height: calc(100vh - 20px);
  padding-bottom: 20px;
  min-width: 1400px;

  .page-header {
    width: 100%;
    height: 61px;
  }

  .header {
    width: 100%;
    min-width: 1200px;
    height: 150px;
    box-shadow: 0 0 15px #dcdbdc;
    background: #ffffff url("../../assets/icon/job-header-bg.png") no-repeat bottom right;
    background-size: 35% 100%;
    color: #303030;
    margin-bottom: 20px;

    .main {
      margin: 0 auto;
      width: 1000px;
      height: 100%;
      display: flex;
      justify-content: space-between;
    }

    .header-left {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .left {
        width: 110px;
        height: 110px;
        min-width: 110px;
        min-height: 110px;
        margin-right: 20px;

        & /deep/ .el-avatar {
          width: 100%;
          height: 100%;
          background-color: #ffffff;
        }

      }

      .right {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .name {
          font-size: 30px;
          height: 60px;
          line-height: 60px;
        }

        .desc {
          font-size: 16px;
          color: #8a8a8a;
          margin-bottom: 10px;

          span {
            margin-right: 15px;
          }

        }

        .tab {
          left: 0px;
          bottom: 0px;

          & /deep/ .el-tabs .el-tabs__header {
            margin: 0px;
          }

          & /deep/ .el-tabs__item {
            color: #8a8a8a;
          }

          & /deep/ .el-tabs__item.is-active {
            color: #e20000;
          }

          & /deep/ .el-tabs__active-bar {
            background-color: #e20000;
            height: 3px;
          }

        }

      }

    }

    .header-right {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 30px;

      .number {
        font-size: 50px;
      }

      .text {
        font-size: 30px;
      }

    }

  }

  .content1 {
    min-width: 1200px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .card {
      border-radius: 20px;
      background: #ffffff;
    }

    .company-information {
      width: 800px;
      //margin-right: 20px;
      color: #303030;
      padding: 30px 30px;
      box-sizing: border-box;

      .title {
        font-size: 16px;
        font-weight: bold;
        border-left: 2px solid #e20000;
        height: 18px;
        line-height: 18px;
        padding-left: 2px;
      }

      .msg {
        width: 100%;
        margin-top: 10px;
        box-sizing: border-box;
        font-size: 14px;
        margin-bottom: 20px;

        .description {
          & /deep/ .content-detail {
            line-height: 24px;
            padding: 0;
            word-wrap: break-word;
            word-break: break-all;
            white-space: pre-wrap;
            text-indent: -4px;

            .el-button {
              color: #b5b5b5;
              padding: 0;
            }

          }
        }
      }

      .table {
        width: 100%;
        border: 1px solid #b5b5b5;
        border-radius: 5px;
        padding: 20px 20px;
        box-sizing: border-box;

        .name {
          color: #303030;
          height: 40px;
        }

        .main {
          display: flex;
          flex-wrap: wrap;

          > div {
            color: #b5b5b5;
            height: 30px;
            width: 100%;
            display: flex;
            align-items: center;

            span {
              display: inline-block;
              color: #303030;
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
            }

            &:nth-child(1) {
              width: 30%;
              span {
                width: 100px;
              }
            }

            &:nth-child(2) {
              width: 30%;
              span {
                width: 120px;
              }
            }

            &:nth-child(3) {
              width: 30%;
            }

            &:nth-child(5) {
              span {
                width: 500px;
              }
            }

          }

        }

      }

    }

    .company-other {
      width: 380px;

      .address {
        padding: 30px 30px;
        box-sizing: border-box;
        margin-bottom: 20px;

        .text {
          font-size: 14px;
          line-height: 24px;
          margin-top: 10px;
          word-wrap: break-word;
          word-break: break-all;
        }

        .map {
          width: 100%;
          height: 200px;
          border: 1px solid red;
          margin-top: 20px;

          & /deep/ .anchorBL {
            display: none;
          }

        }

      }

      .title {
        font-size: 16px;
        font-weight: bold;
        border-left: 2px solid #e20000;
        height: 18px;
        line-height: 18px;
        padding-left: 2px;
        display: flex;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
          margin-left: 15px;
        }

      }

      .album {
        padding: 30px 30px;

        .main {
          margin-top: 10px;
          background-color: #e7e7e7;

          & /deep/ .img {
            width: 100%;
            height: 100%;
            cursor: zoom-in;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              max-height: 100%;
              max-width: 100%;
            }

          }

        }
      }

    }

  }

  .content2 {
    min-width: 1200px;
    width: 1200px;
    margin: 0 auto;
  }

}
</style>
