<template>
  <div class="register_warp">

    <!-- 注册页面头部部分 -->
    <div class="header">
      <page-header ref="pageHeaderRef" :showMenu="false" @areaChange="selectedArea"></page-header>
    </div>

    <!-- 注册页面主体部分 -->
    <div class="content">

      <!-- 注册页面主要内容 -->
      <div class="main">
        <!-- 主要内容左侧部分 -->
        <div class="main-left">

          <!-- 主要内容左侧logo -->
          <div class="main-left-item">
            <img @click="goHomePage" src="@/assets/icon/logo.png"/>
          </div>

          <!-- 主要内容左侧内容1 -->
          <div class="main-left-item">
            <div class="main-left-icon">
              <img src="@/assets/icon/accumulated-scores.png">
            </div>
            <div class="main-left-text">
              <div class="main-left-title">
                现金领不断
              </div>
              <div class="main-left-msg">
                <div class="p">积分无限叠加不清零</div>
                <div class="p">每周都能领红包</div>
              </div>
            </div>
          </div>

          <!-- 主要内容左侧内容2 -->
          <div class="main-left-item">
            <div class="main-left-icon">
              <img src="@/assets/icon/red-envelopes.png">
            </div>
            <div class="main-left-text">
              <div class="main-left-title">
                红包岗位
              </div>
              <div class="main-left-msg">
                <div class="p">海量入职红包</div>
              </div>
            </div>
          </div>

          <!-- 主要内容左侧内容3 -->
          <div class="main-left-item">
            <div class="main-left-icon">
              <img src="@/assets/icon/social-contact.png">
            </div>
            <div class="main-left-text">
              <div class="main-left-title">
                附近社交
              </div>
              <div class="main-left-msg">
                <div class="p">快速结识附近的朋友</div>
              </div>
            </div>
          </div>

        </div>

        <!-- 主要内容右侧部分 -->
        <div class="main-right">
          <!-- 主要内容右侧部分的头部菜单部分 -->
          <div class="main-right-header">
            <div class="main-right-header-item"
                 :style="currPage === 1? 'border: 2px solid #e20000; color: #e20000;':'border: 2px solid #d1d1d1; color: #898989;'"
                 @click="changeCurrPage(1)">
              找工作
            </div>
            <div class="main-right-header-item"
                 :style="currPage === 0? 'border: 2px solid #e20000; color: #e20000;':'border: 2px solid #d1d1d1; color: #898989;'"
                 @click="changeCurrPage(0)">
              招人才
            </div>
          </div>

          <!-- 主要内容右侧部分的表单部分 -->
          <div class="register-form">

            <!-- 个人注册表单 -->
            <el-form
                ref="employeeFormRef"
                :model="employeeForm"
                :rules="formRules"
                label-width="0px"
                key="employeeForm"
                v-if="currPage === 1">

              <!-- 姓名表单项 -->
              <el-form-item
                  class="register-form-item-employee"
                  prop="name">
                <div class="my-label">姓名</div>
                <el-input
                    v-model.trim="employeeForm.name"
                    clearable></el-input>
              </el-form-item>

              <!-- 性别表单项 -->
              <el-form-item
                  class="register-form-item-employee"
                  prop="gender">
                <div class="my-label">性别</div>
                <div :class="genderRulesStyle?'gender' : 'gender gender-hover'"
                     :style="genderRulesStyle? 'border: 1px solid #f56c6c;' : ''">
                  <el-radio-group
                      v-model="employeeForm.gender"
                      @change="genderChange">
                    <el-radio label="male">男</el-radio>
                    <el-radio label="female">女</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>

              <!-- 出生日期表单项 -->
              <el-form-item
                  class="register-form-item-employee"
                  prop="birthday">
                <div class="my-label">出生日期</div>
                <el-date-picker
                    value-format="yyyy-MM-dd"
                    v-model="employeeForm.birthday"
                    type="date"
                    placeholder="请选择"
                    :picker-options="pickerOptions"
                    :default-value="defaultValue"
                >
                </el-date-picker>
              </el-form-item>

              <!-- 常用地区 -->
<!--              <el-form-item class="register-form-item-employee" prop="area">-->
<!--                <div class="my-label">常用地区</div>-->
<!--                <el-select v-model="employeeForm.area" placeholder="请选择" @change="handleChange" >-->
<!--                  <el-option-->
<!--                      v-for="(item3, index3) in areasOptions"-->
<!--                      :key="'areasOptions' + index3"-->
<!--                      :label="item3.label"-->
<!--                      :value="item3.value">-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->

              <!-- 手机号表单项 -->
              <el-form-item class="register-form-item-employee" prop="phone">
                <div class="my-label">手机号</div>
                <el-input v-model.trim="employeeForm.phone" clearable></el-input>
              </el-form-item>

              <!-- 验证码表单项 -->
              <el-form-item class="register-form-item-employee" prop="msg_code">
                <div class="get-code" @click="employeeGetCode">

                  <get-code ref="employeeRegisterGetCode" :phone="employeeForm.phone"></get-code>

                </div>
                <el-input v-model="employeeForm.msg_code" placeholder="验证码" clearable></el-input>
              </el-form-item>

              <!-- 邀请码表单项 -->
              <el-form-item class="register-form-item-employee">
                <div class="my-label">邀请码</div>
                <el-input v-model.trim="employeeForm.inviteCode" clearable></el-input>
              </el-form-item>

              <!-- 注册按钮表单项 -->
              <el-form-item class="register-form-item-employee">
                <el-button type="primary" class="submit-btn" :icon="emSubmitBtnLoading?'el-icon-loading':''" @click="emSubmitRegisterForm">注 册</el-button>
              </el-form-item>

              <!-- 协议 -->
              <div class="agreement">
                <el-checkbox v-model="agreement" style="color: #9e9e9e;">
                  我已阅读并同意<span
                    @click="toRulesPage('User_Agreement')"
                    style="color: #63A7FF; cursor: pointer;">《{{AllAgreement.User_Agreement}}》</span>及
                  <span
                      @click="toRulesPage('Privacy_Policy')"
                      style="color: #63A7FF; cursor: pointer;">《{{AllAgreement.Privacy_Policy}}》</span>
                </el-checkbox>
              </div>

              <!-- 已有账户，跳转到登录 -->
              <div class="login">
                已有账户
                <router-link to="/login" style="color: #262626;">直接登录</router-link>
              </div>

            </el-form>

            <!-- 企业注册表单 -->
            <el-form
                ref="informationFormRef"
                :model="informationForm"
                :rules="formRules"
                label-width="0px"
                key="informationForm"
                v-if="currPage === 0"
            >

              <!-- 姓名表单项 -->
              <el-form-item class="register-form-item-information" prop="hrName">
                <div class="my-label">姓名</div>
                <el-input v-model.trim="informationForm.hrName" clearable></el-input>
              </el-form-item>

              <!-- 性别表单项 -->
              <el-form-item
                  class="register-form-item-information"
                  prop="hrGender">
                <div class="my-label">性别</div>
                <div :class="informationGenderRulesStyle?'gender' : 'gender gender-hover'"
                     :style="informationGenderRulesStyle? 'border: 1px solid #f56c6c;' : ''">
                  <el-radio-group
                      v-model="informationForm.hrGender"
                      @change="informationGenderChange">
                    <el-radio label="male">男</el-radio>
                    <el-radio label="female">女</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>

              <!-- 手机号表单项 -->
              <el-form-item class="register-form-item-information" prop="phone">
                <div class="my-label">手机号</div>
                <el-input v-model.trim="informationForm.phone" clearable></el-input>
              </el-form-item>

              <!-- 验证码表单项 -->
              <el-form-item class="register-form-item-information" prop="msg_code">
                <div class="get-code" @click="informationGetCode">

                  <get-code ref="informationRegisterGetCode" :phone="informationForm.phone"></get-code>

                </div>
                <el-input v-model="informationForm.msg_code" placeholder="验证码" clearable></el-input>
              </el-form-item>

              <!-- 注册按钮表单项 -->
              <el-form-item class="register-form-item-information">
                <el-button type="primary" class="submit-btn" :icon="inSubmitBtnLoading?'el-icon-loading':''" @click="inSubmitRegisterForm">注 册</el-button>
              </el-form-item>

              <!-- 协议 -->
              <div class="agreement">
                <el-checkbox v-model="agreement" style="color: #9e9e9e;">
                  我已阅读并同意<span
                    @click.stop="toRulesPage('User_Agreement')"
                    style="color: #63A7FF; cursor: pointer;">《{{AllAgreement.User_Agreement}}》</span>及
                  <span
                      @click.stop="toRulesPage('Privacy_Policy')"
                      style="color: #63A7FF; cursor: pointer;">《{{AllAgreement.Privacy_Policy}}》</span>
                </el-checkbox>
              </div>

              <!-- 已有账户，跳转到登录 -->
              <div class="login">
                已有账户
                <router-link to="/login" style="color: #262626;">直接登录</router-link>
              </div>

            </el-form>

          </div>
        </div>
      </div>

    </div>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="400px">
      <div>常用地区将用来积累积分，请注意选择正确的常用地区</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisibleSubmit">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
// 引入页面头部组件
import pageHeader from '@/components/pageHeader/pageHeader'
// 引入api接口
import {
  SendMsgCodeApi,
  EmployeeRegisterApi,
  HrRegisterApi, GetAreaListApi,
} from '@/request/api'

import Vcode from "vue-puzzle-vcode";
import GetCode from "@/components/getCode/getCode";
import {formRules} from '@/share/formRules'
import {mapMutations} from "vuex";
import GenerateTestUserSig from "../../../public/debug/GenerateTestUserSig";
import share from "@/share/share";
import mixin from "@/mixin/mixin";
import {ALL_RULES} from "@/config/baseURL";
import {AllAgreement} from "@/share/shareData";

export default {
  name: "register",
  mixins: [mixin],
  components: {
    GetCode,
    pageHeader,
    Vcode,
  },
  data() {
    return {
      AllAgreement: AllAgreement,
      /* 个人注册的表单内容 */
      employeeForm: {
        name: '',  // 姓名
        gender: '',  // 性别
        birthday: '',  // 生日
        phone: '',  // 手机
        msg_code: '',  // 验证码
        // area: '',  // 注册地区
        inviteCode: '',  // 邀请码
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > (Date.now() - 10 * 365 * 24 * 60 * 60 * 1000)
        }
      },
      defaultValue: Date.now() - 20 * 365 * 24 * 60 * 60 * 1000,
      /* 企业注册的表单内容 */
      informationForm: {
        hrName: '',  // 姓名
        hrGender: '',
        phone: '',  // 手机
        msg_code: '',  // 验证码
      },
      agreement: false,  // 是否同意协议
      genderRulesStyle: false,  // 控制性别验证失败的样式
      informationGenderRulesStyle: false,  // 控制性别验证失败的样式
      /* 验证规则 */
      formRules: formRules,
      currPage: 1,  // 当前选中的菜单页

      emSubmitBtnLoading: false,
      inSubmitBtnLoading: false,
      dialogVisible: false,
    }
  },
  computed: {
    token() {
      return this.$store.state.token
    },
    userType() {
      return this.$store.state.userInfo.userType
    },
    areasOptions() {
      return this.$store.state.areasOptions || []
    },
    // shareId() {
    //   return this.$store.state.shareId || ''
    // },
    // shareArea() {
    //   return this.$store.state.shareArea || ''
    // },
    aonuoChat() {
      return this.$store.state.aonuoChat
    },
    place() {
      return this.$store.state.userInfo.place
    },
    invitationCode() {
      return this.$store.state.invitationCode
    }
  },
  watch: {
    place: {
      handler: function () {
        if (this.place && this.place.value) {
          // this.employeeForm.area = this.$store.state.userInfo.place.value
        }
      }
    },
    invitationCode: {
      handler: function () {
        if (this.invitationCode) {
          this.employeeForm.inviteCode = this.invitationCode
        }
      },
      immediate: true
    }
  },
  mounted() {
    // this.employeeForm.area = this.$store.state.userInfo.place.value || ''
  },
  methods: {
    ...mapMutations(['setShareId', 'setShareArea', 'setInvitationCode']),

    selectedArea() {
      // this.employeeForm.area = this.$store.state.userInfo.place.value
    },
    handleChange() {
      // this.$refs.pageHeaderRef.handleChange(this.employeeForm.area)
    },
    /* 切换当前登录类型 */
    changeCurrPage(num) {
      this.currPage = num
      this.genderRulesStyle = false
      this.informationGenderRulesStyle = false
    },
    async dialogVisibleSubmit() {
      this.dialogVisible = false
      this.emSubmitBtnLoading = true

      // this.employeeForm.area = this.area
      const res2 = await EmployeeRegisterApi({
        ...this.employeeForm,
        // inviteToken: this.shareId,
        // inviteArea: this.shareArea
      })
      if (res2) {

        // const res3 = await this.aonuoChat.createChatUser({
        //   userId: res2.sessionId,
        //   name: this.employeeForm.name,
        //   username: this.employeeForm.phone,
        //   gender: this.employeeForm.gender,
        //   avatar: res2.avatar,
        // })

        // if (res3) {
          share.message.success('注册成功')
          await this.$router.push('/login')
        // }

      }

      this.emSubmitBtnLoading = false
    },
    /* 个人验证提交方法 */
    emSubmitRegisterForm() {
      if (this.emSubmitBtnLoading) {
        return
      }

      this.$refs.employeeFormRef.validateField('gender', res => {
        if (res !== '') {
          this.genderRulesStyle = true
        } else {
          this.genderRulesStyle = false
        }
      })

      this.$refs.employeeFormRef.validate(async res => {
        if (res) {

          if (!this.agreement) {
            share.message.warning(`请先勾选我已阅读并同意《${AllAgreement.User_Agreement}》及《${AllAgreement.Privacy_Policy}》`)
            return
          }

          // this.dialogVisible = true
          this.dialogVisibleSubmit()
        }
      })

    },
    /* hr验证提交方法 */
    inSubmitRegisterForm() {
      if (this.inSubmitBtnLoading) {
        return
      }

      this.$refs.informationFormRef.validateField('hrGender', res => {
        if (res !== '') {
          this.informationGenderRulesStyle = true
        } else {
          this.informationGenderRulesStyle = false
        }
      })

      this.$refs.informationFormRef.validate(async res => {
        if (res) {

          if (!this.agreement) {
            share.message.warning(`请先勾选我已阅读并同意《${AllAgreement.User_Agreement}》及《${AllAgreement.Privacy_Policy}》`)
            return
          }
          this.inSubmitBtnLoading = true

          const res2 = await HrRegisterApi({...this.informationForm})
          if (res2) {

            // const res3 = await this.aonuoChat.createChatUser({
            //   userId: res2.sessionId,
            //   name: this.informationForm.hrName,
            //   username: this.informationForm.phone,
            //   gender: this.informationForm.hrGender,
            //   avatar: res2.avatar,
            // })

            // if (res3) {
              share.message.success('注册成功')

              await this.$router.push('/login')
            // }

          }
        }
        this.inSubmitBtnLoading = false
      })
    },
    /* 个人获取验证码方法 */
    employeeGetCode() {
      if (this.employeeForm.phone === '') {
        share.message.warning('手机号不能为空')
        return
      }
      let ab = /^1[3456789]\d{9}$/
      // /^1[3456789]\d{9}$/
      if (ab.test(this.employeeForm.phone) === false) {
        share.message.warning('手机号格式错误')
        return
      }

      this.$refs.employeeRegisterGetCode.getCode()
    },
    /* hr获取验证码方法 */
    async informationGetCode() {
      if (this.informationForm.phone === '') {
        share.message.warning('手机号不能为空')
        return
      }
      let ab = /^1[3456789]\d{9}$/
      if (ab.test(this.informationForm.phone) === false) {
        share.message.warning('手机号格式错误')
        return
      }

      this.$refs.informationRegisterGetCode.getCode()

    },
    /* 取消性别验证错误样式 */
    genderChange() {
      this.genderRulesStyle = false
    },
    informationGenderChange() {
      this.informationGenderRulesStyle = false
    },
    /* 头部按钮点击时触发 */
    // actionClick(num, state) {
    //   if (num === 1) {
    //     if (state) {
    //       console.log('跳转到首页')
    //     }else {
    //       this.$router.push('/login')
    //     }
    //   }
    // },
    goHomePage() {
      this.$router.push('/')
    },

    toRulesPage(param) {
      window.open(ALL_RULES + '?type=' + param)
    },

  }
}
</script>

<style lang="less" scoped>
/* 注册页样式 */
.register_warp {
  width: 100%;
  height: 100%;
  min-width: 1400px;
  min-height: 700px;

  /* 页面头部样式 */
  .header {
    height: 61px;
    width: 100%;
  }

  /* 页面内容样式 */
  .content {
    width: 100%;
    height: calc(100% - 61px);
    //background-color: #f4f2f3;
    background-image: url("../../assets/icon/register-bg.png");
    background-repeat: no-repeat;
    background-position: bottom;
    display: flex;
    justify-content: center;
    align-items: center;

    /* 页面主要内容样式 */
    .main {
      width: 900px;
      height: 750px;
      display: flex;
      box-shadow: 0 5px 10px #bbb9bb;

      /* 主要内容左侧样式 */
      .main-left {
        width: 300px;
        height: 100%;
        background-color: #dbdbdb;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 30px;
        box-sizing: border-box;

        /* 主要内容左侧列表logo样式 */
        .main-left-item:first-child {
          width: 250px;
          height: 90px;
          margin-bottom: 10px;

          img {
            max-width: 100%;
            cursor: pointer;
          }
        }

        /* 主要内容左侧列表第二、三、四项样式 */
        .main-left-item:nth-child(2), .main-left-item:nth-child(3), .main-left-item:nth-child(4) {
          width: 250px;
          height: 60px;
          margin-bottom: 10px;
          display: flex;
          justify-content: flex-start;
          padding-left: 35px;
          box-sizing: border-box;
          align-items: center;

          /* 左侧列表项图标样式 */
          .main-left-icon {
            width: 50px;
            height: 50px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          /* 左侧列表项文本样式 */
          .main-left-text {

            /* 左侧列表项标题文本样式 */
            .main-left-title {
              font-size: 14px;
              font-weight: bold;
              color: #707070;
              margin-bottom: 4px;
            }

            /* 左侧列表项描述文本样式 */
            .main-left-msg {
              font-size: 10px;

              .p {
                color: #8a8a8a;
                margin: 2px 0;
              }
            }
          }
        }

      }

      /* 主要内容右侧样式 */
      .main-right {
        width: 600px;
        height: 100%;
        padding: 0 80px;
        box-sizing: border-box;
        background-color: #ffffff;

        /* 主要内容右侧的头部菜单样式 */
        .main-right-header {
          height: 150px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          /* 菜单项样式 */
          .main-right-header-item {
            width: 180px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }

        }

        /* 主要内容右侧表单样式 */
        .register-form {

          /* 企业注册表单项样式 */
          .register-form-item-information {
            position: relative;

            /* 第1、2个表单项样式 */
            &:nth-child(1) /deep/ .el-input .el-input__inner,
            &:nth-child(3) /deep/ .el-input .el-input__inner {
              padding-left: 100px !important;
              border-radius: 0px !important;
            }

            /* 第3个表单项样式 */
            &:nth-child(4) /deep/ .el-input .el-input__inner {
              padding-right: 150px !important;
              border-radius: 0px !important;
            }

            /* 第3个表单项右侧清楚图标位置样式 */
            &:nth-child(4) /deep/ .el-input .el-input__suffix {
              right: 125px !important;
            }

            /* 第4个表单项按钮样式 */
            &:nth-child(5) /deep/ .el-button {
              border-radius: 0 !important;
              background-color: #e20000 !important;
              border: #e20000 !important;
            }

            /* 性别表单项样式 */
            .gender {
              border: 1px solid #DCDFE6;
              transition: border 0.3s;
              padding-left: 100px;
            }

            /* 性别表单项鼠标悬浮样式 */
            .gender-hover {
              &:hover {
                border: 1px solid #c0c4cc;
              }
            }

            /* 所有表单样式 */
            & /deep/ .el-date-editor {
              width: 100% !important;

              .el-input__inner {
                padding-left: 120px !important;
              }

              .el-input__prefix {
                left: 95px !important;
              }

            }

            /* 表单label样式 */
            .my-label {
              width: 90px;
              height: 30px;
              position: absolute;
              left: 0px;
              top: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 999;
              border-right: 1px solid #DCDFE6;
              color: #818284;
            }

            /* 表单获取验证码按钮样式 */
            .get-code {
              width: 120px;
              height: 30px;
              position: absolute;
              right: 0px;
              top: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 999;
              border-left: 1px solid #DCDFE6;
              cursor: pointer;
              color: #e20000;
            }

            /* 表单提交按钮样式 */
            .submit-btn {
              width: 100%;
              height: 100%;
              color: #ffffff !important;
            }
          }

          /* 个人注册表单项样式 */
          .register-form-item-employee {
            position: relative;

            /* 第1、4个表单项样式 */
            &:nth-child(1) /deep/ .el-input .el-input__inner,
            &:nth-child(4) /deep/ .el-input .el-input__inner {
              padding-left: 100px !important;
              border-radius: 0px !important;
            }
            &:nth-child(6) /deep/ .el-input .el-input__inner {
              padding-left: 100px !important;
              border-radius: 0px !important;
            }

            /* 第3个表单项样式 */
            &:nth-child(3) /deep/ .el-input .el-input__inner {
              border-radius: 0px !important;
            }

            /* 第4个表单项样式 */
            //&:nth-child(4) /deep/ .el-input .el-input__inner {
            //  border-radius: 0px !important;
            //  padding-left: 100px;
            //  box-sizing: border-box;
            //}
            //
            //&:nth-child(4) /deep/ .el-select {
            //  width: 100%;
            //}

            /* 第5个表单项样式 */
            &:nth-child(5) /deep/ .el-input .el-input__inner {
              padding-right: 150px !important;
              border-radius: 0px !important;
            }

            /* 第5个表单项左侧清除图标位置样式 */
            &:nth-child(5) /deep/ .el-input .el-input__suffix {
              right: 125px !important;
            }

            &:nth-child(6) /deep/ .el-select {
              width: 100%;
            }

            /* 第6个表单项按钮样式 */
            &:nth-child(7) /deep/ .el-button {
              border-radius: 0 !important;
              background-color: #e20000 !important;
              border: #e20000 !important;
            }


            /* 性别表单项样式 */
            .gender {
              border: 1px solid #DCDFE6;
              transition: border 0.3s;
              padding-left: 100px;
            }

            /* 性别表单项鼠标悬浮样式 */
            .gender-hover {
              &:hover {
                border: 1px solid #c0c4cc;
              }
            }

            /* 所有表单样式 */
            & /deep/ .el-date-editor {
              width: 100% !important;

              .el-input__inner {
                padding-left: 120px !important;
              }

              .el-input__prefix {
                left: 95px !important;
              }

            }

            /* 表单项label样式 */
            .my-label {
              width: 90px;
              height: 30px;
              position: absolute;
              left: 0px;
              top: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 999;
              border-right: 1px solid #DCDFE6;
              color: #818284;
            }

            /* 获取验证码按钮样式 */
            .get-code {
              width: 120px;
              height: 30px;
              position: absolute;
              right: 0px;
              top: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 999;
              border-left: 1px solid #DCDFE6;
              cursor: pointer;
              color: #e20000;
            }

            /* 表单提交按钮样式 */
            .submit-btn {
              width: 100%;
              height: 100%;
              color: #ffffff !important;

              //& /deep/ .
            }

          }

          /* 协议表单项样式 */
          .agreement {
            width: 100%;
            height: 30px;
            text-align: center;
            line-height: 30px;
            font-size: 14px;
            margin-top: -10px;
          }

          /* 登录文本样式 */
          .login {
            width: 100%;
            height: 30px;
            text-align: center;
            line-height: 30px;
            color: #9e9e9e;
            font-size: 14px;
          }

        }

      }

    }
  }

}
</style>