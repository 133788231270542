import 'babel-polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {Message} from "element-ui";
import echarts from 'echarts'
import './plugins/element.js'
import './assets/css/global.css'  // 导入全局样式
import Print from 'vue-print-nb'
import axios from 'axios'
import Vuex from 'vuex'
import storeValue from './store/index'

import GenerateTestUserSig from '../public/debug/GenerateTestUserSig'

import VueClipboard from 'vue-clipboard2'

import audio from "vue-mobile-audio";
import share from "@/share/share";

Vue.use(VueClipboard)

Vue.use(audio)

Vue.prototype.$axios = axios
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false

Vue.use(Print)

window.genTestUserSig = GenerateTestUserSig.genTestUserSig

import AonuoChatSdk from './aonuo-chat-js-sdk'
window.$AonuoChatSdk = AonuoChatSdk
Vue.prototype.$AonuoChatSdk = AonuoChatSdk
Vue.prototype.$bus = new Vue()


/* 路由判断登录 根据路由配置文件的参数 */
router.beforeEach((to, from, next) => {

  console.log('--------', 'to:', to, storeValue.state)
  console.log('--------', 'requireAuth:', to.matched.some(record => record.meta.requireAuth), storeValue.state.token)

  if (to.matched.some(record => record.meta.requireAuth)){  // 判断该路由是否需要登录权限

    let store2 = JSON.parse(sessionStorage.getItem("store"))
    console.log('store2', store2)
    let token2 = ''
    if (store2 && store2.token) {
      token2 = store2.token
    }
    let token = storeValue.state.token || token2

    // let token = storeValue.state.token

    console.log('JXZP_User_Token', token)

    if (token && token !== '' && token !== null && token !== 'null') {  // 判断当前的token是否存在 ； 登录存入的token
      next();
    }
    else {
      share.message.warning('请先去登录')
      next({
        path: '/login',
        query: {redirect: to.fullPath}  // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })

    }
  } else {
    next();
  }


})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')