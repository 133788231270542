<template>
  <div class="personalDynamic_warp">

    <div class="left">

      <div class="header-tab">

        <div class="header-tab-left">
          <el-tabs v-model="currNavPage" @tab-click="currNavPageChange">
            <el-tab-pane label="看过我" name="1"></el-tab-pane>
            <el-tab-pane label="我看过" name="2"></el-tab-pane>
            <el-tab-pane label="对我感兴趣" name="3"></el-tab-pane>
            <el-tab-pane label="沟通过" name="4"></el-tab-pane>
            <el-tab-pane label="面试邀请" name="5"></el-tab-pane>
            <el-tab-pane label="收藏" name="6"></el-tab-pane>
            <el-tab-pane label="红包申请" name="7"></el-tab-pane>
          </el-tabs>
        </div>

        <div class="header-tab-right" v-if="currNavPage !== '5' && currNavPage !== '7'">
          <el-select v-model="jobFilter" placeholder="请选择" size="mini" @change="jobFilterChange">
            <el-option
                label="显示全部岗位"
                value="">
            </el-option>
            <el-option
                label="仅显示红包岗位"
                value="1">
            </el-option>
          </el-select>
        </div>

        <div class="header-tab-right" v-if="currNavPage === '7'">
          <el-select v-model="jobFilter2" placeholder="请选择" size="mini" @change="jobFilterChange">
            <el-option
                label="全部状态"
                value="">
            </el-option>
            <el-option
                label="待确认"
                value="1">
            </el-option>
            <el-option
                label="已拒绝"
                value="2">
            </el-option>
            <el-option
                label="进行中"
                value="3">
            </el-option>
            <el-option
                label="已中断"
                value="4">
            </el-option>
            <el-option
                label="已领完"
                value="5">
            </el-option>
            <el-option
                label="红包冲突"
                value="6">
            </el-option>
          </el-select>
        </div>

        <div class="header-tab-right" v-if="currNavPage === '5'">
          <el-select v-model="jobFilter3" placeholder="请选择" size="mini" @change="jobFilterChange">
            <el-option
                label="全部状态"
                value="">
            </el-option>
            <el-option
                label="已预约"
                value="0">
            </el-option>
            <el-option
                label="已结束"
                value="1">
            </el-option>
          </el-select>
        </div>

      </div>

      <div class="content" id="personal-dynamic-tab5" v-loading="pageLoading">

        <template v-if="currNavPage !== '5'">
          <div class="dynamic-job-item" v-for="(item, index) in jobList"
               :key="'dynamic-job' + index">
            <template v-if="currNavPage === '7'">
              <dynamic-job-item
                  :hr-name="item.hrName"
                  :hr-id="item.hr_id"
                  :hr-gender="item.hrGender"
                  :academic-requirement="item.academicRequirement"
                  :enterprise-address="item.enterpriseAddress"
                  :enterprise-logo="item.enterpriseLogo"
                  :enterprise-name="item.enterpriseName"
                  :experience-requirement-range="item.experienceRequirementRange"
                  :hr-avatar="item.hrAvatar"
                  :job-welfare-list="item.jobWelfareList"
                  :salary-range="item.salaryRange"
                  :job-name="item.jobName"
                  :job-id="item.job_id"
                  :job-type="item.jobType"
                  :amount="item.RedPocketPerPerson"
                  :enterprise-id="item.enterprise_id"
                  :job-address="item.jobAddressList"
                  :red-envelope-application="redEnvelopeApplication"
                  :red-envelope-process-status="item.redPocketApplyStatus"
                  :record-id="item.record_id"
                  :enterprise-status="item.enterpriseStatus"
                  :job-status="3"
                  :is-close="false"
                  :is-red-packet-job="true"
                  @goCommunication="(toSessionId) => goCommunication(toSessionId, item.job_id, item.jobName)"
                  @clickLeft="clickLeft"
                  @clickRight="clickRight"
                  @toRedEnvelopeApplicationPage="toRedEnvelopeApplicationPage"
              ></dynamic-job-item>
            </template>
            <template v-if="currNavPage !== '7'">
              <dynamic-job-item
                  :hr-name="item.hrName"
                  :hr-id="item.hr_id"
                  :hr-gender="item.hrGender"
                  :academic-requirement="item.academicRequirement"
                  :enterprise-address="item.enterpriseAddress"
                  :enterprise-logo="item.enterpriseLogo"
                  :enterprise-name="item.enterpriseName"
                  :experience-requirement-range="item.experienceRequirementRange"
                  :hr-avatar="item.hrAvatar"
                  :job-welfare-list="item.jobWelfareList"
                  :salary-range="item.salaryRange"
                  :job-name="item.jobName"
                  :job-id="item.job_id"
                  :job-type="item.jobType"
                  :amount="item.RedPocketPerPerson"
                  :enterprise-id="item.enterprise_id"
                  :job-address="item.jobAddressList"
                  :red-envelope-application="redEnvelopeApplication"
                  :red-envelope-process-status="item.redPocketApplyStatus"
                  :record-id="item.record_id"
                  :enterprise-status="item.enterpriseStatus"
                  :job-status="item.jobStatus"
                  :is-close="item.status !== 1"
                  @goCommunication="(toSessionId) => goCommunication(toSessionId, item.job_id, item.jobName)"
                  @clickLeft="clickLeft"
                  @clickRight="clickRight"
                  @toRedEnvelopeApplicationPage="toRedEnvelopeApplicationPage"
              ></dynamic-job-item>
            </template>
          </div>
        </template>

        <template v-for="(item, index) in jobList">
          <div :class="index < jobList.length - 1?'dynamic-job-item2 bottom-border':'dynamic-job-item2'" v-if="currNavPage === '5' && item.jonInformation && item.jonInformation.jobStatus !== undefined && item.jonInformation.isClose !== undefined"
               :key="'dynamic-job2' + index" :style="interviewItemStatus(item)?'':'opacity: 0.5;'">
            <div class="interview-item-warp" v-if="item && item.jonInformation">
              <div class="top">
                <div class="title">
                  面试岗位：<span @click="clickLeft2(item.jonInformation.job_id, item.jonInformation.jobStatus, item.jonInformation.isClose, item.enterpriseInformation.enterpriseStatus)">{{item.jonInformation.jobName}}<i class="el-icon-arrow-right"></i></span>
                </div>
                <div class="time">
                  面试时间：{{item.interviewTime}}
                </div>
                <div class="place">
                  面试地点：{{item.interviewAddress}}
                </div>
              </div>
              <div class="bottom">
                {{item.createTime}}
              </div>
            </div>
          </div>
        </template>

        <div v-if="jobList && jobList.length > 0" class="pagination">
          <el-pagination
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
        </div>

        <div class="jon-list-none" v-if="isLobListNone">
          <img src="@/assets/icon/empty.png">
          <div class="text">
            数据为空，去<span @click="goMyResume">完善简历</span>引起更多HR的兴趣吧
          </div>
        </div>

      </div>

    </div>

    <div class="right">

      <div class="card">

        <div class="right-top">
          <el-avatar size="large" :src="profilePhoto"></el-avatar>
          {{ username }}
          <el-button size="mini" @click="goMyResume">在线编辑简历</el-button>
        </div>

        <div class="right-bottom">

          <div class="dynamic-item" @click="currNavPageChange2('4')">
            <div class="number">
              {{ userStatistics.communicationCount }}
            </div>
            <div class="title">
              沟通过
            </div>
          </div>

          <div class="dynamic-item" @click="currNavPageChange2('5')">
            <div class="number">
              {{ userStatistics.getInterviewInvitationCount }}
            </div>
            <div class="title">
              面试邀请
            </div>
          </div>

          <div class="dynamic-item" @click="currNavPageChange2('6')">
            <div class="number">
              {{ userStatistics.collectCount }}
            </div>
            <div class="title">
              收藏
            </div>
          </div>

        </div>

      </div>

      <div class="card">

        <div class="dynamic-item" @click="currNavPageChange2('1')">
          <div class="title">
            看过我
          </div>
          <div class="number">
            {{ userStatistics.seeMeCount }}
          </div>
        </div>

        <div class="dynamic-item" @click="currNavPageChange2('2')">
          <div class="title">
            我看过
          </div>
          <div class="number">
            {{ userStatistics.seeJobCount }}
          </div>
        </div>

        <div class="dynamic-item" @click="currNavPageChange2('3')">
          <div class="title">
            对我感兴趣
          </div>
          <div class="number">
            {{ userStatistics.collectMeCount }}
          </div>
        </div>

        <div class="dynamic-item" @click="currNavPageChange2('7')">
          <div class="title">
            红包申请
          </div>
          <div class="number">
            {{ userStatistics.applyRedPocketCount }}
          </div>
        </div>

      </div>

    </div>

    <el-dialog
        title="提示"
        :visible.sync="toMyResumeDialogVisible"
        width="350px">
      <div style="text-align: center">
        <p style="font-size: 20px; font-weight: bold; color: #3a3a3a;">请先去完善简历</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="toMyResumeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toMyResume">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import DynamicJobItem from "@/components/jobItem/dynamicJobItem";
import {
  StatusStatisticsApi,
  SeeJobRecordApi,
  SeeMeRecordApi,
  CommunicatedJobRecordApi,
  CollectMeRecordApi,
  GetCollectJobRecordApi,
  GetRedPocketApplyRecordApi,
  GetInterviewInvitationRecordApi, PostEmployeeContactHrApi, isEditedResumeApi
} from "@/request/api";
import {mapMutations} from "vuex";
import share from "@/share/share";

export default {
  name: "personalDynamic",
  components: {DynamicJobItem},
  data() {
    return {
      pageLoading: true,
      currNavPage: '',
      jobFilter: '',
      jobFilter2: '',
      jobFilter3: '',
      pageSize: 5,
      currentPage: 1,
      total: 0,
      jobList: [],
      isLobListNone: false,
      userStatistics: {
        getInterviewInvitationCount: 0,
        collectCount: 0,
        collectMeCount: 0,
        communicationCount: 0,
        seeJobCount: 0,
        seeMeCount: 0,
        applyRedPocketCount: 0,
      },
      toMyResumeDialogVisible: false,

      isFirst: true,
    }
  },
  computed: {
    profilePhoto() {
      return this.$store.state.userInfo.profilePhoto
    },
    username() {
      return this.$store.state.userInfo.username
    },
    isEditedResume() {
      return this.$store.state.isEditedResume || ''
    },
    redEnvelopeApplication() {
      if (this.currNavPage === '7') {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    // currNavPage() {
    // },
  },
  mounted() {
    // let loading = share.loadingShow({
    //   id: '#personal-dynamic-tab5',
    // })
    // loading.close()
    let index = this.$route.query.index
    let currNavPage = sessionStorage.getItem('currNavPage1')
    if (index && index !== '' && index !== 'null' && index !== null) {
      this.currNavPage = index
    } else {
      this.currNavPage = currNavPage || '1'
    }
    sessionStorage.setItem('currNavPage1', this.currNavPage)

    let currentPage = sessionStorage.getItem('currentPage1')
    if (currentPage) {
      this.currentPage = parseInt(currentPage) || 1
    }
    sessionStorage.setItem('currentPage1', this.currentPage)

    this.loadIsEditedResume()
    this.loadData()

  },
  methods: {
    ...mapMutations(['setCurrentSessionId', 'setIsEditedResume']),
    async loadIsEditedResume() {
      const res = await isEditedResumeApi()

      if (res) {
        this.setIsEditedResume({
          isEditedResume: res.isEditedResume
        })
      }
    },
    async loadData() {
      const res = await StatusStatisticsApi()
      if (res) {
        this.userStatistics.getInterviewInvitationCount = res.statistics.GetInterviewInvitationCount || 0
        this.userStatistics.collectCount = res.statistics.collectCount || 0
        this.userStatistics.collectMeCount = res.statistics.collectMeCount || 0
        this.userStatistics.communicationCount = res.statistics.communicationCount || 0
        this.userStatistics.seeJobCount = res.statistics.seeJobCount || 0
        this.userStatistics.seeMeCount = res.statistics.seeMeCount || 0
        this.userStatistics.applyRedPocketCount = res.statistics.applyRedPocketCount || 0
      }
      await this.loadJobListData()
    },
    async loadJobListData() {
      this.pageLoading = true
      let res = []
      if (this.currNavPage === '1') {
        res = await SeeMeRecordApi({
          jobType: this.jobFilter,
          pageNum: this.currentPage,
          pageSize: this.pageSize
        })
      }
      if (this.currNavPage === '2') {
        res = await SeeJobRecordApi({
          jobType: this.jobFilter,
          pageNum: this.currentPage,
          pageSize: this.pageSize
        })
      }
      if (this.currNavPage === '3') {
        res = await CollectMeRecordApi({
          jobType: this.jobFilter,
          pageNum: this.currentPage,
          pageSize: this.pageSize
        })
      }
      if (this.currNavPage === '4') {
        res = await CommunicatedJobRecordApi({
          jobType: this.jobFilter,
          pageNum: this.currentPage,
          pageSize: this.pageSize
        })
      }
      if (this.currNavPage === '5') {
        res = await GetInterviewInvitationRecordApi({
          jobType: '',
          finished: this.jobFilter3,
          pageNum: this.currentPage,
          pageSize: this.pageSize
        })
      }
      if (this.currNavPage === '6') {
        res = await GetCollectJobRecordApi({
          jobType: this.jobFilter,
          pageNum: this.currentPage,
          pageSize: this.pageSize
        })
      }
      if (this.currNavPage === '7') {
        res = await GetRedPocketApplyRecordApi({
          redPocketApplyStatus: this.jobFilter2,
          pageNum: this.currentPage,
          pageSize: this.pageSize
        })
      }

      if (res) {
        this.total = res.numOfRecords
        this.jobList = res.records

        if (res.records.length <= 0) {
          this.isLobListNone = true
        } else {
          this.isLobListNone = false
        }
      }

      if ((!res || res.records.length <= 0) && this.isFirst) {
        this.currentPage = 1
        sessionStorage.setItem('currentPage1', this.currentPage)
        this.isFirst = false
        await this.loadJobListData()
      }
      this.isFirst = false
      this.pageLoading = false
    },
    jobFilterChange() {
      this.currentPage = 1
      sessionStorage.setItem('currentPage1', this.currentPage)
      this.loadJobListData()
    },
    currNavPageChange() {
      this.currentPage = 1
      sessionStorage.setItem('currentPage1', this.currentPage)
      sessionStorage.setItem('currNavPage1', this.currNavPage)
      this.loadJobListData()
    },
    currNavPageChange2(value) {
      this.currentPage = 1
      sessionStorage.setItem('currentPage1', this.currentPage)
      this.currNavPage = value
      sessionStorage.setItem('currNavPage1', this.currNavPage)
      this.loadJobListData()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      sessionStorage.setItem('currentPage1', this.currentPage)
      this.loadJobListData()
    },
    toMyResume() {
      this.$router.push('/myResume')
    },
    goCommunication(toSessionId, jobId, jobName) {

      if (!this.isEditedResume) {
        this.toMyResumeDialogVisible = true
        return
      }

      PostEmployeeContactHrApi({
        jobId: jobId
      })

      share.toChatWindow(toSessionId, {jobId: jobId, jobName: jobName}, '/chatSectionHome')

    },
    clickLeft(id) {
      if (this.currNavPage === '7') {
        this.$router.push({
          path: '/jobDetailed',
          query: {
            id: id,
            statusDisplay: 'none'
          }
        })
      } else {
        this.$router.push({
          path: '/jobDetailed',
          query: {
            id: id
          }
        })
      }
    },
    clickLeft2(id, state, state2, enterpriseStatus) {
      if(enterpriseStatus !== 1) {
        share.message.warning('该岗位的企业信息异常')
        return
      }

      this.$router.push({
        path: '/jobDetailed',
        query: {
          id: id
        }
      })
    },
    clickRight(id) {
      this.$router.push({
        path: '/companyDetailed',
        query: {
          id: id
        }
      })
    },
    toRedEnvelopeApplicationPage(id, recordId, jobId) {
      this.$router.push({
        path: '/redEnvelopeApplicationPage',
        query: {
          id: jobId,
          recordId: recordId
        }
      })
    },
    goMyResume() {
      this.$router.push('/myResume')
    },

    interviewItemStatus(item) {
      return item.jonInformation.jobStatus === 3 && item.jonInformation.status === 1 && item.enterpriseInformation.enterpriseStatus === 1
    },
  }
}
</script>

<style lang="less" scoped>
.personalDynamic_warp {
  width: 100%;
  //height: 100%;
  background-color: #f4f2f3;
  display: flex;
  justify-content: space-between;

  .left {
    flex: 1;
    min-height: 500px;

    .header-tab {
      height: 40px;
      width: 100%;
      background-color: #ffffff;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .header-tab-left {

        & /deep/ .el-tabs {
          margin-left: 10px;
          position: relative;
          top: 2px;

          .el-tabs__header {
            margin-bottom: 0px;
          }

          .el-tabs__active-bar {
            height: 3px;
            background-color: #e20000;
          }

          .el-tabs__item {
            //margin: 0 10px;
          }

          .el-tabs__item.is-active {
            color: #e20000;
          }

          .el-tabs__nav-wrap::after {
            background: none;
          }

        }

      }

      .header-tab-right {
        height: 100%;
        display: flex;
        align-items: center;
        padding-right: 20px;
        box-sizing: border-box;

        .el-select {
          width: 150px;
        }

        & /deep/ .el-input__inner {
          border: 1px solid #e20000;
        }

      }

    }

    .content {
      margin-top: 3px;
      width: 100%;
      min-height: 500px;
      background-color: #ffffff;
      padding: 0 20px 70px 20px;
      box-sizing: border-box;
      position: relative;

      .dynamic-job-item {
        width: 100%;
        height: 150px;
        border-bottom: 1px solid #d3d3d3;
      }

      .bottom-border {
        border-bottom: 10px solid #F2F2F2;
      }
      .dynamic-job-item2 {
        width: 100%;
        height: 150px;

        .interview-item-warp {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;

          .top {
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            .title {
              font-size: 20px;
              margin-bottom: 10px;
              color: #303030;
              cursor: pointer;
              i {
                color: #409eff;
              }
            }
            .time {
              font-size: 16px;
              color: #303030;
              margin-bottom: 10px;
            }
            .place {
              font-size: 16px;
              color: #303030;
            }
          }
          .bottom {
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 14px;
            color: #bdbdbd;
            border-top: 1px solid #F2F2F2;
          }
        }
      }

      .pagination {
        width: 100%;
        height: 60px;
        position: absolute;
        bottom: 0px;
        left: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .jon-list-none {
        width: 100%;
        height: 410px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 100px;
          height: 100px;
          margin-bottom: 10px;
        }

        .text {
          font-size: 16px;
          color: #cdcdcd;

          span {
            color: #e20000;
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }

    }

  }

  .right {
    width: 250px;
    height: 485px;
    margin-left: 15px;


    .card {
      background-color: #ffffff;
      margin-bottom: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:first-child {
        padding: 40px 40px;
        box-sizing: border-box;
        font-size: 14px;

        .right-top {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          & /deep/ .el-avatar--large {
            width: 100px;
            height: 100px;
            margin-bottom: 10px;
          }

          & /deep/ .el-button {
            color: #ffffff;
            background-color: #e20000;
            margin: 10px 0px 20px 0px;
          }


        }

        .right-bottom {
          display: flex;
          align-items: center;
          justify-content: center;

          .dynamic-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 15px;
            cursor: pointer;

            .number {
              margin-bottom: 5px;
              color: #303030;
            }

            .title {
              color: #b5b5b5;
            }

            &:last-child {
              margin-right: 0px;
            }

          }

        }
      }

      &:last-child {
        font-size: 14px;
        padding: 30px 30px;
        box-sizing: border-box;

        .dynamic-item {
          width: 150px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #dcdcdc;
          margin-bottom: 15px;
          cursor: pointer;

          .title {
            color: #b5b5b5;
          }

          .number {
            color: #303030;
          }

        }

      }

    }

  }

}
</style>
