import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    BMap: null,
    token: '',
    userInfo: {
      place: {
        value: '',
        label: ''
      },
      userType: '',
      username: '',
      gender: '',
      profilePhoto: '',
    },
    areasOptions: [],
    shareId: '',  // 弃用了
    shareJobId: '',
    shareArea: '',  // 弃用了
    invitationCode: '',

    // toSessionUserId: '',  // 会话双方中的对方的 userID
    // currentSessionId: '',  // 当前选中的会话 conversationID
    hrCurrentRepresentative: {
      label: '',
      value: '',
    },  // hr当前代表的岗位
    hrPublishJobList: [],
    hrSelectArea: [],
    hrSelectCityCode: '',
    isEditedResume: null,  // 求职者是否编辑过简历

    aonuoChat: null,
    aonuoChatReady: false,
    myChatUserId: '',
    temporaryMyChatUserId: '',  // 临时用户id
    chatSessionId: '',
    chatSessionInfo: null,
    chatSession: null,

    messageUnreadCount: 0,
    joinUnreadCount: 0,

    jobSeekerFirst: true,

    automaticGreetingIsOpen: true,  // 自动打招呼
    greetingsList: [],  // 问候语列表
    automaticGreetingsForSpecificPositions: [],  // 针对特定岗位的问候语列表
    currentAutomaticGreetings: '',  // 当前选中的打招呼语
  },
  /* 同步的方式设置state中的值 */
  mutations: {
    /**
     * 设置 vuex 的 automaticGreetingIsOpen
     * */
    setAutomaticGreetingIsOpen(state, automaticGreetingIsOpen) {
      state.automaticGreetingIsOpen = automaticGreetingIsOpen.automaticGreetingIsOpen
    },
    /**
     * 设置 vuex 的 greetingsList
     * */
    setGreetingsList(state, greetingsList) {
      state.greetingsList = greetingsList.greetingsList
    },
    /**
     * 设置 vuex 的 automaticGreetingsForSpecificPositions
     * */
    setAutomaticGreetingsForSpecificPositions(state, automaticGreetingsForSpecificPositions) {
      state.automaticGreetingsForSpecificPositions = automaticGreetingsForSpecificPositions.automaticGreetingsForSpecificPositions
    },
    /**
     * 设置 vuex 的 currentAutomaticGreetings
     * */
    setCurrentAutomaticGreetings(state, currentAutomaticGreetings) {
      state.currentAutomaticGreetings = currentAutomaticGreetings.currentAutomaticGreetings
    },

    /**
     * 设置 vuex 的 jobSeekerFirst
     * */
    setJobSeekerFirst(state, jobSeekerFirst) {
      state.jobSeekerFirst = jobSeekerFirst.jobSeekerFirst
    },

    /**
     * 设置 vuex 的 BMap
     * */
    setBMap(state, BMap) {
      state.BMap = BMap.BMap
    },

    /**
     * 设置 vuex 的 messageUnreadCount
     * */
    setMessageUnreadCount(state, messageUnreadCount) {
      state.messageUnreadCount = messageUnreadCount.messageUnreadCount
    },

    /**
     * 设置 vuex 的 joinUnreadCount
     * */
    setJoinUnreadCount(state, joinUnreadCount) {
      state.joinUnreadCount = joinUnreadCount.joinUnreadCount
    },

    /**
     * 设置 vuex 的 aonuoChat
     * */
    setAonuoChat(state, aonuoChat) {
      state.aonuoChat = aonuoChat.aonuoChat
    },

    /**
     * 设置 vuex 的 aonuoChatReady
     * */
    setAonuoChatReady(state, aonuoChatReady) {
      state.aonuoChatReady = aonuoChatReady.aonuoChatReady
    },

    /**
     * 设置 vuex 的 myChatUserId
     * */
    setMyChatUserId(state, myChatUserId) {
      state.myChatUserId = myChatUserId.myChatUserId
    },

    /**
     * 设置 vuex 的 temporaryMyChatUserId
     * */
    setTemporaryMyChatUserId(state, temporaryMyChatUserId) {
      state.temporaryMyChatUserId = temporaryMyChatUserId.temporaryMyChatUserId
    },

    /**
     * 设置 vuex 的 chatSessionId
     * */
    setChatSessionId(state, chatSessionId) {
      state.chatSessionId = chatSessionId.chatSessionId
    },

    /**
     * 设置 vuex 的 chatSessionInfo
     * */
    setChatSessionInfo(state, chatSessionInfo) {
      state.chatSessionInfo = chatSessionInfo.chatSessionInfo
    },

    /**
     * 设置 vuex 的 chatSession
     * */
    setChatSession(state, chatSession) {
      state.chatSession = chatSession.chatSession
    },


    /**
     * 设置 vuex 的 token
     * */
    setToken(state,token) {
      state.token = token.token
    },

    /**
     * 设置 vuex 的 invitationCode
     * */
    setInvitationCode(state,invitationCode) {
      state.invitationCode = invitationCode.invitationCode
    },

    /**
     * 设置 vuex 的 shareId
     * */
    setShareId(state,shareId) {
      state.shareId = shareId.shareId
    },

    /**
     * 设置 vuex 的 shareJobId
     * */
    setShareJobId(state,shareJobId) {
      state.shareJobId = shareJobId.shareJobId
    },

    /**
     * 设置 vuex 的 shareArea
     * */
    setShareArea(state,shareArea) {
      state.shareArea = shareArea.shareArea
    },

    /**
     * 设置 vuex 的 currentSessionId
     * */
    setCurrentSessionId(state,currentSessionId) {
      state.currentSessionId = currentSessionId.currentSessionId
    },

    /**
     * 设置 vuex 的 toSessionUserId
     * */
    setToSessionUserId(state,toSessionUserId) {
      state.toSessionUserId = toSessionUserId.toSessionUserId
    },

    /**
     * 设置 vuex 的 areasOptions
     * */
    setAreasOptions(state,areasOptions) {
      state.areasOptions = areasOptions.areasOptions
    },

    /**
     * 设置 vuex 的 hrSelectArea
     * */
    setHrSelectArea(state,hrSelectArea) {
      state.hrSelectArea = hrSelectArea.hrSelectArea
    },

    /**
     * 设置 vuex 的 hrSelectCityCode
     * */
    setHrSelectCityCode(state,hrSelectCityCode) {
      state.hrSelectCityCode = hrSelectCityCode.hrSelectCityCode
    },

    /**
     * 设置 vuex 的 isEditedResume
     * */
    setIsEditedResume(state,isEditedResume) {
      state.isEditedResume = isEditedResume.isEditedResume
    },

    /**
     * 设置 vuex 的 hrCurrentRepresentative
     * */
    setHRCurrentRepresentative(state,hrCurrentRepresentative) {
      state.hrCurrentRepresentative = hrCurrentRepresentative.hrCurrentRepresentative
    },

    /**
     * 设置 vuex 的 hrPublishJobList
     * */
    setHrPublishJobList(state,hrPublishJobList) {
      state.hrPublishJobList = hrPublishJobList.hrPublishJobList
    },

    /**
    * 设置 vuex 的 userinfo
    * */
    setUserinfo(state,userInfo) {
      if (userInfo.place !== undefined) {
        state.userInfo.place = userInfo.place
      }
      if (userInfo.userType !== undefined) {
        state.userInfo.userType = userInfo.userType
      }
      if (userInfo.username !== undefined) {
        state.userInfo.username = userInfo.username
      }
      if (userInfo.profilePhoto !== undefined) {
        state.userInfo.profilePhoto = userInfo.profilePhoto
      }
      if (userInfo.gender !== undefined) {
        state.userInfo.gender = userInfo.gender
      }
    }
  },
  /* 异步的方式设置state中的值 */
  actions: {
  },
  modules: {
  }
})
