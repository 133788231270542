<template>
  <div class="hrSendJobCardItem_warp" @click="handleClickItem">
    <div class="left">
      <div class="title">
        <div class="name">
          {{jobName}}
        </div>
        <div class="red-envelope" v-if="jobType === 1">
          红包
        </div>
      </div>
      <div class="information">
        <div class="place">
          <div class="city">
            {{jobAddress[1]}}
          </div>
          ·
          <div class="area">
            {{jobAddress[2]}}
          </div>
        </div>

        <div class="education">
          {{academicRequirement}}
        </div>

        <div class="age">
          {{newAgeRequirementRange}}
        </div>

        <div class="experience">
          {{newExperienceRequirementRange}}
        </div>

        <div class="salary">
          {{newSalaryRange}}
        </div>

      </div>
    </div>
    <div class="right">
      <div class="btn" @click.stop="handleClickAction">
        发送
      </div>
    </div>
  </div>
</template>

<script>
import {experience, ageRange} from '@/share/shareData'
export default {
  name: "hrSendJobCardItem",
  props: {
    jobId: {  // 岗位id
      type: [String, Number],
      default: ''
    },
    jobType: {  // 岗位类型
      type: [String, Number],
      default: '',
    },
    jobName: {  // 岗位名
      type: String,
      default: ''
    },
    salaryRange: {  // 薪资待遇范围
      type: Array,
      default: () => []
    },
    experienceRequirementRange: {  // 经验要求范围
      type: Array,
      default: () => []
    },
    jobAddress: {  // 岗位省市区
      type: Array,
      default: () => []
    },
    academicRequirement: {  // 学历要求
      type: [String, Number],
      default: ''
    },
    seeCount: {  // 查看数
      type: [String, Number],
      default: ''
    },
    communityCount: {  // 沟通数
      type: [String, Number],
      default: ''
    },
    applyRedPocketCount: {  // 红包申请数
      type: [String, Number],
      default: ''
    },
    publishTime: {  // 发布时间
      type: String,
      default: ''
    },
    jobStatus: {  // 岗位状态
      type: [String, Number],
      default: ''
    },
    isClose: {  // 是否已关闭
      type: Boolean,
      default: false
    },
    expireDate: {  // 岗位过期时间
      type: String,
      default: ''
    },
    ageRequirementRange: {  // 年龄要求
      type: Array,
      default: () => []
    },
    detailAddress: {  // 岗位详细地址
      type: String,
      default: ''
    },
    jobWelfare: {  // 岗位福利
      type: Array,
      default: () => []
    },
    description: {  // 岗位描述
      type: String,
      default: ''
    },
  },
  data() {
    return {}
  },
  computed: {
    newExperienceRequirementRange() {
      let label = ''
      if (this.experienceRequirementRange && this.experienceRequirementRange.length > 1) {
        let value = this.experienceRequirementRange[0] + '-' + this.experienceRequirementRange[1]
        experience.forEach((item, index) => {
          if (item.value === value) {
            label = item.label + '经验'
          }
        })
      }else {
        label = '经验不限'
      }
      return label
    },
    newSalaryRange() {
      let label = ''
      if (this.salaryRange && this.salaryRange.length > 1) {
        label = this.salaryRange[0] + '-' + this.salaryRange[1]
      }else {
        label = '面议'
      }

      return label
    },
    newAgeRequirementRange() {
      let label = ''
      if (this.ageRequirementRange && this.ageRequirementRange.length > 1) {
        // let value = this.ageRequirementRange[0] + '-' + this.ageRequirementRange[1]
        // ageRange.forEach((item, index) => {
        //   if (item.value === value) {
        //     label = item.label
        //   }
        // })

        label = this.ageRequirementRange[0] + '岁-' + this.ageRequirementRange[1] + '岁'
      }else {
        label = '年龄不限'
      }
      return label
    },

  },
  methods: {
    handleClickItem() {
      this.$emit('handleClickItem', this.jobId)
    },
    handleClickAction() {
      let jobInfo = {
        jobId: this.jobId,  // 岗位id
        jobType: this.jobType,  // 岗位类型，枚举值
        jobName: this.jobName,  // 岗位名字
        jobAddress: this.jobAddress,  // 工作地点列表
        detailAddress: this.detailAddress,  // 详细地址
        salaryRange: this.newSalaryRange,  // 薪资待遇
        experienceRequirementRange: this.newExperienceRequirementRange,  // 工作经验
        ageRequirementRange: this.newAgeRequirementRange,  // 年龄要求
        academicRequirement: this.academicRequirement,  // 学历要求
        jobWelfare: this.jobWelfare,  // 岗位福利
        description: this.description,  // 岗位描述
      }
      this.$emit('handleClickAction', jobInfo)
    }
  }
}
</script>

<style lang="less" scoped>
.hrSendJobCardItem_warp {
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #999999;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;

    .title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .name {
        max-width: 400px;
        font-size: 20px;
        margin-right: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .red-envelope {
        color: #e20000;
        border: 1px solid #e20000;
        padding: 1px 6px;
        box-sizing: border-box;
        font-size: 14px;
      }
    }

    .information {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 10px;
      font-size: 14px;

      .place {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-right: 20px;
        .city {
          margin-right: 5px;
          max-width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .area {
          margin-left: 5px;
          max-width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .education {
        margin-right: 20px;
      }

      .age {
        margin-right: 20px;
      }

      .experience {
        margin-right: 20px;
      }
    }
  }
  .right {
    width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      width: 80px;
      height: 35px;
      border-radius: 5px;
      font-size: 16px;
      background-color: #e20000;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
</style>