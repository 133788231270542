<template>
  <div class="previewImageOrVideo_warp">
    <el-dialog :title="title" :visible.sync="newPreviewIsShow" custom-class="previewAlbumImgDialog">
      <img v-if="previewType === 'image'" width="100%" :src="url" alt="">
      <video v-if="previewType === 'video'" style="max-width: 100%;" controls autoplay :src="url">
        您的浏览器不支持 video 标签。
      </video>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "previewImageOrVideo",
  props: {
    title: {
      type: String,
      default: '相册'
    },
    previewType: {
      type: String,
      default: 'image'
    },
    url: {
      type: String,
      default: ''
    },
    previewIsShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newPreviewIsShow: false,
    }
  },
  watch: {
    previewIsShow: {
      handler: function () {
        this.newPreviewIsShow = this.previewIsShow
      },
      immediate: true,
    },
    newPreviewIsShow() {
      this.$emit('update:previewIsShow', this.newPreviewIsShow)
    }
  }
}
</script>

<style lang="less" scoped>
.previewImageOrVideo_warp {
  
}
</style>