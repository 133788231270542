<template>
  <div class="editMyResume_warp" v-loading="pageLoading">

    <div class="actions">
      <div class="title">
        在线编辑简历
      </div>
      <el-button type="text" @click="previewMyResume">
        <img src="@/assets/icon/printing.png">预览
      </el-button>
    </div>

    <div class="content">

      <el-form ref="myResumeDataFormRef" :model="myResumeData" label-width="80px" :rules="formRules">

        <div class="form-card">

          <div class="title">
            上传头像
          </div>

          <div class="main">
              <div class="upload-btn" @click="uploadHeadPortrait">
                <img v-if="imgDataUrl"
                     :src="imgDataUrl">
                <div v-else>
                  <i class="el-icon-plus"></i>
                </div>
              </div>

              <my-upload field="image"
                         :width="110"
                         :height="110"
                         img-format="jpg"
                         :max-size="1024 * 20"
                         :url="uploadUrl"
                         :headers="headers"
                         :noCircle="false"
                         :modelValue.sync="show"
                         @src-file-set="srcFileSet"
                         @crop-success="cropSuccess"
                         @crop-upload-success="cropUploadSuccess"
                         @crop-upload-fail="cropUploadFail"
              ></my-upload>

<!--              <div class="desc">-->
<!--                注:上传头像成功后会立即修改当前头像-->
<!--              </div>-->

          </div>

        </div>

        <div class="form-card">

          <div class="title">
            个人信息
          </div>

          <div class="main">

            <el-row :gutter="20">
              <el-col :span="12">

                <el-form-item label="姓名" prop="name">
                  <el-input
                      v-model.trim="myResumeData.name" clearable :disabled="isRealName"
                      placeholder="请输入"
                  >
                  </el-input>
                </el-form-item>

                <el-form-item label="性别" prop="gender">
                  <el-radio v-model="myResumeData.gender" label="male" border  :disabled="isRealName">男</el-radio>
                  <el-radio v-model="myResumeData.gender" label="female" border  :disabled="isRealName">女</el-radio>
                </el-form-item>

<!--                <el-form-item label="邮箱">-->
<!--                  <el-input-->
<!--                      v-model="myResumeData.personalInformation.email"-->
<!--                      clearable-->
<!--                  >-->
<!--                  </el-input>-->
<!--                </el-form-item>-->

<!--                <el-form-item label="手机号">-->
<!--                  <el-input-->
<!--                      v-model="myResumeData.personalInformation.phone"-->
<!--                      clearable-->
<!--                  >-->
<!--                  </el-input>-->
<!--                </el-form-item>-->

              </el-col>
              <el-col :span="12">

                <el-form-item label="求职状态" prop="jobSearchingStatus">
                  <el-select v-model="myResumeData.jobSearchingStatus">
                    <div v-for="(item0_2, index0_2) in jobSearchingStatus" :key="'jobSearchingStatus_index0' + index0_2">
                      <el-option
                          :label="item0_2.label"
                          :value="item0_2.value"
                      >
                      </el-option>
                    </div>
                  </el-select>
                </el-form-item>

                <el-form-item label="出生日期" prop="birthday">
                  <el-date-picker
                      v-model="myResumeData.birthday"
                      placeholder="请输入"
                      value-format="yyyy-MM-dd"
                      type="date"
                      clearable
                      :disabled="isRealName"
                      :picker-options="pickerOptions"
                      :default-value="defaultValue"
                  >
                  </el-date-picker>
                </el-form-item>

<!--                <el-form-item label="微信">-->
<!--                  <el-input-->
<!--                      v-model="myResumeData.personalInformation.weChat"-->
<!--                      clearable-->
<!--                  >-->
<!--                  </el-input>-->
<!--                </el-form-item>-->


              </el-col>
            </el-row>

          </div>

        </div>

        <div class="form-card">

          <div class="title">
            自我介绍
          </div>

          <div class="main">

            <el-row :gutter="20">
              <el-col :span="24">

                <el-form-item>
                  <el-input
                      type="textarea"
                      :rows="3"
                      maxlength="200"
                      show-word-limit
                      v-model="myResumeData.selfIntroduction"
                      placeholder="请输入"
                  >
                  </el-input>
                </el-form-item>

              </el-col>
            </el-row>

          </div>

        </div>

        <div class="form-card">

          <div class="title">
            亮点
          </div>

          <div class="main">

            <el-row :gutter="20">
              <el-col :span="24">

                <el-form-item label="个人亮点">

                  <el-row class="personalHighLightList">
                    <el-col :span="24">
                      <el-tag
                          v-for="(tag, index) in myResumeData.personalHighLightList"
                          :key="'personalHighLightList' + index"
                          closable
                          :disable-transitions="false"
                          @close="personalHighLightClose(tag)"
                      >
                        {{tag}}
                      </el-tag>

<!--                      <el-input-->
<!--                          class="input-new-tag"-->
<!--                          v-if="inputVisible"-->
<!--                          v-model="inputValue"-->
<!--                          ref="saveTagInput"-->
<!--                          size="small"-->
<!--                          maxlength="8"-->
<!--                          @keyup.enter.native="handleInputConfirm"-->
<!--                          @blur="handleInputConfirm"-->
<!--                      >-->
<!--                      </el-input>-->
<!--                      <el-button v-if="!inputVisible" class="button-new-tag" size="small" @click="showInput">+ 添 加</el-button>-->

                    </el-col>
                  </el-row>

                  <el-row class="personalHighLightList">
                    <el-col :span="24">
                      <span class="span">选项列表：</span>
                      <el-tag
                          v-for="(tag2, index) in defaultDynamicTags"
                          :key="'defaultDynamicTags' + index"
                          :closable="false"
                          type="info"
                          :disable-transitions="false"
                          @click="defaultDynamicTagsClick(tag2)"
                      >
                        {{tag2}}
                      </el-tag>
                    </el-col>
                  </el-row>
                </el-form-item>

              </el-col>
            </el-row>

          </div>

        </div>

        <div class="form-card">

          <div class="title">
            学历
          </div>

          <div class="main">

            <el-row :gutter="20">
              <el-col :span="24">

                <el-form-item label="学历" prop="academicLevelMax">

                  <el-select v-model="myResumeData.academicLevelMax">
                    <div v-for="(item0_1, index0_1) in academicLevel" :key="'academicLevel' + index0_1">
                      <el-option
                          :label="item0_1.label"
                          :value="item0_1.value"
                      >
                      </el-option>
                    </div>
                  </el-select>
                </el-form-item>

              </el-col>
            </el-row>

          </div>

        </div>

        <div class="form-card">

          <div class="title">
            求职期望
          </div>

          <div class="main">

            <el-row :gutter="20">
              <el-col :span="12">

                <el-form-item label="期望岗位" prop="expectedJobList">
                  <el-cascader
                      v-model="myResumeData.expectedJobList"
                      key="expectedJobList"
                      @change="workDutiesChangeValue"
                      :show-all-levels="false"
                      popper-class="work_duties_item"
                      :props="{
                        expandTrigger: 'click',
                        multiple: true,
                        emitPath: false,
                        checkStrictly: false,
                        lazy: true,
                        lazyLoad: getJobGetJobCategoryList
                      }"
                      clearable
                  ></el-cascader>
                </el-form-item>


              </el-col>
              <el-col :span="12">

                <el-form-item label="期望地点" prop="expectedJobAddressList">
                  <el-cascader
                      v-model="myResumeData.expectedJobAddressList"
                      key="expectedJobAddressList"
                      :options="areasOptions"
                      :show-all-levels="false"
                      @change="workDutiesChangeValue"
                      popper-class="work_duties_item"
                      :props="{
                        expandTrigger: 'hover',
                        multiple: true,
                        emitPath: false,
                        value: 'value',
                        checkStrictly: false
                      }"
                      clearable
                  ></el-cascader>
                </el-form-item>

              </el-col>
            </el-row>

            <el-row class="special-row">
              <el-col :span="7">
                <el-form-item label="期望薪资" prop="expectedSalaryMin">

                  <el-select v-model="myResumeData.expectedSalaryMin" placeholder="请选择" @change="expectedSalaryMinChange">
                    <el-option
                        v-for="(item, index) in expectedSalaryRangeOptions"
                        :key="'expectedSalary' + index"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                  </el-select>

<!--                  <el-input-->
<!--                      type="number"-->
<!--                      v-model.trim="myResumeData.expectedSalaryMin"-->
<!--                      placeholder="请输入"-->
<!--                      clearable-->
<!--                  >-->
<!--                    <template slot="prepend">-->
<!--                    <span class="RMB_class">-->
<!--                      ¥-->
<!--                    </span>-->
<!--                    </template>-->
<!--                    <template slot="append">-->
<!--                    <span class="RMB_class">-->
<!--                      元-->
<!--                    </span>-->
<!--                    </template>-->
<!--                  </el-input>-->

                </el-form-item>
              </el-col>
              <el-col :span="17" class="special-col">
                <el-form-item label="至" prop="expectedSalaryMax">

                  <el-select v-model="myResumeData.expectedSalaryMax" placeholder="请选择" @change="expectedSalaryMaxChange">
                    <el-option
                        v-for="(item, index) in expectedSalaryRangeOptions"
                        :key="'expectedSalary' + index"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                  </el-select>

<!--                  <el-input-->
<!--                      type="number"-->
<!--                      v-model.trim="myResumeData.expectedSalaryMax"-->
<!--                      placeholder="请输入"-->
<!--                      clearable-->
<!--                  >-->
<!--                    <template slot="prepend">-->
<!--                    <span class="RMB_class">-->
<!--                      ¥-->
<!--                    </span>-->
<!--                    </template>-->
<!--                    <template slot="append">-->
<!--                    <span class="RMB_class">-->
<!--                      元-->
<!--                    </span>-->
<!--                    </template>-->
<!--                  </el-input>-->

                </el-form-item>
              </el-col>
            </el-row>

          </div>

        </div>

        <div class="form-card">

          <div class="title">
            工作经历

            <div class="form-card-actions">
              <el-button type="text" size="mini" @click="addFormItem('workExperienceList')" icon="el-icon-plus">新增</el-button>
            </div>

          </div>

          <div class="main main_card" v-for="(item2, index2) in myResumeData.workExperienceList" :key="'workExperienceList' + index2">

            <div class="title-min">
              工作经历{{item2.id + 1}}
              <div class="form-card-actions">
                <el-button type="text" size="mini" @click="delFormItem('workExperienceList', item2.id)" icon="el-icon-delete">删除</el-button>
              </div>
            </div>

            <el-row :gutter="20">
              <el-col :span="12">

                <el-form-item label="企业" :prop="'workExperienceList['+item2.id+'].enterpriseName'">
                  <el-input
                      v-model.trim="item2.enterpriseName"
                      placeholder="请输入"
                      clearable
                  >
                  </el-input>
                </el-form-item>

              </el-col>
              <el-col :span="12">

                <el-form-item label="岗位" :prop="'workExperienceList['+item2.id+'].jobName'">
                  <el-input
                      v-model.trim="item2.jobName"
                      placeholder="请输入"
                      clearable
                  >
                  </el-input>
                </el-form-item>

              </el-col>
            </el-row>

            <el-row :gutter="0">

              <el-col :span="12">
                <el-form-item label="开始日期" :prop="'workExperienceList['+item2.id+'].startDate'">
                  <el-date-picker
                      v-model="item2.startDate"
                      value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="请选择"
                      clearable
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="结束日期" :prop="'workExperienceList['+item2.id+'].endDate'">
                  <el-date-picker
                      v-model="item2.endDate"
                      value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="请选择"
                      clearable
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>

            </el-row>

            <el-row :gutter="0">

              <el-col :span="24">
                <el-form-item label="工作内容" :prop="'workExperienceList['+item2.id+'].description'">
                  <el-input
                      type="textarea"
                      :rows="3"
                      v-model="item2.description"
                      placeholder="请输入"
                  >
                  </el-input>
                </el-form-item>
              </el-col>

            </el-row>

          </div>

        </div>

        <div class="form-card">

          <div class="title">
            教育经历

            <div class="form-card-actions">
              <el-button type="text" size="mini" @click="addFormItem('educationExperienceList')" icon="el-icon-plus">新增</el-button>
            </div>

          </div>

          <div class="main main_card" v-for="(item3, index3) in myResumeData.educationExperienceList" :key="'educationalExperience' + index3">

            <div class="title-min">
              教育经历{{item3.id + 1}}
              <div class="form-card-actions">
                <el-button type="text" size="mini" @click="delFormItem('educationExperienceList', item3.id)" icon="el-icon-delete">删除</el-button>
              </div>
            </div>

            <el-row :gutter="20">
              <el-col :span="12">

                <el-form-item label="学校" :prop="'educationExperienceList['+item3.id+'].schoolName'">
                  <el-input
                      v-model.trim="item3.schoolName"
                      placeholder="请输入"
                      clearable
                  >
                  </el-input>
                </el-form-item>

                <el-form-item label="学历" :prop="'educationExperienceList['+item3.id+'].academicLevel'">

                  <el-select v-model="item3.academicLevel">
                    <div v-for="(item3_1, index3_1) in academicLevel" :key="'academicLevelMax' + index3_1">
                      <el-option
                          :label="item3_1.label"
                          :value="item3_1.value"
                      >
                      </el-option>
                    </div>
                  </el-select>
                </el-form-item>

              </el-col>
              <el-col :span="12">

                <el-form-item label="专业" :prop="'educationExperienceList['+item3.id+'].major'">
                  <el-input
                      v-model.trim="item3.major"
                      placeholder="请输入"
                      clearable
                  >
                  </el-input>
                </el-form-item>

              </el-col>
            </el-row>

            <el-row :gutter="0">

              <el-col :span="12">
                <el-form-item label="开始日期" :prop="'educationExperienceList['+item3.id+'].startDate'">
                  <el-date-picker
                      v-model="item3.startDate"
                      value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="请输入"
                      clearable
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="结束日期" :prop="'educationExperienceList['+item3.id+'].endDate'">
                  <el-date-picker
                      v-model="item3.endDate"
                      value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="请输入"
                      clearable
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>

            </el-row>

            <el-row :gutter="0">

              <el-col :span="24">
                <el-form-item label="在校经历" :prop="'educationExperienceList['+item3.id+'].description'">
                  <el-input
                      type="textarea"
                      :rows="3"
                      v-model="item3.description"
                      placeholder="请输入"
                  >
                  </el-input>
                </el-form-item>
              </el-col>

            </el-row>

          </div>

        </div>

        <div class="form-card">

          <div class="title">
            证书信息

            <div class="form-card-actions">
              <el-button type="text" size="mini" @click="addFormItem('certificateList')" icon="el-icon-plus">新增</el-button>
            </div>

          </div>

          <div class="main main_card" v-for="(item4, index4) in myResumeData.certificateList" :key="'certificateList' + index4">

            <div class="title-min">
              证书信息{{item4.id + 1}}
              <div class="form-card-actions">
                <el-button type="text" size="mini" @click="delFormItem('certificateList', item4.id)" icon="el-icon-delete">删除</el-button>
              </div>
            </div>


            <el-row :gutter="20">
              <el-col :span="12">

                <el-form-item label="证书名" :prop="'certificateList['+item4.id+'].name'">
                  <el-input
                      v-model.trim="item4.name"
                      placeholder="请输入"
                      clearable
                  >
                  </el-input>
                </el-form-item>

              </el-col>
              <el-col :span="12">

                <el-form-item label="获取时间" :prop="'certificateList['+item4.id+'].getDate'">
                  <el-date-picker
                      v-model="item4.getDate"
                      value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="请输入"
                      clearable
                  >
                  </el-date-picker>
                </el-form-item>

              </el-col>
            </el-row>

          </div>

        </div>

        <div class="btn">
          <el-button :loading="btnLoading" @click="formCancel">取 消</el-button>
          <el-button :loading="btnLoading" @click="formSubmit">保 存</el-button>
        </div>

      </el-form>

    </div>

  </div>
</template>

<script>
import myUpload from 'vue-image-crop-upload'
import {
  industry,
  areas,
  cityAreas,
  jobType,
  jobSearchingStatus,
  education,
} from '@/share/shareData'
import {formRules} from '@/share/formRules'
import {BASE_URL} from '@/config/baseURL'
import {
  AccountManagementApi,
  GetResumeEditApi,
  GetJobGetJobCategoryListApi,
  UpDataResumeEditApi,
  PostPersonalHighLightOptionListApi,
  GetRegionGetPopularRegionListApi
} from "@/request/api";
import {mapMutations} from "vuex";
import share from "@/share/share";

export default {
  name: "editMyResume",
  components: {
    myUpload
  },
  data() {
    return {
      pageLoading: false,
      uploadUrl: BASE_URL + '/user-files/upload-image/',
      btnLoading: false,
      headers: {},
      isRealName: false,  // 是否实名认证过
      show: false,
      workDutiesOptions: industry,
      areasOptions: [],
      jobType: jobType,
      jobSearchingStatus: jobSearchingStatus,
      academicLevel: education,
      academicLevelMax: '',

      template: {
        // jobIntentionList: {  // 求职意向
        //   jobIntentionId: 0,
        //   expectedSalary: '',  // 期望薪资
        //   place: '',  // 地点
        //   expectedCategory: '',  // 职能
        //   expectedProperty: '',  // 工作性质
        // },
        workExperienceList: {  // 工作经历
          workExperienceId: 0,
          enterpriseName: '',  // 企业名
          jobTitle: '',  // 岗位名称
          startDate: '',  // 开始时间
          endDate: '',  // 结束时间
          description: '',  // 描述
        },
        educationExperienceList: {  // 教育经历
          educationExperienceId: 0,
          schoolName: '',  // 学校名
          major: '',  // 专业
          academicLevel: '',  // 学历
          startDate: '',  // 开始时间
          endDate: '',  // 结束时间
          description: '',
        },
        certificateList: {  // 证书信息
          certificateId: 0,
          name: '',  // 证书名
          getDate: '',  // 获取证书的时间
        }
      },

      myResumeData: {},

      expectedSalaryRangeOptions: [
        {
          value: 1000,
          label: '1000'
        },
        {
          value: 2000,
          label: '2000'
        },
        {
          value: 3000,
          label: '3000'
        },
        {
          value: 4000,
          label: '4000'
        },
        {
          value: 5000,
          label: '5000'
        },
        {
          value: 6000,
          label: '6000'
        },
        {
          value: 7000,
          label: '7000'
        },
        {
          value: 8000,
          label: '8000'
        },
        {
          value: 9000,
          label: '9000'
        },
        {
          value: 10000,
          label: '10000'
        },
        {
          value: 11000,
          label: '11000'
        },
        {
          value: 12000,
          label: '12000'
        },
        {
          value: 13000,
          label: '13000'
        },
        {
          value: 14000,
          label: '14000'
        },
        {
          value: 15000,
          label: '15000'
        },
        {
          value: 16000,
          label: '16000'
        },
        {
          value: 17000,
          label: '17000'
        },
        {
          value: 18000,
          label: '18000'
        },
        {
          value: 19000,
          label: '19000'
        },
        {
          value: 20000,
          label: '20000'
        },
        {
          value: 21000,
          label: '21000'
        },
        {
          value: 22000,
          label: '22000'
        },
        {
          value: 23000,
          label: '23000'
        },
        {
          value: 24000,
          label: '24000'
        },
        {
          value: 25000,
          label: '25000'
        },
        {
          value: 26000,
          label: '26000'
        },
      ],

      // formRules: formRules,
      formRulesTemplate: {
        // jobIntentionList: {  // 求职意向
        //   expectedSalary: [
        //     { required: true, message: '期望薪资不能为空' }
        //   ],
        //   jobAddress: [
        //     { required: true, message: '地点不能为空' }
        //   ],
        //   expectedProperty: [
        //     { required: true, message: '工作性质不能为空' }
        //   ],
        //   expectedCategory: [
        //     { required: true, message: '职能不能为空' }
        //   ],
        // },
        workExperienceList: {  // 工作经历
          enterpriseName: [
            { required: true, message: '企业名不能为空' }
          ],
          jobName: [
            { required: true, message: '岗位名不能为空' }
          ],
          startDate: [
            { required: true, message: '开始时间不能为空' }
          ],
          endDate: [
            { required: true, message: '结束时间不能为空' }
          ],
          description: [
            { required: true, message: '工作内容描述不能为空' }
          ],
        },

        educationExperienceList: {  // 教育经历
          schoolName: [
            { required: true, message: '学校名不能为空' }
          ],
          academicLevel: [
            { required: true, message: '学历名不能为空' }
          ],
          major: [
            { required: true, message: '专业不能为空' }
          ],
          startDate: [
            { required: true, message: '开始不能为空' }
          ],
          endDate: [
            { required: true, message: '结束时间不能为空' }
          ],
          description: [
            { required: true, message: '在校经历描述不能为空' }
          ],
        },

        certificateList: {  // 证书信息
          name: [
            { required: true, message: '证书名不能为空' }
          ],
          getDate: [
            { required: true, message: '获取时间不能为空' }
          ],
        },

      },
      formRules: {
        name: [
          { required: true, message: '姓名不能为空' }
        ],
        gender: [
          { required: true, message: '性别不能为空' }
        ],
        academicLevelMax: [
          { required: true, message: '学历不能为空' }
        ],
        jobSearchingStatus: [
          { required: true, message: '求职状态不能为空' }
        ],
        birthday: [
          { required: true, message: '出生日期不能为空' }
        ],
        expectedJobList: [
          {required: true, validator: this.expectedCategoryRules}
        ],
        expectedSalaryMin: [
          {required: true, validator: this.salaryMinRules}
        ],
        expectedSalaryMax: [
          {required: true, validator: this.salaryMaxRules}
        ],
        expectedJobAddressList: [
          {required: true, validator: this.jobAddressRules}
        ],

        workExperienceList: [],  // 工作经历
        educationExperienceList: [],  // 教育经历
        certificateList: [],  // 证书信息
      },
      imgDataUrl: '',

      defaultDynamicTags: [],
      inputVisible: false,
      inputValue: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > (Date.now() - 10 * 365 * 24 * 60 * 60 * 1000)
        }
      },
      defaultValue: Date.now() - 20 * 365 * 24 * 60 * 60 * 1000,

      openProvinceList: [],
      openCityList: [],
    }
  },
  computed: {
    profilePhoto() {
      return this.$store.state.userInfo.profilePhoto
    }
  },
  watch: {
    'myResumeData.expectedJobList'() {
      this.getPersonalHighLightOptionList()
    }
  },
  created() {
    // this.academicLevel = education.slice(1,education.length)
  },
  async mounted() {
    this.pageLoading = true
    await this.getRegionGetPopularRegionList()
    this.areasOptions = this.filterArea(areas)
    const res = await AccountManagementApi()

    if (res) {
      this.isRealName = res.isRealNameAuthentication
    }
    let myResumeData = this.$route.params.myResumeData || ''

    await this.loadData(myResumeData)
    await this.getPersonalHighLightOptionList()
    this.pageLoading = false
  },
  methods: {
    ...mapMutations(['setUserinfo', 'setIsEditedResume']),
    /**
     * 获取当前开放地区
     * */
    async getRegionGetPopularRegionList() {
      const res = await GetRegionGetPopularRegionListApi()

      if (res) {
        let arr = []
        let arr2 = []
        res.dataList.forEach((item, index) => {
          arr.push({
            provinceCode: item.provinceCode,
            provinceName: item.provinceName,
          })
          arr2.push({
            cityCode: item.code,
            cityName: item.name,
          })
        })
        this.openProvinceList = arr
        this.openCityList = arr2
      }
    },
    /**
     * 过滤地区
     * */
    filterArea(areaArr) {
      if (this.openProvinceList.length > 0 && this.openCityList.length > 0) {
        let provinceArr = []
        areaArr.forEach((item, index) => {
          this.openProvinceList.forEach((item2, index2) => {
            if (item.value === item2.provinceCode) {
              provinceArr.push(item)
            }
          })
        })
        let arr = []
        provinceArr.forEach((item, index) => {
          if (item.children) {
            let cityArr = []
            item.children.forEach((item2, index2) => {
              this.openCityList.forEach((item3, index3) => {
                if (item2.value === item3.cityCode) {
                  cityArr.push(item2)
                }
              })
            })
            arr.push({
              i: index,
              children: cityArr
            })
          }
        })
        arr.forEach((item, index) => {
          provinceArr[item.i].children = item.children
        })
        return provinceArr
      }else {
        return areaArr
      }
    },
    /**
    * 获取岗位各级类别
    * */
    async getJobGetJobCategoryList(node, resolve) {
      if (node.level <= 2){
        let leaf = false
        let parentId = ''
        if (node.level !== 0) {
          parentId = node.data.id
        }
        if (node.level === 2) {
          leaf = true
        }
        const res = await GetJobGetJobCategoryListApi({
          parentId: parentId
        })

        let arr = []
        if (res) {
          res.dataList.forEach((item, index) => {
            arr.push({
              id: item.id,
              label: item.name,
              value: item.name,
              leaf: leaf,
            })
          })
        }
        resolve(arr)
      }else {
        resolve([])
      }
    },
    async loadData(param) {
      let res = {}
      if (param) {
        res.employeeInformation = param
      }else {
        res = await GetResumeEditApi()
      }


      let myResumeData = {}
      myResumeData.name = res.employeeInformation.name  // 姓名
      myResumeData.age = res.employeeInformation.age  // 年龄
      myResumeData.birthday = res.employeeInformation.birthday  // 生日
      let academicLevelMax = parseInt(res.employeeInformation.academicLevelMax)  // 最高学历
      if(academicLevelMax === 1) {
        myResumeData.academicLevelMax = 10
      }else {
        myResumeData.academicLevelMax = academicLevelMax
      }
      myResumeData.jobSearchingStatus = res.employeeInformation.jobSearchingStatus  // 求职状态
      myResumeData.gender = res.employeeInformation.gender  // 性别
      myResumeData.expectedJobList = res.employeeInformation.expectedJobList  // 期望岗位列表
      myResumeData.expectedJobAddressList = res.employeeInformation.expectedJobAddressListCode  // 期望工作地点
      myResumeData.personalHighLightList = res.employeeInformation.personalHighLightList  // 个人亮点
      myResumeData.selfIntroduction = res.employeeInformation.selfIntroduction  // 自我介绍
      if (param) {
        myResumeData.expectedSalaryMin = res.employeeInformation.expectedSalaryMin || ''  // 最高工资
        myResumeData.expectedSalaryMax = res.employeeInformation.expectedSalaryMax || ''  // 最低工资
      }else {
        myResumeData.expectedSalaryMin = res.employeeInformation.expectedSalaryRange[0] || ''  // 最高工资
        myResumeData.expectedSalaryMax = res.employeeInformation.expectedSalaryRange[1] || ''  // 最低工资
      }
      myResumeData.workExperienceYears = res.employeeInformation.workExperienceYears  // 最高工资年数

      myResumeData.workExperienceList = []  // 工作经历
      myResumeData.educationExperienceList = []  // 教育经历
      myResumeData.certificateList = []  // 证书列表

      this.myResumeData = myResumeData

      this.initData()

      res.employeeInformation.workExperienceList.forEach((item, index) => {
        this.addFormItem('workExperienceList', '', item)
      })

      res.employeeInformation.educationExperienceList.forEach((item, index) => {
        this.addFormItem('educationExperienceList', '', item)
      })

      res.employeeInformation.certificateList.forEach((item, index) => {
        this.addFormItem('certificateList', '', item)
      })

      this.imgDataUrl = this.$store.state.userInfo.profilePhoto
    },
    async getPersonalHighLightOptionList() {
      let arr = this.myResumeData.expectedJobList || []
      const res = await PostPersonalHighLightOptionListApi({
        expectedJobList: JSON.stringify(arr)
      })
      if (res) {
        this.defaultDynamicTags = res.personalHighLightList
      }
    },
    initData() {
      if (!this.myResumeData) {
        return
      }
      // if (this.myResumeData.jobIntentionList.length <= 0) {
      //   let obj = JSON.parse(JSON.stringify(this.template.jobIntentionList))
      //   this.myResumeData.jobIntentionList.push(obj)
      // }
      // if (this.myResumeData.workExperienceList.length <= 0) {
      //   let obj = JSON.parse(JSON.stringify(this.template.workExperienceList))
      //   this.myResumeData.workExperienceList.push(obj)
      // }
      // if (this.myResumeData.educationExperienceList.length <= 0) {
      //   let obj = JSON.parse(JSON.stringify(this.template.educationExperienceList))
      //   this.myResumeData.educationExperienceList.push(obj)
      // }
      // if (this.myResumeData.certificateList.length <= 0) {
      //   let obj = JSON.parse(JSON.stringify(this.template.certificateList))
      //   this.myResumeData.certificateList.push(obj)
      // }
      if (this.myResumeData.expectedSalaryRange && this.myResumeData.expectedSalaryRange.length > 0) {
        this.myResumeData.expectedSalaryMin = this.myResumeData.expectedSalaryRange[0]
        this.myResumeData.expectedSalaryMax = this.myResumeData.expectedSalaryRange[1]
      }
      // this.myResumeData.jobIntentionList = this.initArr(this.myResumeData.jobIntentionList)
      this.myResumeData.workExperienceList = this.initArr(this.myResumeData.workExperienceList)
      this.myResumeData.educationExperienceList = this.initArr(this.myResumeData.educationExperienceList)
      this.myResumeData.certificateList = this.initArr(this.myResumeData.certificateList)
    },
    initRules(arr = [], key) {
      let rules = JSON.parse(JSON.stringify(this.formRulesTemplate[key]))
      arr.forEach((item, index) => {
        this.formRules[key].push(rules)
      })
    },
    initArr(arr = [], key) {
      let newArr = arr
      newArr.forEach((item, index) => {
        this.$set(item,'id', index)

        // if (isInit) {
        //   let timeFrame = []
        //   timeFrame.push(item.startDate)
        //   timeFrame.push(item.endDate)
        //   this.$set(item,'timeFrame', timeFrame)
        // }

      })
      return newArr
    },
    uploadHeadPortrait() {
      this.show = !this.show
    },
    srcFileSet(fileName, fileType, fileSize) {
    },
    cropSuccess(imgDataUrl, field) {
    },
    cropUploadSuccess(jsonData, field) {
      this.imgDataUrl = jsonData.data.url;
      // this.setUserinfo({
      //   profilePhoto: this.imgDataUrl
      // })
    },
    cropUploadFail(status, field) {
    },
    // beforeAvatarUpload(file) {
    //
    //
    //   const isJPG = file.type === 'image/jpeg';
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //
    //   if (!isJPG) {
    //     this.$message.error('上传头像图片只能是 JPG 格式!');
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('上传头像图片大小不能超过 2MB!');
    //   }
    //   return isJPG && isLt2M;
    // }

    workDutiesChangeValue() {
    },
    jobIntentionPlaceChange() {
    },
    addFormItem(key, id, param) {
      let obj = JSON.parse(JSON.stringify(this.template[key]))
      let rules = JSON.parse(JSON.stringify(this.formRulesTemplate[key]))
      if (key !== 'jobIntentionList') {

        if (param) {
          obj = param
        }

        this.myResumeData[key].push(obj)

        this.formRules[key].push(rules)

      }else {
        if (this.myResumeData.jobIntentionList.length < 3) {
          this.myResumeData[key].push(obj)
        }
      }
      this.initData()
    },

    delFormItem(key, id) {
      this.myResumeData[key].forEach((item, index) => {
        if (item.id === id) {
          this.myResumeData[key].splice(index,1)
        }
      })
      this.formRules[key].splice(this.formRules[key].length,1)
      this.initData()
    },

    formSubmit() {

      this.$refs.myResumeDataFormRef.validate(async res => {
        if (res) {

          let logoPath = share.initImageUrlPath(this.imgDataUrl)

          let myResumeData = JSON.parse(JSON.stringify(this.myResumeData))

          let nowDate = new Date().getTime()
          let workState1 = false
          let workState2 = false
          let workState3 = false
          myResumeData.workExperienceList.forEach((item, index) => {
            let startDate = new Date(item.startDate).getTime()
            let endDate = new Date(item.endDate).getTime()
            if (startDate > nowDate) {
              workState2 = true
            }
            if (endDate > nowDate) {
              workState3 = true
            }
            if (startDate >= endDate) {
              workState1 = true
            }
          })
          if (workState3) {
            share.message.warning('工作经历的结束日期不能大于当前日期')
            return
          }
          if (workState2) {
            share.message.warning('工作经历的开始日期不能大于当前日期')
            return
          }
          if (workState1) {
            share.message.warning('工作经历的开始日期不能大于结束日期')
            return
          }

          let educationState1 = false
          let educationState2 = false
          let educationState3 = false
          myResumeData.educationExperienceList.forEach((item, index) => {
            let startDate = new Date(item.startDate).getTime()
            let endDate = new Date(item.endDate).getTime()
            if (startDate > nowDate) {
              educationState2 = true
            }
            if (endDate > nowDate) {
              educationState3 = true
            }
            if (startDate >= endDate) {
              educationState1 = true
            }
          })
          if (educationState3) {
            share.message.warning('教育经历的结束日期不能大于当前日期')
            return
          }
          if (educationState2) {
            share.message.warning('教育经历的开始日期不能大于当前日期')
            return
          }
          if (educationState1) {
            share.message.warning('教育经历的开始日期不能大于结束日期')
            return
          }

          let certificateState1 = false
          myResumeData.certificateList.forEach((item, index) => {
            let getDate = new Date(item.getDate).getTime()
            if (getDate > nowDate) {
              certificateState1 = true
            }
          })
          if (certificateState1) {
            share.message.warning('证书信息的获取日期不能大于当前日期')
            return
          }
          // let param = JSON.parse(JSON.stringify(myResumeData))
          // myResumeData.jobIntentionList = JSON.stringify(myResumeData.jobIntentionList)

          myResumeData.expectedSalaryRange = []
          myResumeData.expectedSalaryRange.push(myResumeData.expectedSalaryMin)
          myResumeData.expectedSalaryRange.push(myResumeData.expectedSalaryMax)

          myResumeData.avatarPath = logoPath
          myResumeData.expectedJobList = JSON.stringify(myResumeData.expectedJobList)
          myResumeData.expectedJobAddressList = JSON.stringify(myResumeData.expectedJobAddressList)
          myResumeData.personalHighLightList = JSON.stringify(myResumeData.personalHighLightList)
          myResumeData.expectedSalaryRange = JSON.stringify(myResumeData.expectedSalaryRange)
          myResumeData.workExperienceList = JSON.stringify(myResumeData.workExperienceList)
          myResumeData.educationExperienceList = JSON.stringify(myResumeData.educationExperienceList)
          myResumeData.certificateList = JSON.stringify(myResumeData.certificateList)

          this.btnLoading = true

          let res3 = await UpDataResumeEditApi({...myResumeData})

          if (res3) {
            let url = share.initImageUrl(this.imgDataUrl)
            this.setUserinfo({
              profilePhoto:  url
            })
            this.setIsEditedResume({
              isEditedResume: true
            })
          }

          this.btnLoading = false

          this.setUserinfo({
            username: this.myResumeData.name,
          })

          this.$router.push('/myResume')

        }
      })
    },
    formCancel() {
      this.$router.push('/myResume')
    },
    previewMyResume() {
      this.$router.push({
        name: 'myResume',
        params: {
          myResumeData: this.myResumeData,
        }
      })
    },

    personalHighLightClose(tag) {
      this.myResumeData.personalHighLightList.splice(this.myResumeData.personalHighLightList.indexOf(tag), 1)
    },

    showInput() {
      if (this.myResumeData.personalHighLightList.length >= 8) {
        share.message.error('最多添加8个个人亮点')
        return
      }
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    defaultDynamicTagsClick(tag) {
      if (this.myResumeData.personalHighLightList && this.myResumeData.personalHighLightList.length >= 8) {
        share.message.error('最多添加8个个人亮点')
        return
      }
      let state = false
      this.myResumeData.personalHighLightList.forEach((item, index) => {
        if (item === tag) {
          state = true
        }
      })
      if (!state) {
        this.myResumeData.personalHighLightList.push(tag)
      }
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.myResumeData.personalHighLightList.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },


    jobAddressRules(rule, value, callback) {
      if (value && value.length > 0) {
        if (value.length > 3) {
          callback(new Error('期望工作地点最多为3个'))
        }else {
          callback()
        }
      } else {
        callback(new Error('期望工作地点不能为空'))
      }
    },
    expectedCategoryRules(rule, value, callback) {
      if (value && value.length > 0) {
        if (value.length > 3) {
          callback(new Error('期望岗位最多为3个'))
        }else {
          callback()
        }
      } else {
        callback(new Error('期望岗位不能为空'))
      }
    },
    /* 最小薪资验证 */
    salaryMinRules(rule, value, callback) {
      let re = /^[1-9]+[0-9]*]*$/
      if (value === '') {
        callback(new Error('请输入期望薪资'))
      } else if (!re.test(value)) {
        callback(new Error('金额只能为正整数'))
      } else if (parseInt(this.myResumeData.expectedSalaryMax) <= parseInt(this.myResumeData.expectedSalaryMin)) {
        callback(new Error('薪资范围最小值要小于最大值'))
      } else {
        callback()
      }
    },
    /* 最大薪资验证 */
    salaryMaxRules(rule, value, callback) {
      let re = /^[1-9]+[0-9]*]*$/
      if (value === '') {
        callback(new Error('请输入期望薪资'))
      } else if (!re.test(value)) {
        callback(new Error('薪资只能为正整数'))
      } else if (parseInt(this.myResumeData.expectedSalaryMax) <= parseInt(this.myResumeData.expectedSalaryMin)) {
        callback(new Error('薪资范围最大值要大于最小值'))
      } else {
        callback()
      }
    },
    expectedSalaryMinChange() {
      this.$refs['myResumeDataFormRef'].validateField('expectedSalaryMin', res => {

      })
      this.$refs['myResumeDataFormRef'].validateField('expectedSalaryMax', res => {

      })
    },
    expectedSalaryMaxChange() {
      this.$refs['myResumeDataFormRef'].validateField('expectedSalaryMin', res => {

      })
      this.$refs['myResumeDataFormRef'].validateField('expectedSalaryMax', res => {

      })
    },
  }
}
</script>

<style lang="less" scoped>
.editMyResume_warp {
  width: 100%;
  //height: 100%;
  background-color: #ffffff;
  padding-bottom: 40px;
  box-sizing: border-box;

  .actions {
    width: 1000px;
    height: 40px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #c9c7c8;

    .title {
      color: #707070;
      font-weight: bold;
      padding-left: 15px;
      font-size: 16px;
    }

    .el-button {
      color: #707070;
      font-size: 18px;

      & /deep/ span {
        width: 100%;
        height: 25px;
        line-height: 25px;

        img {
          width: 25px;
          height: 25px;
          position: relative;
          top: 5px;
          margin-right: 5px;
        }
      }

    }
  }

  .content {
    width: 1000px;
    margin: 0 auto;

    .el-form {

      .form-card {
        border-bottom: 1px solid #c9c7c8;
        padding: 20px 0;
        box-sizing: border-box;

        //&:hover {
        //  .title {
        //    .form-card-actions {
        //      visibility: visible;
        //    }
        //  }
        //}

        .title {
          color: #e20000;
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .form-card-actions {
            visibility: visible;
          }

        }

        .main_card {
          background-color: #f4f2f3;
          margin-bottom: 10px;
        }

        .main {
          padding: 0 10px;
          box-sizing: border-box;

          .special-row {

            .special-col {
              & /deep/ .el-form-item__label {
                text-align: center;
              }
            }

          }

          .title-min {
            color: #e20000;
            font-size: 12px;
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .form-card-actions {
              visibility: hidden;
            }
          }

          &:hover {
            .form-card-actions {
              visibility: visible;
            }
          }

          .form-card-actions {
            visibility: hidden;
          }

          .upload-btn {
            width: 110px;
            height: 110px;
            border: 1px dashed #d9d9d9;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
              border-color: #409EFF;

              > div {
                color: #409EFF;
              }
            }

            > img {
              width: 100%;
              height: 100%;
            }

            > div {
              font-size: 30px;
              color: #8c939d;
            }

          }

          & /deep/ .el-form-item {

            .el-form-item__label {
              color: #3f3f3f;
            }


          }

          .yuan-item {

            .yuan-icon {
              font-size: 20px;
              font-weight: bold;
              width: 25px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

          }

          .desc {
            font-size: 14px;
            color: #E6A23C;
            margin-top: 10px;
          }

          .el-tag + .el-tag {
            margin-left: 10px;
          }
          .button-new-tag {
            margin-left: 10px;
            height: 32px;
            line-height: 30px;
            padding-top: 0;
            padding-bottom: 0;
          }
          .input-new-tag {
            width: 90px;
            margin-left: 10px;
            vertical-align: bottom;
          }

        }

        .personalHighLightList {
          .el-tag {
            cursor: pointer;
          }

          .span {
            font-size: 14px;
            color: #909399;
          }
        }

      }

      .btn {
        height: 60px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }

    }

  }
}
</style>
<style lang="less">
.work_duties_item {
  .el-cascader-menu {
    height: 200px !important;
  }
}

.job-intention-place {
  .el-cascader-menu {
    height: 400px !important;
  }
}
</style>
