<template>
  <div class="likeJobItem_warp" @click="goJobDetailed">

    <div class="left">

      <div class="name">
        <div class="name-text">
          {{jobName}}
        </div>
        <span>
          {{salary}}
        </span>
      </div>

      <div class="place" v-if="jobAddress && jobAddress.length">
<!--        <span>{{jobAddress[0]}}</span><span>· {{jobAddress[1]}}</span><span>· {{jobAddress[2]}}</span>-->
        <span>{{jobAddress[2]}}</span>
      </div>


    </div>

    <div class="right">
      <el-avatar shape="square" size="large" :src="newEnterpriseLogoUrl"></el-avatar>
    </div>

  </div>
</template>

<script>
import share from "@/share/share";

export default {
  name: "likeJobItem",
  props: {
    jobId: {
      type: [String, Number],
      default: ''
    },
    jobName: {
      type: String,
      default: ''
    },
    salaryRange: {
      type: Array,
      default: () => []
    },
    jobAddress: {
      type: Array,
      default: () => []
    },
    logo: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    newEnterpriseLogoUrl() {
      return share.initImageUrl(this.logo)
    },
    salary() {
      let str = ''
      if (this.salaryRange && this.salaryRange.length > 0) {
        // str = this.salaryRange[0] + '-' + this.salaryRange[1]
        str = parseInt(this.salaryRange[0])/1000 + 'k-' + parseInt(this.salaryRange[1])/1000 + 'k'
      }else {
        str = '面议'
      }
      return str
    }
  },
  methods: {
    goJobDetailed() {
      this.$emit('goJobDetailed', this.jobId)
    }
  }
}
</script>

<style lang="less" scoped>
.likeJobItem_warp {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  box-sizing: border-box;
  font-size: 14px;

  .left {
    .name {
      height: 25px;
      display: flex;

      .name-text {
        margin-right: 10px;
        max-width: 200px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }

      span {
        color: #fd1514;
      }

    }
  }

  .right {
    height: 100%;
    display: flex;
    align-items: center;
  }

}
</style>
