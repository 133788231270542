<template>
  <div class="historicalOperationRecords_warp">
    <div class="title">
      推广操作记录
    </div>
    <div class="filter">
      <div class="row">
        <div class="label">
          操作时间：
        </div>
        <div class="value">
          <el-radio-group v-model="operationTimeRadio" @change="operationTimeRadioChange">
            <el-radio :label="1">今日</el-radio>
            <el-radio :label="2">近七天</el-radio>
            <el-radio :label="3">近一个月</el-radio>
            <el-radio :label="4">自定义</el-radio>
            <div v-if="operationTimeRadio === 4" class="date-range">
              <el-date-picker
                  v-model="operationTimeDateRange"
                  size="medium"
                  type="daterange"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  :clearable="false"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                  @change="operationTimeDateRangeChange">
              </el-date-picker>
            </div>
          </el-radio-group>
        </div>
      </div>
      <div class="row">
        <div class="label">
          操作类别：
        </div>
        <div class="value">
          <div class="check-all">
            <el-checkbox :indeterminate="operatingClassIsIndeterminate" v-model="operatingClassCheckAll" @change="handleCheckAllChange">全选</el-checkbox>
          </div>
          <el-checkbox-group v-model="operatingClass" @change="operatingClassChange">
            <el-checkbox :label="1">新增推广</el-checkbox>
            <el-checkbox :label="2">暂停推广</el-checkbox>
            <el-checkbox :label="3">开启推广</el-checkbox>
            <el-checkbox :label="4">删除推广</el-checkbox>
            <el-checkbox :label="5">修改设置</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="row">
        <div class="label">
          搜索：
        </div>
        <div class="value">
          <el-input v-model="searchValue" size="medium" placeholder="请输入岗位名" clearable></el-input>
        </div>
      </div>
      <div class="row">
        <el-button size="medium" @click="searchSubmit">搜 索</el-button>
      </div>
    </div>
    <div class="content" v-loading="pageLoading">
      <div class="table-header">
        <download-excel
            class = "export-excel-wrapper"
            :data = "json_data"
            :fields = "json_fields"
            name = "推广操作记录导出.xls">
          <el-button type="text" size="medium"><span style="color: #e20000;">导出数据</span></el-button>
        </download-excel>
      </div>
      <el-table
          :data="tableData"
          style="width: 100%">
        <el-table-column
            prop="createTime"
            label="操作时间">
        </el-table-column>
        <el-table-column
            prop="name"
            label="岗位名称">
        </el-table-column>
        <el-table-column
            prop="type"
            label="操作类型">
        </el-table-column>
        <el-table-column
            prop="detail"
            label="说明">
        </el-table-column>
      </el-table>
      <div class="pagination" v-if="tableData.length > 0">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[15, 20, 30, 40]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {GetJobPromoteOperateHistoryListApi} from '@/request/api'
import JsonExcel from "vue-json-excel";
export default {
  name: "historicalOperationRecords",
  components: {'download-excel': JsonExcel},
  data() {
    return {
      pageLoading: false,
      operationTimeRadio: 1,  // 操作时间
      operationTimeDateRange: [],  // 操作时间
      disabledDate: Date.now(),
      enableDisableDate: false,
      pickerOptions: {},
      finOperationTimeDateRange: [],  // 操作时间
      operatingClass: [1, 2, 3, 4, 5],  // 操作类别
      operatingClassCheckAll: true,
      operatingClassIsIndeterminate: false,
      searchValue: '',
      tableData: [],
      pageSize: 15,
      pageNum: 1,
      total: 0,
      json_fields: {},
      json_data: [],
    }
  },
  created() {
    this.pickerOptions = {
      disabledDate: (time) => {
        if (this.enableDisableDate) {
          return ((time.getTime() < (this.disabledDate - 30 * 24 * 60 * 60 * 1000)) || (time.getTime() > (this.disabledDate + 30 * 24 * 60 * 60 * 1000)))
        }else {
          return false
        }
      },
      onPick: ({minDate, maxDate}) => {
        if (minDate && maxDate) {
          this.disabledDate = Date.now()
          this.enableDisableDate = false
        }else {
          this.disabledDate = minDate.getTime()
          this.enableDisableDate = true
        }
      }
    }
  },
  async mounted() {
    let date = new Date()
    let nowDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    this.operationTimeDateRange = [nowDate, nowDate]
    this.finOperationTimeDateRange = [nowDate, nowDate]
    await this.getJobPromoteOperateHistoryList()
  },
  methods: {
    async getJobPromoteOperateHistoryList() {
      this.pageLoading = true
      const res = await GetJobPromoteOperateHistoryListApi({
        type: JSON.stringify(this.operatingClass),
        time: JSON.stringify(this.finOperationTimeDateRange),
        jobName: this.searchValue,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      })
      this.pageLoading = false
      if (res) {
        this.tableData = res.operateHistoryInfoList
        this.numOfPages = res.pageNum
        this.total = res.totalNum

        let json_fields = {
          '操作时间': 'date',
          '岗位名称': 'name',
          '操作类型': 'type',
          '说明': 'detail',
        }
        let json_data = []
        res.operateHistoryInfoList.forEach((item, index) => {
          let obj = {
            date: item.createTime,  // 操作时间
            name: item.name,  // 岗位名称
            type: item.type,  // 操作类型
            detail: item.detail,  // 说明
          }
          json_data.push(obj)
        })
        this.json_fields = json_fields
        this.json_data = json_data
      }
    },
    operationTimeRadioChange() {
      let data = new Date()
      let dataStr = data.getFullYear() + '-' + (data.getMonth() + 1) + '-' + data.getDate()
      if (this.operationTimeRadio === 1) {
        this.finOperationTimeDateRange = [dataStr, dataStr]
      }
      if (this.operationTimeRadio === 2) {
        let startDate = new Date(data.setDate(data.getDate() - 7))
        let startDateStr = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate()
        this.finOperationTimeDateRange = [startDateStr, dataStr]
      }
      if (this.operationTimeRadio === 3) {
        let startDate = new Date(data.setDate(data.getDate() - 30))
        let startDateStr = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate()
        this.finOperationTimeDateRange = [startDateStr, dataStr]
      }
      if (this.operationTimeRadio === 4) {
        this.finOperationTimeDateRange = [...this.operationTimeDateRange]
      }
    },
    operationTimeDateRangeChange() {
      this.finOperationTimeDateRange = [...this.operationTimeDateRange]
    },
    handleCheckAllChange() {
      this.operatingClassIsIndeterminate = false
      if (this.operatingClassCheckAll) {
        this.operatingClass = [1, 2, 3, 4, 5]
      }else {
        this.operatingClass = []
      }
    },
    operatingClassChange() {
      if (this.operatingClass.length === 5) {
        this.operatingClassCheckAll = true
        this.operatingClassIsIndeterminate = false
      }else if (this.operatingClass.length > 0) {
        this.operatingClassCheckAll = false
        this.operatingClassIsIndeterminate = true
      }else {
        this.operatingClassCheckAll = false
        this.operatingClassIsIndeterminate = false
      }
    },
    searchSubmit() {
      this.getJobPromoteOperateHistoryList()
    },
    handleSizeChange(value) {
      this.pageSize = value
      this.getJobPromoteOperateHistoryList()
    },
    handleCurrentChange(value) {
      this.pageNum = value
      this.getJobPromoteOperateHistoryList()
    },
  }
}
</script>

<style lang="less" scoped>
.historicalOperationRecords_warp {
  width: 100%;
  padding: 30px 30px;
  box-sizing: border-box;
  .title {
    font-size: 18px;
    color: #3a3a3a;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .filter {
    width: 100%;
    .row {
      width: 100%;
      height: 40px;
      font-size: 14px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .label {

      }
      .value {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .check-all {
          margin-right: 30px;
        }
        .date-range {
          display: inline-block;
        }
      }
    }
  }
  .content {
    border-top: 2px solid #e7e9ef;
    .table-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
</style>
