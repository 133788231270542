<template>
  <div class="jobSeekerHome_warp">
    <div class="header">
      <page-header :curr-page="currHeaderMenu" @changeCurrPage="changeCurrPage"
                   backgroundColor="#eeeeee" @areaChange="areaChange" @chooseJobValueChangeSuccess="chooseJobValueChangeSuccess"></page-header>
    </div>

    <div class="search">
      <div class="search-content">
        <div class="icon">
          <img src="@/assets/icon/logo.png">
        </div>
        <div class="search-input">
          <search-input placeholder="搜索关键词" :defSearchValue="searchValue" @searchValueInput="searchValueInput"
                        @searchSubmit="searchSubmit"></search-input>
        </div>
      </div>
    </div>

    <div class="content">

      <div class="user-info">
        <div class="user-info-left">
          <el-avatar class="profile-photo" size="large">
            <img :src="profilePhoto"/>
          </el-avatar>
          <div class="text" v-if="isLogin">欢迎您！{{username}}</div>
          <div class="text" v-if="!isLogin">登录</div>
        </div>
        <div class="user-info-right" @click="goPostJob">
          <img src="@/assets/icon/plus.png">
          发布新岗位
        </div>
      </div>

      <div class="main">
        <div class="main-left">

<!--          <div class="filter card">-->
<!--            <div class="title selectTitle">-->
<!--              <div class="text">岗位切换（当前的岗位快捷卡片）</div>-->
<!--            </div>-->

<!--            <div class="filter-item">-->
<!--              <el-cascader-->
<!--                  v-model="chooseJobValue"-->
<!--                  :options="jobValueOptions"-->
<!--                  :show-all-levels="false"-->
<!--                  placeholder="当前未发布岗位"-->
<!--                  popper-class="jobValue_filter_item"-->
<!--                  :props="{-->
<!--                  expandTrigger: 'hover',-->
<!--                }"-->
<!--                  collapse-tags-->
<!--                  clearable-->
<!--              ></el-cascader>-->
<!--              <el-select v-model="chooseJobValue" placeholder="请选择" @change="chooseJobValueClick">-->
<!--                <el-option-->
<!--                    v-for="(item, index) in jobValueOptions"-->
<!--                    :key="'jobValueOptions' + index"-->
<!--                    :label="item.label"-->
<!--                    :value="item.value">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </div>-->
<!--          </div>-->

          <div class="filter card">
            <div class="title selectTitle">
              <div class="text">岗位切换</div>
            </div>

            <div class="filter-item">
              <lazy-select :options="jobValueOptions" :auto-complete="true" :new-value="chooseJobValue" :label="jobName" :no-more="jobValueOptionsNoMore" @loadData="loadMoreMyPublishedJob" @change="chooseJobValueClick"></lazy-select>
            </div>
          </div>

          <div class="filter card">
            <div class="title">
              <div class="text">年龄过滤</div>
            </div>
            <div class="age-range-filter">
              <div class="rang-num" v-if="ageRangeSlider && ageRangeSlider.length > 1">
                <span>{{ageRangeSlider[0]}}岁</span>
                <span>{{ageRangeSlider[1]}}岁</span>
              </div>
              <div class="slider">
                <el-slider
                    v-model="ageRangeSlider"
                    range
                    :min="16"
                    :max="60">
                </el-slider>
              </div>
              <div class="btn">
                <el-button size="mini" @click="ageRangeSliderChange">提 交</el-button>
              </div>
            </div>
          </div>

          <div class="filter card">
            <div class="title">
              <div class="text">更多选择</div>
              <div class="des" @click="clearAllFilterValue">清空所有筛选项</div>
            </div>
            <job-filter ref="jobSeekerFilterRef" @jobFilterChange="jobFilterChange"></job-filter>

          </div>

          <div class="card" id="scanJobSeekerRecord" v-loading="pageLoading">
            <job-seeker-card title="我看过的招聘者" :job-seeker-list="jobSeekerList" @clickTitle="goDynamic()"
                             @clickItem="goJobSeekerDetailed"></job-seeker-card>
          </div>

        </div>
        <div class="main-right">
          <router-view ref="jobSeekerHomeRouterView" :search-value="searchValue" :job-filter="jobFilter" :job-id="chooseJobValue"
                       :job-name="jobName" :current-position-information="currentPositionInformationDetails"></router-view>
        </div>
      </div>
    </div>

    <el-dialog
        title="您当前未加入或创建企业"
        :visible.sync="enterpriseInformationIsNull"
        width="400px">
      <span>是否立即去加入或创建企业</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="enterpriseInformationIsNull = false">取 消</el-button>
        <el-button type="primary" @click="goEnterpriseCenter">确 定</el-button>
      </span>
    </el-dialog>


    <el-dialog
        title="您当前加入企业的申请还在审核中"
        :visible.sync="isHaveApplyingJoinEnterprise"
        width="400px">
      <span>是否立即去查看</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isHaveApplyingJoinEnterprise = false">取 消</el-button>
        <el-button type="primary" @click="goEnterpriseCenter">确 定</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>
import PageHeader from "@/components/pageHeader/pageHeader";
import Search from "@/views/search/search";
import SearchInput from "@/components/searchInput/searchInput";
import JobFilter from "@/components/jobFilter/jobFilter";
import JobCard from "@/components/jobCard/jobCard";
import share from '@/share/share'
import {
  SeeEmployeeRecordApi,
  MyPublishedJobApi,
  HrStatusApi,
  GetHrCheckJoinEnterpriseApplyApi,
  JobHrSeeJobDetailApi
} from '@/request/api'
import JobSeekerCard from "@/components/jobSeekerCard/jobSeekerCard";
import {mapMutations} from "vuex";
import LazySelect from "@/components/lazySelect/lazySelect";

let defaultProfilePhoto = require('@/assets/icon/profile-photo.png')
export default {
  name: "jobSeekerHome",
  components: {LazySelect, JobSeekerCard, JobCard, JobFilter, SearchInput, Search, PageHeader},
  data() {
    return {
      pageLoading: true,
      currHeaderMenu: 1,
      searchValue: '',
      chooseJobValue: '',
      jobName: '',
      jobValueOptionsPage: {
        pageNumber: 1,
        pageSize: 20,
      },
      jobValueOptionsNoMore: true,
      jobValueOptions: [],
      jobList: [],
      jobFilter: {},

      jobSeekerList: [],

      ageRangeSlider: [16, 60],

      enterpriseInformationIsNull: false,
      isHaveApplyingJoinEnterprise: false,

      currentPositionInformationDetails: null,  // 当前hr代表的的岗位信息
    }
  },
  computed: {
    isLogin() {
      let token = this.$store.state.token
      let state = false
      if (token && token !== '' && token !== null && token !== 'null') {
        state = true
      }
      return state
    },
    username() {
      return this.$store.state.userInfo.username || ''
    },
    profilePhoto() {
      return this.$store.state.userInfo.profilePhoto || defaultProfilePhoto
    },
    hrCurrentRepresentative() {
      return this.$store.state.hrCurrentRepresentative || {}
    },
    place() {
      return this.$store.state.userInfo.place
    },
    jobSeekerFirst() {
      return this.$store.state.jobSeekerFirst
    }
  },
  watch: {
    chooseJobValue() {
      this.initCurrentPositionInformationDetails()
    }
  },
  created() {
    this.searchValue = this.$route.query.searchValue || ''
  },
  async mounted() {
    // let loading = share.loadingShow({
    //   id: '#scanJobSeekerRecord',
    //   background: '#f4f2f3',
    // })
    let jobSeekerFilter = sessionStorage.getItem('jobSeekerFilter')
    if (jobSeekerFilter) {
      this.jobFilter = JSON.parse(jobSeekerFilter)
      this.ageRangeSlider = this.jobFilter.ageRange
      this.$refs.jobSeekerFilterRef.init(this.jobFilter)
    }
    let jobSeekerSearchValue = sessionStorage.getItem('jobSeekerSearchValue')
    if (jobSeekerSearchValue) {
      this.searchValue = jobSeekerSearchValue
    }
    await this.loadData()
    // await this.loadSeeEmployeeRecordData()
    // await this.loadUserStatusData()

    // loading.close()
  },
  methods: {
    ...mapMutations(['setHRCurrentRepresentative', 'setJoinUnreadCount', 'setHrPublishJobList', 'setJobSeekerFirst']),
    async loadData() {
      const res = await Promise.all([await this.loadMyJobData(), this.loadSeeEmployeeRecordData(), this.loadUserStatusData()])

    },
    async loadUserStatusData() {
      const res = await HrStatusApi()
      if (res) {
        if (!res.enterpriseId && this.jobSeekerFirst) {
          if (res.applyingJoinEnterprise) {
            this.isHaveApplyingJoinEnterprise = true
          }else {
            this.enterpriseInformationIsNull = true
          }
          this.setJobSeekerFirst({
            jobSeekerFirst: false
          })
        }
        if (res.isAdmin) {
          const res2 = await GetHrCheckJoinEnterpriseApplyApi({
            pageNum: 1,
            pageSize: 10000
          })
          if (res2) {
            let hrApplyJoinList = res2.recordList
            let newJoin = []
            hrApplyJoinList.forEach((item, index) => {
              if (item.applyStatus === 1) {
                newJoin.push(item)
              }
            })
            this.setJoinUnreadCount({
              count: newJoin.length
            })
          }
        }
      }
    },
    async loadMyJobData() {
      const res = await MyPublishedJobApi({
        pageNum: this.jobValueOptionsPage.pageNumber,
        pageSize: this.jobValueOptionsPage.pageSize,
        jobStatus: 3,
      })
      if (res) {
        if (this.jobValueOptionsPage.pageNumber >= res.numOfPages) {
          this.jobValueOptionsNoMore = true
        }else {
          this.jobValueOptionsNoMore = false
        }

        let newArr = []
        let arr = res.jobList
        this.jobList = res.jobList
        // this.setHrPublishJobList({
        //   hrPublishJobList: res.jobList
        // })
        arr.forEach((item, index) => {
          let obj = {
            label: item.jobName,
            value: item.jobId
          }
          newArr.push(obj)
        })
        this.jobValueOptions = newArr
      }
      this.jobValueOptionsPage.pageNumber++
      await this.initJobValueOptions()
      // this.initCurrentPositionInformationDetails()
    },
    async loadMoreMyPublishedJob(callback) {
      if (this.jobValueOptionsNoMore) {
        return;
      }
      const res = await MyPublishedJobApi({
        pageNum: this.jobValueOptionsPage.pageNumber,
        pageSize: this.jobValueOptionsPage.pageSize,
        jobStatus: 3,
      })
      if (res) {
        if (this.jobValueOptionsPage.pageNumber >= res.numOfPages) {
          this.jobValueOptionsNoMore = true
        }else {
          this.jobValueOptionsNoMore = false
        }

        let newArr = []
        let arr = res.jobList
        this.jobList = this.jobList.concat(res.jobList)
        arr.forEach((item, index) => {
          let obj = {
            label: item.jobName,
            value: item.jobId
          }
          newArr.push(obj)
        })
        this.jobValueOptions = this.jobValueOptions.concat(newArr)
      }
      this.jobValueOptionsPage.pageNumber++
      callback()
    },
    async initJobValueOptions() {
      if (this.jobValueOptions.length > 0) {
        let jobInfo = null
        if (this.hrCurrentRepresentative.value) {
          const res = await JobHrSeeJobDetailApi({
            jobId: this.hrCurrentRepresentative.value,
          })
          if (res && res.jobInformation && !res.jobInformation.isClose && res.jobInformation.jobStatus === 3) {
            this.chooseJobValue = this.hrCurrentRepresentative.value
            this.jobName = this.hrCurrentRepresentative.label
            jobInfo = res.jobInformation
          }else {
            this.chooseJobValue = this.jobValueOptions[0].value
            this.jobName = this.jobValueOptions[0].label
            jobInfo = this.jobList[0]
          }
        }else {
          this.chooseJobValue = this.jobValueOptions[0].value
          this.jobName = this.jobValueOptions[0].label
          jobInfo = this.jobList[0]
        }
        let obj = {
          label: this.jobName,
          value: this.chooseJobValue
        }
        this.setHRCurrentRepresentative({
          hrCurrentRepresentative: {
            label: null,
            value: null
          }
        })
        this.$nextTick(() => {
          this.setHRCurrentRepresentative({
            hrCurrentRepresentative: obj
          })
        })

        let salary = ''
        if (jobInfo.salaryRange.length > 0) {
          salary = parseInt(jobInfo.salaryRange[0])/1000 + 'k-' + parseInt(jobInfo.salaryRange[1])/1000 + 'k'
        }else {
          salary = '面议'
        }
        let address = jobInfo.jobAddress[1] + jobInfo.jobAddress[2]
        let obj2 = {
          jobName: jobInfo.jobName,
          salary: salary,
          address: address,
        }
        this.currentPositionInformationDetails = obj2

      }else {
        this.chooseJobValue = ''
        this.jobName = ''
        this.setHRCurrentRepresentative({
          hrCurrentRepresentative: {
            label: '',
            value: '',
          }
        })
        this.$nextTick(() => {
          this.$refs.jobSeekerHomeRouterView.submit()
        })
      }

    },
    initCurrentPositionInformationDetails() {
      if (this.chooseJobValue !== undefined && this.chooseJobValue !== '') {
        this.jobList.forEach((item, index) => {
          if (item.jobId === this.chooseJobValue) {
            let salary = ''
            if (item.salaryRange.length > 0) {
              salary = parseInt(item.salaryRange[0])/1000 + 'k-' + parseInt(item.salaryRange[1])/1000 + 'k'
            }else {
              salary = '面议'
            }
            let address = item.jobAddress[1] + item.jobAddress[2]
            let obj = {
              jobName: item.jobName,
              salary: salary,
              address: address,
            }
            this.currentPositionInformationDetails = obj
          }
        })
      }
    },
    async loadSeeEmployeeRecordData() {
      this.pageLoading = true
      const res = await SeeEmployeeRecordApi({
        jobId: this.chooseJobValue,
        pageNum: 1,
        pageSize: 3,
      })

      if (res) {
        this.jobSeekerList = res.recordList
      }
      this.pageLoading = false
    },
    goPostJob() {
      this.$router.push({
        path: '/postJob',
        query: {
          currHeaderMenu: 3,
        }
      })
    },
    chooseJobValueClick(value) {
      this.chooseJobValue = value
      this.jobValueOptions.forEach((item, index) => {
        if (item.value === this.chooseJobValue) {
          this.jobName = item.label
        }
      })

      let obj = {
        label: this.jobName,
        value: this.chooseJobValue
      }

      this.setHRCurrentRepresentative({
        hrCurrentRepresentative: obj
      })

      this.jobFilter.ageRange = this.ageRangeSlider
    },
    chooseJobValueChangeSuccess() {
      this.$refs.jobSeekerHomeRouterView.submit()
    },
    searchValueInput(searchValue) {
      this.searchValue = searchValue
    },
    searchSubmit() {
      this.jobFilter.ageRange = this.ageRangeSlider
      this.$refs.jobSeekerHomeRouterView.submit()
    },
    changeCurrPage(num) {
      this.currHeaderMenu = num
    },
    areaChange() {
      this.jobFilter.ageRange = this.ageRangeSlider
      this.$refs.jobSeekerHomeRouterView.submit()
    },
    ageRangeSliderChange() {
      this.jobFilter.ageRange = this.ageRangeSlider
      this.$refs.jobSeekerHomeRouterView.submit()
    },
    jobFilterChange(arr) {
      arr.forEach((item, index) => {
        this.jobFilter[item.key] = item.value
      })
      this.jobFilter.ageRange = this.ageRangeSlider

      this.$refs.jobSeekerHomeRouterView.submit()
    },
    clearAllFilterValue() {
      this.$refs.jobSeekerFilterRef.clear()
    },
    goDynamic() {
      this.$router.push({
        path: '/enterpriseDynamic',
        query: {
          currHeaderMenu: 3,
          index: '2'
        }
      })
    },
    goJobSeekerDetailed(employeeId, schoolName) {
      if (this.chooseJobValue === '') {
        share.message.error('请先发布岗位才能查看求职者简历')
        return
      }

      this.$router.push({
        path: '/resumeDetails',
        query: {
          id: employeeId,
          jobId: this.chooseJobValue,
          jobName: this.jobName,
          schoolName: schoolName
        }
      })
    },
    goEnterpriseCenter() {
      this.$router.push({
        path: '/enterpriseCenter',
        query: {
          currHeaderMenu: 3,
        }
      })
    }

  }
}
</script>

<style lang="less" scoped>
.jobSeekerHome_warp {
  width: 100%;
  background-color: #f4f2f3;
  min-width: 1400px;
  //min-height: 100vh;

  .header {
    width: 100%;
    height: 61px;
  }

  .search {
    width: 100%;
    height: 100px;
    //background-color: #ffffff;

    .search-content {
      width: 1000px;
      margin: 0 auto;

      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 80px 10px 80px;
      box-sizing: border-box;
      //margin: 10px 0px 10px 0px;

      .icon {
        height: 90px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        img {
          max-height: 80%;
        }
      }

      .search-input {
        flex: 1;
      }
    }

  }

  .content {
    width: 1000px;
    //height: 100%;
    margin: 0 auto;

    .user-info {
      width: 100%;
      height: 100px;
      background: url("../../assets/icon/jobHunting-header-bg.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 30px;
      box-sizing: border-box;
      margin-bottom: 10px;
      color: #e80003;

      .user-info-left {
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;

        .profile-photo {
          margin: 0 20px;
        }

        & /deep/ .el-avatar {
          background-color: #ffffff;
        }

        & /deep/ .el-avatar--large {
          height: 80px;
          width: 80px;

        }

      }

      .user-info-right {
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          height: 30px;
          width: 30px;
          margin-right: 10px;
        }
      }
    }

    .main {
      width: 100%;
      height: 100%;
      display: flex;

      .main-left {
        width: 250px;
        height: 100%;

        .card {
          width: 100%;
          margin-bottom: 10px;
          background-color: #ffffff;
        }


        .filter {
          padding-bottom: 20px;

          .selectTitle {
            margin-bottom: 20px;
          }

          .title {
            height: 35px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #dfdfdf;

            .text {
              color: #e80003;
              font-size: 14px;
              height: 35px;
              line-height: 35px;
              margin-left: 5px;
            }

            .des {
              height: 100%;
              display: flex;
              align-items: flex-end;
              font-size: 12px;
              color: #7f7f7f;
              margin-bottom: 8px;
              margin-right: 5px;
              cursor: pointer;
            }

          }

          .filter-item {
            width: 100%;
            padding: 0px 10px;
            box-sizing: border-box;

            & /deep/ .el-cascader {
              width: 100%;

              .el-input__inner {
                border: none;
              }
            }

          }

          .age-range-filter {
            width: 100%;
            padding: 10px 20px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .rang-num {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
            .slider {
              width: 100%;
            }
            .btn {
              width: 100%;
              .el-button {
                width: 100%;
              }
            }
          }

        }
      }

      .main-right {
        width: calc(100% - 250px);
        height: 100%;
      }

    }

  }

}
</style>
<style lang="less">
.jobValue_filter_item {
  .el-cascader-menu {
    height: 200px !important;
  }
}
</style>
