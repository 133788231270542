<template>
  <div class="card" v-loading="pageLoading">
    <div class="header">
<!--      <img class="avatar" width="40" height="40" :alt="username" :src="profilePhoto">-->
<!--      <p class="name">{{ username }}</p>-->
      <el-checkbox v-model="newFilterUnread" @change="filterUnreadChange">筛选未读</el-checkbox>
      <el-button type="warning" size="mini" @click="toAllRead">一键已读</el-button>
      <el-popover
          placement="right-start"
          title="黑名单列表"
          width="300"
          trigger="click"
          content="">

        <div class="blacklist-content" v-loading="pageLoading">
          <div class="session" v-for="(item, index) in blacklist" :key="'blackItem' + index">
            <div class="left">
              <img class="avatar" :alt="item.session_info.name" :src="item.session_info.avatar">
              <p class="name">{{ item.session_info.name }}</p>
            </div>
            <div class="right">
              <el-button type="warning" size="mini" @click="whetherBlock(item)">移除黑名单</el-button>
            </div>
          </div>
          <div class="empty" v-if="blacklist.length <= 0">
            空
          </div>
        </div>
        <el-button size="mini" slot="reference" type="warning">黑名单</el-button>
      </el-popover>
    </div>
    <footer>
      <div class="footer-warp">

        <el-autocomplete
            size="mini"
            class="search"
            v-model="state"
            :fetch-suggestions="querySearchAsync"
            placeholder="搜索内容"
            @select="handleSelect"
            popper-class="autocomplete-user-list"
        >

          <template slot-scope="{ item }">
            <div class="user-item">
              <img class="avatar" :alt="item.session_info.name" :src="item.session_info.avatar">
              <p class="name">{{ item.session_info.name }}</p>
              <div class="badge" v-if="item.unread_count > 0">
                {{item.unread_count | badgeNumber}}
              </div>
            </div>
          </template>

        </el-autocomplete>

      </div>


    </footer>
  </div>
</template>

<script>

import share from "@/share/share";

export default {
  name: 'card',
  props: {
    filterUnread: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pageLoading: false,
      // searchValue: '',
      state: '',
      blacklist: [],
      newFilterUnread: false,
    }
  },
  computed: {
    username() {
      return this.$store.state.userInfo.username || ''
    },
    profilePhoto() {
      return this.$store.state.userInfo.profilePhoto || ''
    },
    aonuoChat() {
      return this.$store.state.aonuoChat
    },
    aonuoChatReady() {
      return this.$store.state.aonuoChatReady
    }
  },
  watch: {
    async aonuoChatReady() {
      if (this.aonuoChatReady) {
        await this.loadBlockList()
      }
    },
    filterUnread: {
      handler: function () {
        this.newFilterUnread = this.filterUnread
      },
      immediate: true
    },
    newFilterUnread: {
      handler: function () {
        this.$emit('update:filterUnread', this.newFilterUnread)
      },
    }
  },
  async mounted() {
    if (this.aonuoChatReady) {
      await this.loadBlockList()
    }
  },
  filters: {
    badgeNumber (date) {
      let str = ''
      if (parseInt(date) > 99) {
        str = '99+'
      }else {
        str = date
      }
      return str
    }
  },
  methods: {
    async loadBlockList() {
      this.pageLoading = true
      const res = await this.aonuoChat.getSetBlockList(0, 1000)

      if (res) {
        this.blacklist = res.session_info_list
      }
      this.pageLoading = false
    },
    async querySearchAsync(queryString, cb) {
      if(queryString) {
        const res = await this.aonuoChat.getSearchConversationList(queryString, 'total')


        if (res) {
          let arr = [...res.group_chat_session_info_list, ...res.single_chat_session_info_list]
          cb(arr)
        }
      }else {
        cb([])
      }
    },
    handleSelect(item) {
      console.log(item)
      this.state = item.session_info.name
      this.$emit('handleSelect', item.session_id, item.type, item.session_info, item)
    },

    /**
     * 是否拉黑
     * */
    async whetherBlock (obj) {
      this.pageLoading = true
      let action = ''
      if (obj.set_block === true) {
        action = 'cancel_block'
      }else {
        action = 'set_block'
      }
      const res = await this.aonuoChat.postSetBlock(action, obj.session_id)

      if (res) {
        share.message.success('操作成功')
        await this.loadBlockList()
      }
      this.$emit('cancelBlock')
    },

    filterUnreadChange() {
      this.$nextTick(() => {
        this.$emit('refreshList')
      })
    },

    /**
     * 设置全部已读
     * */
    async toAllRead() {
      await this.aonuoChat.setMessageRead('', 'total')

      this.$nextTick(() => {
        this.$emit('refreshList')
      })
    },
  }
};
</script>

<style scoped lang="less">
.card {
  padding: 12px;
  box-sizing: border-box;
  //border-bottom: solid 1px #24272C;
  //border-bottom: 1px solid #ffffff;
  border-bottom: 1px solid #eaeaea;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  footer {
    margin-top: 10px;
  }

  .avatar, .name {
    vertical-align: middle;
  }

  .avatar {
    border-radius: 50%;
  }

  .name {
    display: inline-block;
    margin: 0 0 0 15px;
    font-size: 16px;
    width: 120px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }

  .footer-warp {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .search {
      //padding: 0 10px;
      box-sizing: border-box;
      width: 100%;
      font-size: 12px;
      color: #fff;
      //height: 30px;
      //line-height: 30px;
      //border: solid 1px #3a3a3a;
      //border-radius: 4px;
      //outline: none;
      //background-color: #26292E;

      //margin-right: 10px;
    }
  }

}
</style>

<style lang="less">
.autocomplete-user-list {
  .user-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    .badge {
      position: absolute;
      right: 0;
      top: 5px;
      background-color: #f56c6c;
      border-radius: 10px;
      height: 18px;
      line-height: 18px;
      padding: 0 6px;
      text-align: center;
      white-space: nowrap;
      border: 1px solid #ffffff;
      font-size: 12px;
      color: #ffffff;
    }
  }

  .more {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #c6c6c6;
    cursor: pointer;
  }

  .avatar, .name {
    vertical-align: middle;
  }

  .avatar {
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }

  .name {
    flex: 1;
    margin: 0 0 0 15px;
    padding-right: 20px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.blacklist-content {
  width: 100%;
  max-height: 200px;
  overflow-y: auto;

  .empty {
    font-size: 16px;
    color: #8a8a8a;
    font-weight: bold;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .session {
    width: 100%;
    padding: 5px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .avatar {
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }

    .name {
      flex: 1;
      margin: 0 0 0 10px;
      padding-right: 15px;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

  }
}

</style>
