<template>
  <div class="text" v-if="chatSessionId" v-loading="pageLoading">

    <div v-if="cardIsShow" class="quick-send-card">
      <div class="card">

        <div class="left">
          <div class="card-left-top">
            <div class="title" v-if="userType === 'hr'">
              {{sendCardData.jobName}}
            </div>
            <div class="title" v-if="userType === 'employee'">
              {{sendCardData.name}}
            </div>
            <div class="msg" v-if="userType === 'hr'">
              {{sendCardData.description}}
            </div>
            <div class="msg" v-if="userType === 'employee'">
              {{sendCardData.selfIntroduction}}
            </div>
          </div>
          <div class="card-left-bottom" v-if="userType === 'hr'">
            薪资：{{sendCardData.salaryRange}}
          </div>
          <div class="card-left-bottom" v-if="userType === 'employee'">
            期望薪资：{{sendCardData.expectedSalaryRange}}
          </div>
        </div>

        <div class="right">
          <div class="card-right-top">
            <img class="icon" src="@/assets/icon/cross.png" @click="cardCancel">
          </div>
          <div class="card-right-bottom">
            <div class="btn" @click="sendMsg('card', '')">
              发送
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="actives">

      <el-popover
          placement="top-start"
          popper-class="emoji-popover-warp"
          width="400"
          trigger="click">
        <div class="emoji-popover-content">
          <div class="emoji-item" v-for="(item, index) in emojiList" :key="'emojiList' + index">
            <div class="emoji-text" @click="addEmoji(item)">
              {{item}}
            </div>
          </div>
        </div>
        <div slot="reference" class="active-item">
          <img src="@/assets/icon/bq.png">
        </div>
      </el-popover>

      <el-popover
          v-if="!isSystemChatSession"
          placement="top-start"
          popper-class="common-terms-popover-warp"
          width="400"
          v-model="commonTermsPopoverIsShow"
          @hide="commonTermsManage = false; commonTermsValue = ''"
          trigger="click">
        <div class="common-terms-popover-content" v-loading="commonTermsLoading">
          <div class="common-terms-manage">
            管理
            <el-switch
              v-model="commonTermsManage">
            </el-switch>
          </div>
          <div class="common-terms-list">
            <div class="common-terms-item" v-for="(item, index) in commonTermsList" :key="'common-terms-item' + index">
              <div class="common-terms">{{item.content}}</div>
              <div class="btn">
                <el-button size="mini" v-if="!commonTermsManage" @click="sendMsg('cumText', item.content)">发送</el-button>
                <el-button class="edit-btn" v-if="commonTermsManage" size="mini" :loading="commonTermsManageDelBtnLoading" @click="toEditorialTerms(item)">编辑</el-button>
                <el-popconfirm
                    v-if="commonTermsManage"
                    title="确定要删除此常用语？"
                    @confirm="delCommonSentence(item.id)"
                >
                  <el-button slot="reference" size="mini" :loading="commonTermsManageDelBtnLoading">删除</el-button>
                </el-popconfirm>
              </div>
            </div>

            <div v-if="commonTermsList.length <= 0" class="empty">
              空
            </div>
          </div>
          <div class="add-warp" v-if="commonTermsManage">
            <div class="input-warp">
              <el-input
                  type="textarea"
                  :rows="2"
                  maxlength="100"
                  placeholder="请输入内容"
                  v-model="commonTermsValue">
              </el-input>
            </div>
            <el-button size="mini" :loading="commonTermsManageAddBtnLoading" @click="addCommonSentence">新增</el-button>
          </div>
        </div>
        <div slot="reference" class="active-item">
          <img src="@/assets/icon/common-terms.png">
        </div>
      </el-popover>

      <el-tooltip effect="dark" content="发送图片" placement="top" :enterable="false">
        <el-upload
            ref="imageUploadRef"
            :action="UPLOAD_FILE_URL + '/file/upload-chat-file/'"
            :headers="headers"
            name="file"
            :multiple="false"
            :show-file-list="false"
            :auto-upload="false"
            :limit="1"
            :on-change="imageOnChange"
            :on-success="imageOnSuccess"
            :on-error="imageOnError"
            :file-list="imageFileList">
          <div class="active-item">
            <img src="@/assets/icon/image.png">
          </div>
        </el-upload>
      </el-tooltip>

      <el-tooltip v-if="!isSystemChatSession && userType === 'employee' && !isSystemChatSession" effect="dark" content="发送简历" placement="top" :enterable="false">
        <div class="active-item" @click="sendMsg('card')">
          <img src="@/assets/icon/resume-delivery.png">
        </div>
      </el-tooltip>

      <el-tooltip v-if="!isSystemChatSession && userType === 'hr' && !isSystemChatSession" effect="dark" content="发送岗位信息" placement="top" :enterable="false">
        <div class="active-item" @click="sendJobCardDialogVisible = true">
          <img src="@/assets/icon/position-posted.png">
        </div>
      </el-tooltip>

      <el-tooltip class="item" v-if="!isSystemChatSession && userType === 'hr'" effect="dark" content="发送微信" placement="top" :enterable="false">
        <div class="active-item" @click="sendMsg('WechatCode')">
          <img src="@/assets/icon/wechat3.png">
        </div>
      </el-tooltip>

      <el-tooltip class="item" v-if="!isSystemChatSession && userType === 'hr'" effect="dark" content="发送面试邀请" placement="top" :enterable="false">
        <div class="active-item" @click="interviewInvitationDialog = true">
          <img src="@/assets/icon/interview-time.png">
        </div>
      </el-tooltip>

    </div>
    <el-input class="textarea" type="textarea" placeholder="请输入..." v-model="content"
              @keydown.native="onKeyup($event)"
              rows="4"
    >
    </el-input>
    <div class="btn">
      <span>按 Shift + Enter 回车， 按 Enter 发送</span>
      <el-button size="mini" @click="sendMsg('text')">发 送</el-button>
    </div>

    <el-dialog
        title="提示"
        :visible.sync="hintDialogVisible"
        width="400px"
    >
      <div style="font-size: 20px;">
        {{modalContent}}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hintDialogCancel">取 消</el-button>
        <el-button type="primary" @click="hintDialogConfirm">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="hintDialogVisible2"
        width="400px"
    >
      <div style="font-size: 20px;">
        {{modalContent2}}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hintDialogCancel2">取 消</el-button>
        <el-button type="primary" @click="hintDialogConfirm2">{{modalSubmitBtnText2}}</el-button>
      </span>
    </el-dialog>

    <!--  发送岗位卡片  -->
    <el-dialog
        title="发送岗位卡片"
        custom-class="send_job_card_dialog_warp"
        :visible.sync="sendJobCardDialogVisible"
        width="800px"
    >
      <el-tabs v-model="activeName" type="card" v-loading="sendJobCardDialogLoading">
        <el-tab-pane label="普通岗位" name="0">
          <div class="send-job-card-dialog-content">
            <template v-if="hrPostedJobList && hrPostedJobList.length > 0">
              <hr-send-job-card-item
                  v-for="(item, index) in hrPostedJobList"
                  :key="'hrPostedJobList' + index"
                  :job-id="item.jobId"
                  :job-type="0"
                  :job-name="item.jobName"
                  :academic-requirement="item.academicRequirement"
                  :experience-requirement-range="item.experienceRequirementRange"
                  :salary-range="item.salaryRange"
                  :job-address="item.jobAddress"
                  :expire-date="item.expireDate"
                  :community-count="item.communityCount"
                  :is-close="item.isClose"
                  :job-status="item.jobStatus"
                  :publish-time="item.publishTime"
                  :see-count="item.seeCount"
                  :age-requirement-range="item.ageRequirementRange"
                  :description="item.description"
                  :detail-address="item.detailAddress"
                  :job-welfare="item.jobWelfare"
                  @handleClickItem="handleClickItem"
                  @handleClickAction="handleClickAction"
              ></hr-send-job-card-item>
            </template>
            <div class="pagination" v-if="hrPostedJobList && hrPostedJobList.length > 0">
              <el-pagination
                  @current-change="sendJobCardDialogPageCurrentChange"
                  background
                  :current-page.sync="jobPage1.pageNumber"
                  :page-size="jobPage1.pageSize"
                  layout="total, prev, pager, next"
                  :total="jobPage1.total">
              </el-pagination>
            </div>
            <div v-if="hrPostedJobList.length <= 0" class="empty">
              {{hrPostedJobListStr}}
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="红包岗位" name="1">
          <div class="send-job-card-dialog-content">
            <template v-if="hrPostedJobList && hrPostedJobList.length > 0">
              <hr-send-job-card-item
                  v-for="(item, index) in hrPostedJobList"
                  :key="'hrPostedJobList' + index"
                  :job-id="item.jobId"
                  :job-type="1"
                  :job-name="item.jobName"
                  :academic-requirement="item.academicRequirement"
                  :experience-requirement-range="item.experienceRequirementRange"
                  :salary-range="item.salaryRange"
                  :job-address="item.jobAddress"
                  :expire-date="item.expireDate"
                  :apply-red-pocket-count="item.applyRedPocketCount"
                  :community-count="item.communityCount"
                  :is-close="item.isClose"
                  :job-status="item.jobStatus"
                  :publish-time="item.publishTime"
                  :see-count="item.seeCount"
                  :age-requirement-range="item.ageRequirementRange"
                  :description="item.description"
                  :detail-address="item.detailAddress"
                  :job-welfare="item.jobWelfare"
                  @handleClickItem="handleClickItem"
                  @handleClickAction="handleClickAction"
              ></hr-send-job-card-item>
            </template>
            <div class="pagination" v-if="hrPostedJobList && hrPostedJobList.length > 0">
              <el-pagination
                  @current-change="sendJobCardDialogPageCurrentChange"
                  background
                  :current-page.sync="jobPage2.pageNumber"
                  :page-size="jobPage2.pageSize"
                  layout="total, prev, pager, next"
                  :total="jobPage2.total">
              </el-pagination>
            </div>
            <div v-if="hrPostedJobList.length <= 0" class="empty">
              {{hrPostedJobListStr}}
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

      <span slot="footer" class="dialog-footer">
        <el-button @click="sendJobCardDialogCancel">取 消</el-button>
      </span>
    </el-dialog>

    <!--  面试邀请  -->
    <el-dialog
        title="面试邀请"
        :visible.sync="interviewInvitationDialog"
        width="500px"
        :before-close="interviewInvitationCancel"
        custom-class="interview-invitation-dialog"
        >
      <div class="interviewInvitationDialog-content">

        <el-form ref="interviewInvitationFormRef" :rules="formRules" :model="interviewInvitationForm" label-width="80px">

          <div class="title">
            向对方发送面试邀请
          </div>

          <el-form-item label="面试时间" prop="time">
            <el-date-picker
                v-model="interviewInvitationForm.time"
                value-format="yyyy-MM-dd HH:mm"
                type="datetime"
                placeholder="选择日期"
                :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="面试地点" prop="place">
            <el-input v-model="interviewInvitationForm.place" clearable></el-input>
          </el-form-item>

        </el-form>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="interviewInvitationCancel">取 消</el-button>
        <el-button type="primary" :loading="interviewInvitationFormSubmitLoading" @click="interviewInvitation">确 定</el-button>
      </span>
    </el-dialog>

    <!--  编辑常用语  -->
    <el-dialog
        title="编辑常用语"
        :visible.sync="editorialTermsDialog"
        width="500px"
        :before-close="editorialTermsDialogCancel"
        custom-class="editorial-terms-dialog"
        >
      <div class="editorial-terms-dialog-content">

        <el-form ref="editorialTermsFormRef" :rules="formRules" :model="editorialTermsForm" label-width="80px">

          <el-form-item label="常用语" prop="terms">
            <el-input type="textarea" row="2" v-model="editorialTermsForm.terms" maxlength="100" clearable></el-input>
          </el-form-item>

        </el-form>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editorialTermsDialogCancel">取 消</el-button>
        <el-button type="primary" :loading="editorialTermsDialogSubmitLoading" @click="editorialTermsDialogSubmit">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import emoji from './emoji'
import {
  GiveInterviewInvitationApi,
  GetResumeEditApi,
  JobHrSeeJobDetailApi,
  HrMyPublishedNormalJobApi,
  HrMyPublishedRedPocketJobApi,
  GetHrChangeSelfInformationApi,
  ProductCanHrCommunicateApi, MyPublishedJobApi, PostEmployeeSendPersonalCardApi, PostEmployeePostResumeApi,
} from '@/request/api'
import {formRules} from '@/share/formRules'
import {ageRange, education2, experience, jobSearchingStatus} from "@/share/shareData";
import {UPLOAD_FILE_URL} from '@/config/baseURL'
import HrSendJobCardItem from "@/components/hrSendJobCardItem/hrSendJobCardItem";
import share from "@/share/share";
import {mapMutations} from "vuex";
// import * as imageConversion from 'image-conversion';

export default {
  name: 'myText',
  components: {HrSendJobCardItem},
  props: {
    cardIsShow: {
      type: Boolean,
      default: true
    },
    isTemporaryCustomerService: {  // 是否是临时会话
      type: Boolean,
      default: false,
    },
    customerServiceType: {  // 是否是人工客服
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      pageLoading: false,

      UPLOAD_FILE_URL: UPLOAD_FILE_URL,
      content: '',
      hrPostedJobListStr: '',
      // hrId: '',
      // jobId: '',
      // employeeId: '',
      // hrInfo: {
      //   hrWechat: '',
      // },
      interviewInvitationDialog: false,
      interviewInvitationForm: {
        time: '',
        place: '',
      },
      interviewInvitationFormSubmitLoading: false,
      formRules: formRules,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < (Date.now() - 1 * 24 * 60 * 60 * 1000)
        }
      },

      emojiList: emoji,
      sendCardData: {},
      sendCardData2: {},
      modalContent: '',
      hintDialogVisible: false,
      imageFileList: [],
      headers: {
        Authorization: ''
      },
      sendJobCardDialogVisible: false,
      activeName: '0',
      hrPostedJobList: [],
      sendJobCardDialogLoading: false,
      wechat: '',
      hintDialogVisible2: false,
      modalContent2: '',
      modalSubmitBtnText2: '确定',
      uploadImageFileId: '',
      sendCardFileId: '',
      sendWechatCodeFileId: '',

      commonTermsPopoverIsShow: false,
      commonTermsLoading: false,
      commonTermsManage: false,
      commonTermsManageAddBtnLoading: false,
      commonTermsManageDelBtnLoading: false,
      commonTermsList: [],
      commonTermsValue: '',

      jobValueOptions: [],

      editorialTermsDialog: false,  // 编辑常用语弹窗
      editorialTermsForm: {
        terms: '',
      },
      editId: '',
      editorialTermsDialogSubmitLoading: false,

      jobPage1: {
        pageNumber: 1,
        pageSize: 5,
        total: 0,
      },
      jobPage2: {
        pageNumber: 1,
        pageSize: 5,
        total: 0,
      }
    }
  },
  computed: {
    userType() {
      return this.$store.state.userInfo.userType
    },
    avatarUrl() {
      return this.$store.state.userInfo.profilePhoto || ''
    },
    aonuoChat() {
      return this.$store.state.aonuoChat
    },
    myChatUserId() {
      if (this.isTemporaryCustomerService) {
        return this.$store.state.temporaryMyChatUserId
      }else {
        return this.$store.state.myChatUserId
      }
    },
    chatSessionId() {
      return this.$store.state.chatSessionId
    },
    chatSession() {
      return this.$store.state.chatSession
    },
    chatSessionInfo() {
      return this.$store.state.chatSessionInfo
    },
    isSystemChatSession() {
      let state = false
      if ((this.chatSessionInfo && this.chatSessionInfo.user_id === '000001') || (this.chatSessionInfo && this.chatSessionInfo.user_id === '000002')) {
        state = true
      }
      return state
    },
    aonuoChatReady() {
      return this.$store.state.aonuoChatReady
    },
    jobId() {
      return this.$store.state.hrCurrentRepresentative.value
    }
  },
  watch: {
    jobId: {
      handler: function () {
        if (this.userType === 'hr' && (this.jobId === undefined || this.jobId === '')) {
          if (this.isSystemChatSession) {
            this.$emit('update:card-is-show', false)
          }else {
            this.$emit('update:card-is-show', true)
          }
        }else {
          if (this.isSystemChatSession) {
            this.$emit('update:card-is-show', false)
          }else {
            this.$emit('update:card-is-show', true)
          }
        }
      },
      immediate: true
    },
    chatSessionId: {
      handler: function () {
        if (this.userType === 'hr') {
          if (this.isSystemChatSession) {
            this.$emit('update:card-is-show', false)
          }else {
            if (this.jobId) {
              this.$emit('update:card-is-show', true)
              if (this.chatSessionInfo && this.chatSessionInfo.user_id !== '000001' && this.chatSessionInfo.user_id !== '000002') {
                this.loadCardData()
              }
            }else {
              this.$emit('update:card-is-show', false)
            }
          }
        }else {
          if (this.isSystemChatSession) {
            this.$emit('update:card-is-show', false)
          }else {
            this.$emit('update:card-is-show', true)
            if (this.chatSessionInfo && this.chatSessionInfo.user_id !== '000001' && this.chatSessionInfo.user_id !== '000002') {
              this.loadCardData()
            }
          }
        }
      },
      immediate: true
    },
    activeName() {
      this.loadHrPostedJobs()
    },
    aonuoChatReady() {
      if (this.aonuoChatReady) {
        this.getCommonSentenceData()
      }
    }
  },
  async mounted() {
    this.pageLoading = true
    if (this.chatSessionInfo && this.chatSessionInfo.user_id !== '000001' && this.chatSessionInfo.user_id !== '000002') {
      await this.loadCardData()
    }

    if (this.aonuoChatReady) {
      await this.getCommonSentenceData()
    }
    if (this.userType === 'hr') {
      await this.getUserInfo()
    }
    // if (this.aonuoChatReady) {
    //   if (this.chatSessionInfo && this.chatSessionInfo.user_id !== '000001' && this.chatSessionInfo.user_id !== '000002') {
    //     await this.loadData()
    //   }
    // }
    this.pageLoading = false
  },
  methods: {
    ...mapMutations(['setHRCurrentRepresentative']),
    async loadData() {
      if (this.userType === 'hr') {
        const res = await Promise.all([this.loadCardData(), this.getCommonSentenceData(), this.getUserInfo()])
      }else {
        const res = await Promise.all([this.loadCardData(), this.getCommonSentenceData()])
      }
    },
    /**
     * 获取常用语列表
     * */
    async getCommonSentenceData() {
      this.commonTermsLoading = true
      const res = await this.aonuoChat.getCommonSentenceList()
      if (res) {
        let list = []
        res.common_sentence_list.forEach((item, index) => {
          let obj = {
            id: index,
            content: item
          }
          list.push(obj)
        })
        this.commonTermsList = list
      }
      this.commonTermsLoading = false
    },

    /**
     * 新增常用语
     * */
    async addCommonSentence() {
      this.commonTermsManageAddBtnLoading = true
      const res = await this.aonuoChat.postCommonSentenceList('add', this.commonTermsValue)
      if (res) {
        this.commonTermsValue = ''
        share.message.success('新增成功')
        await this.getCommonSentenceData()
      }
      this.commonTermsManageAddBtnLoading = false
    },

    /**
     * 删除常用语
     * */
    async delCommonSentence(id) {
      this.commonTermsManageDelBtnLoading = true
      const res = await this.aonuoChat.postCommonSentenceList('delete', '', id)
      if (res) {
        share.message.success('删除成功')
        await this.getCommonSentenceData()
      }
      this.commonTermsManageDelBtnLoading = false
    },


    async loadMyJobData() {
      const res = await MyPublishedJobApi({
        pageNum: 1,
        pageSize: 20,
        jobStatus: 3,
      })

      if (res) {
        let newArr = []
        let arr = res.jobList
        arr.forEach((item, index) => {
          let obj = {
            label: item.jobName,
            value: item.jobId
          }
          newArr.push(obj)
        })
        this.jobValueOptions = newArr
      }
    },

    /**
     * 获取卡片数据
     * */
    async loadCardData() {
      let obj = {}
      if (this.userType === 'employee') {
        // const res = await GetResumeEditApi()
        const res = await PostEmployeeSendPersonalCardApi({
          jobId: this.chatSessionInfo.job_information.jobId
        })
        if (res) {
          obj = {
            employeeId: res.cardInfo.employeeId,  // 求职者id
            name: res.cardInfo.name,  // 姓名
            gender: res.cardInfo.gender,  // 性别
            birthday: res.cardInfo.birthday,  // 生日
            age: res.cardInfo.age,  // 年龄
            jobSearchingStatus: res.cardInfo.jobSearchingStatus,  // 求职状态
            academicLevelMax: res.cardInfo.academicLevelMax,  // 最高学历
            workExperienceYears: res.cardInfo.workExperienceYears,  // 工作经验时长
            selfIntroduction: res.cardInfo.selfIntroduction,  // 自我介绍
            personalHighLightList: res.cardInfo.personalHighLightList,  // 个人亮点
            expectedJobList: res.cardInfo.expectedJobList,  // 期望岗位列表
            expectedJobAddressList: res.cardInfo.expectedJobAddressList,  // 期望岗位地点列表
            expectedSalaryRange: res.cardInfo.expectedSalaryRange,  // 期望薪资范围，列表，面议为空列表
            employeePhone: res.cardInfo.phone,  // 手机号
            // workExperienceList: res.employeeInformation.workExperienceList,  // 工作经历列表
            // educationExperienceList: res.employeeInformation.educationExperienceList,  // 教育经历列表
            // certificateList: res.employeeInformation.certificateList,  // 证书信息列表
          }
          obj = this.employeeInitCardData(obj)
          this.sendCardData = obj
        }
      }
      if (this.userType === 'hr') {
        // if (this.jobId === undefined || this.jobId === '') {
        //   share.message.warning('您当前没有发布岗位')
        //   this.$nextTick(() => {
        //     this.cardCancel()
        //   })
        //   return
        // }

        await this.loadMyJobData()
        let state = false
        if (this.jobValueOptions.length > 0) {

          if (this.jobId) {
            const res = await JobHrSeeJobDetailApi({
              jobId: this.jobId,
            })
            if (res && res.jobInformation && !res.jobInformation.isClose && res.jobInformation.jobStatus === 3) {
              state = true
            }else {
              state = false
            }
          }

          if (!state) {
            this.setHRCurrentRepresentative({
              hrCurrentRepresentative: {
                label: this.jobValueOptions[0].label,
                value: this.jobValueOptions[0].value,
              }
            })
          }
        }else {
          this.setHRCurrentRepresentative({
            hrCurrentRepresentative: {
              label: '',
              value: '',
            }
          })
          this.$nextTick(() => {
            this.cardCancel()
          })
        }
        if (this.jobId) {
          const res = await JobHrSeeJobDetailApi({
            jobId: this.jobId
          })
          if (res) {
            obj = {
              jobId: res.jobInformation.jobId,  // 岗位id
              jobType: res.jobInformation.jobType,  // 岗位类型，枚举值
              jobName: res.jobInformation.jobName,  // 岗位名字
              jobAddress: res.jobInformation.jobAddress,  // 工作地点列表
              detailAddress: res.jobInformation.detailAddress,  // 详细地址
              salaryRange: res.jobInformation.salaryRange,  // 薪资待遇
              experienceRequirementRange: res.jobInformation.experienceRequirementRange,  // 工作经验
              ageRequirementRange: res.jobInformation.ageRequirementRange,  // 年龄要求
              academicRequirement: res.jobInformation.academicRequirement,  // 学历要求
              jobWelfare: res.jobInformation.jobWelfare,  // 岗位福利
              description: res.jobInformation.description,  // 岗位描述
            }
            obj = this.hrInitCardData(obj)
            this.sendCardData = obj
          }
        }
        await this.loadHrPostedJobs()
      }
    },

    async getUserInfo() {
      const res = await GetHrChangeSelfInformationApi()

      if (res) {
        this.wechat = res.hrInformation.wechat
      }
    },

    async loadHrPostedJobs() {
      this.sendJobCardDialogLoading = true
      this.hrPostedJobListStr = '加载中...'
      let res = {}
      if (this.activeName === '0') {
        res = await HrMyPublishedNormalJobApi({
          jobStatus: 3,
          pageNum: this.jobPage1.pageNumber,
          pageSize: this.jobPage1.pageSize,
        })
        this.jobPage1.total = res.numOfJobs || 0
      }else if (this.activeName === '1') {
        res = await HrMyPublishedRedPocketJobApi({
          jobStatus: 3,
          pageNum: this.jobPage2.pageNumber,
          pageSize: this.jobPage2.pageSize,
        })
        this.jobPage2.total = res.numOfJobs || 0
      }

      if (res) {
        this.hrPostedJobList = res.jobList
      }
      if (this.hrPostedJobList.length <= 0) {
        this.hrPostedJobListStr = '空'
      }
      this.sendJobCardDialogLoading = false
    },
    sendJobCardDialogPageCurrentChange() {
      this.loadHrPostedJobs()
    },
    employeeInitCardData(data) {
      let obj = data

      if (obj.gender === 'male') {
        obj.gender = '男'
      }else {
        obj.gender = '女'
      }

      obj.age += '岁'

      jobSearchingStatus.forEach((item, index) => {
        if (obj.jobSearchingStatus === item.value) {
          obj.jobSearchingStatus = item.label
        }
      })

      education2.forEach((item, index) => {
        if (obj.academicLevelMax === item.value) {
          obj.academicLevelMax = item.label
        }
      })

      if (obj.workExperienceYears && obj.workExperienceYears > 0) {
        obj.workExperienceYears = obj.workExperienceYears + '年经验'
      }else {
        obj.workExperienceYears = '无经验'
      }

      if (obj.expectedSalaryRange && obj.expectedSalaryRange.length > 1) {
        // obj.expectedSalaryRange = obj.expectedSalaryRange[0] + '-' + obj.expectedSalaryRange[1]
        obj.expectedSalaryRange = parseInt(obj.expectedSalaryRange[0])/1000 + 'k-' + parseInt(obj.expectedSalaryRange[1])/1000 + 'k'
      }else {
        obj.expectedSalaryRange = '面议'
      }

      return obj
    },

    hrInitCardData(data) {
      let obj = data

      if (obj.salaryRange && obj.salaryRange.length > 1) {
        obj.salaryRange = parseInt(obj.salaryRange[0])/1000 + 'k-' + parseInt(obj.salaryRange[1])/1000 + 'k'
      }else {
        obj.salaryRange = '面议'
      }

      if (obj.experienceRequirementRange && obj.experienceRequirementRange.length > 1) {
        let value = obj.experienceRequirementRange[0] + '-' + obj.experienceRequirementRange[1]
        experience.forEach((item, index) => {
          if (item.value === value) {
            obj.experienceRequirementRange = item.label + '经验'
          }
        })
      }else {
        obj.experienceRequirementRange = '经验不限'
      }

      if (obj.ageRequirementRange && obj.ageRequirementRange.length > 1) {
        // let value = obj.ageRequirementRange[0] + '-' + obj.ageRequirementRange[1]
        // ageRange.forEach((item, index) => {
        //   if (item.value === value) {
        //     obj.ageRequirementRange = item.label
        //   }
        // })

        obj.ageRequirementRange = obj.ageRequirementRange[0] + '岁-' + obj.ageRequirementRange[1] + '岁'
      }else {
        obj.ageRequirementRange = '年龄不限'
      }

      return obj
    },

    handleClickItem(jobId) {
    },
    handleClickAction(data) {
      if (this.userType === 'hr') {
        this.modalContent = '确定发送' + this.sendCardData.jobName + '岗位卡片？'
      }
      if (this.userType === 'employee'){
        this.modalContent = '确定发送简历卡片？'
      }
      this.sendCardData2 = data
      this.hintDialogVisible = true
    },

    /**
     * 回车发送消息
     * */
    onKeyup(event) {
      if (event.shiftKey) {
        if (event.keyCode === 13) {
          event.preventDefault() // 阻止浏览器默认换行操作
          this.content += '\n'
          return false
        }
      }else {
        if (event.keyCode === 13) {
          event.preventDefault() // 阻止浏览器默认换行操作
          this.sendMsg('text')
          return false
        }
      }
    },

    /**
    * 添加表情
    * */
    addEmoji(emoji) {
      this.content += emoji
    },

    /**
     * 是否允许发送消息
     * */
    async isAllowToSend(obj) {
      if (this.isSystemChatSession) {
        return true
      }

      let state = true
      let employeeId = this.chatSessionInfo.user_id.split('-')[1]
      const res = await ProductCanHrCommunicateApi({
        jobId: this.jobId,
        employeeId: employeeId,
      })

      if (!res) {
        state = false
        this.$emit('listDelItem', obj)
      }
      return state
    },

    /**
     * 发送消息
     * */
    async sendMsg(type, msg) {
      let fileId = this.getFileId()
      if (type === 'text' || type === 'cumText') {
        let newMsg = this.content
        if (type === 'cumText') {
          newMsg = msg
        }
        if(newMsg.replace(/\s/g,'').length < 1){
          share.message.warning('消息内容不能全为空')
          return
        }
        if (type !== 'cumText') {
          this.content = ''
        }else {
          this.commonTermsPopoverIsShow = false
        }
        this.sendTextMsg(fileId, newMsg)
      }
      if (type === 'image') {
        this.sendImageMsg(fileId, msg)
      }
      if (type === 'card') {
        if (this.userType === 'hr') {
          this.modalContent = '确定发送' + this.sendCardData.jobName + '岗位卡片？'
        }
        if (this.userType === 'employee'){
          this.modalContent = '确定发送简历卡片？'
        }
        this.sendCardFileId = fileId
        this.hintDialogVisible = true
      }
      if (type === 'WechatCode') {
        this.sendWechatCodeFileId = fileId
        if (this.wechat) {
          this.modalContent2 = '确定要发送当前绑定的快捷微信号？'
          this.modalSubmitBtnText2 = '确定'
          this.hintDialogVisible2 = true

        }else {
          this.modalContent2 = '您当前未绑定快捷微信号？'
          this.modalSubmitBtnText2 = '去绑定'
          this.hintDialogVisible2 = true
        }
      }

      if (type === 'interview-invitation') {

        const state = await this.aonuoChat.getCanSendMessage(this.chatSessionId)
        if (!state.can_send_message) {
          share.message.error('发送失败，消息被拒收了')
        }else {
          let employeeId = this.chatSessionInfo.user_id.split('-')[1]
          const res = await GiveInterviewInvitationApi({
            employeeId: employeeId,
            jobId: this.jobId,
            interviewTime: this.interviewInvitationForm.time,
            interviewAddress: this.interviewInvitationForm.place,
          })
          if (res) {
            let msg = `您好，您已成功预约${this.interviewInvitationForm.time}的面试,面试地址为${this.interviewInvitationForm.place},请及时到面。`
            this.sendTextMsg(fileId, msg)
          }
        }

      }

    },
    /**
     * 发送消假息
     * */
    sendFakeMsg(fileId, type, data) {
      let obj = {
        id: fileId,
        sender: this.myChatUserId,
        content: {
          id: fileId,
          type: type,
          uploading: true,
        },
        is_recall: false,  // 是否被标记已撤回
        have_read: false,  // 是否被标记已读
        is_error: false,  // 是否被标记出错
      }
      if (type === 'text') {
        obj.content.text = data.text
      }
      if (type === 'image') {
        obj.content.url = data.url
      }
      if (type === 'voice') {
        obj.content.url = data.url
        obj.content.length = data.length
        obj.content.voiceDuration = data.voiceDuration
      }
      if (type === 'job-card' || type === 'resume-card') {
        obj.content.data = data.data
      }
      this.$emit('listUnshift', obj)
    },
    /**
     * 发送文本消息
     * */
    sendTextMsg(fileId, msg) {
      this.sendFakeMsg(fileId, 'text', {
        text: msg
      })
      let obj = {
        fileId: fileId,
        toSession: this.chatSessionId,
        text: msg
      }
      let content = this.aonuoChat.createTextMessage(obj)
      if (this.chatSessionInfo.user_id === '000001' || this.chatSessionInfo.user_id === '000002') {
        if (this.customerServiceType) {  // 发给人工客服
          console.log('发给人工客服')
          this.aonuoChat.sendMessage(content)
        }else {  // 发给机器人客服
          console.log('发给机器人客服')
          this.aonuoChat.postAutoCustomerService(content)
        }
      }else {
        this.aonuoChat.sendMessage(content)
      }
    },
    /**
     * 发送图片消息
     * */
    async sendImageMsg(fileId, msg, customerServiceType) {
      const fileType = msg.raw.type
      const isLt20M = msg.raw.size / 1024 / 1024 < 20
      let isJPGOrPng = true
      if (fileType !== 'image/jpeg' && fileType !== 'image/png') {
        share.message.error('上传图片只能是 JPG/JPEG 或 PGN 格式!')
        isJPGOrPng = false
      }
      if (!isLt20M) {
        share.message.error('上传图片大小不能超过 20MB!')
      }

      if (isJPGOrPng && isLt20M) {
        let url = window.URL.createObjectURL(msg.raw)
        this.uploadImageFileId = fileId
        this.sendFakeMsg(this.uploadImageFileId,'image', {url: url})
        // if (this.userType === 'hr') {
        //   const state = await this.isAllowToSend(obj)
        //   if (!state) {
        //     this.$refs.imageUploadRef.clearFiles()
        //     return
        //   }
        // }
        const userSig = await this.aonuoChat.getUserSig()
        this.headers.Authorization = userSig
        this.$refs.imageUploadRef.submit()
      }else {
        this.$refs.imageUploadRef.clearFiles()
      }
    },
    /**
     * 自定义图片上传方法 （暂时不做压缩处理）
     * */
    imageHttpRequest(data) {
      let file = data.file
      imageConversion.compressAccurately(file, 10).then(res=>{
      })

      // let formData = new window.FormData()
      // formData.append(data.filename, data.file)
      // let xhr = new XMLHttpRequest()
      // xhr.open('POST', data.action ,false)
      // xhr.setRequestHeader('Authorization',data.headers.Authorization)
      // xhr.onreadystatechange = () => {
      //   if (xhr.readyState === 4){
      //     if (xhr.status === 200 || xhr.status === 304){
      //       let response = JSON.parse(xhr.responseText)
      //       data.onSuccess(response)
      //     }else {
      //       data.onError(xhr.response)
      //     }
      //   }else {
      //     data.onError()
      //   }
      // }
      // xhr.send(formData);
    },
    /**
     * 发送卡片消息
     * */
    sendCardMsg(fileId, data = null) {
      let msgType = ''
      if (this.userType === 'hr') {
        msgType = 'job-card'
      }
      if (this.userType === 'employee') {
        msgType = 'resume-card'
      }
      let sendCardData = null
      if (data) {
        sendCardData = data
      } else {
        sendCardData = this.sendCardData
      }
      this.sendFakeMsg(fileId, msgType, {
        data: sendCardData,
      })
      let obj = {
        fileId: fileId,
        toSession: this.chatSessionId,
        data: sendCardData,
        type: msgType,
      }
      let content = this.aonuoChat.createCustomMessage(obj)
      this.aonuoChat.sendMessage(content)
    },

    /**
     * 生成唯一的 fileId
     * */
    getFileId() {
      let nowDate = new Date()
      let nowTime = nowDate.getTime()
      // let nowYear = nowDate.getFullYear()
      // let nowMonth = nowDate.getMonth() + 1
      // let nowDay = nowDate.getDate()

      // let fileId = 'uploadFiling' + nowTime + '' + Math.round(Math.random()*100)
      let fileId = nowTime + '' + Math.round(Math.random()*100)
      return fileId
    },
    cardCancel() {
      this.$emit('update:card-is-show', false)
    },
    hintDialogCancel() {
      this.hintDialogVisible = false
    },
    async hintDialogConfirm() {
      let sendCardData = {}
      if (this.sendJobCardDialogVisible) {
        sendCardData = this.sendCardData2
      }else {
        sendCardData = this.sendCardData
      }
      if (this.userType === 'employee') {
        PostEmployeePostResumeApi({
          jobId: this.chatSessionInfo.job_information.jobId
        })
      }
      this.hintDialogVisible = false
      this.sendJobCardDialogVisible = false
      this.sendCardMsg(this.sendCardFileId, sendCardData)
      this.cardCancel()
    },

    hintDialogCancel2() {
      this.hintDialogVisible2 = false
    },
    async hintDialogConfirm2() {
      if (this.wechat) {
        let msg4 = '我的微信号为：' + this.wechat
        this.sendTextMsg(this.sendWechatCodeFileId, msg4)
        this.hintDialogCancel2()
      }else {
        this.hintDialogVisible2 = false
        this.$router.push('/hrData')
      }
    },
    sendJobCardDialogCancel() {
      this.sendJobCardDialogVisible = false
    },

    interviewInvitationCancel() {
      this.interviewInvitationForm.time = ''
      this.interviewInvitationForm.place = ''
      this.$refs['interviewInvitationFormRef'].resetFields()
      this.interviewInvitationDialog = false
      this.interviewInvitationFormSubmitLoading = false
    },
    interviewInvitation() {
      this.$refs.interviewInvitationFormRef.validate(async res => {
        if (res) {
          let nowDate = new Date()
          if (nowDate >= new Date(this.interviewInvitationForm.time)) {
            share.message.warning('面试时间不能小于当前时间')
          }else {
            this.interviewInvitationFormSubmitLoading = true
            await this.sendMsg('interview-invitation')
            this.interviewInvitationFormSubmitLoading = false
            this.interviewInvitationCancel()
          }
        }
      })
    },

    /**
    * 图片上传状态改变时触发
    * */
    async imageOnChange(file, fileList) {
      if (file.status === 'ready') {
        this.sendMsg('image', file)
      }
    },

    /**
     * 图片上传成功时触发
     * */
    async imageOnSuccess(response, file, fileList) {
      this.$refs.imageUploadRef.clearFiles()
      if (file.response && file.response.code && file.response.code === 200) {
        let obj = {
          fileId: this.uploadImageFileId,
          toSession: this.chatSessionId,
          url: file.response.data.chat_file.url
        }
        let content = this.aonuoChat.createImageMessage(obj)
        if (this.chatSessionInfo.user_id === '000001' || this.chatSessionInfo.user_id === '000002') {
          if (this.customerServiceType) {  // 发给人工客服
            console.log('发给人工客服')
            this.aonuoChat.sendMessage(content)
          }else {  // 发给机器人客服
            console.log('发给机器人客服')
            this.aonuoChat.postAutoCustomerService(content)
          }
        }else {
          this.aonuoChat.sendMessage(content)
        }
      }
    },

    /**
     * 图片上传失败时触发
     * */
    async imageOnError(response, file, fileList) {
      this.imageFileList = []
      this.$refs.imageUploadRef.clearFiles()
    },

    /**
    * 去编辑常用语
    * */
    toEditorialTerms(value) {
      this.editorialTermsForm.terms = value.content
      this.editId = value.id
      this.editorialTermsDialog = true
    },

    /**
    * 常用语弹窗关闭时
    * */
    editorialTermsDialogCancel() {
      this.editorialTermsDialogSubmitLoading = false
      this.editorialTermsDialog = false
    },

    /**
    * 常用语弹窗修改提交时
    * */
    editorialTermsDialogSubmit() {
      this.$refs.editorialTermsFormRef.validate(async res => {
        if (res) {
          this.editorialTermsDialogSubmitLoading = true

          const res = await this.aonuoChat.postCommonSentenceList('change', this.editorialTermsForm.terms, this.editId)
          if (res) {
            share.message.success('修改成功')
            await this.getCommonSentenceData()
          }

          this.editorialTermsDialogSubmitLoading = false
          this.editorialTermsDialogCancel()
        }
      })
    },

    /**
    * 发送快捷问题
    * */
    sendQuestion(text) {

    },

  }
};
</script>


<style lang="less" scoped>
.text {
  width: 100%;
  border-top: solid 1px #ddd;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  background-color: #ffffff;

  .quick-send-card {
    width: 100%;
    height: 80px;
    background-color: #ffffff;
    padding: 10px 10px;
    box-sizing: border-box;
    z-index: 999;
    border-right: 1px solid #DCDFE6;

    .card {
      background-color: #ededed;
      height: 100%;
      width: 100%;
      border-radius: 30px;
      display: flex;
      overflow: hidden;

      .left {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        padding-left: 30px;

        .card-left-top {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .title {
            font-size: 24px;
            margin-right: 20px;
            max-width: 350px;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }
          .msg {
            max-width: 220px;
            font-size: 16px;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }
        }

        .card-left-bottom {
          font-size: 16px;
          color: #e20000;
        }

      }

      .right {
        width: 120px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
        padding-right: 30px;
        box-sizing: border-box;


        .card-right-top {
          width: 20px;
          height: 20px;
          margin-bottom: 4px;

          .icon {
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }

        .card-right-bottom {
          .btn {
            background-color: #E82727;
            height: 25px;
            width: 80px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 25px;
            color: #ffffff;
          }
        }

      }

    }
  }

  .actives {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 20px 0 20px;
    box-sizing: border-box;
    border-right: 1px solid #DCDFE6;

    .active-item {
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      cursor: pointer;

      &:hover {
        background-color: #e5e5e5;
        border-radius: 5px;
      }

      >img {
        max-height: 70%;
      }
    }

  }

  .textarea {
    width: 100%;
    height: 94px;
    border-right: 1px solid #DCDFE6;

    & /deep/ .el-textarea__inner {
      border: none !important;
      outline: none !important;
      font-family: "Micrsofot Yahei" !important;
      resize: none !important;
    }

  }

  .btn {
    border-right: 1px solid #DCDFE6;
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    color: #adadad;
    padding: 0 20px;
    box-sizing: border-box;

    span {
      margin-right: 10px;
    }

    .el-button {
      padding: 7px 20px;
    }

  }
}
</style>

<style lang="less">

.interview-invitation-dialog {

  .interviewInvitationDialog-content {

    .title {
      margin-bottom: 20px;
    }

  }

}


.emoji-popover-warp {

  ::-webkit-scrollbar {
    width: 0 !important;
  }
  ::-webkit-scrollbar {
    width: 0 !important;
    height: 0;
  }

  .emoji-popover-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-height: 280px;
    overflow-y: scroll;

    .emoji-item {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;

      .emoji-text {
        padding: 5px 5px;
        box-sizing: border-box;

        &:hover {
          border-radius: 5px;
          background-color: #f2f2f2;
          cursor: pointer;
        }
      }

    }
  }

}

.common-terms-popover-warp {
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  ::-webkit-scrollbar {
    width: 0 !important;
    height: 0;
  }

  .common-terms-popover-content {
    width: 100%;
    .common-terms-manage {
      width: 100%;
      text-align: right;
      border-bottom: 4px solid #efefef;
      padding-bottom: 5px;
      box-sizing: border-box;
    }
    .common-terms-list {
      width: 100%;
      max-height: 200px;
      overflow-y: scroll;
      margin: 10px 0;
      .common-terms-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #c2c2c2;
        padding: 5px 0;
        box-sizing: border-box;
        &:last-child {
          border-bottom: 0;
        }
        .common-terms {
          flex: 1;
          margin-right: 10px;
          white-space: pre-line;
        }

        .btn {
          .edit-btn {
            margin-right: 10px;
          }
        }
      }

      .empty {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        color: #999999;
      }
    }
    .add-warp {
      width: 100%;
      padding-top: 5px;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      border-top: 4px solid #efefef;
      .input-warp {
        flex: 1;
        margin-right: 10px;
      }
    }
  }
}

.send_job_card_dialog_warp {

  .send-job-card-dialog-content {
    height: 470px;
    overflow-y: auto;

    .pagination {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: flex-end;
    }

    .empty {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      color: #999999;
    }
  }
}
</style>
