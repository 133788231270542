<template>
  <div class="accountManagement_warp">

    <div class="content" v-loading="pageLoading">

      <div class="content-item">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="left">
              <img src="@/assets/icon/safety.png" v-if="passwordState">
              <img src="@/assets/icon/caveat.png" v-if="!passwordState">
              设置密码
            </div>
          </el-col>
          <el-col :span="8">
            <div class="center">
              {{passwordStr}}
            </div>
          </el-col>
          <el-col :span="8">
            <div class="right" v-if="passwordState">
              <span @click="passwordModelShow">修改密码</span>
            </div>
            <div class="right" v-if="!passwordState">
              <span @click="passwordModelShow">设置密码</span>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="content-item">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="left">
              <img src="@/assets/icon/safety.png" v-if="phoneState">
              <img src="@/assets/icon/caveat.png" v-if="!phoneState">
              绑定手机号
            </div>
          </el-col>
          <el-col :span="8">
            <div class="center">
              {{phoneStr}}
            </div>
          </el-col>
          <el-col :span="8">
            <div class="right" v-if="phoneState">
              <span @click="phoneModelShow">更换手机号</span>
            </div>
            <div class="right" v-if="!phoneState">
              <span @click="phoneModelShow">绑定手机号</span>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="content-item">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="left">
              <img src="@/assets/icon/safety.png" v-if="WechatState">
              <img src="@/assets/icon/caveat.png" v-if="!WechatState">
              绑定微信账号
            </div>
          </el-col>
          <el-col :span="8">
            <div class="center">
              {{WechatStr}}
            </div>
          </el-col>
          <el-col :span="8">
            <div class="right" v-if="WechatState">
              <span @click="toBindWechat">换绑微信账号</span>
            </div>
            <div class="right" v-if="!WechatState">
              <span @click="toBindWechat">绑定微信账号</span>
            </div>
          </el-col>
        </el-row>
      </div>


      <div class="content-item">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="left">
              <img src="@/assets/icon/safety.png" v-if="mailboxState">
              <img src="@/assets/icon/caveat.png" v-if="!mailboxState">
              绑定邮箱
            </div>
          </el-col>
          <el-col :span="8">
            <div class="center">
              {{mailboxStr}}
            </div>
          </el-col>
          <el-col :span="8">
            <div class="right" v-if="mailboxState">
              <span @click="mailboxModelShow">更换邮箱</span>
            </div>
            <div class="right" v-if="!mailboxState">
              <span @click="mailboxModelShow">绑定邮箱</span>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="content-item">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="left">
              <img src="@/assets/icon/safety.png" v-if="realNameAuthenticationState">
              <img src="@/assets/icon/caveat.png" v-if="!realNameAuthenticationState">
              实名认证
            </div>
          </el-col>
          <el-col :span="8">
            <div class="center">
              {{realNameAuthenticationStr}}
            </div>
          </el-col>
          <el-col :span="8">
            <div class="right" v-if="!realNameAuthenticationState">
              <span @click="goRealName">去认证</span>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="content-item">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="left">
              <img src="@/assets/icon/safety.png" v-if="bankCardState">
              <img src="@/assets/icon/caveat.png" v-if="!bankCardState">
              绑定银行卡
            </div>
          </el-col>
          <el-col :span="8">
            <div class="center">
              {{bankCardStr}}
            </div>
          </el-col>
          <el-col :span="8">
            <div class="right" v-if="bankCardState">
              <span @click="toUnbindBankCard">解绑银行卡</span>
            </div>
            <div class="right" v-if="!bankCardState">
              <span @click="bankCardModelShow">绑定银行卡</span>
            </div>
          </el-col>
        </el-row>
      </div>

      <!--      <div class="content-item">-->
      <!--        <el-row :gutter="20">-->
      <!--          <el-col :span="8">-->
      <!--            <div class="left">-->
      <!--              <img src="@/assets/icon/safety.png" v-if="AlipayState">-->
      <!--              <img src="@/assets/icon/caveat.png" v-if="!AlipayState">-->
      <!--              绑定支付宝-->
      <!--            </div>-->
      <!--          </el-col>-->
      <!--          <el-col :span="8">-->
      <!--            <div class="center">-->
      <!--              {{AlipayStr}}-->
      <!--            </div>-->
      <!--          </el-col>-->
      <!--          <el-col :span="8">-->
      <!--            <div class="right" v-if="AlipayState">-->
      <!--              <span>更换支付宝</span>-->
      <!--            </div>-->
      <!--            <div class="right" v-if="!AlipayState">-->
      <!--              <span>绑定支付宝</span>-->
      <!--            </div>-->
      <!--          </el-col>-->
      <!--        </el-row>-->
      <!--      </div>-->

    </div>

    <!-- 修改密码弹窗 -->
    <el-dialog
        title="修改密码"
        :visible.sync="passwordModelIsShow"
        width="600px"
        custom-class="accountManagement_dialog"
    >

      <div class="dialog-content">

        <div class="dialog-tab">
          <div :class="passwordModeFormNav === 1?'tab tab-checked':'tab'" @click="passwordModeFormNav = 1">
            旧密码验证
          </div>
          <div :class="passwordModeFormNav === 2?'tab tab-checked':'tab'" @click="passwordModeFormNav = 2">
            手机号验证
          </div>
        </div>

        <div class="main" v-if="passwordModeFormNav === 1">
          <el-form
              :rules="modelRules"
              ref="passwordModelPasswordRef"
              :model="passwordModePasswordForm"
              key="passwordModeFormPasswordRule"
              label-width="80px"
          >
            <div class="form-item">
              <el-form-item label="旧密码" prop="oldPassword">
                <el-input v-model.trim="passwordModePasswordForm.oldPassword" clearable></el-input>
              </el-form-item>
              <div class="desc"></div>
            </div>

            <div class="form-item">
              <el-form-item label="新密码" prop="password">
                <el-input type="password" v-model.trim="passwordModePasswordForm.password" clearable></el-input>
              </el-form-item>
              <div class="desc">
                <div>
                  <div :class="complexity >= 1?'top top-checked1':'top'"></div>
                  弱
                </div>
                <div>
                  <div :class="complexity >= 2?'top top-checked2':'top'"></div>
                  中
                </div>
                <div>
                  <div :class="complexity >= 3?'top top-checked3':'top'"></div>
                  强
                </div>
              </div>
            </div>

            <div class="form-item">
              <el-form-item label="重复密码" prop="repeatPassword">
                <el-input type="password" v-model.trim="passwordModePasswordForm.repeatPassword" clearable></el-input>
              </el-form-item>
              <div class="desc"></div>
            </div>

          </el-form>
        </div>


        <div class="main" v-if="passwordModeFormNav === 2">
          <el-form
              :rules="modelRules"
              ref="passwordModelPhoneRef"
              :model="passwordModePhoneForm"
              key="passwordModeFormPhoneRule"
              label-width="80px"
          >
            <div class="form-item">
              <span class="msg">您当前绑定的手机号是{{currPhone}}</span>
              <el-form-item label="验证码" prop="customMsgCode">
                <el-input v-model.trim="passwordModePhoneForm.customMsgCode" clearable></el-input>
              </el-form-item>
              <div class="get-code">
                <div class="btn" @click="passwordModelGetCode">
                  <get-code ref="passwordModePhoneFormGetCodeRef" :phone="accountManagementState.phone" :is-not-verify="true"></get-code>
                </div>
              </div>
            </div>

            <div class="form-item">
              <el-form-item label="新密码" prop="password">
                <el-input type="password" v-model.trim="passwordModePhoneForm.password" clearable></el-input>
              </el-form-item>
              <div class="desc">
                <div>
                  <div :class="complexity >= 1?'top top-checked1':'top'"></div>
                  弱
                </div>
                <div>
                  <div :class="complexity >= 2?'top top-checked2':'top'"></div>
                  中
                </div>
                <div>
                  <div :class="complexity >= 3?'top top-checked3':'top'"></div>
                  强
                </div>
              </div>
            </div>

            <div class="form-item">
              <el-form-item label="重复密码" prop="repeatPassword">
                <el-input type="password" v-model.trim="passwordModePhoneForm.repeatPassword" clearable></el-input>
              </el-form-item>
              <div class="desc"></div>
            </div>

          </el-form>
        </div>

      </div>

      <span slot="footer">
        <el-button @click="passwordModelIsShow = false">取 消</el-button>
        <el-button type="primary" :loading="passwordModelSubmitLoading" @click="passwordModelSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改手机号弹窗 -->
    <el-dialog
        title="修改手机号"
        :visible.sync="phoneModelIsShow"
        width="600px"
        custom-class="accountManagement_dialog"
    >

      <div class="dialog-content">

        <div class="steps">
          <el-steps :active="phoneModelActive" finish-status="success">
            <el-step title="第一步"></el-step>
            <el-step title="第二步"></el-step>
          </el-steps>
        </div>

        <div class="main" v-if="phoneModelActive <= 1">
          <el-form
              :rules="modelRules"
              ref="phoneModelCodeRef"
              :model="phoneModelCodeForm"
              key="phoneModelFormCodeRule"
              label-width="80px"
          >
            <div class="form-item">
              <span class="msg">您当前绑定的手机号是{{currPhone}}</span>
              <el-form-item label="验证码" prop="customMsgCode">
                <el-input v-model.trim="phoneModelCodeForm.customMsgCode" clearable></el-input>
              </el-form-item>
              <div class="get-code">
                <div class="btn" @click="phoneModelCodeFormGetCode">
                  <get-code ref="phoneModelCodeFormGetCodeRef" :phone="accountManagementState.phone" :is-not-verify="true"></get-code>
                </div>
              </div>
            </div>
          </el-form>
        </div>

        <div class="main" v-else>
          <el-form
              :rules="modelRules"
              ref="phoneModelPhoneRef"
              :model="phoneModelPhoneForm"
              key="phoneModelFormPhoneRule"
              label-width="80px"
          >
            <div class="form-item">
              <el-form-item label="手机号" prop="phone">
                <el-input v-model.trim="phoneModelPhoneForm.phone" clearable></el-input>
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item label="验证码" prop="msg_code">
                <el-input v-model.trim="phoneModelPhoneForm.msg_code" clearable></el-input>
              </el-form-item>
              <div class="get-code">
                <div class="btn" @click="phoneModelPhoneFormGetCode">
                  <get-code ref="phoneModelPhoneFormGetCodeRef" :phone="phoneModelPhoneForm.phone" :is-not-verify="true"></get-code>
                </div>
              </div>
            </div>
          </el-form>
        </div>

      </div>

      <span slot="footer">
        <el-button v-if="phoneModelActive <= 1" @click="phoneModelNext">下一步</el-button>
        <el-button v-else type="primary" :loading="phoneModelSubmitLoading" @click="phoneModelSubmit">完 成</el-button>
      </span>
    </el-dialog>

    <!-- 绑定微信前验证弹窗 -->
    <el-dialog
        title="绑定微信验证"
        :visible.sync="weChatModelIsShow"
        width="600px"
        custom-class="accountManagement_dialog"
    >
      <div class="dialog-content">
        <div class="main">
          <el-form
              :rules="modelRules"
              ref="weChatModelCodeRef"
              :model="weChatModelCodeForm"
              key="weChatModelFormCodeRule"
              label-width="80px"
          >
            <div class="form-item">
              <span class="msg">您当前绑定的手机号是{{currPhone}}</span>
              <el-form-item label="验证码" prop="customMsgCode">
                <el-input v-model.trim="weChatModelCodeForm.customMsgCode" clearable></el-input>
              </el-form-item>
              <div class="get-code">
                <div class="btn" @click="weChatModelCodeFormGetCode">
                  <get-code ref="weChatModelCodeFormGetCodeRef" :phone="accountManagementState.phone" :is-not-verify="true"></get-code>
                </div>
              </div>
            </div>
          </el-form>
        </div>
      </div>

      <span slot="footer">
        <el-button type="primary" @click="weChatModelSubmit">完 成</el-button>
      </span>
    </el-dialog>

    <!-- 修改邮箱弹窗 -->
    <el-dialog
        title="绑定邮箱"
        :visible.sync="mailboxIsShow"
        width="600px"
        custom-class="accountManagement_dialog"
    >

      <div class="dialog-content">

        <div class="steps">
          <el-steps :active="mailboxModelActive" finish-status="success">
            <el-step title="第一步"></el-step>
            <el-step title="第二步"></el-step>
          </el-steps>
        </div>

        <div class="main" v-if="mailboxModelActive <= 1">
          <el-form
              :rules="modelRules"
              ref="mailboxModelCodeRef"
              :model="mailboxModelCodeForm"
              key="mailboxModelFormCodeRule"
              label-width="80px"
          >
            <div class="form-item">
              <span class="msg">您当前绑定的手机号是{{currPhone}}</span>
              <el-form-item label="验证码" prop="customMsgCode">
                <el-input v-model.trim="mailboxModelCodeForm.customMsgCode" clearable></el-input>
              </el-form-item>
              <div class="get-code">
                <div class="btn" @click="mailboxModelCodeFormGetCode">
                  <get-code ref="mailboxModelCodeFormGetCodeRef" :phone="accountManagementState.phone" :is-not-verify="true"></get-code>
                </div>
              </div>
            </div>
          </el-form>
        </div>

        <div class="main" v-else>
          <el-form
              :rules="modelRules"
              ref="mailboxModelMailboxRef"
              :model="mailboxModelMailboxForm"
              key="mailboxModelFormMailboxRule"
              label-width="80px"
          >
            <div class="form-item">
              <el-form-item label="邮箱" prop="mailbox">
                <el-input v-model.trim="mailboxModelMailboxForm.mailbox" clearable></el-input>
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item label="验证码" prop="msg_code">
                <el-input v-model.trim="mailboxModelMailboxForm.msg_code" clearable></el-input>
              </el-form-item>
              <div class="get-code">
                <div class="btn" @click="mailboxModelMailboxFormGetCode">
                  <get-code ref="mailboxModelMailboxFormGetCodeRef" status="email" :mailbox="mailboxModelMailboxForm.mailbox" :is-not-verify="true"></get-code>
                </div>
              </div>
            </div>
          </el-form>
        </div>

      </div>

      <span slot="footer">
        <el-button v-if="mailboxModelActive <= 1" @click="mailboxModelNext">下一步</el-button>
        <el-button v-else type="primary" :loading="mailboxSubmitLoading" @click="mailboxModelSubmit">完 成</el-button>
      </span>
    </el-dialog>

    <!-- 实名认证弹窗 -->
    <el-dialog
        title="实名认证"
        :visible.sync="realNameIsShow"
        width="600px"
        custom-class="accountManagement_dialog"
    >

      <div class="dialog-content">

        <div class="steps">
          <el-steps :active="realNameModelActive" finish-status="success">
            <el-step title="第一步"></el-step>
            <el-step title="第二步"></el-step>
          </el-steps>
        </div>

        <div class="main" v-if="realNameModelActive <= 1">
          <el-form
              :rules="modelRules"
              ref="realNameModelCodeRef"
              :model="realNameModelCodeForm"
              key="realNameModelFormCodeRule"
              label-width="80px"
          >
            <div class="form-item">
              <span class="msg">您当前绑定的手机号是{{currPhone}}</span>
              <el-form-item label="验证码" prop="customMsgCode">
                <el-input v-model.trim="realNameModelCodeForm.customMsgCode" clearable></el-input>
              </el-form-item>
              <div class="get-code">
                <div class="btn" @click="realNameModelCodeFormGetCode">
                  <get-code ref="realNameModelCodeFormGetCodeRef" :phone="accountManagementState.phone" :is-not-verify="true"></get-code>
                </div>
              </div>
            </div>
          </el-form>
        </div>

        <div class="main" v-else>
          <el-form
              :rules="modelRules"
              ref="realNameModelRealNameRef"
              :model="realNameModelRealNameForm"
              key="realNameModelFormRealNameRule"
              label-width="80px"
          >
            <div class="form-item">
              <el-form-item label="真实姓名" prop="name">
                <el-input v-model.trim="realNameModelRealNameForm.name" clearable></el-input>
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item label="身份证号" prop="idCard">
                <el-input v-model.trim="realNameModelRealNameForm.idCard" clearable></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>

      </div>

      <span slot="footer">
        <el-button v-if="realNameModelActive <= 1" @click="realNameModelNext">下一步</el-button>
        <el-button v-else type="primary" :loading="realNameSubmitLoading" @click="realNameModelSubmit">完 成</el-button>
      </span>
    </el-dialog>

    <!-- 修改银行卡弹窗 -->
    <el-dialog
        title="修改银行卡"
        :visible.sync="bankCardModelIsShow"
        width="600px"
        custom-class="accountManagement_dialog"
    >

      <div class="dialog-content">

        <div class="steps">
          <el-steps :active="bankCardModelActive" finish-status="success">
            <el-step title="第一步"></el-step>
            <el-step title="第二步"></el-step>
          </el-steps>
        </div>

        <div class="main" v-if="bankCardModelActive <= 1">
          <el-form
              :rules="modelRules"
              ref="bankCardModelCodeRef"
              :model="bankCardModelCodeForm"
              key="bankCardModelFormCodeRule"
              label-width="80px"
          >
            <div class="form-item">
<!--              <span class="msg">您当前绑定的银行卡是{{accountManagementState.bankCardNum}}</span>-->
              <span class="msg">您当前绑定的手机号是{{currPhone}}</span>
              <el-form-item label="验证码" prop="customMsgCode">
                <el-input v-model.trim="bankCardModelCodeForm.customMsgCode" clearable></el-input>
              </el-form-item>
              <div class="get-code">
                <div class="btn" @click="bankCardModelCodeFormGetCode">
                  <get-code ref="bankCardModelCodeFormGetCodeRef" :phone="accountManagementState.phone" :is-not-verify="true"></get-code>
                </div>
              </div>
            </div>
          </el-form>
        </div>

        <div class="main" v-else>
          <el-form
              :rules="modelRules"
              ref="bankCardModelBankCardRef"
              :model="bankCardModelBankCardForm"
              key="bankCardModelFormBankCardRule"
              label-width="80px"
          >
            <div class="form-item">
              <el-form-item label="银行卡号" prop="bankCardId">
                <el-input v-model.trim="bankCardModelBankCardForm.bankCardId" clearable></el-input>
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item label="所属银行">
                <div>
                  {{bankBelong}}
                </div>
              </el-form-item>
            </div>
          </el-form>
        </div>

      </div>

      <span slot="footer">
        <el-button v-if="bankCardModelActive <= 1" @click="bankCardModelNext">下一步</el-button>
        <el-button v-else type="primary" :loading="bankCardModelSubmitLoading" @click="bankCardModelSubmit">完 成</el-button>
      </span>
    </el-dialog>

    <!-- 解绑银行卡弹窗 -->
    <el-dialog
        title="提示"
        :visible.sync="unbindBankCardVisible"
        width="400px"
    >
      <div style="text-align: center;font-size: 16px; color: #333333;">确定要解绑银行卡？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="unbindBankCardVisible = false">取 消</el-button>
        <el-button type="primary" :loading="bankCardModelSubmitLoading" @click="unbindBankCardSubmit">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import share from "@/share/share";
import GetCode from "@/components/getCode/getCode";
import {formRules} from '@/share/formRules'
import {emailRegular} from '@/share/regularExpression'
import {
  AccountManagementApi,
  ChangePasswordApi,
  PostChangeLoginPhoneApi,
  ChangeEmailApi,
  RealNameAuthenticationApi,
  LoginWithUserTokenApi,
  GetWechatAccessTokenApi,
  GetWechatUserInfoApi,
  EmployeeBindWechatApi,
  BindBankCardNumApi,
  PostEmployeeUnbindBankCardNumApi,
  PostUtilsCheckMsgCodeApi, PostUtilsCheckEmailCanUseApi,
} from '@/request/api'
import {mapMutations} from "vuex";
import {WEB_BASE_URL} from "@/config/baseURL";
import {get} from "../../request/http";
import banksName from '../../share/banksName'
import {Debounce} from "@/until/until";
export default {
  name: "accountManagement",
  components: {GetCode},
  data() {
    return {
      pageLoading: true,
      accountManagementState: {
        password: '',
        phone: '',
        bindWechat: '',  // 微信昵称
        realNameAuthentication: '',  // 是否实名认证
        mailbox: '',  // 邮箱
        Alipay: '',  // 支付宝
        bankCardNum: '',  // 银行卡
      },
      passwordState: false,
      phoneState: false,
      WechatState: false,
      realNameAuthenticationState: false,
      mailboxState: false,
      AlipayState: false,
      bankCardState: false,

      passwordModelIsShow: false,  // 修改密码弹窗
      passwordModelSubmitLoading: false,
      passwordModePasswordForm: {
        oldPassword: '',
        password: '',
        repeatPassword: '',
      },
      passwordModePhoneForm: {
        customMsgCode: '',
        password: '',
        repeatPassword: '',
      },
      modelRules: {
        ...formRules,
        customMsgCode: [
          {required: true, validator: this.msgCodeRule}
        ],
        mailbox: [
          {required: true, validator: this.mailboxRule}
        ],
        repeatPassword: [  // 再次输入密码
          {required: true, validator: this.repeatPassword}
        ],
      },
      passwordModeFormNav: 2,
      complexity: 0,

      phoneModelIsShow: false,  // 修改手机号弹窗
      phoneModelSubmitLoading: false,
      phoneModelActive: 1,
      phoneModelCodeForm: {
        customMsgCode: '',
      },
      phoneModelPhoneForm: {
        phone: '',
        msg_code: '',
      },

      WechatModelIsShow: false,
      realNameAuthenticationIsShow: false,

      mailboxIsShow: false,  // 修改邮箱弹窗
      mailboxSubmitLoading: false,
      mailboxModelActive: 1,
      mailboxModelCodeForm: {
        customMsgCode: '',
      },
      mailboxModelMailboxForm: {
        mailbox: '',
        msg_code: '',
      },

      bankCardModelIsShow: false,  // 银行卡
      bankCardModelSubmitLoading: false,
      bankCardModelActive: 1,
      bankCardModelCodeForm: {
        customMsgCode: '',
      },
      bankCardModelBankCardForm: {
        bankCardId: '',
      },

      unbindBankCardVisible: false,

      realNameIsShow: false,
      realNameSubmitLoading: false,
      realNameModelActive: 1,
      realNameModelCodeForm: {
        customMsgCode: '',
      },
      realNameModelRealNameForm: {
        name: '',
        idCard: '',
      },

      weChatModelIsShow: false,
      weChatModelCodeForm: {
        customMsgCode: '',
      },

      loading: null,
      wxCode: '',

      bankBelong: '',
    }
  },
  computed: {
    aonuoChat() {
      return this.$store.state.aonuoChat
    },
    passwordStr() {
      let str = ''
      if (this.accountManagementState.password === '' || this.accountManagementState.password === null) {
        str = '未设置'
        this.passwordState = false
      }else {
        str = '已设置'
        this.passwordState = true
      }
      return str
    },
    phoneStr() {
      let str = ''
      if (this.accountManagementState.phone === '' || this.accountManagementState.phone === null) {
        str = '未绑定手机号'
        this.phoneState = false
      }else {
        str = '已绑定'
        this.phoneState = true
      }
      return str
    },
    currPhone() {
      let str = ''
      if (this.accountManagementState.phone === '' || this.accountManagementState.phone === null) {
        str = '空'
      }else {
        let str1 = this.accountManagementState.phone.slice(0, 3)
        let str2 = this.accountManagementState.phone.slice(7, 11)
        str = str1 + '****' + str2
      }
      return str
    },
    WechatStr() {
      let str = ''
      if (this.accountManagementState.bindWechat === '' || this.accountManagementState.bindWechat === null) {
        str = '未绑定微信账号'
        this.WechatState = false
      }else {
        str = '已绑定：' + this.accountManagementState.bindWechat
        this.WechatState = true
      }
      return str
    },
    realNameAuthenticationStr() {
      let str = ''
      if (!this.accountManagementState.realNameAuthentication) {
        str = '未实名认证'
        this.realNameAuthenticationState = false
      }else {
        str = '已实名认证'
        this.realNameAuthenticationState = true
      }
      return str
    },
    mailboxStr() {
      let str = ''
      if (this.accountManagementState.mailbox) {
        str = this.accountManagementState.mailbox
        this.mailboxState = true
      }else {
        str = '未绑定邮箱'
        this.mailboxState = false
      }
      return str
    },
    currEmail() {
      return this.accountManagementState.mailbox
    },
    bankCardStr() {
      let str = ''
      if (this.accountManagementState.bankCardNum === '' || this.accountManagementState.bankCardNum === null) {
        str = '未绑定银行卡'
        this.bankCardState = false
      }else {
        let str1 = this.accountManagementState.bankCardNum.slice(0, 4)
        let str2 = this.accountManagementState.bankCardNum.slice(this.accountManagementState.bankCardNum.length - 4, this.accountManagementState.bankCardNum.length)
        str = '已绑定（' + str1 + '****' + str2 + '）'
        this.bankCardState = true
      }
      return str
    },
    AlipayStr() {
      let str = ''
      if (this.accountManagementState.Alipay === '' || this.accountManagementState.Alipay === null) {
        str = '未绑定支付宝'
        this.AlipayState = false
      }else {
        str = '已绑定'
        this.AlipayState = true
      }
      return str
    },

  },
  watch: {
    'passwordModePhoneForm.password': {
      handler: function() {
        let num = share.checkStrong(this.passwordModePhoneForm.password)
        this.complexity = num
      },
    },
    'passwordModePasswordForm.password': {
      handler: function() {
        let num = share.checkStrong(this.passwordModePasswordForm.password)
        this.complexity = num
      },
    },
    passwordModeFormNav() {
      if (this.passwordModeFormNav === 2) {
        this.passwordModePasswordForm.oldPassword = ''
        this.passwordModePasswordForm.password = ''
        this.passwordModePasswordForm.repeatPassword = ''
        this.$nextTick(()=>{
          this.$refs.passwordModelPhoneRef.resetFields()
        })
      }else {
        this.passwordModePhoneForm.customMsgCode = ''
        this.passwordModePhoneForm.password = ''
        this.passwordModePhoneForm.repeatPassword = ''
        this.$nextTick(()=>{
          this.$refs.passwordModelPasswordRef.resetFields()
        })
      }
    },
    'bankCardModelBankCardForm.bankCardId': {
      handler: function() {
        if (this.bankCardModelBankCardForm.bankCardId.length > 5) {
          this.VueDebounce({vm: this})
        }
      },
    }
  },
  mounted() {
    this.loadData()

    let local = location.href
    let code = share.getUrlCode(local, 'code')
    if (code && code !== '' && code !== 'null') {
      let stateUrl = share.getUrlCode(local, 'state')

      let state = localStorage.getItem('wx_bind_state')
      if (stateUrl && state && (stateUrl.toString() === state.toString())) {
        this.wxCode = code
        this.toH5WeChatLogin()
      }else {
        share.message.error('微信授权登录失效')
      }
    }
    localStorage.removeItem('wx_bind_state')
  },
  methods: {
    ...mapMutations(['setUserinfo', 'setToken']),
    VueDebounce: Debounce(({vm}) => {
      vm['loadCardInfo']()
    }, 500),
    async loadData() {
      this.pageLoading = true
      const res = await AccountManagementApi()

      if (res) {
        this.accountManagementState.password = res.password
        this.accountManagementState.mailbox = res.email
        this.accountManagementState.phone = res.phone
        this.accountManagementState.bindWechat = res.bindWechat
        this.accountManagementState.realNameAuthentication = res.isRealNameAuthentication
        this.accountManagementState.bankCardNum = res.bankCardNum
      }
      this.pageLoading = false
    },

    async loadCardInfo() {
      let cardNo = this.bankCardModelBankCardForm.bankCardId
      const res = await get(`https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?cardNo=${cardNo}&cardBinCheck=true`, '')
      if (res.data.validated) {
        let t = res.data.bank
        this.bankBelong = banksName[t]
      }else {
        this.bankBelong = ''
      }
    },

    repeatPassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (this.passwordModeFormNav === 1) {
        if (this.passwordModePasswordForm.password !== value) {
          callback(new Error('两次密码输入不一致'))
        } else {
          callback()
        }

      } else {
        if (this.passwordModePhoneForm.password !== value) {
          callback(new Error('两次密码输入不一致'))
        }else {
          callback()
        }
      }
    },
    async msgCodeRule(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入验证码'))
      } else {
        const res = await PostUtilsCheckMsgCodeApi({
          check: true,
          phone: this.accountManagementState.phone,
          msgCode: value,
        })
        if (res && res.res) {
          callback()
        } else {
          callback(new Error('验证码错误'))
        }
      }
    },
    async mailboxRule(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入邮箱'))
      } else if (!emailRegular.test(value)) {
        callback(new Error('邮箱格式错误'))
      } else {
        const res = await PostUtilsCheckEmailCanUseApi({
          email: value,
        })
        if (res && res.res) {
          callback()
        } else {
          callback(new Error('该邮箱已被绑定'))
        }
      }
    },
    passwordModelGetCode() {
      this.$refs.passwordModePhoneFormGetCodeRef.getCode()
    },
    phoneModelCodeFormGetCode() {
      this.$refs.phoneModelCodeFormGetCodeRef.getCode()
    },
    phoneModelPhoneFormGetCode() {
      if (this.phoneModelPhoneForm.phone  === this.accountManagementState.phone) {
        share.message.error('新手机号不能与旧手机号相同')
        return
      }
      this.$refs.phoneModelPhoneFormGetCodeRef.getCode()
    },
    mailboxModelCodeFormGetCode() {
      this.$refs.mailboxModelCodeFormGetCodeRef.getCode()
    },
    mailboxModelMailboxFormGetCode() {
      this.$refs.mailboxModelMailboxFormGetCodeRef.getCode()
    },
    bankCardModelCodeFormGetCode() {
      this.$refs.bankCardModelCodeFormGetCodeRef.getCode()
    },
    passwordModelShow() {
      this.passwordModeFormNav = 2
      this.passwordModelIsShow = true
      this.$nextTick(()=>{
        this.$refs.passwordModelPhoneRef.resetFields()
      })
    },
    passwordModelSubmit() {
      if (this.passwordModeFormNav === 1) {

        this.$refs.passwordModelPasswordRef.validate(async res => {
          if (res) {
            this.passwordModelSubmitLoading = true

            const res2 = await ChangePasswordApi({
              action: 'oldPassword',
              oldPassword: this.passwordModePasswordForm.oldPassword,
              newPassword: this.passwordModePasswordForm.password,
              confirmPassword: this.passwordModePasswordForm.repeatPassword,
            })
            if (res2) {
              share.message.success('密码修改成功！请重新登录')
              this.userExit()
            }
            this.passwordModelIsShow = false
            this.passwordModelSubmitLoading = false
          }
        })

      }else if (this.passwordModeFormNav === 2) {

        this.$refs.passwordModelPhoneRef.validate(async res => {
          if (res) {
            this.passwordModelSubmitLoading = true

            const res2 = await ChangePasswordApi({
              action: 'msgCode',
              msgCode: this.passwordModePhoneForm.customMsgCode,
              newPassword: this.passwordModePhoneForm.password,
              confirmPassword: this.passwordModePhoneForm.repeatPassword,
            })
            if (res2) {
              share.message.success('修改成功')
              this.userExit()
            }
            this.passwordModelIsShow = false
            this.passwordModelSubmitLoading = false
          }
        })

      }
    },
    phoneModelNext() {
      this.$refs.phoneModelCodeRef.validate(res => {
        if (res) {
          this.phoneModelActive ++
        }
      })
    },
    mailboxModelNext() {
      this.$refs.mailboxModelCodeRef.validate(res => {
        if (res) {
          this.mailboxModelActive ++
        }
      })
    },
    phoneModelSubmit() {
      this.$refs.phoneModelPhoneRef.validate(async res => {
        if (res) {
          if (this.phoneModelPhoneForm.phone  === this.accountManagementState.phone) {
            share.message.error('新手机号不能与旧手机号相同')
            return
          }
          this.phoneModelSubmitLoading = true
          const res2 = await PostChangeLoginPhoneApi({
            oldMsgCode: this.phoneModelCodeForm.customMsgCode,
            newMsgCode: this.phoneModelPhoneForm.msg_code,
            newPhone: this.phoneModelPhoneForm.phone,
          })
          if (res2) {
            share.message.success('修改成功')
            this.userExit()
          }

          this.phoneModelIsShow = false
          this.phoneModelSubmitLoading = false
        }
      })
    },
    phoneModelShow() {
      this.phoneModelActive = 1
      this.phoneModelCodeForm.customMsgCode = ''
      this.phoneModelPhoneForm.phone = ''
      this.phoneModelPhoneForm.msg_code = ''
      this.phoneModelIsShow = true
      this.$nextTick(()=>{
        this.$refs.phoneModelCodeRef.resetFields()
      })
    },
    mailboxModelSubmit() {
      this.$refs.mailboxModelMailboxRef.validate(async res => {
        if (res) {
          this.mailboxSubmitLoading = true
          const res2 = await ChangeEmailApi({
            msgCode: this.mailboxModelCodeForm.customMsgCode,
            newEmail: this.mailboxModelMailboxForm.mailbox,
            emailCode: this.mailboxModelMailboxForm.msg_code,
          })
          if (res2) {
            share.message.success('绑定成功')
          }
          this.$refs.mailboxModelMailboxRef.resetFields()
          this.mailboxIsShow = false
          await this.loadData()
          this.mailboxSubmitLoading = false
        }
      })
    },
    mailboxModelShow() {
      this.mailboxModelActive = 1
      this.mailboxModelCodeForm.customMsgCode = ''
      this.mailboxModelMailboxForm.mailbox = ''
      this.mailboxModelMailboxForm.msg_code = ''
      this.mailboxIsShow = true
      this.$nextTick(()=>{
        this.$refs.mailboxModelCodeRef.resetFields()
      })
    },
    bankCardModelShow() {
      if (!this.accountManagementState.realNameAuthentication) {
        share.message.warning('请先去实名认证')
        return
      }
      this.bankCardModelActive = 1
      this.bankCardModelCodeForm.customMsgCode = ''
      this.bankCardModelBankCardForm.bankCardId = ''
      this.bankBelong = ''
      this.bankCardModelIsShow = true
      this.$nextTick(()=>{
        this.$refs.bankCardModelCodeRef.resetFields()
      })
    },
    toUnbindBankCard() {
      this.unbindBankCardVisible = true
    },
    async unbindBankCardSubmit() {
      this.bankCardModelSubmitLoading = true
      const res = await PostEmployeeUnbindBankCardNumApi()
      if (res) {
        share.message.success('解绑成功')
        this.loadData()
      }
      this.bankCardModelSubmitLoading = false
      this.unbindBankCardVisible = false
    },
    bankCardModelNext() {
      this.$refs.bankCardModelCodeRef.validate(res => {
        if (res) {
          this.bankCardModelActive ++
        }
      })
    },
    bankCardModelSubmit() {
      this.$refs.bankCardModelBankCardRef.validate(async res => {
        if (res) {
          this.bankCardModelSubmitLoading = true
          const res2 = await BindBankCardNumApi({
            bankCardNum: this.bankCardModelBankCardForm.bankCardId,
            msgCode: this.bankCardModelCodeForm.customMsgCode,
          })


          if (res2.code && res2.code === 440) {
            share.message.error(res2.data.message)
          } else {
            share.message.success('恭喜你，绑定银行卡成功！')
          }
          this.bankCardModelIsShow = false
          this.bankCardModelSubmitLoading = false
        }
      })
    },
    /* 去实名认证 */
    goRealName() {
      this.realNameModelActive = 1
      this.realNameModelCodeForm.customMsgCode = ''

      this.realNameModelRealNameForm.name = ''
      this.realNameModelRealNameForm.idCard = ''
      this.realNameIsShow = true
      this.$nextTick(() => {
        this.$refs.realNameModelCodeRef.resetFields()
      })
    },

    realNameModelCodeFormGetCode() {
      this.$refs.realNameModelCodeFormGetCodeRef.getCode()
    },

    realNameModelNext() {
      this.$refs.realNameModelCodeRef.validate(res => {
        if (res) {
          this.realNameModelActive ++
        }
      })
    },

    realNameModelSubmit() {
      this.$refs.realNameModelRealNameRef.validate(async res => {
        if (res) {
          this.realNameSubmitLoading = true

          const res3 = await RealNameAuthenticationApi({...this.realNameModelRealNameForm, ...this.realNameModelCodeForm})

          if (res3) {
            if (res3.code === 441) {
              share.message.error('实名认证失败！' + res3.data.message)
            }else {
              share.message.success('实名认证成功')
              this.setUserinfo({
                username: res3.realNameInfo.name,
                gender: res3.realNameInfo.gender,
              })

              await this.loadData()
              this.realNameIsShow = false
            }
          }
          this.realNameSubmitLoading = false
        }
      })

    },

    toBindWechat() {
      this.weChatModelCodeForm.customMsgCode = ''
      this.weChatModelIsShow = true
    },

    weChatModelCodeFormGetCode() {
      this.$refs.weChatModelCodeFormGetCodeRef.getCode()
    },

    weChatModelSubmit() {
      this.$refs.weChatModelCodeRef.validate(async res => {
        if (res) {
          this.beginBindWechat()
        }
      })
    },

    beginBindWechat() {
      let timestamp = new Date().getTime()
      let num = Math.round(Math.random() * 10)
      let state = timestamp + '' + num

      localStorage.setItem('wx_bind_state', state)
      let redirect_uri = WEB_BASE_URL + 'accountManagement'
      window.location.href = 'https://open.weixin.qq.com/connect/qrconnect?appid=wxfe42628884a0a90a&redirect_uri=' + encodeURIComponent(redirect_uri) + '&response_type=code&scope=snsapi_login&state=' + state
    },

    async toH5WeChatLogin() {
      this.loading = share.loadingShow({
        id: '#hrData_warp_id',
        text: '绑定中',
        background: '#f4f2f3',
      })

      const res = await GetWechatAccessTokenApi({
        code: this.wxCode,
        platform: 'web',
      })

      if (res.data && res.data.access_token) {
        const res2 = await GetWechatUserInfoApi({
          accessToken: res.data.access_token,
          openId: res.data.openid
        })


        if (res2.data) {
          let info = {
            ...res2.data,
            unionId: res2.data.unionid,
            openId: res2.data.openid
          }

          const res3 = await EmployeeBindWechatApi({
            unionId: info.unionId || info.unionid,
            name: info.nickName || info.nickname,
          })

          if (res3) {
            this.loading.close()
            share.message.success('绑定微信账号成功')
            await this.loadData()
          }else {
            this.loading.close()
            share.message.error('绑定微信账号失败')
          }

        }else {
          this.loading.close()
          share.message.error('获取微信用户信息失败')
        }

      }else {
        this.loading.close()
        share.message.error('获取登录微信授权失败')
      }
    },

    /* 用户退出 */
    userExit() {
      this.aonuoChat.logout()
      share.loginOut()
      this.setUserinfo({
        userType: 'employee',
      })
      this.$router.push('/login')
    },

  }
}
</script>

<style lang="less" scoped>
.accountManagement_warp {

  .header-tab {
    height: 40px;
    width: 100%;
    background-color: #ffffff;

    & /deep/ .el-tabs {
      margin-left: 20px;
      position: relative;
      top: 2px;

      .el-tabs__header {
        margin-bottom: 0px;
      }

      .el-tabs__active-bar {
        height: 3px;
        background-color: #e20000;
      }

      .el-tabs__item {
        //margin: 0 10px;
      }

      .el-tabs__item.is-active {
        color: #e20000;
      }

      .el-tabs__nav-wrap::after {
        background: none;
      }

    }

  }

  .content {
    width: 100%;
    padding: 30px 30px;
    box-sizing: border-box;

    .content-item {
      width: 100%;
      height: 50px;
      background-color: #ffffff;
      border-bottom: 1px solid #d2d2d2;
      display: flex;
      align-items: center;
      margin-top: 20px;

      .el-row {
        width: 100%;

        .left {
          padding-left: 20px;
          box-sizing: border-box;
          color: #4c4c4c;
          display: flex;
          align-items: center;

          img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
          }

        }

        .center {
          width: 100%;
          color: #838282;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .right {
          color: #409EFF;
          padding-right: 40px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          span {
            cursor: pointer;
          }

        }

      }

    }

  }

}
</style>
<style lang="less">
.accountManagement_dialog {

  .dialog-content {
    width: 100%;

    .dialog-tab {
      width: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;

      .tab {
        color: #353535;
        font-size: 16px;
        padding: 0 30px;
        border-right: 1px solid #bbbbbb;
        height: 18px;
        line-height: 18px;
        cursor: pointer;

        &:last-child {
          border-right: 0px;
        }

      }

      .tab-checked {
        color: #e20000;
      }

    }

    .steps {
      width: 400px;
      margin: 0 auto;
    }

    .main {
      margin-top: 30px;

      .el-form {

        .form-item {
          display: flex;
          position: relative;

          .msg {
            position: absolute;
            color: #eba825;
            top: -20px;
            left: 80px;
            font-size: 14px;
          }

          .el-form-item {
            flex: 1;
          }

          .desc {
            width: 150px;
            display: flex;
            justify-content: center;
            padding-top: 16px;
            box-sizing: border-box;
            padding-left: 10px;

            >div {
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin: 0 3px;
              color: #a8a8a8;
              font-size: 14px;

              .top {
                width: 100%;
                height: 3px;
                background-color: #fdfdfd;
                border: 1px solid #e7e7e7;
                border-radius: 5px;
              }
              .top-checked1 {
                background-color: #F56C6C;
              }
              .top-checked2 {
                background-color: #E6A23C;
              }
              .top-checked3 {
                background-color: #67C23A;
              }

            }

          }

          .get-code {
            width: 150px;
            display: flex;
            justify-content: center;
            padding-bottom: 22px;

            >.btn {
              width: 100%;
              margin-left: 5px;
              background-color: #ffffff;
              border: 1px solid #bfbfbf;
            }

          }

        }

      }

    }

  }

  .el-dialog__footer {
    text-align: center;
  }

}
</style>
