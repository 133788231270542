let service,hypertext_transfer_protocol,WebSocket_protocol;
// 开发环境
//     service = 'jiaxinzp.com:9999'
//     hypertext_transfer_protocol = 'http'
//     WebSocket_protocol = 'ws'
// 生产环境
service = 'chat.jiaxinzp.com'
hypertext_transfer_protocol = 'https'
WebSocket_protocol = 'wss'


function AoNuoChatService() {
    this.service = service
    this.deviceId = ''  // 设备id
    this.WebSocket = null  // SDK 实例
    // this.SDKAppID = ''  // SDK AppID
    this.userSig = ''  // SDK 令牌
    this.userID = ''  // 用户 id
    this.lockReconnect = false
    this.connectTimer = null
    this.onKeyList = []  // 放置的监听事件
    this.EVENT = {
        // SDK_READY: 'sdkStateReady',  // 收到离线消息和会话列表同步完毕通知
        SDK_READY: 'sdkStateReady',  // 通道已建立,可以发送消息了
        LOGIN_SUCCESS: 'loginSuccess',  // 聊天登录认证成功
        LOGOUT_SUCCESS: 'logoutSuccess',  // 聊天登出成功
        MESSAGE_SEND_SUCCESS: 'onMessageSendSuccess',  // 消息发送成功的响应
        MESSAGE_RECEIVED: 'onMessageReceived',  // 收到推送的单聊、群聊、群提示、群系统通知的新消息
        MESSAGE_REVOKED: 'onMessageRevoked',  // 收到消息被撤回的通知

        MESSAGE_READ_BY_PEER: 'onMessageReadByPeer',  // 收到对端已读消息的通知
        MESSAGE_BLOCKED_ERROR: 'onMessageBlockedError',  // 被拉黑导致消息发送失败的响应

        // MESSAGE_READ_BY_PEER: 'onMessageReadByPeer',  // 收到对端已读消息的通知
        // CONVERSATION_LIST_UPDATED: 'onConversationListUpdated',  // 收到会话列表更新通知
        CONVERSATION_CREATED: 'onConversationCreated',  // 收到会话创建通知
        // GROUP_LIST_UPDATED: 'onGroupListUpdated',  // 收到群组列表更新通知
        // PROFILE_UPDATED: 'onProfileUpdated',  // 收到自己或好友的资料变更通知
        // BLACKLIST_UPDATED: 'blacklistUpdated',  // 收到黑名单列表更新通知
        ERROR: 'error',  // 收到 SDK 发生错误通知
        SDK_NOT_READY: 'sdkStateNotReady',  // 收到 SDK 进入 not ready 状态通知，此时 SDK 无法正常工作
        KICKED_OUT: 'kickedOut',  // 收到被踢下线通知
        // NET_STATE_CHANGE: 'netStateChange',  // 网络状态发生改变
    }

    /**
     * 获取 UserSig
     * */
    this.getUserSig = (userID = this.userID) => {

        return new Promise((resolve, reject) => {

            let userToken = hex_md5(userID)
            ajax({
                type: 'POST',
                url: hypertext_transfer_protocol + '://' + this.service + '/chat/get-user-sig/',
                ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
                data: 'token=' + userToken + '&user_id=' + userID
            }).then((res) => {
                console.log('getUserSig', res)
                if (res.code === 200 && res.data) {
                    resolve(res.data.user_sig)
                }else {
                    reject(res)
                }
            }).catch((err) => {
                reject(err)
            })

        })
    }

    /**
     * 创建通道连接
     * */
    this.connect = () => {
        if(this.lockReconnect) {
            return
        }
        this.lockReconnect = true
        //没连接上会一直重连，设置延迟避免请求过多
        this.connectTimer && clearTimeout(this.connectTimer)
        this.connectTimer = setTimeout(() => {
            this.create().then((res) => {
                this.lockReconnect = false
            })
        }, 2000)

    }

    /**
     * 初始化 SDK 实例
     * */
    this.create = () => {

        return new Promise((resolve, reject) => {

            try {
                let webSocketUrl = WebSocket_protocol + '://' + this.service + '/ws/chat/'
                console.log('connect', '创建通道连接')
                this.WebSocket = new WebSocket(webSocketUrl)
                this.init()
                resolve(this.WebSocket)
            }catch(err) {
                this.onKeyList.forEach((item, index) => {
                    if (item.key === 'error') {
                        item.fun(err)
                    }
                })
                reject(err)
            }

        })


    }

    /**
     * 初始化实例
     * */
    this.init = () => {
        /**
         * 通道连接成功
         * */
        this.WebSocket.onopen = (res) => {
            console.log('onopen---通道连接成功')
            this.onKeyList.forEach((item, index) => {
                if (item.key === 'sdkStateReady') {
                    item.fun(res)
                }
            })
        }

        /**
         * 通道连接报错
         * */
        this.WebSocket.onerror = (res) => {
            console.log('res---通道连接报错')
            this.onKeyList.forEach((item, index) => {
                if (item.key === 'error') {
                    item.fun(res)
                }
            })
        }

        /**
         * 通道连接收到消息
         * */
        this.WebSocket.onmessage = (res) => {
            console.log('onmessage', res)
            if(typeof res.data === 'string') {
                console.log('------------onSocketMessage---string:', res)
                let data = JSON.parse(res.data)
                if (data.type === 'deviceNo') {
                    this.deviceId = data.device_id
                } else if (data.type === 'onConversationCreated') {
                    this.onKeyList.forEach((item, index) => {
                        if (item.key === 'onConversationCreated') {
                            item.fun(data)
                        }
                    })
                } else if (data.type === 'onMessageReadByPeer') {
                    this.onKeyList.forEach((item, index) => {
                        if (item.key === 'onMessageReadByPeer') {
                            item.fun(data)
                        }
                    })
                } else if (data.type === 'kickedOut') {
                    this.onKeyList.forEach((item, index) => {
                        if (item.key === 'kickedOut') {
                            item.fun(data)
                        }
                    })
                } else {
                    this.onKeyList.forEach((item, index) => {
                        if (data.type === item.key) {
                            item.fun(data.chat_log)
                        }
                    })
                }
            }

            if(res.data instanceof ArrayBuffer){
                console.log('------------onSocketMessage---ArrayBuffer:', res)
                let buffer = res.data
                console.log('Received arraybuffer', buffer)
            }

        }

        /**
         * 通道连接关闭
         * */
        this.WebSocket.onclose = (res) => {
            console.log('onclose---通道连接关闭')
            this.onKeyList.forEach((item, index) => {
                if (item.key === 'sdkStateNotReady') {
                    this.deviceId = ''
                    item.fun(res)
                }
            })
        }
    }

    /**
     * 关闭 SDK 实例
     * */
    this.close = () => {
        this.WebSocket.close()
        this.WebSocket = null
    }

    /**
     * 监听事件
     * */
    this.on = (key, callback) => {
        if (this.EVENT[key] && this.EVENT[key] !== '' && this.EVENT[key] !== null) {
            let state = false
            this.onKeyList.forEach((item, index) => {
                if (item.key === this.EVENT[key]) {
                    state = true
                }
            })
            if (!state) {
                let obj = {
                    key: this.EVENT[key],
                    fun: callback
                }
                this.onKeyList.push(obj)
            }
        }
    }

    /**
     * 取消监听事件
     * */
    this.off = (key) => {
        this.onKeyList.forEach((item, index) => {
            if (item.key === key) {
                this.onKeyList.splice(index, 1)
            }
        })
    }

    /**
     * 创建聊天系统用户
     * */
    this.createChatUser = (userData) => {
        return new Promise((resolve, reject) => {
            let data = ''
            if (userData.userId) {
                if (data !== '') {
                    data += '&'
                }
                data += 'user_id=' + userData.userId
            }
            if (userData.name) {
                if (data !== '') {
                    data += '&'
                }
                data += 'name=' + userData.name
            }
            if (userData.nickname) {
                if (data !== '') {
                    data += '&'
                }
                data += 'nickname=' + userData.nickname
            }
            if (userData.username) {
                if (data !== '') {
                    data += '&'
                }
                data += 'username=' + userData.username
            }
            if (userData.gender) {
                if (data !== '') {
                    data += '&'
                }
                data += 'gender=' + userData.gender
            }
            if (userData.birthday) {
                if (data !== '') {
                    data += '&'
                }
                data += 'birthday=' + userData.birthday
            }
            if (userData.idCardNum) {
                if (data !== '') {
                    data += '&'
                }
                data += 'id_card_num=' + userData.idCardNum
            }
            if (userData.phone) {
                if (data !== '') {
                    data += '&'
                }
                data += 'phone=' + userData.phone
            }
            if (userData.email) {
                if (data !== '') {
                    data += '&'
                }
                data += 'email=' + userData.email
            }
            if (userData.province) {
                if (data !== '') {
                    data += '&'
                }
                data += 'province=' + userData.province
            }
            if (userData.city) {
                if (data !== '') {
                    data += '&'
                }
                data += 'city=' + userData.city
            }
            if (userData.avatar) {
                if (data !== '') {
                    data += '&'
                }
                data += 'avatar=' + userData.avatar
            }
            if (userData.description) {
                if (data !== '') {
                    data += '&'
                }
                data += 'description=' + userData.description
            }

            ajax({
                type: 'POST',
                url: hypertext_transfer_protocol + '://' + this.service + '/chat/create-chat-user/',
                ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
                data: data
            }).then((res) => {
                if (res.code === 200 && res.data) {
                    resolve(res.data)
                }else {
                    reject(res)
                }
            }).catch((err) => {
                reject(err)
            })

        })
    }

    /**
     * 登录聊天系统
     * */
    this.login = (userID) => {
        return new Promise((resolve, reject) => {
            this.userID = userID
            this.getUserSig(userID).then((res) => {
                console.log('login', res, this.deviceId)
                ajax({
                    type: 'POST',
                    url: hypertext_transfer_protocol + '://' + this.service + '/chat/chat-login/',
                    ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
                    Authorization: res,
                    data: 'device_id=' + this.deviceId
                }).then((res2) => {
                    if (res2.code === 200 && res2.data) {
                        resolve(res2.data)
                    }else {
                        reject(res2)
                    }
                }).catch((err2) => {
                    reject(err2)
                })
            })

        })
    }

    /**
     * 登出聊天系统
     * */
    this.logout = () => {

        return new Promise((resolve, reject) => {

            this.getUserSig(this.userID).then((res) => {
                ajax({
                    type: 'POST',
                    url: hypertext_transfer_protocol + '://' + this.service + '/chat/chat-logout/',
                    ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
                    Authorization: res,
                    data: 'device_id=' + this.deviceId
                }).then((res2) => {
                    if (res2.code === 200 && res2.data) {
                        resolve(res2.data)
                    }else {
                        reject(res2)
                    }
                }).catch((err2) => {
                    reject(err2)
                })
            })

        })
    }

    /**
     * 创建文本消息，返回消息实例
     * */
    this.createTextMessage = (params) => {
        if (params !== '') {
            let obj = {
                to_session: params.toSession,
                content: {
                    id: params.fileId,
                    type: 'text',
                    text: params.text
                },
            }
            return  JSON.stringify(obj)
        }
    }

    /**
     * 创建图片消息，返回消息实例
     * */
    this.createImageMessage = (params) => {
        if (params !== '') {
            let obj = {
                to_session: params.toSession,
                content: {
                    id: params.fileId,
                    type: 'image',
                    url: params.url
                },
            }
            return  JSON.stringify(obj)
        }
    }

    /**
     * 创建语音消息，返回消息实例
     * */
    this.createVoiceMessage = (params) => {
        if (params !== '') {
            let obj = {
                to_session: params.toSession,
                content: {
                    id: params.fileId,
                    type: 'voice',
                    url: params.url,
                    length: params.length,
                    voiceDuration: params.voiceDuration
                },
            }
            return  JSON.stringify(obj)
        }
    }

    /**
     * 创建 custom 类型消息，返回消息实例
     * */
    this.createCustomMessage = (params) => {
        if (params !== '') {
            let obj = {
                to_session: params.toSession,
                content: {
                    id: params.fileId,
                    type: params.type || 'text',
                    data: params.data
                },
            }
            return  JSON.stringify(obj)
        }
    }

    /**
     * 发送消息
     * */
    this.sendMessage = (message) => {
        this.WebSocket.send(message)
    }

    /**
     * 获取消息列表
     * */
    this.getMessageList = (sessionId, offset = 0, length = 10, total = '') => {

        return new Promise((resolve, reject) => {

            this.getUserSig(this.userID).then((res) => {
                if (res) {

                    ajax({
                        type: 'GET',
                        url: hypertext_transfer_protocol + '://' + this.service + '/chat/chat-log-info/',
                        ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
                        Authorization: res,
                        data: 'session_id=' + sessionId + '&offset=' + offset + '&length=' + length + '&total=' + total
                    }).then((res2) => {
                        if (res2.code === 200 && res2.data) {
                            resolve(res2.data)
                        }else {
                            reject(res2)
                        }
                    }).catch((err2) => {
                        reject(err2)
                    })

                }else {
                    reject(res)
                }
            }).catch(err => {
                reject(err)
            })

        })

    }

    /**
     * 设置消息已读
     * */
    this.setMessageRead = (sessionId, total = '') => {

        return new Promise((resolve, reject) => {

            this.getUserSig(this.userID).then((res) => {
                if (res) {

                    ajax({
                        type: 'POST',
                        url: hypertext_transfer_protocol + '://' + this.service + '/chat/single-chat-mark-as-read/',
                        ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
                        Authorization: res,
                        data: 'session_id=' + sessionId + '&total=' + total
                    }).then((res2) => {
                        if (res2.code === 200 && res2.data) {
                            resolve(res2.data)
                        }else {
                            reject(res2)
                        }
                    }).catch((err2) => {
                        reject(err2)
                    })

                }else {
                    reject(res)
                }
            }).catch(err => {
                reject(err)
            })

        })

    }

    /**
     * 获取未读消息的数量
     * */
    this.getMessageUnreadCount = () => {
        return new Promise((resolve, reject) => {
            this.getUserSig(this.userID).then((res) => {
                if (res) {
                    ajax({
                        type: 'GET',
                        url: hypertext_transfer_protocol + '://' + this.service + '/chat/unread-count/',
                        Authorization: res,
                    }).then((res2) => {
                        if (res2.code === 200 && res2.data) {
                            resolve(res2.data)
                        }else {
                            reject(res2)
                        }
                    }).catch((err2) => {
                        reject(err2)
                    })
                }else {
                    reject(res)
                }
            })
        })
    }

    /**
     * 获取会话列表
     * */
    this.getConversationList = (offset = 0, length = 10, total= '', filterUnread = '') => {
        return new Promise((resolve, reject) => {

            this.getUserSig(this.userID).then((res) => {
                if (res) {
                    ajax({
                        type: 'GET',
                        url: hypertext_transfer_protocol + '://' + this.service + '/chat/session-info/',
                        ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
                        Authorization: res,
                        data: 'offset=' + offset + '&length=' + length + '&total=' + total + '&filterUnread=' + filterUnread
                    }).then((res2) => {
                        if (res2.code === 200 && res2.data) {
                            resolve(res2.data)
                        }else {
                            reject(res2)
                        }
                    }).catch((err2) => {
                        reject(err2)
                    })

                }else {
                    reject(res)
                }
            }).catch(err => {
                reject(err)
            })

        })
    }

    /**
     * 搜索会话列表
     * */
    this.getSearchConversationList = (searchValue = '', total = '') => {
        return new Promise((resolve, reject) => {

            this.getUserSig(this.userID).then((res) => {
                if (res) {

                    ajax({
                        type: 'GET',
                        url: hypertext_transfer_protocol + '://' + this.service + '/chat/search-session/',
                        Authorization: res,
                        data: 'search_text=' + searchValue + '&total=' + total
                    }).then((res2) => {
                        if (res2.code === 200 && res2.data) {
                            resolve(res2.data)
                        }else {
                            reject(res2)
                        }
                    }).catch((err2) => {
                        reject(err2)
                    })

                }else {
                    reject(res)
                }
            }).catch(err => {
                reject(err)
            })

        })
    }

    /**
     * 获取会话资料
     * */
    this.getConversationProfile = () => {

    }

    /**
     * 创建会话
     * */
    this.createConversation = (params) => {
        return new Promise((resolve, reject) => {

            this.getUserSig(this.userID).then((res) => {
                let chatUserIdList = []
                chatUserIdList.push(this.userID)
                if (params.toUserId) {
                    chatUserIdList.push(params.toUserId)
                }else {
                    reject('toUserId 不能为空')
                }
                if (res) {

                    let type = 1
                    if (params.type) {
                        type = params.type
                    }

                    let job_information = params.jobInformation || ''

                    ajax({
                        type: 'POST',
                        url: hypertext_transfer_protocol + '://' + this.service + '/chat/create-session/',
                        ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
                        Authorization: res,
                        // data: 'name=' + params.name + '&type=' + type + '&chat_user_id_list=' + JSON.stringify(chatUserIdList) + '&job_information=' + job_information
                        data: `name=${params.name}&type=${type}&chat_user_id_list=${JSON.stringify(chatUserIdList)}&job_information=${job_information}`
                    }).then((res2) => {
                        if (res2.code === 200 && res2.data) {
                            resolve(res2.data)
                        }else {
                            reject(res2)
                        }
                    }).catch((err2) => {
                        reject(err2)
                    })

                }else {
                    reject(res)
                }
            }).catch(err => {
                reject(err)
            })

        })
    }

    /**
     * 删除会话
     * */
    this.deleteConversation = (sessionId) => {
        return new Promise((resolve, reject) => {

            this.getUserSig(this.userID).then((res) => {
                if (res) {
                    ajax({
                        type: 'POST',
                        url: hypertext_transfer_protocol + '://' + this.service + '/chat/delete-session/',
                        ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
                        Authorization: res,
                        data: 'session_id=' + sessionId
                    }).then((res2) => {
                        if (res2.code === 200 && res2.data) {
                            resolve(res2.data)
                        }else {
                            reject(res2)
                        }
                    }).catch((err2) => {
                        reject(err2)
                    })

                }else {
                    reject(res)
                }
            }).catch(err => {
                reject(err)
            })

        })
    }

    /**
     * 获取常用语列表
     * */
    this.getCommonSentenceList = () => {
        return new Promise((resolve, reject) => {

            this.getUserSig(this.userID).then((res) => {
                if (res) {
                    ajax({
                        type: 'GET',
                        url: hypertext_transfer_protocol + '://' + this.service + '/chat/common-sentence/',
                        Authorization: res,
                    }).then((res2) => {
                        if (res2.code === 200 && res2.data) {
                            resolve(res2.data)
                        }else {
                            reject(res2)
                        }
                    }).catch((err2) => {
                        reject(err2)
                    })
                }else {
                    reject(res)
                }
            }).catch(err => {
                reject(err)
            })

        })
    }

    /**
     * 管理常用语列表
     * */
    this.postCommonSentenceList = (action = 'add', sentence = '', sentence_index = 0) => {
        return new Promise((resolve, reject) => {

            this.getUserSig(this.userID).then((res) => {
                if (res) {
                    ajax({
                        type: 'POST',
                        url: hypertext_transfer_protocol + '://' + this.service + '/chat/common-sentence/',
                        ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
                        Authorization: res,
                        data: 'action=' + action + '&sentence=' + sentence + '&sentence_index=' + sentence_index
                    }).then((res2) => {
                        if (res2.code === 200 && res2.data) {
                            resolve(res2.data)
                        }else {
                            reject(res2)
                        }
                    }).catch((err2) => {
                        reject(err2)
                    })
                }else {
                    reject(res)
                }
            }).catch(err => {
                reject(err)
            })

        })
    }

    /**
     * 置顶指定会话
     * */
    this.postConversationSetTop = (action = 'set_top', sessionId = '') => {
        return new Promise((resolve, reject) => {
            this.getUserSig(this.userID).then((res) => {
                if (res) {
                    ajax({
                        type: 'POST',
                        url: hypertext_transfer_protocol + '://' + this.service + '/chat/set-top/',
                        ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
                        Authorization: res,
                        data: 'action=' + action + '&session_id=' + sessionId
                    }).then((res2) => {
                        if (res2.code === 200 && res2.data) {
                            resolve(res2.data)
                        }else {
                            reject(res2)
                        }
                    }).catch((err2) => {
                        reject(err2)
                    })
                }else {
                    reject(res)
                }
            }).catch(err => {
                reject(err)
            })

        })
    }

    /**
     * 获取拉黑列表
     * */
    this.getSetBlockList = (offset = 0, length = 10, total = '') => {
        return new Promise((resolve, reject) => {

            this.getUserSig(this.userID).then((res) => {
                if (res) {
                    ajax({
                        type: 'GET',
                        url: hypertext_transfer_protocol + '://' + this.service + '/chat/set-block/',
                        Authorization: res,
                        data: 'offset=' + offset + '&length=' + length + '&total=' + total
                    }).then((res2) => {
                        if (res2.code === 200 && res2.data) {
                            resolve(res2.data)
                        }else {
                            reject(res2)
                        }
                    }).catch((err2) => {
                        reject(err2)
                    })
                }else {
                    reject(res)
                }
            }).catch(err => {
                reject(err)
            })

        })
    }

    /**
     * 拉黑/取消拉黑会话
     * */
    this.postSetBlock = (action = 'set_block', sessionId = '') => {
        return new Promise((resolve, reject) => {
            this.getUserSig(this.userID).then((res) => {
                if (res) {
                    ajax({
                        type: 'POST',
                        url: hypertext_transfer_protocol + '://' + this.service + '/chat/set-block/',
                        ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
                        Authorization: res,
                        data: 'action=' + action + '&session_id=' + sessionId
                    }).then((res2) => {
                        if (res2.code === 200 && res2.data) {
                            resolve(res2.data)
                        }else {
                            reject(res2)
                        }
                    }).catch((err2) => {
                        reject(err2)
                    })
                }else {
                    reject(res)
                }
            }).catch(err => {
                reject(err)
            })

        })
    }

    /**
     * 当前用户是否可以在指定会话发消息
     * */
    this.getCanSendMessage = (sessionId) => {
        return new Promise((resolve, reject) => {

            this.getUserSig(this.userID).then((res) => {
                if (res) {
                    ajax({
                        type: 'GET',
                        url: hypertext_transfer_protocol + '://' + this.service + '/chat/can-send-message/',
                        Authorization: res,
                        data: 'session_id=' + sessionId
                    }).then((res2) => {
                        if (res2.code === 200 && res2.data) {
                            resolve(res2.data)
                        }else {
                            reject(res2)
                        }
                    }).catch((err2) => {
                        reject(err2)
                    })
                }else {
                    reject(res)
                }
            }).catch(err => {
                reject(err)
            })

        })
    }

    /**
     * 临时登录
     * */
    this.postTemporaryLogin = () => {
        return new Promise((resolve, reject) => {
            ajax({
                type: 'POST',
                url: hypertext_transfer_protocol + '://' + this.service + '/chat/temporary-login/',
                ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
                data: 'device_id=' + this.deviceId
            }).then((res2) => {
                if (res2.code === 200 && res2.data) {
                    this.userID = res2.data.user_id
                    resolve(res2.data)
                }else {
                    reject(res2)
                }
            }).catch((err2) => {
                reject(err2)
            })

        })
    }

    /**
     * 获取客服问题列表
     * */
    this.getCustomerServiceQuestionList = () => {
        return new Promise((resolve, reject) => {

            this.getUserSig(this.userID).then((res) => {
                if (res) {
                    ajax({
                        type: 'GET',
                        url: hypertext_transfer_protocol + '://' + this.service + '/chat/customer-service-question-list/',
                        Authorization: res,
                        data: ''
                    }).then((res2) => {
                        if (res2.code === 200 && res2.data) {
                            resolve(res2.data)
                        }else {
                            reject(res2)
                        }
                    }).catch((err2) => {
                        reject(err2)
                    })
                }else {
                    reject(res)
                }
            }).catch(err => {
                reject(err)
            })

        })
    }

    /**
     * 发送给自动客服
     * */
    this.postAutoCustomerService = (content = '') => {
        return new Promise((resolve, reject) => {
            this.getUserSig(this.userID).then((res) => {
                if (res) {
                    ajax({
                        type: 'POST',
                        url: hypertext_transfer_protocol + '://' + this.service + '/chat/auto-customer-service/',
                        ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
                        Authorization: res,
                        data: 'device_id=' + this.deviceId + '&content=' + content
                    }).then((res2) => {
                        if (res2.code === 200 && res2.data) {
                            resolve(res2.data)
                        }else {
                            reject(res2)
                        }
                    }).catch((err2) => {
                        reject(err2)
                    })

                }else {
                    reject(res)
                }
            }).catch(err => {
                reject(err)
            })

        })
    }


    /**
     * 撤回消息
     * */
    this.postRecallMessage = (chatLogId = '') => {
        return new Promise((resolve, reject) => {

            this.getUserSig(this.userID).then((res) => {
                if (res) {
                    ajax({
                        type: 'POST',
                        url: hypertext_transfer_protocol + '://' + this.service + '/chat/recall-message/',
                        ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
                        Authorization: res,
                        data: 'chat_log_id=' + chatLogId
                    }).then((res2) => {
                        if (res2.code === 200 && res2.data) {
                            resolve(res2.data)
                        }else if (res2.code === 400) {
                            resolve(res2)
                        }else {
                            reject(res2)
                        }
                    }).catch((err2) => {
                        reject(err2)
                    })

                }else {
                    reject(res)
                }
            }).catch(err => {
                reject(err)
            })

        })
    }

}

export default AoNuoChatService



function ajax(opts) {
    let xhr = new XMLHttpRequest()
    let type = opts.type || 'GET'
    let url = opts.url
    let params = opts.data || ''
    let dataType = opts.dataType || 'json';

    type = type.toUpperCase();

    if (type === 'GET') {
        url=url+'?'+params
    }

    xhr.open(type, url);

    if (opts.ContentType) {
        xhr.setRequestHeader('Content-type', opts.ContentType);
    }

    if (opts.Authorization) {
        xhr.setRequestHeader('Authorization', opts.Authorization);
    }

    xhr.send(params ? params : null);

    //return promise
    return new Promise(function (resolve, reject) {
        //onload are executed just after the sync request is comple，
        //please use 'onreadystatechange' if need support IE9-
        xhr.onload = function () {
            if (xhr.status === 200) {
                let result;
                try {
                    result = JSON.parse(xhr.response);
                } catch (e) {
                    result = xhr.response;
                }
                resolve(result);
            } else {
                reject(xhr.response);
            }
        };

    }).catch((e) => {});
}



/*
 * A JavaScript implementation of the RSA Data Security, Inc. MD5 Message
 * Digest Algorithm, as defined in RFC 1321.
 * Version 2.1 Copyright (C) Paul Johnston 1999 - 2002.
 * Other contributors: Greg Holt, Andrew Kepert, Ydnar, Lostinet
 * Distributed under the BSD License
 * See http://pajhome.org.uk/crypt/md5 for more info.
 */

/*
 * Configurable variables. You may need to tweak these to be compatible with
 * the server-side, but the defaults work in most cases.
 */
var hexcase = 0;  /* hex output format. 0 - lowercase; 1 - uppercase        */
var b64pad  = ""; /* base-64 pad character. "=" for strict RFC compliance   */
var chrsz   = 8;  /* bits per input character. 8 - ASCII; 16 - Unicode      */

/*
 * These are the functions you'll usually want to call
 * They take string arguments and return either hex or base-64 encoded strings
 */
function hex_md5(s){ return binl2hex(core_md5(str2binl(s), s.length * chrsz));}
function b64_md5(s){ return binl2b64(core_md5(str2binl(s), s.length * chrsz));}
function str_md5(s){ return binl2str(core_md5(str2binl(s), s.length * chrsz));}
function hex_hmac_md5(key, data) { return binl2hex(core_hmac_md5(key, data)); }
function b64_hmac_md5(key, data) { return binl2b64(core_hmac_md5(key, data)); }
function str_hmac_md5(key, data) { return binl2str(core_hmac_md5(key, data)); }

/*
 * Perform a simple self-test to see if the VM is working
 */
function md5_vm_test()
{
    return hex_md5("abc") == "900150983cd24fb0d6963f7d28e17f72";
}

/*
 * Calculate the MD5 of an array of little-endian words, and a bit length
 */
function core_md5(x, len)
{
    /* append padding */
    x[len >> 5] |= 0x80 << ((len) % 32);
    x[(((len + 64) >>> 9) << 4) + 14] = len;

    var a =  1732584193;
    var b = -271733879;
    var c = -1732584194;
    var d =  271733878;

    for(var i = 0; i < x.length; i += 16)
    {
        var olda = a;
        var oldb = b;
        var oldc = c;
        var oldd = d;

        a = md5_ff(a, b, c, d, x[i+ 0], 7 , -680876936);
        d = md5_ff(d, a, b, c, x[i+ 1], 12, -389564586);
        c = md5_ff(c, d, a, b, x[i+ 2], 17,  606105819);
        b = md5_ff(b, c, d, a, x[i+ 3], 22, -1044525330);
        a = md5_ff(a, b, c, d, x[i+ 4], 7 , -176418897);
        d = md5_ff(d, a, b, c, x[i+ 5], 12,  1200080426);
        c = md5_ff(c, d, a, b, x[i+ 6], 17, -1473231341);
        b = md5_ff(b, c, d, a, x[i+ 7], 22, -45705983);
        a = md5_ff(a, b, c, d, x[i+ 8], 7 ,  1770035416);
        d = md5_ff(d, a, b, c, x[i+ 9], 12, -1958414417);
        c = md5_ff(c, d, a, b, x[i+10], 17, -42063);
        b = md5_ff(b, c, d, a, x[i+11], 22, -1990404162);
        a = md5_ff(a, b, c, d, x[i+12], 7 ,  1804603682);
        d = md5_ff(d, a, b, c, x[i+13], 12, -40341101);
        c = md5_ff(c, d, a, b, x[i+14], 17, -1502002290);
        b = md5_ff(b, c, d, a, x[i+15], 22,  1236535329);

        a = md5_gg(a, b, c, d, x[i+ 1], 5 , -165796510);
        d = md5_gg(d, a, b, c, x[i+ 6], 9 , -1069501632);
        c = md5_gg(c, d, a, b, x[i+11], 14,  643717713);
        b = md5_gg(b, c, d, a, x[i+ 0], 20, -373897302);
        a = md5_gg(a, b, c, d, x[i+ 5], 5 , -701558691);
        d = md5_gg(d, a, b, c, x[i+10], 9 ,  38016083);
        c = md5_gg(c, d, a, b, x[i+15], 14, -660478335);
        b = md5_gg(b, c, d, a, x[i+ 4], 20, -405537848);
        a = md5_gg(a, b, c, d, x[i+ 9], 5 ,  568446438);
        d = md5_gg(d, a, b, c, x[i+14], 9 , -1019803690);
        c = md5_gg(c, d, a, b, x[i+ 3], 14, -187363961);
        b = md5_gg(b, c, d, a, x[i+ 8], 20,  1163531501);
        a = md5_gg(a, b, c, d, x[i+13], 5 , -1444681467);
        d = md5_gg(d, a, b, c, x[i+ 2], 9 , -51403784);
        c = md5_gg(c, d, a, b, x[i+ 7], 14,  1735328473);
        b = md5_gg(b, c, d, a, x[i+12], 20, -1926607734);

        a = md5_hh(a, b, c, d, x[i+ 5], 4 , -378558);
        d = md5_hh(d, a, b, c, x[i+ 8], 11, -2022574463);
        c = md5_hh(c, d, a, b, x[i+11], 16,  1839030562);
        b = md5_hh(b, c, d, a, x[i+14], 23, -35309556);
        a = md5_hh(a, b, c, d, x[i+ 1], 4 , -1530992060);
        d = md5_hh(d, a, b, c, x[i+ 4], 11,  1272893353);
        c = md5_hh(c, d, a, b, x[i+ 7], 16, -155497632);
        b = md5_hh(b, c, d, a, x[i+10], 23, -1094730640);
        a = md5_hh(a, b, c, d, x[i+13], 4 ,  681279174);
        d = md5_hh(d, a, b, c, x[i+ 0], 11, -358537222);
        c = md5_hh(c, d, a, b, x[i+ 3], 16, -722521979);
        b = md5_hh(b, c, d, a, x[i+ 6], 23,  76029189);
        a = md5_hh(a, b, c, d, x[i+ 9], 4 , -640364487);
        d = md5_hh(d, a, b, c, x[i+12], 11, -421815835);
        c = md5_hh(c, d, a, b, x[i+15], 16,  530742520);
        b = md5_hh(b, c, d, a, x[i+ 2], 23, -995338651);

        a = md5_ii(a, b, c, d, x[i+ 0], 6 , -198630844);
        d = md5_ii(d, a, b, c, x[i+ 7], 10,  1126891415);
        c = md5_ii(c, d, a, b, x[i+14], 15, -1416354905);
        b = md5_ii(b, c, d, a, x[i+ 5], 21, -57434055);
        a = md5_ii(a, b, c, d, x[i+12], 6 ,  1700485571);
        d = md5_ii(d, a, b, c, x[i+ 3], 10, -1894986606);
        c = md5_ii(c, d, a, b, x[i+10], 15, -1051523);
        b = md5_ii(b, c, d, a, x[i+ 1], 21, -2054922799);
        a = md5_ii(a, b, c, d, x[i+ 8], 6 ,  1873313359);
        d = md5_ii(d, a, b, c, x[i+15], 10, -30611744);
        c = md5_ii(c, d, a, b, x[i+ 6], 15, -1560198380);
        b = md5_ii(b, c, d, a, x[i+13], 21,  1309151649);
        a = md5_ii(a, b, c, d, x[i+ 4], 6 , -145523070);
        d = md5_ii(d, a, b, c, x[i+11], 10, -1120210379);
        c = md5_ii(c, d, a, b, x[i+ 2], 15,  718787259);
        b = md5_ii(b, c, d, a, x[i+ 9], 21, -343485551);

        a = safe_add(a, olda);
        b = safe_add(b, oldb);
        c = safe_add(c, oldc);
        d = safe_add(d, oldd);
    }
    return Array(a, b, c, d);

}

/*
 * These functions implement the four basic operations the algorithm uses.
 */
function md5_cmn(q, a, b, x, s, t)
{
    return safe_add(bit_rol(safe_add(safe_add(a, q), safe_add(x, t)), s),b);
}
function md5_ff(a, b, c, d, x, s, t)
{
    return md5_cmn((b & c) | ((~b) & d), a, b, x, s, t);
}
function md5_gg(a, b, c, d, x, s, t)
{
    return md5_cmn((b & d) | (c & (~d)), a, b, x, s, t);
}
function md5_hh(a, b, c, d, x, s, t)
{
    return md5_cmn(b ^ c ^ d, a, b, x, s, t);
}
function md5_ii(a, b, c, d, x, s, t)
{
    return md5_cmn(c ^ (b | (~d)), a, b, x, s, t);
}

/*
 * Calculate the HMAC-MD5, of a key and some data
 */
function core_hmac_md5(key, data)
{
    var bkey = str2binl(key);
    if(bkey.length > 16) bkey = core_md5(bkey, key.length * chrsz);

    var ipad = Array(16), opad = Array(16);
    for(var i = 0; i < 16; i++)
    {
        ipad[i] = bkey[i] ^ 0x36363636;
        opad[i] = bkey[i] ^ 0x5C5C5C5C;
    }

    var hash = core_md5(ipad.concat(str2binl(data)), 512 + data.length * chrsz);
    return core_md5(opad.concat(hash), 512 + 128);
}

/*
 * Add integers, wrapping at 2^32. This uses 16-bit operations internally
 * to work around bugs in some JS interpreters.
 */
function safe_add(x, y)
{
    var lsw = (x & 0xFFFF) + (y & 0xFFFF);
    var msw = (x >> 16) + (y >> 16) + (lsw >> 16);
    return (msw << 16) | (lsw & 0xFFFF);
}

/*
 * Bitwise rotate a 32-bit number to the left.
 */
function bit_rol(num, cnt)
{
    return (num << cnt) | (num >>> (32 - cnt));
}

/*
 * Convert a string to an array of little-endian words
 * If chrsz is ASCII, characters >255 have their hi-byte silently ignored.
 */
function str2binl(str)
{
    var bin = Array();
    var mask = (1 << chrsz) - 1;
    for(var i = 0; i < str.length * chrsz; i += chrsz)
        bin[i>>5] |= (str.charCodeAt(i / chrsz) & mask) << (i%32);
    return bin;
}

/*
 * Convert an array of little-endian words to a string
 */
function binl2str(bin)
{
    var str = "";
    var mask = (1 << chrsz) - 1;
    for(var i = 0; i < bin.length * 32; i += chrsz)
        str += String.fromCharCode((bin[i>>5] >>> (i % 32)) & mask);
    return str;
}

/*
 * Convert an array of little-endian words to a hex string.
 */
function binl2hex(binarray)
{
    var hex_tab = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
    var str = "";
    for(var i = 0; i < binarray.length * 4; i++)
    {
        str += hex_tab.charAt((binarray[i>>2] >> ((i%4)*8+4)) & 0xF) +
            hex_tab.charAt((binarray[i>>2] >> ((i%4)*8  )) & 0xF);
    }
    return str;
}

/*
 * Convert an array of little-endian words to a base-64 string
 */
function binl2b64(binarray)
{
    var tab = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    var str = "";
    for(var i = 0; i < binarray.length * 4; i += 3)
    {
        var triplet = (((binarray[i   >> 2] >> 8 * ( i   %4)) & 0xFF) << 16)
            | (((binarray[i+1 >> 2] >> 8 * ((i+1)%4)) & 0xFF) << 8 )
            |  ((binarray[i+2 >> 2] >> 8 * ((i+2)%4)) & 0xFF);
        for(var j = 0; j < 4; j++)
        {
            if(i * 8 + j * 6 > binarray.length * 32) str += b64pad;
            else str += tab.charAt((triplet >> 6*(3-j)) & 0x3F);
        }
    }
    return str;
}
