<template>
  <div class="jobSeeker_warp">
    <div class="menu">
      <div class="title">
        活跃人才
      </div>
    </div>

    <div class="content" id="jobSeekerList" v-loading="pageLoading">

      <div class="job-seeker-item" v-for="(item, index) in jobList"
           :key="'job-seeker-item' + index">

        <job-seeker-item
            :job-id="jobId"
            :job-name="jobName"
            :work-experience-years="item.workExperienceYears"
            :academic-level="item.academicLevel"
            :self-introduction="item.selfIntroduction"
            :expected-salary-range="item.expectedSalaryRange"
            :employee-id="item.employeeId"
            :gender="item.gender"
            :last-login-time="item.lastLoginTime"
            :job-searching-status="item.jobSearchingStatus"
            :age="item.age"
            :name="item.name"
            :avatar="item.avatar"
            :expected-job-address-list="item.expectedJobAddressList"
            :expected-job-list="item.expectedJobList"
            :personal-high-light-list="item.personalHighLightList"
            :school-name="item.school"
            :is-first-communication="!item.communicateFlag"
            :search-value="newSearchValue"
            @clickLeft="goJobSeekerDetailed"
            @clickRight="toChatWith"></job-seeker-item>

      </div>

      <div v-if="jobList.length <= 0" class="empty">
        空
      </div>

      <div class="pagination" v-if="jobList && jobList.length > 0">
        <el-pagination
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            :current-page="currentPage"
            :page-sizes="[10, 15, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>

      <buy-additional-communicate-time ref="buyAdditionalCommunicateTimeRef"
                                       :job-id="buyAdditionalCommunicateTimeJobId"
                                       :employee-id="buyAdditionalCommunicateTimeEmployeeId"
                                       @success="purchasedSuccessfully"
      ></buy-additional-communicate-time>

      <buy-search-additional-communicate-time ref="buySearchAdditionalCommunicateTimeRef"
                                              :job-id="buyAdditionalCommunicateTimeJobId"
                                              :employee-id="buyAdditionalCommunicateTimeEmployeeId"
                                              @success="purchasedSuccessfullySearchJob"></buy-search-additional-communicate-time>

    </div>

  </div>
</template>

<script>
import share from '@/share/share'
import {SearchEmployeeApi} from "@/request/api";
import JobSeekerItem from "@/components/jobSeekerItem/jobSeekerItem";
import {mapMutations} from "vuex";
import BuyAdditionalCommunicateTime from "@/components/buyAdditionalCommunicateTime/buyAdditionalCommunicateTime";
import BuySearchAdditionalCommunicateTime
  from "@/components/buySearchAdditionalCommunicateTime/buySearchAdditionalCommunicateTime";

export default {
  name: "jobSeeker",
  components: {BuyAdditionalCommunicateTime, BuySearchAdditionalCommunicateTime, JobSeekerItem},
  props: {
    jobFilter: {
      type: [Array, Object],
      default: () => []
    },
    searchValue: {
      type: String,
      default: ''
    },
    jobId: {
      type: [String, Number],
      default: ''
    },
    jobName: {
      type: String,
      default: ''
    },
    currentPositionInformation: {  // 当前hr代表的的岗位信息
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      pageLoading: false,
      activePageIndex: '1',
      jobList: [],
      pageSize: 10,
      currentPage: 1,
      total: 0,
      newJobId: '',
      address: '',
      buyAdditionalCommunicateTimeJobId: '',
      buyAdditionalCommunicateTimeSessionId: '',
      buyAdditionalCommunicateTimeEmployeeId: '',

      isFirstCommunication: true,  // 是否是第一次沟通
      jobSeekerItemCallBack: null,
      newSearchValue: '',
    }
  },
  watch: {
    jobId() {
      this.newJobId = this.jobId
    },
    // currentPage: {
    //   handler: function () {
    //     debugger
    //   },
    //   immediate: true
    // }
  },
  computed: {
    place() {
      return this.$store.state.userInfo.place
    },
    hrSelectArea() {
      return this.$store.state.hrSelectArea
    },
    areasOptions() {
      return this.$store.state.areasOptions
    },
    hrSelectCityCode() {
      return this.$store.state.hrSelectCityCode
    },

  },
  mounted() {
    // let loading = share.loadingShow({
    //   id: '#jobSeekerList',
    //   background: '#f4f2f3',
    // })
    // loading.close()
    let currentPage = sessionStorage.getItem('jobSeekerCurrentPage')
    if (currentPage) {
      this.currentPage =  parseInt(currentPage)
    }
    let pageSize = sessionStorage.getItem('jobSeekerPageSize')
    if (pageSize) {
      this.pageSize = parseInt(pageSize)
    }
    // this.loadData()
  },
  methods: {
    ...mapMutations(['setCurrentSessionId']),
    submit() {
      this.newSearchValue = this.searchValue
      sessionStorage.setItem('jobSeekerFilter', JSON.stringify(this.jobFilter))
      sessionStorage.setItem('jobSeekerSearchValue', this.searchValue)

      this.currentPage = 1

      this.$nextTick(() => {

        if (this.hrSelectArea && this.hrSelectArea.length > 0) {
          this.address = JSON.stringify(this.hrSelectArea)
        }else {
          let arr = []
          if (this.hrSelectCityCode) {
            arr.push(this.hrSelectCityCode)
          }
          this.address = JSON.stringify(arr)
        }
        this.loadData()
      })
    },
    async loadData() {
      sessionStorage.setItem('jobSeekerCurrentPage', this.currentPage)
      sessionStorage.setItem('jobSeekerPageSize', this.pageSize)

      this.pageLoading = true
      const res = await SearchEmployeeApi({
        searchInput: this.searchValue,  // 搜索内容
        // address: this.place.value,  // 地点
        address: this.address,  // 地点
        jobId: this.newJobId,  // 职位id
        salaryRange: JSON.stringify(this.jobFilter.salaryRangeList),  // 薪资范围
        academicRequirement: JSON.stringify(this.jobFilter.academicRequirement),  // 学历要求
        ageRange: JSON.stringify(this.jobFilter.ageRange),  // 年龄要求
        experienceRequirementList: JSON.stringify(this.jobFilter.experienceRequirementList),  // 经验要求
        jobSearchingStatus: JSON.stringify(this.jobFilter.jobSearchingStatus),  // 求职状态
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      })

      if (res) {
        this.jobList = res.employeeList
        this.total = res.numOfEmployees
      }
      this.pageLoading = false
    },
    switchPage() {
      this.loadData()
    },
    sizeChange(num) {
      this.pageSize = num
      this.loadData()
    },
    currentChange(num) {
      this.currentPage = num
      this.loadData()
    },
    goJobSeekerDetailed(employeeId, schoolName, recordId, jobId, jobName, coverWithMosaics) {

      if (this.jobId === '') {
        share.message.error('请先发布岗位才能查看求职者简历')
        return
      }
      this.$router.push({
        path: '/resumeDetails',
        query: {
          id: employeeId,
          jobId: this.jobId,
          jobName: this.jobName,
          schoolName: schoolName,
          coverWithMosaics: coverWithMosaics
        }
      })
    },
    toChatWith(toSessionId, jobId, jobName, employeeId, isFirstCommunication, coverWithMosaics, callBack) {
      if (this.jobId === '') {
        share.message.error('请先发布岗位才能去沟通')
        return
      }
      this.buyAdditionalCommunicateTimeJobId = jobId
      this.buyAdditionalCommunicateTimeSessionId = toSessionId
      this.buyAdditionalCommunicateTimeEmployeeId = employeeId
      this.isFirstCommunication = isFirstCommunication

      /**
       * 将列表项中的回调函数地址传给变量
       * */
      this.jobSeekerItemCallBack = callBack

      if (this.searchValue && this.isFirstCommunication) {
        this.$nextTick(() => {
          this.$refs.buySearchAdditionalCommunicateTimeRef.init(true)
        })
      }else {
        this.$nextTick(() => {
          this.$refs.buyAdditionalCommunicateTimeRef.init()
        })
      }

    },
    /**
     * 主动沟通购买成功（搜索）
     * */
    purchasedSuccessfullySearchJob() {
      let param = {
        jobId: this.buyAdditionalCommunicateTimeJobId,
        jobName: this.jobName,
        currHeaderMenu: 2
      }
      let notTaken = this.isFirstCommunication
      this.jobSeekerItemCallBack()
      share.toChatWindow(this.buyAdditionalCommunicateTimeSessionId, param, '/chatSectionHomeHr', notTaken, this.currentPositionInformation)
    },
    /**
     * 主动沟通购买成功（正常）
     * */
    purchasedSuccessfully() {
      let param = {
        jobId: this.buyAdditionalCommunicateTimeJobId,
        jobName: this.jobName,
        currHeaderMenu: 2
      }
      let notTaken = this.isFirstCommunication
      this.jobSeekerItemCallBack()
      share.toChatWindow(this.buyAdditionalCommunicateTimeSessionId, param, '/chatSectionHomeHr', notTaken, this.currentPositionInformation)
    },
  }
}
</script>

<style lang="less" scoped>
.jobSeeker_warp {
  width: 100%;
  height: 100%;
  padding-left: 70px;
  box-sizing: border-box;

  .menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .title {
      border-bottom: 3px solid #e20000;
    }

  }

  .content {
    height: 100%;
    width: 100%;
    min-height: 500px;

    .job-seeker-item {
      width: 100%;
      height: 140px;
      background-color: #ffffff;
      border-bottom: 1px solid #e1e1e1;
      margin: 10px 0;
    }

  }

  .pagination {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .empty {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    color: #e1e1e1;
  }

}
</style>
