<template>
  <div class="jobPromotion_warp">

    <div class="jobPromotion-left">

      <el-row :gutter="5">
        <el-col :span="12">
          <div class="jobPromotion-item jobPromotion-item1" @click="setMealDialogShow(1)" v-loading="getVipPackageTemplate">
            <div class="title">
              会员
            </div>
            <div class="desc">
              可同时展示多个岗位；赠送每日主动沟通次数。
            </div>
            <div class="content">
              <div class="content-item" v-for="(item2, index2) in vipPackageTemplateList" :key="'vipPackageTemplateList' + index2">
                <div class="text">
                  {{item2.price}}￥/{{item2.effectiveDays}}天/每日主动沟通{{item2.activeCommunicationTimesPerDay}}人
                </div>
                <div class="text">
                  可同时在线岗位{{item2.onlineJobNum}}个
                </div>
              </div>
            </div>
            <div class="message">
              注：点击本套餐选择不同额度的套餐进行购买，套餐购买后立即生效。
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="jobPromotion-item jobPromotion-item1" @click="setMealDialogShow(2)" v-loading="getDurationPackageTemplate">
            <div class="title">
              时长套餐
            </div>
            <div class="desc">
              单个岗位短期展示；赠送每日主动沟通次数。
            </div>
            <div class="content">
              <div class="content-item" v-for="(item2, index2) in durationPackageTemplateList" :key="'durationPackageTemplateList' + index2">
                <div class="text">
                  {{item2.price}}￥/{{item2.effectiveDays}}天/每日主动沟通{{item2.activeCommunicationTimesPerDay}}人
                </div>
              </div>
            </div>
            <div class="message">
              注：点击本套餐选择不同额度的套餐进行购买，套餐购买后立即生效。
            </div>
          </div>
        </el-col>
      </el-row>

    </div>

    <div class="jobPromotion-right">
      <account-card></account-card>
    </div>

    <all-package-purchase ref="allPackagePurchaseRef" :pay-immediately="true" @successfulPurchase="successfulPurchase"></all-package-purchase>

  </div>
</template>

<script>
import {formRules} from '@/share/formRules'
import {
  MyPublishedJobApi,
  GetCanInvoicingOrderApi,
  GetInvoicedRecordApi,
  ApplyVatNormalInvoiceApi,
  ApplyVatSpecialInvoiceApi,
  GetHrGetHrMoneyApi,
  GetMoneyChangeRecordApi,
  GetHrChangeSelfInformationApi,
  PostHrMoneyWithdrawalApi,
  GetProductGetHrVipTemplateListApi,
  GetDurationPackageTemplateListApi,
  HrGetHrInformationApi,
  PostHrBindDankCardNumApi, PostOrderApplyInvoiceApi,
} from "@/request/api";
import {mapMutations} from "vuex";
import share from '@/share/share'
import {HrGreetings} from '@/share/greetings'
import Ads from "@/components/ads/ads";
import AllPackagePurchase from "@/components/allPackagePurchase/allPackagePurchase";
import {ALL_RULES} from "@/config/baseURL";
import {AllAgreement} from "@/share/shareData";
import ApplicationOfMakeOutAnInvoice from "@/components/applicationOfMakeOutAnInvoice/applicationOfMakeOutAnInvoice";
import {get} from "@/request/http";
import banksName from "@/share/banksName";
import GetCode from "@/components/getCode/getCode";
import AccountCard from "@/components/accountCard/accountCard";

export default {
  name: "jobPromotion",
  components: {AccountCard, GetCode, ApplicationOfMakeOutAnInvoice, AllPackagePurchase, Ads},
  data() {
    return {
      AllAgreement: AllAgreement,

      formRules: formRules,

      vipPackageTemplateList: [],
      getVipPackageTemplate: true,

      durationPackageTemplateList: [],
      getDurationPackageTemplate: true,
    }
  },
  async mounted() {
    this.getVipPackageTemplateList()  // 获取会员套餐
    this.getDurationPackageTemplateList()  // 获取时长套餐
  },
  methods: {
    ...mapMutations(['setCurrentSessionId']),
    /**
    * 获取会员套餐
    * */
    async getVipPackageTemplateList() {
      this.getVipPackageTemplate = true
      const res = await GetProductGetHrVipTemplateListApi()
      if (res) {
        this.vipPackageTemplateList = res.templateList
      }
      this.getVipPackageTemplate = false
    },
    /**
     * 获取时长套餐
     * */
    async getDurationPackageTemplateList() {
      this.getDurationPackageTemplate = true
      const res = await GetDurationPackageTemplateListApi()
      if (res) {
        this.durationPackageTemplateList = res.templateList
      }
      this.getDurationPackageTemplate = false
    },

    setMealDialogShow(id) {
      if (id === 1) {
        this.$refs.allPackagePurchaseRef.showPayVipPackageDialog()
      }
      if (id === 2) {
        this.$router.push('/postJob')
      }
    },
    /**
    * 购买成功
    * */
    successfulPurchase(res) {
    },

    /**
     * 去协议详情页
     * */
    toRulesPage(param) {
      window.open(ALL_RULES + '?type=' + param)
    },

  }
}
</script>

<style lang="less" scoped>
.jobPromotion_warp {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .jobPromotion-left {
    width: 750px;
    min-height: 100%;
    padding: 20px 20px;
    box-sizing: border-box;
    background-color: #f4f2f3;

    & /deep/ .el-row {

      .el-col {

        &:first-child {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }

      .el-col {
        height: 100%;

        &:last-child {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }

    }

    .jobPromotion-item {
      width: 300px;
      height: 300px;
      margin: 10px;
      background-color: #ffffff;
      border-radius: 20px;
      border: 2px solid #e20000;
      padding: 20px 20px;
      cursor: pointer;
      box-sizing: border-box;

      .title {
        font-size: 16px;
        font-weight: bold;
        color: #ff4200;
      }

      .desc {
        font-size: 12px;
        color: #e41414;
      }

      .content {
        color: #3b3b3b;

        .content-item {
          font-size: 14px;
          margin: 15px 0;
          font-weight: bold;
        }
      }

      .message {
        font-size: 12px;
        color: #5c5c5c;
      }

    }

    .jobPromotion-item1 {
      background-image: url("../../assets/icon/jobPromotion-1.png");
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: 60% 60%;
    }

    .jobPromotion-item2 {
      background-image: url("../../assets/icon/jobPromotion-2.png");
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: 60% 60%;
    }

    .jobPromotion-item3 {
      background-image: url("../../assets/icon/jobPromotion-3.png");
      background-repeat: no-repeat;
      background-size: 60% 60%;
      background-position: bottom right;
    }

  }

  .jobPromotion-right {
    flex: 1;
    //min-height: 300px;
    background-color: #ffffff;

    .title {
      font-size: 16px;
      color: #323232;
      height: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 15px;

      .icon-left {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        margin-left: 20px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .amount {
      width: 100%;
      height: 130px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 10px;
      box-sizing: border-box;
      border-bottom: 1px solid #f2f2f2;


      .amount-top {
        font-size: 30px;
        font-weight: bold;
        color: #3b3b3b;
      }

      .btn {
        margin: 5px 0 10px 0;

        .el-button {
          padding: 8px 20px;
          color: #e20000;
          border: 1px solid #e20000;
        }
      }

      .amount-bottom {
        width: 100%;
        text-align: right;
        font-size: 14px;
        color: #6b6a6a;
        padding: 0 20px;
        box-sizing: border-box;
        cursor: pointer;

        i {
          margin-left: 5px;
        }
      }
    }

    .action {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 10px 25px;
      box-sizing: border-box;
      font-size: 14px;
      color: #454444;

      .action-item {
        cursor: pointer;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        i {
          margin-left: 5px;
        }

      }
    }

  }

}
</style>
<style lang="less">
.invoicingDialog_warp {
  width: 100%;

  .pagination {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.set-meal-dialog-wrap {

  .el-dialog__body {
    padding: 0 20px;
  }

  .dialog-footer {
    padding-top: 20px;
    box-sizing: border-box;
  }

  .set-meal-dialog-content {
    width: 100%;
    height: 450px;

    .title {
      font-size: 18px;
      color: #e20000;
      height: 30px;
      margin-bottom: 10px;
      line-height: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .tag {
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        padding: 0px 10px;
        box-sizing: border-box;
        border-radius: 3px;
        background-color: #ffcbbf;
        margin-left: 20px;
      }
    }

    .set-meal-dialog-main {
      width: 100%;
      height: calc(100% - 30px);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        width: 500px;
        height: 100%;
        margin-right: 20px;

        .select-job {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        .content {
          border: 1px solid #b1b1b1;
          border-radius: 10px;
          padding: 0 10px 10px 10px;
          box-sizing: border-box;

          .table {

            .el-row {
              height: 100%;

              .el-col {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            .header {
              width: 100%;
              height: 30px;
              border-bottom: 1px solid #bfbfbf;
            }

            .row {
              height: 50px;
              color: #343434;

              .row-item-first {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              }

              span {
                font-weight: bold;
                color: #343434;
              }
            }
          }

        }

        .desc {
          margin-top: 10px;
          font-size: 12px;
          color: #969696;
        }

      }

      .right {
        flex: 1;
        height: 100%;
        background-color: #eeeeee;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 0 30px 30px 30px;
        box-sizing: border-box;

        > div {
          width: 100%;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #a9a9a9;
          font-size: 12px;
        }

        .payAble {
          margin-top: 15px;
          font-size: 14px;
          color: #5c5c5c;
          align-items: flex-end;
          padding-bottom: 2px;
          box-sizing: border-box;
          margin-bottom: 5px;
          border-bottom: 1px solid #ffffff;

          span {
            font-size: 24px;
            color: #e20000;
            margin-right: 5px;
          }
        }

        .desc {
          margin-top: 10px;
        }

        .order-number {
          color: #5c5c5c;
          margin-top: 10px;
          font-size: 12px;

          .num {
            color: #323232;
            font-size: 14px;
            font-weight: bold;
          }
        }
      }

    }

  }
}

.accountDetailsDialog-content {
  .pagination {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.fuel-bag-dialog-wrap {
  .el-dialog__body {
    padding: 0 20px;
  }

  .dialog-footer {
    padding-top: 20px;
    box-sizing: border-box;
  }

  .fuel-bag-dialog-content {
    width: 100%;
    height: 450px;

    .title {
      font-size: 18px;
      color: #e20000;
      height: 30px;
      margin-bottom: 10px;
      line-height: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .tag {
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        padding: 0px 10px;
        box-sizing: border-box;
        border-radius: 3px;
        background-color: #ffcbbf;
        margin-left: 20px;
      }
    }

    .fuel-bag-dialog-main {
      width: 100%;
      height: calc(100% - 30px);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        width: 500px;
        height: 100%;
        margin-right: 20px;

        .select-job {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        .content {
          border: 1px solid #b1b1b1;
          border-radius: 10px;
          padding: 0 10px 10px 10px;
          box-sizing: border-box;

          .table {

            .el-row {
              height: 100%;

              .el-col {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            .header {
              width: 100%;
              height: 30px;
              border-bottom: 1px solid #bfbfbf;
            }

            .row {
              height: 50px;
              color: #343434;

              .row-item-first {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              }

              span {
                font-weight: bold;
                color: #343434;
              }
            }
          }

        }

        .desc {
          margin-top: 10px;
          font-size: 12px;
          color: #969696;
        }

      }

      .right {
        flex: 1;
        height: 100%;
        background-color: #eeeeee;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 0 30px 30px 30px;
        box-sizing: border-box;

        > div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #a9a9a9;
          font-size: 12px;
        }

        .payAble {
          margin-top: 15px;
          font-size: 14px;
          color: #5c5c5c;
          align-items: flex-end;
          padding-bottom: 2px;
          box-sizing: border-box;
          margin-bottom: 5px;
          border-bottom: 1px solid #ffffff;

          span {
            font-size: 24px;
            color: #e20000;
            margin-right: 5px;
          }
        }

        .desc {
          margin-top: 10px;
        }

        .order-number {
          color: #5c5c5c;
          margin-top: 10px;
          font-size: 12px;

          .num {
            color: #323232;
            font-size: 14px;
            font-weight: bold;
          }
        }
      }

    }

  }
}

</style>
