import Vue from 'vue'
import VueRouter from 'vue-router'
import Register from '@/views/register/register'
import Search from '@/views/search/search'
import Login from '@/views/login/login'
import JobHuntingHome from '@/views/jobHunting/jobHuntingHome'
import JobHunting from '@/views/jobHunting/jobHunting'
import UnfoldJobHunting from '@/views/jobHunting/unfoldJobHunting'
import JobDetailed from '@/views/jobDetailed/jobDetailed'
import CompanyDetailed from '@/views/companyDetailed/companyDetailed'
import PersonalCenter from '@/views/personalCenter/personalCenter'
import PersonalInformation from '@/views/personalInformation/personalInformation'
import PersonalDynamic from '@/views/personalDynamic/personalDynamic'
import MyResume from '@/views/myResume/myResume'
import EditMyResume from '@/views/myResume/editMyResume'
import AccountManagement from '@/views/accountManagement/accountManagement'
import JobSeeker from '@/views/jobSeeker/jobSeeker'
import JobSeekerHome from '@/views/jobSeeker/jobSeekerHome'
import PointsCollection from '@/views/pointsCollection/pointsCollection'
import WealthWithdrawal from '@/views/wealthWithdrawal/wealthWithdrawal'
import ResumeDetails from '@/views/resumeDetails/resumeDetails'
import JobSeekerDetailed from '@/views/resumeDetails/jobSeekerDetailed'
import EnterpriseCenter from '@/views/enterpriseCenter/enterpriseCenter'
import EnterpriseInformation from '@/views/enterpriseInformation/enterpriseInformation'
import EditEnterpriseInformation from '@/views/enterpriseInformation/editEnterpriseInformation'
import SearchEnterprise from '@/views/enterpriseInformation/searchEnterprise'
import PostJob from '@/views/postJob/postJob'
import HotJobList from '@/views/hotJobList/hotJobList'
import RedEnvelopeJobList from '@/views/redEnvelopeJobList/redEnvelopeJobList'
import EnterpriseDynamic from '@/views/enterpriseDynamic/enterpriseDynamic'
import RecruitmentData from '@/views/recruitmentData/recruitmentData'
import JobPromotion from '@/views/jobPromotion/jobPromotion'
import ChatSectionHome from '@/views/chatSectionHome/chatSectionHome'
import ChatPage from '@/views/chatPage/chatPage'
import HrSeeJobDetailed from '@/views/hrSeeJobDetailed/hrSeeJobDetailed'
import ApplicationProcess from '@/views/applicationProcess/applicationProcess'
import HrManagement from '@/views/hrManagement/hrManagement'
import HrData from '@/views/hrData/hrData'
import Recharge from '@/views/recharge/recharge'
import NewJobPromotion from '@/views/newJobPromotion/newJobPromotion'
import PromotionManagement from '@/views/promotionManagement/promotionManagement'
import PromoteDetails from '@/views/promoteDetails/promoteDetails'
import HistoricalOperationRecords from '@/views/historicalOperationRecords/historicalOperationRecords'
import RedEnvelopeApplicationPage from '@/views/redEnvelopeApplicationPage/redEnvelopeApplicationPage'
import VersionNumber from '@/views/versionNumber/versionNumber'
import AdditionalInformation from '@/views/additionalInformation/additionalInformation'
import TemporaryCustomerService from '@/views/temporaryCustomerService/temporaryCustomerService'

import Router from 'vue-router'

Vue.use(VueRouter)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '*',
        redirect: '/login',  // 登录页
        meta: {
            requireAuth: '', // 不需要鉴权
            type: '*',
        }
    },
    {
        path: '/',
        name: 'search',  // 搜索页
        component: Search,
        meta: {
            requireAuth: '', // 不需要鉴权
            type: 'employee',
        }
    },
    {
        path: '/register',  // 注册
        name: 'register',
        component: Register,
        meta: {
            requireAuth: '', // 不需要鉴权
            type: '*',
        }
    },
    {
        path: '/login',  // 登录
        name: 'login',
        component: Login,
        meta: {
            requireAuth: '', // 不需要鉴权
            type: '*',
        }
    },
    {
        path: '/additionalInformation',  // 信息补充
        name: 'additionalInformation',
        component: AdditionalInformation,
        meta: {
            requireAuth: '',
            type: '*',
        },
    },
    {
        path: '/jobHuntingHome',  // 找工作页面
        name: 'jobHuntingHome',
        redirect: '/jobHunting',
        component: JobHuntingHome,
        meta: {
            requireAuth: '', // 不需要鉴权
            type: 'employee',
        },
        children: [
            {
                path: '/jobHunting',  // 红包岗位和普通岗位
                name: 'jobHunting',
                component: JobHunting,
                meta: {
                    requireAuth: '', // 不需要鉴权
                    type: 'employee',
                },
            },
            {
                path: '/unfoldJobHunting',  // 全部岗位
                name: 'unfoldJobHunting',
                component: UnfoldJobHunting,
                meta: {
                    requireAuth: '', // 不需要鉴权
                    type: 'employee',
                },
            }
        ]
    },
    {
        path: '/jobDetailed',  // 岗位详情
        name: 'jobDetailed',
        component: JobDetailed,
        meta: {
            requireAuth: '', // 不需要鉴权
            type: 'employee',
        },
    },
    {
        path: '/companyDetailed',  // 企业详情
        name: 'companyDetailed',
        component: CompanyDetailed,
        meta: {
            requireAuth: '', // 不需要鉴权
            type: 'employee',
        },
    },
    {
        path: '/chatPage',  // 聊天室
        name: 'chatPage',
        redirect: '/chatSectionHome',
        component: ChatPage,
        meta: {
            requireAuth: true,
            type: 'employee',
        },
        children: [
            {
                path: '/chatSectionHome',
                name: 'chatSectionHome',
                component: ChatSectionHome,
                meta: {
                    requireAuth: true,
                    type: 'employee',
                },
            }
        ]
    },
    {
        path: '/personalCenter',  // 个人中心
        name: 'personalCenter',
        redirect: '/personalInformation',
        component: PersonalCenter,
        meta: {
            requireAuth: true,  // 需要鉴权
            type: 'employee',
        },
        children: [
            {
                path: '/personalInformation',  // 账号设置
                name: 'personalInformation',
                component: PersonalInformation,
                meta: {
                    requireAuth: true,
                    type: 'employee',
                },
            },
            {
                path: '/myResume',  // 我的简历
                name: 'myResume',
                component: MyResume,
                meta: {
                    requireAuth: true,
                    type: 'employee',
                },
            },
            {
                path: '/editMyResume',  // 编辑我的简历
                name: 'editMyResume',
                component: EditMyResume,
                meta: {
                    requireAuth: true,
                    type: 'employee',
                },
            },
            {
                path: '/pointsCollection',  // 积分领取
                name: 'pointsCollection',
                component: PointsCollection,
                meta: {
                    requireAuth: true,
                    type: 'employee',
                },
            },
            {
                path: '/wealthWithdrawal',  // 财富提现
                name: 'wealthWithdrawal',
                component: WealthWithdrawal,
                meta: {
                    requireAuth: true,
                    type: 'employee',
                },
            },
            {
                path: '/personalDynamic',  // 我的动态
                name: 'personalDynamic',
                component: PersonalDynamic,
                meta: {
                    requireAuth: true,
                    type: 'employee',
                },
            },
            {
                path: '/accountManagement',  // 账户管理
                name: 'accountManagement',
                component: AccountManagement,
                meta: {
                    requireAuth: true,
                    type: 'employee',
                },
            },
            {
                path: '/redEnvelopeApplicationPage',  // 账户管理
                name: 'redEnvelopeApplicationPage',
                component: RedEnvelopeApplicationPage,
                meta: {
                    requireAuth: true,
                    type: 'employee',
                },
            },
        ]
    },
    {
        path: '/jobSeekerHome',  // 找人才
        name: 'jobSeekerHome',
        redirect: '/jobSeeker',
        component: JobSeekerHome,
        meta: {
            requireAuth: true,
            type: 'hr',
        },
        children: [
            {
                path: '/jobSeeker',  // 找人才
                name: 'jobSeeker',
                component: JobSeeker,
                meta: {
                    requireAuth: true,
                    type: 'hr',
                },
            },
        ]
    },
    {
        path: '/resumeDetails',  // 招聘者查看求职者简历
        name: 'resumeDetails',
        redirect: '/jobSeekerDetailed',
        component: ResumeDetails,
        meta: {
            requireAuth: true,
            type: 'hr',
        },
        children: [
            {
                path: '/jobSeekerDetailed',  // 求职者简历
                name: 'jobSeekerDetailed',
                component: JobSeekerDetailed,
                meta: {
                    requireAuth: true,
                    type: 'hr',
                },
            },
        ]
    },
    {
        path: '/enterpriseCenter',  // 个人中心
        name: 'enterpriseCenter',
        redirect: '/enterpriseInformation',
        component: EnterpriseCenter,
        meta: {
            requireAuth: true,
            type: 'hr',
        },
        children: [
            {
                path: '/chatSectionHomeHr',  // 聊天
                name: 'chatSectionHomeHr',
                component: ChatSectionHome,
                meta: {
                    requireAuth: true,
                    type: 'hr',
                },
            },
            {
                path: '/enterpriseInformation',  // 企业信息
                name: 'enterpriseInformation',
                component: EnterpriseInformation,
                meta: {
                    requireAuth: true,
                    type: 'hr',
                },
            },
            {
                path: '/enterpriseInformation',  // 加入企业时显示的企业信息页
                name: 'enterpriseInformation',
                component: EnterpriseInformation,
                meta: {
                    requireAuth: true,
                    type: 'hr',
                },
            },
            {
                path: '/editEnterpriseInformation',  // 编辑企业信息
                name: 'editEnterpriseInformation',
                component: EditEnterpriseInformation,
                meta: {
                    requireAuth: true,
                    type: 'hr',
                },
            },
            {
                path: '/searchEnterprise',  // 搜索企业
                name: 'searchEnterprise',
                component: SearchEnterprise,
                meta: {
                    requireAuth: true,
                    type: 'hr',
                },
            },
            {
                path: '/postJob',  // 发布岗位
                name: 'postJob',
                component: PostJob,
                meta: {
                    requireAuth: true,
                    type: 'hr',
                },
            },
            {
                path: '/hotJobList',  // 普通岗位仓库
                name: 'hotJobList',
                component: HotJobList,
                meta: {
                    requireAuth: true,
                    type: 'hr',
                },
            },
            {
                path: '/redEnvelopeJobList',  // 红包岗位仓库
                name: 'redEnvelopeJobList',
                component: RedEnvelopeJobList,
                meta: {
                    requireAuth: true,
                    type: 'hr',
                },
            },
            {
                path: '/enterpriseDynamic',  // hr动态
                name: 'enterpriseDynamic',
                component: EnterpriseDynamic,
                meta: {
                    requireAuth: true,
                    type: 'hr',
                },
            },
            {
                path: '/recruitmentData',  // 客服中心
                name: 'recruitmentData',
                component: RecruitmentData,
                meta: {
                    requireAuth: true,
                    type: 'hr',
                },
            },
            {
                path: '/jobPromotion',  // 岗位推广
                name: 'jobPromotion',
                component: JobPromotion,
                meta: {
                    requireAuth: true,
                    type: 'hr',
                },
            },
            {
                path: '/hrSeeJobDetailed',  // hr看岗位详情页
                name: 'hrSeeJobDetailed',
                component: HrSeeJobDetailed,
                meta: {
                    requireAuth: true,
                    type: 'hr',
                },
            },
            {
                path: '/applicationProcess',  // 红包流程详情
                name: 'applicationProcess',
                component: ApplicationProcess,
                meta: {
                    requireAuth: true,
                    type: 'hr',
                },
            },
            {
                path: '/hrManagement',  // hr管理
                name: 'hrManagement',
                component: HrManagement,
                meta: {
                    requireAuth: true,
                    type: 'hr',
                },
            },
            {
                path: '/hrData',  // hr修改个人信息
                name: 'hrData',
                component: HrData,
                meta: {
                    requireAuth: true,
                    type: 'hr',
                },
            },
            {
                path: '/recharge',  // 充值
                name: 'recharge',
                component: Recharge,
                meta: {
                    requireAuth: true,
                    type: 'hr',
                },
            },
            {
                path: '/newJobPromotion',  // 设置推广
                name: 'newJobPromotion',
                component: NewJobPromotion,
                meta: {
                    requireAuth: true,
                    type: 'hr',
                },
            },
            {
                path: '/promotionManagement',  // 推广管理
                name: 'promotionManagement',
                component: PromotionManagement,
                meta: {
                    requireAuth: true,
                    type: 'hr',
                },
            },
            {
                path: '/promoteDetails',  // 弃用的页面
                name: 'promoteDetails',
                component: PromoteDetails,
                meta: {
                    requireAuth: true,
                    type: 'hr',
                },
            },
            {
                path: '/historicalOperationRecords',  // 查看推广管理历史操作记录
                name: 'historicalOperationRecords',
                component: HistoricalOperationRecords,
                meta: {
                    requireAuth: true,
                    type: 'hr',
                },
            },
        ]
    },
    {
        path: '/temporaryCustomerService',  // 企业详情
        name: 'temporaryCustomerService',
        component: TemporaryCustomerService,
        meta: {
            requireAuth: '', // 不需要鉴权
            type: '',
        },
    },
    {
        path: '/v',
        name: 'versionNumber',  // 版本号
        component: VersionNumber,
        meta: {
            requireAuth: '', // 不需要鉴权
            type: '',
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
