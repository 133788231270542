/*
 *  Debounce: 在vue中使用的防抖函数
 */
export const Debounce = (fun, time) => {
    let timeout = null;
    return (v) => {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            fun(v);
        }, time);
    };
}
