import { Message } from 'element-ui'
import axios from 'axios'
import { BASE_URL } from '@/config/baseURL'
import QS from 'qs'; // 引入qs模块，用来序列化post类型的数据
import store from '../store/index'
axios.defaults.baseURL = BASE_URL

/* 请求响应超时时间 */
axios.defaults.timeout = 20000;

/* 设置post请求头 */
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';



// 请求拦截器
axios.interceptors.request.use(
config => {
    // 每次发送请求之前判断vuex中是否存在token
    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断

    const token = store.state.token;
    if (token) {
        config.headers.Authorization = token
    }
    // token && (config.headers.Authorization = token);
    return config;
},
error => {
    return Promise.error(error);
})

// 响应拦截器
axios.interceptors.response.use(
    response => {

        if ( response.data && response.data.code === 500) {
            // Message.error('服务器内部错误')
            Message({
                showClose: true,
                message: '服务器内部错误',
                type: 'error',
                duration: 1500,
            })
            return ''
        }
        if ( response.data && response.data.code === 403) {
            Message({
                showClose: true,
                message: '登录已过期',
                type: 'error',
                duration: 1500,
            })

            window.selfRouter('/login')

            return ''
        }
        if ( response.data && response.data.code === 410) {
            Message({
                showClose: true,
                message: '主动沟通次数已用完，请购买加油包',
                type: 'error',
                duration: 1500,
            })

            window.selfRouter('/jobPromotion')

            return ''
        }
        /**
         * 实名认证错误专用的
         * */
        if ( response.data && response.data.code === 441) {
            return Promise.resolve(response.data)
        }
        /**
         * 绑定银行卡错误专用的
         * */
        if ( response.data && response.data.code === 440) {
            return Promise.resolve(response.data)
        }
        /**
         * hr发布岗位时没有加入或创建企业时的错误
         * */
        if ( response.data && response.data.code === 450) {
            return Promise.resolve(response.data)
        }

        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        if ( response.data && response.data.code === 200) {
            return Promise.resolve(response.data.data)
        }
        // if ( response.status && response.status === 403) {
        //
        //     Message.error(response.data.message)
        //     return ''
        // }
        // else if (response.status && response.status === 400) {
        //
        //     this.$message.error(response.data.message)
        //     return Promise.resolve(response.data)
        // }
        else {
            if (response && response.data && response.data.data && response.data.data.message) {
                Message({
                    showClose: true,
                    message: response.data.data.message,
                    type: 'error',
                    duration: 1500,
                })
                // Message.error(response.data.message)
                return ''
            }else {
                return response
            }

        }
    },
    error => {
        console.log(error)

        if (error.response) {
            Message({
                showClose: true,
                message: error.response.data.message,
                type: 'error',
                duration: 1500,
            })
            // Message.error(error.response.data.message)
        }

        // if (error.response.status) {
            // if (error.response.status === 400) {
            //     console.log('请求错误')
            // }
            // console.log('请求错误')
        // }
        return ''
    }
)

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params){
    return new Promise((resolve, reject) =>{
        axios.get(url, {
            params: params
        })
            .then(res => {
            resolve(res)
        }).catch(err =>{
            reject(err)
        })
    })
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, QS.stringify(params))
            .then(res => {
                resolve(res)
            })
            .catch(err =>{
                reject(err)
            })
    })
}
