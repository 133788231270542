<template>
  <div class="jobSeekerCard_warp">
    <div class="title" @click="clickTitle">
      <div class="text">{{title}}</div>
      <i class="el-icon-arrow-right"></i>
    </div>
    <job-seeker-card-item class="item" v-for="(item, index) in jobSeekerList" :key="'job-seeker-card-item' + index"
                          :employee-id="item.employeeInformation.employeeId"
                          :employee-name="item.employeeInformation.name"
                          :academic-level="item.employeeInformation.academicLevel"
                          :expected-category-list="item.employeeInformation.expectedJobList"
                          :expected-salary-range="item.employeeInformation.expectedSalaryRange"
                          :expected-job-address-list="item.employeeInformation.expectedJobAddressList"
                          :school-name="item.employeeInformation.schoolName"
                          @goJobDetails="clickItem"></job-seeker-card-item>
    <div v-if="jobSeekerList.length <= 0 " class="null">
      空
    </div>
  </div>
</template>

<script>
import JobSeekerCardItem from "@/components/jobSeekerCard/jobSeekerCardItem";
export default {
  name: "jobSeekerCard",
  components: {JobSeekerCardItem},
  props: {
    title: {
      type: String,
      default: ''
    },
    jobSeekerList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    clickTitle() {
      this.$emit('clickTitle')
    },
    clickItem(employeeId, school) {
      this.$emit('clickItem', employeeId, school)
    },
  }
}
</script>

<style lang="less" scoped>
.jobSeekerCard_warp {
  width: 100%;
  height: 100%;
  padding: 0px 20px 10px 20px;
  box-sizing: border-box;

  .title {
    height: 35px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 20px;
    cursor: pointer;

    .text {
      color: #454545;
      font-size: 14px;
      height: 35px;
      line-height: 35px;
      margin-left: 5px;
    }

  }

  .item {
    margin-bottom: 30px;
    cursor: pointer;
  }

  .null {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #C0C4CC;
    font-weight: bold;
  }

}
</style>
