<template>
  <div class="unfoldJobHunting_warp">
    <div class="menu">
      <el-tabs v-model="activePageIndex" @tab-click="switchPage">

        <el-tab-pane label="全部岗位" name="1"></el-tab-pane>

        <el-tab-pane label="仅显示红包岗位" name="2"></el-tab-pane>

        <!--        <el-tab-pane label="仅显示普通岗位" name="3"></el-tab-pane>-->

      </el-tabs>
    </div>

    <div class="content" id="unfold-job-hunting" v-loading="pageLoading">

      <div class="job-item" v-for="(item, index) in jobList"
           :key="'job-item' + index"
      >
        <job-item @clickLeft="openJobDetailed(item.jobInformation.jobId)"
                  @clickRight="openCompanyDetailed(item.enterpriseInformation.enterpriseId)"
                  @clickCenter="(toSessionId) => openContacts(toSessionId, item.jobInformation.jobId, item.jobInformation.jobName)"
                  :job-id="item.jobInformation.jobId"
                  :hr-id="item.hrInformation.hrId"
                  :job-type="item.jobInformation.jobType"
                  :red-pocket-per-person="item.jobInformation.redPocketPerPerson"
                  :job-name="item.jobInformation.jobName"
                  :jobAddress="item.jobInformation.jobAddress"
                  :salary-range="item.jobInformation.salaryRange"
                  :academic-requirement="item.jobInformation.academicRequirement"
                  :experience-requirement-range="item.jobInformation.experienceRequirementRange"
                  :job-welfare="item.jobInformation.jobWelfare"
                  :hr-name="item.enterpriseInformation.hrName"
                  :enterprise-name="item.enterpriseInformation.enterpriseName"
                  :industry="item.enterpriseInformation.industry"
                  :enterprise-size-range="item.enterpriseInformation.enterpriseSizeRange"
                  :financing-status="item.enterpriseInformation.financingStatus"
                  :enterprise-logo="item.enterpriseInformation.logo"
                  :online-status="item.hrInformation.onlineStatus"
        ></job-item>
      </div>

      <div v-if="jobList.length <= 0" class="empty">
        空
      </div>

      <div class="pagination" v-if="jobList && jobList.length > 0">
        <el-pagination
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            :current-page="currentPage"
            :page-sizes="[10, 15, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>

    </div>

    <el-dialog
        title="提示"
        :visible.sync="toMyResumeDialogVisible"
        width="350px">
      <div style="text-align: center">
        <p style="font-size: 20px; font-weight: bold; color: #3a3a3a;">请先去完善简历</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="toMyResumeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toMyResume">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import JobItem from "@/components/jobItem/jobItem";
import share from '@/share/share'
import {PostEmployeeContactHrApi, SearchJobApi} from "@/request/api";
import {mapMutations} from "vuex";

export default {
  name: "unfoldJobHunting",
  components: {JobItem},
  props: {
    jobFilter: {
      type: [Array, Object],
      default: () => []
    },
    searchValue: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      pageLoading: true,
      activePageIndex: '1',
      jobList: [],
      pageSize: 10,
      currentPage: 1,
      total: 50,
      toMyResumeDialogVisible: false,
    }
  },
  computed: {
    isEditedResume() {
      return this.$store.state.isEditedResume || ''
    },
    place() {
      return this.$store.state.userInfo.place
    },
    isLogin() {
      let token = this.$store.state.token
      let state = false
      if (token && token !== '' && token !== null && token !== 'null') {
        state = true
      }
      return state
    },
  },
  mounted() {
    // let place = this.$store.state.userInfo.place
    // this.address = place[place.length - 1]

    // let loading = share.loadingShow({
    //   id: '#unfold-job-hunting',
    //   background: '#f4f2f3',
    // })
    // loading.close()

    let activePageIndex = sessionStorage.getItem('activePageIndex')
    if (activePageIndex) {
      this.activePageIndex = activePageIndex
    }else {
      this.activePageIndex = '1'
    }
    sessionStorage.setItem('activePageIndex', this.activePageIndex)

    let currentPage = sessionStorage.getItem('searchJobCurrentPage')
    if (currentPage) {
      this.currentPage =  parseInt(currentPage)
    }
    let pageSize = sessionStorage.getItem('searchJobPageSize')
    if (pageSize) {
      this.pageSize = parseInt(pageSize)
    }

    this.loadData()
  },
  methods: {
    ...mapMutations(['setCurrentSessionId']),
    submit() {
      sessionStorage.setItem('searchJobFilter', JSON.stringify(this.jobFilter))
      sessionStorage.setItem('searchJobSearchValue', this.searchValue)

      this.currentPage = 1
      this.loadData()
    },
    async loadData() {
      sessionStorage.setItem('searchJobCurrentPage', this.currentPage)
      sessionStorage.setItem('searchJobPageSize', this.pageSize)

      this.pageLoading = true
      let jobType = ''
      if (this.activePageIndex === '1') {
        jobType = [1, 0]
      } else if (this.activePageIndex === '2') {
        jobType = [1]
      } else if (this.activePageIndex === '3') {
        jobType = [0]
      }

      let address = ''
      if (this.jobFilter.address) {
        let jobFilterAddressArr = JSON.parse(this.jobFilter.address)

        address = this.jobFilter.address
        if (!jobFilterAddressArr || jobFilterAddressArr.length <= 0) {
          if (this.place.value) {
            let arr = []
            arr.push(this.place.value)
            address = JSON.stringify(arr)
          }
        }
      }else {
        if (this.place.value) {
          let arr = []
          arr.push(this.place.value)
          address = JSON.stringify(arr)
        }
      }

      const res = await SearchJobApi({
        jobType: JSON.stringify(jobType),  // 岗位类型
        searchInput: this.searchValue,  // 搜索内容
        address: address,  // 地点
        jobCategory: JSON.stringify(this.jobFilter.jobCategory),  // 岗位类别
        jobProperty: JSON.stringify(this.jobFilter.jobProperty),  // 岗位性质
        salaryRange: JSON.stringify(this.jobFilter.salaryRangeList),  // 薪资范围
        welfare: JSON.stringify(this.jobFilter.welfare),  // 福利待遇
        academicRequirement: JSON.stringify(this.jobFilter.academicRequirement),  // 学历要求
        experienceRequirement: JSON.stringify(this.jobFilter.experienceRequirementList),  // 经验要求
        enterpriseSize: JSON.stringify(this.jobFilter.enterpriseSize),  // 企业规模
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      })

      if (res.jobs) {
        this.jobList = res.jobs
        this.total = res.numOfJobs
      }

      this.pageLoading = false

    },
    switchPage() {
      this.currentPage = 1
      sessionStorage.setItem('activePageIndex', this.activePageIndex)
      this.loadData()
    },
    sizeChange(num) {
      this.pageSize = num
      this.loadData()
    },
    currentChange(num) {
      this.currentPage = num
      this.loadData()
    },
    openJobDetailed(id) {
      this.$router.push({
        path: '/jobDetailed',
        query: {
          id: id
        }
      })
    },
    openCompanyDetailed(id) {
      this.$router.push({
        path: '/companyDetailed',
        query: {
          id: id
        }
      })
    },
    toMyResume() {
      this.$router.push('/myResume')
    },
    openContacts(toSessionId, jobId, jobName) {

      if (!this.isLogin) {
        share.message.warning('请先去登录')
        return
      }

      if (!this.isEditedResume) {
        this.toMyResumeDialogVisible = true
        return
      }

      PostEmployeeContactHrApi({
        jobId: jobId
      })

      share.toChatWindow(toSessionId, {jobId: jobId, jobName: jobName}, '/chatSectionHome')
    }
  }
}
</script>

<style lang="less" scoped>
.unfoldJobHunting_warp {
  width: 100%;
  //height: 100%;
  padding-left: 70px;
  box-sizing: border-box;

  .menu {

    & /deep/ .el-tabs__active-bar {
      background-color: #e20000;
    }

    & /deep/ .el-tabs__item.is-active {
      color: #e20000;
    }

  }

  .content {
    width: 100%;
    min-height: 500px;

    .job-item {
      width: 100%;
      height: 120px;
      background-color: #ffffff;
      border-bottom: 1px solid #e1e1e1;
    }

  }

  .pagination {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .empty {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    color: #e1e1e1;
  }

}
</style>
