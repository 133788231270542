<template>
  <div class="personalCenter_warp">

    <div class="header">
<!--      <page-header :curr-page="currHeaderMenu" @actionClick="actionClick" @changeCurrPage="changeCurrPage"></page-header>-->
      <page-header :curr-page="currHeaderMenu" @changeCurrPage="changeCurrPage"></page-header>
    </div>

    <div class="content">

      <div class="main">

        <div class="left">

          <div class="icon">
            <img src="@/assets/icon/logo.png" />
          </div>

          <el-menu
              :default-active="leftNav"
              @select="leftMenuChange"
              background-color="#eeeeee"
              text-color="#303030"
              active-text-color="#303030"
          >

            <el-menu-item index="personalInformation">
              <img src="@/assets/icon/personal-center.png">
              <span slot="title">个人中心</span>
            </el-menu-item>

            <el-menu-item index="myResume">
              <img src="@/assets/icon/resume3.png">
              <span slot="title">我的简历</span>
            </el-menu-item>

            <el-menu-item index="pointsCollection">
              <img src="@/assets/icon/integral.png">
              <span slot="title">积分领取</span>
            </el-menu-item>

            <el-menu-item index="wealthWithdrawal">
              <img src="@/assets/icon/withdrawal.png">
              <span slot="title">财富提现</span>
            </el-menu-item>

            <el-menu-item index="personalDynamic">
              <img src="@/assets/icon/dynamic.png">
              <span slot="title">我的动态</span>
            </el-menu-item>

            <el-menu-item index="accountManagement">
              <img src="@/assets/icon/account.png">
              <span slot="title">账户管理</span>
            </el-menu-item>

<!--            <el-menu-item index="7">-->
<!--              <img src="@/assets/icon/customer-service2.png">-->
<!--              <span slot="title">客服中心</span>-->
<!--            </el-menu-item>-->

          </el-menu>

        </div>

        <div class="right">
          <router-view></router-view>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import PageHeader from "@/components/pageHeader/pageHeader";

export default {
  name: "personalCenter",
  components: {PageHeader},
  data () {
    return {
      currHeaderMenu: 5,
      leftNav: '',
    }
  },
  computed: {
    path() {
      return this.$route.path
    }
  },
  mounted() {
    let currHeaderMenu = this.$route.query.currHeaderMenu || ''
    if (currHeaderMenu) {
      this.currHeaderMenu = parseInt(currHeaderMenu)
    }

    let enterpriseCenterLeftMenuIndex = sessionStorage.getItem('enterpriseCenterLeftMenuIndex')
    this.leftNav = enterpriseCenterLeftMenuIndex || ''
  },
  methods: {
    changeCurrPage(num) {
      this.currHeaderMenu = num
      if (num === 3) {
        this.leftNav = 'wealthWithdrawal'
      }
      if (num === 5) {
        this.leftNav = 'personalInformation'
      }
    },
    /* 点击头部按钮项时触发 */
    // actionClick(num, userState) {
    //   if (num === 1) {
    //     if (userState) {
    //     }else {
    //       this.$router.push('/login')
    //     }
    //   }
    // },
    leftMenuChange(path) {
      if (this.leftNav !== path) {
        if (path === 'wealthWithdrawal') {
          this.currHeaderMenu = 3
        } else {
          this.currHeaderMenu = 5
        }
        this.leftNav = path
        sessionStorage.setItem('personalCenterLeftMenuIndex', path)
        this.$router.push('/' + path)
      }
    }

  }
}
</script>

<style lang="less" scoped>
.personalCenter_warp {
  width: 100%;
  height: 100%;
  background-color: #f4f2f3;
  padding-bottom: 20px;
  //min-height: calc(100vh - 20px);
  min-width: 1400px;

  .header {
    width: 100%;
    height: 61px;
  }

  .content {
    width: 100%;
    margin-top: 50px;

    .main {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;
      display: flex;

      .left {
        width: 150px;
        height: 460px;
        padding-bottom: 10px;
        box-sizing: border-box;
        background-color: #eeeeee;
        z-index: 999;
        border-bottom-left-radius: 15px;
        border-top-left-radius: 15px;

        .icon {
          width: 100%;
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 20px 0 20px;
          box-sizing: border-box;

          img {
            max-height: 100%;
            max-width: 110px;
          }

        }

        & /deep/ .el-menu {
          border-right: 0px;

          .el-menu-item {
            border-left: 3px solid #eeeeee;
            height: 40px;
            line-height: 40px;
            margin: 10px 0;

            img {
              width: 30px;
              height: 30px;
              margin-right: 10px;
            }

            &:hover {
              border-left: 3px solid rgb(190, 190, 190);
            }

          }
          .el-menu-item.is-active {
            background-color: #ffffff !important;
            border-left: 3px solid #e20000;
          }
        }

      }

      .right {
        flex: 1;
        background-color: #ffffff;
      }

    }

  }

}
</style>
