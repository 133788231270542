<template>
  <div class="accountCard_warp">
    <div class="title">
      <div class="icon-left">
        <img src="@/assets/icon/wallet.png">
      </div>
      <div class="text">
        账户余额
      </div>
      <div class="icon-right">

      </div>
    </div>

    <div class="amount" v-loading="moneyLoading">
      <div class="amount-top">
        {{money}}
      </div>
      <div class="btn">
        <el-button @click="toWithdraw">提 现</el-button>
        <el-button @click="toRecharge">充 值</el-button>
      </div>
      <div class="amount-bottom" @click="accountDetailsDialogShow">
        查看账单明细<i class="el-icon-arrow-right"></i>
      </div>
    </div>

    <div class="action">
      <div class="action-item" @click="invoicingDialogShow(1)">
        开增值税普通发票<i class="el-icon-arrow-right"></i>
      </div>
      <div class="action-item" @click="invoicingDialogShow(2)">
        开增值税专用发票<i class="el-icon-arrow-right"></i>
      </div>
    </div>

    <!-- 账户明细 -->
    <el-dialog
        title="账户明细"
        :visible.sync="accountDetailsDialog"
        width="800px"
    >
      <div class="accountDetailsDialog-content" v-loading="accountDetailsLoading">
        <el-table
            :data="accountDetailsTableData"
            style="width: 100%"
        >
          <el-table-column
              prop="record_id"
              label="记录编号"
          >
          </el-table-column>
          <el-table-column
              prop="action"
              label="操作"
          >
          </el-table-column>
          <el-table-column
              prop="money"
              label="金额"
          >
          </el-table-column>
          <el-table-column
              prop="createTime"
              label="时间"
          >
          </el-table-column>
        </el-table>
        <div class="pagination" v-if="accountDetailsTableData.length > 0">
          <el-pagination
              background
              @current-change="accountDetailsTableCurrentChange"
              :current-page="accountDetailsTableCurrentPage"
              :page-size="10"
              layout="total, prev, pager, next, jumper"
              :total="accountDetailsTableTotal">
          </el-pagination>
        </div>
      </div>
    </el-dialog>

    <!--  提现弹窗  -->
    <el-dialog
        :visible.sync="withdrawalAmountModel"
        :before-close="withdrawalAmountModelBeforeClose"
        width="550px"
        custom-class="withdrawalAmountModel"
    >
      <div class="content">

        <div class="card">
          <div class="title">
            提现金额
          </div>
          <div class="main">
            <div class="desc">当前可提现<span>{{money}}</span>元，<span @click="withdrawalAmountAll">全部提现</span></div>
            <el-form ref="amountFormRef" :model="amountForm" :rules="formRules" label-width="20px">
              <el-form-item label="¥" prop="amountInput2">
                <el-input placeholder="请输入金额" v-model="amountForm.amountInput2" type="number" clearable></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <div class="card">
          <div class="title">
            请选择提现到
          </div>
          <div class="main">
            <div class="icons">
              <el-radio v-model="withdrawalAmountMode" label="3" border>
                <img class="icon" src="@/assets/icon/bankCard.png">
              </el-radio>
            </div>

            <div class="dialog_information">

              <div class="text" v-if="withdrawalAmountMode === '1'">
                到账支付宝：
              </div>
              <div class="detailed" v-if="withdrawalAmountMode === '1'">
                <div class="id">
                  <span v-if="accountManagementState.bindAlipay">支付宝昵称：</span>
                  <span v-if="!accountManagementState.bindAlipay">当前未绑定支付宝！</span>
                </div>
              </div>

              <div class="text" v-if="withdrawalAmountMode === '2'">
                到账微信：
              </div>
              <div class="detailed" v-if="withdrawalAmountMode === '2'">
                <div class="id">
                  <span v-if="accountManagementState.bindWechat">微信昵称：{{accountManagementState.bindWechat}}</span>
                  <span v-if="!accountManagementState.bindWechat">当前未绑定微信！</span>
                </div>
              </div>

              <div class="text" v-if="withdrawalAmountMode === '3'">
                到账银行卡：
              </div>
              <div class="detailed" v-if="withdrawalAmountMode === '3'">
                <div class="id">
                  <span v-if="accountManagementState.bankCardNum">银行卡号：{{bankCardStr}}</span>
                  <span v-if="!accountManagementState.bankCardNum">当前未绑定银行卡！</span>
                </div>
              </div>

              <div class="btn">
                <el-button :loading="withdrawalAmountLoading" @click="withdrawalAmountModelSubmit">{{amountModelSubmitBtnText}}</el-button>
              </div>
              <div class="protocol">
                您申请提现的金额将在2h内到账
              </div>
            </div>

          </div>
        </div>

      </div>
    </el-dialog>

    <!-- 开票记录 -->
    <el-dialog
        :title="invoicingDialogTitle"
        :visible.sync="invoicingDialog"
        custom-class="invoicingDialog_warp"
        width="800px"
    >
      <div class="invoicingDialog-content" v-loading="invoicingDialogLoading">

        <el-tabs v-model="invoicingDialogTab" @tab-click="invoicingDialogTabClick">
          <el-tab-pane label="可开票的购买记录" name="1"></el-tab-pane>
          <el-tab-pane label="开票记录" name="2"></el-tab-pane>
        </el-tabs>

        <el-table
            :data="invoicingTableData"
            style="width: 100%"
        >
          <el-table-column
              prop="createTime"
              label="时间"
              width="170"
          >
          </el-table-column>
          <el-table-column
              prop="orderNum"
              label="订单号"
          >
          </el-table-column>
          <el-table-column
              width="180"
              v-if="invoicingDialogTab === '2'"
              prop="waybillNumber"
              label="运单号"
          >
          </el-table-column>
          <el-table-column
              width="180"
              v-if="invoicingDialogTab === '2'"
              prop="check_taker_email"
              label="接收邮箱"
          >
          </el-table-column>
          <el-table-column
              v-if="invoicingDialogTab === '1'"
              prop="detail"
              label="详细说明"
          >
          </el-table-column>
          <el-table-column
              prop="amount"
              label="金额"
          >
          </el-table-column>

          <el-table-column
              label="操作"
              width="150"
              v-if="invoicingDialogTab === '1'"
          >
            <template v-if="invoicingDialogTab === '1'" slot-scope="scope">
              <el-button
                  size="mini"
                  @click="invoicingApply(scope)"
              >申请开票
              </el-button>
            </template>
          </el-table-column>

          <el-table-column
              prop="recordStatus"
              label="开票状态"
              width="100"
              v-if="invoicingDialogTab === '2'"
          >
          </el-table-column>

        </el-table>

        <div class="pagination">
          <el-pagination
              background
              @current-change="invoicingTableCurrentChange"
              :current-page="invoicingTableCurrentPage"
              :page-size="10"
              layout="total, prev, pager, next, jumper"
              :total="invoicingTableTotal">
          </el-pagination>
        </div>

      </div>
    </el-dialog>

    <!-- 申请开票 -->
    <application-of-make-out-an-invoice ref="applicationOfMakeOutAnInvoiceRef" :visible.sync="invoicingDetailedDialogVisible" @handleSubmit="invoicingDetailedDialogSubmit"></application-of-make-out-an-invoice>

    <!-- 绑定银行卡弹窗 -->
    <el-dialog
        title="绑定银行卡"
        :visible.sync="bankCardModelIsShow"
        width="600px"
        :before-close="bankCardModelClose"
        custom-class="accountManagement_dialog"
    >

      <div class="dialog-content">

        <div class="steps">
          <el-steps :active="bankCardModelActive" finish-status="success">
            <el-step title="第一步"></el-step>
            <el-step title="第二步"></el-step>
          </el-steps>
        </div>

        <div class="main" v-if="bankCardModelActive <= 1">
          <el-form
              :rules="formRules"
              ref="bankCardModelCodeRef"
              :model="bankCardModelCodeForm"
              key="bankCardModelFormCodeRule"
              label-width="80px"
          >
            <div class="form-item">
              <span class="msg">您当前绑定的手机号是{{currPhone}}</span>
              <el-form-item label="验证码" prop="msg_code">
                <el-input v-model="bankCardModelCodeForm.msg_code" clearable></el-input>
              </el-form-item>
              <div class="get-code">
                <div class="btn" @click="bankCardModelCodeFormGetCode">
                  <get-code ref="bankCardModelCodeFormGetCodeRef" :phone="accountManagementState.phone" :is-not-verify="true"></get-code>
                </div>
              </div>
            </div>
          </el-form>
        </div>

        <div class="main" v-else>
          <el-form
              :rules="formRules"
              ref="bankCardModelPhoneRef"
              :model="bankCardModelPhoneForm"
              key="bankCardModelFormPhoneRule"
              label-width="120px"
              style="flex-direction: column;"
          >
            <div class="form-item">
              <el-form-item label="持卡人姓名" prop="name">
                <el-input v-model="bankCardModelPhoneForm.name" clearable></el-input>
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item label="持卡人手机号" prop="phone">
                <el-input v-model="bankCardModelPhoneForm.phone" clearable></el-input>
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item label="银行卡号" prop="bankCardNum">
                <el-input v-model="bankCardModelPhoneForm.bankCardNum" clearable></el-input>
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item label="所属银行">
                <div>
                  {{bankBelong}}
                </div>
              </el-form-item>
            </div>
          </el-form>
        </div>

      </div>

      <span slot="footer">
        <el-button v-if="bankCardModelActive <= 1" @click="bankCardModelNext">下一步</el-button>
        <el-button v-else type="primary" :loading="bankCardModelLoading" @click="bankCardModelSubmit">完 成</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {
  GetCanInvoicingOrderApi, GetHrChangeSelfInformationApi,
  GetHrGetHrMoneyApi, GetInvoicedRecordApi,
  GetMoneyChangeRecordApi, HrGetHrInformationApi, PostHrBindDankCardNumApi,
  PostHrMoneyWithdrawalApi, PostOrderApplyInvoiceApi
} from "@/request/api";
import {formRules} from "@/share/formRules";
import share from "@/share/share";
import ApplicationOfMakeOutAnInvoice from "@/components/applicationOfMakeOutAnInvoice/applicationOfMakeOutAnInvoice";
import {get} from "@/request/http";
import banksName from "@/share/banksName";
import {Debounce} from "@/until/until";
import GetCode from "@/components/getCode/getCode";

export default {
  name: "accountCard",
  components: {GetCode, ApplicationOfMakeOutAnInvoice},
  data() {
    return {
      formRules: formRules,
      money: 0,
      moneyLoading: false,

      /*
      * 账户信息
      * */
      accountManagementState: {
        bindAlipay: '',
        bindWechat: '',
        bankCardNum: '',
        phone: '',
      },

      /*
      * 开票记录
      * */
      invoicingDialogType: '',
      invoicingDialogTitle: '',
      invoicingDialog: false,
      invoicingDialogLoading: false,
      invoicingTableData: [],

      invoicingDialogTab: '1',
      invoicingTableCurrentPage: 1,
      invoicingTableTotal: 0,
      invoicingDetailedDialogVisible: false,

      /*
      * 账单明细
      * */
      accountDetailsLoading: false,
      accountDetailsDialog: false,
      accountDetailsTableData: [],
      accountDetailsTableCurrentPage: 1,
      accountDetailsTableTotal: 0,

      /*
      * 提现窗口
      * */
      withdrawalAmountModel: false,
      amountForm: {
        amountInput2: '',
      },
      withdrawalAmountMode: '3',
      protocol: true,
      modelState: false,
      withdrawalAmountLoading: false,

      /*
      * 绑定银行卡弹窗
      * */
      bankCardModelIsShow: false,
      bankCardModelLoading: false,
      bankCardModelActive: 1,
      bankCardModelCodeForm: {
        msg_code: '',
      },
      bankCardModelPhoneForm: {
        name: '',
        bankCardNum: '',
        phone: ''
      },
      bankBelong: '',

    }
  },
  computed: {
    amountModelSubmitBtnText() {
      if (this.withdrawalAmountMode === '1') {
        if(this.accountManagementState.bindAlipay) {
          return '提 现'
        }else {
          return '去绑定'
        }
      }
      if (this.withdrawalAmountMode === '2') {
        if(this.accountManagementState.bindWechat) {
          return '提 现'
        }else {
          return '去绑定'
        }
      }
      if (this.withdrawalAmountMode === '3') {
        if(this.accountManagementState.bankCardNum) {
          return '提 现'
        }else {
          return '去绑定'
        }
      }
    },
    bankCardStr() {
      let str = ''
      if (this.accountManagementState.bankCardNum === '' || this.accountManagementState.bankCardNum === null) {
        str = '空'
      }else {
        let str1 = this.accountManagementState.bankCardNum.slice(0, 4)
        let str2 = this.accountManagementState.bankCardNum.slice(this.accountManagementState.bankCardNum.length - 4, this.accountManagementState.bankCardNum.length)
        str = '已绑定（' + str1 + '****' + str2 + '）'
      }
      return str
    },
    currPhone() {
      let str = ''
      if (this.accountManagementState.phone === '' || this.accountManagementState.phone === null) {
        str = '空'
      }else {
        let str1 = this.accountManagementState.phone.slice(0, 3)
        let str2 = this.accountManagementState.phone.slice(7, 11)
        str = str1 + '****' + str2
      }
      return str
    },
  },
  watch: {
    'amountForm.amountInput2': {
      handler: function () {
        if (this.amountForm.amountInput2) {
          let amountInput = this.amountForm.amountInput2.toString()
          if (amountInput.indexOf('.') !== -1) {
            let arr = amountInput.split('.')
            if (arr[1].length > 2) {
              arr[1] = arr[1].slice(0, 2)
              this.$nextTick(() => {
                this.amountForm.amountInput2 = arr[0] + '.' + arr[1]
              })
            }
          }
        }
      }
    },
    'bankCardModelPhoneForm.bankCardNum': {
      handler: function() {
        if (this.bankCardModelPhoneForm.bankCardNum.length > 5) {
          this.VueDebounce({vm: this})
        }
      },
    }
  },
  mounted() {
    this.getHrInformation()
    this.getHrGetHrMoney()
    this.getCanInvoicingOrder()
    this.GetMoneyChangeRecord()
  },
  methods: {
    VueDebounce: Debounce(({vm}) => {
      vm['loadCardInfo']()
    }, 500),
    /**
     * 获取hr信息
     * */
    async getHrInformation() {
      const res = await GetHrChangeSelfInformationApi()
      if (res) {
        this.accountManagementState.bankCardNum = res.hrInformation.bankCardNum || ''
        this.accountManagementState.phone = res.hrInformation.phone || ''
        this.hrId = res.hrInformation.hrId
        this.HrGetHrInformationApi()
      }
    },
    async HrGetHrInformationApi() {
      const res = await HrGetHrInformationApi({
        hrId: this.hrId,
      })

      if (res) {
        this.enterpriseName = res.enterpriseName
      }
    },
    /**
    * 获取余额
    * */
    async getHrGetHrMoney() {
      this.moneyLoading = true
      const res = await GetHrGetHrMoneyApi()

      if (res) {
        this.money = res.money
      }
      this.moneyLoading = false
    },
    /**
    * 获取开票记录
    * */
    async getCanInvoicingOrder() {
      this.invoicingDialogLoading = true
      let res = []
      if (this.invoicingDialogTab === '1') {
        res = await GetCanInvoicingOrderApi({
          pageNum: this.invoicingTableCurrentPage,
          pageSize: 10,
        })
      }else {
        res = await GetInvoicedRecordApi({
          pageNum: this.invoicingTableCurrentPage,
          pageSize: 10,
        })
      }
      this.invoicingDialogLoading = false

      if (res) {
        this.invoicingTableData = res.recordList
        this.invoicingTableTotal = res.NumOfRecords
      }
    },
    /**
     * 获取账单明细数据
     * */
    async GetMoneyChangeRecord() {
      this.accountDetailsLoading = true
      const res = await GetMoneyChangeRecordApi({
        pageNum: this.accountDetailsTableCurrentPage,
        pageSize: 10,
      })

      this.accountDetailsLoading = false
      if (res) {
        this.accountDetailsTableData = res.recordList
        this.accountDetailsTableTotal = res.numOfRecords
      }
    },
    /**
    * 开票记录弹窗展示
    * */
    invoicingDialogShow(id) {
      this.invoicingDialogType = id
      if (id === 1) {
        this.invoicingDialogTitle = '开增值税普通发票'
      } else {
        this.invoicingDialogTitle = '开增值税专用发票'
      }
      this.invoicingDialog = true
    },
    /**
     * 开票记录弹窗tab改变时触发
     * */
    invoicingDialogTabClick() {
      this.invoicingTableCurrentPage = 1
      this.getCanInvoicingOrder()
    },
    /**
    * 开票记录弹窗页码改变时触发
    * */
    invoicingTableCurrentChange(num) {
      this.invoicingTableCurrentPage = num
      this.getCanInvoicingOrder()
    },
    /**
    * 申请开票
    * */
    invoicingApply(row) {
      this.invoicingDialog = false
      let obj = row.row
      this.$refs.applicationOfMakeOutAnInvoiceRef.init({
        invoiceHeader: this.enterpriseName,  // 发票抬头
        applicationAmount: obj.amount,  // 申请金额
        invoiceType: this.invoicingDialogType,  // 发票类型； 1是增值税普通发票、2是增值税专用发票
      }, obj.orderNum)
      this.invoicingDetailedDialogVisible = true
    },
    /**
    * 开票申请提交时触发
    * */
    async invoicingDetailedDialogSubmit(params, callBack) {
      const res = await PostOrderApplyInvoiceApi(params)

      callBack()
      if (res) {
        share.message.success('申请提交成功')
      }
      await this.getCanInvoicingOrder()
    },
    /**
    * 展示账单明细
    * */
    accountDetailsDialogShow() {
      this.accountDetailsDialog = true
    },
    /**
    * 账单明细页面切换时触发
    * */
    accountDetailsTableCurrentChange(num) {
      this.accountDetailsTableCurrentPage = num
      this.GetMoneyChangeRecord()
    },
    /**
    * 展示提现窗口
    * */
    toWithdraw() {
      this.withdrawalAmountModel = true
    },
    /**
    * 提现窗口关闭时触发
    * */
    withdrawalAmountModelBeforeClose() {
      this.amountForm.amountInput2 = ''
      this.$refs.amountFormRef.resetFields()
      this.withdrawalAmountLoading = false
      this.withdrawalAmountModel = false
    },
    /**
    * 全部提现
    * */
    withdrawalAmountAll() {
      this.amountForm.amountInput2 = this.money
    },
    /**
    * 提现窗口提交时触发
    * */
    async withdrawalAmountModelSubmit() {
      if (this.withdrawalAmountLoading) {
        return
      }
      let state = true
      if (this.withdrawalAmountMode === '1') {
        if (this.accountManagementState.bindAlipay) {
          state = true
        }else {
          state = false
        }
      }
      if (this.withdrawalAmountMode === '2') {
        if (this.accountManagementState.bindWechat) {
          state = true
        }else {
          state = false
        }
      }
      if (this.withdrawalAmountMode === '3') {
        if (this.accountManagementState.bankCardNum) {
          state = true
        }else {
          state = false
        }
      }

      if (state) {
        this.$refs.amountFormRef.validate(async res => {
          if (res) {
            this.withdrawalAmount()
          }
        })
      }else {
        this.withdrawalAmountModelBeforeClose()
        this.bankCardModelIsShow = true
      }
    },
    /**
    * 提现申请
    * */
    async withdrawalAmount() {
      this.withdrawalAmountLoading = true
      let withdrawalAmount = this.amountForm.amountInput2
      const res2 = await PostHrMoneyWithdrawalApi({
        withdrawalAmount: withdrawalAmount,
      })
      if (res2) {
        this.amountForm.amountInput2 = ''
        this.$refs.amountFormRef.resetFields()
        this.withdrawalAmountLoading = false
        this.withdrawalAmountModel = false
        share.message.success('提现申请成功')
        this.getHrGetHrMoney()
        this.getCanInvoicingOrder()
        this.GetMoneyChangeRecord()
      }else {
        this.withdrawalAmountLoading = false
        this.withdrawalAmountModel = false
      }
    },
    /**
     * 展示充值窗口
     * */
    toRecharge() {
      this.$router.push({
        path: '/recharge',
      })
    },
    /**
    * 获取银行卡所属行信息
    * */
    async loadCardInfo() {
      let cardNo = this.bankCardModelPhoneForm.bankCardNum
      const res = await get(`https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?cardNo=${cardNo}&cardBinCheck=true`, '')
      if (res.data.validated) {
        let t = res.data.bank
        this.bankBelong = banksName[t]
      }else {
        this.bankBelong = ''
      }
    },
    /**
    * 绑定银行卡弹窗关闭时触发
    * */
    bankCardModelClose() {
      if (this.bankCardModelActive === 1) {
        this.$refs.bankCardModelCodeRef.resetFields()
      }else {
        this.$refs.bankCardModelPhoneRef.resetFields()
      }
      this.bankCardModelActive = 1
      this.bankCardModelCodeForm.msg_code = ''
      this.bankCardModelPhoneForm.name = ''
      this.bankCardModelPhoneForm.bankCardNum = ''
      this.bankCardModelPhoneForm.phone = ''
      this.bankBelong = ''
      this.bankCardModelIsShow = false
    },
    bankCardModelCodeFormGetCode() {
      this.$refs.bankCardModelCodeFormGetCodeRef.getCode()
    },
    bankCardModelNext() {
      this.$refs.bankCardModelCodeRef.validate(res => {
        if (res) {
          this.bankCardModelActive ++
        }
      })
    },
    bankCardModelSubmit() {
      this.$refs.bankCardModelPhoneRef.validate(async res => {
        if (res) {
          this.bankCardModelLoading = true

          const res2 = await PostHrBindDankCardNumApi({
            name: this.bankCardModelPhoneForm.name,
            bankCardNum: this.bankCardModelPhoneForm.bankCardNum,
            msgCode: this.bankCardModelCodeForm.msg_code,
            phone: this.bankCardModelPhoneForm.phone
          })
          if (res2.code && res2.code === 440) {
            share.message.error(res2.data.message)
          } else {
            share.message.success('恭喜你，绑定银行卡成功！')
          }
          await this.getHrInformation()
          this.bankCardModelClose()
          this.bankCardModelLoading = false
        }
      })
    },

  }
}
</script>

<style lang="less" scoped>
.accountCard_warp {
  width: 100%;
  background-color: #ffffff;

  .title {
    font-size: 16px;
    color: #323232;
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15px;

    .icon-left {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      margin-left: 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .amount {
    width: 100%;
    height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #f2f2f2;


    .amount-top {
      font-size: 30px;
      font-weight: bold;
      color: #3b3b3b;
    }

    .btn {
      margin: 5px 0 10px 0;

      .el-button {
        padding: 8px 20px;
        color: #e20000;
        border: 1px solid #e20000;
      }
    }

    .amount-bottom {
      width: 100%;
      text-align: right;
      font-size: 14px;
      color: #6b6a6a;
      padding: 0 20px;
      box-sizing: border-box;
      cursor: pointer;

      i {
        margin-left: 5px;
      }
    }
  }

  .action {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px 25px;
    box-sizing: border-box;
    font-size: 14px;
    color: #454444;

    .action-item {
      cursor: pointer;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      i {
        margin-left: 5px;
      }

    }
  }
}
</style>
<style lang="less">
/*
开票记录弹窗
*/
.invoicingDialog_warp {
  width: 100%;

  .pagination {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
/*
账单明细弹窗
*/
.accountDetailsDialog-content {
  .pagination {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
