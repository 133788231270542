<template>
  <div class="jobFilterItem_warp">
    <el-cascader
        v-model="newValue"
        @change="changeValue"
        :options="options.options"
        :show-all-levels="false"
        :placeholder="options.placeholder"
        popper-class="job_filter_item"
        :props="{
          expandTrigger: 'hover',
          multiple: options.multiple
        }"
        collapse-tags
        clearable
    ></el-cascader>
  </div>
</template>

<script>
export default {
  name: "jobFilterItem",
  props: {
    options: {
      type: [Array, Object],
      default: () => []
    },
    value: {
      type: [Array, Object, String],
      default: () => []
    }
  },
  data() {
    return {
      newValue: ''
    }
  },
  watch: {
    value() {
      this.newValue = this.value
    }
  },
  mounted() {
    this.newValue = this.value
  },
  methods: {
    changeValue() {

      let arr = []
      // if (this.options.multiple) {
        this.newValue.forEach((item, index) => {
          let obj = item[item.length - 1]
          arr.push(obj)
        })
      // }
      // else {
      //   let strArr = []
      //
      //   if (this.newValue && this.newValue.length > 0) {
      //     if (typeof this.newValue[0] === 'number') {
      //       arr.push(this.newValue[0])
      //     }else {
      //       strArr = this.newValue[0].split('-')
      //       arr.push(strArr[0])
      //       arr.push(strArr[1])
      //     }
      //
      //   }
      //
      // }
      this.$emit('jobFilterChange', arr, this.newValue, this.options.placeholder)

    }
  }
}
</script>

<style lang="less" scoped>
.jobFilterItem_warp {
  width: 100%;
  height: 100%;
  padding: 0px 10px;
  box-sizing: border-box;

  & /deep/ .el-cascader {
    width: 100%;

    .el-input__inner {
      border: none;
    }

    .el-input {
      input::-webkit-input-placeholder {
        color: #8a8a8a;
      }
    }
  }
}
</style>
<style lang="less">
/* 选择过滤条件组件样式 */
.job_filter_item {
  /* 选择区域组件展开时的高度最大为400px */

  .el-cascader-menu {
    height: 200px !important;
  }
}
</style>
