<template>
  <div class="enterpriseCenter_warp">

    <div class="header">
      <page-header :curr-page="currHeaderMenu" @actionClick="actionClick" @changeCurrPage="changeCurrPage"></page-header>
    </div>

    <div class="content">

      <div class="main">

        <div class="left" id="leftNavId">

          <div class="icon">
            <img src="@/assets/icon/logo.png" />
          </div>

          <el-menu
              :default-active="leftNav"
              @select="leftMenuChange"
              background-color="#26282f"
              text-color="#767778"
              active-text-color="#ffffff"
          >

            <el-menu-item index="chatSectionHomeHr">
              <img src="@/assets/icon/message-center.png">
              <span slot="title">消息中心</span>
            </el-menu-item>

            <el-menu-item index="enterpriseInformation">
              <img src="@/assets/icon/enterprise-information.png">
              <span slot="title">企业资料</span>
            </el-menu-item>

            <el-menu-item index="hrData">
              <img src="@/assets/icon/personal-information.png">
              <span slot="title">账号设置</span>
            </el-menu-item>

            <el-menu-item index="hrManagement" v-if="isAdmin">
              <img src="@/assets/icon/business-management.png">
              <span slot="title">HR管理</span>
            </el-menu-item>

            <el-menu-item index="postJob">
              <img src="@/assets/icon/post-job.png">
              <span slot="title">发布岗位</span>
            </el-menu-item>

            <el-menu-item index="hotJobList">
              <img src="@/assets/icon/popular.png">
              <span slot="title">普通岗位</span>
            </el-menu-item>

            <el-menu-item index="redEnvelopeJobList">
              <img src="@/assets/icon/red-envelope.png">
              <span slot="title">红包岗位</span>
            </el-menu-item>

            <el-menu-item index="enterpriseDynamic">
              <img src="@/assets/icon/enterprise-dynamic.png">
              <span slot="title">招聘动态</span>
            </el-menu-item>

<!--            <el-menu-item index="10">-->
<!--              <img src="@/assets/icon/customer-service2.png">-->
<!--              <span slot="title">客服中心</span>-->
<!--            </el-menu-item>-->

            <el-menu-item index="jobPromotion">
              <img src="@/assets/icon/promote.png">
              <span slot="title">会员套餐</span>
            </el-menu-item>

            <el-menu-item index="promotionManagement">
              <img src="@/assets/icon/promotion-management.png">
              <span slot="title">推广管理</span>
            </el-menu-item>

            <el-menu-item index="recruitmentData">
              <img src="@/assets/icon/recruitment-data.png">
              <span slot="title">招聘数据</span>
            </el-menu-item>

          </el-menu>

        </div>

        <div class="right">
          <router-view @changeHeaderMenu="changeHeaderMenu"></router-view>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import PageHeader from "@/components/pageHeader/pageHeader"
import {GetHrCheckJoinEnterpriseApplyApi, HrStatusApi} from '@/request/api'
import {mapMutations} from "vuex";
export default {
  name: "enterpriseCenter",
  components: {PageHeader},
  data () {
    return {
      currHeaderMenu: '',
      leftNav: '',
      enterpriseId: '',
      isAdmin: false,
    }
  },
  computed: {
    path() {
      return this.$route.path
    }
  },
  mounted() {
    let currHeaderMenu = this.$route.query.currHeaderMenu || ''
    if (currHeaderMenu) {
      this.currHeaderMenu = parseInt(currHeaderMenu)
    }

    let enterpriseCenterLeftMenuIndex = sessionStorage.getItem('enterpriseCenterLeftMenuIndex')
    this.leftNav = enterpriseCenterLeftMenuIndex || ''
    this.loadData()
  },
  methods: {
    ...mapMutations(['setHRCurrentRepresentative', 'setJoinUnreadCount']),

    async loadData() {
      const res = await HrStatusApi()

      if (res) {
        this.enterpriseId = res.enterpriseId
        this.isAdmin = res.isAdmin

        if (res.isAdmin) {
          const res2 = await GetHrCheckJoinEnterpriseApplyApi({
            pageNum: 1,
            pageSize: 10000
          })
          if (res2) {
            let hrApplyJoinList = res2.recordList
            let newJoin = []
            hrApplyJoinList.forEach((item, index) => {
              if (item.applyStatus === 1) {
                newJoin.push(item)
              }
            })
            this.setJoinUnreadCount({
              count: newJoin.length
            })
          }
        }

      }
    },
    changeCurrPage(num) {
      this.currHeaderMenu = num
      if (num === 2) {
        this.leftNav = 'chatSectionHomeHr'
      }
      if (num === 3) {
        this.leftNav = 'enterpriseInformation'
      }
    },
    /* 点击头部按钮项时触发 */
    actionClick(num, userState) {
      if (num === 1) {
        if (!userState) {
          this.$router.push('/login')
        }
      }
    },
    changeHeaderMenu(num) {
      this.currHeaderMenu = num
    },
    leftMenuChange(path) {
      if (this.leftNav !== path) {
        if (path === 'chatSectionHomeHr') {
          this.currHeaderMenu = 2
        } else {
          this.currHeaderMenu = 3
        }
        this.leftNav = path
        sessionStorage.setItem('enterpriseCenterLeftMenuIndex', path)
        this.$router.push('/' + path)
      }
    }

  }
}
</script>

<style lang="less" scoped>
.enterpriseCenter_warp {
  width: 100%;
  height: 100%;
  background-color: #f4f2f3;
  padding-bottom: 20px;
  //min-height: calc(100vh - 20px);
  min-width: 1400px;

  .header {
    width: 100%;
    height: 61px;
  }

  .content {
    width: 100%;
    //height: calc(100% - 61px);
    margin-top: 50px;

    .main {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      display: flex;

      .left {
        width: 150px;
        //min-height: 543px;
        max-height: 640px;
        padding-bottom: 10px;
        box-sizing: border-box;
        //background-color: #eeeeee;
        background-color: #202329;
        z-index: 999;
        border-bottom-left-radius: 15px;
        border-top-left-radius: 15px;

        .icon {
          width: 100%;
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 20px 0 20px;
          box-sizing: border-box;

          img {
            max-height: 100%;
            max-width: 110px;
          }

        }

        & /deep/ .el-menu {
          border-right: 0px;

          .el-menu-item {
            //border-left: 3px solid #eeeeee;
            height: 40px;
            line-height: 40px;
            margin: 10px 0;

            img {
              width: 30px;
              height: 30px;
              margin-right: 10px;
            }

            &:hover {
              //border-left: 3px solid rgb(190, 190, 190);
            }

          }
          .el-menu-item.is-active {
            //background-color: #ffffff !important;
            background-color: #37393f !important;
            //border-left: 3px solid #e20000;
          }
        }

      }

      .right {
        flex: 1;
        background-color: #ffffff;
      }

    }

  }

}
</style>
