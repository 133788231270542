<template>
  <div class="sliderRange_warp">
    <div class="content">
      <div class="left">
        {{newValue[0]}}岁
      </div>
      <div class="center">
        <el-slider
            v-model="newValue"
            range
            :min="min"
            :max="max"
            @input="sliderInput"
        >
        </el-slider>
      </div>
      <div class="right">
        {{newValue[1]}}岁
      </div>
    </div>
    <div class="action">
      <el-checkbox v-model="checked" @change="checkedChange">年龄不限</el-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  name: "sliderRange",
  props: {
    value: {
      type: String,
      default: '16-60'
    },
    max: {
      type: [String, Number],
      default: 60
    },
    min: {
      type: [String, Number],
      default: 16
    }
  },
  watch: {
    value: {
      handler: function () {
        if (this.value !== undefined && this.value !== null && this.value !== '') {
          if (this.value.indexOf('-') !== -1) {
            this.checked = false
            let arr = this.value.split('-')
            arr[0] = parseInt(arr[0])
            arr[1] = parseInt(arr[1])
            this.newValue = arr
          }else {
            this.checked = true
          }
        }
      },
      immediate: true
    },
  },
  data() {
    return {
      newValue: [0, 100],
      checked: false,
    }
  },
  methods: {
    checkedChange() {
      if (this.checked) {
        this.$emit('update:value', '0')
      }else {
        let str = this.newValue[0] + '-' + this.newValue[1]
        this.$emit('update:value', str)
      }
    },
    sliderInput(e) {
      if (this.newValue.length > 0) {
        this.checked = false
        let str = this.newValue[0] + '-' + this.newValue[1]
        this.$emit('update:value', str)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.sliderRange_warp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left,.right {
      width: 40px;
    }
    .center {
      margin: 0 15px;
      flex: 1;
    }
  }
  .action {
    width: 100px;
    text-align: right;
  }
}
</style>
