import areasData from './areas.json'
import cityAreasData from './city_areas.json'
import city_under_direct_jurisdiction from './city_under_direct_jurisdiction.json'
import industryData from './industry.json'

export const ak_web = 'fYeKvYAu9ZFmBcuIm7f1jh7oOKIC4eOD'  // 浏览器端
export const ak_server = 'gmPQKWm5zurfvt4VPxWpSxXX1tcn2a7p'  // 服务端

/* 所有地区 */
export const areas = areasData

/* 所有地区只到市 */
export const cityAreas = cityAreasData

/* 直辖市 */
export const cityUnderDirectJurisdiction = city_under_direct_jurisdiction

/* 求职状态 */
export const jobSearchingStatus = [
    {
        value: 1,
        label: '在校-暂不考虑',
    },
    {
        value: 2,
        label: '在校-正在找工作',
    },
    {
        value: 3,
        label: '离职-暂不考虑',
    },
    {
        value: 4,
        label: '离职-正在找工作',
    },
    {
        value: 5,
        label: '在职-暂不考虑',
    },
    {
        value: 6,
        label: '在职-正在找工作',
    }
]

/* 岗位行业 */
export const industry = industryData

export const enterpriseIndustry = [
    {
        value: '生活服务',
        label: '生活服务',
    },
    {
        value: '批发零售',
        label: '批发零售',
    },
    {
        value: '工业制造',
        label: '工业制造',
    },
    {
        value: '餐饮酒店',
        label: '批发零售',
    },
    {
        value: '信息科技服务',
        label: '信息科技服务',
    },
    {
        value: '仓储运输',
        label: '仓储运输',
    },
    {
        value: '地产/建筑',
        label: '地产/建筑',
    },
    {
        value: '其他',
        label: '其他',
    }
]

/* 岗位性质 */
export const jobType = [
    {
        value: '兼职',
        label: '兼职',
    }, {
        value: '全职',
        label: '全职',
    }, {
        value: '短期',
        label: '短期',
    }
]

/* 薪资范围 */
export const salaryRange = [
    {
        value: '0-3000',
        label: '3k以下',
    }, {
        value: '3000-5000',
        label: '3-5k',
    }, {
        value: '5000-8000',
        label: '5-8k',
    }, {
        value: '8000-12000',
        label: '8-12k',
    }, {
        value: '12000-20000',
        label: '12-20k',
    }, {
        value: '20000-1000000',
        label: '20以上',
    }
]

/* 福利待遇 */
export const fringeBenefits = [
    {
        value: '五险—金',
        label: '五险—金',
    }, {
        value: '包吃',
        label: '包吃',
    }, {
        value: '包住',
        label: '包住',
    }, {
        value: '年底双薪',
        label: '年底双薪',
    }, {
        value: '周末双休',
        label: '周末双休',
    }, {
        value: '交通补助',
        label: '交通补助',
    }, {
        value: '加班补助',
        label: '加班补助',
    }, {
        value: '饭补',
        label: '饭补',
    }, {
        value: '话补',
        label: '话补',
    }, {
        value: '房补',
        label: '房补',
    }, {
        value: '产量奖',
        label: '产量奖',
    }, {
        value: '工龄奖',
        label: '工龄奖',
    }
]

/* 学历 */
export const education = [
    {
        value: 10,
        label: '初中及以下',
    }, {
        value: 20,
        label: '技校/中专',
    }, {
        value: 30,
        label: '高中',
    }, {
        value: 40,
        label: '大专',
    }, {
        value: 50,
        label: '本科',
    }, {
        value: 60,
        label: '硕士',
    }, {
        value: 70,
        label: '博士',
    }
]

/* 学历 */
export const education2 = [
    {
        value: 1,
        label: '学历不限',
    }, {
        value: 10,
        label: '初中及以下',
    }, {
        value: 20,
        label: '技校/中专',
    }, {
        value: 30,
        label: '高中',
    }, {
        value: 40,
        label: '大专',
    }, {
        value: 50,
        label: '本科',
    }, {
        value: 60,
        label: '硕士',
    }, {
        value: 70,
        label: '博士',
    }
]

/* 年龄范围 */
export const ageRange = [
    {
        value: '16-100',
        label: '16岁以上',
    }, {
        value: '18-100',
        label: '18岁以上',
    }, {
        value: '0-45',
        label: '45岁以下',
    }, {
        value: '0-50',
        label: '50岁以下',
    }, {
        value: '16-45',
        label: '16-45岁',
    }, {
        value: '16-50',
        label: '16-50岁',
    }, {
        value: '18-45',
        label: '18-45岁',
    }, {
        value: '18-50',
        label: '18-50岁',
    }
]

/* 年龄范围 */
export const ageRange2 = [
    {
        value: '0',
        label: '年龄不限',
    }, {
        value: '16-100',
        label: '16岁以上',
    }, {
        value: '18-100',
        label: '18岁以上',
    }, {
        value: '0-45',
        label: '45岁以下',
    }, {
        value: '0-50',
        label: '50岁以下',
    }, {
        value: '16-45',
        label: '16-45岁',
    }, {
        value: '16-50',
        label: '16-50岁',
    }, {
        value: '18-45',
        label: '18-45岁',
    }, {
        value: '18-50',
        label: '18-50岁',
    }
]

/* 经验 */
export const experience = [
    {
        value: '0-1',
        label: '1年以下',
    }, {
        value: '1-2',
        label: '1-2年',
    }, {
        value: '3-5',
        label: '3-5年',
    }, {
        value: '6-7',
        label: '6-7年',
    }, {
        value: '8-10',
        label: '8-10年',
    }, {
        value: '10-100',
        label: '10年以上',
    }
]

/* 经验 */
export const experience2 = [
    {
        value: '0',
        label: '经验不限',
    },
    {
        value: '0-1',
        label: '1年以下',
    }, {
        value: '1-2',
        label: '1-2年',
    }, {
        value: '3-5',
        label: '3-5年',
    }, {
        value: '6-7',
        label: '6-7年',
    }, {
        value: '8-10',
        label: '8-10年',
    }, {
        value: '10-100',
        label: '10年以上',
    }
]

/* 企业规模 */
export const enterpriseScale = [
    {
        value: '0-20',
        label: '0-20人',
    }, {
        value: '20-50',
        label: '20-50人',
    }, {
        value: '50-100',
        label: '50-100人',
    }, {
        value: '100-500',
        label: '100-500人',
    }, {
        value: '500-1000',
        label: '500-1000人',
    }, {
        value: '1000-99999999',
        label: '1000人以上',
    }
]

/* 企业融资状态 */
export const financingSituation = [
    {
        value: 0,
        label: '不需要融资',
    },
    {
        value: 1,
        label: '种子轮',
    },
    {
        value: 2,
        label: '天使轮',
    }, {
        value: 3,
        label: 'A轮',
    }, {
        value: 4,
        label: 'B轮',
    }, {
        value: 5,
        label: 'C轮',
    }, {
        value: 6,
        label: 'D轮',
    }, {
        value: 7,
        label: 'E轮',
    }, {
        value: 8,
        label: '已上市',
    }
]

/* 企业性质 */
export const enterpriseNature = [
    {
        value: 1,
        label: '有限责任公司',
    },
    {
        value: 2,
        label: '股份有限公司',
    },
    {
        value: 3,
        label: '国有独资企业',
    },
    {
        value: 4,
        label: '个人独资企业',
    },
    {
        value: 5,
        label: '合伙企业',
    },
    {
        value: 6,
        label: '个体工商户',
    },
    {
        value: 7,
        label: '外商投资企业',
    },
    {
        value: 8,
        label: '私营企业',
    },
    {
        value: 9,
        label: '全民所有制企业',
    },
    {
        value: 10,
        label: '集体所有制企业',
    }
]

/* 发布的岗位过滤 */
export const filterJobStateOptions = [
    {
        value: '',
        label: '全部',
    },
    // {
    //     value: 1,
    //     label: '待支付',
    // },
    {
        value: 2,
        label: '审核中',
    },
    {
        value: 3,
        label: '展示中',
    },
    {
        value: 4,
        label: '未过审',
    },
    {
        value: 6,
        label: '已关闭',
    },
    {
        value: 5,
        label: '已过期',
    },
    // {
    //     value: 7,
    //     label: '开放的',
    // }
]

/* 举报类型列表 */
export const reportTypeOptions = [
    {
        value: '诈骗信息',
        label: '诈骗信息',
    },
    {
        value: '人身攻击',
        label: '人身攻击',
    },
    {
        value: '涉黄信息',
        label: '涉黄信息',
    },
    {
        value: '职位虚假',
        label: '职位虚假',
    },
    {
        value: '收取求职者费用',
        label: '收取求职者费用',
    },
    {
        value: '红包不符',
        label: '红包不符',
    }
]

/**
 * 系统消息指定的页与实际的枚举关系
 * */
export const enumeratePagePath = [
    {
        page: 'hrSeeJobDetails',  // 岗位过审/未过审
        path: '/hrSeeJobDetailed',
        param: {
            jobId: '',
        }
    },
    {
        page: 'enterpriseInformation',  // 企业过审/未过审
        path: '/enterpriseInformation',
        param: {
            id: '',
            currHeaderMenu: 3,
        }
    },
    {
        page: 'enterpriseInformation',  // hr加入企业通过/未通过
        path: '/enterpriseInformation',
        param: {
            enterpriseId: '',
            isJoin: '',  // 通过时为 false， 未通过时为 true
            currHeaderMenu: 3,
        }
    },
    {
        page: 'redEnvelopeCollectionDetails',  // 求职者查看红包申请详情
        path: '/redEnvelopeApplicationPage',
        param: {
            id: '',  // 岗位id
            recordId: '',  // 红包申请记录id
        }
    },
    {
        page: 'jobManagement',  // 岗位过期
        path: '',
        param: {
            jobType: '1',  // 1是红包岗位
        }
    }
]

/**
 * 所有协议
 * */
export const AllAgreement = {
    User_Agreement: '佳薪职聘用户协议',
    Privacy_Policy: '佳薪职聘隐私政策',
    Log_Off_Accounts_Agreement: '佳薪职聘注销协议',
    Invoicing_Agreement: '佳薪职聘开票说明',
    Service_Agreement: '佳薪职聘增值服务协议',
    Promotion_Description: '佳薪职聘岗位推广说明',
    Publish_Job_Agreement: '佳薪职聘岗位信息发布规则',
}
