import { render, staticRenderFns } from "./getCode.vue?vue&type=template&id=ee36ee90&scoped=true"
import script from "./getCode.vue?vue&type=script&lang=js"
export * from "./getCode.vue?vue&type=script&lang=js"
import style0 from "./getCode.vue?vue&type=style&index=0&id=ee36ee90&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee36ee90",
  null
  
)

export default component.exports