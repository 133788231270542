<template>
  <div class="lazySelect_warp">
    <el-select v-model="value" :disabled="disabled" :size="size" v-lazy-load="lazyOption" :placeholder="placeholder" @change="handleChange">
      <el-option
          v-for="item in options"
          :key="'select-option' + item.value"
          :label="item.label"
          :value="item.value">
      </el-option>
      <el-option
          v-if="autoComplete && temporaryItem"
          :label="label"
          :value="value">
      </el-option>
      <el-option
          v-if="!noMore"
          :disabled="true"
          label="加载中"
          value="加载中">
        <div style="color: #409EFF; text-align: center; font-size: 12px;">
          <span v-if="loading">加载中<i class="el-icon-loading"></i></span>
          <span v-if="!loading">加载更多</span>
        </div>
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "lazySelect",
  props: {
    options: {
      type: Array,
      default: () => []
    },
    newValue: {
      type: [Number, String],
      default: () => ''
    },
    label: {
      type: [Number, String],
      default: () => ''
    },
    noMore: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autoComplete: {  // 自动补全
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
    size: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      loading: false,
      lazyOption: {
        loadData: () => this.loadData(),
        distance: 20,
        scrollBody: '.el-scrollbar__wrap',
        callback: (fun) => {
          this.$once("hook:beforeDestroy", () => fun())
        },
      },
      value: '',
      temporaryItem: false,
    }
  },
  watch: {
    newValue: {
      handler: function () {
        this.value = this.newValue
        if (this.options.length > 0) {
          let state = false
          for (let i = 0, l = this.options.length; i < l; i++) {
            if (this.options[i].value === this.newValue) {
              state = true
            }
          }
          if (!state) {
            this.temporaryItem = true
          }else {
            this.temporaryItem = false
          }
        }
      },
      immediate: true
    },
    'options.length': {
      handler: function () {
        if (this.options.length > 0) {
          let state = false
          for (let i = 0, l = this.options.length; i < l - 1; i++) {
            if (this.options[i].value === this.value) {
              state = true
            }
          }
          if (!state) {
            this.temporaryItem = true
          }else {
            this.temporaryItem = false
          }
        }
      }
    }
    // value: {
    //   handler: function () {
    //     this.$emit('update:newValue', this.value)
    //   },
    // },
  },
  directives: {
    'lazy-load': {
      inserted: (el, binding) => {
        const { loadData, distance, scrollBody, callback } = binding.value;
        let scrollContainer = el;
        if (scrollBody) {
          scrollContainer = el.querySelector(scrollBody) || el;
        }
        // 滚动事件监听
        const scroll = () => {
          const scrollBottom = scrollContainer.scrollTop + scrollContainer.clientHeight;
          if (scrollContainer.scrollHeight - scrollBottom <= distance) {
            loadData();
          }
        };
        scrollContainer.addEventListener("scroll", scroll);
        // 回调时 返回事件销毁函数
        callback(() => {
          scrollContainer.removeEventListener("scroll", scroll);
        });
      },
    }
  },
  methods: {
    loadData() {
      if (this.loading) {
        return
      }
      this.loading = true
      this.$emit('loadData', () => {
        this.loading = false
      })
    },
    handleChange() {
      this.$emit('change', this.value)
    }
  }
}
</script>

<style lang="less" scoped>
.lazySelect_warp {
  display: inline-block;
}
</style>
