import share from "@/share/share";
/* 行业 */
export const formRules = {
    phone: [
        // 必填，自定义手机号验证
        {required: true, validator: share.phoneRules}
    ],
    password: [
        // 必填，错误信息，失去焦点时触发
        {required: true, message: '请输入密码'},
        { min: 6, message: '长度不低于6个字符'},
    ],
    msg_code: [
        // 必填，自定义验证码验证
        {required: true, message: '请输入验证码'}
    ],
    msgCode: [
        // 必填，自定义验证码验证
        {required: true, message: '请输入验证码'}
    ],
    name: [
        // 必填，错误信息，失去焦点时触发
        {required: true, message: '请输入名字'}
    ],
    bankCardNum: [
        {required: true, message: '请输入卡号'}
    ],
    gender: [
        // 必填，错误信息，失去焦点时触发
        { required: true, message: '请选择性别', trigger: 'change' }
    ],
    hrGender: [
        // 必填，错误信息，失去焦点时触发
        { required: true, message: '请选择性别', trigger: 'change' }
    ],
    birthday: [
        // 必填，错误信息，内容改变时触发
        { required: true, message: '请选择日期', trigger: 'change' }
    ],
    hrName: [
        // 必填，错误信息，失去焦点时触发
        { required: true, message: '请输入姓名' }
    ],
    oldPassword: [
        { required: true, message: '旧密码不能为空' }
    ],
    bankCardId: [
        { required: true, message: '银行卡号不能为空' }
    ],

    amountInput: [
        // 必填，错误信息，失去焦点时触发
        { validator: share.amountRules}
    ],
    amountInput2: [
        // 必填，错误信息，失去焦点时触发
        { validator: share.amountRules2}
    ],

    jobName: [
        { required: true, message: '岗位名称不能为空' }
    ],

    education: [
        { required: true, message: '学历不能为空' }
    ],

    jobNature: [
        { required: true, message: '岗位性质不能为空' }
    ],

    experience: [
        { required: true, message: '经验不能为空' }
    ],

    jobType: [
        { required: true, message: '岗位类别不能为空' }
    ],

    ageRange: [
        { required: true, message: '年龄不能为空' }
    ],

    redEnvelopeAmount: [
        { required: true, message: '红包金额不能为空' }
    ],

    peopleNumber: [
        { required: true, message: '需招人数不能为空' },
        { validator: share.peopleNumberRules}
    ],

    province: [
        { required: true, message: '省份不能为空' }
    ],

    city: [
        { required: true, message: '市不能为空' }
    ],

    area: [
        { required: true, message: '地区不能为空' }
    ],

    jobBenefits: [
        { required: true, message: '福利不能为空' }
    ],

    jobDescription: [
        { required: true, message: '岗位描述不能为空' },
        { max: 2000, message: '长度不能大于2000个字符'},
    ],

    jobSearchingStatus: [
        { required: true, message: '求职状态不能为空' }
    ],

    selfIntroduction: [
        { required: true, message: '自我介绍不能为空' }
    ],

    expectedSalary: [
        { required: true, message: '期望薪资不能为空' }
    ],

    expectedCategory: [
        { required: true, message: '职能不能为空' }
    ],

    jobAddress: [
        { required: true, message: '地点不能为空' }
    ],

    expectedProperty: [
        { required: true, message: '工作性质不能为空' }
    ],

    enterpriseName: [
        { required: true, message: '企业名不能为空' }
    ],

    enterpriseNature: [
        { required: true, message: '企业性质不能为空' }
    ],

    enterpriseSize: [
        { required: true, message: '企业规模不能为空' }
    ],

    industry: [
        { required: true, message: '企业行业不能为空' }
    ],

    establishmentDate: [
        { required: true, message: '企业成立日期不能为空' }
    ],

    startDate: [
        { required: true, message: '开始日期不能为空' }
    ],

    endDate: [
        { required: true, message: '结束日期不能为空' }
    ],

    description: [
        { required: true, message: '描述不能为空' }
    ],

    schoolName: [
        { required: true, message: '学校名不能为空' }
    ],

    academicLevel: [
        { required: true, message: '学历不能为空' }
    ],

    major: [
        { required: true, message: '专业不能为空' }
    ],

    getDate: [
        { required: true, message: '获取时间不能为空' }
    ],

    headerName: [
        { required: true, message: '抬头不能为空' }
    ],

    taxNumber: [
        { required: true, message: '单位税号不能为空' }
    ],

    receiveAddress: [
        { required: true, message: '地址不能为空' }
    ],

    mailbox: [
        { required: true, message: '邮箱不能为空' }
    ],

    detailAddress: [
        { required: true, message: '详细地址不能为空' }
    ],

    idCard: [
        { required: true, message: '身份证号不能为空' }
    ],

    jobFilter: [
        { required: true, message: '目标岗位不能为空' }
    ],

    value: [
        { required: true, message: '内容不能为空' }
    ],

    legalPerson: [
        { required: true, message: '法定代表人不能为空' }
    ],

    financingStatus: [
        { required: true, message: '企业融资状态不能为空' }
    ],

    time: [
        { required: true, message: '时间不能为空' }
    ],

    place: [
        { required: true, message: '地点不能为空' }
    ],

    selectJob: [
        { required: true, message: '请选择岗位' }
    ],

    promotionArea: [
        { required: true, message: '请选择地区' }
    ],

    promotionJobCategory: [
        { required: true, message: '请选择岗位类别' }
    ],

    promotionBudget: [
        // 必填，错误信息，失去焦点时触发
        { validator: share.amountRules3}
    ],

    terms: [
        { required: true, message: '请输入招呼语' }
    ],

    invoiceNature: [
        { required: true, message: '发票性质不能为空' }
    ],

    invoiceHeader: [
        { required: true, message: '发票抬头不能为空' }
    ],

    applicationAmount: [
        { required: true, message: '申请金额不能为空' }
    ],

    billingItems: [
        { required: true, message: '开票项目不能为空' }
    ],

    invoiceType: [
        { required: true, message: '发票类型不能为空' }
    ],

    taxID: [
        { required: true, message: '税号不能为空' }
    ],

    checkTakerEmail: [
        { required: true, message: '收票人邮箱不能为空' }
    ],

    checkTakerName: [
        { required: true, message: '收票人姓名不能为空' }
    ],

    checkTakerPhoneNumber: [
        { required: true, message: '收票人电话不能为空' }
    ],

    checkTakerAddress: [
        { required: true, message: '收票人地址不能为空' }
    ],

    registeredAddress: [
        { required: true, message: '注册地址不能为空' }
    ],

    registeredTelephone: [
        { required: true, message: '注册电话不能为空' }
    ],

    bankAccount: [
        { required: true, message: '银行账户不能为空' }
    ],

    bankOfDeposit: [
        { required: true, message: '开户行名称不能为空' }
    ],

}
