<template>
  <div class="hrSeeJobDetailed_warp">

    <div class="hrSeeJobDetailed-left">
      <div class="content" v-loading="pageLoading">

        <div class="header">
          <div class="header-left">
            <div class="title">
              {{jobInformation.jobName}}<span>{{salary}}</span>
            </div>
            <div class="information">
              <div v-if="jobInformation && jobInformation.jobAddress && jobInformation.jobAddress.length > 0">[{{jobInformation.jobAddress[0]}} · {{jobInformation.jobAddress[1]}} · {{jobInformation.jobAddress[2]}}]</div>
              <div>{{ageRequirement}}</div>
              <div>{{experienceRequirement}}</div>
              <div>{{jobInformation.academicRequirement}}</div>
            </div>
          </div>
          <div class="header-right">
            {{jobStatus}} · {{jobTypeStr}}
          </div>
        </div>

        <div class="main">

          <div class="card">
            <div class="title">
              岗位描述
            </div>
            <div class="card-content">
              <p>
                {{jobInformation.description}}
              </p>
            </div>
          </div>

          <div class="card">
            <div class="title">
              岗位福利
            </div>
            <div class="card-content">
              <div class="tag" v-if="jobInformation.jobWelfare">
                <div class="item" v-for="(item, index) in jobInformation.jobWelfare" :key="'jobWelfare' + index">
                  {{item}}
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="title">
              岗位地址
            </div>
            <div class="card-content">
              <p v-if="jobInformation.jobAddress" style="white-space: pre-wrap; text-indent: -4px;">
                {{jobInformation.jobAddress[0]}}{{jobInformation.jobAddress[1]}}{{jobInformation.jobAddress[2]}}{{jobInformation.detailAddress}}
              </p>
            </div>
          </div>

          <div class="card" v-if="jobType === 1">
            <div class="title">
              红包详情
            </div>
            <div class="card-content">
              <div class="table">
                <div class="table-header">
                  红包金额: {{tableData[0].redPocketPerPerson}}(元)
                </div>

                <el-table
                    :data="tableData"
                    border
                    style="width: 100%">
                  <el-table-column
                      prop="redPocketTotalAmount"
                      label="总金额 (元)"
                  >
                  </el-table-column>
                  <el-table-column
                      prop="redPocketUsedAmount"
                      label="已用金额 (元)"
                  >
                  </el-table-column>
                  <el-table-column
                      prop="redPocketRemainAmount"
                      label="剩余金额 (元)">
                  </el-table-column>
                  <el-table-column
                      prop="redPocketTotalNum"
                      label="总红包 (个)">
                  </el-table-column>
                  <el-table-column
                      prop="redPocketUsedNum"
                      label="已用 (个)">
                  </el-table-column>
                  <el-table-column
                      prop="redPocketRemainNum"
                      label="剩余 (个)">
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>

          <div class="footer">
            <el-button-group>
              <el-button @click="returnJobList">
                返 回
              </el-button>
              <el-button @click="editJob" v-if="!editingUnderReview && !isClose && (jobInformation.jobStatus === 3 || jobInformation.jobStatus === 4)">
                编 辑
              </el-button>
              <el-button @click="recheckJob" v-if="!editingUnderReview && !isClose && jobInformation.jobStatus === 4">
                重新审核
              </el-button>
              <el-button @click="pay" v-if="!editingUnderReview && !isClose && jobInformation.jobStatus === 1">
<!--                <span v-if="hrVipRemainJobNum <= 0">支 付</span>-->
<!--                <span v-if="hrVipRemainJobNum > 0">开 放</span>-->
                <span>支 付</span>
              </el-button>
              <el-button @click="renew" v-if="!editingUnderReview && !isClose && jobInformation.jobStatus === 5">
<!--                <span v-if="hrVipRemainJobNum <= 0">续 费</span>-->
<!--                <span v-if="hrVipRemainJobNum > 0">开 放</span>-->
<!--                <span>续 费</span>-->
                <span>开 放</span>
              </el-button>
              <el-button v-if="!editingUnderReview && !isClose && jobInformation.jobStatus === 3 && !isPromoting" :loading="payLoading" @click="closeJob">
                关 闭
              </el-button>

              <template v-if="isClose">
                <el-button v-if="!isPromoting && !haveValidDurationPackage" @click="delJob">
                  删 除
                </el-button>
              </template>
              <template v-if="!isClose">
                <el-button v-if="jobInformation.jobStatus !== 2 &&  jobInformation.jobStatus !== 3 && !isPromoting && !haveValidDurationPackage" @click="delJob">
                  删 除
                </el-button>
              </template>

              <el-button v-if="isPromoting" @click="toSetPromotingDetails">
                设置推广
              </el-button>
              <el-button v-if="!editingUnderReview && !isPromoting && !isClose && jobInformation.jobStatus === 3" @click="toPromotingDetails">
                推广
              </el-button>
              <el-button v-if="isClose" :loading="payLoading" @click="openJob">
                开 放
              </el-button>
              <el-button @click="upgradeDialog = true" v-if="!editingUnderReview && !isClose && jobInformation.jobType === 0 && jobInformation.jobStatus === 3">
                升级为红包岗位
              </el-button>
            </el-button-group>
          </div>

        </div>

      </div>
    </div>

    <div class="hrSeeJobDetailed-right">
      <dynamic-entry @goEnterpriseDynamic="changeCurrNavPage"></dynamic-entry>
    </div>

    <!--  普通岗位升级为红包岗位  -->
    <el-dialog
        title="升级为红包岗位"
        :visible.sync="upgradeDialog"
        width="800px"
        custom-class="upgrade-dialog-wrap"
        :before-close="upgradeDialogClose"
    >
      <div class="pay-dialog-content">

        <div class="form-warp">

          <el-form ref="upgradeDialogFormRef" :rules="formRules2" :model="upgradeDialogForm" label-width="80px" key="upgradeDialogForm">

            <el-form-item label="红包金额" prop="redEnvelopeAmount">

              <el-popover
                  width="400"
                  v-model="popoverIsShow"
                  trigger="click"
              >

                <div class="red-envelope-amount-popover-content" v-if="!isModify">
                  <div class="header">
                    <el-steps :space="200" :active="stagActive" finish-status="success" align-center>
                      <el-step title="分期选择"></el-step>
                      <el-step title="分期间隔"></el-step>
                      <el-step title="红包金额"></el-step>
                    </el-steps>
                  </div>
                  <div class="content" v-if="stagActive === 0">
                    <el-radio-group v-model="stageSelection">
                      <el-row :gutter="20">
                        <el-col :span="8">
                          <el-radio-button label="不分期"></el-radio-button>
                        </el-col>
                        <el-col :span="8">
                          <el-radio-button label="分2期"></el-radio-button>
                        </el-col>
                        <el-col :span="8">
                          <el-radio-button label="分3期"></el-radio-button>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="8">
                          <el-radio-button label="分4期"></el-radio-button>
                        </el-col>
                        <el-col :span="8">
                          <el-radio-button label="分5期"></el-radio-button>
                        </el-col>
                        <el-col :span="8">
                          <el-radio-button label="分6期"></el-radio-button>
                        </el-col>
                      </el-row>
                    </el-radio-group>
                  </div>

                  <div class="content" v-if="stagActive === 1">

                    <el-form ref="stagingIntervalDetailsFormRef" key="stagingIntervalDetailsForm2"
                             :model="stagingIntervalDetailsForm" :rules="formRules2" label-width="100px">
                      <el-form-item
                          v-for="(item3, index3) in stageSelectionNum"
                          :label="'第'+ (index3 + 1) + '期间隔'"
                          :key="'stagingIntervalDetailsForm' + index3"
                          :prop="'stagingInterval' + (parseInt(index3) + 1)"
                      >
                        <el-input type="number" v-model="stagingIntervalDetailsForm['stagingInterval' + (parseInt(index3) + 1)]">
                          <template slot="suffix">
                            <div
                                style="width: 100%;height: 100%; display: flex; align-items: center; justify-content: center">
                              天
                            </div>
                          </template>
                        </el-input>
                      </el-form-item>
                    </el-form>
                  </div>

                  <div class="content" v-if="stagActive === 2">
                    <el-form ref="installmentDetailsFormRef" key="installmentDetailsForm2"
                             :model="installmentDetailsForm" :rules="formRules2" label-width="100px">
                      <el-form-item
                          v-for="(item2, index2) in stageSelectionNum"
                          :label="'第'+ (index2 + 1) + '期金额'"
                          :key="'installmentDetailsForm' + index2"
                          :prop="'amount' + (parseInt(index2) + 1)"
                      >
                        <el-input type="number" v-model="installmentDetailsForm['amount' + (parseInt(index2) + 1)]" :min="100">
                          <template slot="prepend">¥</template>
                          <template slot="suffix">
                            <div
                                style="width: 100%;height: 100%; display: flex; align-items: center; justify-content: center">
                              元
                            </div>
                          </template>
                        </el-input>
                      </el-form-item>
                    </el-form>
                  </div>

                  <div class="btn">
                    <el-button v-if="stagActive > 0" @click="stagActive--">上一步</el-button>
                    <el-button v-if="stagActive < 2" @click="installmentDetailsFormNext">下一步</el-button>
                    <el-button v-if="stagActive === 2" @click="installmentDetailsFormSubmit">完 成
                    </el-button>
                  </div>
                </div>

                <div slot="reference">
                  <el-input :disabled="Boolean(isModify)" v-model="upgradeDialogForm.redEnvelopeAmount" readonly clearable placeholder="请输入">
                    <template slot="prepend">
                          <span class="RMB_class">
                            ¥
                          </span>
                    </template>
                    <template slot="append">
                          <span class="RMB_class">
                            元
                          </span>
                    </template>
                  </el-input>
                </div>
              </el-popover>


            </el-form-item>

            <el-form-item label="需招人数" prop="peopleNumber">
              <el-input :disabled="Boolean(isModify)" type="number" min="5" v-model="upgradeDialogForm.peopleNumber" placeholder="请输入"
                        clearable></el-input>
            </el-form-item>

            <el-form-item label="需付总额">
              <span style="font-size: 20px; color: #e20000;">{{totalRenewal}}</span>
            </el-form-item>

          </el-form>

        </div>

      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="upgradeDialogClose">取 消</el-button>
        <el-button type="primary" :loading="payLoading" @click="upgradeDialogSubmit">确定支付</el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="delJobDialogVisible"
        width="400px">
      <span>确定要删除此岗位？</span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="delJobDialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="delJobDialogBtnLoading" @click="delJobDialogSubmit">确 定</el-button>
      </div>
    </el-dialog>


    <all-package-purchase ref="allPackagePurchaseRef" @successfulPurchase="successfulPurchase"></all-package-purchase>

  </div>
</template>

<script>
import {
  CloseJobApi,
  JobHrSeeJobDetailApi,
  OpenJobApi,
  UpdateRedPocketJobApi, PostJobDeleteJobApi, PostJobPayJobApi,

} from '@/request/api'
import DynamicEntry from "@/components/dynamicEntry/dynamicEntry";
import {ageRange2, experience, filterJobStateOptions} from '@/share/shareData'
import {formRules} from "@/share/formRules";
import {redEnvelopeJobPay, hotMoveJobPay} from "@/share/copywriting";
import share from "@/share/share";
import AllPackagePurchase from "@/components/allPackagePurchase/allPackagePurchase";
import {Loading} from "element-ui";
export default {
  name: "hrSeeJobDetailed",
  components: {AllPackagePurchase, DynamicEntry},
  data() {
    return {
      redEnvelopeJobPay: redEnvelopeJobPay,
      hotMoveJobPay: hotMoveJobPay,
      pageLoading: true,
      jobId: '',
      isClose: false,
      jobInformation: {},
      jobType: '',
      tableData: [
        {
          redPocketPerPerson: '',  // 每人的红包金额（元）
          redPocketTotalAmount: '',  // 红包总金额（元）
          redPocketUsedAmount: '',  // 红包已用金额（元）
          redPocketRemainAmount: '',  // 红包剩余金额（元）
          redPocketTotalNum: '',  // 红包总个数（个）
          redPocketUsedNum: '',  // 红包已用个数（个）
          redPocketRemainNum: '',  // 剩余红包个数（个）
        }
      ],
      unitPricePerPerson: 0,  // 红包岗位每人份的红包金额
      peopleNumber: 0,  // 红包岗位支付时需招人数
      formRules: {
        peopleNumber: [
          {required: true, message: '请输入需招人数'},
        ]
      },

      payLoading: false,


      upgradeDialog: false,
      upgradeDialogForm: {
        peopleNumber: '',  // 需招人数
        redEnvelopeAmount: '',  // 红包金额
        numberOfInstallments: '',  // 红包分期数
        installmentInterval: [],  // 每期间隔
        redPocketPerInstallmentList: [],  // 每期红包
      },
      formRules2: {
        ...formRules,

        stagingInterval1: [
          {required: true, validator: this.stagingIntervalRules}
        ],
        stagingInterval2: [
          {required: true, validator: this.stagingIntervalRules}
        ],
        stagingInterval3: [
          {required: true, validator: this.stagingIntervalRules}
        ],
        stagingInterval4: [
          {required: true, validator: this.stagingIntervalRules}
        ],
        stagingInterval5: [
          {required: true, validator: this.stagingIntervalRules}
        ],
        stagingInterval6: [
          {required: true, validator: this.stagingIntervalRules}
        ],

        amount1: [
          {required: true, validator: this.amountRules}
        ],
        amount2: [
          {required: true, validator: this.amountRules}
        ],
        amount3: [
          {required: true, validator: this.amountRules}
        ],
        amount4: [
          {required: true, validator: this.amountRules}
        ],
        amount5: [
          {required: true, validator: this.amountRules}
        ],
        amount6: [
          {required: true, validator: this.amountRules}
        ],
      },
      popoverIsShow: false,
      isModify: false,
      stagActive: 0,
      stageSelection: '不分期',
      stagingIntervalDetailsForm: {
        stagingInterval1: '',
        stagingInterval2: '',
        stagingInterval3: '',
        stagingInterval4: '',
        stagingInterval5: '',
        stagingInterval6: '',
      },
      stageSelectionNum: 1,
      installmentDetailsForm: {
        amount1: '',
        amount2: '',
        amount3: '',
        amount4: '',
        amount5: '',
        amount6: '',
      },

      delJobDialogVisible: false,
      delJobDialogBtnLoading: false,
      editingUnderReview: false,

      isPromoting: false,  // 是否在推广中
      jobPromoteInfo: null,  // 推广信息
      haveValidDurationPackage: false,  // 是否有时长套餐

      openJobId: '',

      hrVipRemainJobNum: 0,  // vip套餐是否还有剩余可在线的岗位次数
    }
  },
  watch: {
    stageSelection() {
      if (this.stageSelection === '不分期') {
        this.stageSelectionNum = 1
      } else {
        let str = this.stageSelection.slice(1, this.stageSelection.length - 1)
        this.stageSelectionNum = parseInt(str)
      }
    },
  },
  mounted() {
    this.jobId = this.$route.query.id || ''
    this.loadData()
  },
  computed: {
    salary() {
      let str = ''
      if (this.jobInformation.salaryRange && this.jobInformation.salaryRange.length > 0) {
        // str = this.jobInformation.salaryRange[0] + '-' + this.jobInformation.salaryRange[1]
        str = parseInt(this.jobInformation.salaryRange[0])/1000 + 'k-' + parseInt(this.jobInformation.salaryRange[1])/1000 + 'k'
      }else {
        str = '面议'
      }
      return str
    },
    ageRequirement() {
      let str = ''
      if (this.jobInformation.ageRequirementRange && this.jobInformation.ageRequirementRange.length > 0) {
        // let str2 = this.jobInformation.ageRequirementRange[0] + '-' + this.jobInformation.ageRequirementRange[1]
        // ageRange2.forEach((item, index) => {
        //   if (item.value === str2) {
        //     str = item.label
        //   }
        // })

        str = this.jobInformation.ageRequirementRange[0] + '岁-' + this.jobInformation.ageRequirementRange[1] + '岁'
      }else {
        str = '年龄不限'
      }
      return str
    },
    experienceRequirement() {
      let str = ''
      if (this.jobInformation.experienceRequirementRange && this.jobInformation.experienceRequirementRange.length > 0) {
        let str2 = this.jobInformation.experienceRequirementRange[0] + '-' + this.jobInformation.experienceRequirementRange[1]

        experience.forEach((item, index) => {
          if (item.value === str2) {
            str = item.label + '经验'
          }
        })
      }else {
        str = '经验不限'
      }
      return str
    },
    jobTypeStr() {
      let str = ''
      if (this.jobInformation.jobType === 1) {
        str = '红包岗位'
      }else {
        str = '普通岗位'
      }
      return str
    },
    jobStatus() {
      let str = ''
      if (this.isClose) {
        str = '已关闭'
      }else {
        filterJobStateOptions.forEach((item, index) => {
          if (item.value === this.jobInformation.jobStatus) {
            str = item.label
          }
        })
      }
      return str
    },
    totalRenewal() {
      let allRedPocketPer = 0
      this.upgradeDialogForm.redPocketPerInstallmentList.forEach((item, index) => {
        allRedPocketPer += item
      })
      return this.upgradeDialogForm.peopleNumber * allRedPocketPer
    }
  },
  methods: {
    async loadData() {
      this.pageLoading = true
      const res = await JobHrSeeJobDetailApi({
        jobId: this.jobId
      })

      if (res) {
        this.haveValidDurationPackage = res.jobInformation.haveValidDurationPackage
        this.jobInformation = res.jobInformation
        this.jobType = res.jobInformation.jobType
        this.isClose = res.jobInformation.isClose
        this.editingUnderReview = res.jobInformation.editingUnderReview
        if (this.jobType === 1) {
          this.unitPricePerPerson = res.jobInformation.redPocketPerPerson
          this.peopleNumber = res.jobInformation.redPocketTotalNum

          this.tableData[0].redPocketPerPerson = res.jobInformation.redPocketPerPerson  // 每人的红包金额（元）
          this.tableData[0].redPocketTotalAmount = res.jobInformation.redPocketTotalAmount  // 红包总金额（元）
          this.tableData[0].redPocketUsedAmount = res.jobInformation.redPocketUsedAmount  // 红包已用金额（元）
          this.tableData[0].redPocketRemainAmount = res.jobInformation.redPocketRemainAmount  // 红包剩余金额（元）
          this.tableData[0].redPocketTotalNum = res.jobInformation.redPocketTotalNum  // 红包总个数（个）
          this.tableData[0].redPocketUsedNum = res.jobInformation.redPocketUsedNum  // 红包已用个数（个）
          this.tableData[0].redPocketRemainNum = res.jobInformation.redPocketRemainNum  // 剩余红包个数（个）
        }

        this.hrVipRemainJobNum = res.hrVipRemainJobNum
        if (res.jobInformation.jobPromoteInfo && res.jobInformation.jobPromoteInfo.id !== undefined && res.jobInformation.jobPromoteInfo.id !== '') {
          this.isPromoting = true  // 是否处在推广中
          this.jobPromoteInformation = res.jobInformation.jobPromoteInfo
        }
      }
      this.pageLoading = false
    },
    async successfulPurchase(obj) {
      this.pageLoading = true
      let params = {
        jobId: this.jobId
      }
      // 没有会员套餐
      if (obj.state === 0) {
        // 买的时长包
        if (obj.type === 1) {
          params = {
            ...params,
            durationPackageTemplateId: obj.templateId
          }
        }
        // 买的会员
        if (obj.type === 0) {
          params = {
            ...params,
            hrVipTemplateId: obj.templateId
          }
        }
      }
      // 有会员套餐没有剩余次数
      if (obj.state === 2)  {
        params = {
          ...params,
          durationPackageTemplateId: obj.templateId
        }
      }
      const res2 = await PostJobPayJobApi({
        ...params
      })
      if (res2) {
        // 没有会员套餐
        if (obj.state === 0) {
          share.message.success('支付成功已开启')
        }
        // 有会员套餐有剩余次数
        if (obj.state === 1) {
          share.message.success('支付成功已开启')
        }
        // 有会员套餐没有剩余次数
        if (obj.state === 2) {
          share.message.success('支付成功已开启')
        }
        this.pageLoading = false
        this.loadData()
      }else {
        this.pageLoading = false
      }
    },

    async publishedCallback(res) {
      if (res && this.openJobId) {
        const res = await OpenJobApi({
          jobId: this.openJobId
        })

        if (res && res.needBuy === false) {
          if (this.jobInformation.jobStatus === 1) {
            share.message.success('已提交审核')
          }else {
            share.message.success('开启成功')
          }
        }
      }
      this.openJobId = ''
      this.$nextTick(() => {
        this.loadData()
      })
    },
    changeCurrNavPage(key, index) {
      this.$router.push({
        path: key,
        query: {
          index: index
        }
      })
    },
    editJob() {
      this.$router.push({
        path: '/postJob',
        query: {
          jobId: this.jobId,
          isModify: 'yes',
          editType: 'edit',
          jobType: this.jobType + ''
        }
      })
    },
    recheckJob() {
      this.$router.push({
        path: '/postJob',
        query: {
          jobId: this.jobId,
          isModify: 'yes',
          editType: 'recheck',
          jobType: this.jobType + ''
        }
      })
    },
    returnJobList() {
      this.$router.go(-1)
    },
    /**
    * 支付
    * */
    pay() {
      let param = {
        jobType: this.jobType,
        unitPricePerPerson: this.unitPricePerPerson,
        peopleNumber: this.peopleNumber,
      }
      this.$refs.allPackagePurchaseRef.checkVipPackageStatus(param)
    },
    /**
    * 续费
    * */
    renew() {
      this.openJob()
      // let param = {
      //   jobType: this.jobType,
      //   unitPricePerPerson: this.unitPricePerPerson,
      //   peopleNumber: this.peopleNumber,
      //   renew: true,
      // }
      // this.$refs.allPackagePurchaseRef.checkVipPackageStatus(param)
    },
    /**
    * 关闭岗位
    * */
    async closeJob() {
      if (this.payLoading) {
        return
      }
      this.payLoading = true
      const res = await CloseJobApi({
        jobId: this.jobId
      })

      if (res) {
        share.message.success('关闭成功')
        await this.loadData()
      }
      this.payLoading = false
    },
    /**
     * 去推广详情
     * */
    toSetPromotingDetails() {
      this.$router.push({
        path: '/newJobPromotion',
        query: {
          isModifyStatus: 'yes',
          jobPromoteId: this.jobPromoteInformation.id
        }
      })
    },
    /**
     * 去推广
     * */
    toPromotingDetails() {
      this.$router.push({
        path: '/newJobPromotion',
        query: {
          jobId: this.jobId,
        }
      })
    },
    async openJob() {
      if (this.payLoading) {
        return
      }
      this.payLoading = true

      const res = await OpenJobApi({
        jobId: this.jobId
      })
      if (res && res.needBuy === false) {
        this.payLoading = false
        share.message.success('开启成功')
        this.loadData()
      } else {
        this.payLoading = false
        let param = {
          jobId: this.jobId,
          jobType: 1,
        }
        this.$nextTick(() => {
          this.$refs.allPackagePurchaseRef.checkVipPackageStatus(param)
        })
      }
    },
    async delJob() {
      this.delJobDialogVisible = true
    },
    async delJobDialogSubmit() {
      this.delJobDialogBtnLoading = true
      const res = await PostJobDeleteJobApi({
        jobId: this.jobId
      })

      if (res) {
        share.message.success('操作成功')
      }
      this.delJobDialogBtnLoading = false
      this.delJobDialogVisible = false
      this.$router.go(-1)
    },
    upgradeDialogClose() {
      this.$refs.upgradeDialogFormRef.resetFields()
      this.upgradeDialog = false
    },
    /**
    * 升级普通岗位为红包岗位
    * */
    async upgradeDialogSubmit() {
      this.$refs.upgradeDialogFormRef.validate(async res => {
        if (res) {
          if (this.payLoading) {
            return
          }
          this.payLoading = true
          const res2 = await UpdateRedPocketJobApi({
            jobId: this.jobId,
            numberOfInstallments: this.upgradeDialogForm.numberOfInstallments,
            installmentInterval: JSON.stringify(this.upgradeDialogForm.installmentInterval),
            redPocketPerInstallment: JSON.stringify(this.upgradeDialogForm.redPocketPerInstallmentList),
            needEmployee: this.upgradeDialogForm.peopleNumber,
          })

          if (res) {
            share.message.success('升级成功')
          }
          await this.loadData()
          this.upgradeDialogClose()
          this.payLoading = false
        }
      })
    },
    installmentDetailsFormNext() {
      if (this.$refs.stagingIntervalDetailsFormRef) {
        this.$refs.stagingIntervalDetailsFormRef.validate(res => {
          if (res) {
            this.stagActive++
          }
        })
      } else {
        this.stagActive++
      }
    },
    installmentDetailsFormSubmit() {
      this.$refs.installmentDetailsFormRef.validate(res => {
        if (res) {
          this.initEnvelopeAmount()

          this.popoverIsShow = false
          setTimeout(() => {
            this.stagActive = 0
          }, 200)
        }
      })
    },
    initEnvelopeAmount() {

      let stagingIntervalStr = ''
      let stagingIntervalArr = []
      for (let j = 0; j < this.stageSelectionNum; j++) {
        stagingIntervalStr += this.stagingIntervalDetailsForm['stagingInterval' + (j + 1)] + '天'
        stagingIntervalArr.push(this.stagingIntervalDetailsForm['stagingInterval' + (j + 1)])
        if (j < this.stageSelectionNum - 1) {
          stagingIntervalStr += '、'
        }
      }

      let amountStr = ''
      let amountArr = []
      for (let i = 0; i < this.stageSelectionNum; i++) {
        let num = parseInt(this.installmentDetailsForm['amount' + (i + 1)])
        amountStr += num + '元'
        amountArr.push(num)
        if (i < this.stageSelectionNum - 1) {
          amountStr += '、'
        }
      }

      let str2 = `分${this.stageSelectionNum}期/每期间隔分别为${stagingIntervalStr}/每期红包额分别为：` + amountStr

      this.upgradeDialogForm.redEnvelopeAmount = str2
      this.upgradeDialogForm.numberOfInstallments = this.stageSelectionNum
      this.upgradeDialogForm.installmentInterval = stagingIntervalArr
      this.upgradeDialogForm.redPocketPerInstallmentList = amountArr

    },
    /* 天数验证 */
    stagingIntervalRules(rule, value, callback) {
      let re = /^[1-9]+[0-9]*]*$/
      if (value === '') {
        callback(new Error('请输入天数'))
      } else if (!re.test(value)){
        callback(new Error('天数只能为正整数'))
      } else {
        callback()
      }
    },
    /* 金额验证 */
    amountRules(rule, value, callback) {
      let re = /^[1-9]+[0-9]*]*$/
      if (value === '') {
        callback(new Error('请输入金额'))
      } else if (parseInt(value) < 100) {
        callback(new Error('金额不能小于100元'))
      }else if (!re.test(value)) {
        callback(new Error('金额只能为正整数'))
      } else {
        callback()
      }
    },
  }
}
</script>

<style lang="less" scoped>
.hrSeeJobDetailed_warp {
  width: 100%;
  //height: 100%;
  background-color: #f4f2f3;
  display: flex;
  justify-content: space-between;

  .hrSeeJobDetailed-left {
    width: 780px;
    //min-height: 600px;
    background-color: #ffffff;

    .content {
      width: 100%;
      //height: 100%;
      padding: 0 20px;
      box-sizing: border-box;

      .header {
        width: 100%;
        height: 100px;
        padding: 0 10px 10px 10px;
        box-sizing: border-box;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border-bottom: 1px solid #e3e3e3;

        .header-left{
          height: 100%;
          display: flex;
          justify-content: flex-end;
          flex-direction: column;

          .title {
            font-size: 24px;
            color: #333333;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;

            span {
              color: #e20000;
              font-size: 18px;
              margin-left: 10px;
            }
          }
          .information {
            font-size: 16px;
            margin-top: 7px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            div {
              color: #333333;
              padding: 0 8px;
              border-right: 1px solid #333333;
              height: 15px;
              line-height: 15px;

              &:first-child {
                padding-left: 0px;
              }

              &:last-child {
                border-right: 0px;
              }
            }
          }
        }
        .header-right{
          width: 200px;
          text-align: right;
          padding-top: 10px;
          box-sizing: border-box;
          font-size: 14px;
          color: #8e8e8e;
        }
      }

      .main {
        color: #333333;

        .card {
          margin-bottom: 30px;

          .title {
            font-size: 16px;
            height: 16px;
            line-height: 16px;
            //display: flex;
            //align-items: center;
            //justify-content: flex-start;
            border-left: 2px solid #e20000;
            padding-left: 5px;
            box-sizing: border-box;
            margin-top: 10px;
          }

          .card-content {
            font-size: 14px;
            padding: 0 8px;
            box-sizing: border-box;

            p {
              word-wrap: break-word;
              word-break: break-all;
              white-space: pre-wrap;
              text-indent: -4px;
            }

            .tag{
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: flex-start;
              margin-top: 10px;

              .item {
                border: 1px solid #e20000;
                padding: 2px 10px;
                box-sizing: border-box;
                font-size: 10px;
                border-radius: 10px;
                margin-right: 10px;
                margin-bottom: 5px;
                color: #e20000;
              }
            }

            .table {
              width: 100%;
              height: 100%;
              margin-top: 10px;
              .table-header {
                width: 100%;
                border-top: 1px solid #EBEEF5;
                border-left: 1px solid #EBEEF5;
                border-right: 1px solid #EBEEF5;
                padding: 12px 10px;
                box-sizing: border-box;
                font-size: 14px;
              }
            }

          }

        }

        .footer {
          width: 100%;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .el-button {
            padding: 10px 30px;
            color: #606266;
            border: 1px solid #dcdfe6;

            &:hover {
              color: #ffffff;
              background-color: #e20000;
            }
          }
        }
      }

    }
  }

  .hrSeeJobDetailed-right {
    flex: 1;
    //min-height: 600px;
    margin-left: 10px;
    background-color: #f4f2f3;
  }
}
</style>
<style lang="less">
.hot-job-pay-dialog-wrap {

  .el-dialog__body {
    padding: 0 20px;
  }

  .pay-dialog-content {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      width: 500px;
      height: 100%;
      margin-right: 20px;

      .title {
        font-size: 18px;
        color: #e20000;
        height: 30px;
        line-height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .tag {
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          padding: 0px 10px;
          box-sizing: border-box;
          border-radius: 3px;
          background-color: #ffcbbf;
          margin-left: 20px;
        }
      }

      .content {
        border: 1px solid #b1b1b1;
        border-radius: 10px;
        padding: 0 10px 10px 10px;
        box-sizing: border-box;

        .table {

          .el-row {
            height: 100%;
            .el-col {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .header {
            width: 100%;
            height: 30px;
            border-bottom: 1px solid #bfbfbf;
          }

          .row {
            height: 50px;
            color: #343434;

            .row-item-first {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }

            span {
              font-weight: bold;
              color: #343434;
            }
          }
        }

      }

      .desc {
        margin-top: 10px;
        font-size: 12px;
        color: #969696;
      }

    }

    .right {
      flex: 1;
      height: 100%;
      background-color: #eeeeee;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 0 30px 30px 30px;
      box-sizing: border-box;

      >div {
        width: 100%;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #a9a9a9;
        font-size: 12px;
      }

      .payAble {
        margin-top: 15px;
        font-size: 14px;
        color: #5c5c5c;
        align-items: flex-end;
        padding-bottom: 2px;
        box-sizing: border-box;
        margin-bottom: 5px;
        border-bottom: 1px solid #ffffff;

        span {
          font-size: 24px;
          color: #e20000;
          margin-right: 5px;
        }
      }

      .desc {
        margin-top: 10px;
      }

      .order-number {
        color: #5c5c5c;
        margin-top: 10px;
        font-size: 12px;

        .num {
          color: #323232;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }

  }
}
.red-envelope-job-pay-dialog-wrap {

  .el-dialog__body {
    padding: 0 20px;
  }

  .pay-dialog-content {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      width: 500px;
      height: 100%;
      margin-right: 20px;

      .title {
        font-size: 18px;
        color: #e20000;
        height: 30px;
        line-height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .tag {
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          padding: 0px 10px;
          box-sizing: border-box;
          border-radius: 3px;
          background-color: #ffcbbf;
          margin-left: 20px;
        }
      }

      .content {
        border: 1px solid #b1b1b1;
        border-radius: 10px;
        padding: 10px 10px 10px 10px;
        box-sizing: border-box;

        .table {

          .el-row {
            height: 100%;
            .el-col {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .row {
            height: 50px;
            color: #343434;

            .row-item-first {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }

            span {
              font-weight: bold;
              color: #e20000;
              font-size: 24px;
              margin-right: 5px;
            }
          }
        }

      }

      .desc {
        margin-top: 10px;
        font-size: 12px;
        color: #969696;
      }

    }

    .right {
      flex: 1;
      height: 100%;
      background-color: #eeeeee;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 0 30px 30px 30px;
      box-sizing: border-box;

      >div {
        width: 100%;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #a9a9a9;
        font-size: 12px;
      }

      .payAble {
        margin-top: 15px;
        font-size: 14px;
        color: #5c5c5c;
        align-items: flex-end;
        padding-bottom: 2px;
        box-sizing: border-box;
        margin-bottom: 5px;
        border-bottom: 1px solid #ffffff;

        span {
          font-size: 24px;
          color: #e20000;
          margin-right: 5px;
        }
      }

      .desc {
        margin-top: 10px;
      }

      .order-number {
        color: #5c5c5c;
        margin-top: 10px;
        font-size: 12px;

        .num {
          color: #323232;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }

  }
}
</style>
