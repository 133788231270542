<template>
  <div class="wealthWithdrawal_warp">

    <div class="header">
      <div class="left">
        <div class="desc">
          注：积分单价会随着资金总额和区域积分的变化而变化
        </div>
        我的积分：{{coin}}积分
      </div>
      <div class="right">
        <el-button @click="pointsUnitPriceModelShow(false)">领取财富</el-button>
      </div>
    </div>

    <div class="header">
      <div class="left">
        我的财富：{{wealth}}元
      </div>
      <div class="right">
        <el-button @click="withdrawalAmountModel = true">财富提现</el-button>
      </div>
    </div>

    <div class="card">

      <div class="title">
        领取记录
      </div>

      <div class="content">

        <el-table
            :data="pointsTableData"
            v-loading="pageLoading1"
            style="width: 100%">
          <el-table-column
              prop="wealth"
              label="金额">
          </el-table-column>
          <el-table-column
              prop="getTime"
              label="领取时间">
          </el-table-column>
          <el-table-column
              prop="detail"
              label="详情">
          </el-table-column>
        </el-table>

        <div class="pagination" v-if="pointsTableData && pointsTableData.length > 0">
          <el-pagination
              background
              @current-change="pointsCurrentChange"
              :current-page="pointsCurrentPage"
              :page-size="pointsPageSize"
              layout="total, prev, pager, next, jumper"
              :total="pointsTotal">
          </el-pagination>
        </div>

      </div>

    </div>

    <div class="card">

      <div class="title">
        提现记录<span>（累计提现{{totalWithdrawalAmount}}元）</span>
      </div>

      <div class="content">

        <el-table
            v-loading="pageLoading2"
            :data="cashTableData"
            style="width: 100%">
          <el-table-column
              prop="withdrawalAmount"
              label="金额">
          </el-table-column>
          <el-table-column
              prop="status"
              label="状态">
          </el-table-column>
          <el-table-column
              prop="getTime"
              label="领取时间">
          </el-table-column>
        </el-table>

        <div class="pagination" v-if="cashTableData && cashTableData.length > 0">
          <el-pagination
              background
              @current-change="cashCurrentChange"
              :current-page="cashCurrentPage"
              :page-size="cashPageSize"
              layout="total, prev, pager, next, jumper"
              :total="cashTotal">
          </el-pagination>
        </div>

      </div>

    </div>

    <el-dialog
        title="积分规则"
        :visible.sync="pointsRule"
        width="550px"
        custom-class="pointsRuleModel"
    >
      <div class="text">积分单价=奖金总额/积分</div>
    </el-dialog>

    <el-dialog
        title="当前积分详情"
        :visible.sync="pointsUnitPrice"
        width="550px"
        custom-class="pointsUnitPriceModel"
    >
      <div class="content">
        <div>
          <span class="title">当前积分单价：</span>1积分 = <span class="num">{{coinUnitPrice}}</span>(元)
        </div>
        <div>
          <span class="title">当前可领取的积分：</span><span class="num">{{coin}}(积分)</span>
        </div>
        <div>
          <span class="title">当前可领取的金额：</span><span class="num">{{collectibleWealth}}(元) = {{coinUnitPrice}}(元) × {{coin}}(积分)</span>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="pointsUnitPrice = false">取 消</el-button>
        <el-button type="primary" @click="withdrawalAmountModelShow">领取到我的财富</el-button>
<!--        <el-button v-if="!modelState" type="primary" @click="withdrawalAmountModelShow">立即领取到我的财富</el-button>-->
<!--        <el-button v-if="modelState" type="primary" @click="withdrawalAmountModelShow">立即提现</el-button>-->
      </div>
    </el-dialog>

    <el-dialog
        :visible.sync="withdrawalAmountModel"
        :before-close="withdrawalAmountModelBeforeClose"
        width="550px"
        custom-class="withdrawalAmountModel"
    >
      <div class="content">

        <div class="card">
          <div class="title">
            提现金额
          </div>
          <div class="main">
            <div class="desc">当前可提现<span>{{wealth}}</span>元(一次最低提现10元)，<span @click="withdrawalAmountAll">全部提现</span></div>
            <el-form ref="amountFormRef" :model="amountForm" :rules="formRules" label-width="20px">
              <el-form-item label="¥" prop="amountInput">
                <el-input placeholder="请输入金额" v-model="amountForm.amountInput" type="number" clearable></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <div class="card">
          <div class="title">
            请选择提现到
          </div>
          <div class="main">
            <div class="icons">
<!--              <el-radio v-model="withdrawalAmountMode" label="1" border>-->
<!--                <img class="icon" src="@/assets/icon/alipay.png">-->
<!--              </el-radio>-->
<!--              <el-radio v-model="withdrawalAmountMode" label="2" border>-->
<!--                <img class="icon" src="@/assets/icon/weChat2.png">-->
<!--              </el-radio>-->
              <el-radio v-model="withdrawalAmountMode" label="3" border>
                <img class="icon" src="@/assets/icon/bankCard.png">
              </el-radio>
            </div>

            <div class="dialog_information">

              <div class="text" v-if="withdrawalAmountMode === '1'">
                到账支付宝：
              </div>
              <div class="detailed" v-if="withdrawalAmountMode === '1'">
                <div class="id">
                  <span v-if="accountManagementState.bindAlipay">支付宝昵称：</span>
                  <span v-if="!accountManagementState.bindAlipay">当前未绑定支付宝！</span>
                </div>
              </div>

              <div class="text" v-if="withdrawalAmountMode === '2'">
                到账微信：
              </div>
              <div class="detailed" v-if="withdrawalAmountMode === '2'">
                <div class="id">
                  <span v-if="accountManagementState.bindWechat">微信昵称：{{accountManagementState.bindWechat}}</span>
                  <span v-if="!accountManagementState.bindWechat">当前未绑定微信！</span>
                </div>
              </div>

              <div class="text" v-if="withdrawalAmountMode === '3'">
                到账银行卡：
              </div>
              <div class="detailed" v-if="withdrawalAmountMode === '3'">
                <div class="id">
                  <span v-if="accountManagementState.bankCardNum">银行卡号：{{accountManagementState.bankCardNum}}</span>
                  <span v-if="!accountManagementState.bankCardNum">当前未绑定银行卡！</span>
                </div>
              </div>

              <div class="btn">
                <el-button :loading="withdrawalAmountLoading" @click="withdrawalAmountModelSubmit">{{amountModelSubmitBtnText}}</el-button>
              </div>
              <div class="protocol">
                您申请提现的金额将在2h内到账
<!--                <el-checkbox v-model="protocol">-->
<!--                  申请提现的金额将在2h内到账，由于个人原因导致未到账<br>则后果自负-->
<!--                </el-checkbox>-->
              </div>
            </div>

          </div>
        </div>

      </div>
    </el-dialog>

    <ads :visible.sync="adsVisible" :src="adsSrc" @watchingTime="adsWatchingTime"></ads>

    <el-dialog
        title="提示"
        :visible.sync="hintDialogVisible"
        width="400px">
      <span>您当前还未实名认证，请先去实名认证</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hintDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="hintDialogConfirm">现在去</el-button>
      </span>
    </el-dialog>


    <!-- 修改银行卡弹窗 -->
    <el-dialog
        title="修改银行卡"
        :visible.sync="bankCardModelIsShow"
        width="600px"
        custom-class="accountManagement_dialog"
    >

      <div class="dialog-content">

        <div class="steps">
          <el-steps :active="bankCardModelActive" finish-status="success">
            <el-step title="第一步"></el-step>
            <el-step title="第二步"></el-step>
          </el-steps>
        </div>

        <div class="main" v-if="bankCardModelActive <= 1">
          <el-form
              :rules="modelRules"
              ref="bankCardModelCodeRef"
              :model="bankCardModelCodeForm"
              key="bankCardModelFormCodeRule"
              label-width="80px"
          >
            <div class="form-item">
              <!--              <span class="msg">您当前绑定的银行卡是{{accountManagementState.bankCardNum}}</span>-->
              <span class="msg">您当前绑定的手机号是{{currPhone}}</span>
              <el-form-item label="验证码" prop="msg_code">
                <el-input v-model.trim="bankCardModelCodeForm.msg_code" clearable></el-input>
              </el-form-item>
              <div class="get-code">
                <div class="btn" @click="bankCardModelCodeFormGetCode">
                  <get-code ref="bankCardModelCodeFormGetCodeRef" :phone="accountManagementState.phone" :is-not-verify="true"></get-code>
                </div>
              </div>
            </div>
          </el-form>
        </div>

        <div class="main" v-else>
          <el-form
              :rules="modelRules"
              ref="bankCardModelBankCardRef"
              :model="bankCardModelBankCardForm"
              key="bankCardModelFormBankCardRule"
              label-width="80px"
          >
            <div class="form-item">
              <el-form-item label="银行卡号" prop="bankCardId">
                <el-input v-model.trim="bankCardModelBankCardForm.bankCardId" clearable></el-input>
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item label="所属银行">
                <div>
                  {{bankBelong}}
                </div>
              </el-form-item>
            </div>
          </el-form>
        </div>

      </div>

      <span slot="footer">
        <el-button v-if="bankCardModelActive <= 1" @click="bankCardModelNext">下一步</el-button>
        <el-button v-else type="primary" :loading="bankCardModelSubmitLoading" @click="bankCardModelSubmit">完 成</el-button>
      </span>
    </el-dialog>


    <!-- 实名认证弹窗 -->
    <el-dialog
        title="实名认证"
        :visible.sync="realNameIsShow"
        width="600px"
        custom-class="accountManagement_dialog"
    >

      <div class="dialog-content">

        <div class="steps">
          <el-steps :active="realNameModelActive" finish-status="success">
            <el-step title="第一步"></el-step>
            <el-step title="第二步"></el-step>
          </el-steps>
        </div>

        <div class="main" v-if="realNameModelActive <= 1">
          <el-form
              :rules="modelRules"
              ref="realNameModelCodeRef"
              :model="realNameModelCodeForm"
              key="realNameModelFormCodeRule"
              label-width="80px"
          >
            <div class="form-item">
              <span class="msg">您当前绑定的手机号是{{currPhone}}</span>
              <el-form-item label="验证码" prop="msg_code">
                <el-input v-model.trim="realNameModelCodeForm.msg_code" clearable></el-input>
              </el-form-item>
              <div class="get-code">
                <div class="btn" @click="realNameModelCodeFormGetCode">
                  <get-code ref="realNameModelCodeFormGetCodeRef" :phone="accountManagementState.phone" :is-not-verify="true"></get-code>
                </div>
              </div>
            </div>
          </el-form>
        </div>

        <div class="main" v-else>
          <el-form
              :rules="modelRules"
              ref="realNameModelRealNameRef"
              :model="realNameModelRealNameForm"
              key="realNameModelFormRealNameRule"
              label-width="80px"
          >
            <div class="form-item">
              <el-form-item label="真实姓名" prop="name">
                <el-input v-model.trim="realNameModelRealNameForm.name" clearable></el-input>
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item label="身份证号" prop="idCard">
                <el-input v-model.trim="realNameModelRealNameForm.idCard" clearable></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>

      </div>

      <span slot="footer">
        <el-button v-if="realNameModelActive <= 1" @click="realNameModelNext">下一步</el-button>
        <el-button v-else type="primary" :loading="realNameSubmitLoading" @click="realNameModelSubmit">完 成</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {formRules} from '@/share/formRules'
import {
  GetWealthApi,
  PostWealthApi,
  WealthWithdrawalApi,
  GetWealthRecordApi,
  GetWealthWithdrawalRecordApi,
  GetCoinUnitPriceApi,
  AccountManagementApi, BindBankCardNumApi, RealNameAuthenticationApi, LoginWithUserTokenApi
} from '@/request/api'
import share from "@/share/share";
import Ads from "@/components/ads/ads";
import {get} from "@/request/http";
import banksName from "@/share/banksName";
import GetCode from "@/components/getCode/getCode";
import {mapMutations} from "vuex";
import {Debounce} from "@/until/until";
export default {
  name: "wealthWithdrawal",
  components: {GetCode, Ads},
  data() {
    return {
      pageLoading1: true,
      pageLoading2: true,
      coin: 0,  // 当前积分
      wealth: 0,  // 当前财富
      coinUnitPrice: 0,  // 积分单价

      pointsTableData: [],
      pointsPageSize: 5,
      pointsCurrentPage: 1,
      pointsTotal: 0,

      cashTableData: [],
      totalWithdrawalAmount: '',
      cashPageSize: 5,
      cashCurrentPage: 1,
      cashTotal: 0,

      pointsRule: false,
      pointsUnitPrice: false,
      withdrawalAmountModel: false,
      amountForm: {
        amountInput: '',
      },
      formRules: formRules,

      withdrawalAmountMode: '3',
      protocol: true,
      modelState: false,
      withdrawalAmountLoading: false,

      accountManagementState: {
        bindAlipay: '',
        bindWechat: '',
        bankCardNum: '',
        isRealNameAuthentication: false,
        phone: '',
      },

      adsVisible: false,
      adsSrc: 'https://jiaxinzp.com/api/api/media/videos/5968561956a2b3c49a75e96472a898d7_1aKm0hE.mp4',

      hintDialogVisible: false,
      dialogVisible: false,

      modelRules: {
        ...formRules,
      },

      bankCardModelIsShow: false,  // 银行卡
      bankCardModelSubmitLoading: false,
      bankCardModelActive: 1,
      bankCardModelCodeForm: {
        msg_code: '',
      },
      bankCardModelBankCardForm: {
        bankCardId: '',
      },
      bankBelong: '',


      realNameIsShow: false,
      realNameSubmitLoading: false,
      realNameModelActive: 1,
      realNameModelCodeForm: {
        msg_code: '',
      },
      realNameModelRealNameForm: {
        name: '',
        idCard: '',
      },

    }
  },
  computed: {
    place() {
      return this.$store.state.userInfo.place
    },
    collectibleWealth() {
      return this.coin * this.coinUnitPrice
    },
    amountModelSubmitBtnText() {
      if (this.withdrawalAmountMode === '1') {
        if(this.accountManagementState.bindAlipay) {
          return '提 现'
        }else {
          return '去绑定'
        }
      }
      if (this.withdrawalAmountMode === '2') {
        if(this.accountManagementState.bindWechat) {
          return '提 现'
        }else {
          return '去绑定'
        }
      }
      if (this.withdrawalAmountMode === '3') {
        if(this.accountManagementState.bankCardNum) {
          return '提 现'
        }else {
          return '去绑定'
        }
      }
    },
    currPhone() {
      let str = ''
      if (this.accountManagementState.phone === '' || this.accountManagementState.phone === null) {
        str = '空'
      }else {
        let str1 = this.accountManagementState.phone.slice(0, 3)
        let str2 = this.accountManagementState.phone.slice(7, 11)
        str = str1 + '****' + str2
      }
      return str
    },
  },
  watch: {
    place() {
      this.loadData()
    },
    'amountForm.amountInput': {
      handler: function () {
        if (this.amountForm.amountInput) {
          let amountInput = this.amountForm.amountInput.toString()
          if (amountInput.indexOf('.') !== -1) {
            let arr = amountInput.split('.')
            if (arr[1].length > 2) {
              arr[1] = arr[1].slice(0, 2)
              this.$nextTick(() => {
                this.amountForm.amountInput = arr[0] + '.' + arr[1]
              })
            }
          }
        }
      }
    },
    'bankCardModelBankCardForm.bankCardId': {
      handler: function() {
        if (this.bankCardModelBankCardForm.bankCardId.length > 5) {
          this.VueDebounce({vm: this})
        }
      },
    }
  },
  mounted() {
    this.loadData()
    this.loadAccountManagement()
  },
  methods: {
    ...mapMutations(['setUserinfo', 'setToken']),
    VueDebounce: Debounce(({vm}) => {
      vm['loadCardInfo']()
    }, 500),
    async loadData() {
      const res = await GetWealthApi({area: this.place.value})

      if (res) {
        this.wealth = res.wealth
        this.coin = res.coin
      }

      await this.loadWealthRecordData()

      await this.loadWealthWithdrawalRecordData()
    },

    async loadWealthRecordData() {
      this.pageLoading1 = true
      const res2 = await GetWealthRecordApi({
        pageNum: this.pointsCurrentPage,
        pageSize: this.pointsPageSize,
      })

      if (res2) {
        this.pointsTotal = res2.numOfRecords
        this.pointsTableData = res2.recordList
      }
      this.pageLoading1 = false
    },

    async loadWealthWithdrawalRecordData() {
      this.pageLoading2 = true
      const res3 = await GetWealthWithdrawalRecordApi({
        pageNum: this.cashCurrentPage,
        pageSize: this.cashPageSize,
      })

      if (res3) {
        this.cashTotal = res3.numOfRecords
        this.cashTableData = res3.recordList
        this.totalWithdrawalAmount = res3.totalWithdrawalAmount
      }
      this.pageLoading2 = false
    },

    async loadAccountManagement() {
      const res = await AccountManagementApi()

      if (res) {
        this.accountManagementState.bindWechat = res.bindWechat
        this.accountManagementState.bankCardNum = res.bankCardNum
        this.accountManagementState.isRealNameAuthentication = res.isRealNameAuthentication
        this.accountManagementState.phone = res.phone

        if (res.isRealNameAuthentication === false) {
          this.hintDialogVisible = true
        }
      }
    },

    pointsCurrentChange(val) {
      this.pointsCurrentPage = val
      this.loadWealthRecordData()
    },

    cashCurrentChange(val) {
      this.cashCurrentPage = val
      this.loadWealthWithdrawalRecordData()
    },

    async pointsUnitPriceModelShow(state) {
      this.pointsUnitPrice = true
      this.modelState = state
      const res = await GetCoinUnitPriceApi({
        area: this.place.value
      })

      this.coinUnitPrice = res.coinUnitPrice
    },

    async withdrawalAmountModelShow() {
      this.pointsUnitPrice = false
      if (this.modelState) {
        setTimeout(() =>{
          this.withdrawalAmountModel = true
        },200)
      }else {
        const res = await PostWealthApi({
          area: this.place.value,
        })
        if (res) {
          share.message.success('领取成功')
          this.loadData()
        }
      }
    },
    withdrawalAmountAll() {
      this.amountForm.amountInput = this.wealth
    },
    withdrawalAmountModelSubmit() {
      if (this.withdrawalAmountLoading) {
        return
      }
      let state = true
      if (this.withdrawalAmountMode === '1') {
        if (this.accountManagementState.bindAlipay) {
          state = true
        }else {
          state = false
        }
      }
      if (this.withdrawalAmountMode === '2') {
        if (this.accountManagementState.bindWechat) {
          state = true
        }else {
          state = false
        }
      }
      if (this.withdrawalAmountMode === '3') {
        if (this.accountManagementState.bankCardNum) {
          state = true
        }else {
          state = false
        }
      }

      if (state) {
        if (!this.accountManagementState.isRealNameAuthentication) {
          this.hintDialogVisible = true
          return;
        }
        this.$refs.amountFormRef.validate( res => {
          if (res) {
            this.withdrawalAmount()
            // this.adsVisible = true
          }
        })

      }else {
        this.withdrawalAmountModelBeforeClose()
        this.bankCardModelActive = 1
        this.bankCardModelCodeForm.msg_code = ''
        this.bankCardModelBankCardForm.bankCardId = ''
        this.bankBelong = ''


        if (!this.accountManagementState.isRealNameAuthentication) {
          this.hintDialogVisible = true
        }else {
          this.bankCardModelIsShow = true
          this.$nextTick(()=>{
            this.$refs.bankCardModelCodeRef.resetFields()
          })
        }
      }
    },
    adsWatchingTime(state) {
      if (state) {
        this.adsVisible = false
        this.withdrawalAmount()
      }else {
        this.adsVisible = false
        share.message.warning('提现失败！观看广告的时长不足')
      }
    },
    async withdrawalAmount() {
      this.withdrawalAmountLoading = true

      let withdrawalAmount = this.amountForm.amountInput
      const res2 = await WealthWithdrawalApi({
        withdrawalAmount: withdrawalAmount,
        area: this.place.value
      })
      if (res2) {
        this.amountForm.amountInput = ''
        this.$refs.amountFormRef.resetFields()
        this.withdrawalAmountLoading = false
        this.withdrawalAmountModel = false
        share.message.success('提现申请成功')
        this.loadData()
      }else {
        this.withdrawalAmountLoading = false
        this.withdrawalAmountModel = false
        share.message.error('提现申请失败')
      }
    },
    withdrawalAmountModelBeforeClose() {
      this.amountForm.amountInput = ''
      this.$refs.amountFormRef.resetFields()
      this.withdrawalAmountLoading = false
      this.withdrawalAmountModel = false
    },
    hintDialogConfirm() {
      if (this.withdrawalAmountModel) {
        this.withdrawalAmountModelBeforeClose()
      }
      this.realNameModelActive = 1
      this.realNameModelCodeForm.msg_code = ''

      this.realNameModelRealNameForm.name = ''
      this.realNameModelRealNameForm.idCard = ''
      this.realNameIsShow = true
      this.$nextTick(() => {
        this.$refs.realNameModelCodeRef.resetFields()
      })
    },

    async loadCardInfo() {
      let cardNo = this.bankCardModelBankCardForm.bankCardId
      const res = await get(`https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?cardNo=${cardNo}&cardBinCheck=true`, '')
      if (res.data.validated) {
        let t = res.data.bank
        this.bankBelong = banksName[t]
      }else {
        this.bankBelong = ''
      }
    },

    bankCardModelNext() {
      this.$refs.bankCardModelCodeRef.validate(res => {
        if (res) {
          this.bankCardModelActive ++
        }
      })
    },
    bankCardModelCodeFormGetCode() {
      this.$refs.bankCardModelCodeFormGetCodeRef.getCode()
    },
    bankCardModelSubmit() {
      this.$refs.bankCardModelBankCardRef.validate(async res => {
        if (res) {
          this.bankCardModelSubmitLoading = true
          const res2 = await BindBankCardNumApi({
            bankCardNum: this.bankCardModelBankCardForm.bankCardId,
            msgCode: this.bankCardModelCodeForm.msg_code,
          })

          if (res2.code && res2.code === 440) {
            share.message.error(res2.data.message)
          } else {
            share.message.success('恭喜你，绑定银行卡成功！')
            await this.loadAccountManagement()
          }
          this.bankCardModelIsShow = false
          this.bankCardModelSubmitLoading = false
        }
      })
    },

    realNameModelCodeFormGetCode() {
      this.$refs.realNameModelCodeFormGetCodeRef.getCode()
    },

    realNameModelNext() {
      this.$refs.realNameModelCodeRef.validate(res => {
        if (res) {
          this.realNameModelActive ++
        }
      })
    },

    realNameModelSubmit() {
      this.$refs.realNameModelRealNameRef.validate(async res => {
        if (res) {
          this.realNameSubmitLoading = true

          const res3 = await RealNameAuthenticationApi({...this.realNameModelRealNameForm, ...this.realNameModelCodeForm})
          if (res3) {
            share.message.success('实名认证成功')
            await this.loadAccountManagement()
          }
          this.realNameSubmitLoading = false
          this.realNameIsShow = false
          this.hintDialogVisible = false
          const res2 = await LoginWithUserTokenApi()
          if (res2) {
            this.setToken({
              'token': res2.userToken
            })
            this.setUserinfo({
              username: res2.information.name || '',
              userType: res2.information.userType || '',
              gender: res2.information.gender || '',
              profilePhoto: res2.information.avatar || ''
            })
          }
        }
      })
    },

  }
}
</script>

<style lang="less" scoped>
.wealthWithdrawal_warp {
  width: 100%;
  //height: 100%;
  padding: 20px 40px;
  box-sizing: border-box;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #e20000;

    .desc {
      font-size: 12px;
      color: #E6A23C;
      margin-bottom: 3px;
    }

    .el-button {
      padding: 10px 20px;
      color: #e20000;
      border: 1px solid #e20000;
    }

  }

  .card {
    width: 100%;
    margin-bottom: 20px;

    .title {
      font-size: 18px;
      color: #323232;
      font-weight: bold;
    }

    .content {
      background-color: #fcfcfc;
      padding: 20px 30px;

      .pagination {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
      }

    }

    .information {
      width: 100%;
      height: 80px;
      background-color: #fcfcfc;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .desc {
        font-size: 14px;
        color: #E6A23C;
      }

      .text {
        height: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #323232;

        > div {
          > span {
            color: #e20000;
          }
        }

        .a {
          text-decoration: underline;
          cursor: pointer;

          &:first-child {
            color: #e20000;
          }

        }

      }

    }

  }
}
</style>
<style lang="less">
.pointsRuleModel {
  .text {
    width: 100%;
    color: #323232;
    font-size: 16px;
    letter-spacing: 2px;
  }
}

.pointsUnitPriceModel {
  .content {
    width: 100%;
    >div {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 16px;
      color: #323232;
      letter-spacing: 2px;

      .title {
        font-size: 16px;
        font-weight: bold;
        color: #323232;
      }
      .num {
        font-size: 20px;
        color: #e20000;
        font-weight: bold;
      }
    }
  }
}
.withdrawalAmountModel {
  .content {
    width: 100%;

    .card {
      width: 100%;

      .title {
        font-size: 16px;
      }

      .main {
        width: 100%;
        padding: 10px 20px 0 20px;
        box-sizing: border-box;

        .qrcode-warp {
          width: 100%;
          height: 300px;
          display: flex;
          align-items: center;
          justify-content: center;

          .qrcode {
            width: 220px;
            height: 220px;
            display: inline-block;
            background-color: #ffffff; //设置白色背景色
            padding: 10px; // 利用padding的特性，挤出白边
            box-sizing: border-box;
            img {
              width: 132px;
              height: 132px;

            }
          }
        }

        .el-form-item__label {
          font-size: 20px;
        }

        .desc {
          font-size: 14px;
          color: #898989;
          letter-spacing: 1px;

          span {
            color: #e20000;
            &:last-child {
              text-decoration: underline;
              cursor: pointer;
            }
          }

        }

        .icons {
          text-align: center;
          margin: 10px 0 20px 0;

          .el-radio.is-bordered {
            padding: 0;
            height: auto;
          }

          .el-radio__inner {
            display: none;
          }

          .el-radio__label {
            padding-left: 0px;
          }

          //.el-radio__input,.is-checked {
          //  display: none;
          //}

          .icon {
            width: 50px;
            height: 50px;
            margin: 10px 12px;
          }

        }

        .dialog_information {
          background-color: #f5f5f5;
          padding: 20px 20px;
          box-sizing: border-box;
          border-radius: 10px;

          .text {
            font-size: 14px;
            color: #323232;
          }

          .pay-amount {
            font-size: 30px;
            text-align: center;
            color: #e20000;
            span {
              font-size: 20px;
              color: #333333;
            }
          }

          .detailed {
            width: 100%;
            padding: 0 40px;
            box-sizing: border-box;
            margin: 10px 0 20px 0;
          }

          .btn {
            width: 100%;
            padding: 0 40px;
            box-sizing: border-box;

            .el-button{
              width: 100%;
              color: #ffffff;
              background-color: #37bd43;
            }
          }

          .protocol {
            width: 100%;
            margin-top: 20px;
            font-size: 14px;
            color: #c7c7c7;

            .el-checkbox__inner{
              position: relative;
              top: -18px;
              left: 0;
            }
          }

        }

      }

    }

  }
}
</style>
