<template>
  <div class="allPackagePurchase_warp">

    <el-dialog
        title="选择套餐"
        :visible.sync="choosePackageDialogVisible"
        width="500px"
        :before-close="choosePackageDialogClose"
    >
      <div class="choose-package-dialog-content">

        <div class="jobPromotion-item jobPromotion-item1" @click="choosePackageValue = 0">
          <div class="title">
            会员套餐 <i v-show="choosePackageValue === 0" class="el-icon-check"></i>
          </div>
          <div class="desc">
            本套餐包含可同时挂载的岗位数量，和每日可主动沟通的次数，还有专属客服为您服务
          </div>
        </div>

        <div class="jobPromotion-item jobPromotion-item3" @click="choosePackageValue = 1">
          <div class="title">
            时长套餐 <i v-show="choosePackageValue === 1" class="el-icon-check"></i>
          </div>
          <div class="desc">
            本套餐包含岗位可挂载的天数，和每日可主动沟通的次数
          </div>
        </div>

      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="choosePackageDialogClose">取 消</el-button>
        <el-button type="primary" :loading="payLoading" @click="choosePackageDialogSubmit">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="购买套餐"
        :visible.sync="allPackagePurchaseDialogVisible"
        width="900px"
        :before-close="allPackagePurchaseDialogClose">
      <div class="all-package-dialog-content" v-loading="pageLoading">

        <el-alert
            v-if="vipPackageWarning"
            title="提示"
            description="您当前的会员套餐可同时在线岗位数已达上线"
            type="warning"
            :closable="false"
            show-icon>
        </el-alert>

        <div class="title" v-if="choosePackageValue === 0">
          {{title}}
          <div class="tag">
            购买后立即生效
          </div>
        </div>

        <div class="title" v-if="choosePackageValue === 1">
          {{title}}
          <div class="tag">
            购买后立即上线岗位
          </div>
        </div>

        <div class="all-package-dialog-main" v-if="!vipInformationIsShow">
          <div class="left">
            <div class="content" style="margin-bottom: 20px;" v-if="allTemplateList.length >0">

              <div class="table">
                <div class="header" v-if="choosePackageValue === 0">
                  <el-row :gutter="10">
                    <el-col :span="8">
                      类型
                    </el-col>
                    <el-col :span="6">
                      可同时在线岗位
                    </el-col>
                    <el-col :span="5">
                      每日主动沟通
                    </el-col>
                    <el-col :span="3">
                      专属客服
                    </el-col>
                    <el-col :span="2">
                    </el-col>
                  </el-row>
                </div>
                <div class="header" v-if="choosePackageValue === 1">
                  <el-row :gutter="10">
                    <el-col :span="8">
                      类型
                    </el-col>
                    <el-col :span="8">
                      每日主动沟通
                    </el-col>
                    <el-col :span="8">
                    </el-col>
                  </el-row>
                </div>
                <div class="row" v-for="(item, index) in allTemplateList" :key="'activeCommunicationTemplateListTable' + index">
                  <el-row :gutter="10" v-if="choosePackageValue === 0">
                    <el-col :span="8">
                      <div class="row-item row-item-first">
                        <div><span>{{item.price}}</span>RMB/<span>{{item.effectiveDays}}</span>天</div>
                        <div>立即生效</div>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div class="row-item">
                        <div><span>{{item.onlineJobNum}}</span>个</div>
                      </div>
                    </el-col>
                    <el-col :span="5">
                      <div class="row-item">
                        <div><span>{{item.activeCommunicationTimesPerDay}}</span>人/天</div>
                      </div>
                    </el-col>
                    <el-col :span="3">
                      <div class="row-item">
                        <div><span>是</span></div>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="row-item">
                        <el-radio v-model="activeTemplate" :label="item.price" @change="activeCommunicationChange(item.id)">&nbsp;</el-radio>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row :gutter="10" v-if="choosePackageValue === 1">
                    <el-col :span="8">
                      <div class="row-item row-item-first">
                        <div><span>{{item.price}}</span>RMB/<span>{{item.effectiveDays}}</span>天</div>
                        <div>立即生效</div>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="row-item">
                        <div><span>{{item.activeCommunicationTimesPerDay}}</span>人/天</div>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="row-item">
                        <el-radio v-model="activeTemplate" :label="item.price" @change="activeCommunicationChange(item.id)">&nbsp;</el-radio>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div class="content" v-if="jobType === 1 && !renew">

              <div class="table2">
                <div class="row">
                  <el-row :gutter="10">
                    <el-col :span="12">
                      <div class="row-item row-item-first">
                        一个人的红包金额
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="row-item">
                        <span>{{unitPricePerPerson}}</span>元
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div class="row">
                  <el-row :gutter="10">
                    <el-col :span="12">
                      <div class="row-item row-item-first">
                        需招人数
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <span>{{peopleNumber}}</span>人
                    </el-col>
                  </el-row>
                </div>
              </div>

            </div>
            <div class="desc" v-if="choosePackageValue === 0">
              <div class="desc-item">
                *会员套餐购买后立即生效，该产品属于数字化商品，不支持七天无理由退货。
              </div>
              <div class="desc-item">
                *续费会员只能在到期前三天内续费。
              </div>
              <div class="desc-item">
                *此套餐不包含红包岗位的红包金额。
              </div>
            </div>
            <div class="desc" v-if="choosePackageValue === 1">
              <div class="desc-item">
                *时长套餐购买后立即生效，该产品属于数字化商品，不支持七天无理由退货。
              </div>
            </div>
          </div>

          <div class="right">
            <div class="payAble">
              <div class="text">
                需要支付
              </div>
              <div class="num">
                <span>{{ finalAmount }}</span>元
              </div>
            </div>
            <div class="original-price">
              <div class="text">
                原价
              </div>
              <div class="num">
                {{ finalAmount }}
              </div>
            </div>
            <div class="discount">
              <div class="text">
                优惠
              </div>
              <div class="num">
                -0
              </div>
            </div>
            <!--            <div class="order-number">-->
            <!--              <div class="text">-->
            <!--                订单编号为：-->
            <!--              </div>-->
            <!--              <div class="num">-->
            <!--                123456-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="desc">
              注：点击确定支付后会自动从账户余额中扣除
            </div>
          </div>
        </div>

        <div class="vip-information" v-if="vipInformationIsShow">
          <div class="vip-information-card">
            <div class="vip-information-title">
              <div class="title-warp">
                <img class="image" src="@/assets/icon/vip.png">
                当前会员信息
                <span class="link" v-if="remainDays <= 3" @click="vipInformationIsShow = false">
                  点击续费会员
                </span>
              </div>
            </div>
            <div class="vip-information-item">
              <div>当前会员剩余天数：<span class="num">{{remainDays}}天</span></div>
            </div>
            <div class="vip-information-item">
              <div>剩余可在线岗位数量：<span class="num">{{remainJobNum}}个</span></div>
            </div>
            <div class="vip-information-item">
              <div>今日剩余主动沟通数量：<span class="num">{{remainActiveCommunicationTimes}}次</span></div>
            </div>
          </div>
        </div>

      </div>
      <div slot="footer" class="dialog-footer" v-if="!vipInformationIsShow">
        <el-button @click="allPackagePurchaseDialogClose">取 消</el-button>
        <el-button type="primary" :loading="payLoading" @click="allPackagePurchaseDialogSubmit">确定支付</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { Loading } from 'element-ui';
import {
  GetDurationPackageTemplateListApi, GetHrBeforePublishJobCheckApi, GetHrGetHrVipStatusApi,
  GetProductGetHrVipTemplateListApi, JobHrSeeJobDetailApi,
  PostProductBuyHrVipApi,
} from "@/request/api";
import share from "@/share/share";

export default {
  name: "allPackagePurchase",
  props: {
    payImmediately: {  // 直接购买
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      pageLoading: false,
      payLoading: false,

      jobId: '',
      jobType: 0,
      unitPricePerPerson: 0,
      peopleNumber: 0,

      renew: false,  // 是否是续费

      beforePublishJobCheckState: 0,

      choosePackageValue: 0,
      allPackagePurchaseDialogVisible: false,
      choosePackageDialogVisible: false,

      vipPackageWarning: false,

      allTemplateList: [],
      activeTemplate: 0,
      activeTemplateId: '',
      title: '',
      isVip: false,
      vipInformationIsShow: false,
      remainDays: 0,  // 当前会员剩余天数
      remainJobNum: 0,  // 剩余可在线岗位数量
      remainActiveCommunicationTimes: 0,  // 今日剩余主动沟通数量
    }
  },
  computed: {
    finalAmount() {
      let money = 0
      if (this.renew === true) {
        money = parseFloat(this.activeTemplate).toFixed(2)
      } else {
        money = (parseFloat(this.activeTemplate) + parseFloat(this.unitPricePerPerson) * parseFloat(this.peopleNumber)).toFixed(2)
      }
      return money
    }
  },
  async mounted() {

  },
  methods: {
    /**
    * 获取hr会员套餐信息
    * */
    async getHrGetHrVipStatus() {
      const res = await GetHrGetHrVipStatusApi()

      if (res) {
        this.isVip = res.isVip  // 是否是会员
        this.remainDays = res.remainDays  // 剩余有效期天数
        this.remainJobNum = res.remainJobNum  // 剩余可用在线岗位数
        this.remainActiveCommunicationTimes = res.remainActiveCommunicationTimes  // 今日剩余可主动沟通数
        if(this.isVip) {
          this.vipInformationIsShow = true
        }else {
          this.vipInformationIsShow = false
        }
      }
    },
    async loadJobDetailData() {
      this.pageLoading = true
      const res = await JobHrSeeJobDetailApi({
        jobId: this.jobId
      })
      if (res) {
        this.unitPricePerPerson = res.jobInformation.redPocketPerPerson  // 每人的红包金额（元）
        this.peopleNumber = res.jobInformation.redPocketTotalNum  // 需招人数
      }
    },
    /**
     * 查看是否购买了会员及会员剩余次数情况
     * */
    async getBeforePublishJobCheck() {
      let state = -1
      const res = await GetHrBeforePublishJobCheckApi()

      if (res) {
        state = res.status
      }
      return state
    },
    /**
    * 检查会员套餐状态
    * */
    async checkVipPackageStatus(obj) {
      if (obj && obj.jobId !== undefined && obj.jobId !== '') {
        this.jobId = obj.jobId
      }
      if (obj && obj.jobType !== undefined && obj.jobType !== '') {
        this.jobType = obj.jobType
      }
      if (obj && obj.unitPricePerPerson !== undefined && obj.unitPricePerPerson !== '') {
        this.unitPricePerPerson = obj.unitPricePerPerson
      }
      if (obj && obj.peopleNumber !== undefined && obj.peopleNumber !== '') {
        this.peopleNumber = obj.peopleNumber
      }
      if (obj.renew) {
        this.renew = true
      }
      let loadingInstance = Loading.service({background: 'hsla(0,0%,100%,.9)'});
      let state = await this.getBeforePublishJobCheck()
      this.beforePublishJobCheckState = state
      if (state === 2)  {
        if (this.jobType === 1 && this.jobId) {
          await this.loadJobDetailData()
        }
      }
      loadingInstance.close();
      /*
      * 没有会员套餐
      * */
      if (state === 0) {
        this.showChoosePackageDialog()
      }
      /*
      * 有会员套餐有剩余次数
      * */
      if (state === 1) {
        if (this.jobType === 1) {
          this.showPayRedEnvelopeDialog()
        }else {
          this.$emit('successfulPurchase', {
            state: state,
          })
          this.allPackagePurchaseDialogClose()
        }
      }
      /*
       * 有会员套餐没有剩余次数
       * */
      if (state === 2)  {
        this.vipPackageWarning = true
        this.showPayDurationPackageDialog()
      }
    },
    /**
    * 显示选择套餐弹窗
    * */
    showChoosePackageDialog() {
      this.choosePackageDialogVisible = true
    },
    /**
     * 显示红包金额支付弹窗
     * */
    showPayRedEnvelopeDialog() {
      this.choosePackageValue = 2
      this.allPackagePurchaseDialogVisible = true
    },
    /**
    * 显示会员套餐购买弹窗
    * */
    showPayVipPackageDialog() {
      this.choosePackageValue = 0
      this.title = '会员套餐'
      this.loadTemplateData()
      this.allPackagePurchaseDialogVisible = true
    },
    /**
    * 显示时长套餐购买弹窗
    * */
    showPayDurationPackageDialog() {
      this.choosePackageValue = 1
      this.title = '时长套餐'
      this.loadTemplateData()
      this.allPackagePurchaseDialogVisible = true
    },
    choosePackageDialogSubmit() {
      this.choosePackageDialogVisible = false

      if (this.choosePackageValue === 0) {
        this.title = '时长套餐'
      }
      if (this.choosePackageValue === 1) {
        this.title = '会员套餐'
      }

      this.loadTemplateData()

      setTimeout(() => {
        this.allPackagePurchaseDialogVisible = true
      }, 200)
    },

    choosePackageDialogClose() {
      this.choosePackageDialogVisible = false
    },
    /**
    * 获取套餐模板列表
    * */
    async loadTemplateData() {
      this.pageLoading = true
      let res = {}
      if (this.choosePackageValue === 0) {
        res = await GetProductGetHrVipTemplateListApi()
        await this.getHrGetHrVipStatus()
      }else if (this.choosePackageValue === 1) {
        res = await GetDurationPackageTemplateListApi()
      }

      if (res) {
        this.allTemplateList = res.templateList
        if (res.templateList.length > 0) {
          this.activeTemplate = res.templateList[0].price
          this.activeTemplateId = res.templateList[0].id
        }
      }
      this.pageLoading = false
    },
    allPackagePurchaseDialogClose() {
      this.allPackagePurchaseDialogVisible = false
      setTimeout(() => {
        this.vipPackageWarning = false
      }, 200)
    },
    activeCommunicationChange(id) {
      this.activeTemplateId = id
    },
    /**
    * 确定支付
    * */
    async allPackagePurchaseDialogSubmit() {
      this.payLoading = true

      // 直接购买的情况
      if (this.payImmediately) {
        const res = await PostProductBuyHrVipApi({
          templateId: this.activeTemplateId
        })
        if (res) {
          // 目前只有一种（直接购买会员）
          share.message.success('购买会员成功')
        }
      }
      this.$emit('successfulPurchase', {
        templateId: this.activeTemplateId,
        type: this.choosePackageValue,
        state: this.beforePublishJobCheckState
      })
      this.allPackagePurchaseDialogClose()
      this.payLoading = false
    },
  }
}
</script>

<style lang="less" scoped>
.allPackagePurchase_warp {

}
</style>

<style lang="less">
.allPackagePurchase_warp {
  .el-dialog__body {
    padding: 0 20px;
  }

  .dialog-footer {
    padding-top: 20px;
    box-sizing: border-box;
  }

  .choose-package-dialog-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .jobPromotion-item {
      width: 200px;
      height: 200px;
      margin: 10px;
      background-color: #ffffff;
      border-radius: 20px;
      border: 2px solid #e20000;
      padding: 20px 20px;
      cursor: pointer;
      box-sizing: border-box;

      .title {
        height: 28px;
        font-size: 18px;
        font-weight: bold;
        color: #ff4200;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .el-icon-check {
          font-weight: bold;
          font-size: 28px;
          margin-left: 10px;
        }
      }

      .desc {
        font-size: 14px;
        color: #4b4b4b;
      }

      .content {
        color: #3b3b3b;

        .content-item {
          font-size: 14px;
          margin: 15px 0;
          font-weight: bold;
        }
      }

      .message {
        font-size: 12px;
        color: #5c5c5c;
      }

    }

    .jobPromotion-item1 {
      background-image: url("../../assets/icon/jobPromotion-1.png");
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: 60% 60%;
    }

    .jobPromotion-item2 {
      background-image: url("../../assets/icon/jobPromotion-2.png");
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: 60% 60%;
    }

    .jobPromotion-item3 {
      background-image: url("../../assets/icon/jobPromotion-3.png");
      background-repeat: no-repeat;
      background-size: 60% 60%;
      background-position: bottom right;
    }
  }

  .all-package-dialog-content {
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .title {
      font-size: 18px;
      color: #e20000;
      height: 30px;
      margin-bottom: 10px;
      line-height: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .tag {
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        padding: 0px 10px;
        box-sizing: border-box;
        border-radius: 3px;
        background-color: #ffcbbf;
        margin-left: 20px;
      }
    }

    .all-package-dialog-main {
      width: 100%;
      //height: calc(100% - 30px);
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        width: 580px;
        height: 100%;
        margin-right: 20px;

        .select-job {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        .content {
          border: 1px solid #b1b1b1;
          border-radius: 10px;
          padding: 0 10px 10px 10px;
          box-sizing: border-box;

          .table {

            .el-row {
              height: 100%;

              .el-col {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            .header {
              width: 100%;
              height: 30px;
              border-bottom: 1px solid #bfbfbf;
            }

            .row {
              height: 50px;
              color: #343434;

              .row-item-first {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              }

              span {
                font-weight: bold;
                color: #343434;
              }
            }
          }

          .table2 {

            .el-row {
              height: 100%;
              .el-col {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            .row {
              height: 50px;
              color: #343434;

              .row-item-first {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              }

              span {
                font-weight: bold;
                color: #e20000;
                font-size: 24px;
                margin-right: 5px;
              }
            }
          }

        }

        .desc {
          margin-top: 10px;
          font-size: 12px;
          color: #969696;
        }

      }

      .right {
        flex: 1;
        height: 100%;
        background-color: #eeeeee;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 0 30px 30px 30px;
        box-sizing: border-box;

        > div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #a9a9a9;
          font-size: 12px;
        }

        .payAble {
          margin-top: 15px;
          font-size: 14px;
          color: #5c5c5c;
          align-items: flex-end;
          padding-bottom: 2px;
          box-sizing: border-box;
          margin-bottom: 5px;
          border-bottom: 1px solid #ffffff;

          span {
            font-size: 24px;
            color: #e20000;
            margin-right: 5px;
          }
        }

        .desc {
          margin-top: 10px;
        }

        .order-number {
          color: #5c5c5c;
          margin-top: 10px;
          font-size: 12px;

          .num {
            color: #323232;
            font-size: 14px;
            font-weight: bold;
          }
        }
      }

    }

    .vip-information {
      width: 100%;
      box-sizing: border-box;
      .vip-information-card {
        width: 100%;
        border-radius: 20px;
        background-color: #efefef;
        padding: 20px 20px;
        box-sizing: border-box;
        .vip-information-title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 10px;
          .title-warp {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .image {
              width: 34px;
              height: 34px;
              margin-right: 10px;
            }
            .link {
              margin-left: 30px;
              font-weight: normal;
              cursor: pointer;
              font-size: 14px;
              color: #63A7FF;
            }
          }
        }
        .vip-information-item {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 16px;
          .num {
            color: #e20000;
          }
        }
      }
    }

  }
}
</style>
