<template>
  <div class="buyAdditionalCommunicateTime_warp">
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="400px"
        :before-close="handleClose">
      <div class="content">您当日主动沟通次数已用完，是否立即花费<span class="num">2元</span>购买一次主动沟通次数</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {PostProductBuyAdditionalCommunicateTimeApi, ProductCanHrCommunicateApi} from "@/request/api";
import share from "@/share/share";

export default {
  name: "buyAdditionalCommunicateTime",
  props: {
    jobId: {
      type: [String, Number],
      default: ''
    },
    employeeId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
    }
  },
  methods: {
    async init() {
      const res = await ProductCanHrCommunicateApi({
        jobId: this.jobId,
        employeeId: this.employeeId,
      })

      if (res && res.canCommunicate === true) {
        this.$emit('success')
      }else {
        this.dialogVisible = true
      }
    },
    handleClose() {
      this.dialogVisible = false
      this.btnLoading = false
    },
    async submit() {
      this.btnLoading = true
      const res = await PostProductBuyAdditionalCommunicateTimeApi({
        jobId: this.jobId,
        employeeId: this.employeeId,
      })

      if (res) {
        share.message.success('购买成功')
        this.dialogVisible = false
        this.btnLoading = false
        this.$emit('success')
      }else {
        this.dialogVisible = false
        this.btnLoading = false
      }
    },

  }
}
</script>

<style lang="less" scoped>
.buyAdditionalCommunicateTime_warp {
  .content {
    font-size: 16px;

    .num {
      color: #e20000;
      font-weight: bold;
      padding: 0 10px;
    }
  }
}
</style>
