<template>
  <div class="hrManagement_warp">

    <div class="left">

      <div class="card">
        <div class="title">
          新同事
        </div>
        <div class="content" v-loading="pageLoading1">

          <div class="content-item" v-if="newHrList && newHrList.length > 0" v-for="(item, index) in newHrList" :key="'newHrList' + index">
            <hr-item :record-id="item.recordId" :avatar-url="item.hrAvatar" :gender="item.hrGender"
                     :hr-id="item.hrId" :name="item.hrName" :time="item.applyTime"
                     :apply-status="item.applyStatus"
                     @action="action"></hr-item>
          </div>
          <div v-if="newHrList.length <= 0" class="empty">
            空
          </div>
        </div>
      </div>

      <div class="card">
        <div class="title">
          人员管理
        </div>
        <div class="content" v-loading="pageLoading2">

          <div class="content-item"  v-if="oldHrList && oldHrList.length > 0" v-for="(item2, index2) in oldHrList" :key="'oldHrList' + index2">
            <hr-item :avatar-url="item2.hrAvatar" :gender="item2.hrGender" :hr-id="item2.hrId" :name="item2.hrName" :isApplication="true"></hr-item>
          </div>
          <div v-if="oldHrList.length <= 0" class="empty">
            空
          </div>

        </div>
      </div>

    </div>

    <div class="right">

      <dynamic-entry @goEnterpriseDynamic="changeCurrNavPage"></dynamic-entry>

    </div>

  </div>
</template>

<script>
import DynamicEntry from "@/components/dynamicEntry/dynamicEntry";
import HrItem from "@/components/hrItem/hrItem";
import {GetHrCheckJoinEnterpriseApplyApi, PostHrCheckJoinEnterpriseApplyApi} from '@/request/api'
import share from "@/share/share";
export default {
  name: "hrManagement",
  components: {HrItem, DynamicEntry},
  data() {
    return {
      pageLoading1: false,
      pageLoading2: false,
      pageNum: 1,
      pageSize: 100000,
      newHrList: [],
      oldHrList: [],
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.pageLoading1 = true
      this.pageLoading2 = true
      const res = await GetHrCheckJoinEnterpriseApplyApi({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      })

      if (res) {
        let newHrList = []
        let oldHrList = []
        res.recordList.forEach((item, index) => {
          newHrList.push(item)
          if (item.applyStatus === 3) {
            oldHrList.push(item)
          }
        })
        this.newHrList = newHrList
        this.oldHrList = oldHrList
      }
      this.pageLoading1 = false
      this.pageLoading2 = false
    },
    changeCurrNavPage(key, index) {
      this.$router.push({
        path: key,
        query: {
          index: index
        }
      })
    },
    async action(state, id, recordId) {
      this.pageLoading1 = true
       const res = await PostHrCheckJoinEnterpriseApplyApi({
        action: state,
        recordId: recordId
      })

      this.pageLoading1 = false
      if (res) {
        share.message.success('操作成功')
        await this.loadData()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.hrManagement_warp {
  width: 100%;
  //height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #f4f2f3;

  .left {
    width: 780px;
    //height: 100%;
    background-color: #f4f2f3;

    .card {
      width: 100%;
      background-color: #ffffff;
      margin-bottom: 20px;

      .title {
        height: 40px;
        border-bottom: 1px solid #d2d2d2;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0px 20px;
        box-sizing: border-box;
      }

      .content {
        width: 100%;
        max-height: 600px;
        overflow-y: scroll;

        .content-item {
          width: 100%;
          height: 100px;
          border-bottom: 1px solid #efefef;
        }

        .empty {
          width: 100%;
          height: 400px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          font-weight: bold;
          color: #e1e1e1;
        }
      }
    }

  }

  .right {
    flex: 1;
    //min-height: 600px;
    margin-left: 10px;
    background-color: #f4f2f3;
  }
}
</style>
