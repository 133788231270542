import Vue from 'vue'
import {
    Button,
    Form,
    FormItem,
    Input,
    RadioGroup,
    Radio,
    DatePicker,
    Checkbox,
    Message,
    Cascader,
    Menu,
    MenuItem,
    Avatar,
    Tabs,
    TabPane,
    Pagination,
    Backtop,
    Tag,
    Dialog,
    Upload,
    Select,
    Option,
    Carousel,
    CarouselItem,
    Row,
    Col,
    Table,
    TableColumn,
    Loading,
    Timeline,
    TimelineItem,
    Steps,
    Step,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Popover,
    RadioButton,
    Autocomplete,
    Popconfirm,
    Tooltip,
    ButtonGroup,
    Image,
    Slider,
    Switch,
    Alert,
    CheckboxGroup,
    TimePicker,
    TimeSelect,
} from 'element-ui'

Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(DatePicker)
Vue.use(Checkbox)
Vue.use(Cascader)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Avatar)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Pagination)
Vue.use(Backtop)
Vue.use(Tag)
Vue.use(Dialog)
Vue.use(Upload)
Vue.use(Select)
Vue.use(Option)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Row)
Vue.use(Col)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Popover)
Vue.use(RadioButton)
Vue.use(Autocomplete)
Vue.use(Popconfirm)
Vue.use(Loading)
Vue.use(Tooltip)
Vue.use(ButtonGroup)
Vue.use(Image)
Vue.use(Slider)
Vue.use(Switch)
Vue.use(Alert)
Vue.use(CheckboxGroup)
Vue.use(TimePicker)
Vue.use(TimeSelect)

Vue.prototype.$loading = Loading.service
Vue.prototype.$message = Message

// 这一段代码不能少
// 这里注意一下，我用的$msg，所以我在使用的时候也是用的this.$msg
// Vue.prototype.$message = function(msg){
//     return Message({
//         message:msg,
//         duration:1500,
//         showClose: true,
//     })
// }
// 分别对success、warning和error等样式进行设置
// Vue.prototype.$message.success = function (msg) {
//     return Message.success({
//         message: msg,
//         duration: 1500,
//         showClose: true,
//     })
// }
// Vue.prototype.$message.warning = function (msg) {
//     return Message.warning({
//         message: msg,
//         duration: 1500,
//         showClose: true,
//     })
// }

// Vue.prototype.$message.error = function (msg) {
//     return Message.error({
//         message: msg,
//         duration: 1500,
//         showClose: true,
//     })
// }