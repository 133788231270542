<template>
  <div class="search_warp">
    <!-- 搜索页头部区域 -->
    <div class="header">
<!--      <page-header :curr-page="currHeaderMenu" backgroundColor="#eeeeee" @actionClick="actionClick" @changeCurrPage="changeCurrPage"></page-header>-->
      <page-header :curr-page="currHeaderMenu" @changeCurrPage="changeCurrPage" backgroundColor="#eeeeee"></page-header>
    </div>

    <!-- 主体内容区域 -->
    <div class="content">

      <!-- 主体内容头部区域 -->
      <div class="content-top">

        <!-- 主体内容中的主要内容区域 -->
        <div class="main">

          <!-- logo区域 -->
          <div class="logo">
            <img src="@/assets/icon/logo.png" />
          </div>

          <!-- 搜索框区域 -->
          <div class="search">
            <search-input @searchValueInput="searchValueInput" @searchSubmit="searchSubmit"></search-input>
          </div>

          <!-- 快捷入口区域 -->
          <div class="action">
            <div class="action-item" @click="jobHunting">
              <div class="action-item-icon">
                <img src="@/assets/icon/work.png">
              </div>
              <div class="action-item-title">
                找工作
              </div>
            </div>
            <div class="action-item" @click="getCash">
              <div class="action-item-icon">
                <img src="@/assets/icon/cash.png">
              </div>
              <div class="action-item-title">
                领现金
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="content-bottom"></div>

    </div>
  </div>
</template>

<script>
/* 引用头部组件 */
import PageHeader from "@/components/pageHeader/pageHeader";
import SearchInput from "@/components/searchInput/searchInput";
import {isEditedResumeApi} from '@/request/api'
import {mapMutations} from "vuex";
import mixin from '@/mixin/mixin'
export default {
  name: "search",
  mixins: [mixin],
  components: {SearchInput, PageHeader},
  data() {
    return {
      searchValue: '',  // 当前用户输入的搜索内容
      currHeaderMenu: 1,
    }
  },
  computed: {
    token() {
      return this.$store.state.token
    },
    isLogin() {
      let token = this.$store.state.token
      let state = false
      if (token && token !== '' && token !== null && token !== 'null') {
        state = true
      }
      return state
    },
    userType() {
      return this.$store.state.userInfo.userType || ''
    },
    aonuoChat() {
      return this.$store.state.aonuoChat
    },
    aonuoChatReady() {
      return this.$store.state.aonuoChatReady
    }
  },
  watch: {
    aonuoChatReady: {
      handler: function () {
        if (this.aonuoChatReady && this.isLogin) {
          this.loadMessageUnreadCount()
        }
      },
      immediate: true
    }
  },
  created() {
  },
  mounted() {
    if (this.isLogin && this.userType === 'employee') {
      this.loadIsEditedResume()
    }

  },
  methods: {
    ...mapMutations(['setIsEditedResume', 'setShareId', 'setShareArea', 'setInvitationCode', 'setMessageUnreadCount']),

    async loadMessageUnreadCount() {
      const res = await this.aonuoChat.getMessageUnreadCount()
      if (res) {
        this.setMessageUnreadCount({
          messageUnreadCount: res.unread_count
        })
      }
    },

    async loadIsEditedResume() {
      const res = await isEditedResumeApi()

      if (res) {
        this.setIsEditedResume({
          isEditedResume: res.isEditedResume
        })
      }
    },
    changeCurrPage(num) {
      this.currHeaderMenu = num
    },
    searchValueInput(searchValue) {
      this.searchValue = searchValue
    },
    searchSubmit() {
      if (this.searchValue === '') {
        return
      }
      this.$router.push({
        path: '/unfoldJobHunting',
        query: {
          searchValue: this.searchValue,
          jobType: '1'
        }
      })
    },
    jobHunting() {
      this.$router.push({
        path: '/jobHunting'
      })
    },
    getCash() {
      this.$router.push({
        path: '/wealthWithdrawal'
      })
    },

  }
}
</script>

<style lang="less" scoped>
/* 搜索页样式 */
.search_warp {
  width: 100%;
  min-width: 1400px;
  //height: 100%;
  /* 搜索页头部样式 */
  .header {
    width: 100%;
    height: 61px;
  }

  /* 搜索页主体内容样式 */
  .content {
    width: 100%;
    height: calc(100% - 61px);
    padding-top: 80px;
    box-sizing: border-box;

    /* 主体内容上半部样式 */
    .content-top {
      width: 100%;
      height: 60%;
      //min-height: 400px;
      //min-width: 800px;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      /* 主体内容的主要内容样式 */
      .main {
        width: 700px;
        height: 350px;

        /* 图标 */
        .logo {
          width: 40%;
          margin: 0 auto;

          img {
            max-width: 100%;
          }
        }

        /* 快捷入口样式 */
        .action {
          width: 100%;
          display: flex;
          justify-content: center;

          .action-item {
            width: 80px;
            height: 100%;
            margin: 20px 20px;
            cursor: pointer;

            .action-item-icon {
              width: 50px;
              height: 50px;
              margin: 0 auto;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .action-item-title {
              width: 100%;
              text-align: center;
              color: #292929;
            }

          }
        }

      }

      /* 搜索框 */
      .search {
        width: 100%;
        height: 100px;
      }

    }

    .content-bottom {
      width: 100%;
      height: 40%;
    }

  }
}
</style>
