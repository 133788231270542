<template>
  <div class="jobFilter_warp">
    <template v-for="(item, index) in options">
      <div class="item" v-if="item.options && item.options.length > 0" :key="'item' + index">
        <job-filter-item @jobFilterChange="jobFilterChange" :options="item"
                         :value="filterArr[index].oldValue"></job-filter-item>
      </div>
    </template>
  </div>
</template>

<script>
import JobFilterItem from "@/components/jobFilter/jobFilterItem";
import {
  industry,
  jobType,
  salaryRange,
  fringeBenefits,
  education,
  ageRange,
  experience,
  enterpriseScale,
  financingSituation,
  jobSearchingStatus,
} from '@/share/shareData'
import {GetResumeEditApi} from '@/request/api'

export default {
  name: "jobFilter",
  components: {JobFilterItem},
  data() {
    return {
      industry: [],
      options: [],
      isInit: false,
      initValue: null,
      filterArr: [],
    }
  },
  computed: {
    userType() {
      return this.$store.state.userInfo.userType
    },
    token() {
      return this.$store.state.token
    }
  },
  created() {

  },
  async mounted() {

    if (this.userType === 'hr') {
      this.options = [
        // {
        //   placeholder: '岗位类别（行业）',
        //   multiple: true,
        //   options: []
        // },
        // {
        //   placeholder: '岗位性质（兼职、长期）',
        //   multiple: true,
        //   options: []
        // },
        {
          placeholder: '薪资范围',
          multiple: true,
          options: []
        },
        {
          placeholder: '学历要求',
          multiple: true,
          options: []
        },
        // {
        //   placeholder: '年龄范围',
        //   multiple: false,
        //   options: []
        // },
        {
          placeholder: '经验要求',
          multiple: true,
          options: []
        },
        {
          placeholder: '求职状态',
          multiple: true,
          options: []
        },
      ]

      this.filterArr = [
        // {
        //   label: '岗位类别（行业）',
        //   key: 'jobType',
        //   value: [],
        //   oldValue: [],
        // },
        // {
        //   label: '岗位性质（兼职、长期）',
        //   key: 'jobName',
        //   value: [],
        //   oldValue: [],
        // },
        {
          label: '薪资范围',
          key: 'salaryRangeList',
          value: [],
          oldValue: [],
        },
        {
          label: '学历要求',
          key: 'academicRequirement',
          value: [],
          oldValue: [],
        },
        // {
        //   label: '年龄范围',
        //   key: 'ageRequirement',
        //   value: [],
        //   oldValue: [],
        // },
        {
          label: '经验要求',
          key: 'experienceRequirementList',
          value: [],
          oldValue: [],
        },
        {
          label: '求职状态',
          key: 'jobSearchingStatus',
          value: [],
          oldValue: [],
        },
      ]
    }else {
      if (this.token && this.token !== 'null') {
        await this.loadResumeData()
      }

      this.options = [
        {
          placeholder: '期望岗位',
          multiple: true,
          options: []
        },
        {
          placeholder: '岗位性质（兼职、长期）',
          multiple: true,
          options: []
        },
        {
          placeholder: '薪资范围',
          multiple: true,
          options: []
        },
        {
          placeholder: '福利待遇',
          multiple: true,
          options: []
        },
        {
          placeholder: '学历要求',
          multiple: true,
          options: []
        },
        // {
        //   placeholder: '年龄范围',
        //   multiple: true,
        //   options: []
        // },
        {
          placeholder: '经验要求',
          multiple: true,
          options: []
        },
        {
          placeholder: '企业规模',
          multiple: true,
          options: []
        },
        // {
        //   placeholder: '企业融资状态',
        //   multiple: false,
        //   options: []
        // },
      ]

      this.filterArr = [
        {
          label: '期望岗位',
          key: 'jobCategory',
          value: [],
          oldValue: [],
        },
        {
          label: '岗位性质（兼职、长期）',
          key: 'jobProperty',
          value: [],
          oldValue: [],
        },
        {
          label: '薪资范围',
          key: 'salaryRangeList',
          value: [],
          oldValue: [],
        },
        {
          label: '福利待遇',
          key: 'welfare',
          value: [],
          oldValue: [],
        },
        {
          label: '学历要求',
          key: 'academicRequirement',
          value: [],
          oldValue: [],
        },
        // {
        //   label: '年龄范围',
        //   key: 'ageRequirement',
        //   value: [],
        //   oldValue: [],
        // },
        {
          label: '经验要求',
          key: 'experienceRequirementList',
          value: [],
          oldValue: [],
        },
        {
          label: '企业规模',
          key: 'enterpriseSize',
          value: [],
          oldValue: [],
        },
        // {
        //   label: '企业融资状态',
        //   key: 'financingStatus',
        //   value: [],
        //   oldValue: [],
        // }
      ]

      if (this.isInit) {

        this.filterArr.forEach((item, index) => {
          if (this.initValue[item.key]) {
            item.value = this.initValue[item.key]
            item.oldValue = this.initValue[item.key]
          }
        })

        this.$emit('jobFilterChange', this.filterArr)
        this.initValue = null
        this.isInit = false
      }
    }

    if (this.userType === 'hr') {
      // this.options[0].options = industry  // 行业
      // this.options[0].options = jobType  // 性质
      this.options[0].options = salaryRange  // 薪资范围
      this.options[1].options = education  // 学历要求
      // this.options[2].options = ageRange  // 年龄范围
      this.options[2].options = experience  // 经验要求
      this.options[3].options = jobSearchingStatus  // 求职状态
    }else {
      this.options[0].options = this.industry  // 行业
      this.options[1].options = jobType  // 性质
      this.options[2].options = salaryRange  // 薪资范围
      this.options[3].options = fringeBenefits  // 福利待遇
      this.options[4].options = education  // 学历要求
      // this.options[5].options = ageRange  // 年龄范围
      this.options[5].options = experience  // 经验要求
      this.options[6].options = enterpriseScale  // 企业规模
      // this.options[8].options = financingSituation  // 企业融资状态
    }
  },
  methods: {
    init(obj) {
      this.filterArr.forEach((item, index) => {
        if (obj[item.key]) {
          item.value = obj[item.key]
          item.oldValue = obj[item.key]
        }
      })
      if (this.userType === 'employee') {
        this.isInit = true
        this.initValue = obj
      }
    },
    async loadResumeData() {
      const res = await GetResumeEditApi()
      if (res) {
        let industry = res.employeeInformation.expectedJobList
        if (industry && industry.length > 0) {
          industry.forEach((item, index) => {
            this.industry.push({
              label: item,
              value: item
            })
          })
        }
      }
    },
    clear() {
      this.filterArr.forEach((item, index) => {
        item.value = []
        item.oldValue = []
      })
      this.$emit('jobFilterChange', this.filterArr)
    },
    jobFilterChange(arr, oldArr, label) {
      this.filterArr.forEach((item, index) => {
        if (item.label === label) {
          item.value = arr
          item.oldValue = oldArr
        }
      })
      this.$emit('jobFilterChange', this.filterArr)
    }
  }
}
</script>

<style lang="less" scoped>
.jobFilter_warp {
  width: 100%;
  height: 100%;

  .item {
    margin: 20px 0px;
  }
}
</style>
