<template>
  <div class="newJobPromotion_warp" v-loading="pageLoading">

    <div class="promotional-coins">
      <div class="promotional-coins-title">
        <div class="icon">
          <img src="@/assets/icon/wallet3.png">
        </div>
        <div class="text">
          推广币剩余
        </div>
      </div>
      <div class="promotional-coins-main">
        <div class="amount">
          {{promote_coin}}
        </div>
        <div class="btn">
          <el-button @click="toByPromotionalCoins">充 值</el-button>
        </div>
      </div>
    </div>

    <div class="header">
      <div class="title" v-if="!isModifyStatus">
        设置岗位推广
      </div>
      <div class="title" v-if="isModifyStatus">
        修改岗位推广
      </div>
    </div>
    <div class="content">
      <el-form ref="newJobPromotionFormRef" :model="jobPromotion" :rules="formRules" label-width="0px">

        <div class="block">
          <div class="block-title">
            推广职位
          </div>
          <div class="block-content">

            <div class="block-content-item">
              <el-form-item label="" prop="selectJob">
                <div class="select-job block-form-item">
                  <div class="label"><span class="required">*</span>推广岗位</div>
<!--                  <el-select :disabled="isModifyStatus" v-model="jobPromotion.selectJob" placeholder="请选择">-->
<!--                    <el-option-->
<!--                        v-for="item in jobsOptions"-->
<!--                        :key="item.value"-->
<!--                        :label="item.label"-->
<!--                        :value="item.value">-->
<!--                    </el-option>-->
<!--                  </el-select>-->
                  <lazy-select :options="jobsOptions" :auto-complete="true" placeholder="请选择" :disabled="isModifyStatus" :new-value="jobPromotion.selectJob" :label="jobName" :no-more="jobOptionsNoMore" @loadData="loadMoreMyPublishedJob" @change="selectJobChange"></lazy-select>
                </div>
              </el-form-item>
            </div>

          </div>
        </div>

        <div class="block">
          <div class="block-title">
            推广范围
          </div>
          <div class="block-content">

            <div class="block-content-item">
              <el-form-item label="" prop="promotionArea">
                <div class="promotion-area block-form-item">
                  <div class="label">
                    <div>
                      <span class="required">*</span>推广地区
                    </div>
                    <div>
                      <el-checkbox label="全选" v-model="promotionAreaIsSelectAll" :indeterminate="areaIsIndeterminate" @change="promotionAreaIsSelectAllChange"></el-checkbox>
                    </div>
                  </div>
                  <div class="areas">
                    <el-checkbox-group v-model="jobPromotion.promotionArea" size="mini" @change="promotionAreaChange">
                      <template  v-for="(item, index) in areasOptions">
                        <el-checkbox :label="item.value" border :key="'areasOptions' + index">{{item.label}}</el-checkbox>
                      </template>
                    </el-checkbox-group>
                  </div>
                </div>
              </el-form-item>
            </div>

            <div class="block-content-item">
              <el-form-item label="" prop="promotionJobCategory">
                <div class="promotion-area block-form-item">
                  <div class="label">
                    <div>
                      <span class="required">*</span>推广类别
                    </div>
                    <div>
                      <el-checkbox label="全选" v-model="promotionJobCategoryIsSelectAll" :indeterminate="jobCategoryIsIndeterminate" @change="promotionJobCategoryIsSelectAllChange"></el-checkbox>
                    </div>
                  </div>
                  <div class="areas">
                    <el-checkbox-group v-model="jobPromotion.promotionJobCategory" size="mini" @change="promotionJobCategoryChange">
                      <template  v-for="(item, index) in jobCategoryOptions">
                        <el-checkbox :label="item.value" :key="'jobCategory' + index">{{item.label}}</el-checkbox>
                      </template>
                    </el-checkbox-group>
                  </div>
                </div>
              </el-form-item>
            </div>

          </div>
        </div>

        <div class="block">
          <div class="block-title">
            推广预算
          </div>
          <div class="block-content">

            <div class="block-content-item">
              <el-form-item label="" prop="promotionBudget">
                <div class="promotion-budget block-form-item">
                  <div class="average-price row">
                    <div class="label">预计平均价格</div>
                    <div class="value">1.00元/点击</div>
                  </div>
                  <div class="daily-budget row">
                    <div class="label"><span class="required">*</span>单日预算</div>
                    <div class="value">
                      <el-input type="number" placeholder="请输入内容" v-model="jobPromotion.promotionBudget" @blur="promotionBudgetBlur">
                        <template slot="append">元</template>
                      </el-input>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </div>

          </div>
        </div>

        <div class="block">
          <div class="block-title">
            日程管理
          </div>
          <div class="block-content">

            <div class="block-content-item">
              <el-form-item label="">
                <div class="schedule-management block-form-item">
                  <div class="daily-budget row">
                    <div class="label">开始日期</div>
                    <div class="value">购买成功时开始</div>
                  </div>
                  <div class="daily-budget row">
                    <div class="label">结束日期</div>
                    <div class="value">
                      <el-date-picker
                          v-model="jobPromotion.endDate"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          :picker-options="pickerOptions"
                          type="date"
                          placeholder="默认持续推广"
                          clearable
                      >
                      </el-date-picker>
                    </div>
                  </div>
                </div>
                <div class="schedule-management block-form-item">
                  <div class="daily-budget row">
                    <div class="label">推广周期</div>
                    <div class="value">
                      <el-radio-group v-model="jobPromotion.promotionPeriod">
                        <el-radio :label="1">每天</el-radio>
                        <el-radio :label="2">工作日</el-radio>
                        <el-radio :label="3">双休日</el-radio>
                      </el-radio-group>
                    </div>
                  </div>
                </div>
                <div class="schedule-management block-form-item">
                  <div class="daily-budget row">
                    <div class="label">推广时间</div>
                    <div class="value">
                      <el-radio-group v-model="jobPromotion.promotionTimeMode">
                        <el-radio :label="1">全部时间</el-radio>
                        <el-radio :label="2">早九点-晚九点</el-radio>
                        <el-radio :label="3">自定义
                          <el-time-select
                              placeholder="起始时间"
                              v-model="jobPromotion.timeHorizon[0]"
                              :picker-options="{
                                start: '00:00',
                                step: '01:00',
                                end: '23:00',
                              }">
                          </el-time-select>
                          至
                          <el-time-select
                              placeholder="结束时间"
                              v-model="jobPromotion.timeHorizon[1]"
                              :picker-options="{
                                start: '00:00',
                                step: '01:00',
                                end: '23:00',
                                minTime: jobPromotion.timeHorizon[0],
                              }">
                          </el-time-select>
                        </el-radio>
                      </el-radio-group>

                    </div>
                  </div>
                </div>
              </el-form-item>
            </div>

          </div>
        </div>

        <div class="block2">
          <div class="block-title">
            优惠劵
          </div>
          <div class="block-content">
            当前无可用优惠卷
          </div>
        </div>

        <div class="agreement">
          <el-checkbox v-model="isRead">我已阅读并同意<span class="link" @click="toRulesPage('Service_Agreement')">《{{AllAgreement.Service_Agreement}}》</span>及
            <span class="link" @click="toRulesPage('Promotion_Description')">《{{AllAgreement.Promotion_Description}}》</span></el-checkbox>
        </div>

      </el-form>
    </div>
    <div class="footer">
      <div class="btn">
        <el-button type="primary" @click="goBack">返 回</el-button>
      </div>
      <div class="btn">
        <el-button type="primary" v-if="!isModifyStatus" :loading="btnLoading" @click="newJobPromotionSubmit">立即推广</el-button>
        <el-button type="primary" v-if="isModifyStatus" :loading="btnLoading" @click="modifyJobPromotionSubmit">提 交</el-button>
      </div>
    </div>

    <el-dialog
        title="购买推广币"
        :visible.sync="byPromotionalCoinsDialogVisible"
        width="600px"
        :before-close="byPromotionalCoinsDialogClose"
        custom-class="by-promotional-coins"
    >
      <div class="by-promotional-coins-content">
        <div class="content">
          <div class="content-title">
            注：<span>1 推广币</span>=<span>1 RMB</span>
          </div>
          <div class="main">

            <el-row :gutter="20">

              <el-col :span="12">
                <div :class="checkedId === 1?'main-item main-item-checked':'main-item'" @click="itemChecked(1, 100)">
                  <span>100 RMB</span>
                </div>
              </el-col>

              <el-col :span="12">
                <div :class="checkedId === 2?'main-item main-item-checked':'main-item'" @click="itemChecked(2, 200)">
                  <span>200 RMB</span>
                </div>
              </el-col>

              <el-col :span="12">
                <div :class="checkedId === 3?'main-item main-item-checked':'main-item'" @click="itemChecked(3, 2000)">
                  <span>2000 RMB</span>
                </div>
              </el-col>

              <el-col :span="12">
                <div :class="checkedId === 4?'main-item main-item-checked':'main-item'" @click="itemChecked(4, 5000)">
                  <span>5000 RMB</span>
                </div>
              </el-col>

              <el-col :span="24">
                <div :class="checkedId === 8?'main-item main-item-checked':'main-item'" @click="itemChecked(8)">
                  <el-input type="number" v-model="customAmount" placeholder="自定义充值数额" :min="10">
                    <!--                <template slot="prepend">¥</template>-->
                    <!--                <template slot="append">元</template>-->
                  </el-input>
                </div>
              </el-col>

            </el-row>

          </div>

        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="byPromotionalCoinsDialogClose">取 消</el-button>
        <el-button type="primary" :loading="payLoading" @click="byPromotionalCoinsDialogSubmit">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {
  GetJobPromoteBuyPromoteCoinApi, GetJobPromoteJobPromoteDetailApi, JobHrSeeJobDetailApi,
  MyPublishedJobApi,
  PostJobPromoteAddJobPromoteApi,
  PostJobPromoteBuyPromoteCoinApi, PostJobPromoteEditJobPromoteApi
} from "@/request/api";
import {formRules} from "@/share/formRules";
import {AllAgreement, areas, industry} from "@/share/shareData";
import {intRegular} from "@/share/regularExpression";
import share from "@/share/share";
import {ALL_RULES} from "@/config/baseURL";
import LazySelect from "@/components/lazySelect/lazySelect";

export default {
  name: "newJobPromotion",
  components: {LazySelect},
  data() {
    return {
      AllAgreement: AllAgreement,
      formRules: formRules,
      industryList: industry,

      isModifyStatus: false,
      jobPromoteId: '',

      byPromotionalCoinsDialogVisible: false,
      pageLoading: false,
      btnLoading: false,
      payLoading: false,
      promote_coin: 0,
      jobList: [],
      jobsOptions: [],
      jobOptionsNoMore: true,
      jobOptionsPage: {
        pageNumber: 1,
        pageSize: 20,
      },
      jobName: '',
      jobPromotion: {
        selectJob: '',
        promotionArea: [],
        promotionJobCategory: [],
        promotionBudget: '',
        endDate: '',
        promotionPeriod: 1,  // 推广周期
        timeHorizon: ['09:00', '17:00'],
        promotionTimeMode: 1
      },
      areasOptions: [],
      jobCategoryOptions: [],
      promotionAreaIsSelectAll: false,  // 地区全选是否是选中状态
      promotionJobCategoryIsSelectAll: false,  // 岗位类别全选是否是选中状态
      areaIsIndeterminate: false,  // 地区全选是否是半选状态
      jobCategoryIsIndeterminate: false,  // 岗位类别全选是否是半选状态
      jobCategory: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < (Date.now() - 24 * 60 * 60 * 1000)
        }
      },

      checkedId: 1,
      customAmount: '',
      amount: 100,

      isRead: false,
    }
  },
  watch: {
    'jobPromotion.selectJob': {
      handler:async function () {
        if (this.jobPromotion.selectJob) {
          this.promotionAreaIsSelectAll = false
          this.promotionJobCategoryIsSelectAll = false
          this.areaIsIndeterminate = false
          this.jobCategoryIsIndeterminate = false
          this.jobPromotion.promotionArea = []
          this.jobPromotion.promotionJobCategory = []

          const res2 = await JobHrSeeJobDetailApi({
            jobId: this.jobPromotion.selectJob,
          })
          if (res2 && res2.jobInformation && !res2.jobInformation.isClose && res2.jobInformation.jobStatus === 3) {
            let jobAddressCode = res2.jobInformation.jobAddressCode
            let province = this.getArrCode(areas, jobAddressCode[0])
            let city = this.getArrCode(province.children, jobAddressCode[1])
            this.areasOptions = city.children
            this.jobCategory = res2.jobInformation.jobCategory
            this.jobCategoryOptions = this.getJobCategoryList(this.jobCategory)
          }
        }
      },
    },
    'jobPromotion.timeHorizon': {
      handler: function () {
      }
    }
  },
  async mounted() {
    this.pageLoading = true

    // await this.loadMyPublishedJobData()
    // await this.getJobPromoteBuyPromoteCoin()

    if (this.$route.query.jobId) {
      this.jobPromotion.selectJob = this.$route.query.jobId
    }
    if (this.$route.query.isModifyStatus === 'yes') {
      this.isModifyStatus = true
      this.jobPromoteId = this.$route.query.jobPromoteId
      await this.loadMyPublishedJobData()
      await this.loadData2()
    }else {
      await this.loadData()
    }

    this.pageLoading = false
  },
  computed: {
  },
  methods: {
    async loadData() {
      const res = await Promise.all([this.loadMyPublishedJobData(), this.getJobPromoteBuyPromoteCoin()])
    },
    async loadData2() {
      const res = await Promise.all([this.getJobPromoteJobPromoteDetail(), this.getJobPromoteBuyPromoteCoin()])
    },
    /**
     * 岗位推广详情
     * */
    async getJobPromoteJobPromoteDetail() {
      const res = await GetJobPromoteJobPromoteDetailApi({
        jobPromoteId: this.jobPromoteId
      })

      if (res) {
        this.jobPromotion.selectJob = res.jobPromote.job.id

        this.$nextTick(() => {
          let dailyBudget = 0
          if (res.jobPromote.dailyBudget) {
            dailyBudget = parseFloat(res.jobPromote.dailyBudget)
          }
          this.jobPromotion.promotionArea = res.jobPromote.promoteAreaCodeList
          this.jobPromotion.promotionJobCategory = res.jobPromote.promoteCategory
          this.jobPromotion.promotionBudget = dailyBudget
          this.jobPromotion.endDate = res.jobPromote.endDate
          this.jobPromotion.promotionPeriod = res.jobPromote.promoteCycle
          if (res.jobPromote.promoteTime && res.jobPromote.promoteTime.length > 0) {
            if (res.jobPromote.promoteTime[0] === 9 && res.jobPromote.promoteTime[1] === 21) {
              this.jobPromotion.promotionTimeMode = 2
            }else {
              let time1 = '', time2 = ''
              if (parseInt(res.jobPromote.promoteTime[0]) <= 9) {
                time1 = '0' + parseInt(res.jobPromote.promoteTime[0]) + ':' + '00'
              }else {
                time1 = parseInt(res.jobPromote.promoteTime[0]) + ':' + '00'
              }
              if (parseInt(res.jobPromote.promoteTime[1]) <= 9) {
                time2 = '0' + parseInt(res.jobPromote.promoteTime[1]) + ':' + '00'
              }else {
                time2 = parseInt(res.jobPromote.promoteTime[1]) + ':' + '00'
              }
              this.jobPromotion.timeHorizon = [time1, time2]
              this.jobPromotion.promotionTimeMode = 3

            }
          }else {
            this.jobPromotion.promotionTimeMode = 1
          }

          if (this.areasOptions.length > 0 && this.areasOptions.length === this.jobPromotion.promotionArea.length){
            this.promotionAreaIsSelectAll = true
            this.areaIsIndeterminate = false
          }else if (this.areasOptions.length > 0 && this.jobPromotion.promotionArea.length > 0) {
            this.promotionAreaIsSelectAll = false
            this.areaIsIndeterminate = true
          }else {
            this.promotionAreaIsSelectAll = false
            this.areaIsIndeterminate = false
          }

          if (this.jobCategoryOptions.length > 0 && this.jobCategoryOptions.length === this.jobPromotion.promotionJobCategory.length){
            this.promotionJobCategoryIsSelectAll = true
            this.jobCategoryIsIndeterminate = false
          }else if (this.jobCategoryOptions.length > 0 && this.jobPromotion.promotionJobCategory.length > 0) {
            this.promotionJobCategoryIsSelectAll = false
            this.jobCategoryIsIndeterminate = true
          }else {
            this.promotionJobCategoryIsSelectAll = false
            this.jobCategoryIsIndeterminate = false
          }
        })

      }
      return res
    },
    /**
    * 获取我发布的岗位
    * */
    async loadMyPublishedJobData() {
      const res = await MyPublishedJobApi({
        pageNum: this.jobOptionsPage.pageNumber,
        pageSize: this.jobOptionsPage.pageSize,
        jobStatus: 3,
      })

      if (res) {
        if (this.jobOptionsPage.pageNumber >= res.numOfPages) {
          this.jobOptionsNoMore = true
        }else {
          this.jobOptionsNoMore = false
        }

        let arr = res.jobList
        this.jobList = res.jobList
        let jobList = []
        arr.forEach((item, index) => {
          let obj = {
            value: item.jobId,
            label: item.jobName
          }
          jobList.push(obj)
        })
        this.jobsOptions = jobList

        if (jobList.length > 0) {
          if (this.jobPromotion.selectJob) {
            const res2 = await JobHrSeeJobDetailApi({
              jobId: this.jobPromotion.selectJob,
            })
            if (res2 && res2.jobInformation && !res2.jobInformation.isClose && res2.jobInformation.jobStatus === 3) {
              this.jobPromotion.selectJob = res2.jobInformation.jobId
              this.jobName = res2.jobInformation.jobName
            }else {
              this.jobPromotion.selectJob = jobList[0].value
              this.jobName = jobList[0].label
            }
          }else {
            this.jobPromotion.selectJob = jobList[0].value
            this.jobName = jobList[0].label
          }
        }
      }
      this.jobOptionsPage.pageNumber++
      return res
    },
    async loadMoreMyPublishedJob(callback) {
      if (this.jobOptionsNoMore) {
        return;
      }
      const res = await MyPublishedJobApi({
        pageNum: this.jobOptionsPage.pageNumber,
        pageSize: this.jobOptionsPage.pageSize,
        jobStatus: 3,
      })
      if (res) {
        if (this.jobOptionsPage.pageNumber >= res.numOfPages) {
          this.jobOptionsNoMore = true
        }else {
          this.jobOptionsNoMore = false
        }
        this.jobList = this.jobList.concat(res.jobList)

        let jobList = []
        res.jobList.forEach((item, index) => {
          let obj = {
            value: item.jobId,
            label: item.jobName
          }
          jobList.push(obj)
        })
        this.jobsOptions = this.jobsOptions.concat(jobList)
      }
      this.jobOptionsPage.pageNumber++
      callback()
    },
    selectJobChange(value) {
      this.jobPromotion.selectJob = value
      this.jobsOptions.forEach((item, index) => {
        if (item.value === value) {
          this.jobName = item.label
        }
      })
    },
    /**
     * 获取我的推广币余额
     * */
    async getJobPromoteBuyPromoteCoin() {
      const res = await GetJobPromoteBuyPromoteCoinApi()

      if (res) {
        this.promote_coin = res.promote_coin.coin
      }
      return res
    },

    getArrCode(arr, code) {
      let value = {}
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].value === code) {
          value = arr[i]
          break
        }
      }
      return value
    },

    toByPromotionalCoins() {
      this.byPromotionalCoinsDialogVisible = true
    },

    itemChecked(id, amount) {
      this.checkedId = id
      if (this.checkedId !== 8) {
        this.amount = amount
      }
    },
    goBack() {
      this.$router.go(-1)
    },

    async promotionBudgetBlur() {
      await this.getJobPromoteBuyPromoteCoin()
      if (parseFloat(this.jobPromotion.promotionBudget) > parseFloat(this.promote_coin)) {
        share.message.warning('当前推广币余额不足')
        return false
      }else {
        return true
      }
    },
    /**
    * 新增岗位推广
    * */
    newJobPromotionSubmit() {
      this.$refs.newJobPromotionFormRef.validate(async (res) => {
        if (res) {

          let promoteTime = []
          if (this.jobPromotion.promotionTimeMode === 3) {
            let time1 = parseInt(this.jobPromotion.timeHorizon[0].split(':')[0])
            let time2 = parseInt(this.jobPromotion.timeHorizon[1].split(':')[0])
            if (time1 >= time2) {
              share.message.warning('自定义时间的结束时间要大于开始时间')
              return
            }
            promoteTime = [time1, time2]
          }else if (this.jobPromotion.promotionTimeMode === 2) {
            promoteTime = [9, 21]
          }

          if (!this.isRead) {
            share.message.warning(`请勾选我已阅读并同意《${AllAgreement.Service_Agreement}》及《${AllAgreement.Promotion_Description}》`)
            return;
          }

          this.btnLoading = true

          await this.getJobPromoteBuyPromoteCoin()
          if (parseFloat(this.jobPromotion.promotionBudget) > parseFloat(this.promote_coin)) {
            share.message.warning('推广失败,余额不足')
            this.btnLoading = false
            return;
          }

          const res = await PostJobPromoteAddJobPromoteApi({
            jobId: this.jobPromotion.selectJob,  // 岗位id
            dailyBudget: this.jobPromotion.promotionBudget,  // 每日预算
            promoteArea: JSON.stringify(this.jobPromotion.promotionArea),  // 推广地区
            promoteCategory: JSON.stringify(this.jobPromotion.promotionJobCategory),  // 推广类别
            endDate: this.jobPromotion.endDate,  // 结束日期
            promoteCycle: this.jobPromotion.promotionPeriod,  // 推广周期
            promoteTime: JSON.stringify(promoteTime),  // 推广时间
          })


          if (res) {
            share.message.success('设置成功')
            this.$router.push('/promotionManagement')
          }

          this.btnLoading = false
        }
      })
    },
    /**
     * 编辑岗位推广
     * */
    modifyJobPromotionSubmit() {
      this.$refs.newJobPromotionFormRef.validate(async (res) => {
        if (res) {
          let promoteTime = []
          if (this.jobPromotion.promotionTimeMode === 3) {
            let time1 = parseInt(this.jobPromotion.timeHorizon[0].split(':')[0])
            let time2 = parseInt(this.jobPromotion.timeHorizon[1].split(':')[0])
            if (time1 >= time2) {
              share.message.warning('自定义时间的结束时间要大于开始时间')
              return
            }
            promoteTime = [time1, time2]
          }else if (this.jobPromotion.promotionTimeMode === 2) {
            promoteTime = [9, 21]
          }

          if (!this.isRead) {
            share.message.warning(`请勾选我已阅读并同意《${AllAgreement.Service_Agreement}》及《${AllAgreement.Promotion_Description}》`)
            return;
          }

          this.btnLoading = true

          await this.getJobPromoteBuyPromoteCoin()
          if (parseFloat(this.jobPromotion.promotionBudget) > parseFloat(this.promote_coin)) {
            share.message.warning('推广失败,余额不足')
            this.btnLoading = false
            return;
          }

          const res = await PostJobPromoteEditJobPromoteApi({
            jobPromoteId: this.jobPromoteId,  // 岗位推广id
            dailyBudget: this.jobPromotion.promotionBudget,  // 每日预算
            promoteArea: JSON.stringify(this.jobPromotion.promotionArea),  // 推广地区
            promoteCategory: JSON.stringify(this.jobPromotion.promotionJobCategory),  // 推广类别
            endDate: this.jobPromotion.endDate,  // 结束日期
            promoteCycle: this.jobPromotion.promotionPeriod,  // 推广周期
            promoteTime: JSON.stringify(promoteTime),  // 推广时间
          })


          if (res) {
            share.message.success('修改成功')
            this.$router.push('/promotionManagement')
          }

          this.btnLoading = false
        }
      })
    },

    promotionAreaIsSelectAllChange() {
      if (this.promotionAreaIsSelectAll) {
        let arr = []
        this.areasOptions.forEach((item, index) => {
          arr.push(item.value)
        })
        this.jobPromotion.promotionArea = JSON.parse(JSON.stringify(arr))
      }else {
        this.jobPromotion.promotionArea = []
      }
      this.areaIsIndeterminate = false
    },

    promotionJobCategoryIsSelectAllChange() {
      if (this.promotionJobCategoryIsSelectAll) {
        let arr = []
        this.jobCategoryOptions.forEach((item, index) => {
          arr.push(item.value)
        })
        this.jobPromotion.promotionJobCategory = JSON.parse(JSON.stringify(arr))
      }else {
        this.jobPromotion.promotionJobCategory = []
      }
      this.jobCategoryIsIndeterminate = false
    },

    promotionAreaChange() {
      let checkedCount = this.jobPromotion.promotionArea.length
      this.promotionAreaIsSelectAll = checkedCount === this.areasOptions.length
      this.areaIsIndeterminate = checkedCount > 0 && checkedCount < this.areasOptions.length
    },

    promotionJobCategoryChange() {
      let checkedCount = this.jobPromotion.promotionJobCategory.length
      this.promotionJobCategoryIsSelectAll = checkedCount === this.jobCategoryOptions.length
      this.jobCategoryIsIndeterminate = checkedCount > 0 && checkedCount < this.jobCategoryOptions.length
    },

    getJobCategoryList(jobCategory) {
      return this.getIndustryList(this.industryList, jobCategory)
    },

    getIndustryList(arr, value) {
      let data = ''
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].value === value) {
          data = arr
          break
        }else if (arr[i].children && arr[i].children.length > 0) {
          data = this.getIndustryList(arr[i].children, value)
          if (data && data.length > 0) {
            break
          }
        }
      }
      return  data
    },

    byPromotionalCoinsDialogClose() {
      this.byPromotionalCoinsDialogVisible = false
      this.checkedId = 1
      this.customAmount = ''
      this.amount = 100
    },

    async byPromotionalCoinsDialogSubmit() {
      if (this.checkedId === 8) {
        this.amount = this.customAmount
      }

      let re = intRegular
      if (!re.test(this.amount)) {
        share.message.warning('金额只能为正整数')
        return
      }
      if (this.amount === '' || parseInt(this.amount) < 10) {
        share.message.warning('充值金额不能小于10元')
        return
      }
      this.payLoading = true

      const res = await PostJobPromoteBuyPromoteCoinApi({
        amount: this.amount
      })

      if (res) {
        share.message.success('购买成功')
      }
      await this.getJobPromoteBuyPromoteCoin()
      this.payLoading = false
      this.byPromotionalCoinsDialogClose()
    },

    /**
     * 去协议详情页
     * */
    toRulesPage(param) {
      window.open(ALL_RULES + '?type=' + param)
    },

  },

}
</script>

<style lang="less" scoped>
.newJobPromotion_warp {
  width: 100%;
  //height: 100%;
  background-color: #FFFFFF;

  .promotional-coins {
    width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;
    border-bottom: 20px solid #efefef;

    .promotional-coins-title {
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .icon {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .promotional-coins-main {
      width: 100%;
      padding: 10px 0;
      box-sizing: border-box;

      .amount {
        width: 100%;
        font-size: 34px;
        letter-spacing: 1px;
        font-weight: bold;
        margin-right: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
      }

      .btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .header {
    width: 100%;
    height: 60px;
    border-bottom: 2px solid #ff704f;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    .title {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .content {
    padding: 0 20px;
    box-sizing: border-box;
    .block {
      border-bottom: 2px solid #f3f3f3;

      .block-title {
        font-size: 16px;
        font-weight: bold;
        padding: 30px 0 20px 0;
        box-sizing: border-box;
      }
      .block-content {
        font-size: 14px;
        .block-content-item {
          margin-bottom: 40px;
          &:last-child {
            margin-bottom: 0;
          }

          .required {
            color: #f6756c;
            padding-right: 4px;
          }

          .block-form-item {
          }

          .select-job {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .label {
              margin-right: 20px;
            }
          }
          .promotion-area {
            .label {
              width: 100%;
              padding: 0 20px;
              box-sizing: border-box;
              background-color: #f5f5f9;
              margin-bottom: 20px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
            .areas {
              & /deep/ label {
                margin-left: 0;
              }
            }
          }

          .promotion-budget {
            width: 100%;
            .row {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              .label {
                margin-right: 20px;
              }
            }
          }

          .schedule-management {
            width: 100%;
            .row {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              .label {
                margin-right: 20px;
              }
            }
          }
        }

      }
    }
    .block2 {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: #f5f5f9;
      padding: 20px 20px;
      box-sizing: border-box;
      .block-title {
        font-size: 16px;
        font-weight: bold;
        margin-right: 20px;
      }
      .block-content {
        color: #a1a4b0;
        font-size: 14px;
      }
    }
    .agreement {
      width: 100%;
      border-top: 2px solid #f3f3f3;
      margin-bottom: 20px;
      padding: 20px 0;
      box-sizing: border-box;
      .link {
        cursor: pointer;
        color: #63A7FF;
      }
    }
  }
  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
    .btn {
      margin-left: 20px;
    }
  }
}
</style>
<style lang="less">
.by-promotional-coins {
  .by-promotional-coins-content {
    width: 100%;

    .content {
      width: 100%;

      .content-title {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #9b9b9b;
        padding: 0 30px;
        box-sizing: border-box;
        margin-bottom: 0px;

        span {
          color: #e20000;
          font-size: 16px;
          padding: 0 10px;
          box-sizing: content-box;
        }
      }

      .main {
        width: 100%;
        padding: 0 30px;
        box-sizing: border-box;

        .main-item {
          width: 100%;
          height: 100px;
          margin-bottom: 10px;
          border: 2px solid #d2d2d2;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 25px;
          color: #3a3a3a;
          cursor: pointer;

          span {
            color: #3a3a3a;
            font-weight: bold;
          }

          &:last-child {
            .el-input {
              width: 100%;
              height: 100%;
              font-size: 20px;

              .el-input__inner {
                width: 100%;
                height: 100%;
                border: none;
              }
            }
          }

        }

        .main-item-checked {
          border: 2px solid #e20000;

          span {
            color: #e20000;
          }
        }

      }
    }
  }
}
</style>
