<template>
  <div class="postJob_warp">

    <div class="postJob-left" v-loading="pageLoading">

      <div class="tab" v-if="isModify !== 'yes'">
        <el-tabs v-model="activeTab" type="card" @tab-click="changeTab">
          <el-tab-pane label="发布红包岗位" name="1"></el-tab-pane>
          <el-tab-pane label="发布普通岗位" name="0"></el-tab-pane>
        </el-tabs>
      </div>

      <div class="content">

        <div class="title">
          岗位信息
        </div>

        <div class="information" v-if="activeTab === '1'">

          <el-form ref="redEnvelopeJobFormRef" :model="redEnvelopeJobForm" :rules="formRules" label-width="80px"
                   key="redEnvelopeJobForm">

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="岗位名称" prop="jobName">
                  <!--                  <el-input v-model="redEnvelopeJobForm.jobName" placeholder="请输入" clearable></el-input>-->
                  <el-autocomplete
                      clearable
                      class="jobName"
                      show-word-limit
                      v-model.trim="redEnvelopeJobForm.jobName"
                      :debounce="800"
                      :fetch-suggestions="jobNameSearch"
                      placeholder="请输入"
                      :maxlength="12"
                      :disabled="editType === 'edit'"
                      @select="jobNameSelect"
                  ></el-autocomplete>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="学历要求" prop="education">
                  <el-cascader
                      v-model="redEnvelopeJobForm.education"
                      :options="educationOptions"
                      :show-all-levels="false"
                      placeholder="请选择"
                      popper-class="postJob-select-popper"
                      :props="{
                        emitPath: false,
                        expandTrigger: 'hover',
                        multiple: false
                      }"
                      collapse-tags
                      clearable
                      @change="setCache"
                  ></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="岗位性质" prop="jobNature">
                  <el-cascader
                      v-model="redEnvelopeJobForm.jobNature"
                      :options="jobNatureOptions"
                      :show-all-levels="false"
                      placeholder="请选择"
                      popper-class="postJob-select-popper"
                      :props="{
                        emitPath: false,
                        expandTrigger: 'hover',
                        multiple: true
                      }"
                      collapse-tags
                      clearable
                      @change="setCache"
                  ></el-cascader>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="经验要求" prop="experience">
                  <el-cascader
                      v-model="redEnvelopeJobForm.experience"
                      :options="experienceOptions"
                      :show-all-levels="false"
                      placeholder="请选择"
                      popper-class="postJob-select-popper"
                      :props="{
                        emitPath: false,
                        expandTrigger: 'hover',
                        multiple: false
                      }"
                      collapse-tags
                      clearable
                      @change="setCache"
                  ></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="岗位类别" prop="jobType">

                  <el-cascader
                      v-if="jobCategorySelectShow"
                      v-model="redEnvelopeJobForm.jobType"
                      :show-all-levels="false"
                      placeholder="请选择"
                      popper-class="postJob-select-popper"
                      :props="{
                        emitPath: false,
                        expandTrigger: 'click',
                        multiple: false,
                        lazy: true,
                        lazyLoad: (node, resolve) => getJobGetJobCategoryList(node, resolve, redEnvelopeJobForm.jobType, 'redEnvelopeJobForm')
                      }"
                      collapse-tags
                      clearable
                      :disabled="editType === 'edit'"
                      @change="setCache"
                  ></el-cascader>

                </el-form-item>
              </el-col>

              <el-col :span="12">

              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="年纪要求" prop="ageRange">

                  <slider-range :value.sync="redEnvelopeJobForm.ageRange"></slider-range>

                  <!--                  <el-cascader-->
                  <!--                      v-model="redEnvelopeJobForm.ageRange"-->
                  <!--                      :options="ageRangeOptions"-->
                  <!--                      :show-all-levels="false"-->
                  <!--                      placeholder="请选择"-->
                  <!--                      popper-class="postJob-select-popper"-->
                  <!--                      :props="{-->
                  <!--                        emitPath: false,-->
                  <!--                        expandTrigger: 'hover',-->
                  <!--                        multiple: false-->
                  <!--                      }"-->
                  <!--                      collapse-tags-->
                  <!--                      clearable-->
                  <!--                  ></el-cascader>-->

                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="红包金额" prop="redEnvelopeAmount">

                  <el-popover
                      width="400"
                      v-model="popoverIsShow"
                      trigger="click"
                  >

                    <div class="red-envelope-amount-popover-content" v-if="isModify !== 'yes'">
                      <div class="header">
                        <el-steps :space="200" :active="stagActive" finish-status="success" align-center>
                          <el-step title="分期选择"></el-step>
                          <el-step title="分期间隔"></el-step>
                          <el-step title="红包金额"></el-step>
                        </el-steps>
                      </div>
                      <div class="content" v-if="stagActive === 0">
                        <el-radio-group v-model="stageSelection">
                          <el-row :gutter="20">
                            <el-col :span="8">
                              <el-radio-button label="不分期"></el-radio-button>
                            </el-col>
                            <el-col :span="8">
                              <el-radio-button label="分2期"></el-radio-button>
                            </el-col>
                            <el-col :span="8">
                              <el-radio-button label="分3期"></el-radio-button>
                            </el-col>
                          </el-row>
                          <el-row :gutter="20">
                            <el-col :span="8">
                              <el-radio-button label="分4期"></el-radio-button>
                            </el-col>
                            <el-col :span="8">
                              <el-radio-button label="分5期"></el-radio-button>
                            </el-col>
                            <el-col :span="8">
                              <el-radio-button label="分6期"></el-radio-button>
                            </el-col>
                          </el-row>
                        </el-radio-group>
                      </div>

                      <div class="content" v-if="stagActive === 1">
                        <!--                        <el-radio-group v-model="stagingInterval">-->
                        <!--                          <el-row :gutter="20">-->
                        <!--                            <el-col :span="8">-->
                        <!--                              <el-radio-button label="每7天"></el-radio-button>-->
                        <!--                            </el-col>-->
                        <!--                            <el-col :span="8">-->
                        <!--                              <el-radio-button label="每10天"></el-radio-button>-->
                        <!--                            </el-col>-->
                        <!--                            <el-col :span="8">-->
                        <!--                              <el-radio-button label="每15天"></el-radio-button>-->
                        <!--                            </el-col>-->
                        <!--                          </el-row>-->
                        <!--                          <el-row :gutter="20">-->
                        <!--                            <el-col :span="8">-->
                        <!--                              <el-radio-button label="每30天"></el-radio-button>-->
                        <!--                            </el-col>-->
                        <!--                            <el-col :span="8">-->
                        <!--                              <el-radio-button label="每60天"></el-radio-button>-->
                        <!--                            </el-col>-->
                        <!--                          </el-row>-->
                        <!--                        </el-radio-group>-->
                        <el-form ref="stagingIntervalDetailsFormRef" key="stagingIntervalDetailsForm"
                                 :model="stagingIntervalDetailsForm" :rules="formRules" label-width="100px">
                          <el-form-item
                              v-for="(item3, index3) in stageSelectionNum"
                              :label="'第'+ (index3 + 1) + '期间隔'"
                              :key="'stagingIntervalDetailsForm' + index3"
                              :prop="'stagingInterval' + (parseInt(index3) + 1)"
                          >
                            <el-input type="number" v-model.trim="stagingIntervalDetailsForm['stagingInterval' + (parseInt(index3) + 1)]">
                              <template slot="suffix">
                                <div
                                    style="width: 100%;height: 100%; display: flex; align-items: center; justify-content: center">
                                  天
                                </div>
                              </template>
                            </el-input>
                          </el-form-item>
                        </el-form>
                      </div>

                      <div class="content" v-if="stagActive === 2">
                        <el-form ref="installmentDetailsFormRef" key="installmentDetailsForm"
                                 :model="installmentDetailsForm" :rules="formRules" label-width="100px">
                          <el-form-item
                              v-for="(item2, index2) in stageSelectionNum"
                              :label="'第'+ (index2 + 1) + '期金额'"
                              :key="'installmentDetailsForm' + index2"
                              :prop="'amount' + (parseInt(index2) + 1)"
                          >
                            <el-input type="number" v-model.trim="installmentDetailsForm['amount' + (parseInt(index2) + 1)]" :min="100">
                              <template slot="prepend">¥</template>
                              <template slot="suffix">
                                <div
                                    style="width: 100%;height: 100%; display: flex; align-items: center; justify-content: center">
                                  元
                                </div>
                              </template>
                            </el-input>
                          </el-form-item>
                        </el-form>
                      </div>

                      <div class="btn">
                        <el-button v-if="stagActive > 0" @click="stagActive--">上一步</el-button>
                        <el-button v-if="stagActive < 2" @click="installmentDetailsFormNext">下一步</el-button>
                        <el-button v-if="stagActive === 2" @click="installmentDetailsFormSubmit">完 成
                        </el-button>
                      </div>
                    </div>

                    <div slot="reference">
                      <el-input :disabled="isModify === 'yes'" v-model="redEnvelopeJobForm.redEnvelopeAmount" readonly placeholder="请输入">
                        <template slot="prepend">
                          <span class="RMB_class">
                            ¥
                          </span>
                        </template>
                        <template slot="append">
                          <span class="RMB_class">
                            元
                          </span>
                        </template>
                      </el-input>
                    </div>
                  </el-popover>


                </el-form-item>
              </el-col>

            </el-row>

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="需招人数" prop="peopleNumber">
                  <el-input :disabled="isModify === 'yes'" type="number" v-model="redEnvelopeJobForm.peopleNumber" placeholder="请输入"
                            clearable @input="setCache"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="工作省" prop="province">
                  <el-select v-model="redEnvelopeJobForm.province" placeholder="请选择" @change="changeProvince(false)" :disabled="editType === 'edit'">
                    <el-option
                        v-for="(item, index) in provinceOptions"
                        :key="'provinceOptions' + index"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="工作市" prop="city">
                  <el-select v-model="redEnvelopeJobForm.city" placeholder="请选择" @change="changeCity(false)" :disabled="editType === 'edit'">
                    <el-option
                        v-for="(item, index) in cityOptions"
                        :key="'cityOptions' + index"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="工作区" prop="area">
                  <el-select v-model="redEnvelopeJobForm.area" placeholder="请选择" @change="changeArea" :disabled="editType === 'edit'">
                    <el-option
                        v-for="(item, index) in areaOptions"
                        :key="'areaOptions' + index"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="详细地址" prop="detailAddress">
                  <el-input
                      type="text"
                      clearable
                      placeholder="请输入内容"
                      v-model="redEnvelopeJobForm.detailAddress"
                      :disabled="editType === 'edit'"
                      @input="setCache"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="0">
<!--              <el-col :span="10">-->
<!--                <el-form-item label="薪资待遇" prop="salaryMin">-->
<!--                  <el-input type="number" v-model.trim="redEnvelopeJobForm.salaryMin" clearable>-->
<!--                    <template slot="prepend">-->
<!--                          <span class="RMB_class">-->
<!--                            ¥-->
<!--                          </span>-->
<!--                    </template>-->
<!--                    <template slot="append">-->
<!--                          <span class="RMB_class">-->
<!--                            元-->
<!--                          </span>-->
<!--                    </template>-->
<!--                  </el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--              <el-col :span="10">-->
<!--                <el-form-item label="至" class="special-form-item" prop="salaryMax">-->
<!--                  <el-input type="number" v-model="redEnvelopeJobForm.salaryMax" clearable>-->
<!--                    <template slot="prepend">-->
<!--                          <span class="RMB_class">-->
<!--                            ¥-->
<!--                          </span>-->
<!--                    </template>-->
<!--                    <template slot="append">-->
<!--                          <span class="RMB_class">-->
<!--                            元-->
<!--                          </span>-->
<!--                    </template>-->
<!--                  </el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->

              <el-col :span="21">
                <el-form-item label="薪资待遇" class="special-form-item" prop="salaryRangeArr">
                  <div class="salary-range-arr-content">
                    <div class="left">
<!--                      {{salaryRangeArr[0]}}k-->
                      <el-select v-model="salaryRangeArr[0]" placeholder="请选择" @change="salaryRangeChange(0)">
                        <el-option
                            v-for="item in salaryRangeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="center">
<!--                      <el-slider-->
<!--                          v-model="salaryRangeArr"-->
<!--                          range-->
<!--                          :min="1"-->
<!--                          :max="26"-->
<!--                      >-->
<!--                      </el-slider>-->
                      至
                    </div>
                    <div class="right">
<!--                      {{salaryRangeArr[1]}}k-->
                      <el-select v-model="salaryRangeArr[1]" placeholder="请选择" @change="salaryRangeChange(1)">
                        <el-option
                            v-for="item in salaryRangeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                  </div>


                </el-form-item>
              </el-col>
              <el-col :span="3" class="special-col">
                <el-form-item label="" prop="isNegotiable">
                  <el-checkbox v-model="redEnvelopeJobForm.isNegotiable" @change="changeNegotiable('redEnvelopeJobFormRef')">面议</el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="岗位福利" prop="jobBenefits">

                  <div class="job-benefits">
                    <div class="job-benefits-left">
                      <el-cascader
                          v-model="redEnvelopeJobForm.jobBenefits"
                          :options="jobBenefitsOptions"
                          :show-all-levels="false"
                          placeholder="请选择"
                          popper-class="postJob-select-popper"
                          :props="{
                        emitPath: false,
                        expandTrigger: 'hover',
                        multiple: true
                      }"
                          clearable
                          @change="setCache"
                      ></el-cascader>
                    </div>

                    <div class="add">
                      <el-input placeholder="自定义福利" v-model="addJobBenefitsValue" :disabled="redEnvelopeJobForm.jobBenefits.length >= 8" class="input-with-select" :maxlength="8" clearable>
                        <el-button :disabled="redEnvelopeJobForm.jobBenefits.length >= 8" slot="append" type="" @click="addJobBenefits">添加福利</el-button>
                      </el-input>
                    </div>
                  </div>

                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="岗位描述" prop="jobDescription">
                  <el-input
                      type="textarea"
                      :rows="3"
                      placeholder="请输入内容"
                      v-model="redEnvelopeJobForm.jobDescription"
                      @input="setCache"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="24">
                <div class="agreement">
                  <el-checkbox v-model="isRead0">
                    我已阅读并同意
                    <span @click="toRulesPage('Publish_Job_Agreement')" style="color: #63A7FF; cursor: pointer;">《{{AllAgreement.Publish_Job_Agreement}}》</span>
                  </el-checkbox>
                </div>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="24">
                <div class="btn">
                  <el-button v-if="isModify !== 'yes'" :loading="btnLoading" @click="redEnvelopeJobFormSubmit">发 布</el-button>
                  <el-button v-if="isModify === 'yes' && this.editType === 'recheck'" :loading="btnLoading" @click="redEnvelopeJobFormSubmit" :disabled="isClose">重新审核</el-button>
                  <el-button v-if="isModify === 'yes' && this.editType === 'edit'" :loading="btnLoading" @click="modifyRedEnvelopeJobFormSubmit" :disabled="isClose">修 改</el-button>
                </div>
              </el-col>
            </el-row>

          </el-form>

        </div>

        <div class="information" v-if="activeTab === '0'">

          <el-form ref="hotEnvelopeJobFormRef" :model="hotEnvelopeJobForm" :rules="formRules" label-width="80px"
                   key="hotEnvelopeJobForm">

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="岗位名称" prop="jobName">
                  <!--                  <el-input v-model="hotEnvelopeJobForm.jobName" clearable placeholder="请输入"></el-input>-->
                  <el-autocomplete
                      clearable
                      class="jobName"
                      show-word-limit
                      v-model.trim="hotEnvelopeJobForm.jobName"
                      :debounce="800"
                      :fetch-suggestions="jobNameSearch"
                      placeholder="请输入"
                      :maxlength="12"
                      :disabled="editType === 'edit'"
                      @select="jobNameSelect"
                  ></el-autocomplete>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="学历要求" prop="education">

                  <el-cascader
                      v-model="hotEnvelopeJobForm.education"
                      :options="educationOptions"
                      :show-all-levels="false"
                      placeholder="请选择"
                      popper-class="postJob-select-popper"
                      :props="{
                        emitPath: false,
                        expandTrigger: 'hover',
                        multiple: false
                      }"
                      collapse-tags
                      clearable
                      @change="setCache"
                  ></el-cascader>

                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="岗位性质" prop="jobNature">
                  <el-cascader
                      v-model="hotEnvelopeJobForm.jobNature"
                      :options="jobNatureOptions"
                      :show-all-levels="false"
                      placeholder="请选择"
                      popper-class="postJob-select-popper"
                      :props="{
                        emitPath: false,
                        expandTrigger: 'hover',
                        multiple: true
                      }"
                      collapse-tags
                      clearable
                      @change="setCache"
                  ></el-cascader>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="经验要求" prop="experience">
                  <el-cascader
                      v-model="hotEnvelopeJobForm.experience"
                      :options="experienceOptions"
                      :show-all-levels="false"
                      placeholder="请选择"
                      popper-class="postJob-select-popper"
                      :props="{
                        emitPath: false,
                        expandTrigger: 'hover',
                        multiple: false
                      }"
                      collapse-tags
                      clearable
                      @change="setCache"
                  ></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="岗位类别" prop="jobType">

                  <el-cascader
                      v-if="jobCategorySelectShow"
                      v-model="hotEnvelopeJobForm.jobType"
                      :show-all-levels="false"
                      placeholder="请选择"
                      popper-class="postJob-select-popper"
                      :props="{
                        emitPath: false,
                        expandTrigger: 'click',
                        multiple: false,
                        lazy: true,
                        lazyLoad: (node, resolve) => getJobGetJobCategoryList(node, resolve, hotEnvelopeJobForm.jobType, 'hotEnvelopeJobForm')
                      }"
                      :disabled="editType === 'edit'"
                      collapse-tags
                      clearable
                      @change="setCache"
                  ></el-cascader>

                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="年纪要求" prop="ageRange">

                  <slider-range :value.sync="hotEnvelopeJobForm.ageRange"></slider-range>

                  <!--                  <el-cascader-->
                  <!--                      v-model="hotEnvelopeJobForm.ageRange"-->
                  <!--                      :options="ageRangeOptions"-->
                  <!--                      :show-all-levels="false"-->
                  <!--                      placeholder="请选择"-->
                  <!--                      popper-class="postJob-select-popper"-->
                  <!--                      :props="{-->
                  <!--                        emitPath: false,-->
                  <!--                        expandTrigger: 'hover',-->
                  <!--                        multiple: false-->
                  <!--                      }"-->
                  <!--                      collapse-tags-->
                  <!--                      clearable-->
                  <!--                  ></el-cascader>-->

                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="工作省" prop="province">
                  <el-select v-model="hotEnvelopeJobForm.province" placeholder="请选择" @change="changeProvince(false)" :disabled="editType === 'edit'">
                    <el-option
                        v-for="(item, index) in provinceOptions"
                        :key="'provinceOptions' + index"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="工作市" prop="city">
                  <el-select v-model="hotEnvelopeJobForm.city" placeholder="请选择" @change="changeCity(false)" :disabled="editType === 'edit'">
                    <el-option
                        v-for="(item, index) in cityOptions2"
                        :key="'cityOptions' + index"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="工作区" prop="area">
                  <el-select v-model="hotEnvelopeJobForm.area" placeholder="请选择" @change="changeArea" :disabled="editType === 'edit'">
                    <el-option
                        v-for="(item, index) in areaOptions2"
                        :key="'areaOptions' + index"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="详细地址" prop="detailAddress">
                  <el-input
                      type="text"
                      clearable
                      placeholder="请输入内容"
                      v-model="hotEnvelopeJobForm.detailAddress"
                      :disabled="editType === 'edit'"
                      @input="setCache"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="0">
<!--              <el-col :span="10">-->
<!--                <el-form-item label="薪资待遇" prop="salaryMin">-->
<!--                  <el-input type="number" v-model.trim="hotEnvelopeJobForm.salaryMin" clearable>-->
<!--                    <template slot="prepend">-->
<!--                      <span class="RMB_class">-->
<!--                        ¥-->
<!--                      </span>-->
<!--                    </template>-->
<!--                    <template slot="append">-->
<!--                      <span class="RMB_class">-->
<!--                        元-->
<!--                      </span>-->
<!--                    </template>-->
<!--                  </el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--              <el-col :span="10">-->
<!--                <el-form-item label="至" class="special-form-item" prop="salaryMax">-->
<!--                  <el-input type="number" v-model="hotEnvelopeJobForm.salaryMax" clearable>-->
<!--                    <template slot="prepend">-->
<!--                      <span class="RMB_class">-->
<!--                        ¥-->
<!--                      </span>-->
<!--                    </template>-->
<!--                    <template slot="append">-->
<!--                      <span class="RMB_class">-->
<!--                        元-->
<!--                      </span>-->
<!--                    </template>-->
<!--                  </el-input>-->
<!--                </el-form-item>-->
<!--              </el-col>-->

              <el-col :span="21">
                <el-form-item label="薪资待遇" class="special-form-item" prop="salaryRangeArr">
                  <div class="salary-range-arr-content">
                    <div class="left">
<!--                      {{salaryRangeArr2[0]}}k-->
                      <el-select v-model="salaryRangeArr2[0]" placeholder="请选择" @change="salaryRangeChange2(0)">
                        <el-option
                            v-for="item in salaryRangeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="center">
<!--                      <el-slider-->
<!--                          v-model="salaryRangeArr2"-->
<!--                          range-->
<!--                          :min="1"-->
<!--                          :max="26"-->
<!--                      >-->
<!--                      </el-slider>-->
                      至
                    </div>
                    <div class="right">
<!--                      {{salaryRangeArr2[1]}}k-->
                      <el-select v-model="salaryRangeArr2[1]" placeholder="请选择" @change="salaryRangeChange2(1)">
                        <el-option
                            v-for="item in salaryRangeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="3" class="special-col">
                <el-form-item label="" prop="isNegotiable">
                  <el-checkbox v-model="hotEnvelopeJobForm.isNegotiable" @change="changeNegotiable('hotEnvelopeJobFormRef')">面议</el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="岗位福利" prop="jobBenefits">
                  <div class="job-benefits">
                    <div class="job-benefits-left">
                      <el-cascader
                          v-model="hotEnvelopeJobForm.jobBenefits"
                          :options="jobBenefitsOptions2"
                          :show-all-levels="false"
                          placeholder="请选择"
                          popper-class="postJob-select-popper"
                          :props="{
                        emitPath: false,
                        expandTrigger: 'hover',
                        multiple: true
                      }"
                          clearable
                          @change="setCache"
                      ></el-cascader>
                    </div>

                    <div class="add">
                      <el-input placeholder="自定义福利" v-model="addJobBenefitsValue2" :disabled="hotEnvelopeJobForm.jobBenefits.length >= 8" class="input-with-select" :maxlength="8" clearable>
                        <el-button :disabled="hotEnvelopeJobForm.jobBenefits.length >= 8" slot="append" type="" @click="addJobBenefits2">添加福利</el-button>
                      </el-input>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="岗位描述" prop="jobDescription">
                  <el-input
                      type="textarea"
                      :rows="3"
                      placeholder="请输入内容"
                      v-model="hotEnvelopeJobForm.jobDescription"
                      @input="setCache"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="24">
                <div class="agreement">
                  <el-checkbox v-model="isRead1">
                    我已阅读并同意
                    <span @click="toRulesPage('Publish_Job_Agreement')" style="color: #63A7FF; cursor: pointer;">《{{AllAgreement.Publish_Job_Agreement}}》</span>
                  </el-checkbox>
                </div>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="24">
                <div class="btn">
                  <el-button v-if="isModify !== 'yes'" :loading="btnLoading" @click="hotEnvelopeJobFormSubmit">发 布</el-button>
                  <el-button v-if="isModify === 'yes' && editType === 'recheck'" :loading="btnLoading" @click="hotEnvelopeJobFormSubmit" :disabled="isClose">重新审核</el-button>
                  <el-button v-if="isModify === 'yes' && editType === 'edit'" :loading="btnLoading" @click="modifyHotEnvelopeJobFormSubmit" :disabled="isClose">修 改</el-button>
                </div>
              </el-col>
            </el-row>

          </el-form>

        </div>

      </div>

    </div>

    <div class="postJob-right">
      <dynamic-entry :on-post-job-page="true" @goPostJob="goPostJob" @goEnterpriseDynamic="goEnterpriseDynamic"></dynamic-entry>
    </div>

    <el-dialog
        title="您当前未加入或创建企业"
        :visible.sync="toEnterpriseHint"
        :before-close="toEnterpriseHintBeforeClose"
        width="400px">
      <span>是否立即去加入或创建企业</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="toEnterpriseHintBeforeClose">取 消</el-button>
        <el-button type="primary" @click="toEnterprise">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="您当前加入企业的申请还在审核中"
        :visible.sync="isHaveApplyingJoinEnterprise"
        :before-close="toEnterpriseHintBeforeClose"
        width="400px">
      <span>是否立即去查看</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="toEnterpriseHintBeforeClose">取 消</el-button>
        <el-button type="primary" @click="toEnterprise">确 定</el-button>
      </span>
    </el-dialog>

    <all-package-purchase ref="allPackagePurchaseRef" @successfulPurchase="successfulPurchase"></all-package-purchase>

  </div>
</template>

<script>
import DynamicEntry from "@/components/dynamicEntry/dynamicEntry";
import {
  education2,
  jobType,
  experience2,
  industry,
  ageRange2,
  salaryRange,
  fringeBenefits,
  areas,
  AllAgreement
} from '@/share/shareData'
import {formRules} from '@/share/formRules'
import {
  GetJobEditRedPocketJobApi,
  GetJobEditNormalJobApi,
  PostJobEditRedPocketJobApi,
  PostJobEditNormalJobApi,
  GetJobEditJobApi,
  PostJobEditJobApi,
  HrStatusApi,
  GetHrCheckJoinEnterpriseApplyApi,
  OpenJobApi,
  PostJobPayJobApi,
  GetHrJobPublishCacheApi,
  PostHrJobPublishCacheApi,
  EnterpriseIsPassCheckApi,
  GetJobGetJobCategoryListApi,
  GetRegionGetPopularRegionListApi, GetJobSearchJobCategoryApi
} from '@/request/api'
import share from "@/share/share";
import SliderRange from "@/components/sliderRange/sliderRange";
import AllPackagePurchase from "@/components/allPackagePurchase/allPackagePurchase";
import {Loading} from "element-ui";
import {ALL_RULES} from "@/config/baseURL";
import {Debounce} from "@/until/until";

export default {
  name: "postJob",
  components: {AllPackagePurchase, SliderRange, DynamicEntry},
  data() {
    return {
      pageLoading: false,
      AllAgreement: AllAgreement,
      activeTab: '1',
      educationOptions: education2,
      jobNatureOptions: jobType,
      experienceOptions: experience2,
      jobTypeOptions: industry,
      ageRangeOptions: ageRange2,
      jobBenefitsOptions: JSON.parse(JSON.stringify(fringeBenefits)),
      jobBenefitsOptions2: JSON.parse(JSON.stringify(fringeBenefits)),
      jobBenefitsOptionsOld: JSON.parse(JSON.stringify(fringeBenefits)),
      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
      cityOptions2: [],
      areaOptions2: [],

      popoverIsShow: false,

      formRules: {
        ...formRules,
        jobBenefits: [
          {required: false, validator: this.jobBenefitsRules}
        ],
        salaryRangeArr: [
          {required: true, validator: this.salaryRangeArrRules}
        ],
        salaryMin: [
          {required: true, validator: this.salaryMinRules}
        ],
        salaryMax: [
          {required: true, validator: this.salaryMaxRules}
        ],
        isNegotiable: [
          {required: true, validator: this.isNegotiableRules}
        ],
        stagingInterval1: [
          {required: true, validator: this.stagingIntervalRules}
        ],
        stagingInterval2: [
          {required: true, validator: this.stagingIntervalRules}
        ],
        stagingInterval3: [
          {required: true, validator: this.stagingIntervalRules}
        ],
        stagingInterval4: [
          {required: true, validator: this.stagingIntervalRules}
        ],
        stagingInterval5: [
          {required: true, validator: this.stagingIntervalRules}
        ],
        stagingInterval6: [
          {required: true, validator: this.stagingIntervalRules}
        ],

        amount1: [
          {required: true, validator: this.amountRules}
        ],
        amount2: [
          {required: true, validator: this.amountRules}
        ],
        amount3: [
          {required: true, validator: this.amountRules}
        ],
        amount4: [
          {required: true, validator: this.amountRules}
        ],
        amount5: [
          {required: true, validator: this.amountRules}
        ],
        amount6: [
          {required: true, validator: this.amountRules}
        ],
      },

      unitPricePerPerson: '',  // 每人的红包金额
      redEnvelopeJobForm: {
        jobName: '',  // 岗位名
        education: '',  // 学历
        jobNature: [],  // 岗位性质
        experience: null,  // 经验
        jobType: '',  // 岗位行业
        ageRange: null,  // 年龄范围
        province: '',  // 省
        city: '',  // 市
        area: '',  // 区
        detailAddress: '',  // 详细地址
        salaryMin: 3000,  // 薪资最小值
        salaryMax: 5000,  // 薪资最大值
        isNegotiable: true,  // 是否面议
        peopleNumber: '',  // 需招人数
        jobBenefits: [],  // 岗位福利
        jobDescription: '',  // 岗位描述
        redEnvelopeAmount: '',  // 红包金额
        numberOfInstallments: '',  // 红包分期数
        installmentInterval: [],  // 每期间隔
        redPocketPerInstallmentList: [],  // 每期红包
      },
      salaryRangeArr: [3000, 5000],
      salaryRangeArr2: [3000, 5000],
      hotEnvelopeJobForm: {
        jobName: '',  // 岗位名
        education: '',  // 学历
        jobNature: [],  // 岗位性质
        experience: null,  // 经验
        jobType: '',  // 岗位行业
        ageRange: null,  // 年龄范围
        province: '',  // 省
        city: '',  // 市
        area: '',  // 区
        detailAddress: '',  // 详细地址
        salaryMin: 3000,  // 薪资最小值
        salaryMax: 5000,  // 薪资最大值
        isNegotiable: true,  // 是否面议
        jobBenefits: [],  // 岗位福利
        jobDescription: '',  // 岗位描述
      },
      salaryRangeOptions: [
        {
          value: 1000,
          label: '1000'
        },
        {
          value: 2000,
          label: '2000'
        },
        {
          value: 3000,
          label: '3000'
        },
        {
          value: 4000,
          label: '4000'
        },
        {
          value: 5000,
          label: '5000'
        },
        {
          value: 6000,
          label: '6000'
        },
        {
          value: 7000,
          label: '7000'
        },
        {
          value: 8000,
          label: '8000'
        },
        {
          value: 9000,
          label: '9000'
        },
        {
          value: 10000,
          label: '10000'
        },
        {
          value: 11000,
          label: '11000'
        },
        {
          value: 12000,
          label: '12000'
        },
        {
          value: 13000,
          label: '13000'
        },
        {
          value: 14000,
          label: '14000'
        },
        {
          value: 15000,
          label: '15000'
        },
        {
          value: 16000,
          label: '16000'
        },
        {
          value: 17000,
          label: '17000'
        },
        {
          value: 18000,
          label: '18000'
        },
        {
          value: 19000,
          label: '19000'
        },
        {
          value: 20000,
          label: '20000'
        },
        {
          value: 21000,
          label: '21000'
        },
        {
          value: 22000,
          label: '22000'
        },
        {
          value: 23000,
          label: '23000'
        },
        {
          value: 24000,
          label: '24000'
        },
        {
          value: 25000,
          label: '25000'
        },
        {
          value: 26000,
          label: '26000'
        },
      ],

      addJobBenefitsValue: '',
      addJobBenefitsValue2: '',

      stageSelection: '不分期',
      stagActive: 0,
      stagingInterval: '每7天',
      stageSelectionNum: 1,

      stagingIntervalDetailsForm: {
        stagingInterval1: '',
        stagingInterval2: '',
        stagingInterval3: '',
        stagingInterval4: '',
        stagingInterval5: '',
        stagingInterval6: '',
      },
      installmentDetailsForm: {
        amount1: '',
        amount2: '',
        amount3: '',
        amount4: '',
        amount5: '',
        amount6: '',
      },


      isModify: 'no',
      editType: '',
      jobNameOptions: [],
      jobId: '',
      jobType: 0,
      isClose: false,
      toPlay: false,

      btnLoading: false,

      toEnterpriseHint: false,
      enterpriseIsPass: false,
      isHaveApplyingJoinEnterprise: false,

      jobParams: null,
      isRead0: false,
      isRead1: false,

      isInit: false,

      openProvinceList: [],
      openCityList: [],

      jobCategorySelectShow: true,  // 选择岗位行业的选择框展示情况
    }
  },
  watch: {
    'redEnvelopeJobForm.jobName': {
      handler: function () {
        if (this.isInit) {
          this.VueDebounce({vm: this})
        }
      }
    },
    'hotEnvelopeJobForm.jobName': {
      handler: function () {
        if (this.isInit) {
          this.VueDebounce({vm: this})
        }
      }
    },
    'redEnvelopeJobForm.ageRange': {
      handler: function () {
        if (this.isInit) {
          this.VueDebounce({vm: this})
        }
      }
    },
    'hotEnvelopeJobForm.ageRange': {
      handler: function () {
        if (this.isInit) {
          this.VueDebounce({vm: this})
        }
      }
    },
    'redEnvelopeJobForm.salaryMin': {
      handler: function () {
        if (this.redEnvelopeJobForm.salaryMin !== '' && this.isInit) {
          this.redEnvelopeJobForm.isNegotiable = false
          this.$refs.redEnvelopeJobFormRef.validateField('isNegotiable', res => {
          })
        }
      }
    },
    'redEnvelopeJobForm.salaryMax': {
      handler: function () {
        if (this.redEnvelopeJobForm.salaryMax !== '' && this.isInit) {
          this.redEnvelopeJobForm.isNegotiable = false
          this.$refs.redEnvelopeJobFormRef.validateField('isNegotiable', res => {
          })
        }
      }
    },
    'hotEnvelopeJobForm.salaryMin': {
      handler: function () {
        if (this.hotEnvelopeJobForm.salaryMin !== '' && this.isInit) {
          this.hotEnvelopeJobForm.isNegotiable = false
          this.$refs.hotEnvelopeJobFormRef.validateField('isNegotiable', res => {
          })
        }
      }
    },
    'hotEnvelopeJobForm.salaryMax': {
      handler: function () {
        if (this.hotEnvelopeJobForm.salaryMax !== '' && this.isInit) {
          this.hotEnvelopeJobForm.isNegotiable = false
          this.$refs.hotEnvelopeJobFormRef.validateField('isNegotiable', res => {
          })
        }
      }
    },
    stageSelection() {
      if (this.stageSelection === '不分期') {
        this.stageSelectionNum = 1
      } else {
        let str = this.stageSelection.slice(1, this.stageSelection.length - 1)
        this.stageSelectionNum = parseInt(str)
      }
    },
  },
  created() {
    // areas.forEach(item => {
    //   let obj = {}
    //   obj.label = item.label
    //   obj.value = item.value
    //   obj.children = item.children || []
    //   this.provinceOptions.push(obj)
    // })

    // this.jobNameOptions = this.initJobNameOptions()
  },
  async mounted() {
    this.pageLoading = true
    this.jobId = this.$route.query.jobId || ''
    let jobType = this.$route.query.jobType || ''
    let isModify = this.$route.query.isModify || ''
    let editType = this.$route.query.editType || ''

    this.jobType = parseInt(jobType)

    await this.getRegionGetPopularRegionList()

    this.provinceOptions = this.filterArea(areas)

    await this.loadUserStatusData()
    if (this.jobId && this.jobId !== '') {
      this.activeTab = jobType
      this.isModify = isModify
      this.editType = editType

      if (this.isModify === 'yes') {
        this.isInit = true

        await this.loadData()
        this.reloadJobCategory()
      }
    }else {
      await this.loadHrJobPublishCache()

      this.reloadJobCategory()
    }
    this.pageLoading = false
  },
  methods: {
    VueDebounce: Debounce(({vm}) => {
      vm['setHrJobPublishCache']()
    }, 500),
    reloadJobCategory() {
      this.jobCategorySelectShow = false
      this.$nextTick(() => {
        this.jobCategorySelectShow = true
      })
    },
    /**
     * 获取当前开放地区
     * */
    async getRegionGetPopularRegionList() {
      const res = await GetRegionGetPopularRegionListApi()
      if (res) {
        let arr = []
        let arr2 = []
        res.dataList.forEach((item, index) => {
          arr.push({
            provinceCode: item.provinceCode,
            provinceName: item.provinceName,
          })
          arr2.push({
            cityCode: item.code,
            cityName: item.name,
          })
        })
        this.openProvinceList = arr
        this.openCityList = arr2
      }
    },
    /**
     * 过滤地区
     * */
    filterArea(areaArr) {
      if (this.openProvinceList.length > 0 && this.openCityList.length > 0) {
        let provinceArr = []
        areaArr.forEach((item, index) => {
          this.openProvinceList.forEach((item2, index2) => {
            if (item.value === item2.provinceCode) {
              provinceArr.push(item)
            }
          })
        })
        let arr = []
        provinceArr.forEach((item, index) => {
          if (item.children) {
            let cityArr = []
            item.children.forEach((item2, index2) => {
              this.openCityList.forEach((item3, index3) => {
                if (item2.value === item3.cityCode) {
                  cityArr.push(item2)
                }
              })
            })
            arr.push({
              i: index,
              children: cityArr
            })
          }
        })
        arr.forEach((item, index) => {
          provinceArr[item.i].children = item.children
        })
        return provinceArr
      }else {
        return areaArr
      }
    },
    /**
     * 获取岗位各级类别
     * */
    async getJobGetJobCategoryList(node, resolve, value, key) {
      if (node.level <= 2){
        let leaf = false
        let parentId = ''
        if (node.level !== 0) {
          parentId = node.data.id
        }
        if (node.level === 2) {
          leaf = true
        }
        const res = await GetJobGetJobCategoryListApi({
          parentId: parentId
        })
        let arr = []
        if (res) {
          res.dataList.forEach((item, index) => {
            arr.push({
              id: item.id,
              label: item.name,
              value: item.name,
              leaf: leaf,
            })
          })
        }
        if (parentId === '' && value && key) {
          let jobType = this[key].jobType
          this[key].jobType = ''
          arr.push({
            label: value,
            value: value,
            leaf: true,
          })
          this.$nextTick(() => {
            this[key].jobType = jobType
          })
        }
        resolve(arr)
      }else {
        resolve([])
      }
    },
    /**
    * 获取 hr 发布岗位的草稿
    * */
    async loadHrJobPublishCache() {
      const res = await GetHrJobPublishCacheApi({
        jobType: this.activeTab
      })
      if (res && res.jobPublishCache) {
        let jobPublishCache = JSON.parse(res.jobPublishCache)
        if (this.activeTab === '1') {
          this.initRedPocketJobData(jobPublishCache)
        } else {
          this.initNormalData(jobPublishCache)
        }

        this.changeProvince(true)
        this.changeCity(true)
      }
      this.$nextTick(() => {
        this.isInit = true
      })
    },
    async setHrJobPublishCache() {
      let jobInformation = null
      if (this.activeTab === '1') {
        jobInformation = this.redEnvelopeJobForm
      }else {
        jobInformation = this.hotEnvelopeJobForm
      }
      let salaryRange = []
      if (!jobInformation.isNegotiable && jobInformation.salaryMin && jobInformation.salaryMax) {
        salaryRange = [jobInformation.salaryMin, jobInformation.salaryMax]
      }
      let jobAddress = []
      if (jobInformation.province && jobInformation.city && jobInformation.area) {
        jobAddress = [jobInformation.province, jobInformation.city, jobInformation.area]
      }
      let experience = []
      if (jobInformation.experience && jobInformation.experience.indexOf('-') !== -1) {
        experience = jobInformation.experience.split('-')
      }
      let ageRange = []
      if (jobInformation.ageRange && jobInformation.ageRange.indexOf('-') !== -1) {
        ageRange = jobInformation.ageRange.split('-')
      }
      let params = {
        jobInformation: {
          jobName: jobInformation.jobName,  // 岗位名字
          jobProperty: jobInformation.jobNature,  // 岗位性质
          jobCategory: jobInformation.jobType,  // 岗位类别
          description: jobInformation.jobDescription,  // 岗位描述
          jobWelfare: jobInformation.jobBenefits,  // 岗位福利
          salaryRange:  salaryRange,  // 薪资待遇
          experienceRequirementRange: experience,  // 经验要求
          ageRequirementRange: ageRange,  // 年龄要求
          academicRequirement: jobInformation.education,  // 学历要求
          jobAddress: jobAddress,  // 岗位三级地点代码列表
          // numberOfInstallments: jobInformation.numberOfInstallments,  // 红包分期天数
          // installmentInterval: jobInformation.installmentInterval,  // 每期间隔（天）
          // redPocketPerInstallment: jobInformation.redPocketPerInstallmentList,  // 每期红包
          // needEmployee: jobInformation.peopleNumber,  // 需招人数
          jobDetailAddress: jobInformation.detailAddress,  // 岗位详细地址
        }
      }
      const res = await PostHrJobPublishCacheApi({
        jobType: this.activeTab,
        jobPublishCache: JSON.stringify(params),
      })
    },
    async loadUserStatusData() {
      const res = await HrStatusApi()
      if (res && res.enterpriseId) {
        await this.enterpriseIsPassCheck()
        if (this.enterpriseIsPass) {
          this.toEnterpriseHint = false
          this.isHaveApplyingJoinEnterprise = false
        }else {
          if (res.applyingJoinEnterprise) {
            this.isHaveApplyingJoinEnterprise = true
          }else {
            this.toEnterpriseHint = true
          }
        }
      }else {
        if (res.applyingJoinEnterprise) {
          this.isHaveApplyingJoinEnterprise = true
        }else {
          this.toEnterpriseHint = true
        }
      }
    },
    async enterpriseIsPassCheck() {
      const res = await EnterpriseIsPassCheckApi()
      if (res) {
        this.enterpriseIsPass = res.isPassCheck
      }
    },
    async loadData() {
      if (this.editType === 'recheck') {
        if (this.activeTab === '1') {

          const res = await GetJobEditRedPocketJobApi({
            jobId: this.jobId
          })
          if (res) {
            this.initRedPocketJobData(res)
          }
        } else {
          const res = await GetJobEditNormalJobApi({
            jobId: this.jobId
          })
          if (res) {
            this.initNormalData(res)
          }
        }
      }

      if (this.editType === 'edit') {
        const res = await GetJobEditJobApi({
          jobId: this.jobId
        })
        if (res) {
          if (this.activeTab === '1') {
            this.initRedPocketJobData(res)
          }else {
            this.initNormalData(res)
          }
        }
      }

      this.changeProvince(true)
      this.changeCity(true)

    },

    /**
    * 初始化红包岗位数据
    * */
    initRedPocketJobData(res) {
      let jobInformation = {}

      if (res.jobInformation.ageRequirementRange && res.jobInformation.ageRequirementRange.length > 0) {  // 年龄
        jobInformation.ageRange = res.jobInformation.ageRequirementRange[0] + '-' + res.jobInformation.ageRequirementRange[1]
      }else {
        jobInformation.ageRange = '0'  // 年龄不限
      }
      jobInformation.jobName = res.jobInformation.jobName  // 岗位名

      jobInformation.education = res.jobInformation.academicRequirement  // 学历

      jobInformation.jobNature = res.jobInformation.jobProperty  // 岗位性质

      if (res.jobInformation.experienceRequirementRange && res.jobInformation.experienceRequirementRange.length > 0) {  // 经验
        jobInformation.experience = res.jobInformation.experienceRequirementRange[0] + '-' + res.jobInformation.experienceRequirementRange[1]
      }else {
        jobInformation.experience = '0'  // 经验不限
      }

      jobInformation.jobType = res.jobInformation.jobCategory  // 岗位行业
      jobInformation.province = res.jobInformation.jobAddress[0]  // 省
      jobInformation.city = res.jobInformation.jobAddress[1]  // 市
      jobInformation.area = res.jobInformation.jobAddress[2]  // 区
      jobInformation.detailAddress = res.jobInformation.jobDetailAddress  // 详细地址

      if (res.jobInformation.salaryRange && res.jobInformation.salaryRange.length > 0) {  // 薪资
        jobInformation.salaryMin = res.jobInformation.salaryRange[0]
        jobInformation.salaryMax = res.jobInformation.salaryRange[1]
        let arr = []
        arr.push(parseInt(res.jobInformation.salaryRange[0]))
        arr.push(parseInt(res.jobInformation.salaryRange[1]))
        this.salaryRangeArr = arr
        jobInformation.isNegotiable = false
      }else {
        jobInformation.salaryMin = 3000
        jobInformation.salaryMax = 5000
        jobInformation.isNegotiable = true
      }

      res.jobInformation.jobWelfare.forEach((item, index) => {
        let state = false
        this.jobBenefitsOptions.forEach((item2, index2) => {
          if (item === item2.value) {
            state = true
          }
        })
        if (!state) {
          this.jobBenefitsOptions.push({
            value: item,
            label: item
          })
        }
      })

      jobInformation.jobBenefits = res.jobInformation.jobWelfare  // 岗位福利

      jobInformation.jobDescription = res.jobInformation.description  // 岗位描述

      this.isClose = res.jobInformation.isClose  // 岗位是否已经关闭

      if (this.isModify === 'yes' || this.editType === 'recheck' || this.editType === 'edit') {
        jobInformation.peopleNumber = res.jobInformation.needEmployee  // 需招人数
        jobInformation.redPocketPerInstallmentList = res.jobInformation.redPocketPerInstallment  // 每期红包
        jobInformation.installmentInterval = res.jobInformation.installmentInterval  // 每期间隔
        jobInformation.numberOfInstallments = res.jobInformation.numberOfInstallments  // 红包分期数
        this.stagingIntervalDetailsForm.stagingInterval = res.jobInformation.stagingInterval  // 每期间隔
      }

      jobInformation.jobDescription = res.jobInformation.description  // 岗位描述

      this.redEnvelopeJobForm = jobInformation

      if (this.isModify === 'yes' || this.editType === 'recheck' || this.editType === 'edit') {
        this.stageSelectionNum = this.redEnvelopeJobForm.installmentInterval.length || 1

        res.jobInformation.installmentInterval.forEach((item, index) => {
          this.stagingIntervalDetailsForm['stagingInterval' + (index + 1)] = item
        })

        res.jobInformation.redPocketPerInstallment.forEach((item, index) => {
          this.installmentDetailsForm['amount' + (index + 1)] = item
        })

        this.initEnvelopeAmount()
      }
    },

    /**
    * 初始化一般岗位数据
    * */
    initNormalData(res) {
      let jobInformation = {}

      if (res.jobInformation.ageRequirementRange && res.jobInformation.ageRequirementRange.length > 0) {  // 年龄
        jobInformation.ageRange = res.jobInformation.ageRequirementRange[0] + '-' + res.jobInformation.ageRequirementRange[1]
      }else {
        jobInformation.ageRange = '0'
      }

      jobInformation.jobName = res.jobInformation.jobName  // 岗位名

      jobInformation.education = res.jobInformation.academicRequirement  // 学历

      jobInformation.jobNature = res.jobInformation.jobProperty  // 岗位性质

      if (res.jobInformation.experienceRequirementRange && res.jobInformation.experienceRequirementRange.length > 0) {  // 经验
        jobInformation.experience = res.jobInformation.experienceRequirementRange[0] + '-' + res.jobInformation.experienceRequirementRange[1]
      }else {
        jobInformation.experience = '0'
      }

      jobInformation.jobType = res.jobInformation.jobCategory  // 岗位行业
      jobInformation.province = res.jobInformation.jobAddress[0]  // 省
      jobInformation.city = res.jobInformation.jobAddress[1]  // 市
      jobInformation.area = res.jobInformation.jobAddress[2]  // 区
      jobInformation.detailAddress = res.jobInformation.jobDetailAddress  // 详细地址

      if (res.jobInformation.salaryRange && res.jobInformation.salaryRange.length > 0) {  // 薪资
        jobInformation.salaryMin = res.jobInformation.salaryRange[0]
        jobInformation.salaryMax = res.jobInformation.salaryRange[1]
        let arr = []
        arr.push(parseInt(res.jobInformation.salaryRange[0]))
        arr.push(parseInt(res.jobInformation.salaryRange[1]))
        this.salaryRangeArr2 = arr
        jobInformation.isNegotiable = false
      }else {
        jobInformation.salaryMin = 1000
        jobInformation.salaryMax = 26000
        jobInformation.isNegotiable = true
      }

      res.jobInformation.jobWelfare.forEach((item, index) => {
        let state = false
        this.jobBenefitsOptions2.forEach((item2, index2) => {
          if (item === item2.value) {
            state = true
          }
        })
        if (!state) {
          this.jobBenefitsOptions2.push({
            value: item,
            label: item
          })
        }
      })

      jobInformation.jobBenefits = res.jobInformation.jobWelfare  // 岗位福利

      jobInformation.jobDescription = res.jobInformation.description  // 岗位描述

      this.isClose = res.jobInformation.isClose  // 岗位是否已经关闭

      jobInformation.jobDescription = res.jobInformation.description  // 岗位描述

      this.hotEnvelopeJobForm = jobInformation
    },


    initJobNameOptions() {
      let value = []
      value = this.searchIndustry(industry, value)
      return value
    },
    searchIndustry(arr, value) {
      let data = value
      arr.forEach((item, index) => {
        if (item.children && item.children.length) {
          this.searchIndustry(item.children, data)
        } else {
          data.push(item)
        }
      })
      return data
    },

    /* 选择职位 */
    jobNameSelect(item) {
    },

    /* 搜索职位 */
    async jobNameSearch(queryString, cb) {
      // let restaurants = this.jobNameOptions
      // let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      let arr = await this.getJobSearchJobCategory(queryString)
      // 调用 callback 返回建议列表的数据
      cb(arr)
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    /**
    * 搜索相似的岗位名
    * */
    async getJobSearchJobCategory(keyWord) {
      const res = await GetJobSearchJobCategoryApi({
        keyWord: keyWord
      })
      if (res) {
        let arr = []
        res.dataList.forEach((item, index) => {
          arr.push({
            label: item.name,
            value: item.name,
          })
        })
        return arr
      }else {
        return []
      }
    },
    jobBenefitsRules(rule, value, callback) {
      if (value){
        if (value.length > 8) {
          callback(new Error('岗位福利数量不能大于8个'))
        }else {
          callback()
        }
      } else {
        callback()
      }
    },

    salaryRangeArrRules(rule, value, callback) {
      // callback()
      if (this.activeTab === '1') {
        if (!this.redEnvelopeJobForm.isNegotiable) {
          if (this.redEnvelopeJobForm.salaryMin >= this.redEnvelopeJobForm.salaryMax) {
            callback(new Error('薪资最大值必须大于薪资最小值'))
          }else {
            callback()
          }
        }else {
          callback()
        }
      }else {
        if (!this.hotEnvelopeJobForm.isNegotiable) {
          if (this.hotEnvelopeJobForm.salaryMin >= this.hotEnvelopeJobForm.salaryMax) {
            callback(new Error('薪资最大值必须大于薪资最小值'))
          }else {
            callback()
          }
        }else {
          callback()
        }
      }
    },

    /* 天数验证 */
    stagingIntervalRules(rule, value, callback) {
      let re = /^[1-9]+[0-9]*]*$/
      if (value === '') {
        callback(new Error('请输入天数'))
      } else if (!re.test(value)){
        callback(new Error('天数只能为正整数'))
      } else {
        callback()
      }
    },
    /* 金额验证 */
    amountRules(rule, value, callback) {
      let re = /^[1-9]+[0-9]*]*$/
      if (value === '') {
        callback(new Error('请输入金额'))
      } else if (parseInt(value) < 100) {
        callback(new Error('金额不能小于100元'))
      }else if (!re.test(value)) {
        callback(new Error('金额只能为正整数'))
      } else {
        callback()
      }
    },
    /* 最小薪资验证 */
    salaryMinRules(rule, value, callback) {
      let re = /^[1-9]+[0-9]*]*$/
      if (this.activeTab === '1') {
        if (!this.redEnvelopeJobForm.isNegotiable) {
          if (this.redEnvelopeJobForm.salaryMin === '') {
            callback(new Error('请输入最小金额'))
          } else if (!re.test(value)) {
            callback(new Error('金额只能为正整数'))
          } else {
            callback()
          }
        } else {
          callback()
        }

      } else {
        if (!this.hotEnvelopeJobForm.isNegotiable) {
          if (this.hotEnvelopeJobForm.salaryMin === '') {
            callback(new Error('请输入最大金额'))
          } else if (!re.test(value)) {
            callback(new Error('金额只能为正整数'))
          } else {
            callback()
          }
        } else {
          callback()
        }
      }
    },
    /* 最大薪资验证 */
    salaryMaxRules(rule, value, callback) {
      let re = /^[1-9]+[0-9]*]*$/
      if (this.activeTab === '1') {
        if (!this.redEnvelopeJobForm.isNegotiable) {
          if (this.redEnvelopeJobForm.salaryMax === '') {
            callback(new Error('请输入最小金额'))
          } else if (!re.test(value)) {
            callback(new Error('金额只能为正整数'))
          } else if (parseInt(this.redEnvelopeJobForm.salaryMax) <= parseInt(this.redEnvelopeJobForm.salaryMin)) {
            callback(new Error('薪资范围最大值要大于最小值'))
          } else {
            callback()
          }
        } else {
          callback()
        }

      } else {
        if (!this.hotEnvelopeJobForm.isNegotiable) {
          if (this.hotEnvelopeJobForm.salaryMax === '') {
            callback(new Error('请输入最小金额'))
          } else if (!re.test(value)) {
            callback(new Error('金额只能为正整数'))
          } else if (parseInt(this.hotEnvelopeJobForm.salaryMax) <= parseInt(this.hotEnvelopeJobForm.salaryMin)) {
            callback(new Error('薪资范围最大值要大于最小值'))
          } else {
            callback()
          }
        } else {
          callback()
        }
      }
    },
    /* 是否面议验证 */
    isNegotiableRules(rule, value, callback) {
      if (this.activeTab === '1') {
        if (this.redEnvelopeJobForm.salaryMin === '' && this.redEnvelopeJobForm.salaryMax === '') {
          if (!this.redEnvelopeJobForm.isNegotiable) {
            callback(new Error('请选择面议'))
          } else {
            callback()
          }
        } else {
          callback()
        }
      } else {
        if (this.hotEnvelopeJobForm.salaryMin === '' && this.hotEnvelopeJobForm.salaryMax === '') {
          if (!this.hotEnvelopeJobForm.isNegotiable) {
            callback(new Error('请选择面议'))
          } else {
            callback()
          }
        } else {
          callback()
        }
      }
    },
    async changeTab() {
      await this.loadHrJobPublishCache()
      this.reloadJobCategory()
    },
    changeProvince(modify) {
      if (this.activeTab === '1') {
        if (!modify) {
          this.cityOptions = []
          this.redEnvelopeJobForm.city = ''
          this.areaOptions = []
          this.redEnvelopeJobForm.area = ''
        }

        let key = this.redEnvelopeJobForm.province
        let arr = []
        this.provinceOptions.forEach((item, index) => {
          if (item.value === key) {
            if (item.children && item.children.length > 0) {
              arr = item.children
            }
          }
        })
        let cityOptions = []
        arr.forEach(item => {
          let obj = {}
          obj.label = item.label
          obj.value = item.value
          obj.children = item.children || []
          cityOptions.push(obj)
        })
        this.cityOptions = cityOptions
      } else if (this.activeTab === '0') {
        if (!modify) {
          this.cityOptions2 = []
          this.hotEnvelopeJobForm.city = ''
          this.areaOptions2 = []
          this.hotEnvelopeJobForm.area = ''
        }

        let key = this.hotEnvelopeJobForm.province
        let arr = []
        this.provinceOptions.forEach((item, index) => {
          if (item.value === key) {
            if (item.children && item.children.length > 0) {
              arr = item.children
            }
          }
        })
        let cityOptions2 = []
        arr.forEach(item => {
          let obj = {}
          obj.label = item.label
          obj.value = item.value
          obj.children = item.children || []
          cityOptions2.push(obj)
        })
        this.cityOptions2 = cityOptions2
      }

      if (!modify) {
        this.setCache()
      }
    },
    changeCity(modify) {
      if (this.activeTab === '1') {
        if (!modify) {
          this.areaOptions = []
          this.redEnvelopeJobForm.area = ''
        }

        let key = this.redEnvelopeJobForm.city
        let arr = []
        this.cityOptions.forEach((item, index) => {
          if (item.value === key) {
            if (item.children && item.children.length > 0) {
              arr = item.children
            }
          }
        })
        let areaOptions = []
        arr.forEach(item => {
          let obj = {}
          obj.label = item.label
          obj.value = item.value
          obj.children = item.children || []
          areaOptions.push(obj)
        })
        this.areaOptions = areaOptions
      } else if (this.activeTab === '0') {
        if (!modify) {
          this.areaOptions2 = []
          this.hotEnvelopeJobForm.area = ''
        }

        let key = this.hotEnvelopeJobForm.city
        let arr = []
        this.cityOptions2.forEach((item, index) => {
          if (item.value === key) {
            if (item.children && item.children.length > 0) {
              arr = item.children
            }
          }
        })
        let areaOptions2 = []
        arr.forEach(item => {
          let obj = {}
          obj.label = item.label
          obj.value = item.value
          obj.children = item.children || []
          areaOptions2.push(obj)
        })
        this.areaOptions2 = areaOptions2
      }
      if (!modify) {
        this.setCache()
      }
    },
    changeArea() {
      this.setCache()
    },
    changeNegotiable(key) {
      if (key === 'hotEnvelopeJobFormRef') {
        if (this.hotEnvelopeJobForm.isNegotiable) {
          this.hotEnvelopeJobForm.salaryMax = this.hotEnvelopeJobForm.salaryMax || 1000
          this.hotEnvelopeJobForm.salaryMin = this.hotEnvelopeJobForm.salaryMin || 26000
        }
        this.$refs[key].validateField('salaryRangeArr', res => {
        })
      }else {
        if (this.redEnvelopeJobForm.isNegotiable) {
          this.redEnvelopeJobForm.salaryMax = this.redEnvelopeJobForm.salaryMax || 1000
          this.redEnvelopeJobForm.salaryMin = this.redEnvelopeJobForm.salaryMin || 26000
        }
        this.$refs[key].validateField('salaryRangeArr', res => {
        })
      }
      this.setCache()
    },
    installmentDetailsFormNext() {
      if (this.$refs.stagingIntervalDetailsFormRef) {
        this.$refs.stagingIntervalDetailsFormRef.validate(res => {
          if (res) {
            this.stagActive++
          }
        })
      } else {
        this.stagActive++
      }
    },
    installmentDetailsFormSubmit() {
      this.$refs.installmentDetailsFormRef.validate(res => {
        if (res) {
          this.initEnvelopeAmount()

          this.popoverIsShow = false
          setTimeout(() => {
            this.stagActive = 0
          }, 200)

          this.setCache()
        }
      })
    },
    initEnvelopeAmount() {

      let stagingIntervalStr = ''
      let stagingIntervalArr = []
      for (let j = 0; j < this.stageSelectionNum; j++) {
        stagingIntervalStr += this.stagingIntervalDetailsForm['stagingInterval' + (j + 1)] + '天'
        stagingIntervalArr.push(this.stagingIntervalDetailsForm['stagingInterval' + (j + 1)])
        if (j < this.stageSelectionNum - 1) {
          stagingIntervalStr += '、'
        }
      }
      this.unitPricePerPerson = 0
      let amountStr = ''
      let amountArr = []
      for (let i = 0; i < this.stageSelectionNum; i++) {
        let num = parseInt(this.installmentDetailsForm['amount' + (i + 1)])
        amountStr += num + '元'
        amountArr.push(num)
        this.unitPricePerPerson += num
        if (i < this.stageSelectionNum - 1) {
          amountStr += '、'
        }
      }

      let str2 = `分${this.stageSelectionNum}期/每期间隔分别为${stagingIntervalStr}/每期红包额分别为：` + amountStr

      this.redEnvelopeJobForm.redEnvelopeAmount = str2
      this.redEnvelopeJobForm.numberOfInstallments = this.stageSelectionNum
      this.redEnvelopeJobForm.installmentInterval = stagingIntervalArr
      this.redEnvelopeJobForm.redPocketPerInstallmentList = amountArr

    },
    redEnvelopeJobFormSubmit() {
      this.$refs.redEnvelopeJobFormRef.validate(async res => {
        if (res) {
          this.setCache()

          if (!this.isRead0) {
            share.message.warning(`请勾选我已阅读并同意《${AllAgreement.Publish_Job_Agreement}》`)
            return;
          }
          if (this.btnLoading) {
            return
          }
          this.btnLoading = true
          this.jobParams = this.initRedEnvelopeJobForm()
          if (!this.jobParams) {
            this.btnLoading = false
            return
          }
          // 编辑
          if (this.isModify === 'yes' && this.editType === 'edit') {
          }else {
            // 发布 或 反审(recheck)
            let jobType = 0
            if (this.activeTab === '1') {
              jobType = 1
            }
            let param = {
              jobType: jobType,
            }
            if (jobType === 1) {
              let unitPricePerPerson = 0
              this.redEnvelopeJobForm.redPocketPerInstallmentList.forEach((item, index) => {
                unitPricePerPerson += item
              })
              param = {
                ...param,
                unitPricePerPerson: unitPricePerPerson,
                peopleNumber: this.redEnvelopeJobForm.peopleNumber,
              }
            }
            await this.$refs.allPackagePurchaseRef.checkVipPackageStatus(param)
          }
          this.btnLoading = false
        }
      })
    },
    modifyRedEnvelopeJobFormSubmit() {
      this.$refs.redEnvelopeJobFormRef.validate(async res => {
        if (res) {
          if (!this.isRead0) {
            share.message.warning(`请勾选我已阅读并同意《${AllAgreement.Publish_Job_Agreement}》`)
            return;
          }
          if (this.btnLoading) {
            return
          }
          this.btnLoading = true
          let obj2 = this.initRedEnvelopeJobForm()
          if (!obj2) {
            this.btnLoading = false
            return
          }
          let jobAddress = []
          jobAddress.push(obj2.province)
          jobAddress.push(obj2.city)
          jobAddress.push(obj2.town)
          let param = {
            jobId: this.jobId,
            jobName: obj2.jobName,
            jobProperty: obj2.jobProperty,
            jobCategory: obj2.jobCategory,
            description: obj2.description,
            salaryRange: obj2.salaryRange,
            experienceRequirementRange: obj2.experienceRequirementRange,
            ageRequirementRange: obj2.ageRequirementRange,
            academicRequirement: obj2.academicRequirement,
            jobAddress: JSON.stringify(jobAddress),
            jobDetailAddress: obj2.detailAddress,
            jobWelfare: obj2.jobWelfareList
          }
          const res2 = await PostJobEditJobApi({...param})
          if (res2) {
            share.message.success('修改成功')
            this.$router.push('/redEnvelopeJobList')
          }
          this.btnLoading = false
        }
      })
    },
    hotEnvelopeJobFormSubmit() {
      this.$refs.hotEnvelopeJobFormRef.validate(async res => {
        if (res) {

          this.setCache()

          if (!this.isRead1) {
            share.message.warning(`请勾选我已阅读并同意《${AllAgreement.Publish_Job_Agreement}》`)
            return;
          }
          if (this.btnLoading) {
            return
          }
          this.btnLoading = true
          this.jobParams = this.initHotEnvelopeJobForm()
          if (!this.jobParams) {
            this.btnLoading = false
            return
          }
          // 编辑
          if (this.isModify === 'yes' && this.editType === 'edit') {
          }else {
            // 发布 或 反审(recheck)
            let jobType = 0
            if (this.activeTab === '1') {
              jobType = 1
            }
            let param = {
              jobType: jobType,
            }
            if (jobType === 1) {
              let unitPricePerPerson = 0
              this.redEnvelopeJobForm.redPocketPerInstallmentList.forEach((item, index) => {
                unitPricePerPerson += item
              })
              param = {
                ...param,
                unitPricePerPerson: unitPricePerPerson,
                peopleNumber: this.redEnvelopeJobForm.peopleNumber,
              }
            }
            await this.$refs.allPackagePurchaseRef.checkVipPackageStatus(param)
          }
          this.btnLoading = false
        }
      })
    },
    modifyHotEnvelopeJobFormSubmit() {
      this.$refs.hotEnvelopeJobFormRef.validate(async res => {
        if (res) {
          if (!this.isRead1) {
            share.message.warning(`请勾选我已阅读并同意《${AllAgreement.Publish_Job_Agreement}》`)
            return;
          }
          if (this.btnLoading) {
            return
          }
          this.btnLoading = true
          let obj2 = this.initHotEnvelopeJobForm()
          if (!obj2) {
            this.btnLoading = false
            return
          }
          let jobAddress = []
          jobAddress.push(obj2.province)
          jobAddress.push(obj2.city)
          jobAddress.push(obj2.town)
          let param = {
            jobId: this.jobId,
            jobName: obj2.jobName,
            jobProperty: obj2.jobProperty,
            jobCategory: obj2.jobCategory,
            description: obj2.description,
            salaryRange: obj2.salaryRange,
            experienceRequirementRange: obj2.experienceRequirementRange,
            ageRequirementRange: obj2.ageRequirementRange,
            academicRequirement: obj2.academicRequirement,
            jobAddress: JSON.stringify(jobAddress),
            jobDetailAddress: obj2.detailAddress,
            jobWelfare: obj2.jobWelfareList
          }


          const res2 = await PostJobEditJobApi({...param})
          if (res2) {
            share.message.success('修改成功')
            this.$router.push('/hotJobList')
          }
          this.btnLoading = false
        }
      })
    },

    goEnterpriseDynamic(key, index) {
      this.$router.push({
        path: key,
        query: {
          index: index
        }
      })
    },

    toEnterprise() {
      this.$router.push('/enterpriseInformation')
    },

    toEnterpriseHintBeforeClose() {
      this.toEnterpriseHint = false
      this.isHaveApplyingJoinEnterprise = false
      this.$nextTick(() => {
        this.$router.push('/jobSeeker')
      })
    },

    goPostJob() {
      this.$router.push({
        path: '/postJob'
      })

      this.$nextTick(() => {
        this.$router.go(0)
      })
    },

    initRedEnvelopeJobForm() {
      let obj = JSON.parse(JSON.stringify(this.redEnvelopeJobForm))
      let newObj = {}
      newObj.jobName = obj.jobName  // 岗位名

      if (obj.ageRange.indexOf('-') === -1) {
        newObj.ageRequirementRange = JSON.stringify([])
      }else {
        newObj.ageRequirementRange = JSON.stringify(obj.ageRange.split('-'))  // 岗位年龄
      }

      newObj.academicRequirement = obj.education  // 学历

      if (obj.experience.indexOf('-') === -1) {
        newObj.experienceRequirementRange = JSON.stringify([])
      }else {
        newObj.experienceRequirementRange = JSON.stringify(obj.experience.split('-'))
      }

      // newObj.jobProperty = obj.jobNature  // 岗位性质
      newObj.jobProperty = JSON.stringify(obj.jobNature)  // 岗位性质
      newObj.jobCategory = obj.jobType  // 岗位行业
      newObj.province = obj.province
      newObj.city = obj.city
      newObj.town = obj.area
      newObj.detailAddress = obj.detailAddress
      let salaryRange = []
      salaryRange.push(obj.salaryMin)
      salaryRange.push(obj.salaryMax)
      if (obj.isNegotiable) {
        salaryRange = []
      }else {
        if(obj.salaryMin >= obj.salaryMax) {
          share.message.warning('薪资范围最大值要大于最小值')
          return false
        }
      }
      newObj.salaryRange = JSON.stringify(salaryRange)
      newObj.description = obj.jobDescription
      let jobBenefits = []
      if (obj.jobBenefits && obj.jobBenefits.length > 0) {
        obj.jobBenefits.forEach((item, index) => {
          jobBenefits.push(item)
        })
      }
      newObj.jobWelfareList = JSON.stringify(jobBenefits)
      newObj.numberOfInstallments = obj.numberOfInstallments
      newObj.installmentInterval = JSON.stringify(obj.installmentInterval)
      newObj.redPocketPerInstallmentList = JSON.stringify(obj.redPocketPerInstallmentList)
      newObj.needEmployee = obj.peopleNumber
      return newObj
    },

    initHotEnvelopeJobForm() {
      let obj = JSON.parse(JSON.stringify(this.hotEnvelopeJobForm))
      let newObj = {}
      newObj.jobName = obj.jobName

      if (obj.ageRange.indexOf('-') === -1) {
        newObj.ageRequirementRange = JSON.stringify([])
      }else {
        newObj.ageRequirementRange = JSON.stringify(obj.ageRange.split('-'))  // 岗位年龄
      }

      newObj.academicRequirement = obj.education

      if (obj.experience.indexOf('-') === -1) {
        newObj.experienceRequirementRange = JSON.stringify([])
      }else {
        newObj.experienceRequirementRange = JSON.stringify(obj.experience.split('-'))
      }

      // newObj.jobProperty = obj.jobNature
      newObj.jobProperty = JSON.stringify(obj.jobNature)
      newObj.jobCategory = obj.jobType
      newObj.province = obj.province
      newObj.city = obj.city
      newObj.town = obj.area
      newObj.detailAddress = obj.detailAddress
      let salaryRange = []
      salaryRange.push(obj.salaryMin)
      salaryRange.push(obj.salaryMax)
      if (obj.isNegotiable) {
        salaryRange = []
      }else {
        if(obj.salaryMin >= obj.salaryMax) {
          share.message.warning('薪资范围最大值要大于最小值')
          return false
        }
      }
      newObj.salaryRange = JSON.stringify(salaryRange)
      newObj.description = obj.jobDescription
      let jobBenefits = []
      if (obj.jobBenefits && obj.jobBenefits.length > 0) {
        obj.jobBenefits.forEach((item, index) => {
          jobBenefits.push(item)
        })
      }

      newObj.jobWelfareList = JSON.stringify(jobBenefits)
      return newObj
    },
    addJobBenefits() {
      if (this.addJobBenefitsValue === '') {
        share.message.warning('添加自定义福利不能为空')
        return
      }

      if (this.redEnvelopeJobForm.jobBenefits.length > 7) {
        share.message.warning('最多添加8个自定义福利')
        return;
      }


      if (this.redEnvelopeJobForm.jobBenefits.indexOf(this.addJobBenefitsValue) !== -1) {
        share.message.warning('此福利已存在')
        return;
      } else {
        let state = this.arrFindValue(this.jobBenefitsOptions, this.addJobBenefitsValue)
        if (state && state.value) {
          let arr = JSON.parse(JSON.stringify(this.redEnvelopeJobForm.jobBenefits))
          arr.push(this.addJobBenefitsValue)
          this.redEnvelopeJobForm.jobBenefits = arr
        }else {
          let arr = JSON.parse(JSON.stringify(this.redEnvelopeJobForm.jobBenefits))
          arr.push(this.addJobBenefitsValue)
          this.redEnvelopeJobForm.jobBenefits = arr
          this.jobBenefitsOptions.push({
            value: this.addJobBenefitsValue,
            label: this.addJobBenefitsValue,
          })
        }
        this.addJobBenefitsValue = ''
      }
      this.setCache()
    },
    addJobBenefits2() {
      if (this.addJobBenefitsValue2 === '') {
        share.message.warning('添加自定义福利不能为空')
        return
      }
      if (this.hotEnvelopeJobForm.jobBenefits.length > 7) {
        share.message.warning('最多添加8个自定义福利')
        return;
      }

      if (this.hotEnvelopeJobForm.jobBenefits.indexOf(this.addJobBenefitsValue2) !== -1) {
        share.message.warning('此福利已存在')
        return;
      } else {
        let state = this.arrFindValue(this.jobBenefitsOptions2, this.addJobBenefitsValue2)
        if (state && state.value) {
          let arr = JSON.parse(JSON.stringify(this.hotEnvelopeJobForm.jobBenefits))
          arr.push(this.addJobBenefitsValue2)
          this.hotEnvelopeJobForm.jobBenefits = arr
        }else {
          let arr = JSON.parse(JSON.stringify(this.hotEnvelopeJobForm.jobBenefits))
          arr.push(this.addJobBenefitsValue2)
          this.hotEnvelopeJobForm.jobBenefits = arr
          this.jobBenefitsOptions2.push({
            value: this.addJobBenefitsValue2,
            label: this.addJobBenefitsValue2,
          })
        }
        this.addJobBenefitsValue2 = ''
      }
      this.setCache()
    },
    arrFindValue(arr, value) {
      let obj = {}
      arr.forEach((item, index) => {
        if (item.value === value) {
          obj = item
        }
      })
      return obj
    },

    salaryRangeChange(i) {
      if (i === 0) {
        this.redEnvelopeJobForm.salaryMin = this.salaryRangeArr[0]
      }else {
        this.redEnvelopeJobForm.salaryMax = this.salaryRangeArr[1]
      }
      this.setCache()
    },
    salaryRangeChange2(i) {
      if (i === 0) {
        this.hotEnvelopeJobForm.salaryMin = this.salaryRangeArr2[0]
      }else {
        this.hotEnvelopeJobForm.salaryMax = this.salaryRangeArr2[1]
      }
      this.setCache()
    },

    /**
    * 确认支付
    * */
    async successfulPurchase(obj) {
      await this.confirmPublish(obj)
    },

    /**
    * 确认发布、反审
    * */
    async confirmPublish(obj) {
      let loadingInstance = Loading.service({background: 'hsla(0,0%,100%,.9)'});
      let jobType = 0
      if (this.activeTab === '1') {
        jobType = 1
      }
      let params = {...this.jobParams, jobType: jobType, jobId: this.jobId?Number(this.jobId):''}
      // 没有会员套餐
      if (obj.state === 0) {
        // 买的时长包
        if (obj.type === 1) {
          params = {
            ...params,
            durationPackageTemplateId: obj.templateId
          }
        }
        // 买的会员
        if (obj.type === 0) {
          params = {
            ...params,
            hrVipTemplateId: obj.templateId
          }
        }
      }
      // 有会员套餐没有剩余次数
      if (obj.state === 2)  {
        params = {
          ...params,
          durationPackageTemplateId: obj.templateId
        }
      }
      const res2 = await PostJobPayJobApi({
        ...params
      })
      if (res2.code && res2.code === 450) {
        this.toEnterpriseHint = true
      }else if (res2) {
        // 没有会员套餐
        if (obj.state === 0) {
          share.message.success('支付成功，已送审')
        }
        // 有会员套餐有剩余次数
        if (obj.state === 1) {
          if (jobType === 1) {
            share.message.success('支付成功，已送审')
          }else {
            if (this.editType === 'recheck') {
              share.message.success('已提交反审')
            }else {
              share.message.success('发布成功已送审')
            }
          }
        }
        // 有会员套餐没有剩余次数
        if (obj.state === 2) {
          share.message.success('支付成功已送审')
        }
        loadingInstance.close();
        if (this.activeTab === '1') {
          this.$router.push('/redEnvelopeJobList')
        }else {
          this.$router.push('/hotJobList')
        }
      }else {
        loadingInstance.close();
      }

    },

    /**
     * 去协议详情页
     * */
    toRulesPage(param) {
      window.open(ALL_RULES + '?type=' + param)
    },

    setCache() {
      if (this.isInit) {
        this.VueDebounce({vm: this})
      }
    },

  }
}
</script>

<style lang="less" scoped>
.postJob_warp {
  width: 100%;
  //height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #f4f2f3;

  .postJob-left {
    width: 780px;
    //height: 100%;
    background-color: #ffffff;

    .tab {
      width: 100%;
      height: 50px;

      & /deep/ .el-tabs__item.is-active {
        color: #e20000;
      }
    }

    .content {
      width: 100%;
      height: 100%;
      padding: 0 20px;
      box-sizing: border-box;

      .title {
        width: 100%;
        height: 40px;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 1px;
        box-sizing: border-box;
        color: #424242;
        border-bottom: 1px solid #e3e3e3;
      }

      .information {
        width: 100%;
        height: 100%;
        padding: 20px 0;
        box-sizing: border-box;

        .job-benefits {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .job-benefits-left {
            flex: 1;
            margin-right: 20px;
          }

        }

        .jobName {
          & /deep/ .el-input {

            .el-input__inner {
              padding-right: 80px !important;
              width: calc(100% + 5px);
            }

          }
        }

        .el-cascader {
          width: 100%;
        }

        .special-col {
          & /deep/ .el-form-item__content {
            margin-left: 20px !important;
          }
        }

        & /deep/ .special-form-item .el-form-item__label {
          padding: 0;
          text-align: center;
        }

        .special-form-item {
          .salary-range-arr-content {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            //.left,.right {
            //  width: 40px;
            //}
            .center {
              margin: 0 15px;
              //flex: 1;
            }
          }
        }

        .btn {
          width: 100%;
          height: 50px;
          display: flex;
          align-items: flex-end;
          justify-content: center;

          .el-button {
            padding: 10px 30px;
            background-color: #e20000;
            border: 1px solid #e20000;
            color: #ffffff;
          }
        }

      }

    }

  }

  .postJob-right {
    flex: 1;
    //min-height: 600px;
    margin-left: 10px;
    background-color: #f4f2f3;
  }

}
</style>
<style lang="less">
.postJob-select-popper {
  .el-cascader-menu {
    height: 250px !important;
  }
}

.red-envelope-amount-popover-content {
  width: 100%;

  .header {
    width: 100%;
    height: 70px;
    margin-top: 10px;
  }

  .content {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    max-height: 250px;
    overflow: auto;
    margin-bottom: 10px;

    .el-radio-group {
      width: 100%;
      text-align: center;
    }

    .el-row {
      width: 100%;
      margin-bottom: 10px;
      margin-right: 0px !important;
      margin-left: 0px !important;

      .el-col {
        text-align: center;
      }
    }
  }

  .btn {
    padding: 0 30px;
    box-sizing: border-box;
    text-align: right;
  }
}
</style>
