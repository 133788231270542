<template>
  <div class="redEnvelopeJobList_warp">
    <div class="postJob-left">

      <div class="header">
        <div class="title">
          红包岗位列表
        </div>
        <div class="actions">
          <el-select v-model="filterJobState" placeholder="请选择" size="mini" @change="changeFilterJobState">
            <el-option
                v-for="(item, index) in filterJobStateOptions"
                :key="'filterJobState'+ index"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="content" v-loading="pageLoading">
        <div class="job-item" v-for="(item, index) in jobList" :key="'jobList' + index">
          <warehouse-job-item
              :apply-red-pocket-count="item.applyRedPocketCount"
              :community-count="item.communityCount"
              :experience-requirement-range="item.experienceRequirementRange"
              :is-close="item.isClose"
              :job-address="item.jobAddress"
              :job-id="item.jobId"
              :job-name="item.jobName"
              :job-status="item.jobStatus"
              :salary-range="item.salaryRange"
              :see-count="item.seeCount"
              :collect-count="item.collectCount"
              :academic-requirement="item.academicRequirement"
              :is-red-envelope="true"
              :expire-date="item.expireDate"
              :editing-under-review="item.editingUnderReview"
              :job-promote-information="item.jobPromoteInfo"
              :hr-vip-remain-job-num="hrVipRemainJobNum"
              :have-valid-duration-package="item.haveValidDurationPackage"
              @goJobDetailed="goJobDetailed"
              @clickAction="(id, jobId2) => clickAction(id, jobId2, item)"
          ></warehouse-job-item>
        </div>

        <div v-if="jobList.length <= 0" class="empty">
          空
        </div>

        <div v-if="jobList && jobList.length > 0" class="pagination">
          <el-pagination
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
        </div>
      </div>

    </div>

    <div class="postJob-right">
      <dynamic-entry @goEnterpriseDynamic="goEnterpriseDynamic"></dynamic-entry>
    </div>

    <el-dialog
        title="提示"
        :visible.sync="delJobDialogVisible"
        width="400px">
      <span>确定要删除此岗位？</span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="delJobDialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="delJobDialogBtnLoading" @click="delJobDialogSubmit">确 定</el-button>
      </div>
    </el-dialog>

    <all-package-purchase ref="allPackagePurchaseRef"
                          @successfulPurchase="successfulPurchase"></all-package-purchase>

  </div>
</template>

<script>
import DynamicEntry from "@/components/dynamicEntry/dynamicEntry";
import WarehouseJobItem from "@/components/warehouseJobItem/warehouseJobItem";
import {filterJobStateOptions} from '@/share/shareData'
import {
  HrMyPublishedRedPocketJobApi,
  GetJobGetUnpaidRedPocketJobInfoApi,
  CloseJobApi,
  OpenJobApi,
  PostJobDeleteJobApi, PostJobPayJobApi,
} from '@/request/api'
import share from "@/share/share";
import AllPackagePurchase from "@/components/allPackagePurchase/allPackagePurchase";
import {Loading} from "element-ui";
export default {
  name: "redEnvelopeJobList",
  components: {AllPackagePurchase, WarehouseJobItem, DynamicEntry},
  data() {
    return {
      pageLoading: false,
      // searchValue: '',
      filterJobState: '',
      filterJobStateOptions: filterJobStateOptions,
      peopleNumber: 5,
      unitPricePerPerson: 0,
      jobList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      jobId: '',
      actionLoading: null,
      formRules: {
        peopleNumber: [
          {required: true, message: '请输入需招人数'},
        ]
      },
      purchasePackageId: '',

      payLoading: false,

      delJobDialogVisible: false,
      delJobId: '',
      delJobDialogBtnLoading: false,

      hrVipRemainJobNum: 0,  // vip套餐是否还有剩余可在线的岗位次数
    }
  },
  computed: {
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.pageLoading = true
      let includeEditCheck = ''
      if (this.filterJobState === 2) {
        includeEditCheck = 'includeEditCheck'
      }
      const res = await HrMyPublishedRedPocketJobApi({
        jobStatus: this.filterJobState,
        includeEditCheck: includeEditCheck,
        pageNum: this.currentPage,
        pageSize: this.pageSize
      })

      this.hrVipRemainJobNum = res.hrVipRemainJobNum
      if (res) {
        this.total = res.numOfJobs
        this.jobList = res.jobList
      }
      this.pageLoading = false
    },
    async loadRedPocketJobInfoData(jobId) {
      this.jobId = jobId
      const res = await GetJobGetUnpaidRedPocketJobInfoApi({
        jobId: jobId
      })

      if (res) {
        this.unitPricePerPerson = res.redPocketPerPerson
        this.peopleNumber = res.needEmployee
      }
    },
    changeFilterJobState() {
      this.currentPage = 1
      this.loadData()
    },
    async clickAction(id, jobId, jobInfo) {
      // 支付
      if (id === 1) {
        await this.loadRedPocketJobInfoData(jobId)
        let param = {
          jobType: 1,
          unitPricePerPerson: this.unitPricePerPerson,
          peopleNumber: this.peopleNumber,
        }
        this.$nextTick(() => {
          this.$refs.allPackagePurchaseRef.checkVipPackageStatus(param)
        })
      }
      // 续费
      if (id === 5) {
        await this.openJob(jobId)
        // await this.loadRedPocketJobInfoData(jobId)
        // let param = {
        //   jobType: 1,
        //   unitPricePerPerson: this.unitPricePerPerson,
        //   peopleNumber: this.peopleNumber,
        //   renew: true,
        // }
        // this.$nextTick(() => {
        //   this.$refs.allPackagePurchaseRef.checkVipPackageStatus(param)
        // })
      }
      // 重新审核
      if (id === 6) {
        await this.$router.push({
          path: '/postJob',
          query: {
            jobId: jobId,
            isModify: 'yes',
            editType: 'recheck',
            jobType: '1'
          }
        })
      }
      // 关闭
      if (id === 7) {
        await this.closeJob(jobId)
      }
      // 开放
      if (id === 8) {
        await this.openJob(jobId)
      }
      // 编辑
      if (id === 10) {
        await this.$router.push({
          path: '/postJob',
          query: {
            jobId: jobId,
            isModify: 'yes',
            editType: 'edit',
            jobType: '1'
          }
        })
      }
      // 删除
      if (id === 11) {
        this.delJobId = jobId
        this.delJobDialogVisible = true
      }
      // 设置推广
      if (id === 12) {
        this.$router.push({
          path: '/newJobPromotion',
          query: {
            isModifyStatus: 'yes',
            jobPromoteId: jobInfo.jobPromoteInfo.id
          }
        })
      }
      // 推广
      if (id === 13) {
        this.$router.push({
          path: '/newJobPromotion',
          query: {
            jobId: jobId,
          }
        })
      }
    },
    async successfulPurchase(obj) {
      this.confirmPublish(obj)
    },
    /**
     * 确认续费
     * */
    async confirmPublish(obj) {
      let loadingInstance = Loading.service({background: 'hsla(0,0%,100%,.9)'});
      let params = {
        jobId: this.jobId
      }
      // 没有会员套餐
      if (obj.state === 0) {
        // 买的时长包
        if (obj.type === 1) {
          params = {
            ...params,
            durationPackageTemplateId: obj.templateId
          }
        }
        // 买的会员
        if (obj.type === 0) {
          params = {
            ...params,
            hrVipTemplateId: obj.templateId
          }
        }
      }
      // 有会员套餐没有剩余次数
      if (obj.state === 2)  {
        params = {
          ...params,
          durationPackageTemplateId: obj.templateId
        }
      }
      const res2 = await PostJobPayJobApi({
        ...params
      })
      if (res2) {
        // 没有会员套餐
        if (obj.state === 0) {
          share.message.success('支付成功已开启')
        }
        // 有会员套餐有剩余次数
        if (obj.state === 1) {
          share.message.success('支付成功已开启')
        }
        // 有会员套餐没有剩余次数
        if (obj.state === 2) {
          share.message.success('支付成功已开启')
        }
        loadingInstance.close();
        this.loadData()
      }else {
        loadingInstance.close();
      }
    },
    async delJobDialogSubmit() {
      this.delJobDialogBtnLoading = true
      const res = await PostJobDeleteJobApi({
        jobId: this.delJobId
      })

      if (res) {
        share.message.success('操作成功')
      }
      await this.loadData()
      this.delJobDialogBtnLoading = false
      this.delJobDialogVisible = false
    },
    async closeJob(jobId) {
      this.actionLoading = this.$message({
        message: '关闭中',
        type: 'warning',
        duration: 0,
        customClass: 'message-class',
        iconClass: 'el-icon-loading',
      })
      const res = await CloseJobApi({
        jobId: jobId
      })

      this.actionLoading.close()
      if (res) {
        share.message.success('关闭成功')
        await this.loadData()
      }
    },
    async openJob(jobId) {
      this.actionLoading = this.$message({
        message: '开启中',
        type: 'warning',
        duration: 0,
        customClass: 'message-class',
        iconClass: 'el-icon-loading',
      })
      const res = await OpenJobApi({
        jobId: jobId
      })
      if (res && res.needBuy === false) {
        this.actionLoading.close()
        share.message.success('开启成功')
        this.loadData()
      } else {
        this.actionLoading.close()
        // await this.loadRedPocketJobInfoData(jobId)
        this.jobId = jobId
        let param = {
          jobId: jobId,
          jobType: 1,
          // unitPricePerPerson: this.unitPricePerPerson,
          // peopleNumber: this.peopleNumber,
        }
        this.$nextTick(() => {
          this.$refs.allPackagePurchaseRef.checkVipPackageStatus(param)
        })
      }
    },
    goJobDetailed(id) {
      this.$router.push({
        path: '/hrSeeJobDetailed',
        query: {
          id: id
        }
      })
    },
    goEnterpriseDynamic(key, index) {
      this.$router.push({
        path: key,
        query: {
          index: index
        }
      })
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.loadData()
    },
  }
}
</script>

<style lang="less" scoped>
.redEnvelopeJobList_warp {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #f4f2f3;

  .postJob-left {
    width: 780px;
    height: 100%;
    background-color: #ffffff;

    .header {
      height: 40px;
      width: 100%;
      font-size: 16px;
      color: #e20000;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      box-sizing: border-box;
      border-bottom: 1px solid #bfbfbf;

      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .el-select {
          margin-left: 20px;
        }
      }
    }

    .content {
      width: 100%;
      padding: 20px 20px;
      box-sizing: border-box;

      .job-item {
        width: 100%;
        height: 70px;
        margin-bottom: 10px;
      }

      .pagination {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .empty {
        width: 100%;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: bold;
        color: #e1e1e1;
      }
    }

  }

  .postJob-right {
    flex: 1;
    //min-height: 600px;
    margin-left: 10px;
    background-color: #f4f2f3;
  }
}
</style>

<style lang="less">
.red-envelope-job-pay-dialog-wrap {

  .el-dialog__body {
    padding: 0 20px;
  }

  .pay-dialog-content {
    height: 400px !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      width: 500px;
      margin-right: 20px;

      .title {
        font-size: 18px;
        color: #e20000;
        height: 30px;
        line-height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .tag {
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          padding: 0px 10px;
          box-sizing: border-box;
          border-radius: 3px;
          background-color: #ffcbbf;
          margin-left: 20px;
        }
      }


      .content {
        border: 1px solid #b1b1b1;
        border-radius: 10px;
        padding: 10px 10px 10px 10px;
        box-sizing: border-box;
        margin-bottom: 20px;

        .table2 {

          .el-row {
            height: 100%;

            .el-col {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .header {
            width: 100%;
            height: 30px;
            border-bottom: 1px solid #bfbfbf;
          }

          .row {
            height: 50px;
            color: #343434;

            .row-item-first {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }

            span {
              font-weight: bold;
              color: #343434;
            }
          }
        }

        .table {

          .el-row {
            height: 100%;
            .el-col {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .row {
            height: 50px;
            color: #343434;

            .row-item-first {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }

            span {
              font-weight: bold;
              color: #e20000;
              font-size: 24px;
              margin-right: 5px;
            }
          }
        }

      }

      .desc {
        margin-top: 10px;
        font-size: 12px;
        color: #969696;
      }

    }

    .right {
      flex: 1;
      background-color: #eeeeee;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 0 30px 30px 30px;
      box-sizing: border-box;

      >div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #a9a9a9;
        font-size: 12px;
      }

      .payAble {
        margin-top: 15px;
        font-size: 14px;
        color: #5c5c5c;
        align-items: flex-end;
        padding-bottom: 2px;
        box-sizing: border-box;
        margin-bottom: 5px;
        border-bottom: 1px solid #ffffff;

        span {
          font-size: 24px;
          color: #e20000;
          margin-right: 5px;
        }
      }

      .desc {
        margin-top: 10px;
      }

      .order-number {
        color: #5c5c5c;
        margin-top: 10px;
        font-size: 12px;

        .num {
          color: #323232;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }

  }
}
</style>
