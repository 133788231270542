<template>
  <div class="jobCard_warp">
    <div class="title" @click="clickTitle">
      <div class="text">{{title}}</div>
      <i class="el-icon-arrow-right"></i>
    </div>
    <job-card-item class="item" v-for="(item, index) in jobList" :key="'job-card-item' + index"
                   :job-name="item.jobName"
                   :job-address="item.jobAddressList"
                   :enterprise-name="item.enterpriseName"
                   :salary-range="item.salaryRange"
                   :job-id="item.job_id"
                   :job-status="item.jobStatus"
                   :is-close="item.status !== 1"
                   :enterprise-status="item.enterpriseStatus"
                   @goJobDetailed="goJobDetailed"></job-card-item>
    <div v-if="jobList.length <= 0 " class="null">
      空
    </div>
  </div>
</template>

<script>
import JobCardItem from "@/components/jobCard/jobCardItem";
export default {
  name: "jobCard",
  components: {JobCardItem},
  props: {
    title: {
      type: String,
      default: ''
    },
    jobList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    clickTitle() {
      this.$emit('clickTitle')
    },
    goJobDetailed(id) {
      this.$emit('clickItem', id)
    },
  }
}
</script>

<style lang="less" scoped>
.jobCard_warp {
  width: 100%;
  height: 100%;
  padding: 0px 20px 10px 20px;
  box-sizing: border-box;

  .title {
    height: 35px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 20px;
    cursor: pointer;

    .text {
      color: #454545;
      font-size: 14px;
      height: 35px;
      line-height: 35px;
      margin-left: 5px;
    }

  }

  .item {
    margin-bottom: 30px;
    cursor: pointer;
  }

  .null {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #C0C4CC;
    font-weight: bold;
  }

}
</style>
