<template>
  <div class="searchEnterprise_warp">
    <div class="searchEnterprise-left">

      <div class="title">
        选择企业
      </div>

      <div class="content">

        <div class="search-input">
          <search-input placeholder="搜索企业" @searchSubmit="searchSubmit"></search-input>
        </div>

        <div class="enterprise-list">
          <div class="enterprise-item" v-for="(item, index) in enterpriseList" :key="'enterprise-item' + index">
            <enterprise-item
                :enterprise-id="item.enterpriseId"
                :enterprise-name="item.enterpriseName"
                :industry="item.industry"
                :financing-status="item.financingStatus"
                :admin-name="item.adminName"
                :logo="item.logo"
                @clickItem="clickItem"></enterprise-item>
          </div>
        </div>

      </div>

    </div>

    <div class="searchEnterprise-right">

      <div class="card">

        <div class="right-top">
          <el-avatar size="large" :src="profilePhoto"></el-avatar>
          {{username}}
          <el-button size="mini">发布新岗位</el-button>
        </div>

        <div class="right-bottom">

          <div class="dynamic-item" @click="goEnterpriseDynamic('4')">
            <div class="number">
              {{statistics.normalJobNum}}
            </div>
            <div class="title">
              普通岗位
            </div>
          </div>

          <div class="dynamic-item" @click="goEnterpriseDynamic('5')">
            <div class="number">
              {{statistics.redPocketJobNum}}
            </div>
            <div class="title">
              红包岗位
            </div>
          </div>

        </div>

      </div>

      <div class="card">

        <div class="dynamic-item" @click="goEnterpriseDynamic('1')">
          <div class="title">
            看过我
          </div>
          <div class="number">
            {{statistics.seeMeCount}}
          </div>
        </div>

        <div class="dynamic-item" @click="goEnterpriseDynamic('2')">
          <div class="title">
            我看过
          </div>
          <div class="number">
            {{statistics.seeEmployeeCount}}
          </div>
        </div>

        <div class="dynamic-item" @click="goEnterpriseDynamic('3')">
          <div class="title">
            对我感兴趣
          </div>
          <div class="number">
            {{statistics.collectMeCount}}
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import SearchInput from "@/components/searchInput/searchInput";
import EnterpriseItem from "@/components/enterpriseItem/enterpriseItem";
import {SearchEntepriseApi, HrStatusStatisticsApi} from '@/request/api'
export default {
  name: "searchEnterprise",
  components: {EnterpriseItem, SearchInput},
  data() {
    return {
      enterpriseList: [],
      statistics: {},
    }
  },
  computed: {
    username() {
      return this.$store.state.userInfo.username || ''
    },
    profilePhoto() {
      return this.$store.state.userInfo.profilePhoto || ''
    }
  },
  mounted() {
    this.loadStatusData()
  },
  methods: {
    searchSubmit(value) {

      this.loadData(value)
    },

    async loadStatusData() {
      const res = await HrStatusStatisticsApi()

      if (res) {
        this.statistics = res.statistics
      }
    },

    async loadData(value) {
      const res = await SearchEntepriseApi({
        searchInput: value
      })



      if (res) {
        this.enterpriseList = res.enterpriseList
      }
    },
    goEnterpriseDynamic(index) {

    },
    clickItem(id) {
      this.$router.push({
        path: '/enterpriseInformation',
        query: {
          isJoin: 'true',
          id: id
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.searchEnterprise_warp {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #f4f2f3;

  .searchEnterprise-left {
    width: 780px;
    height: 100%;
    background-color: #f4f2f3;

    .title {
      width: 100%;
      height: 40px;
      background-color: #ffffff;
      margin-bottom: 5px;
      font-size: 18px;
      color: #323232;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 10px;
      box-sizing: border-box;
    }

    .content {
      padding: 0 20px 20px 20px;
      box-sizing: border-box;
      background-color: #ffffff;

      .search-input {
        width: 500px;
        margin: 10px 0 20px 0;
        padding-top: 10px;
        box-sizing: border-box;
      }

      .enterprise-list {
        min-height: 500px;
        background-color: #f4f2f3;

        .enterprise-item {
          padding: 0 10px;
          box-sizing: border-box;
          width: 100%;
          height: 60px;
        }
      }
    }

  }

  .searchEnterprise-right {
    flex: 1;
    height: 485px;
    margin-left: 20px;
    background-color: #f4f2f3;

    .card {
      background-color: #ffffff;
      margin-bottom: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:first-child {
        padding: 40px 40px;
        box-sizing: border-box;
        font-size: 14px;

        .right-top {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          & /deep/ .el-avatar--large {
            width: 100px;
            height: 100px;
            margin-bottom: 10px;
          }

          & /deep/ .el-button {
            color: #ffffff;
            background-color: #e20000;
            margin: 10px 0px 20px 0px;
          }


        }

        .right-bottom {
          display: flex;
          align-items: center;
          justify-content: center;

          .dynamic-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 15px;
            cursor: pointer;

            .number {
              margin-bottom: 5px;
              color: #303030;
            }

            .title {
              color: #b5b5b5;
            }

            &:last-child {
              margin-right: 0px;
            }

          }

        }
      }

      &:last-child {
        font-size: 14px;
        padding: 30px 30px;
        box-sizing: border-box;

        .dynamic-item {
          width: 150px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #dcdcdc;
          margin-bottom: 15px;
          cursor: pointer;

          .title {
            color: #b5b5b5;
          }

          .number {
            color: #303030;
          }

        }

      }

    }

  }
}
</style>
