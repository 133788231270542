<template>
  <div class="pointsCollection_warp">

    <div class="card">

      <div class="title">
        积分规则
      </div>

      <div class="content">
        <div class="text">
          1. 新用户注册获得2积分
        </div>
        <div class="text">
          2. 邀请新用户注册获得1积分
        </div>
      </div>

    </div>

    <div class="card">

      <div class="title">
        邀请注册
      </div>

      <div class="content" v-loading="pageLoading2">
        <div class="invitation-method">
          <div class="invitation-method-title">
            方式一：二维码邀请（通过本方式邀请注册会自动填充邀请码）
          </div>
          <div class="invitation-method-content">
            <div id="invitationQrcode" class="qrcode" ref="qrCodeUrl" v-if="invitationQrcode">

            </div>
          </div>

        </div>

        <div class="invitation-method">
          <div class="invitation-method-title">
            方式二：复制链接发送给好友（通过本方式邀请注册会自动填充邀请码）
          </div>
          <div class="invitation-method-content">
            <el-input class="share-link-input" type="text" readonly v-model="invitationLink">
              <template slot="append">
                <div class="btn" v-clipboard:copy="invitationLink" v-clipboard:success="onCopy" v-clipboard:error="onError">
                  点击复制
                </div>
              </template>
            </el-input>
          </div>
        </div>

        <div class="invitation-method">
          <div class="invitation-method-title">
            方式三：复制邀请码
          </div>
          <div class="invitation-method-content">

            <el-input class="share-link-input" type="text" readonly v-model="invitationCode">
              <template slot="append">
                <div class="btn" v-clipboard:copy="invitationCode" v-clipboard:success="onCopy" v-clipboard:error="onError">
                  点击复制
                </div>
              </template>
            </el-input>

          </div>
        </div>

      </div>

    </div>

    <div class="card">

      <div class="title">
        获取记录
      </div>

      <div class="content">

        <el-table
            v-loading="pageLoading"
            :data="tableData"
            style="width: 100%">
          <el-table-column
              prop="getTime"
              label="获取时间">
          </el-table-column>
          <el-table-column
              prop="action"
              label="动作">
          </el-table-column>
          <el-table-column
              prop="numOfCoin"
              label="积分值">
          </el-table-column>
        </el-table>

        <div class="pagination" v-if="tableData && tableData.length > 0">
          <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
        </div>

      </div>

    </div>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="500px"
        >
      <span style="color: #e20000;">请在复制分享链接之前选择所在地区，否则将无法正确获取积分奖励。</span>
      <span slot="footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import {WEB_BASE_URL} from '@/config/baseURL'
import {GetAreaListApi, GetCoinRecordApi, GetEmployeeGetInviteCodeApi} from '@/request/api'
import share from "@/share/share";

export default {
  name: "pointsCollection",
  data() {
    return {
      pageLoading: true,
      pageLoading2: false,
      tableData: [],
      pageSize: 5,
      currentPage: 1,
      total: 0,
      dialogVisible: false,
      invitationQrcode: false,

      invitationCode: '',
    }
  },
  computed: {
    place() {
      return this.$store.state.userInfo.place
    },
    // area() {
    //   let place = this.$store.state.userInfo.place || ''
    //   let areasOptions = this.$store.state.areasOptions[0].value || ''
    //   let area = place[place.length - 1] || areasOptions
    //   return area
    // },
    token() {
      return this.$store.state.token
    },
    shareArea() {
      let placeCode = ''
      if (this.place && this.place.value && this.place.value !== '') {
        placeCode = this.place.value
      }

      if (placeCode === '' && this.areaOptions && this.areaOptions.length > 0) {
        placeCode = this.areaOptions[0].value
      }
      return placeCode
    },
    invitationLink() {
      // return WEB_BASE_URL + '?share_id=' + encodeURIComponent(this.token) + '&share_area=' + this.shareArea + '&to_page=login'
      let shareId = encodeURIComponent(this.invitationCode)
      // return WEB_BASE_URL + '?share_id=' + shareId + '&to_page=login'
      return WEB_BASE_URL + '?share_id=' + shareId + '&to_page=register'
    }
  },
  watch: {
    place: {
      handler: function () {
        // if (!this.place.value) {
        //   return
        // }
        this.invitationQrcode = false
        this.$nextTick(() => {
          this.invitationQrcode = true
          this.$nextTick(async () => {
            await this.loadEmployeeGetInviteCode()
            this.creatQrCode()
          })
        })
        this.loadData()
      },
      immediate: true
    }
  },
  async mounted() {

    let place = this.$store.state.userInfo.place || ''
    if (place === undefined || place === '' || place.length <= 0) {
      this.dialogVisible = true
    }

  },
  methods: {
    async loadEmployeeGetInviteCode() {
      this.pageLoading2 = true
      const res = await GetEmployeeGetInviteCodeApi({
        areaCode: this.place.value,
      })

      if (res) {
        this.invitationCode = res.invite_code
      }
      this.pageLoading2 = false
    },
    async loadData() {
      this.pageLoading = true
      const res = await GetCoinRecordApi({
        pageNum: this.currentPage,
        pageSize: this.pageSize
      })

      if (res) {
        this.tableData = res.recordList
        this.total = res.numOfRecords
      }
      this.pageLoading = false
    },
    creatQrCode() {
      let qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.invitationLink, // 需要转换为二维码的内容
        width: 150,
        height: 150,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.loadData()
    },
    onCopy(val) {
      share.message.success('复制成功')
    },
    onError(val) {
    }
  }
}
</script>

<style lang="less" scoped>
.pointsCollection_warp {
  width: 100%;
  //height: 100%;
  padding: 20px 40px;
  box-sizing: border-box;

  .card {
    width: 100%;
    margin-bottom: 20px;

    .title {
      font-size: 18px;
      color: #323232;
      font-weight: bold;
    }

    .content {
      background-color: #fcfcfc;
      padding: 20px 30px;
      box-sizing: border-box;

      .text {
        font-size: 16px;
        color: #4f4f4f;
      }

      .invitation-method {
        width: 100%;
        margin-bottom: 40px;

        .invitation-method-title {
          font-size: 16px;
          color: #4f4f4f;
          margin-bottom: 10px;
        }

        .invitation-method-content {
          width: 100%;
          font-size: 16px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;

          .qrcode {
            width: 170px;
            height: 170px;
            display: inline-block;
            background-color: #ffffff; //设置白色背景色
            padding: 10px; // 利用padding的特性，挤出白边
            box-sizing: border-box;
            img {
              width: 132px;
              height: 132px;

            }
          }

          .share-link-input {
            width: 600px;

            .btn {
              width: 50px;
              height: 38px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #333333;
              cursor: pointer;
            }

          }

          p {
            width: 910px;
            word-wrap: break-word;
          }

        }

      }

      .pagination {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
      }

    }

  }
}
</style>
