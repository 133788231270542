<template>
  <div class="jobSeekerDetailed_warp">

    <div class="actions">
      <el-button style="padding: 2px 15px;" type="text" v-print="'#jobSeekerDetailed-print'">
        <img src="@/assets/icon/printing.png">打印
      </el-button>
    </div>

    <div class="content" style="width: 100%;" id="jobSeekerDetailed-print">

      <div
          style="
          width: 800px;
          margin: 0 auto;
          border: 1px solid #c9c7c8;
        "
      >

        <div
            style="
            width: 100%;
            height: 200px;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0 50px;
            box-sizing: border-box;
            color: #323232;
            font-weight: bold;
          "
        >

          <div
              style="
              width: 110px;
              height: 110px;
              min-width: 110px;
              min-height: 110px;
              position: relative;
            "
          >

            <img v-if="!coverWithMosaics" :src="newAvatarUrl"
                 style="
                width: 100%;
                height: 100%;
              "
            >

            <img v-if="coverWithMosaics && myResumeData.gender === 'female'" src="@/assets/icon/cover-with-mosaics-avatar.png"
                 style="
                 position: absolute;
                 left: 0;
                 top: 0;
                width: 100%;
                height: 100%;
              "
            >
            <img v-if="coverWithMosaics && myResumeData.gender === 'male'" src="@/assets/icon/cover-with-mosaics-avatar2.png"
                 style="
                 position: absolute;
                 left: 0;
                 top: 0;
                width: 100%;
                height: 100%;
              "
            >

          </div>

          <div
              style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              font-size: 14px;
              margin-left: 40px;
            "
          >

            <div
                style="
                font-size: 20px;
                margin-bottom: 20px;
              "
            >
              <span v-if="!coverWithMosaics">{{ myResumeData.name }}</span>
              <span v-if="coverWithMosaics">****</span>
              <span style="font-size: 14px; padding-left: 20px; color: #67C23A;" v-if="myResumeData.onlineStatus === '在线'">
                <span style="display: inline-block; width: 5px; height: 5px; border-radius: 50%; background-color: #67C23A; margin-bottom: 2px;"></span>
                {{ myResumeData.onlineStatus }}
              </span>
              <span style="font-size: 14px; padding-left: 20px; color: #4d4d4d;" v-if="myResumeData.onlineStatus !== '在线'">{{ myResumeData.onlineStatus }}</span>
            </div>

            <div
                style="
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-bottom: 10px;
              "
            >
              <div
                  style="
                  padding: 0px 20px 0px 0px;
                  height: 24px;
                  line-height: 24px;
                  border-right: 1px solid #ffffff;
                "
                  v-if="myResumeData.age"
              >
                {{ myResumeData.age }}岁
              </div>
              <div
                  style="
                  padding: 0px 20px 0px 0px;
                  height: 24px;
                  line-height: 24px;
                  border-right: 1px solid #ffffff;
                "
              >
                {{ gender }}
              </div>
              <div
                  style="
                  padding: 0px 20px 0px 0px;
                  height: 24px;
                  line-height: 24px;
                  border-right: 1px solid #ffffff;
                "
              >
                {{ workExperienceYears }}工作经验
              </div>
              <div
                  style="
                  padding: 0px 20px 0px 0px;
                  height: 24px;
                  line-height: 24px;
                  border-right: 1px solid #ffffff;
                "
              >
                {{ myResumeData.academicLevelMax }}
              </div>
              <div
                  style="
                  padding: 0px 20px 0px 0px;
                  height: 24px;
                  line-height: 24px;
                  border-right: 0px solid #ffffff;
                "
              >
                {{ jobSearchingStatus }}
              </div>
              <div
                  v-if="myResumeData && myResumeData.phone"
                  style="
                  padding: 0px 20px 0px 0px;
                  height: 24px;
                  line-height: 24px;
                  border-right: 0px solid #ffffff;
                "
              >
                手机号：{{ myResumeData.phone }}
              </div>
            </div>

            <!--                      <div class="contact-information" style="display: flex;">-->
            <!--                        <div class="weChat" -->
            <!--                          style="-->
            <!--                            display: flex;-->
            <!--                            align-items: center;-->
            <!--                            justify-content: flex-start;-->
            <!--                            margin-right: 30px;-->
            <!--                          "-->
            <!--                        >-->
            <!--                          <img-->
            <!--                            style="-->
            <!--                              width: 20px;-->
            <!--                              height: 20px;-->
            <!--                              margin-right: 5px;-->
            <!--                            "-->
            <!--                            src="@/assets/icon/weChat.png">-->
            <!--                          {{myResumeData.wechat}}-->
            <!--                        </div>-->
            <!--                        <div class="email"-->
            <!--                          style="-->
            <!--                            display: flex;-->
            <!--                            align-items: center;-->
            <!--                            justify-content: flex-start;-->
            <!--                            margin-right: 30px;-->
            <!--                          "-->
            <!--                        >-->
            <!--                          <img-->
            <!--                              style="-->
            <!--                              width: 20px;-->
            <!--                              height: 20px;-->
            <!--                              margin-right: 5px;-->
            <!--                            "-->
            <!--                              src="@/assets/icon/email.png">-->
            <!--                          {{myResumeData.email}}-->
            <!--                        </div>-->
            <!--                        <div class="phone"-->
            <!--                          style="-->
            <!--                            display: flex;-->
            <!--                            align-items: center;-->
            <!--                            justify-content: flex-start;-->
            <!--                            margin-right: 30px;-->
            <!--                          "-->
            <!--                        >-->
            <!--                          <img-->
            <!--                              style="-->
            <!--                              width: 20px;-->
            <!--                              height: 20px;-->
            <!--                              margin-right: 5px;-->
            <!--                            "-->
            <!--                              src="@/assets/icon/phone3.png">-->
            <!--                          {{myResumeData.phone}}-->
            <!--                        </div>-->
            <!--                      </div>-->

          </div>

        </div>

        <div
            style="
            width: 100%;
            border-top: 1px solid #c9c7c8;
            padding: 20px 50px;
            box-sizing: border-box;
            display: flex;
          "
        >

          <div
              style="
              width: 80px;
              font-size: 18px;
              font-weight: bold;
              color: #252525;
            "
          >
            个人亮点
          </div>

          <div
              style="
              flex: 1;
              padding-top: 30px;
              font-size: 16px;
              box-sizing: border-box;
              color: #5b5c5f;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: flex-start;
            "
          >

            <div style="margin-right: 5px;
            margin-bottom: 5px;
            border: 1px solid #d9ecff;
            background-color: #ecf5ff;
            border-radius: 4px;
            padding: 0 10px;
            height: 32px;
            line-height: 30px;
            color: #409eff;
            font-size: 12px;
            box-sizing: border-box;
            white-space: nowrap;
            "
                 v-for="(tag, tagIndex) in myResumeData.personalHighLightList"
                 :key="'personalHighLightList' + tagIndex">
              {{tag}}
            </div>

          </div>

        </div>

        <div
            style="
            width: 100%;
            border-top: 1px solid #c9c7c8;
            padding: 20px 50px;
            box-sizing: border-box;
            display: flex;
          "
            v-if="myResumeData.expectedJobList"
        >

          <div
              style="
              width: 80px;
              font-size: 18px;
              font-weight: bold;
              color: #252525;
            "
          >
            求职意向
          </div>

          <div
              style="
              flex: 1;
              padding-top: 30px;
              font-size: 16px;
              box-sizing: border-box;
              color: #5b5c5f;
            "
          >

            <div style="">
              <div v-if="expectedSalaryMin && expectedSalaryMax">
                期望薪资：<span>{{parseInt(expectedSalaryMin)/1000}}k</span>-<span style="margin-right: 15px;">{{parseInt(expectedSalaryMax)/1000}}k</span>
              </div>
              <div style="margin-top: 5px;">
                期望岗位：<span style="margin-right: 15px;" v-for="(item5, index5) in myResumeData.expectedJobList" :key="'expectedJobList' + index5">{{item5}}</span>
              </div>
              <div style="margin-top: 5px;">
                期望工作地点：<span style="margin-right: 15px;" v-for="(item6, index6) in myResumeData.expectedJobAddressList" :key="'expectedJobList' + index6">{{item6}}</span>
              </div>

            </div>


          </div>

        </div>

        <div
            style="
          width: 100%;
          border-top: 1px solid #c9c7c8;
          padding: 20px 50px;
          box-sizing: border-box;
          display: flex;
        "
            v-if="myResumeData.selfIntroduction"
        >

          <div
              style="
              width: 80px;
              font-size: 18px;
              font-weight: bold;
              color: #252525;
            "
          >
            自我介绍
          </div>

          <div
              style="
              flex: 1;
              padding-top: 30px;
              font-size: 16px;
              box-sizing: border-box;
              color: #5b5c5f;
            "
          >

            <div
                style="
                width: 100%;
                box-sizing: border-box;
                word-wrap: break-word;
                word-break: break-all;
                white-space: pre-wrap;
                text-indent: -4px;
              "
            >
              {{ myResumeData.selfIntroduction }}
            </div>

          </div>

        </div>

        <div
            style="
            width: 100%;
            border-top: 1px solid #c9c7c8;
            padding: 20px 50px;
            box-sizing: border-box;
            display: flex;
          "
        >

          <div
              style="
              width: 80px;
              font-size: 18px;
              font-weight: bold;
              color: #252525;
            "
              v-if="myResumeData.workExperienceList"
          >
            工作经历
          </div>

          <div
              style="
              flex: 1;
              padding-top: 30px;
              font-size: 16px;
              box-sizing: border-box;
              color: #5b5c5f;
            "
          >

            <el-timeline>
              <el-timeline-item
                  v-for="(item2, index2) in myResumeData.workExperienceList"
                  :key="'workExperienceList' + index2"
                  color="#00479d"
              >

                <div
                    style="
                  width: 100%;
                  color: #5b5c5f;
                "
                >

                  <el-row :gutter="10" style="margin-bottom: 5px;">
                    <el-col :span="12">
                      <span style="font-weight: bold;">企业：</span>{{ item2.enterpriseName }}
                    </el-col>
                    <el-col :span="12">
                      <span style="font-weight: bold;">起止时间：</span>{{ item2.startDate }} 至 {{ item2.endDate }}
                    </el-col>
                  </el-row>
                  <el-row :gutter="10" style="margin-bottom: 5px;">
                    <el-col :span="24">
                      <span style="font-weight: bold;">岗位：</span>{{ item2.jobName }}
                    </el-col>
                  </el-row>
                  <el-row :gutter="10" style="margin-bottom: 5px;">
                    <el-col :span="24">
                      <span style="font-weight: bold;word-wrap: break-word; word-break: break-all;">工作内容：</span>
                      <span style="white-space: pre-wrap; text-indent: -4px;">{{ item2.description }}</span>
                    </el-col>
                  </el-row>


                </div>

              </el-timeline-item>
            </el-timeline>

          </div>

        </div>

        <div
            style="
            width: 100%;
            border-top: 1px solid #c9c7c8;
            padding: 20px 50px;
            box-sizing: border-box;
            display: flex;
          "
            v-if="myResumeData.educationExperienceList"
        >

          <div
              style="
              width: 80px;
              font-size: 18px;
              font-weight: bold;
              color: #252525;
            "
          >
            教育经历
          </div>

          <div
              style="
              flex: 1;
              padding-top: 30px;
              font-size: 16px;
              box-sizing: border-box;
              color: #5b5c5f;
            "
          >

            <el-timeline>
              <el-timeline-item
                  v-for="(item3, index3) in myResumeData.educationExperienceList"
                  :key="'educationExperienceList' + index3"
                  color="#00479d">

                <div
                    style="
                  width: 100%;
                  color: #5b5c5f;
                "
                >

                  <el-row :gutter="10" style="margin-bottom: 5px;">
                    <el-col :span="12">
                      <span style="font-weight: bold;
                      padding: 0px 10px 0px 0px;
                border-right: 1px solid #5b5c5f;
                display: inline-block;
                height: 14px;
                line-height: 14px;
">{{ item3.schoolName }}</span>
                      <span style="padding: 0 0 0 10px;
                display: inline-block;
                height: 14px;
                line-height: 14px;">{{ academicLevel(item3.academicLevel) }}</span>
                    </el-col>
                    <el-col :span="12">
                      <span style="font-weight: bold;">起止时间：</span>{{ item3.startDate }} 至 {{ item3.endDate }}
                    </el-col>
                  </el-row>
                  <el-row :gutter="10" style="margin-bottom: 5px;">
                    <el-col :span="24">
                      <span style="font-weight: bold;word-wrap: break-word; word-break: break-all;">专业：</span>{{ item3.major }}
                    </el-col>
                  </el-row>
                  <el-row :gutter="10" style="margin-bottom: 5px;">
                    <el-col :span="24">
                      <span style="font-weight: bold;word-wrap: break-word; word-break: break-all;">在校经历：</span>
                      <span style="white-space: pre-wrap; text-indent: -4px;">{{ item3.description }}</span>
                    </el-col>
                  </el-row>


                </div>

              </el-timeline-item>
            </el-timeline>

          </div>

        </div>

        <div
            style="
            width: 100%;
            border-top: 1px solid #c9c7c8;
            padding: 20px 50px;
            box-sizing: border-box;
            display: flex;
          "
        >

          <div
              style="
              width: 80px;
              font-size: 18px;
              font-weight: bold;
              color: #252525;
            "
              v-if="myResumeData.certificateList"
          >
            证书信息
          </div>

          <div
              style="
              flex: 1;
              padding-top: 30px;
              font-size: 16px;
              box-sizing: border-box;
              color: #5b5c5f;
            "
          >

            <el-timeline>
              <el-timeline-item
                  v-for="(item4, index4) in myResumeData.certificateList"
                  :key="'certificateList' + index4"
                  color="#00479d">

                <div
                    style="
                  width: 100%;
                  color: #5b5c5f;
                "
                >

                  <el-row :gutter="10" style="margin-bottom: 5px;">
                    <el-col :span="12">
                      <span style="padding: 0px 10px 0px 0px;
                display: inline-block;
                height: 14px;
                line-height: 14px;
                font-weight: bold; border-right: 0px;">{{ item4.name }}</span>
                    </el-col>
                    <el-col :span="12">
                      <span style="font-weight: bold;">获取时间：</span>{{ item4.getDate }}
                    </el-col>
                  </el-row>

                </div>

              </el-timeline-item>
            </el-timeline>

          </div>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
import {GetResumeEditApi} from '@/request/api'
import {jobSearchingStatus, education} from '@/share/shareData'
import share from "@/share/share";

export default {
  name: "jobSeekerDetailed",
  props: {
    oldMyResumeData: {
      type: Object,
      default: () => {}
    },
    newCoverWithMosaics: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      myResumeData: {},
      coverWithMosaics: true,
    }
  },

  computed: {
    userType() {
      return this.$store.state.userInfo.userType || ''
    },
    defAvatarUrl() {
      if (this.userType === 'hr') {
        return 'https://cdn.jiaxinzp.com/jxzp/hr_avatar_male.png'
      }else {
        return 'https://cdn.jiaxinzp.com/jxzp/employee_avatar_male.png'
      }
    },
    newAvatarUrl() {
      if (this.myResumeData.avatar) {
        return share.initImageUrl(this.myResumeData.avatar)
      }else {
        return this.defAvatarUrl
      }
    },
    workExperienceYears() {
      let str = this.myResumeData.workExperienceYears || 0
      if (str === 0 || str === '0') {
        str = '无'
      } else {
        str += '年'
      }
      return str
    },
    jobSearchingStatus() {
      let str = ''
      jobSearchingStatus.forEach(item => {
        if (item.value === this.myResumeData.jobSearchingStatus) {
          str = item.label
        }
      })
      return str
    },
    gender() {
      let str = ''
      if (this.myResumeData.gender === 'male') {
        str = '男'
      } else if (this.myResumeData.gender === 'female') {
        str = '女'
      }
      return str
    },
    expectedSalaryMax() {
      let str = this.myResumeData.expectedSalaryRange[1] || ''
      return str
    },
    expectedSalaryMin() {
      let str = this.myResumeData.expectedSalaryRange[0] || ''
      return str
    }
  },
  watch: {
    oldMyResumeData() {
      this.myResumeData = this.oldMyResumeData
    },
    newCoverWithMosaics: {
      handler: function () {
        this.coverWithMosaics = this.newCoverWithMosaics
      },
      immediate: true
    }
  },
  mounted() {
    this.myResumeData = this.oldMyResumeData

    // if (this.$route.query.coverWithMosaics === true || this.$route.query.coverWithMosaics === 'true') {
    //   this.coverWithMosaics = true
    // }else {
    //   this.coverWithMosaics = false
    // }
    // this.loadData()
  },
  methods: {
    async loadData() {
      // const res = await GetResumeEditApi()
      //
      // if (res) {
      //   this.myResumeData = res.employeeInformation
      //   if (res.employeeInformation.expectedSalaryRange.length) {
      //     this.myResumeData.expectedSalaryMin = res.employeeInformation.expectedSalaryRange[0]
      //     this.myResumeData.expectedSalaryMax = res.employeeInformation.expectedSalaryRange[1]
      //   }
      // }
    },
    academicLevel(academicLevel) {
      let str = ''
      education.forEach(item => {
        if (item.value === academicLevel) {
          str = item.label
        }
      })
      return str
    },
    // printContent(e) {
    //   let subOutputRankPrint = document.getElementById('JobSeekerDetailed-print');
    //   let newContent = subOutputRankPrint.innerHTML;
    //   let oldContent = document.body.innerHTML;
    //   document.body.innerHTML = newContent;
    //   window.print();
    //   window.location.reload();
    //   document.body.innerHTML = oldContent;
    //   return false;
    // },
  }
}
</script>

<style lang="less" scoped>
.jobSeekerDetailed_warp {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding-bottom: 40px;
  box-sizing: border-box;

  .actions {
    width: 800px;
    height: 40px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .el-button {
      color: #707070;
      font-size: 18px;

      & /deep/ span {
        width: 100%;
        height: 25px;
        line-height: 25px;

        img {
          width: 25px;
          height: 25px;
          position: relative;
          top: 5px;
          margin-right: 5px;
        }
      }

    }

  }

  .content {
    width: 100%;

    .JobSeekerDetailed-print-warp {
      width: 800px;
      margin: 0 auto;
      border: 1px solid #c9c7c8;

      .personal-information {
        width: 100%;
        height: 200px;
        background-color: #2b3f7d;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 50px;
        box-sizing: border-box;
        color: #ffffff;

        .personal-information-left {
          width: 110px;
          height: 110px;

          img {
            width: 100%;
            height: 100%;
          }

        }

        .personal-information-right {
          //display: flex;
          //flex-direction: column;
          //align-items: flex-start;
          font-size: 14px;
          margin-left: 40px;

          .name {
            font-size: 20px;
            margin-bottom: 20px;
          }

          .information {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            > div {
              padding: 0px 10px;
              height: 12px;
              line-height: 12px;
              border-right: 1px solid #ffffff;

              &:first-child {
                padding-left: 0px;
              }

              &:last-child {
                border-right: 0px;
              }

            }

          }

          .contact-information {
            display: flex;

            > div {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-right: 30px;
            }

            img {
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }

          }

        }

      }

      .card {
        width: 100%;
        border-top: 1px solid #c9c7c8;
        padding: 20px 50px;
        box-sizing: border-box;
        display: flex;

        .title {
          width: 80px;
          font-size: 18px;
          font-weight: bold;
          color: #252525;
        }

        .main {
          flex: 1;
          padding-top: 30px;
          font-size: 16px;
          box-sizing: border-box;
          color: #5b5c5f;

          .text {
            width: 100%;
            padding-left: 28px;
            box-sizing: border-box;
          }

          .main-item {
            width: 100%;
            color: #5b5c5f;

            .el-row {
              margin-bottom: 5px;

              .span {
                font-weight: bold;
              }

              .border {
                padding: 0px 10px;
                border-right: 1px solid #5b5c5f;
                display: inline-block;
                height: 14px;
                line-height: 14px;

                &:first-child {
                  padding-left: 0px;
                }

                &:last-child {
                  border-right: 0px;
                }

              }

            }

          }

          .el-timeline {
            padding-left: 0px;

            & /deep/ .el-timeline-item__node--normal {
              width: 10px;
              height: 10px;
            }

            & /deep/ .el-timeline-item__tail {
              left: 3px;
            }

          }

        }

      }

      .self-introduction {
        width: 100%;
      }
    }

  }

}
</style>
