<template>
  <div class="enterpriseItem_warp" @click="handleClick">
    <div class="left">
      <div class="icon">
        <img :src="logo" />
      </div>
      <div class="enterprise-name">
        {{enterpriseName}}
      </div>
      <div class="contact-person">
        {{adminName}}
      </div>
    </div>
    <div class="right">
      <div class="industry">
        {{industry}}
      </div>
      <div class="financingStatus">
        {{financingStatus}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "enterpriseItem",
  props: {
    enterpriseId: {
      type: [Number, String],
      default: ''
    },
    enterpriseName: {
      type: String,
      default: ''
    },
    logo: {
      type: String,
      default: ''
    },
    adminName: {
      type: String,
      default: ''
    },
    financingStatus: {
      type: String,
      default: ''
    },
    industry: {
      type: String,
      default: ''
    }
  },
  data() {
    return{

    }
  },
  methods: {
    handleClick() {
      this.$emit('clickItem', this.enterpriseId)
    }
  }
}
</script>

<style lang="less" scoped>
.enterpriseItem_warp {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c8c8c8;
  cursor: pointer;

  .left {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    box-sizing: border-box;
    color: #1b1b1b;

    .icon {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .enterprise-name {
      font-size: 16px;
      margin-right: 20px;
      font-weight: bold;
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .contact-person {
      font-size: 14px;
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

  }
  .right {
    height: 100%;
    padding-right: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #1b1b1b;

    >div {
      border-right: 1px solid #1b1b1b;
      height: 15px;
      line-height: 15px;
      padding: 0 10px;
      box-sizing: border-box;

      &:first-child {
        padding-left: 0px;
      }

      &:last-child {
        padding-right: 0px;
        border-right: 0px;
      }
    }
  }
}
</style>