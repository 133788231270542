<template>
  <div class="message_warp" :style="'padding-bottom: ' + warpPaddingBottom + 'px; padding-top: ' + warpPaddingTop + 'px;'">

    <div class="message-header">
      <div class="title">
        {{pageTitle}}
      </div>
    </div>

    <div class="current-job-card" v-if="userType === 'employee' && chatSessionInfo && chatSessionInfo.user_id !== '000001' && chatSessionInfo.user_id !== '000002'">
      <div class="left">
        <span>当前沟通岗位</span>
        <span class="highlight" v-if="currentJobCardData && currentJobCardData.jobName">{{currentJobCardData.jobName}}</span>
        <span v-if="currentJobCardData && currentJobCardData.salaryRange">{{currentJobCardData.salaryRange}}</span>
        <span v-if="currentJobCardData && currentJobCardData.jobAddress && currentJobCardData.jobAddress.length > 0">
          {{currentJobCardData.jobAddress[1]}} - {{currentJobCardData.jobAddress[2]}}
        </span>
      </div>
      <div class="right">
        <el-button type="text" @click="toJobDetails(currentJobCardData.jobId)">
          <span>查看详情</span>
        </el-button>
      </div>
    </div>

    <div class="message-content" id="messageContentScroll" @scroll="scrollEvent">
      <ul>
        <li v-for="(item, index) in listData" :key="'messageList' + index" :class="'message-item-' + item.id"
              v-if="userType !== 'hr' || item.content.type !== 'current-job-card'">

            <div class="message-item-body" v-if="!item.is_recall">

              <div class="time" v-if="item.timeIsShow">
                {{item.create_time}}
              </div>

              <div class="message-value">

                <div class="left" @click="clickThitherPicture">
                  <el-avatar v-if="item.sender !== myChatUserId && item.content.type !== 'current-job-card'" shape="square" :src="chatSessionInfo.avatar"></el-avatar>
                </div>

                <div :class="item.sender === myChatUserId?'center my-message':'center  no-message'" :style="item.sender === myChatUserId?'justify-content: flex-end;':'justify-content: flex-start;'">

                  <!--       文本消息         -->
                  <div class="message-text" v-if="item.content.type === 'text'">
                    <div class="message-loading" v-if="item.sender === myChatUserId && item.content.uploading === true" :style="item.sender === myChatUserId?'left: -26px;':'right: -26px;'">
                      <img src="../../assets/icon/loading.gif">
                    </div>
                    <div class="message-state" v-if="item.sender === myChatUserId && item.is_error === true" :style="item.sender === myChatUserId?'left: -26px;':'right: -26px;'">
                      <img src="../../assets/icon/send-message-error.png">
                    </div>
                    <div class="message-have-read" v-if="item.sender === myChatUserId && !item.content.uploading && item.have_read === true && item.is_error === false" :style="item.sender === myChatUserId?'left: -36px;':'right: -36px;'">
                      已读
                    </div>
                    <div class="message-have-read" v-if="item.sender === myChatUserId && !item.content.uploading && item.have_read === false && item.is_error === false" :style="item.sender === myChatUserId?'left: -36px;':'right: -36px;'">
                      未读
                    </div>

                    <div class="text-content" @mousedown="(e) => messageOnmousedown(e, item, 'text-content')">
                      {{item.content.text}}
                    </div>
                  </div>

                  <!--       图片消息         -->
                  <div class="message-img" v-if="item.content.type === 'image'" @mousedown="(e) => messageOnmousedown(e, item, 'message-img')">
                    <div class="message-loading" v-if="item.sender === myChatUserId && item.content.uploading === true">
                      <i class="el-icon-loading"></i>
                    </div>
                    <div class="message-state" v-if="item.sender === myChatUserId && item.is_error === true" :style="item.sender === myChatUserId?'left: -26px;':'right: -26px;'">
                      <img src="../../assets/icon/send-message-error.png">
                    </div>
                    <div class="message-have-read" v-if="item.sender === myChatUserId && !item.content.uploading && item.have_read === true && item.is_error === false" :style="item.sender === myChatUserId?'left: -36px;':'right: -36px;'">
                      已读
                    </div>
                    <div class="message-have-read" v-if="item.sender === myChatUserId && !item.content.uploading && item.have_read === false && item.is_error === false" :style="item.sender === myChatUserId?'left: -36px;':'right: -36px;'">
                      未读
                    </div>
                    <img class="img-content" @click="toPreviewImage(item.content.url)" :src="item.content.url">
                  </div>

                  <!--       语音消息         -->
                  <div class="message-voice" v-if="item.content.type === 'voice'">
                    <div class="message-loading" v-if="item.sender === myChatUserId && item.content.uploading === true" :style="item.sender === myChatUserId?'left: -26px;':'right: -26px;'">
                      <img src="../../assets/icon/loading.gif">
                    </div>
                    <div class="message-state" v-if="item.sender === myChatUserId && item.is_error === true" :style="item.sender === myChatUserId?'left: -26px;':'right: -26px;'">
                      <img src="../../assets/icon/send-message-error.png">
                    </div>
                    <div class="message-have-read" v-if="item.sender === myChatUserId && !item.content.uploading && item.have_read === true && item.is_error === false" :style="item.sender === myChatUserId?'left: -36px;':'right: -36px;'">
                      已读
                    </div>
                    <div class="message-have-read" v-if="item.sender === myChatUserId && !item.content.uploading && item.have_read === false && item.is_error === false" :style="item.sender === myChatUserId?'left: -36px;':'right: -36px;'">
                      未读
                    </div>
                    <div class="voice-content" @mousedown="(e) => messageOnmousedown(e, item, 'voice-content')">
                      <m-audio :src="item.content.url"></m-audio>
                    </div>
                  </div>

                  <!--       岗位卡片消息         -->
                  <div class="message-job-card" v-if="item.content.type === 'job-card'" @click="toJobDetails(item.content.data.jobId)" @mousedown="(e) => messageOnmousedown(e, item, 'message-job-card')">
                    <div class="message-loading" v-if="item.sender === myChatUserId && item.content.uploading === true" :style="item.sender === myChatUserId?'left: -26px;':'right: -26px;'">
                      <img src="../../assets/icon/loading.gif">
                    </div>
                    <div class="message-state" v-if="item.sender === myChatUserId && item.is_error === true" :style="item.sender === myChatUserId?'left: -26px;':'right: -26px;'">
                      <img src="../../assets/icon/send-message-error.png">
                    </div>
                    <div class="message-have-read" v-if="item.sender === myChatUserId && !item.content.uploading && item.have_read === true && item.is_error === false" :style="item.sender === myChatUserId?'left: -36px;':'right: -36px;'">
                      已读
                    </div>
                    <div class="message-have-read" v-if="item.sender === myChatUserId && !item.content.uploading && item.have_read === false && item.is_error === false" :style="item.sender === myChatUserId?'left: -36px;':'right: -36px;'">
                      未读
                    </div>
                    <div class="card-content">

                      <div class="title">
                        <div v-if="item.content.data && item.content.data.jobName" class="name">
                          {{item.content.data.jobName}}
                        </div>
                        <div v-if="item.content.data && item.content.data.salaryRange" class="salary">
                          {{item.content.data.salaryRange}}
                        </div>
                      </div>
                      <div v-if="item.content.data && item.content.data.companyName" class="enterprise">
                        {{item.content.data.companyName}}
                      </div>
                      <div class="tag">
                        <div v-if="item.content.data && item.content.data.jobType === 1" class="tag-item2">
                          红包岗位
                        </div>
                        <div class="tag-item" v-if="item.content.data && item.content.data.jobAddress && item.content.data.jobAddress.length >= 2">
                          <div class="city">{{item.content.data.jobAddress[1]}}</div> · <div class="area">{{item.content.data.jobAddress[2]}}</div>
                        </div>
                        <div class="tag-item" v-if="item.content.data && item.content.data.experienceRequirementRange">
                          {{item.content.data.experienceRequirementRange}}
                        </div>
                        <div class="tag-item" v-if="item.content.data && item.content.data.academicRequirement">
                          {{item.content.data.academicRequirement}}
                        </div>
                        <div class="tag-item" v-if="item.content.data && item.content.data.ageRequirementRange">
                          {{item.content.data.ageRequirementRange}}
                        </div>
                      </div>
                      <div class="describe" v-if="item.content.data && item.content.data.description">
                        <div>
                          岗位职责：
                        </div>
                        <div class="describe-content">
                          {{item.content.data.description}}
                        </div>
                      </div>
                      <div class="welfare" v-if="item.content.data && item.content.data.jobWelfare && item.content.data.jobWelfare.length > 0">
                        <div class="welfare-item" v-for="(welfare, welfareIndex) in item.content.data.jobWelfare" :key="'welfare' + welfareIndex">
                          {{welfare}}
                        </div>
                      </div>

                    </div>
                  </div>

                  <!--       简历卡片消息         -->
                  <div class="message-resume-card" v-if="item.content.type === 'resume-card'" @click="toEmployeeDetails(item.content.data.employeeId)" @mousedown="(e) => messageOnmousedown(e, item, 'message-resume-card')">
                    <div class="message-loading" v-if="item.sender === myChatUserId && item.content.uploading === true" :style="item.sender === myChatUserId?'left: -26px;':'right: -26px;'">
                      <img src="../../assets/icon/loading.gif">
                    </div>
                    <div class="message-state" v-if="item.sender === myChatUserId && item.is_error === true" :style="item.sender === myChatUserId?'left: -26px;':'right: -26px;'">
                      <img src="../../assets/icon/send-message-error.png">
                    </div>
                    <div class="message-have-read" v-if="item.sender === myChatUserId && !item.content.uploading && item.have_read === true && item.is_error === false" :style="item.sender === myChatUserId?'left: -36px;':'right: -36px;'">
                      已读
                    </div>
                    <div class="message-have-read" v-if="item.sender === myChatUserId && !item.content.uploading && item.have_read === false && item.is_error === false" :style="item.sender === myChatUserId?'left: -36px;':'right: -36px;'">
                      未读
                    </div>
                    <div class="card-content">

                      <div class="title">
                        <div class="name" v-if="item.content.data && item.content.data.name">
                          {{item.content.data.name}}
                        </div>
                        <div class="salary" v-if="item.content.data && item.content.data.expectedSalaryRange">
                          {{item.content.data.expectedSalaryRange}}
                        </div>
                      </div>
                      <div class="job-expectations">
                        <div class="place" v-if="item.content.data && item.content.data.expectedJobAddressList && item.content.data.expectedJobAddressList.length > 0">
                          <div class="place-title">期望地区：</div>
                          <div class="place-item" v-for="(expectArea, expectAreaIndex) in item.content.data.expectedJobAddressList" :key="'expectArea' + expectAreaIndex">{{expectArea}}</div>
                        </div>
                        <div class="job" v-if="item.content.data && item.content.data.expectedJobList && item.content.data.expectedJobList.length > 0">
                          <div class="job-title">期望岗位：</div>
                          <div class="job-item" v-for="(jobName, jobNameIndex) in item.content.data.expectedJobList" :key="'jobName' + jobNameIndex">{{jobName}}</div>
                        </div>
                      </div>
                      <div class="information">
                        <div class="information-item" v-if="item.content.data && item.content.data.gender">
                          <img class="information-item-img" src="@/assets/icon/gender.png">
                          <div>{{item.content.data.gender}}</div>
                        </div>
                        <div class="information-item" v-if="item.content.data && item.content.data.age">
                          <img class="information-item-img" src="@/assets/icon/birthday.png">
                          <div>{{item.content.data.age}}</div>
                        </div>
                        <div class="information-item" v-if="item.content.data && item.content.data.workExperienceYears">
                          <img class="information-item-img" src="@/assets/icon/work-experience.png">
                          <div>{{item.content.data.workExperienceYears}}</div>
                        </div>
                        <div class="information-item" v-if="item.content.data && item.content.data.academicLevelMax">
                          <img class="information-item-img" src="@/assets/icon/education.png">
                          <div>{{item.content.data.academicLevelMax}}</div>
                        </div>
                        <div class="information-item" v-if="item.content.data && item.content.data.jobSearchingStatus">
                          <img class="information-item-img" src="@/assets/icon/clock.png">
                          <div>{{item.content.data.jobSearchingStatus}}</div>
                        </div>
                        <div class="information-item" v-if="item.content.data && item.content.data.employeePhone">
                          <img class="information-item-img" src="@/assets/icon/phone4.png">
                          <div>{{item.content.data.employeePhone}}</div>
                        </div>
                      </div>
                      <div class="describe" v-if="item.content.data && item.content.data.selfIntroduction">
                        <div class="describe-title">
                          自我介绍：
                        </div>
                        <div class="describe-content">
                          {{item.content.data.selfIntroduction}}
                        </div>
                      </div>
                      <div class="welfare" v-if="item.content.data && item.content.data.personalHighLightList && item.content.data.personalHighLightList.length > 0">
                        <div class="welfare-item" v-for="(highlight, highlightIndex) in item.content.data.personalHighLightList" :key="'highlights' + highlightIndex">
                          {{highlight}}
                        </div>
                      </div>

                    </div>
                  </div>

                  <!--       系统图文卡片消息         -->
                  <div class="image-or-text-card" v-if="item.content.type === 'image-or-text-card'">
                    <div class="image-or-text-card-content">
                      <div class="image-or-text-card-title" v-if="item.content && item.content.data && item.content.data.title">
                        {{item.content.data.title}}
                      </div>
                      <div class="img" v-if="item.content && item.content.data && item.content.data.imgUrl">
                        <img :src="item.content.data.imgUrl">
                      </div>
                      <div class="msg" v-if="item.content && item.content.data && item.content.data.msg">
                        {{item.content.data.msg}}
                      </div>
                      <div class="action" v-if="item.content && item.content.data && item.content.data.btnText" @click="clickCardAction(item.content)">
                        <div class="action-left">
                          {{item.content.data.btnText}}
                        </div>
                        <div class="action-right">
                          <img src="../../assets/icon/right-arrow.png">
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--       系统文本卡片消息         -->
                  <div class="text-card" v-if="item.content.type === 'text-card'">
                    <div class="text-card-content">
                      <div class="text-card-title">
                        {{item.content.data.title}}
                      </div>
                      <div class="msg" v-if="item.content && item.content.data && item.content.data.msg">
                        {{item.content.data.msg}}
                      </div>
                      <div class="action" v-if="item.content && item.content.data && item.content.data.btnText" @click="clickCardAction(item.content)">
                        <div class="action-left">
                          {{item.content.data.btnText}}
                        </div>
                        <div class="action-right">
                          <img src="../../assets/icon/right-arrow.png">
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--         提示消息           -->
                  <div class="current-job-card-warp" v-if="item.content.type === 'current-job-card' && userType === 'employee'">
                    <div class="marked-words" v-if="item.content.data && item.content.data.markedWords">
                      <span>{{item.content.data.markedWords}}</span>
                    </div>
                  </div>

                </div>

                <div class="right" @click="clickMyPicture">
                  <el-avatar v-if="item.sender === myChatUserId && item.content.type !== 'current-job-card'" shape="square" :src="myAvatar"></el-avatar>
                </div>

              </div>

            </div>

            <div class="message-item-body" v-if="item.is_recall">

              <div class="time">
                <span v-if="item.sender === myChatUserId">消息已撤回</span>
                <span v-if="item.sender !== myChatUserId">对方消息已撤回</span>
              </div>

            </div>

          </li>
        <li>
          <div class="loading">
            <i v-if="loadMoreStatus === 'loading'" class="el-icon-loading"></i>
            {{loadingText}}
          </div>
        </li>
      </ul>
    </div>

    <preview-image-or-video title="" :preview-is-show.sync="previewIsShow" type="image" :url="previewUrl"></preview-image-or-video>

  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import share from '../../share/share'
import PreviewImageOrVideo from "@/components/previewImage/previewImageOrVideo";
import {JobDetailApi, JobHrSeeJobDetailApi} from "@/request/api";
import {experience} from "@/share/shareData";
export default {
  name: 'message',
  components: {PreviewImageOrVideo},
  props: {
    warpPaddingBottom: {
      type: [Number, String],
      default: 0
    },
    // jobId: {
    //   type: [Number, String],
    //   default: ''
    // }
  },
  data() {
    return {
      listData: [],
      isFirst: true,
      isEnoughData: false,
      loadingText: '上拉显示更多',  // 没有更多数据了 、 正在加载...
      loadMoreStatus: 'more',  // more、noMore、loading
      previewIsShow: false,
      previewUrl: '',
      scrollDiv: null,
      scrollTop: '',
      readyNum: 0,
      pageTitle: '',
      currentJobCardData: null,
      messageMenuTargetDom: null,

      messageActionLoading: false,
    }
  },
  computed: {
    hrCurrentRepresentative() {
      return this.$store.state.hrCurrentRepresentative
    },
    jobId() {
      return this.hrCurrentRepresentative.value
    },
    userType() {
      return this.$store.state.userInfo.userType
    },
    myAvatar() {
      return this.$store.state.userInfo.profilePhoto
    },
    aonuoChat() {
      return this.$store.state.aonuoChat
    },
    aonuoChatReady() {
      return this.$store.state.aonuoChatReady
    },
    myChatUserId() {
      return this.$store.state.myChatUserId
    },
    chatSessionId() {
      return this.$store.state.chatSessionId
    },
    chatSession() {
      return this.$store.state.chatSession
    },
    chatSessionInfo() {
      return this.$store.state.chatSessionInfo
    },
    warpPaddingTop() {
      if (this.userType === 'employee' && this.chatSessionInfo && this.chatSessionInfo.user_id !== '000001' && this.chatSessionInfo.user_id !== '000002') {
        return 85
      }else {
        return 55
      }
    }
  },
  filters: {
    time (value) {
      let date = new Date(parseInt(value) * 1000)
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? "0" + m : m
      let d = date.getDate()
      d = d < 10 ? "0" + d : d
      let h = date.getHours()
      h = h < 10 ? "0" + h : h
      let minute = date.getMinutes()
      let second = date.getSeconds()
      minute = minute < 10 ? "0" + minute : minute
      second = second < 10 ? "0" + second : second
      return m + "月" + d + "日 " + h + ":" + minute
    }
  },
  directives: {
    // /**
    //  * 自定义指令，发送消息后滚动到底部
    //  * */
    // "scroll-bottom"(el) {
      //注意：Vue 要在当前页面 import Vue from 'vue'
      // Vue.nextTick(() => {
      //   el.scrollTop = el.scrollHeight - el.clientHeight;
      // });
    // }
  },
  watch: {
    aonuoChatReady: {
      handler: async function () {
        if (this.aonuoChatReady) {
          this.readyNum++
          await this.setMessageRead()

          this.loadMessageUnreadCount()
        }
      },
    },
    // async aonuoChatReady() {
    //   if (this.aonuoChatReady) {
    //     this.readyNum++
    //   }
      //
      // if (this.aonuoChatReady && this.listData.length === 0) {
      //   if (this.chatSessionId !== undefined && this.chatSessionId !== '' && this.chatSessionId !== null && this.chatSessionId !== 'null') {
      //     await this.init()
      //   }
      // }
    // },
    readyNum: {
      handler: async function () {
        if (this.readyNum >= 1 && this.aonuoChatReady && this.listData.length === 0) {
          if (this.chatSessionId !== undefined && this.chatSessionId !== '' && this.chatSessionId !== null && this.chatSessionId !== 'null') {
            await this.init()
          }
        }
      },
      immediate: true
    },
    async chatSessionId() {
      this.listData = []

      await this.setMessageRead()

      this.loadMessageUnreadCount()

      // await this.loadListData()
      if (this.chatSessionId !== undefined && this.chatSessionId !== '' && this.chatSessionId !== null && this.chatSessionId !== 'null') {
        if (this.userType === 'employee' && this.chatSessionInfo.name) {
          this.pageTitle = this.chatSessionInfo.name
        }else if (this.userType === 'hr' && this.chatSessionInfo.job_information.jobName) {
          this.pageTitle = this.chatSessionInfo.name + '(' + this.chatSessionInfo.job_information.jobName + ')'
        }else {
          this.pageTitle = this.chatSessionInfo.name
        }
        await this.init()
      }
    },
  },
  async mounted() {
    window.oncontextmenu = (e) => {
      //取消默认的浏览器自带右键 很重要！！
      e.preventDefault();
    }
    //鼠标左键任意位置单击, 关闭右键菜单
    window.onclick = (e) => {
      if (this.messageMenuTargetDom) {
        let domArr = document.getElementsByClassName('messageMenuTargetDom')
        if (domArr && domArr.length > 0) {
          this.messageMenuTargetDom.removeChild(domArr[0])
          this.messageMenuTargetDom = null
        }
      }
    }

    /**
     * 放置全局监听事件
     * */
    this.$bus.$on('MESSAGE_RECEIVED', this.messageReceived1)
    this.$bus.$on('MESSAGE_SEND_SUCCESS', this.messageSendSuccess1)
    this.$bus.$on('MESSAGE_BLOCKED_ERROR', this.messageBlockedError1)
    this.$bus.$on('MESSAGE_READ_BY_PEER', this.messageReadByPeer1)
    this.$bus.$on('MESSAGE_REVOKED', this.messageRevoked1)

    //获取节点
    this.scrollDiv = document.getElementById('messageContentScroll')

    //绑定事件
    this.scrollDiv.addEventListener('scroll', () => {
      this.scrollTop = this.scrollDiv.scrollTop
      this.scrollEvent()
    })

    // if (this.aonuoChatReady && this.listData.length === 0) {
    //   if (this.chatSessionId !== undefined && this.chatSessionId !== '' && this.chatSessionId !== null && this.chatSessionId !== 'null') {
    //     await this.init()
    //   }
    // }

    if (this.aonuoChatReady) {
      this.readyNum++
      await this.setMessageRead()
      this.loadMessageUnreadCount()
    }

    if (this.chatSessionId !== undefined && this.chatSessionId !== '' && this.chatSessionId !== null && this.chatSessionId !== 'null') {
      if (this.userType === 'employee' && this.chatSessionInfo.name) {
        this.pageTitle = this.chatSessionInfo.name
      }else if (this.userType === 'hr' && this.chatSessionInfo.job_information.jobName) {
        this.pageTitle = this.chatSessionInfo.name + '(' + this.chatSessionInfo.job_information.jobName + ')'
      }
    }

  },
  destroyed() {
    // this.setChatSessionId({
    //   chatSessionId: ''
    // })
    // this.setChatSession({
    //   chatSession: null
    // })

    this.scrollDiv.removeEventListener('scroll', this.scrollEvent())
    this.$bus.$off('MESSAGE_RECEIVED', this.messageReceived1)
    this.$bus.$off('MESSAGE_SEND_SUCCESS', this.messageSendSuccess1)
    this.$bus.$off('MESSAGE_BLOCKED_ERROR', this.messageBlockedError1)
    this.$bus.$off('MESSAGE_READ_BY_PEER', this.messageReadByPeer1)
    this.$bus.$off('MESSAGE_REVOKED', this.messageRevoked1)
  },
  methods: {
    ...mapMutations(['setChatSession', 'setChatSessionId', 'setMessageUnreadCount']),

    /**
     * 消息通道收到一条消息时触发
     * */
    async messageReceived1(data) {
      console.log('消息通道收到了一条消息1', data)

      if(data.session.session_id === this.chatSessionId) {
        let timeIsShow = true
        if (data.create_timestamp - this.listData[0].create_timestamp < 60 * 5 * 1000) {
          timeIsShow = false
        }
        let message = {
          id: data.id,
          content: data.content,
          sender: data.sender,
          create_time: data.create_time,
          create_timestamp: data.create_timestamp,
          timeIsShow: timeIsShow,
          have_read: data.have_read,
          is_error: false,
          read_time: data.read_time,
        }
        this.listData.unshift(message)

        this.$nextTick(() => {
          this.goBottom()
        })
      }

      await this.setMessageRead()
      this.loadMessageUnreadCount()
    },

    /**
     * 消息通道发送一条消息成功时触发
     * */
    messageSendSuccess1(data) {
      console.log('消息通道发送一条消息成功1', data)

      if(data.session.session_id === this.chatSessionId) {

        try{
          this.listData.forEach((item, index) => {
            if (item.content.id) {
              if (item.content.id < data.content.id - 6000000) {
                throw Error()
              } else if (item.content.id === data.content.id) {
                this.listData.splice(index, 1)
                throw Error()
              }
            }else {
              throw Error()
            }
          })
        }catch(e){
          console.log("结束了")
        }

        let timeIsShow = true
        if (this.listData.length > 0 && data.create_timestamp - this.listData[0].create_timestamp < 60 * 5 * 1000) {
          timeIsShow = false
        }
        let message = {
          id: data.id,
          content: data.content,
          sender: data.sender,
          create_time: data.create_time,
          create_timestamp: data.create_timestamp,
          timeIsShow: timeIsShow,
          have_read: data.have_read,
          is_error: false,
          read_time: data.read_time,
        }
        this.listData.unshift(message)
      }
    },

    /**
     * 被拉黑导致消息发送失败的响应
     * */
    async messageBlockedError1(data) {
      console.log('被拉黑导致消息发送失败的响应1', data)

      if(data.session.session_id === this.chatSessionId) {

        try{
          this.listData.forEach((item, index) => {
            if (item.content.id) {
              if (item.content.id < data.content.id - 6000000) {
                throw Error()
              } else if (item.content.id === data.content.id) {
                this.listData.splice(index, 1)
                throw Error()
              }
            }else {
              throw Error()
            }
          })
        }catch(e){
          console.log("结束了")
        }

        let timeIsShow = true
        if (this.listData.length > 0 && data.create_timestamp - this.listData[0].create_timestamp < 60 * 5 * 1000) {
          timeIsShow = false
        }
        let message = {
          id: data.id,
          content: data.content,
          sender: data.sender,
          create_time: data.create_time,
          create_timestamp: data.create_timestamp,
          timeIsShow: timeIsShow,
          have_read: data.have_read,
          is_error: true,
          read_time: data.read_time,
        }
        this.listData.unshift(message)

        share.message.error('消息已发出，但被对方拒收了')
      }
    },

    /**
     * 收到对端已读消息的通知
     * */
    async messageReadByPeer1(data) {
      console.log('收到对端已读消息的通知1', data)

      if (data.session.session_id === this.chatSessionId) {
        this.listData.forEach((item, index) => {
          if (!item.have_read) {
            item.have_read = true
          }
        })
      }
    },

    /**
     * 收到消息被撤回的通知
     * */
    async messageRevoked1(data) {
      console.log('收到消息被撤回的通知1', data)
      if (data.session.session_id === this.chatSessionId) {
        try{
          this.listData.forEach((item, index) => {
            if (item.id === data.id) {
              this.$set(item, 'is_recall', true)
              throw Error()
            }
          })
        }catch(e){
          console.log("结束了")
        }
      }
    },

    async init() {

      await this.loadListData()
      // if (this.listData.length >= 15) {
      //   this.isEnoughData = true
      // }

      // this.listData.push({
      //
      // })

      if (this.userType === 'employee' && this.chatSessionInfo && this.chatSessionInfo.user_id !== '000001' && this.chatSessionInfo.user_id !== '000002') {
        await this.loadCurrentJobCardData()
      }

      this.$nextTick(() => {
        this.goBottom()
        this.isFirst = false
      })

    },

    /**
     * 获取当前应聘岗位卡片数据
     * */
    async loadCurrentJobCardData() {
      const res = await JobDetailApi({
        jobId: this.chatSessionInfo.job_information.jobId
      })
      if (res) {
        let obj = {
          jobId: res.jobInformation.jobId,  // 岗位id
          jobType: res.jobInformation.jobType,  // 岗位类型，枚举值
          jobName: res.jobInformation.jobName,  // 岗位名字
          jobAddress: res.jobInformation.jobAddress,  // 工作地点列表
          detailAddress: res.jobInformation.detailAddress,  // 详细地址
          salaryRange: res.jobInformation.salaryRange,  // 薪资待遇
          experienceRequirementRange: res.jobInformation.experienceRequirementRange,  // 工作经验
          ageRequirementRange: res.jobInformation.ageRequirementRange,  // 年龄要求
          academicRequirement: res.jobInformation.academicRequirement,  // 学历要求
          jobWelfare: res.jobInformation.jobWelfare,  // 岗位福利
          description: res.jobInformation.description,  // 岗位描述
        }
        obj = this.hrInitCardData(obj)
        this.currentJobCardData = obj
        console.log('currentJobCardData', this.currentJobCardData)
      }
    },

    hrInitCardData(data) {
      let obj = data
      if (obj.salaryRange && obj.salaryRange.length > 1) {
        obj.salaryRange = parseInt(obj.salaryRange[0])/1000 + 'k-' + parseInt(obj.salaryRange[1])/1000 + 'k'
      }else {
        obj.salaryRange = '面议'
      }
      if (obj.experienceRequirementRange && obj.experienceRequirementRange.length > 1) {
        let value = obj.experienceRequirementRange[0] + '-' + obj.experienceRequirementRange[1]
        experience.forEach((item, index) => {
          if (item.value === value) {
            obj.experienceRequirementRange = item.label + '经验'
          }
        })
      }else {
        obj.experienceRequirementRange = '经验不限'
      }
      if (obj.ageRequirementRange && obj.ageRequirementRange.length > 1) {
        // let value = obj.ageRequirementRange[0] + '-' + obj.ageRequirementRange[1]
        // ageRange.forEach((item, index) => {
        //   if (item.value === value) {
        //     obj.ageRequirementRange = item.label
        //   }
        // })
        obj.ageRequirementRange = obj.ageRequirementRange[0] + '岁-' + obj.ageRequirementRange[1] + '岁'
      }else {
        obj.ageRequirementRange = '年龄不限'
      }

      return obj
    },

    /**
     * 获取聊天记录列表
     * */
    async loadListData() {
      // if (this.loadMoreStatus === 'loading') {
      //   return
      // }
      // if (this.isEnoughData) {
      //   this.loadMoreStatus = 'loading'
      //   this.loadingText = '正在加载...'
      // }

      this.loadMoreStatus = 'loading'
      this.loadingText = '正在加载...'

      let className = ''
      if (this.listData.length > 0) {
        className = 'message-item-' + this.listData[this.listData.length - 1].id
      }

      let scrollHeight = this.scrollDiv.scrollHeight

      let offset = this.listData.length
      let length = 15
      const res = await this.aonuoChat.getMessageList(this.chatSessionId, offset, length)
      let num_of_chat_logs = 0
      if (res) {
        let list = res.chat_log_info_list
        num_of_chat_logs = res.num_of_chat_logs
        list = this.initList(list)
        this.listData = [...this.listData, ...list]
      }

      if (this.listData.length >= 15) {
        this.isEnoughData = true
      }

      // if (!this.listData.length >= 15) {
      //   return
      // }

      this.$nextTick(() => {
        setTimeout(() => {
          if (num_of_chat_logs > this.listData.length) {
            this.loadMoreStatus = 'more'
            this.loadingText = '上拉显示更多'
          }else {
            this.loadMoreStatus = 'noMore'
            this.loadingText = '没有更多数据了'
          }
        }, 500)
        if (!this.isFirst) {
          this.fixedMessagePosition(className, scrollHeight)
        }
      })
    },

    /**
     * 初始化聊天记录列表
     * */
    initList(arr) {
      let newArr = arr
      if (newArr.length > 1) {
        newArr.forEach((item, index) => {
          if (index < newArr.length - 1) {
            if (item.create_timestamp - newArr[index + 1].create_timestamp < 60 * 5 * 1000) {
              item.timeIsShow = false
            }else {
              item.timeIsShow = true
            }
          }else {
            item.timeIsShow = true
          }
        })
      }else if (newArr.length === 1) {
        newArr[0].timeIsShow = true
      }

      return arr
    },

    /**
     * 初始化时间是否显示
     * */
    timeIsShow(time1, time2) {
      if (time2 && time2.time) {
        let time = time1 - time2.time
        if (time > 60 * 5) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },

    fixedMessagePosition(className, scrollHeight) {
      let currScrollHeight = this.scrollDiv.scrollHeight
      this.scrollDiv.scrollTop = currScrollHeight - scrollHeight + this.scrollTop
    },

    /**
     * 设置会话已读
     * */
    async setMessageRead() {
      if (this.chatSessionId !== '' && this.chatSessionId !== undefined && this.chatSessionId !== null) {
        const res = await this.aonuoChat.setMessageRead(this.chatSessionId)
      }
    },

    async loadMessageUnreadCount() {
      const res = await this.aonuoChat.getMessageUnreadCount()
      if (res) {
        this.setMessageUnreadCount({
          messageUnreadCount: res.unread_count
        })
      }
    },

    /**
     * 图片视频预览
     * */
    toPreviewImage(url) {
      this.previewUrl = url
      this.previewIsShow = true
    },

    /**
     * 列表滚动时触发
     * */
    scrollEvent() {
      if (this.messageMenuTargetDom) {
        let domArr = document.getElementsByClassName('messageMenuTargetDom')
        if (domArr && domArr.length > 0) {
          this.messageMenuTargetDom.removeChild(domArr[0])
          this.messageMenuTargetDom = null
        }
      }

      if (this.isFirst) {
        return;
      }
      if (!this.isEnoughData || this.loadMoreStatus === 'loading' || this.loadMoreStatus === 'noMore') {
        return
      }
      if (this.scrollTop !== '' && this.scrollTop <= 0) {
        this.loadListData()
      }

    },

    /**
     * 回到列表底部
     * */
    goBottom() {
      this.scrollDiv.scrollTop = 9999999
    },

    /**
     * 为消息列表头部添加数据
     * */
    listUnshift(obj) {
      this.listData.unshift(obj)
      setTimeout(() => {
        // this.$nextTick(() => {
        this.goBottom()
        // })
      }, 100)
    },

    listDelItem(obj) {
      this.listData.forEach((item, index) => {
        if (item.content.id < obj.content.id) {
        } else if (item.content.id === obj.content.id) {
          this.listData.splice(index, 1)
        }
      })
    },

    /**
    * 点击对方头像
    * */
    clickThitherPicture() {
      if (this.userType === 'hr') {
        let employeeId = this.chatSessionInfo.user_id.split('-')[1]
        this.hrToJobSeekerDetails(employeeId)
      }
      if (this.userType === 'employee') {
        let jobId = this.chatSessionInfo.job_information.jobId
        this.toJobDetails(jobId)
      }
    },

    /**
     * 点击我的头像
     * */
    clickMyPicture() {
      if (this.userType === 'hr') {
        let jobId = this.chatSessionInfo.job_information.jobId
        this.hrToHrSeeJobDetails(jobId)
      }
      if (this.userType === 'employee') {
        this.employeeToJobSeekerResume()
      }
    },

    /**
     * 去求职者简历页
     * */
    toEmployeeDetails(employeeId) {
      if (this.userType === 'hr') {
        this.hrToJobSeekerDetails(employeeId)
      }
      if (this.userType === 'employee') {
        this.employeeToJobSeekerResume()
      }
    },

    /**
     * 求职者去简历详情页
     * */
    employeeToJobSeekerResume() {
      this.$router.push('/myResume')
    },

    /**
     * hr去简历详情页
     * */
    hrToJobSeekerDetails(employeeId) {
      if (!this.jobId) {
        share.message.warning('您当前没有发布岗位')
        return
      }
      this.$router.push({
        path: '/resumeDetails',
        query: {
          id: employeeId,
          jobId: this.jobId,
        }
      })
    },

    /**
     * 去岗位详情页
     * */
    toJobDetails(jobId) {
      if (this.userType === 'hr') {
        this.hrToHrSeeJobDetails(jobId)
      }
      if (this.userType === 'employee') {
        this.employeeToJobDetails(jobId)
      }
    },

    /**
     * hr去岗位详情页
     * */
    hrToHrSeeJobDetails(jobId) {
      this.$router.push({
        path: '/hrSeeJobDetailed',
        query: {
          id: jobId
        }
      })
    },

    /**
     * 求职者去岗位详情页
     * */
    employeeToJobDetails(jobId) {
      this.$router.push({
        path: '/jobDetailed',
        query: {
          id: jobId
        }
      })
    },

    /**
    * 去卡片指定的页面
    * */
    clickCardAction(obj) {
      if (obj.data && obj.data.toPage) {
        let data = share.getEnumeratePagePath(obj.data.toPage, obj.data.param)
        this.$router.push({
          path: data.path,
          query: data.param
        })
      }
    },

    /**
    * 点击消息时鼠标按压事件
    * */
    messageOnmousedown(e, data, className) {
      if (data.sender !== this.myChatUserId) {
        return
      }
      if (e.button === 2) {
        if (this.messageMenuTargetDom) {
          let domArr = document.getElementsByClassName('messageMenuTargetDom')
          if (domArr && domArr.length > 0) {
            this.messageMenuTargetDom.removeChild(domArr[0])
            this.messageMenuTargetDom = null
          }
        }
        this.$nextTick(() => {
          let targetDom = e.path[0]
          while (targetDom.className.indexOf(className) === -1) {
            targetDom = targetDom.parentNode
          }

          // let targetDom = e.path[0]
          this.messageMenuTargetDom = targetDom
          let right = targetDom.offsetWidth + 60

          let dom = document.createElement('div')
          dom.className = 'messageMenuTargetDom'
          dom.style.cssText = `width: 100px; height: 30px; position: absolute; right: ${right}px; top: 0px; white-space: normal; direction: rtl;`
          let innerHTML1 = `
<div style="width: 100px; height: 30px; display: flex; align-items: center; justify-content: center;
background-color: #4b4b4b; color: #FFFFFF; white-space: normal; direction: rtl; border-radius: 5px; position: relative;">
  <span class="messageItemActions" style="flex: 1; text-align: center; line-height: 20px; font-size: 12px; cursor: pointer;" data-messageid="${data.id}" data-actiontype="1">复制</span>
  <div style="width: 1px; height: 20px; background-color: #ffffff;"></div>
  <span class="messageItemActions" style="flex: 1; text-align: center; line-height: 20px; font-size: 12px; cursor: pointer;" data-messageid="${data.id}" data-actiontype="2">撤回</span>
  <div class="arrows" style="position: absolute; right: -18px; top: 5px; height: 0; width: 0;
  border: 10px solid #4b4b4b; border-color: transparent transparent transparent #4b4b4b;"></div>
</div>
`
          let innerHTML2 = `
<div style="width: 100px; height: 30px; display: flex; align-items: center; justify-content: center;
background-color: #4b4b4b; color: #FFFFFF; white-space: normal; direction: rtl; border-radius: 5px; position: relative;">
  <span class="messageItemActions" style="flex: 1; text-align: center; line-height: 20px; font-size: 12px; cursor: pointer;" data-messageid="${data.id}" data-actiontype="2">撤回</span>
  <div class="arrows" style="position: absolute; right: -18px; top: 5px; height: 0; width: 0;
  border: 10px solid #4b4b4b; border-color: transparent transparent transparent #4b4b4b;"></div>
</div>
`
          dom.innerHTML = innerHTML1
          targetDom.appendChild(dom)

          this.$nextTick(() => {
            let messageItemActionsDom = document.getElementsByClassName('messageItemActions')
            for (let i = 0; i < messageItemActionsDom.length; i++) {
              messageItemActionsDom[i].onclick = this.toMessageRevoked
            }
          })

        })
      }
    },

    /**
    * 撤回消息
    * */
    async toMessageRevoked(e) {
      e.stopPropagation()
      if (this.messageActionLoading) {
        return
      }
      this.messageActionLoading = true
      let messageId = e.target.dataset.messageid
      let actionType = e.target.dataset.actiontype
      if (actionType === '2') {
        const res = await this.aonuoChat.postRecallMessage(messageId)
        if (res.code === 400) {
          share.message.warning(res.message)
        }
      }
      if (actionType === '1') {
        let text = ''
        try{
          this.listData.forEach((item, index) => {
            if (item.id === parseInt(messageId)) {
              text = item.content.text
              throw Error()
            }
          })
        }catch(e){
          console.log("结束了")
        }
        // 传复制的文本
        let copyipt = document.createElement("input");
        copyipt.setAttribute("value", text);
        document.body.appendChild(copyipt);
        copyipt.select();
        document.execCommand("copy");
        document.body.removeChild(copyipt);
      }

      if (this.messageMenuTargetDom) {
        let domArr = document.getElementsByClassName('messageMenuTargetDom')
        if (domArr && domArr.length > 0) {
          this.messageMenuTargetDom.removeChild(domArr[0])
          this.messageMenuTargetDom = null
        }
      }
      this.messageActionLoading = false
    }

  }

}
</script>

<style lang="less" scoped>
.message_warp {
  width: calc(100% - 1px);
  //height: calc(100% - 200px);
  height: 100%;
  border-right: 1px solid #DCDFE6;
  box-sizing: border-box;
  position: relative;

  .message-header {
    width: 100%;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 1px solid #eaeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    background-color: #FFFFFF;
    .title {
      //text-align: center;
      color: #1b1b1b;
      font-size: 16px;
    }
  }

  .current-job-card {
    width: 100%;
    height: 30px;
    border-bottom: 1px solid #eaeaea;
    position: absolute;
    top: 50px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    font-size: 14px;
    .left {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      span {
        margin-right: 10px;
      }
      .highlight {
        color: #e20000;
      }
    }
    .right {
      span {
        color: #e20000;
      }
    }
  }

  .message-content {
    width: 100%;
    height: 100%;
    padding: 10px 15px;
    box-sizing: border-box;
    overflow-y: scroll;
    //background-color: #ededed;
    background-color: #FFFFFF;

    ul {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      direction: rtl;
      transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -o-transform: rotate(180deg);
    }

    li {
      list-style: none;
      margin-bottom: 15px;
      display: flex;
      direction: ltr;
      transform:rotate(180deg);
      -ms-transform:rotate(180deg);
      -moz-transform:rotate(180deg);
      -webkit-transform:rotate(180deg);
      -o-transform:rotate(180deg);

      .message-item-body {
        width: 100%;

        .time {
          width: 100%;
          text-align: center;
          margin-bottom: 12px;
          font-size: 14px;
          color: #999999;
        }

        .message-value {
          width: 100%;
          display: flex;
          align-items: flex-start;
          white-space: pre-line;

          .left {
            width: 40px;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            cursor: pointer;
          }
          .center {
            display: flex;
            flex: 1;
            padding: 0 10px;
            box-sizing: border-box;

            .message-text, .message-voice {
              position: relative;

              .message-loading, .message-state {
                position: absolute;
                top: 50%;
                width: 18px;
                height: 18px;
                transform: translateY(-50%);
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  width: 100%;
                  height: 100%;
                }

              }

              .message-have-read {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background-color: #fff6f6;
                padding: 0 4px;
                box-sizing: border-box;
                font-size: 12px;
                color: #BBBBBB;
              }

              .text-content {
                padding: 10px 14px;
                box-sizing: border-box;
                border-radius: 10px;
                line-height: 20px;
                font-size: 16px;
                word-break: break-all;
                text-align: left;
                position: relative;
              }

            }

            .message-img {
              cursor: pointer;
              position: relative;

              .message-loading {
                position: absolute;
                right: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: #999999;
                opacity: 0.5;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 98;

                .el-icon-loading {
                  font-size: 40px;
                }
              }

              .message-state {
                position: absolute;
                top: 50%;
                width: 18px;
                height: 18px;
                transform: translateY(-50%);
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              .message-have-read {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background-color: #fff6f6;
                padding: 0 4px;
                box-sizing: border-box;
                font-size: 12px;
                color: #BBBBBB;
              }

              .img-content {
                max-width: 400px;
                max-height: 600px;
                min-width: 80px;
                min-height: 80px;
              }

            }

            .message-job-card {
              width: 300px;
              position: relative;
              cursor: pointer;

              .message-loading {
                position: absolute;
                top: 50%;
              }
              .message-state {
                position: absolute;
                top: 50%;
                width: 18px;
                height: 18px;
                transform: translateY(-50%);
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              .message-have-read {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background-color: #fff6f6;
                padding: 0 4px;
                box-sizing: border-box;
                font-size: 12px;
                color: #BBBBBB;
              }

              .card-content {
                width: 100%;
                padding: 16px 20px;
                box-sizing: border-box;
                border-radius: 20px;
                //background-color: #ffffff;
                border: 1px solid #c5c5c5;

                .title {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  margin-bottom: 4px;
                  .name {
                    flex: 1;
                    text-align: left;
                    margin-right: 10px;
                    //overflow: hidden;
                    //text-overflow: ellipsis;
                    //white-space: nowrap;
                    font-size: 22px;
                  }
                  .salary {
                    max-width: 120px;
                    font-size: 16px;
                    color: #E82727;
                  }
                }

                .enterprise {
                  width: 100%;
                  text-align: left;
                  font-size: 16px;
                  margin-bottom: 8px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                .tag {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  align-items: flex-start;
                  justify-content: flex-start;
                  font-size: 16px;
                  margin-bottom: 2px;

                  .tag-item2 {
                    color: #E82727;
                    border: 1px solid #E82727;
                    margin: 0px 4px 4px 0;
                    padding: 2px 6px;
                    box-sizing: border-box;
                    border-radius: 5px
                  }

                  .tag-item {
                    max-width: 100%;
                    color: #333333;
                    margin: 0 4px 4px 0;
                    border: 1px solid #d7d7d7;
                    background-color: #d7d7d7;
                    padding: 2px 6px;
                    box-sizing: border-box;
                    border-radius: 5px;
                    display: flex;

                    .city {
                      flex: 1;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      margin-right: 8px;
                    }

                    .area {
                      flex: 1;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      margin-left: 8px;
                    }
                  }

                }

                .describe {
                  width: 100%;
                  text-align: left;
                  font-size: 16px;
                  margin-bottom: 8px;

                  .describe-content {
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }

                .welfare {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  align-items: flex-start;
                  justify-content: flex-start;

                  .welfare-item {
                    color: #333333;
                    margin: 0 4px 4px 0;
                    border: 1px solid #d7d7d7;
                    background-color: #d7d7d7;
                    padding: 2px 6px;
                    box-sizing: border-box;
                    border-radius: 5px;
                  }
                }

              }

            }

            .message-current-job-card {
              width: 600px;
              position: relative;
              cursor: pointer;

              .card-content {
                width: 100%;
                padding: 16px 20px;
                box-sizing: border-box;
                border-radius: 20px;
                //background-color: #ffffff;
                border: 1px solid #c5c5c5;

                .hint-text {
                  text-align: left;
                  margin-bottom: 10px;
                  font-size: 14px;
                  color: #757575;
                }

                .title {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  margin-bottom: 4px;
                  .name {
                    flex: 1;
                    text-align: left;
                    margin-right: 10px;
                    //overflow: hidden;
                    //text-overflow: ellipsis;
                    //white-space: nowrap;
                    font-size: 22px;
                  }
                  .salary {
                    max-width: 120px;
                    font-size: 16px;
                    color: #E82727;
                  }
                }

                .enterprise {
                  width: 100%;
                  text-align: left;
                  font-size: 16px;
                  margin-bottom: 8px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                .tag {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  align-items: flex-start;
                  justify-content: flex-start;
                  font-size: 16px;
                  margin-bottom: 2px;

                  .tag-item2 {
                    color: #E82727;
                    border: 1px solid #E82727;
                    margin: 0px 4px 4px 0;
                    padding: 2px 6px;
                    box-sizing: border-box;
                    border-radius: 5px
                  }

                  .tag-item {
                    max-width: 100%;
                    color: #333333;
                    margin: 0 4px 4px 0;
                    border: 1px solid #d7d7d7;
                    background-color: #d7d7d7;
                    padding: 2px 6px;
                    box-sizing: border-box;
                    border-radius: 5px;
                    display: flex;

                    .city {
                      flex: 1;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      margin-right: 8px;
                    }

                    .area {
                      flex: 1;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      margin-left: 8px;
                    }
                  }

                }

                .describe {
                  width: 100%;
                  text-align: left;
                  font-size: 16px;
                  margin-bottom: 8px;

                  .describe-content {
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }

                .welfare {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  align-items: flex-start;
                  justify-content: flex-start;

                  .welfare-item {
                    color: #333333;
                    margin: 0 4px 4px 0;
                    border: 1px solid #d7d7d7;
                    background-color: #d7d7d7;
                    padding: 2px 6px;
                    box-sizing: border-box;
                    border-radius: 5px;
                  }
                }

              }

            }

            .message-resume-card {
              width: 300px;
              position: relative;
              cursor: pointer;

              .message-loading {
                position: absolute;
                top: 50%;
              }

              .message-state {
                position: absolute;
                top: 50%;
                width: 18px;
                height: 18px;
                transform: translateY(-50%);
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              .message-have-read {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background-color: #fff6f6;
                padding: 0 4px;
                box-sizing: border-box;
                font-size: 12px;
                color: #BBBBBB;
              }

              .card-content {
                width: 100%;
                padding: 16px 20px;
                box-sizing: border-box;
                border-radius: 20px;
                //background-color: #ffffff;
                border: 1px solid #c5c5c5;

                .title {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  margin-bottom: 4px;
                  .name {
                    flex: 1;
                    text-align: left;
                    margin-right: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 22px;
                  }
                  .salary {
                    max-width: 120px;
                    font-size: 16px;
                    color: #E82727;
                  }
                }

                .job-expectations {
                  width: 100%;

                  .place, .job {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 16px;
                    margin-bottom: 4px;

                    .place-title, .job-title {
                    }

                    .place-item, .job-item {
                      max-width: 100%;
                      margin: 0 6px 0 0;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }
                  }
                }

                .information {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: flex-start;
                  align-items: flex-start;
                  margin-top: 4px;

                  .information-item {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 16px;
                    margin: 0 10px 4px 0;

                    .information-item-img {
                      width: 20px;
                      height: 20px;
                      margin-right: 2px;
                    }

                  }

                }

                .describe {
                  width: 100%;
                  text-align: left;
                  font-size: 16px;
                  margin-bottom: 4px;
                  margin-top: 2px;

                  .describe-content {
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }

                .welfare {
                  width: 100%;
                  margin-top: 8px;
                  display: flex;
                  flex-wrap: wrap;
                  align-items: flex-start;
                  justify-content: flex-start;
                  font-size: 16px;

                  .welfare-item {
                    color: #333333;
                    margin: 0 4px 4px 0;
                    border: 1px solid #d7d7d7;
                    background-color: #d7d7d7;
                    padding: 2px 6px;
                    box-sizing: border-box;
                    border-radius: 5px;
                  }
                }

              }

            }

            .image-or-text-card {
              width: 300px;

              .image-or-text-card-content {
                width: 100%;
                //background-color: #ffffff;
                border-radius: 10px;
                font-size: 16px;
                cursor: pointer;

                .image-or-text-card-title {
                  text-align: left;
                  font-size: 16px;
                  font-weight: bold;
                  padding: 10px 10px 0 10px;
                  box-sizing: border-box;
                  margin-bottom: 4px;
                }
                .img {
                  max-width: 100%;

                  img {
                    max-width: 100%;
                  }
                }
                .msg {
                  padding: 0px 10px;
                  box-sizing: border-box;
                  font-size: 12px;
                  color: #5E5E5E;
                  text-align: left;
                  margin-bottom: 4px;
                }
                .action {
                  width: 100%;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  border-top: 1px solid #dddddd;
                  padding: 0px 10px;
                  box-sizing: border-box;

                  .action-left {
                    color: #63A7FF;
                  }

                  .action-right {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                      width: 20px;
                      height: 20px;
                    }
                  }
                }
              }

            }

            .text-card {
              width: 300px;
              .text-card-content {
                width: 100%;
                //background-color: #ffffff;
                border-radius: 10px;
                font-size: 16px;
                cursor: pointer;
                .text-card-title {
                  text-align: left;
                  font-size: 16px;
                  font-weight: bold;
                  padding: 10px 10px 0 10px;
                  box-sizing: border-box;
                  margin-bottom: 4px;
                }
                .msg {
                  padding: 0px 10px 4px 10px;
                  box-sizing: border-box;
                  font-size: 12px;
                  color: #5E5E5E;
                  text-align: left;
                }
                .action {
                  width: 100%;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  border-top: 1px solid #dddddd;
                  padding: 0px 10px;
                  box-sizing: border-box;

                  .action-left {
                    color: #63A7FF;
                  }

                  .action-right {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                      width: 20px;
                      height: 20px;
                    }
                  }
                }
              }
            }

            .current-job-card-warp {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              .marked-words {
                background-color: #e1e2e3;
                font-size: 14px;
                color: #6e6e6f;
                padding: 2px 10px;
                box-sizing: border-box;
                border-radius: 5px;
              }
            }
          }

          .my-message {
            .message-text {
              .text-content {
                background-color: #00B26A;
              }
            }
            .message-voice {
              .voice-content {
                position: relative;
                .x-audio-wrap {
                  background-color: #00B26A;
                  & /deep/ .x-time {
                    color: #FFFFFF;
                  }
                }
              }
            }
            //.message-job-card {
            //  .card-content {
            //    background-color: #00B26A;
            //  }
            //}
            //.message-resume-card {
            //  .card-content {
            //    background-color: #00B26A;
            //  }
            //}
            .image-or-text-card {
              .image-or-text-card-content {
                background-color: #00B26A;
              }
            }
            .text-card {
              .text-card-content {
                background-color: #00B26A;
              }
            }
          }

          .no-message {
            .message-text {
              .text-content {
                background-color: #f2f5fa;
              }
            }
            .message-voice {
              .voice-content {
                .x-audio-wrap {
                  background-color: #f2f5fa;
                }
              }
            }
            //.message-job-card {
            //  .card-content {
            //    background-color: #f2f5fa;
            //  }
            //}
            //.message-resume-card {
            //  .card-content {
            //    background-color: #f2f5fa;
            //  }
            //}
            .image-or-text-card {
              .image-or-text-card-content {
                background-color: #f2f5fa;
              }
            }
            .text-card {
              .text-card-content {
                background-color: #f2f5fa;
              }
            }
          }

          .right {
            width: 40px;
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            cursor: pointer;
          }
        }

      }

    }

    .loading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #999999;

      .el-icon-loading {
        margin-right: 2px;
      }
    }
  }

}

</style>
