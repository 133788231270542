<template>
  <div class="hrItem_warp">
    <div class="left">
      <div class="time" v-if="time">
        {{time}}
      </div>
      <div class="avatar">
        <el-avatar :src="newAvatarUrl"></el-avatar>
        <img v-if="gender === 'female'" src="@/assets/icon/female.png">
        <img v-if="gender === 'male'" src="@/assets/icon/male.png">
      </div>

      <div class="name">
        {{name}}
      </div>
      <div>
        HR
      </div>
    </div>
    <div class="right">
      <div class="btn" v-if="applyStatus === 1">
        <el-button @click="action('reject')">拒 绝</el-button>
        <el-button @click="action('pass')">同 意</el-button>
      </div>
      <div class="btn" v-if="applyStatus === 2">
        <span>已拒绝</span>
      </div>
      <div class="btn" v-if="applyStatus === 3">
        <span>已同意</span>
      </div>
      <div class="btn" v-if="applyStatus === 4">
        <span>已过期</span>
      </div>
    </div>
  </div>
</template>

<script>
import share from "@/share/share";

export default {
  name: "hrItem",
  props: {
    recordId: {
      type: [String, Number],
      default: ''
    },
    hrId: {
      type: [String, Number],
      default: ''
    },
    avatarUrl: {
      type: String,
      default: ''
    },
    applyStatus: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    gender: {
      type: String,
      default: ''
    },
    time: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    }
  },
  computed: {
    userType() {
      return this.$store.state.userInfo.userType || ''
    },
    defAvatarUrl() {
      if (this.userType === 'hr') {
        return 'https://cdn.jiaxinzp.com/jxzp/hr_avatar_male.png'
      }else {
        return 'https://cdn.jiaxinzp.com/jxzp/employee_avatar_male.png'
      }
    },
    newAvatarUrl() {
      if (this.avatarUrl) {
        return share.initImageUrl(this.avatarUrl)
      }else {
        return this.defAvatarUrl
      }
    },
  },
  methods: {
    action(state) {
      this.$emit('action', state, this.hrId, this.recordId)
    }
  }
}
</script>

<style lang="less" scoped>
.hrItem_warp {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 30px;
    box-sizing: border-box;
    position: relative;

    .time {
      position: absolute;
      left: 10px;
      top: 0px;
      font-size: 12px;
      color: #9e9d9d;
    }

    .avatar {
      width: 60px;
      height: 60px;
      position: relative;

      .el-avatar {
        width: 60px;
        height: 60px;
      }

      img {
        position: absolute;
        right: 0px;
        bottom: 0px;
        width: 20px;
        height: 20px;
      }
    }

    .name {
      margin-left: 20px;
      margin-right: 10px;
    }
  }

  .right {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 30px;
    box-sizing: border-box;

    .el-button {
      background-color: #d2d2d2;
      color: #333333;
      padding: 10px 25px;

      &:hover {
        background-color: #e20000;
        color: #ffffff;
      }
    }
  }

}
</style>
