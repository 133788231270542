<template>
  <div class="chatSectionHome_warp" id="chatSectionHomeId">
    <div class="sidebar" :style="height !== 0 ? 'height: ' + height + 'px;':''">
      <div class="card-warp">
        <my-card v-if="isReady" ref="cardRef" :filter-unread.sync="filterUnread" @handleSelect="handleSelectSection" @cancelBlock="cancelBlock" @refreshList="refreshList"></my-card>
      </div>
      <div class="list-warp">
        <my-list v-if="isReady" ref="sectionListRef" :filter-unread="filterUnread" @cancelBlock="cancelBlock2"></my-list>
      </div>
    </div>
    <div class="main" :style="height !== 0 ? 'height: ' + height + 'px;':''">
<!--      <div class="message-warp" v-if="chatSessionUserId !== '000002'">-->
<!--        <my-message v-if="isReady" ref="messageListRef" :job-id="jobId" :warp-padding-bottom="warpPaddingBottom"></my-message>-->
<!--      </div>-->
<!--      <div class="message-warp2" v-if="chatSessionUserId === '000002'">-->
<!--        <my-message v-if="isReady" ref="messageListRef" :job-id="jobId" :warp-padding-bottom="warpPaddingBottom"></my-message>-->
<!--      </div>-->
      <chat-message-list v-if="isReady" ref="messageListRef" @customerServiceTypeChange="customerServiceTypeChange" @sendQuestion="sendQuestion" @changeHeaderMenu="changeHeaderMenu"></chat-message-list>
      <my-text ref="myTextRef" v-if="isReady && chatSessionUserId !== '000002'"
               :customer-service-type="customerServiceType"
               :job-id="jobId" @listUnshift="listUnshift" @listDelItem="listDelItem" :card-is-show.sync="cardIsShow"></my-text>
    </div>
    <div class="quick-question" v-if="quickQuestionIsShow" :style="height !== 0 ? 'height: ' + height + 'px;':''">
      <div class="quick-question-title">
        常见问题
      </div>
      <div class="quick-question-content" v-loading="loadQuickQuestion">
        <div class="question question-first" @click="callHumanCustomerService">
          <div class="text">人工客服</div>
        </div>
        <div class="question" v-for="(item, index) in quickQuestionList" :key="'question' + index" @click="sendQuestion(item.question)">
          {{item.question}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyCard from '@/components/chat/card';
import MyList from '@/components/chat/list';
import MyText from '@/components/chat/text';
import MyMessage from '@/components/chat/message';
import {mapMutations} from "vuex";
import ChatMessageList from "@/components/chat/chatMessageList";
import share from "@/share/share";

export default {
  name: "chatSectionHome",
  components: {ChatMessageList, MyCard, MyList, MyText, MyMessage},
  data() {
    return {
      isReady: false,
      height: 0,
      screenHeight: 0,
      warpPaddingBottom: 81,
      cardIsShow: true,
      jobId: '',
      filterUnread: false,
      leftNavDomHeight: 0,
      quickQuestionList: [],
      quickQuestionIsShow: false,
      loadQuickQuestion: false,
      customerServiceType: false,  // 当前是否是人工客服
    }
  },
  computed: {
    hrCurrentRepresentative() {
      return this.$store.state.hrCurrentRepresentative
    },
    usertype() {
      return this.$store.state.userInfo.userType
    },
    aonuoChat() {
      return this.$store.state.aonuoChat
    },
    aonuoChatReady() {
      return this.$store.state.aonuoChatReady
    },
    chatSession() {
      return this.$store.state.chatSession
    },
    chatSessionUserId() {
      if (this.chatSession && this.chatSession.session_info && this.chatSession.session_info.user_id) {
        return this.chatSession.session_info.user_id
      }else {
        return ''
      }
    }
  },
  watch: {
    chatSessionUserId: {
      handler: function () {
        if (this.chatSessionUserId === '000001' || this.chatSessionUserId === '000002') {
          this.cardIsShow = false
        }else {
          this.cardIsShow = true
        }
        if (this.chatSessionUserId === '000001') {
          this.quickQuestionIsShow = true
          if (this.aonuoChatReady) {
            this.getCustomerServiceQuestionList()
          }
        }else {
          this.quickQuestionIsShow = false
        }
      },
      immediate: true
    },
    aonuoChatReady: {
      handler: function () {
        if (this.aonuoChatReady && this.chatSessionUserId === '000001') {
          this.getCustomerServiceQuestionList()
        }
      },
      immediate: true
    },
    // hrCurrentRepresentative: {
    //   handler: function () {
    //     if (this.hrCurrentRepresentative && this.hrCurrentRepresentative.value !== undefined && this.hrCurrentRepresentative.value !== '') {
    //       this.jobId = this.hrCurrentRepresentative.value
    //     }
    //   },
    //   immediate: true
    // },
    screenHeight() {
      let dom = document.getElementById('chatSectionHomeId')
      if (this.usertype === 'hr') {
        let leftNavDom = document.getElementById('leftNavId')
        this.leftNavDomHeight = leftNavDom.offsetHeight

        if (this.screenHeight > 1000) {
          this.height = 800
        }else {
          if (this.screenHeight - 200 < this.leftNavDomHeight) {
            this.height = this.leftNavDomHeight
          }else {
            this.height = this.screenHeight - 200
          }
        }
      }

      if (this.usertype === 'employee') {
        if (dom.offsetHeight > 800) {
          this.height = 800
        }else {
          this.height = dom.offsetHeight
        }
      }
    },
  },
  mounted() {
    this.screenHeight = document.body.clientHeight;
    window.onresize = () => {
      return (() => {
        this.screenHeight = document.body.clientHeight;
      })()
    }

    let dom = document.getElementById('chatSectionHomeId')

    if (this.usertype === 'hr') {
      let leftNavDom = document.getElementById('leftNavId')
      this.leftNavDomHeight = leftNavDom.offsetHeight

      if (this.screenHeight > 1000) {
        this.height = 800
      }else {
        if (this.screenHeight - 200 < this.leftNavDomHeight) {
          this.height = this.leftNavDomHeight
        }else {
          this.height = this.screenHeight - 200
        }
      }
    }

    if (this.usertype === 'employee') {
      if (dom.offsetHeight > 800) {
        this.height = 800
      }else {
        this.height = dom.offsetHeight
      }
    }

    this.isReady = true
  },
  methods: {
    /**
     * 为消息列表头部添加数据
     * */
    listUnshift(obj) {
      this.$refs['messageListRef'].listUnshift(obj)
    },

    listDelItem(obj) {
      this.$refs['messageListRef'].listDelItem(obj)
    },

    handleSelectSection(sessionId, sessionType, sessionInfo, session) {
      this.$refs['sectionListRef'].changeSession(sessionId, sessionType, sessionInfo, session)
    },

    cancelBlock() {
      this.$refs['sectionListRef'].reacquireLoadSessionList()
    },

    refreshList() {
      this.$refs['sectionListRef'].reacquireLoadSessionList(50)
    },

    cancelBlock2() {
      this.$refs['cardRef'].loadBlockList()
    },

    /**
    * 获取快捷问题列表
    * */
    async getCustomerServiceQuestionList() {
      if (this.loadQuickQuestion) {
        return
      }
      this.loadQuickQuestion = true
      const res = await this.aonuoChat.getCustomerServiceQuestionList()
      if (res) {
        this.quickQuestionList = res.auto_message_info_list
      }
      this.loadQuickQuestion = false
    },
    /**
     * 切换机器人客服和人工客服
     * */
    customerServiceTypeChange(type) {
      this.customerServiceType = type
    },
    /**
     * 呼叫人工客服
     * */
    callHumanCustomerService() {
      if (this.customerServiceType) {
        share.message.warning('当前已是人工客服状态')
        return;
      }
      this.sendQuestion('呼叫人工客服')
    },
    sendQuestion(text) {
      this.$refs['myTextRef'].sendMsg('cumText', text)
    },

    changeHeaderMenu(num) {
      this.$emit('changeHeaderMenu', num)
    }

  }
}
</script>

<style lang="less" scoped>
.chatSectionHome_warp {
  //margin: 20px auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 3px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .sidebar, .main {
    height: 100%;
    min-height: 500px;
    background-color: #FFFFFF;
  }

  .sidebar {
    width: 300px;
    //color: #f4f4f4;
    color: #414a60;
    border-right: 1px solid #eaeaea;
    //background-color: #E82727;
    .card-warp {
      width: 100%;
      height: 103px;
    }
    .list-warp {
      width: 100%;
      height: calc(100% - 100px);
    }
  }

  .main {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .quick-question {
    width: 200px;
    background-color: #ffffff;
    .quick-question-title {
      width: 100%;
      height: 51px;
      background-color: #f8f9fc;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }
    .quick-question-content {
      width: 100%;
      height: calc(100% - 51px);
      .question-first {
        .text {
          height: 30px;
          padding: 0 15px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #23a09e;
          border-radius: 20px;
        }
      }
      .question {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 20px;
        box-sizing: border-box;
        font-size: 16px;
        cursor: pointer;
        color: #23a09e;
      }
    }
  }

}
</style>
