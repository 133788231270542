<template>
  <div class="redEnvelopeApplicationPage_warp">

    <div class="left">

      <div class="header">

        <div class="header-top">
          <div class="job-name" v-if="information.jobInformation">
            {{information.jobInformation.jobName}}
          </div>
          <div class="salary-range">
            {{salary}}
          </div>

<!--          <div class="job-state" v-if="information.jobInformation && information.jobInformation.isClose === true">[该岗位已关闭]</div>-->

        </div>

        <div class="header-center">

          <div class="place" v-if="information.jobInformation">
            {{information.jobInformation.jobAddressList[0]}} · {{information.jobInformation.jobAddressList[1]}} · {{information.jobInformation.jobAddressList[2]}}
          </div>

          <div class="experience">
            {{experienceRequirement}}
          </div>

          <div class="age-range">
            {{ageRequirement}}
          </div>

          <div class="education" v-if="information.jobInformation">
            {{information.jobInformation.academicRequirement}}
          </div>

        </div>

        <div class="header-bottom">
          <div class="avatar" v-if="information.hrInformation">
            <img :src="newAvatarUrl">
          </div>

          <div class="info">
            <div class="company-name" v-if="information.enterpriseInformation">
              {{information.enterpriseInformation.enterpriseName}}
            </div>
            <div class="name" v-if="information.hrInformation">
              {{information.hrInformation.hrName}}
            </div>
          </div>
        </div>


      </div>

      <div class="status">
        <div class="title">
          状态
        </div>
        <div class="status-content">

          <div class="btn-item" v-if="redPocketApplyStatus === 1">
            <div>审核中</div>
          </div>
          <div class="btn-item" v-if="redPocketApplyStatus === 2">
            <div class="redEnvelopeProcessStatus2">
              已拒绝
            </div>
            <div></div>
          </div>
          <div class="btn-item" v-if="redPocketApplyStatus === 3">
            <div>进行中</div>
            <div class="redEnvelopeProcessStatus3">
              <div class="show">
                <span>距离该红包到账</span>
                <span class="date">{{remainDaysStr}}</span>
              </div>
            </div>
          </div>
          <div class="btn-item" v-if="redPocketApplyStatus === 4">
            <div class="redEnvelopeProcessStatus2">
              已中断
            </div>
            <div></div>
          </div>
          <div class="btn-item" v-if="redPocketApplyStatus === 5">
            <div class="redEnvelopeProcessStatus2">
              已结束
            </div>
            <div></div>
          </div>
          <div class="btn-item" v-if="redPocketApplyStatus === 6">
            <div class="">
              红包冲突
              <div style="font-size: 12px; color: #6b6a6a;">注：只能同时领取一个红包，同意当前红包会自动终止已有的红包流程</div>
            </div>
            <div>
              <el-button @click="handleAgree">同 意</el-button>
              <el-button @click="handleRefuse">拒 绝</el-button>
            </div>
          </div>

        </div>
      </div>

      <div class="timeline">
        <el-timeline v-if="information.redPocketApplyInformation && redPocketApplyStatus !== 1 && redPocketApplyStatus !== 2 && redPocketApplyStatus !== 6">

          <el-timeline-item
              type="primary"
              color="#e20000"
              size="large"
              :timestamp="information.redPocketApplyInformation.handledTime"
          >
            <div class="timeline-item-warp">
              <div class="title">
                开始
              </div>
            </div>
          </el-timeline-item>
          <el-timeline-item
              v-for="(activity, index) in activities"
              :key="'timeline-item' + index"
              :type="activity.type"
              :color="activity.color"
              :size="activity.size"
              :timestamp="activity.timestamp">

            <div class="timeline-item-warp">
              <div class="title">
                第{{activity.installment}}期
              </div>
              <div class="text">
                红包到账{{activity.amount}}元
              </div>
            </div>

          </el-timeline-item>
        </el-timeline>
      </div>

    </div>

    <div class="right">
    </div>

    <el-dialog
        title="红包冲突同意"
        :visible.sync="collisionDialogVisible"
        width="400px">
      <span>确定同意吗？同意之后会自动终止其它红包流程</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="collisionDialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="collisionAgree">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="红包冲突拒绝"
        :visible.sync="collisionDialogVisible2"
        width="400px">
      <span>确定拒绝吗？拒绝不会影响当前的红包流程</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="collisionDialogVisible2 = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="collisionRefuse">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {
  ConfirmConflictRedPocketApi,
  GetConflictRedPocketApplyApi,
  GetEmployeeSeeRedPocketApplyDetailApi,
  RejectConflictRedPocketApi
} from '@/request/api'
import {ageRange2, experience, jobSearchingStatus} from '@/share/shareData'
import share from "@/share/share";

export default {
  name: "redEnvelopeApplicationPage",
  components: {},
  data() {
    return {
      jobId: '',
      recordId: '',
      information: {},
      redPocketApplyStatus: 0,
      currentInstallment: 0,

      activities: [],

      remainDays: '',
      collisionDialogVisible: false,
      collisionDialogVisible2: false,

      btnLoading: false,
    }
  },
  computed: {
    userType() {
      return this.$store.state.userInfo.userType || ''
    },
    defAvatarUrl() {
      if (this.userType === 'hr') {
        return 'https://cdn.jiaxinzp.com/jxzp/hr_avatar_male.png'
      }else {
        return 'https://cdn.jiaxinzp.com/jxzp/employee_avatar_male.png'
      }
    },
    newAvatarUrl() {
      if (this.information.hrInformation.hrAvatar) {
        return share.initImageUrl(this.information.hrInformation.hrAvatar)
      }else {
        return this.defAvatarUrl
      }
    },
    salary() {
      let str = ''
      if (this.information.jobInformation && this.information.jobInformation.salaryRange.length > 0) {
        // str = this.information.jobInformation.salaryRange[0] + '-' + this.information.jobInformation.salaryRange[1]
        str = parseInt(this.information.jobInformation.salaryRange[0])/1000 + 'k-' + parseInt(this.information.jobInformation.salaryRange[1])/1000 + 'k'
      }else {
        str = '面议'
      }
      return str
    },
    experienceRequirement() {
      let str = ''
      if (this.information.jobInformation && this.information.jobInformation.experienceRequirementRange.length > 0) {
        let str2 = this.information.jobInformation.experienceRequirementRange[0] + '-' + this.information.jobInformation.experienceRequirementRange[1]

        experience.forEach((item, index) => {
          if (item.value === str2) {
            str = item.label + '经验'
          }
        })
      }else {
        str = '经验不限'
      }
      return str
    },
    ageRequirement() {
      let str = ''
      if (this.information.jobInformation && this.information.jobInformation.ageRequirementRange.length > 0) {
        let str2 = this.information.jobInformation.ageRequirementRange[0] + '-' + this.information.jobInformation.ageRequirementRange[1]

        // ageRange2.forEach((item, index) => {
        //   if (item.value === str2) {
        //     str = item.label
        //   }
        // })

        str = this.information.jobInformation.ageRequirementRange[0] + '岁-' + this.information.jobInformation.ageRequirementRange[1] + '岁'
      }else {
        str = '年龄不限'
      }
      return str
    },
    remainDaysStr() {
      let str = ''
      if (this.remainDays === 0) {
        str = '不足一天'
      }else {
        str = '剩余' +  this.remainDays + '天'
      }
      return str
    }

  },
  mounted() {
    this.jobId = this.$route.query.id || ''
    this.recordId = this.$route.query.recordId || ''
    this.loadData()
  },
  methods: {
    async loadData() {
      const res = await GetEmployeeSeeRedPocketApplyDetailApi({
        recordId: this.recordId
      })
      if (res) {
        this.information = res
        this.redPocketApplyStatus = res.redPocketApplyInformation.redPocketApplyStatus

        this.currentInstallment = res.redPocketApplyInformation.currentInstallment

        this.remainDays = res.redPocketApplyInformation.remainDays
        let arr = []
        arr = res.redPocketApplyInformation.eachInstallmentTimeAmount
        arr.forEach((item, index) => {
          item.timestamp = item.time
          item.size = 'large'
          item.type = 'primary'
          if (this.currentInstallment < item.installment + 1) {
            item.color = '#e5e5e5'
          }else {
            item.color = '#e20000'
          }

        })
        this.activities = arr
      }
    },
    handleAgree() {
      // if (this.information.jobInformation && this.information.jobInformation.isClose === true) {
      //   share.message.warning('该岗位已关闭或已删除')
      //   return
      // }
      this.collisionDialogVisible = true
    },
    async collisionAgree() {
      // if (this.information.jobInformation && this.information.jobInformation.isClose === true) {
      //   share.message.warning('该岗位已关闭或已删除')
      //   return
      // }

      this.btnLoading = true

      let recordId = ''
      const res2 = await GetConflictRedPocketApplyApi()

      if (res2.recordList && res2.recordList.length > 0) {
        res2.recordList.forEach((item, index) => {
          if (item.jobInformation.jobId === parseInt(this.jobId)) {
            recordId = item.recordId
          }
        })

        const res = await ConfirmConflictRedPocketApi({
          conflictRecordId: recordId
        })

        if (res) {
          share.message.success('操作成功')
          await this.loadData()
        }
      }
      this.btnLoading = false
      this.collisionDialogVisible = false
    },
    handleRefuse() {
      // if (this.information.jobInformation && this.information.jobInformation.isClose === true) {
      //   share.message.warning('该岗位已关闭或已删除')
      //   return
      // }
      this.collisionDialogVisible2 = true
    },
    async collisionRefuse() {
      // if (this.information.jobInformation && this.information.jobInformation.isClose === true) {
      //   share.message.warning('该岗位已关闭或已删除')
      //   return
      // }

      this.btnLoading = true

      let recordId = ''
      const res2 = await GetConflictRedPocketApplyApi()

      if (res2.recordList && res2.recordList.length > 0) {
        res2.recordList.forEach((item, index) => {
          if (item.jobInformation.jobId === parseInt(this.jobId)) {
            recordId = item.recordId
          }
        })

        const res = await RejectConflictRedPocketApi({
          conflictRecordId: recordId
        })

        if (res) {
          share.message.success('操作成功')
          await this.loadData()
        }
      }
      this.btnLoading = false
      this.collisionDialogVisible2 = false
    }

  }
}
</script>

<style lang="less" scoped>
.redEnvelopeApplicationPage_warp {
  width: 100%;
  height: 100%;
  background-color: #f4f2f3;
  display: flex;
  justify-content: space-between;

  .left {
    width: 780px;
    min-height: 600px;
    background-color: #ffffff;
    padding: 0 30px;
    box-sizing: border-box;

    .header {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .header-top {
        width: 100%;
        font-size: 35px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        margin-top: 20px;
        position: relative;

        .job-state {
          position: absolute;
          right: 0;
          top: 0;
          font-size: 14px;
          color: #e20000;
        }

        .job-name {
          margin-right: 20px;
        }

        .salary-range {
          font-size: 25px;
          color: #e20000;
          font-weight: bold;
        }
      }

      .header-center {
        width: 100%;
        padding-bottom: 10px;
        margin-top: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #efefef;

        >div {
          margin-right: 30px;
        }
      }

      .header-bottom {
        margin-top: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .avatar {
          width: 50px;
          height: 50px;
          margin-right: 20px;

          img {
            height: 100%;
            width: 100%;
            border-radius: 50%;
          }
        }
      }


    }

    .status {
      margin: 40px 0;
      width: 100%;
      height: 130px;
      border: 1px solid #d1d1d1;
      border-radius: 5px;

      .title {
        font-size: 14px;
        background-color: #eeeeee;
        height: 40px;
        line-height: 40px;
        color: #343434;
        padding: 0 20px;
        box-sizing: border-box;
      }

      .status-content {
        height: calc(100% - 40px);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px;
        box-sizing: border-box;

        .btn-item {
          font-size: 18px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .redEnvelopeProcessStatus1 {
            flex: 1;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .el-button {
              color: #333333;
              background-color: #ffffff;

              &:hover {
                color: #ffffff;
                background-color: #e20000;
              }
            }
          }

          .redEnvelopeProcessStatus2 {
            color: #676767;
          }

          .redEnvelopeProcessStatus3 {
            width: 100px;
            height: 50px;
            box-sizing: border-box;
            color: #676767;
            font-size: 12px;
            background-color: #ffffff;
            border: 1px solid #a0a0a0;


            .show {
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .date {
                color: #e20000;
              }
            }
          }

          .redEnvelopeProcessStatus4, .redEnvelopeProcessStatus5 {
            background-color: #d2d2d2;
            padding: 5px 20px;
            box-sizing: border-box;
            color: #676767;
            border: 1px solid #a0a0a0;
          }

          //.el-button {
          //  border: 1px solid #e20000;
          //  color: #e20000;
          //}
        }
      }
    }

    .timeline {
      .timeline-item-warp {
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .title {
          font-size: 16px;
          margin-right: 20px;
        }

        .text {
          font-size: 12px;
          color: #e20000;
        }
      }
    }

  }

  .right {
    flex: 1;
    min-height: 600px;
    margin-left: 10px;
    background-color: #f4f2f3;
  }

}
</style>
