<template>
  <div class="ads_warp">
    <el-dialog
        title="观看广告获取提现次数"
        :visible.sync="dialogVisible"
        width="800px"
        custom-class="adDialog"
        :before-close="handleClose">
      <div class="content" v-if="dialogVisible">
        <video id="myVideoId" width="100%" autoplay>
          <source class="myVideoSource" :src="src" type="video/mp4">
          <source class="myVideoSource" :src="src" type="video/webm">
          <source class="myVideoSource" :src="src" type="video/ogg">
          您的浏览器不支持 HTML5 video 标签。
        </video>
        <div id="numId" class="num">
          {{videoLength}}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ads",
  props: {
    src: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    visible: {
      handler: function () {
        this.dialogVisible = this.visible
        if (this.visible) {
          this.$nextTick(() => {
            this.init()
          })
        }
      },
      immediate: true
    },
    dialogVisible() {
      if (!this.dialogVisible) {
        this.$emit('update:visible', this.dialogVisible)
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      myVideoDom: null,
      videoLength: 15,
      playState: false,
      timer: null,
    }
  },
  mounted() {

  },
  methods: {
    init() {
      this.myVideoDom = document.getElementById('myVideoId')

      this.myVideoDom.addEventListener('loadedmetadata', () => { //加载数据
        //视频的总长度
        let duration = parseInt(this.myVideoDom.duration)
        if (duration > 15) {
          this.videoLength = 15
        }else {
          this.videoLength = duration
        }
      })
      this.myVideoDom.addEventListener('play', () => { //播放
        this.playState = true
      });
      this.myVideoDom.addEventListener('playing', () => { //播放中
        this.setMyInterval()
      });
      this.myVideoDom.addEventListener('waiting', () => { //加载
        this.playState = false
        if (this.timer) {
          clearInterval(this.timer)
          this.timer = null
        }
      });
      this.myVideoDom.addEventListener('pause', () => { //暂停
        this.playState = false
        if (this.timer) {
          clearInterval(this.timer)
          this.timer = null
        }
      });
      this.myVideoDom.addEventListener('ended', () => { //结束
        this.playState = false

        this.dialogVisible = false
        this.$emit('watchingTime', true)

        if (this.timer) {
          clearInterval(this.timer)
          this.timer = null
        }
      }, false);
    },
    handleClose() {
      this.dialogVisible = false
      this.$emit('watchingTime', false)
    },
    setMyInterval() {
      this.timer = setInterval(() => {
        if (this.videoLength > 0) {
          this.videoLength--
        }
        if (!this.playState) {
          if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
          }
        }
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.ads_warp {

}
</style>

<style lang="less">
.adDialog {
  .content {
    width: 100%;
    position: relative;
    .num {
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 5px 0;
      width: 60px;
      background-color: #FFFFFF;
      opacity: 0.3;
      display: flex;
      align-content: center;
      justify-content: center;
    }
  }
}
</style>
