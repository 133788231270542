<template>
  <div class="jobHuntingHome_warp">
    <div class="header">
      <page-header :curr-page="currHeaderMenu" @changeCurrPage="changeCurrPage" @areaChange="areaChange" backgroundColor="#eeeeee"></page-header>
    </div>

    <div class="search">
      <div class="search-content">
        <div class="icon">
          <img src="@/assets/icon/logo.png">
        </div>
        <div class="search-input">
          <search-input :defSearchValue="searchValue" @searchValueInput="searchValueInput" @searchSubmit="searchSubmit"></search-input>
        </div>
      </div>
    </div>

    <div class="content">

      <div class="user-info">
        <div class="user-info-left">
          <el-avatar class="profile-photo" size="large">
            <img :src="profilePhoto" />
          </el-avatar>
          <div class="text" v-if="isLogin">欢迎您！{{username}}</div>
          <div class="text" v-if="!isLogin" @click="goLogin">登录</div>
        </div>
        <div class="user-info-right" @click="goMyResume">
          <img src="@/assets/icon/resume.png">
          在线编辑简历
        </div>
      </div>

      <div class="main">
        <div class="main-left">

          <div class="filter card">
            <div class="title">
              <div class="text">详细地区过滤</div>
            </div>
            <div class="area-filter">
<!--              <el-select v-model="areaValue" placeholder="请选择" @change="areaValueChange" clearable>-->
<!--                <el-option-->
<!--                    v-for="(item, index) in areaOptions"-->
<!--                    :label="item.label"-->
<!--                    :value="item.value">-->
<!--                </el-option>-->
<!--              </el-select>-->

              <el-cascader
                  v-model="areaValue"
                  @change="areaValueChange"
                  :options="areaOptions"
                  :show-all-levels="false"
                  placeholder="请选择"
                  popper-class="job_filter_item"
                  :props="{
                    expandTrigger: 'hover',
                    multiple: true
                  }"
                  collapse-tags
                  clearable
              ></el-cascader>
            </div>
          </div>

          <div class="filter card">
            <div class="title">
              <div class="text">更多选择</div>
              <div class="des" @click="clearAllFilterValue">清空所有筛选项</div>
            </div>
            <job-filter ref="jobFilterRef" @jobFilterChange="jobFilterChange"></job-filter>
          </div>

          <div class="card" id="scanJobHistoryRecord" v-loading="pageLoading">
            <job-card title="我看过的岗位" :job-list="jobHistoryList" @clickTitle="goDynamic()" @clickItem="goJobDetailed"></job-card>
          </div>

          <!--          <div class="card">-->
          <!--            <job-card title="为我优选" :job-list="jobRecommendList"></job-card>-->
          <!--          </div>-->

        </div>
        <div class="main-right">
          <router-view ref="jobHuntingHomeRouterView" :search-value="searchValue" :job-filter="jobFilter"></router-view>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import PageHeader from "@/components/pageHeader/pageHeader";
import Search from "@/views/search/search";
import SearchInput from "@/components/searchInput/searchInput";
import JobFilter from "@/components/jobFilter/jobFilter";
import JobCard from "@/components/jobCard/jobCard";
import {areas} from '@/share/shareData'
import {SeeJobRecordApi, isEditedResumeApi} from '@/request/api'

let defaultProfilePhoto = require('@/assets/icon/profile-photo.png')
export default {
  name: "jobHuntingHome",
  components: {JobCard, JobFilter, SearchInput, Search, PageHeader},
  data () {
    return {
      currHeaderMenu: 2,
      searchValue: '',
      jobFilter: {},
      pageLoading: true,
      jobHistoryList: [],

      areaValue: [],
      areaOptions: [],
      areas: areas,
    }
  },
  computed: {
    isLogin() {
      let token = this.$store.state.token
      let state = false
      if (token && token !== '' && token !== null && token !== 'null') {
        state = true
      }
      return state
    },
    username() {
      return this.$store.state.userInfo.username || ''
    },
    profilePhoto() {
      return this.$store.state.userInfo.profilePhoto || defaultProfilePhoto
    },
    place() {
      return this.$store.state.userInfo.place
    },
    areasOptions() {
      return this.$store.state.areasOptions
    },
  },
  watch: {
    place: {
      handler: function () {
        if (this.place && this.place.value) {
          let obj = {}
          try {
            this.areas.forEach((item, index) => {
              if (item.children && item.children.length > 0) {
                item.children.forEach((item2, index2) => {
                  if (item2.value === this.place.value) {
                    obj = item2
                  }
                })
              }else {
                if (item.value === this.place.value) {
                  obj = item
                  throw Error()
                }
              }
            })
          }catch (e) {
          }

          if (obj.children && obj.children.length > 0) {
            this.areaOptions = obj.children
          }

          let searchJobSelectPlaceValue = sessionStorage.getItem('searchJobSelectPlaceValue')
          if (searchJobSelectPlaceValue === this.place.value.toString()) {
            let searchJobSelectAreaValue = sessionStorage.getItem('searchJobSelectAreaValue')
            this.areaValue = JSON.parse(searchJobSelectAreaValue)
          }else {
            this.areaValue = []
          }

          sessionStorage.setItem('searchJobSelectPlaceValue', this.place.value)
        }
      },
      immediate: true
    }
  },
  created() {
    this.searchValue = this.$route.query.searchValue || ''
  },
  async mounted() {
    // let loading = share.loadingShow({
    //   id: '#scanJobHistoryRecord',
    //   background: '#f4f2f3',
    // })
    this.pageLoading = true

    let searchJobFilter = sessionStorage.getItem('searchJobFilter')
    if (searchJobFilter) {
      this.jobFilter = JSON.parse(searchJobFilter)

      if (this.jobFilter.address) {
        this.areaValue = JSON.parse(this.jobFilter.address)
      }

      this.ageRangeSlider = this.jobFilter.ageRange
      this.$refs.jobFilterRef.init(this.jobFilter)
    }
    let searchJobSearchValue = sessionStorage.getItem('searchJobSearchValue')
    if (searchJobSearchValue) {
      this.searchValue = searchJobSearchValue
    }

    await this.loadData()
    this.pageLoading = false
    // loading.close()
  },
  methods: {
    async loadData() {
      if (!this.isLogin) {
        return
      }

      const res = await SeeJobRecordApi({
        pageNum: 1,
        pageSize: 3,
      })

      if (res) {
        this.jobHistoryList = res.records
      }
    },
    async loadIsEditedResumeData() {
      const res = await isEditedResumeApi()

      if (res) {

      }
    },
    searchValueInput(searchValue) {
      this.searchValue = searchValue
    },
    searchSubmit() {
      this.jobFilter.address = JSON.stringify(this.areaValue)
      this.$refs.jobHuntingHomeRouterView.submit()
    },
    changeCurrPage(num) {
      this.currHeaderMenu = num
    },
    areaChange() {
      this.areaValue = []
      this.jobFilter.address = ''
      this.$refs.jobHuntingHomeRouterView.submit()
    },
    areaValueChange() {

      this.areaValue.forEach((item, index) => {
        this.areaValue[index] = item[0]
      })
      this.jobFilter.address = JSON.stringify(this.areaValue)

      sessionStorage.setItem('searchJobSelectAreaValue', JSON.stringify(this.areaValue))

      this.$refs.jobHuntingHomeRouterView.submit()
    },
    /* 点击头部按钮项时触发 */
    // actionClick(num, userState) {
    //   if (num === 1) {
    //     if (!userState) {
    //       this.$router.push('/login')
    //     }
    //   }
    // },
    jobFilterChange(arr) {
      arr.forEach((item, index) => {
        this.jobFilter[item.key] = item.value
      })
      this.jobFilter.address = JSON.stringify(this.areaValue)
      this.$refs.jobHuntingHomeRouterView.submit()
    },
    clearAllFilterValue() {
      this.$refs.jobFilterRef.clear()
    },
    goDynamic(index) {
      this.$router.push({
        path: '/personalDynamic',
        query: {
          index: '2'
        }
      })
    },
    goJobDetailed(id) {
      this.$router.push({
        path: '/jobDetailed',
        query: {
          id: id
        }
      })
    },
    goMyResume() {
      this.$router.push({
        path: '/myResume',
      })
    },
    goLogin() {
      this.$router.push({
        path: '/login',
      })
    },

  }
}
</script>

<style lang="less" scoped>
.jobHuntingHome_warp {
  width: 100%;
  //height: 100%;
  background-color: #f4f2f3;
  min-width: 1400px;
  //min-height: 100vh;

  .header {
    width: 100%;
    height: 61px;
  }


  .search {
    width: 100%;
    height: 100px;
    //background-color: #ffffff;

    .search-content{
      width: 1000px;
      margin: 0 auto;

      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 80px 10px 80px;
      box-sizing: border-box;
      //margin: 10px 0px 10px 0px;

      .icon {
        height: 90px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        img {
          max-height: 80%;
        }
      }

      .search-input {
        flex: 1;
      }
    }

  }

  .content {
    width: 1000px;
    margin: 0 auto;
    //height: 100%;

    .user-info {
      width: 100%;
      height: 100px;
      background: url("../../assets/icon/jobHunting-header-bg.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 30px;
      box-sizing: border-box;
      margin-bottom: 10px;
      color: #e80003;

      .user-info-left {
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;

        .profile-photo {
          margin: 0 20px;
        }

        & /deep/ .el-avatar {
          background-color: #ffffff;
        }

        & /deep/ .el-avatar--large {
          height: 80px;
          width: 80px;

        }

      }

      .user-info-right {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          height: 30px;
          width: 30px;
          margin-right: 10px;
        }
      }
    }

    .main {
      width: 100%;
      //height: 100%;
      display: flex;

      .main-left {
        width: 250px;
        //height: 100%;

        .card {
          width: 100%;
          margin-bottom: 10px;
          background-color: #ffffff;
        }

        .filter {
          padding-bottom: 1px;

          .title {
            height: 35px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #dfdfdf;

            .text {
              color: #e80003;
              font-size: 14px;
              height: 35px;
              line-height: 35px;
              margin-left: 5px;
            }

            .des {
              height: 100%;
              display: flex;
              align-items: flex-end;
              font-size: 12px;
              color: #7f7f7f;
              margin-bottom: 8px;
              margin-right: 5px;
              cursor: pointer;
            }

          }

          .area-filter {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            & /deep/ .el-input__inner {
              border: 0;
            }
          }

        }
      }

      .main-right {
        width: calc(100% - 250px);
        //height: 100%;
      }

    }

  }

}
</style>
