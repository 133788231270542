<template>
  <div class="warehouseJobItem_warp" @click="goJobDetailed">
    <el-row :gutter="10">

      <el-col :span="8">
        <div class="left">
          <div class="top">
            <div class="title">
              {{jobName}}
            </div>
            <div class="salary">
              {{salary}}
            </div>
          </div>
          <div class="bottom">
            <div class="bottom-item" v-if="jobAddress">
              {{jobAddress[1]}} · {{jobAddress[2]}}
            </div>
            <div class="bottom-item">
              {{experienceRequirement}}
            </div>
            <div class="bottom-item">
              {{academicRequirement}}
            </div>
          </div>
        </div>
      </el-col>

      <el-col :span="7">
        <div class="center">

          <div class="center-top">
            <div class="center_item" @click.stop="goPersonalDynamic('1')" v-if="!editingUnderReview && !isClose && jobStatus === 3">
              {{seeCount}}看过我<i class="el-icon-arrow-right"></i>
            </div>
            <div class="center_item" @click.stop="goPersonalDynamic('5')" v-if="!editingUnderReview && !isClose && jobStatus === 3">
              {{collectCount}}收藏我<i class="el-icon-arrow-right"></i>
            </div>
          </div>

          <div class="center-bottom">
            <div class="center_item" @click.stop="goPersonalDynamic('7')" v-if="!editingUnderReview && !isClose && jobStatus === 3">
              {{communityCount}}沟通过<i class="el-icon-arrow-right"></i>
            </div>
            <div class="center_item" @click.stop="goPersonalDynamic('6')" v-if="isRedEnvelope && !editingUnderReview && !isClose && jobStatus === 3">
              {{applyRedPocketCount}}申请<i class="el-icon-arrow-right"></i>
            </div>
          </div>

        </div>
      </el-col>

      <el-col :span="9">
        <div class="right">
          <div class="right-top">
            <div class="right_item" @click.stop="clickAction(10)" v-if="!editingUnderReview && !isClose && (jobStatus === 3 || jobStatus === 4)">
              编辑
            </div>
            <div class="right_item" @click.stop="clickAction(6)" v-if="!editingUnderReview && !isClose && jobStatus === 4">
              重新审核
            </div>
            <div class="right_item" @click.stop="clickAction(1)" v-if="!editingUnderReview && !isClose && jobStatus === 1">
              <span>支付</span>
            </div>
            <div class="right_item" @click.stop="clickAction(5)" v-if="!editingUnderReview && !isClose && jobStatus === 5">
              <span>开放</span>
            </div>
            <div class="right_item" @click.stop="clickAction(7)" v-if="!editingUnderReview && !isClose && jobStatus === 3 && !isPromoting">
              关闭
            </div>
            <template v-if="isClose">
              <div class="right_item" @click.stop="clickAction(11)" v-if="!isPromoting && !haveValidDurationPackage">
                删除
              </div>
            </template>
            <template v-if="!isClose">
              <div class="right_item" @click.stop="clickAction(11)" v-if="jobStatus !== 2 && jobStatus !== 3 && !isPromoting && !haveValidDurationPackage">
                删除
              </div>
            </template>

            <div class="right_item" @click.stop="clickAction(12)" v-if="isPromoting">
              设置推广
            </div>
            <div class="right_item" @click.stop="clickAction(13)" v-if="!editingUnderReview && !isPromoting && !isClose && jobStatus === 3">
              推广
            </div>
            <div class="right_item" @click.stop="clickAction(8)" v-if="isClose">
              开放
            </div>
            <div class="right_item" @click.stop="clickAction(9)" v-if="!editingUnderReview && !isClose && !isRedEnvelope && jobStatus === 3">
              升级为红包岗位
            </div>
          </div>
          <div class="right-bottom">
            <div class="text" v-if="!editingUnderReview && !isClose && jobStatus === 1">
              未支付
            </div>
            <div class="text" v-if="!editingUnderReview && !isClose && jobStatus === 2">
              <span v-if="haveValidDurationPackage">已支付，审核中</span>
              <span v-if="!haveValidDurationPackage">审核中</span>
            </div>
            <div class="text" v-if="editingUnderReview && !isClose">
              已编辑，审核中
            </div>
            <div class="text" v-if="!editingUnderReview && !isClose && jobStatus === 3">
              {{expireDate}}到期
            </div>
            <div class="text" v-if="!editingUnderReview && !isClose && jobStatus === 4">
              未过审
            </div>
            <div class="text" v-if="!editingUnderReview && !isClose && jobStatus === 5">
              <span>已过期</span>
            </div>
            <div class="text" v-if="isClose">
              该岗位已关闭
            </div>
          </div>
        </div>
      </el-col>

    </el-row>

  </div>
</template>

<script>
export default {
  name: "warehouseJobItem",
  props: {
    jobId: {  // 岗位id
      type: [Number, String],
      default: '',
    },
    isClose: {  // 岗位是否关闭
      type: Boolean,
      default: false,
    },
    jobStatus: {  // 岗位 状态
      type: [Number, String],
      default: 1,
    },
    editingUnderReview: {  // 岗位编辑审核中
      type: Boolean,
      default: false
    },
    isRedEnvelope: {  // 是否是红包岗位
      type: Boolean,
      default: false
    },
    jobName: {  // 岗位名称
      type: String,
      default: '',
    },
    salaryRange: {  // 工资范围
      type: Array,
      default: () => [],
    },
    jobAddress: {  // 工作地
      type: Array,
      default: () => [],
    },
    seeCount: {  // 查看过此岗位的数量
      type: [Number, String],
      default: 0,
    },
    communityCount: {  // 沟通过此岗位的数量
      type: [Number, String],
      default: 0,
    },
    collectCount: {  // 沟通过此岗位的数量
      type: [Number, String],
      default: 0,
    },
    applyRedPocketCount: {  // 红包岗位神请数
      type: [Number, String],
      default: 0,
    },
    experienceRequirementRange: {  // 经验范围
      type: Array,
      default: () => [],
    },
    academicRequirement: {  // 学历要求
      type: String,
      default: '',
    },
    expireDate: {  // 到期时间
      type: String,
      default: '',
    },
    jobPromoteInformation: {  // 推广信息
      type: Object,
      default: () => {},
    },
    hrVipRemainJobNum: {  // vip套餐是否还有剩余可在线的岗位次数
      type: [String, Number],
      default: 0
    },
    haveValidDurationPackage: {  // 是否有时长套餐
      type: Boolean,
      default: false
    },
  },
  data() {
    return {}
  },
  computed: {
    isPromoting() {
      if (this.jobPromoteInformation && this.jobPromoteInformation.id) {
        return true
      }else {
        return false
      }
    },
    salary() {
      let str = ''
      if (this.salaryRange && this.salaryRange.length > 0) {
        // str = this.salaryRange[0] + '-' + this.salaryRange[1]
        str = parseInt(this.salaryRange[0])/1000 + 'k-' + parseInt(this.salaryRange[1])/1000 + 'k'
      }else {
        str = '面议'
      }
      return str
    },
    experienceRequirement() {

      let str = ''
      if (this.experienceRequirementRange && this.experienceRequirementRange.length > 0) {
        str = this.experienceRequirementRange[0] + '-' + this.experienceRequirementRange[1] + '年经验'
      }else {
        str = '经验不限'
      }
      return str
    }
  },
  methods: {
    clickAction(id) {
      this.$emit('clickAction', id, this.jobId)
    },
    goPersonalDynamic(id) {
      let jobFilter = this.jobId
      this.$router.push({
        path: '/enterpriseDynamic',
        query: {
          index: id,
          jobFilter: jobFilter
        }
      })
    },
    goJobDetailed() {
      this.$emit('goJobDetailed', this.jobId)
    },

  }
}
</script>

<style lang="less" scoped>
.warehouseJobItem_warp {
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #f4f4f4;
  cursor: pointer;

  .el-row {
    height: 100%;

    .el-col {
      height: 100%;
    }
  }

  .left {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .top {
      font-size: 16px;
      color: #323232;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 5px;

      .title {
        width: 100px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }

      .salary {
        color: #e20000;
        margin-left: 10px;
      }
    }

    .bottom {
      font-size: 12px;
      color: #5e5e5e;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .bottom-item {
        border-right: 1px solid #909090;
        padding: 0 5px;

        &:first-child {
          padding-left: 0px;
        }

        &:last-child {
          padding-right: 0px;
          border-right: 0px;
        }
      }
    }

  }

  .center {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .center-top {
      height: 35px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
    }

    .center-bottom {
      height: 20px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
    }

    .center_item {
      font-size: 13px;
      color: #5e5e5e;
      margin-right: 10px;
      cursor: pointer;

      .el-icon-arrow-right {
        //margin-left: 0px;
      }
    }
  }

  .right {
    width: 100%;
    height: 100%;
    position: relative;

    .right-top {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 14px;
      padding-bottom: 21px;
      box-sizing: border-box;

      .right_item {
        color: #e20000;
        text-decoration: underline;
        margin-right: 10px;
        cursor: pointer;
      }
    }

    .right-bottom {
      font-size: 14px;
      position: absolute;
      right: 0px;
      bottom: 0px;
      background-color: #eeeeee;
      color: #acacac;
      width: 150px;
      padding-top: 5px;
      padding-right: 10px;
      box-sizing: border-box;
      border-top-left-radius: 100px;
      text-align: right;
    }

  }

}
</style>
