<template>
  <div class="searchInput_warp">
    <el-input :placeholder="placeholder" v-model.trim="searchValue" clearable class="search-input" @clear="searchClear">
      <div class="search-btn" slot="append" @click="searchSubmit">
        搜索
      </div>
    </el-input>
  </div>
</template>

<script>
export default {
  name: "searchInput",
  props: {
    defSearchValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '搜索岗位/企业'
    },
  },
  data() {
    return {
      searchValue: ''
    }
  },
  watch: {
    defSearchValue: {
      handler: function () {
        this.searchValue = this.defSearchValue
      },
      immediate: true
    },
    searchValue() {
      this.$emit('searchValueInput', this.searchValue)
    }
  },
  mounted() {
    this.searchValue = this.defSearchValue
  },
  methods: {
    searchSubmit() {
      this.$emit('searchSubmit', this.searchValue)
    },
    searchClear() {
      this.searchValue = ''
      this.$nextTick(() => {
        this.$emit('searchSubmit', '')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.searchInput_warp {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  /* 搜索框的输入框样式 */
  .search-input {
    font-size: 18px;

    & /deep/ .el-input-group__append {
      background-color: #000000;
      padding: 0px 0px !important;
      border: none;
    }

    & /deep/ .el-input__inner {
      letter-spacing: 1px;
      border-radius: 0px !important;
    }

    /* 搜索框按钮样式 */
    .search-btn {
      font-size: 18px;
      letter-spacing: 10px;
      background-color: #e20000;
      color: #ffffff;
      height: 40px;
      width: 105px;
      box-sizing: border-box;
      line-height: 40px;
      text-align: center;
      padding-left: 12px;
      cursor: pointer;
    }
  }
}
</style>