<template>
  <div class="applicationProcess_warp" v-loading="pageLoading">

    <div class="left">

      <div class="header">

        <div class="header-left" v-if="information.employeeInformation">
          <el-avatar size="large" :src="newAvatarUrl"></el-avatar>

          <div class="information" v-if="information.employeeInformation">
            <div class="top">
              <div class="name">{{ information.employeeInformation.name }}</div>
              <div class="span-warp">
                <span>{{ information.employeeInformation.age }}岁</span>
                <span>{{ information.employeeInformation.workExperienceYears }}年</span>
                <span>{{ information.employeeInformation.academicLevel }}</span>
                <span>{{ jobSearchingStatus }}</span>
              </div>
            </div>

            <div class="center">
              求职期望：
              <span>{{ information.employeeInformation.expectedJobAddressList }}</span>
              <span> · {{ information.employeeInformation.expectedJobList }}</span>
              <span> · {{ expectedSalaryRange }}</span>
            </div>

            <div class="tag">
              个人亮点：
              <span v-for="(item, index) in information.employeeInformation.personalHighLightList"
                    :key="'personalHighLightList'+index">{{ item }}</span>
            </div>

            <div class="bottom">
              {{ information.employeeInformation.selfIntroduction }}
            </div>
          </div>
        </div>


        <div class="header-right" v-if="information.redPocketJobInformation">
          岗位：<span>{{ information.redPocketJobInformation.jobName }}</span>
        </div>

      </div>

      <div class="status">
        <div class="title">
          状态
        </div>
        <div class="status-content">

          <div class="btn-item" v-if="redPocketApplyStatus === 1">
            <div>待处理</div>
            <div class="redEnvelopeProcessStatus1">
              <el-button size="medium" @click="terminateDialog3 = true">同 意</el-button>
              <el-button size="medium" @click="terminateDialog4 = true">拒 绝</el-button>
            </div>
          </div>
          <div class="btn-item" v-if="redPocketApplyStatus === 2">
            <div class="redEnvelopeProcessStatus2">
              已拒绝
            </div>
            <div></div>
          </div>
          <div class="btn-item" v-if="redPocketApplyStatus === 3">
            <div>进行中</div>
            <div class="redEnvelopeProcessStatus3">
              <div class="show">
                <span>距离该红包到账</span>
                <span class="date">{{remainDaysStr}}</span>
              </div>
              <div class="hidden" @click="terminateDialog = true">
                中断该红包
              </div>
            </div>
          </div>
          <div class="btn-item" v-if="redPocketApplyStatus === 4">
            <div class="redEnvelopeProcessStatus4">
              已中断
            </div>
            <div></div>
          </div>
          <div class="btn-item" v-if="redPocketApplyStatus === 5">
            <div class="redEnvelopeProcessStatus5">
              已结束
            </div>
            <div></div>
          </div>
          <div class="btn-item" v-if="redPocketApplyStatus === 6">
            <div class="redEnvelopeProcessStatus5">
              冲突中
            </div>
            <div></div>
          </div>


        </div>
      </div>

      <div class="timeline" v-if="redPocketApplyStatus !== 1 && redPocketApplyStatus !== 2 && redPocketApplyStatus !== 6">
        <el-timeline v-if="information.redPocketApplyInformation">
          <el-timeline-item
              type="primary"
              color="#e20000"
              size="large"
              :timestamp="information.redPocketApplyInformation.handledTime"
          >

            <div class="timeline-item-warp">
              <div class="title">
                开始
              </div>
            </div>

          </el-timeline-item>
          <el-timeline-item
              v-for="(activity, index) in activities"
              :key="'timeline-item' + index"
              :type="activity.type"
              :color="activity.color"
              :size="activity.size"
              :timestamp="activity.timestamp">

            <div class="timeline-item-warp">
              <div class="title">
                第{{activity.installment}}期
              </div>
              <div class="text">
                红包到账{{activity.amount}}元
              </div>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>

    </div>

    <div class="right">

      <dynamic-entry @goEnterpriseDynamic="changeCurrNavPage"></dynamic-entry>

    </div>

    <el-dialog
        title="提示"
        :visible.sync="redPocketCollisionVisible"
        custom-class="red-pocket-collision-visible-dialog"
        width="600px">
      <div class="redPocketCollision_warp">对方已有红包流程在进行中，是否确认继续</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="redPocketCollisionVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="redPocketCollisionSubmit">确 认</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="terminateDialog"
        width="400px"
    >
      <div style="text-align: center;font-size: 16px; color: #333333;">确定要中断该红包吗</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="terminateDialog = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="terminate">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="terminateDialog3"
        width="400px"
    >
      <div style="text-align: center;font-size: 16px; color: #333333;">确定同意吗？同意之后红包流程就会开始</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="terminateDialog3 = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="agreeApply">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="terminateDialog4"
        width="400px"
    >
      <div style="text-align: center;font-size: 16px; color: #333333;">确定拒绝吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="terminateDialog4 = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="rejectApply">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="terminateDialog2"
        custom-class="red-pocket-collision-visible-dialog"
        width="600px">
      <div style="text-align: center;font-size: 16px; color: #333333;">操作失败，该红包岗位剩余的红包金额不足</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="btnLoading" @click="terminateDialog2 = false">确 认</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import DynamicEntry from "@/components/dynamicEntry/dynamicEntry";
import {GetHandleRedPocketApplyApi, GetRedPocketApplyDetailApi, ParamTerminateRedPocketApi} from '@/request/api'
import {jobSearchingStatus} from '@/share/shareData'
import share from "@/share/share";

export default {
  name: "applicationProcess",
  components: {DynamicEntry},
  data() {
    return {
      pageLoading: false,
      jobId: '',
      recordId: '',
      reverse: true,
      information: {},
      activities: [],
      currentInstallment: 0,
      redPocketApplyStatus: 0,
      remainDays: '',
      redPocketCollisionVisible: false,
      terminateDialog: false,
      terminateDialog2: false,
      terminateDialog3: false,
      terminateDialog4: false,
      btnLoading: false,
    }
  },
  computed: {
    userType() {
      return this.$store.state.userInfo.userType || ''
    },
    defAvatarUrl() {
      if (this.userType === 'hr') {
        return 'https://cdn.jiaxinzp.com/jxzp/hr_avatar_male.png'
      }else {
        return 'https://cdn.jiaxinzp.com/jxzp/employee_avatar_male.png'
      }
    },
    newAvatarUrl() {
      if (this.information.employeeInformation.avatar) {
        return share.initImageUrl(this.information.employeeInformation.avatar)
      }else {
        return this.defAvatarUrl
      }
    },
    jobSearchingStatus() {
      let str = ''
      jobSearchingStatus.forEach((item, index) => {
        if (item.value === this.information.employeeInformation.jobSearchingStatus) {
          str = item.label
        }
      })
      return str
    },
    expectedSalaryRange() {
      let str = ''
      if (this.information.employeeInformation.expectedSalaryRange && this.information.employeeInformation.expectedSalaryRange.length) {
        // str = this.information.employeeInformation.expectedSalaryRange[0] + '-' + this.information.employeeInformation.expectedSalaryRange[1]
        str = parseInt(this.information.employeeInformation.expectedSalaryRange[0])/1000 + 'k-' + parseInt(this.information.employeeInformation.expectedSalaryRange[1])/1000 + 'k'
      } else {
        str = '经验不限'
      }
      return str
    },
    remainDaysStr() {
      let str = ''
      if (this.remainDays === 0) {
        str = '不足一天'
      }else {
        str = '剩余' +  this.remainDays + '天'
      }
      return str
    }
  },
  async mounted() {

    this.jobId = this.$route.query.id || ''
    this.recordId = this.$route.query.recordId || ''
    await this.loadData()
  },
  methods: {
    async loadData() {
      this.pageLoading = true
      const res = await GetRedPocketApplyDetailApi({
        recordId: this.recordId
      })

      if (res) {
        this.information = res
        this.currentInstallment = res.redPocketApplyInformation.currentInstallment
        this.redPocketApplyStatus = res.redPocketApplyInformation.redPocketApplyStatus
        this.remainDays = res.redPocketApplyInformation.remainDays
        let arr = []
        arr = res.redPocketApplyInformation.eachInstallmentTimeAmount
        arr.forEach((item, index) => {
          item.timestamp = item.time
          item.size = 'large'
          item.type = 'primary'
          if (this.currentInstallment < item.installment + 1) {
            item.color = '#e5e5e5'
          }else {
            item.color = '#e20000'
          }

        })
        this.activities = arr
      }
      this.pageLoading = false
    },
    changeCurrNavPage(key, index) {
      this.$router.push({
        path: key,
        query: {
          index: index
        }
      })
    },
    async agreeApply() {
      this.btnLoading = true
      const res = await GetHandleRedPocketApplyApi({
        recordId: this.recordId,
        action: 'confirm'
      })

      this.btnLoading = false
      this.terminateDialog3 = false
      if (res) {
        if (res && res.message === 'noMoney') {
          this.terminateDialog2 = true
          return
        }
        if (res && res.message && res.message !== 'warning') {
          share.message.success('操作成功')
          await this.loadData()
        }else {
          this.redPocketCollisionVisible = true
        }
      }

    },
    async rejectApply() {
      this.btnLoading = true
      const res = await GetHandleRedPocketApplyApi({
        recordId: this.recordId,
        action: 'reject'
      })

      this.btnLoading = false
      this.terminateDialog4 = false
      if (res) {
        share.message.success('操作成功')
        await this.loadData()
      }
    },
    async terminate() {
      this.btnLoading = true
      const res = await ParamTerminateRedPocketApi({
        recordId: this.recordId,
      })

      if (res) {
        share.message.success('操作成功')
        await this.loadData()
      }
      this.btnLoading = false
      this.terminateDialog = false
    },
    async redPocketCollisionSubmit() {
      this.btnLoading = true
      const res = await GetHandleRedPocketApplyApi({
        recordId: this.recordId,
        action: 'stillConfirm'
      })

      if (res) {
        share.message.success('已同意，等待求职者同意')
        await this.loadData()
      }
      this.terminateDialog3 = false
      this.btnLoading = false
      this.redPocketCollisionVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.applicationProcess_warp {
  width: 100%;
  //height: 100%;
  background-color: #f4f2f3;
  display: flex;
  justify-content: space-between;

  .left {
    width: 780px;
    //min-height: 600px;
    background-color: #ffffff;
    padding: 0 30px;
    box-sizing: border-box;

    .header {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 30px;

      > div {
        height: 100%;
      }

      .header-left {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        .el-avatar--large {
          width: 100px;
          height: 100px;
          margin-right: 20px;
          //margin-left: 20px;
        }

        .information {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          .top {
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .name {
              max-width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            > div:first-child {
              color: #333333;
              margin-right: 20px;
              font-weight: bold;
            }

            > div:last-child {
              font-size: 14px;
              color: #3a3a3a;

              span {
                padding: 0 5px;
                border-right: 1px solid #3a3a3a;
                height: 14px;
                display: inline-block;
                line-height: 14px;

                &:first-child {
                  padding-left: 0px;
                }

                &:last-child {
                  padding-right: 0px;
                  border-right: 0px;
                }
              }
            }
          }

          .center {
            color: #5a5a5a;
            font-size: 14px;

            span {
              color: #e20000;
            }

            margin: 10px 0 0 0;
          }

          .tag {
            width: 400px;
            color: #5a5a5a;
            font-size: 14px;
            display: flex;
            flex-wrap: wrap;

            span {
              border: 1px solid #e20000;
              padding: 0 12px;
              box-sizing: border-box;
              margin-right: 10px;
              margin-bottom: 5px;
              border-radius: 10px;
            }

            margin: 10px 0;
          }

          .bottom {
            width: 350px;
            color: #5a5a5a;
            font-size: 14px;
            word-wrap: break-word;
            word-break: break-all;
          }
        }

      }


      .header-right {
        height: 100%;
        flex: 1;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        font-size: 14px;
        color: #3a3a3a;

        span {
          color: #e20000;
          display: inline-block;
          max-width: 150px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
      }

    }

    .status {
      margin: 40px 0;
      width: 100%;
      height: 130px;
      border: 1px solid #d1d1d1;
      border-radius: 5px;

      .title {
        font-size: 14px;
        background-color: #eeeeee;
        height: 40px;
        line-height: 40px;
        color: #343434;
        padding: 0 20px;
        box-sizing: border-box;
      }

      .status-content {
        height: calc(100% - 40px);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px;
        box-sizing: border-box;

        .btn-item {
          font-size: 18px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .redEnvelopeProcessStatus1 {
            flex: 1;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .el-button {
              color: #333333;
              background-color: #ffffff;

              &:hover {
                color: #ffffff;
                background-color: #e20000;
              }
            }
          }

          .redEnvelopeProcessStatus2 {
            color: #676767;
          }

          .redEnvelopeProcessStatus3 {
            width: 100px;
            height: 50px;
            box-sizing: border-box;
            color: #676767;
            font-size: 12px;
            background-color: #ffffff;
            border: 1px solid #a0a0a0;

            &:hover {
              .show {
                display: none;
              }

              .hidden {
                display: inline-block;
              }
            }

            .show {
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .date {
                color: #e20000;
              }
            }

            .hidden {
              height: 100%;
              width: 100%;
              text-align: center;
              line-height: 50px;
              display: none;
              cursor: pointer;
              color: #e20000;
            }
          }

          .redEnvelopeProcessStatus4, .redEnvelopeProcessStatus5 {
            color: #676767;
          }

          //.el-button {
          //  border: 1px solid #e20000;
          //  color: #e20000;
          //}
        }
      }
    }

    .timeline {
      .timeline-item-warp {
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .title {
          font-size: 16px;
          margin-right: 20px;
        }

        .text {
          font-size: 12px;
          color: #e20000;
        }
      }
    }

  }

  .right {
    flex: 1;
    //min-height: 600px;
    margin-left: 10px;
    background-color: #f4f2f3;
  }

}
</style>
