<template>
  <div class="applicationOfMakeOutAnInvoice_warp">
    <!-- 申请开票 -->
    <el-dialog
        title="申请开票"
        :visible.sync="invoicingDetailedDialogVisible"
        custom-class="invoicingDetailedDialog_warp"
        :before-close="invoicingDetailedDialogClose"
        width="800px"
    >
      <div class="invoicingDetailed-content" v-loading="dialogLoading">
        <el-form ref="invoicingDetailedFormRef" :rules="invoicingDetailedDialogFormRules"
                 :model="invoicingDetailedDialogForm" label-width="110px">

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="发票性质" prop="invoiceNature">
                <el-radio-group size="mini" v-model="invoicingDetailedDialogForm.invoiceNature" @change="setInvoiceCache">
                  <el-radio :label="1">个人/非企业单位</el-radio>
                  <el-radio :label="2">公司</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="发票抬头" prop="invoiceHeader">
                <el-input
                    size="mini"
                    placeholder="请输入发票抬头"
                    disabled
                    v-model="invoicingDetailedDialogForm.invoiceHeader">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="申请金额" prop="applicationAmount">
                <el-input
                    size="mini"
                    placeholder="请输入申请金额"
                    disabled
                    v-model="invoicingDetailedDialogForm.applicationAmount">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="开票项目" prop="billingItems">
                <el-select size="mini" v-model="invoicingDetailedDialogForm.billingItems" placeholder="请选择开票项目">
                  <el-option
                      label="服务费"
                      :value="1">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发票类型" prop="invoiceType">
                <el-select size="mini" v-model="invoicingDetailedDialogForm.invoiceType" placeholder="请选择发票类型">
                  <el-option
                      label="增值税普通电子发票"
                      :value="1">
                  </el-option>
                  <el-option
                      v-if="invoicingDetailedDialogForm.invoiceNature === 2"
                      label="增值税专用发票"
                      :value="2">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20" v-if="invoicingDetailedDialogForm.invoiceNature === 2">
            <el-col :span="12">
              <el-form-item label="税号" prop="taxID">
                <el-input
                    size="mini"
                    placeholder="请输入纳税人识别号"
                    @input="setCache"
                    v-model="invoicingDetailedDialogForm.taxID">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20" v-if="invoicingDetailedDialogForm.invoiceType === 2">
            <el-col :span="12">
              <el-form-item label="地址" prop="registeredAddress">
                <el-input
                    size="mini"
                    placeholder="请输入注册地址"
                    @input="setCache"
                    v-model="invoicingDetailedDialogForm.registeredAddress">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电话" prop="registeredTelephone">
                <el-input
                    size="mini"
                    placeholder="请输入注册电话"
                    @input="setCache"
                    v-model="invoicingDetailedDialogForm.registeredTelephone">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20" v-if="invoicingDetailedDialogForm.invoiceType === 2">
            <el-col :span="12">
              <el-form-item label="银行账户" prop="bankAccount">
                <el-input
                    size="mini"
                    placeholder="请输入银行账户"
                    @input="setCache"
                    v-model="invoicingDetailedDialogForm.bankAccount">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开户行名称" prop="bankOfDeposit">
                <el-input
                    size="mini"
                    placeholder="请输入开户行名称"
                    @input="setCache"
                    v-model="invoicingDetailedDialogForm.bankOfDeposit">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="tip" v-if="invoicingDetailedDialogForm.invoiceType === 2">
            资料文件上传：（上传文件均需要盖公章，支持的图片格式为jpg/jpeg/png，单个图片小于5MB）
          </div>

          <el-row :gutter="20" v-if="invoicingDetailedDialogForm.invoiceType === 2">
            <el-col :span="12">
              <el-form-item label="营业执照副本">
                <el-upload
                    :action="uploadUrl"
                    name="image"
                    :on-preview="imageHandlePreview"
                    :on-remove="businessLicenseHandleRemove"
                    :before-upload="imageBeforeUpload"
                    :limit="1"
                    :on-exceed="imageHandleExceed"
                    :on-success="businessLicenseHandleSuccess"
                    :file-list="businessLicense">
                  <el-button size="mini" type="warning">点击上传</el-button>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="税务登记证副本">
                <el-upload
                    :action="uploadUrl"
                    name="image"
                    :on-preview="imageHandlePreview"
                    :on-remove="taxRegistrationHandleRemove"
                    :before-upload="imageBeforeUpload"
                    :limit="1"
                    :on-exceed="imageHandleExceed"
                    :on-success="taxRegistrationHandleSuccess"
                    :file-list="taxRegistration">
                  <el-button size="mini" type="warning">点击上传</el-button>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20" v-if="invoicingDetailedDialogForm.invoiceType === 2">
            <el-col :span="12">
              <el-form-item label="银行开户许可证">
                <el-upload
                    :action="uploadUrl"
                    name="image"
                    :on-preview="imageHandlePreview"
                    :on-remove="bankAccountOpeningLicenseHandleRemove"
                    :before-upload="imageBeforeUpload"
                    :limit="1"
                    :on-exceed="imageHandleExceed"
                    :on-success="bankAccountOpeningLicenseHandleSuccess"
                    :file-list="bankAccountOpeningLicense">
                  <el-button size="mini" type="warning">点击上传</el-button>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="一般纳税人证书">
                <el-upload
                    :action="uploadUrl"
                    name="image"
                    :on-preview="imageHandlePreview"
                    :on-remove="generalTaxpayerCertificateHandleRemove"
                    :before-upload="imageBeforeUpload"
                    :limit="1"
                    :on-exceed="imageHandleExceed"
                    :on-success="generalTaxpayerCertificateHandleSuccess"
                    :file-list="generalTaxpayerCertificate">
                  <el-button size="mini" type="warning">点击上传</el-button>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="tip" v-if="invoicingDetailedDialogForm.invoiceType === 1">
            尊敬的用户您好，根据国家相关规定，电子发票的法律效力、基本用途、基本使用规定等与税务机关监制的纸质增值税普通发票相同，建议您选择开具电子发票。
          </div>

          <div class="tip" v-if="invoicingDetailedDialogForm.invoiceType === 2">
            您申请的发票将以快递的方式邮递给您
          </div>

          <el-row :gutter="20" v-if="invoicingDetailedDialogForm.invoiceType === 1">
            <el-col :span="12">
              <el-form-item label="收票人邮箱" prop="checkTakerEmail">
                <el-input
                    size="mini"
                    placeholder="请输入收票人邮箱"
                    @input="setCache"
                    v-model="invoicingDetailedDialogForm.checkTakerEmail">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20" v-if="invoicingDetailedDialogForm.invoiceType === 2">
            <el-col :span="12">
              <el-form-item label="收票人姓名" prop="checkTakerName">
                <el-input
                    size="mini"
                    placeholder="请输入收票人姓名"
                    @input="setCache"
                    v-model="invoicingDetailedDialogForm.checkTakerName">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收票人电话" prop="checkTakerPhoneNumber">
                <el-input
                    size="mini"
                    placeholder="请输入收票人电话"
                    @input="setCache"
                    v-model="invoicingDetailedDialogForm.checkTakerPhoneNumber">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20" v-if="invoicingDetailedDialogForm.invoiceType === 2">
            <el-col :span="24">
              <el-form-item label="收票人地址" prop="checkTakerAddress">
                <el-input
                    size="mini"
                    placeholder="请输入收票人地址"
                    @input="setCache"
                    v-model="invoicingDetailedDialogForm.checkTakerAddress">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="24">
              <div class="agreement">
                <el-checkbox v-model="isRead">我已阅读<span class="link" @click="toRulesPage('Invoicing_Agreement')">《{{AllAgreement.Invoicing_Agreement}}》</span></el-checkbox>
              </div>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item>
                <el-button @click="invoicingDetailedDialogClose">取 消</el-button>
                <el-button type="primary" :loading="submitLoading" @click="invoicingDetailedDialogSubmit">立即申请</el-button>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="previewImageDialogVisible" custom-class="previewImageDialogVisible">
      <img width="100%" :src="previewImage" alt="">
    </el-dialog>
  </div>
</template>

<script>
import {formRules} from "@/share/formRules";
import {ALL_RULES, BASE_URL} from "@/config/baseURL";
import share from "@/share/share";
import {AllAgreement} from "@/share/shareData";
import {
  GetHrInvoiceCacheApi,
  PostHrInvoiceCacheApi,
} from "@/request/api"
import {Debounce} from "@/until/until";

export default {
  name: "applicationOfMakeOutAnInvoice",
  props: {
    visible: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    visible: {
      handler: function () {
        this.invoicingDetailedDialogVisible = this.visible
        this.loadHrInvoiceCacheData()
      },
      immediate: true
    },
    invoicingDetailedDialogVisible: {
      handler: function () {
        this.$emit('update:visible', this.invoicingDetailedDialogVisible)
      },
    },
    'invoicingDetailedDialogForm.invoiceNature': {
      handler: function () {
        if (this.invoicingDetailedDialogForm.invoiceNature === 1) {
          this.invoicingDetailedDialogForm.invoiceType = 1
        }
      }
    }
  },
  data() {
    return {
      AllAgreement: AllAgreement,
      dialogLoading: false,
      isRead: false,
      previewImageDialogVisible: false,
      previewImage: '',
      uploadUrl: BASE_URL + '/user-files/upload-image/',
      invoicingDetailedDialogFormRules: formRules,
      invoicingDetailedDialogVisible: false,

      orderId: '',
      businessLicense: [],  // 营业执照文件列表
      taxRegistration: [],  // 税务登记证文件列表
      bankAccountOpeningLicense: [],  // 银行开户许可证文件列表
      generalTaxpayerCertificate: [],  // 一般纳税人证书文件列表
      invoicingDetailedDialogForm: {
        invoiceNature: 2,  // 发票性质
        invoiceHeader: '',  // 发票抬头
        applicationAmount: '',  // 申请金额
        billingItems: 1,  // 开票项目
        invoiceType: 1,  // 发票类型； 1是增值税普通发票、2是增值税专用发票
        taxID: '',  // 税号
        registeredAddress: '',  // 注册地址
        registeredTelephone: '',  // 注册电话
        bankAccount: '',  // 银行账户
        bankOfDeposit: '',  // 开户行名称

        businessLicense: [],  // 营业执照
        taxRegistration: [],  // 税务登记证
        bankAccountOpeningLicense: [],  // 银行开户许可证
        generalTaxpayerCertificate: [],  // 一般纳税人证书

        checkTakerEmail: '',  // 收票人邮箱
        checkTakerName: '',  // 收票人姓名
        checkTakerPhoneNumber: '',  // 收票人电话
        checkTakerAddress: '',  // 收票人地址
      },
      submitLoading: false,
    }
  },
  mounted() {
  },
  methods: {
    VueDebounce: Debounce(({vm}) => {
      vm['setInvoiceCache']()
    }, 500),
    async loadHrInvoiceCacheData() {
      this.dialogLoading = true
      const res = await GetHrInvoiceCacheApi()
      this.dialogLoading = false

      if (res && res.invoiceCache) {
        let invoiceCache = JSON.parse(res.invoiceCache)
        this.invoicingDetailedDialogForm.invoiceNature = invoiceCache.invoiceNature  // 发票性质
        this.invoicingDetailedDialogForm.taxID = invoiceCache.taxID  // 税号
        this.invoicingDetailedDialogForm.registeredAddress = invoiceCache.registeredAddress  // 注册地址
        this.invoicingDetailedDialogForm.registeredTelephone = invoiceCache.registeredTelephone  // 注册电话
        this.invoicingDetailedDialogForm.bankAccount = invoiceCache.bankAccount  // 银行账户
        this.invoicingDetailedDialogForm.bankOfDeposit = invoiceCache.bankOfDeposit  // 开户行名称

        this.invoicingDetailedDialogForm.businessLicense = invoiceCache.businessLicense  // 营业执照
        this.businessLicense = invoiceCache.businessLicense  // 营业执照
        this.invoicingDetailedDialogForm.taxRegistration = invoiceCache.taxRegistration  // 税务登记证
        this.taxRegistration = invoiceCache.taxRegistration  // 税务登记证
        this.invoicingDetailedDialogForm.bankAccountOpeningLicense = invoiceCache.bankAccountOpeningLicense  // 银行开户许可证
        this.bankAccountOpeningLicense = invoiceCache.bankAccountOpeningLicense  // 银行开户许可证
        this.invoicingDetailedDialogForm.generalTaxpayerCertificate = invoiceCache.generalTaxpayerCertificate  // 一般纳税人证书
        this.generalTaxpayerCertificate = invoiceCache.generalTaxpayerCertificate  // 一般纳税人证书

        this.invoicingDetailedDialogForm.checkTakerEmail = invoiceCache.checkTakerEmail  // 收票人邮箱
        this.invoicingDetailedDialogForm.checkTakerName = invoiceCache.checkTakerName  // 收票人姓名
        this.invoicingDetailedDialogForm.checkTakerPhoneNumber = invoiceCache.checkTakerPhoneNumber  // 收票人电话
        this.invoicingDetailedDialogForm.checkTakerAddress = invoiceCache.checkTakerAddress  // 收票人地址

        if (this.invoicingDetailedDialogForm.invoiceNature === 1) {
          this.invoicingDetailedDialogForm.invoiceType = 1
        }
      }
    },
    init(data, orderId) {
      if (Object.prototype.toString.call(data) === '[object Object]') {
        Object.keys(data).forEach((item, index) => {
          this.invoicingDetailedDialogForm[item] = data[item]
        })
      }
      this.orderId = orderId
    },
    imageBeforeUpload(file) {
      const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        share.message.error('上传的图片只能是 JPG 或 PNG 格式');
      }
      if (!isLt5M) {
        share.message.error('上传的图片大小不能超过 5MB');
      }
      return isJPG && isLt5M;
    },
    imageHandleExceed() {
      share.message.error('只允许上传一张图片')
    },
    imageHandlePreview(file) {
      if (file && file.response && file.response.code === 200) {
        this.previewImage = file.response.data.url
        this.previewImageDialogVisible = true
      }else if (file.url) {
        this.previewImage = file.url
        this.previewImageDialogVisible = true
      }else {
        share.message.error('文件错误，预览失败')
      }
    },
    businessLicenseHandleSuccess(response, file, fileList) {
      if (file && file.response && file.response.code === 200) {
        this.invoicingDetailedDialogForm.businessLicense = [{
          url: file.response.data.url,
          id: file.response.data.imageId,
          name: file.response.data.imageId,
        }]

        this.setCache()
      }
    },
    businessLicenseHandleRemove(file, fileList) {
      this.businessLicense = []
      this.invoicingDetailedDialogForm.businessLicense = []
    },
    taxRegistrationHandleSuccess(response, file, fileList) {
      if (file && file.response && file.response.code === 200) {
        this.invoicingDetailedDialogForm.taxRegistration = [{
          url: file.response.data.url,
          id: file.response.data.imageId,
          name: file.response.data.imageId,
        }]

        this.setCache()
      }
    },
    taxRegistrationHandleRemove(file, fileList) {
      this.taxRegistration = []
      this.invoicingDetailedDialogForm.taxRegistration = []
    },
    bankAccountOpeningLicenseHandleSuccess(response, file, fileList) {
      if (file && file.response && file.response.code === 200) {
        this.invoicingDetailedDialogForm.bankAccountOpeningLicense = [{
          url: file.response.data.url,
          id: file.response.data.imageId,
          name: file.response.data.imageId,
        }]

        this.setCache()
      }
    },
    bankAccountOpeningLicenseHandleRemove(file, fileList) {
      this.bankAccountOpeningLicense = []
      this.invoicingDetailedDialogForm.bankAccountOpeningLicense = []
    },
    generalTaxpayerCertificateHandleSuccess(response, file, fileList) {
      if (file && file.response && file.response.code === 200) {
        this.invoicingDetailedDialogForm.generalTaxpayerCertificate = [{
          url: file.response.data.url,
          id: file.response.data.imageId,
          name: file.response.data.imageId,
        }]

        this.setCache()
      }
    },
    generalTaxpayerCertificateHandleRemove(file, fileList) {
      this.generalTaxpayerCertificate = []
      this.invoicingDetailedDialogForm.generalTaxpayerCertificate = []
    },

    invoicingDetailedDialogClose() {
      this.$refs.invoicingDetailedFormRef.resetFields()

      this.invoicingDetailedDialogVisible = false
    },

    invoicingDetailedDialogSubmit() {
      this.$refs.invoicingDetailedFormRef.validate(async res => {
        if (res) {
          this.setCache()

          if (!this.isRead) {
            share.message.warning(`请勾选我已阅读《${AllAgreement.Invoicing_Agreement}》`)
            return;
          }
          this.submitLoading = true

          let params = {
            orderId: this.orderId,  // 订单信息
            invoiceNature: this.invoicingDetailedDialogForm.invoiceNature,  // 发票性质；1是个人/非企业单位、2是公司
            invoiceHeader: this.invoicingDetailedDialogForm.invoiceHeader,  // 发票抬头
            applicationAmount: this.invoicingDetailedDialogForm.applicationAmount,  // 申请金额
            billingItems: this.invoicingDetailedDialogForm.billingItems,  // 开票项目；1是服务费
            invoiceType: this.invoicingDetailedDialogForm.invoiceType,  // 发票类型； 1是增值税普通发票、2是增值税专用发票 （发票性质为1时强制发票类型也为1）
          }
          if (params.invoiceNature === 2) {
            params.taxID = this.invoicingDetailedDialogForm.taxID  // 税号 （发票性质为2时才有此字段）
          }
          if (params.invoiceType === 1) {
            params.checkTakerEmail = this.invoicingDetailedDialogForm.checkTakerEmail  // 收票人邮箱 （发票类型为1时才有此字段）
          }else {
            params.registeredAddress = this.invoicingDetailedDialogForm.registeredAddress  // 注册地址 （发票类型为2时才有此字段）
            params.registeredTelephone = this.invoicingDetailedDialogForm.registeredTelephone  // 注册电话 （发票类型为2时才有此字段）
            params.bankAccount = this.invoicingDetailedDialogForm.bankAccount  // 银行账户 （发票类型为2时才有此字段）
            params.bankOfDeposit = this.invoicingDetailedDialogForm.bankOfDeposit  // 开户行名称 （发票类型为2时才有此字段）

            let businessLicense = []
            this.invoicingDetailedDialogForm.businessLicense.forEach((item, index) => {
              if (item.url) {
                businessLicense.push(item.url)
              }
            })
            params.businessLicense = JSON.stringify(businessLicense)  // 营业执照 （发票类型为2时才有此字段）

            let taxRegistration = []
            this.invoicingDetailedDialogForm.taxRegistration.forEach((item, index) => {
              if (item.url) {
                taxRegistration.push({
                  url: item.url
                })
              }
            })
            params.taxRegistration = JSON.stringify(taxRegistration)  // 税务登记证 （发票类型为2时才有此字段）

            let bankAccountOpeningLicense = []
            this.invoicingDetailedDialogForm.bankAccountOpeningLicense.forEach((item, index) => {
              if (item.url) {
                bankAccountOpeningLicense.push({
                  url: item.url
                })
              }
            })
            params.bankAccountOpeningLicense = JSON.stringify(bankAccountOpeningLicense)  // 银行开户许可证 （发票类型为2时才有此字段）

            let generalTaxpayerCertificate = []
            this.invoicingDetailedDialogForm.generalTaxpayerCertificate.forEach((item, index) => {
              if (item.url) {
                generalTaxpayerCertificate.push({
                  url: item.url
                })
              }
            })
            params.generalTaxpayerCertificate = JSON.stringify(generalTaxpayerCertificate)  // 一般纳税人证书 （发票类型为2时才有此字段）

            params.checkTakerName = this.invoicingDetailedDialogForm.checkTakerName  // 收票人姓名 （发票类型为2时才有此字段）
            params.checkTakerPhoneNumber = this.invoicingDetailedDialogForm.checkTakerPhoneNumber  // 收票人电话 （发票类型为2时才有此字段）
            params.checkTakerAddress = this.invoicingDetailedDialogForm.checkTakerAddress  // 收票人地址 （发票类型为2时才有此字段）
          }

          this.$emit('handleSubmit', params, () => {
            this.submitLoading = false
            this.invoicingDetailedDialogClose()
          })
        }
      })
    },

    /**
     * 设置发票缓存
     * */
    async setInvoiceCache() {
      let {
        invoiceNature,  // 发票性质
        taxID,  // 税号
        registeredAddress,  // 注册地址
        registeredTelephone,  // 注册电话
        bankAccount,  // 银行账户
        bankOfDeposit,  // 开户行名称

        businessLicense,  // 营业执照
        taxRegistration,  // 税务登记证
        bankAccountOpeningLicense,  // 银行开户许可证
        generalTaxpayerCertificate,  // 一般纳税人证书

        checkTakerEmail,  // 收票人邮箱
        checkTakerName,  // 收票人姓名
        checkTakerPhoneNumber,  // 收票人电话
        checkTakerAddress,  // 收票人地址
      } = {...this.invoicingDetailedDialogForm}
      const res = await PostHrInvoiceCacheApi({
        invoice_cache: JSON.stringify({
          invoiceNature,  // 发票性质
          taxID,  // 税号
          registeredAddress,  // 注册地址
          registeredTelephone,  // 注册电话
          bankAccount,  // 银行账户
          bankOfDeposit,  // 开户行名称

          businessLicense,  // 营业执照
          taxRegistration,  // 税务登记证
          bankAccountOpeningLicense,  // 银行开户许可证
          generalTaxpayerCertificate,  // 一般纳税人证书

          checkTakerEmail,  // 收票人邮箱
          checkTakerName,  // 收票人姓名
          checkTakerPhoneNumber,  // 收票人电话
          checkTakerAddress,  // 收票人地址
        })
      })

    },

    /**
     * 去协议详情页
     * */
    toRulesPage(param) {
      window.open(ALL_RULES + '?type=' + param)
    },

    setCache() {
      this.VueDebounce({vm: this})
    },
  }
}
</script>

<style lang="less" scoped>
.applicationOfMakeOutAnInvoice_warp {
  .invoicingDetailedDialog_warp {
    .invoicingDetailed-content {

      & /deep/ label {
        text-align: left;
      }

      .tip {
        font-size: 12px;
        color: #f27800;
        margin-bottom: 10px;
      }
      .agreement {
        margin-bottom: 20px;
        .link {
          cursor: pointer;
          color: #63A7FF;
        }
      }
    }
  }
}
</style>
