let MIXIN = {
    data() {
        return {
            isLoggingIn: false,
            to_page: '',
        }
    },
    computed: {
    },
    watch: {
        token: {
            handler: function () {
                if (this.isLoggingIn) {
                    return
                }
                if (this.token && this.token !== '' && this.token !== null && this.token !== 'null') {
                    if (this.userType === 'hr') {
                        if (!this.to_page) {
                            this.$router.push('/jobSeeker')
                        }
                    }
                    if (this.userType === 'employee') {
                        if (!this.to_page) {
                            this.$router.push('/')
                        }
                    }
                }
            }
        }
    },
    created() {
    },
    mounted() {
        let query = this.$route.query
        console.log('mixin----------------', query)
        // let share_area = query.share_area || ''
        let share_id = query.share_id || ''
        let to_page = query.to_page || ''
        let job_id = query.job_id || ''

        if (query) {
            // let new_share_id = decodeURIComponent(share_id)
            // this.setShareId({
            //     shareId: new_share_id
            // })
            // this.setShareArea({
            //     shareArea: share_area
            // })
            this.to_page = to_page
            if (share_id) {
                let invitationCode = decodeURIComponent(share_id)
                this.setInvitationCode({
                    invitationCode: invitationCode
                })
            }

            console.log(this.$store.state.shareId, this.$store.state.shareArea)

            if (to_page === 'jobDetails') {
                console.log('')
                this.$router.push({
                    path: '/jobDetailed',
                    query: {
                        id: job_id
                    }
                })
            }
            if (to_page === 'login') {
                this.$router.push({
                    path: '/login',
                })
            }
            if (to_page === 'register') {
                this.$router.push({
                    path: '/register',
                })
            }
        }

        if (this.token && this.token !== '' && this.token !== null && this.token !== 'null') {
            if (this.userType === 'hr') {
                if (!to_page) {
                    this.$router.push('/jobSeeker')
                }
            }
            if (this.userType === 'employee') {
                if (!to_page) {
                    this.$router.push('/')
                }
            }
        }
    },
    methods: {}
}
export default MIXIN;
