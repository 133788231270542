<template>
  <div class="login_warp" id="login_warp_id">
    <div class="header">
      <page-header :showMenu="false" :showPhone="true" backgroundColor="#f3f3f3"></page-header>
    </div>

    <div class="content">

      <div class="main">

        <div class="main-left">
          <img src="@/assets/icon/login-with-gifts.png"/>
        </div>

        <div class="main-right">

          <div class="logo">
            <img @click="goHomePage" src="@/assets/icon/logo3.png"/>
          </div>

          <div class="main-right-header">
            <div :class="newUserType === 'employee'?'tab-btn tab-btn-checked':'tab-btn'" @click="userTypeChange('employee')">
              我要找工作
            </div>
            <div :class="newUserType === 'hr'?'tab-btn tab-btn-checked':'tab-btn'" @click="userTypeChange('hr')">
              我要招人才
            </div>
          </div>
          <div class="main-right-content">

            <el-form :model="loginForm" :rules="formRules" ref="loginFormRef" label-width="0px" key="loginForm"
                     v-if="loginMode === 1">
              <el-form-item prop="phone" class="form-item">
                <el-input type="text" placeholder="手机号" v-model.trim="loginForm.phone" clearable>
                  <div class="icon" slot="prefix">
                    <img src="@/assets/icon/phone2.png"/>
                  </div>
                </el-input>
              </el-form-item>
              <el-form-item prop="password" class="form-item">
                <el-input type="password" placeholder="密码" v-model.trim="loginForm.password" clearable>
                  <div class="icon" slot="prefix">
                    <img src="@/assets/icon/password.png"/>
                  </div>
                </el-input>
              </el-form-item>
            </el-form>

            <el-form :model="phoneLoginForm" :rules="formRules" ref="phoneLoginFormRef" label-width="0px"
                     key="phoneLoginForm" v-if="loginMode === 0">
              <el-form-item prop="phone" class="form-item">
                <el-input type="text" placeholder="手机号" v-model.trim="phoneLoginForm.phone" clearable>
                  <div class="icon" slot="prefix">
                    <img src="@/assets/icon/phone2.png"/>
                  </div>
                </el-input>
              </el-form-item>
              <el-form-item prop="msg_code" class="form-item-code">

                <div class="get-code" @click="getCode">

                  <get-code ref="loginGetCode" :phone="phoneLoginForm.phone"></get-code>

                </div>

                <el-input v-model.trim="phoneLoginForm.msg_code" placeholder="验证码" clearable></el-input>

              </el-form-item>
            </el-form>

          </div>
          <div class="main-right-footer">

            <div class="switch-login-mode main-right-footer-item">
              <div class="text2">
              </div>
              <div class="text2"><span @click="forgotPassword">忘记密码</span></div>
            </div>

            <div class="login-submit main-right-footer-item">
              <el-button @click="formSubmit" :icon="submitLoading? 'el-icon-loading':''">登 录</el-button>
            </div>
            <div class="login-register main-right-footer-item">
              <el-button type="text" @click="goRegister">免费注册</el-button>
            </div>

            <div class="switch-login-mode main-right-footer-item">
              <div class="text2" @click="toWxLogin">
                <span>微信快捷登录</span>
              </div>
              <div v-if="loginMode === 1" class="text"><span @click="changeLoginMode">手机号验证登录</span></div>
              <div v-if="loginMode === 0" class="text"><span @click="changeLoginMode">手机号密码登录</span></div>
            </div>

            <div class="agreement">
              登录即视为您同意<span @click="toRulesPage('User_Agreement')">《{{AllAgreement.User_Agreement}}》</span>及<span @click="toRulesPage('Privacy_Policy')">《{{AllAgreement.Privacy_Policy}}》</span>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="footer">
      <div class="footer-title">
        求职要佳薪，现金天天领
      </div>
      <div class="footer-content">

        <div class="footer-content-item">
          <div class="icon">
            <img src="@/assets/icon/login-page-icon1.png">
          </div>
          <div class="text">
            <div class="title">屯积分领红包</div>
            <div class="msg">注册、分享获得积分，积分兑换不清零，现金红包每天领~</div>
          </div>
        </div>

        <div class="footer-content-item">
          <div class="icon">
            <img src="@/assets/icon/login-page-icon2.png">
          </div>
          <div class="text">
            <div class="title">红包岗位</div>
            <div class="msg">上岗同时领红包，百元千元等你来，快给自己佳薪一下!</div>
          </div>
        </div>

        <div class="footer-content-item">
          <div class="icon">
            <img src="@/assets/icon/login-page-icon4.png">
          </div>
          <div class="text">
            <div class="title">即时沟通</div>
            <div class="msg">人事在线即时聊，邀约简历一键发，求职路上更轻松。</div>
          </div>
        </div>

      </div>
    </div>

    <!-- 修改密码弹窗 -->
    <el-dialog
        title="忘记密码"
        :visible.sync="forgotPasswordModelIsShow"
        width="600px"
        :before-close="forgotPasswordModelClose"
        custom-class="forgotPassword_dialog"
    >
      <div class="dialog-content">
        <div class="main">
          <el-form
              :rules="modelRules"
              ref="forgotPasswordModelRef"
              :model="forgotPasswordModel"
              key="forgotPasswordModelKey"
              label-width="80px"
          >
            <div class="form-item">
              <el-form-item label="手机号" prop="phone">
                <el-input type="number" v-model.trim="forgotPasswordModel.phone" clearable></el-input>
              </el-form-item>
              <div class="desc"></div>
            </div>
            <div class="form-item">
              <el-form-item label="验证码" prop="msgCode">
                <el-input v-model.trim="forgotPasswordModel.msgCode" clearable></el-input>
              </el-form-item>
              <div class="get-code">
                <div class="btn" @click="forgotPasswordModelGetCode">
                  <get-code ref="forgotPasswordModelPhoneFormGetCodeRef" :phone="forgotPasswordModel.phone" :is-not-verify="true"></get-code>
                </div>
              </div>
            </div>
            <div class="form-item">
              <el-form-item label="新密码" prop="password">
                <el-input type="password" v-model.trim="forgotPasswordModel.password" clearable></el-input>
              </el-form-item>
              <div class="desc">
                <div>
                  <div :class="complexity >= 1?'top top-checked1':'top'"></div>
                  弱
                </div>
                <div>
                  <div :class="complexity >= 2?'top top-checked2':'top'"></div>
                  中
                </div>
                <div>
                  <div :class="complexity >= 3?'top top-checked3':'top'"></div>
                  强
                </div>
              </div>
            </div>

            <div class="form-item">
              <el-form-item label="重复密码" prop="repeatPassword">
                <el-input type="password" v-model.trim="forgotPasswordModel.repeatPassword" clearable></el-input>
              </el-form-item>
              <div class="desc"></div>
            </div>

          </el-form>
        </div>

      </div>
      <span slot="footer">
        <el-button @click="forgotPasswordModelClose">取 消</el-button>
        <el-button type="primary" :loading="forgotPasswordModelSubmitLoading" @click="forgotPasswordModelSubmit">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import PageHeader from "@/components/pageHeader/pageHeader";
import {
  EmployeeLoginApi,
  HrLoginApi,
  GetEmployeeAvatarApi,
  HrWechatLoginApi,
  EmployeeWechatLoginApi,
  GetWechatAccessTokenApi,
  GetWechatUserInfoApi, PostEmployeeForgetPasswordApi, PostHrForgetPasswordApi,
} from "@/request/api";
import {mapMutations} from "vuex"
// import {BASE_URL} from '@/config/baseURL'
import GetCode from "@/components/getCode/getCode";
import {formRules} from '@/share/formRules'
import GenerateTestUserSig from '../../../public/debug/GenerateTestUserSig'
import {ALL_RULES, WEB_BASE_URL} from "@/config/baseURL";
import share from '@/share/share'
import {get} from '@/request/http'
import axios from "axios";

import {phoneRegular} from '@/share/regularExpression'
import mixin from "@/mixin/mixin";
import {AllAgreement} from "@/share/shareData";

export default {
  name: "login",
  mixins: [mixin],
  components: {GetCode, PageHeader},
  data() {
    return {
      AllAgreement: AllAgreement,

      loginMode: 0,
      newUserType: 'employee',  // employee 雇员，employer 雇主
      loginForm: {
        phone: '',
        password: '',
        action: 'password_login'
      },
      formRules: formRules,
      phoneLoginForm: {
        phone: '',
        msg_code: '',
        action: 'msg_login'
      },
      submitLoading: false,

      wxCode: '',

      loading: null,

      loginChatServiceNum: 0,


      modelRules: {
        ...formRules,
        repeatPassword: [  // 再次输入密码
          {required: true, validator: this.repeatPassword}
        ],
      },

      forgotPasswordModel: {
        phone: '',
        msgCode: '',
        password: '',
        repeatPassword: '',
      },
      forgotPasswordModelIsShow: false,
      forgotPasswordModelSubmitLoading: false,
      complexity: 0,
    }
  },
  computed: {
    // path() {
    //   return this.$store.state.path || ''
    // }
    token() {
      return this.$store.state.token
    },
    userType() {
      return this.$store.state.userInfo.userType
    },
    temporaryMyChatUserId() {
      return this.$store.state.temporaryMyChatUserId
    },
  },
  watch: {
    // token: {
    //   handler: function () {
    //     if (this.token && this.token !== '' && this.token !== null && this.token !== 'null') {
    //
    //       if (this.userType === 'hr') {
    //         this.$router.push('/jobSeeker')
    //       }
    //       if (this.userType === 'employee') {
    //         this.$router.push('/')
    //       }
    //     }
    //   },
    //   immediate: true
    // },
    'forgotPasswordModel.password': {
      handler: function() {
        let num = share.checkStrong(this.forgotPasswordModel.password)
        this.complexity = num
      },
    },
  },
  mounted() {
    let local = location.href
    let code = share.getUrlCode(local, 'code')
    if (code && code !== '' && code !== 'null') {
      let stateUrl = share.getUrlCode(local, 'state')
      let state = localStorage.getItem('wx_login_state')
      if (stateUrl && state && (stateUrl.toString() === state.toString())) {
        let newUserType = localStorage.getItem('wx_login_user_type')
        this.newUserType = newUserType
        this.wxCode = code
        this.toH5WeChatLogin()
      }else {
        share.message.error('微信授权登录失效')
      }
    }
    localStorage.removeItem('wx_login_state')
    localStorage.removeItem('wx_login_user_type')

    if (this.temporaryMyChatUserId) {
      let aonuoChat = this.$store.state.aonuoChat
      aonuoChat.logout()
      this.setTemporaryMyChatUserId({
        temporaryMyChatUserId: ''
      })
    }
  },
  methods: {  /* 自定义手机号验证 */
    ...mapMutations(['setUserinfo', 'setToken', 'setMyChatUserId', 'setShareId', 'setShareArea', 'setInvitationCode', 'setTemporaryMyChatUserId', 'setChatSessionId', 'setChatSessionInfo', 'setChatSession']),

    repeatPassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (this.passwordModeFormNav === 1) {
        if (this.forgotPasswordModel.password !== value) {
          callback(new Error('两次密码输入不一致'))
        } else {
          callback()
        }

      } else {
        if (this.forgotPasswordModel.password !== value) {
          callback(new Error('两次密码输入不一致'))
        }else {
          callback()
        }
      }
    },

    getCode() {
      if (this.phoneLoginForm.phone === '') {
        share.message.warning('手机号不能为空')
        return
      }
      let ab = phoneRegular
      if (ab.test(this.phoneLoginForm.phone) === false) {
        share.message.warning('手机号格式错误')
        return
      }
      this.$refs.loginGetCode.getCode()
    },
    changeLoginMode() {
      if (this.loginMode === 1) {
        this.loginMode = 0
      } else {
        this.loginMode = 1
      }
    },
    toWxLogin() {
      let timestamp = new Date().getTime()
      let num = Math.round(Math.random() * 10)
      let state = timestamp + '' + num

      localStorage.setItem('wx_login_state', state)
      localStorage.setItem('wx_login_user_type', this.newUserType)
      let redirect_uri = WEB_BASE_URL + 'login'
      window.location.href = 'https://open.weixin.qq.com/connect/qrconnect?appid=wxfe42628884a0a90a&redirect_uri=' + encodeURIComponent(redirect_uri) + '&response_type=code&scope=snsapi_login&state=' + state
    },
    async toH5WeChatLogin() {
      this.loading = share.loadingShow({
        id: '#login_warp_id',
        text: '登录中',
        background: '#f4f2f3',
      })

      const res = await GetWechatAccessTokenApi({
        code: this.wxCode,
        platform: 'web',
      })

      if (res.data && res.data.access_token) {
        const res2 = await GetWechatUserInfoApi({
          accessToken: res.data.access_token,
          openId: res.data.openid
        })


        if (res2.data) {
          let info = {
            ...res2.data,
            nickName: res2.data.nickname,
            unionId: res2.data.unionid,
          }
          await this.toWechatLogin(info)
        }else {
          this.loading.close()
          share.message.error('获取微信用户信息失败')
        }

      }else {
        this.loading.close()
        share.message.error('获取登录微信授权失败')
      }
    },
    async toWechatLogin(userInfo) {

      let res = {}
      if (this.newUserType === 'hr') {
        res = await HrWechatLoginApi({
          unionId: userInfo.unionId || userInfo.unionid
        })
      }else {
        res = await EmployeeWechatLoginApi({
          unionId: userInfo.unionId || userInfo.unionid
        })
      }

      if (res) {
        if (res.message === 'error') {
          this.loading.close()
          await this.$router.push({
            path: '/additionalInformation',
            query: {
              userType: this.newUserType,
              info: userInfo
            }
          })
        }else {
          await this.setUserInfo(res)
          this.loading.close()
          share.message.success('登录成功')
          if (this.newUserType === 'hr') {
            await this.$router.push('/jobSeeker')
          }else {
            await this.$router.push('/')
          }
        }
      }else {
        this.loading.close()
        share.message.error('登录失败')
      }

    },
    userTypeChange(str) {
      this.newUserType = str
    },
    async formSubmit() {
      if (this.submitLoading) {
        return
      }
      let state = false
      if (this.loginMode === 1) {
        this.$refs.loginFormRef.validate(res => {
          if (res) {
            state = true
          }
        })
      }else {
        this.$refs.phoneLoginFormRef.validate(res => {
          if (res) {
            state = true
          }
        })
      }
      if (!state) {
        return
      }


      this.isLoggingIn = true
      this.submitLoading = true

      if (this.newUserType === 'employee') {
        let res = null
        if (this.loginMode === 1) {
          res = await EmployeeLoginApi({...this.loginForm})
        }else {
          res = await EmployeeLoginApi({...this.phoneLoginForm})
        }

        if (res) {

          await this.setUserInfo(res)

          this.submitLoading = false
          await this.$router.push('/')
          this.isLoggingIn = false
        }else {
          this.submitLoading = false
          this.isLoggingIn = false
        }

      } else if (this.newUserType === 'hr') {
        let res2 = null
        if (this.loginMode === 1) {
          res2 = await HrLoginApi({...this.loginForm})
        }else {
          res2 = await HrLoginApi({...this.phoneLoginForm})
        }

        if (res2) {
          await this.setUserInfo(res2)
          this.submitLoading = false
          await this.$router.push('/jobSeeker')
          this.isLoggingIn = false
        }else {
          this.submitLoading = false
          this.isLoggingIn = false
        }
      }
    },
    async setUserInfo(data) {

      this.setToken({
        token: data.userToken
      })
      localStorage.setItem('JXZP_User_Token', data.userToken)

      this.setChatSessionId({
        chatSessionId: ''
      })
      this.setChatSessionInfo({
        chatSessionInfo: null
      })
      this.setChatSession({
        chatSession: null
      })

      if (this.newUserType === 'employee'){

        let profilePhoto = share.initImageUrl(data.employeeInformation.avatar)
        this.setUserinfo({
          username: data.employeeInformation.name,
          gender: data.employeeInformation.gender,
          userType: data.employeeInformation.userType,
          profilePhoto: profilePhoto
        })

        if (data.areaCoinInformation) {
          this.setUserinfo({
            place: {
              value: data.areaCoinInformation.code,
              label: data.areaCoinInformation.area
            }
          })
        }

        this.setMyChatUserId({
          myChatUserId: data.employeeInformation.sessionId
        })
        await this.chatServiceLogin(data.employeeInformation.sessionId)

      }else {
        let profilePhoto = share.initImageUrl(data.hrInformation.avatar)
        this.setUserinfo({
          username: data.hrInformation.name,
          userType: data.hrInformation.userType,
          gender: data.hrInformation.gender,
          profilePhoto: profilePhoto
        })

        this.setMyChatUserId({
          myChatUserId: data.hrInformation.sessionId
        })

        await this.chatServiceLogin(data.hrInformation.sessionId)

      }

    },

    /**
     * 登录聊天服务
     * */
    async chatServiceLogin(userId) {
      let aonuoChat = this.$store.state.aonuoChat
      await aonuoChat.login(userId)
    },

    goRegister() {
      this.$router.push('/register')
    },

    goHomePage() {
      this.$router.push('/')
    },

    /**
    * 去协议详情页
    * */
    toRulesPage(param) {
      window.open(ALL_RULES + '?type=' + param)
    },

    forgotPassword() {
      this.forgotPasswordModelIsShow = true
    },
    forgotPasswordModelGetCode() {
      this.$refs.forgotPasswordModelPhoneFormGetCodeRef.getCode()
    },
    forgotPasswordModelClose() {
      this.$refs.forgotPasswordModelRef.resetFields()
      this.forgotPasswordModelIsShow = false
      this.forgotPasswordModel.password = ''
      this.forgotPasswordModel.msgCode = ''
      this.forgotPasswordModel.phone = ''
      this.forgotPasswordModel.repeatPassword = ''
    },
    forgotPasswordModelSubmit() {
      this.$refs.forgotPasswordModelRef.validate(async res => {
        if (res) {
          this.forgotPasswordModelSubmitLoading = true

          let res = null
          if (this.newUserType === 'employee') {
            res = await PostEmployeeForgetPasswordApi({
              phone: this.forgotPasswordModel.phone,
              msgCode: this.forgotPasswordModel.msgCode,
              password: this.forgotPasswordModel.password,
            })
          }else {
            res = await PostHrForgetPasswordApi({
              phone: this.forgotPasswordModel.phone,
              msgCode: this.forgotPasswordModel.msgCode,
              password: this.forgotPasswordModel.password,
            })
          }
          if (res) {
            share.message.success('密码重置成功')
          }

          this.forgotPasswordModelSubmitLoading = false
          this.forgotPasswordModelClose()
        }
      })
    }

  }
}
</script>

<style lang="less" scoped>
.login_warp {
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  min-width: 1400px;
  min-height: 700px;

  .header {
    width: 100%;
    height: 61px;
  }

  .content {
    width: 100%;
    height: 510px;
    background-color: #e1201a;
    display: flex;
    justify-content: center;
    align-items: center;

    .main {
      width: 1000px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .main-left {
        width: 510px;
        height: 510px;
        margin-right: 100px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .main-right {
        margin-top: 50px;
        width: 350px;
        height: 420px;
        background-color: #f3f3f3;
        padding: 0 20px;
        box-sizing: border-box;
        position: relative;
        box-shadow: 10px 10px 10px #a91e18;

        .logo {
          width: 250px;
          position: absolute;
          top: -64px;
          left: -5px;
          z-index: 999;

          img {
            width: 100%;
            max-width: 100%;
            cursor: pointer;
          }
        }

        .main-right-header {
          height: 100px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          .tab-btn {
            cursor: pointer;
            width: 145px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ffffff;
            margin: 10px 0px;
            border: 1px solid #d1d1d1;
            font-weight: bold;
            border-radius: 5px;
            color: #d1d1d1;
            letter-spacing: 2px;
          }

          .tab-btn-checked {
            color: #e20000;
            border: 1px solid #e20000;
          }

        }

        .main-right-content {
          width: 100%;
          height: 130px;

          .form-item-code {

            /* 第3个表单项样式 */

            &:nth-child(2) /deep/ .el-input .el-input__inner {
              padding-right: 150px !important;
            }

            /* 第3个表单项右侧清楚图标位置样式 */

            &:nth-child(2) /deep/ .el-input .el-input__suffix {
              right: 125px !important;
            }

            /* 表单获取验证码按钮样式 */

            .get-code {
              width: 120px;
              height: 30px;
              position: absolute;
              right: 0px;
              top: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 999;
              border-left: 1px solid #DCDFE6;
              cursor: pointer;
              color: #e20000;
            }

          }

          .form-item {
            position: relative;

            & /deep/ .el-input__prefix {
              display: flex;
              align-items: center;
            }

            .icon {
              width: 20px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 100%;
                height: 100%;
              }
            }

          }
        }

        .main-right-footer {
          width: 100%;
          height: calc(100% - 230px);

          .main-right-footer-item {
            height: 30px;
            width: 100%;
            margin-bottom: 5px;
            display: flex;

            &:first-child {
              height: 20px;
            }

            &:last-child {
              height: 20px;
            }

            & /deep/ .el-button {
              width: 100%;
            }

            span {
              cursor: pointer;
            }

            .text {
              color: #8c8c8c;

              span {
                color: #e20000;
                text-decoration: underline;
              }
            }

            .text2 {
              text-decoration: underline;
              color: #8c8c8c;
            }

            > span {
              text-decoration: #e20000;
            }
          }

          .switch-login-mode {
            justify-content: space-between;
            align-items: flex-start;
            font-size: 14px;
          }

          .login-submit {
            width: 100%;
            height: 40px;
            & /deep/ .el-button {
              color: #ffffff;
              font-size: 16px;
              font-weight: bold;
              background-color: #e20000;
              border: 1px solid #e20000;
            }
          }

          .login-register {
            width: 100%;
            height: 40px;
            & /deep/ .el-button {
              color: #8c8c8c;

              &:hover {
                background-color: transparent !important;
              }
            }
          }

          .agreement {
            height: 30px;
            width: 100%;
            margin-top: -10px;
            color: #8c8c8c;
            font-size: 14px;
            span {
              color: #e20000;
              cursor: pointer;
            }
          }

        }

      }

    }
  }

  .footer {
    width: 1000px;
    margin: 0 auto;
    height: calc(100% - 480px - 61px);

    > .footer-title {
      font-size: 30px;
      height: 60px;
      line-height: 70px;
      color: #e20000;
      font-weight: bold;
      letter-spacing: 2px;
      text-align: center;
    }

    .footer-content {
      display: flex;
      flex-wrap: wrap;

      .footer-content-item {
        width: 311px;
        height: 140px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 20px 10px;
        border: 1px solid #e1e1e1;
        box-shadow: 5px 5px 8px #dddddd;

        .icon {
          width: 140px;
          height: 140px;
          padding: 20px;
          box-sizing: border-box;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .text {
          flex: 1;
          height: 100%;
          padding: 0px 20px;
          box-sizing: border-box;

          .title {
            height: 60px;
            width: 100%;
            line-height: 60px;
            font-size: 20px;
            color: #a9a9a9;
            font-weight: bold;
            //letter-spacing: 1px;
          }

          .msg {
            font-size: 12px;
            color: #a9a9a9;
          }

        }

      }
    }

  }

}
</style>

<style lang="less">
.forgotPassword_dialog {

  .dialog-content {
    width: 100%;

    .steps {
      width: 400px;
      margin: 0 auto;
    }

    .main {
      margin-top: 30px;

      .el-form {

        .form-item {
          display: flex;
          position: relative;

          .msg {
            position: absolute;
            color: #eba825;
            top: -20px;
            left: 80px;
            font-size: 14px;
          }

          .el-form-item {
            flex: 1;
          }

          .desc {
            width: 150px;
            display: flex;
            justify-content: center;
            padding-top: 16px;
            box-sizing: border-box;
            padding-left: 10px;

            >div {
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin: 0 3px;
              color: #a8a8a8;
              font-size: 14px;

              .top {
                width: 100%;
                height: 3px;
                background-color: #fdfdfd;
                border: 1px solid #e7e7e7;
                border-radius: 5px;
              }
              .top-checked1 {
                background-color: #F56C6C;
              }
              .top-checked2 {
                background-color: #E6A23C;
              }
              .top-checked3 {
                background-color: #67C23A;
              }

            }

          }

          .get-code {
            width: 150px;
            display: flex;
            justify-content: center;
            padding-bottom: 22px;

            >.btn {
              width: 100%;
              margin-left: 5px;
              background-color: #ffffff;
              border: 1px solid #bfbfbf;
            }

          }

        }

      }

    }

  }

  .el-dialog__footer {
    text-align: center;
  }

}
</style>
