<template>
  <div class="enterpriseDynamic_warp">

    <div class="left">

      <div class="header-tab">

        <div class="header-tab-left">
          <el-tabs v-model="currNavPage" @tab-click="currNavPageChange">
            <el-tab-pane label="看过我" name="1"></el-tab-pane>
            <el-tab-pane label="我看过" name="2"></el-tab-pane>
            <el-tab-pane label="对我感兴趣" name="3"></el-tab-pane>
            <el-tab-pane label="沟通过" name="7"></el-tab-pane>
            <el-tab-pane label="面试邀请" name="4"></el-tab-pane>
            <el-tab-pane label="收藏" name="5"></el-tab-pane>
            <el-tab-pane label="红包申请" name="6"></el-tab-pane>
          </el-tabs>
        </div>

        <div class="header-tab-right" v-if="currNavPage !== '6'">
<!--          <el-select v-model="jobFilter" placeholder="请选择" size="mini" @change="jobFilterChange">-->
<!--            <el-option-->
<!--                v-for="(item, index) in jobValueOptions"-->
<!--                :key="'jobValueOptions' + index"-->
<!--                :label="item.label"-->
<!--                :value="item.value">-->
<!--            </el-option>-->
<!--          </el-select>-->
          <lazy-select :options="jobValueOptions" placeholder="请选择" size="mini" :new-value="jobFilter" :no-more="jobOptionsNoMore" @loadData="loadMoreMyPublishedJob" @change="jobFilterChange"></lazy-select>
        </div>

        <div class="header-tab-right" v-if="currNavPage === '6'">
<!--          <el-select v-model="jobFilter2" placeholder="请选择" size="mini" @change="jobFilterChange2">-->
<!--            <el-option-->
<!--                v-for="(item, index) in jobValueOptions2"-->
<!--                :key="'jobValueOptions2' + index"-->
<!--                :label="item.label"-->
<!--                :value="item.value">-->
<!--            </el-option>-->
<!--          </el-select>-->
          <el-select v-model="jobFilter3" placeholder="请选择" size="mini" @change="jobFilterChange3">
            <el-option
                v-for="(item, index) in jobValueOptions3"
                :key="'jobValueOptions3' + index"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <lazy-select :options="jobValueOptions" placeholder="请选择" size="mini" :new-value="jobFilter" :no-more="jobOptionsNoMore" @loadData="loadMoreMyPublishedJob" @change="jobFilterChange"></lazy-select>
        </div>

      </div>

      <div class="content" id="enterprise-dynamic-tab6" v-loading="dynamicLoading">

        <div class="dynamic-job-seeker-item" v-if="currNavPage !== '4'" v-for="(item, index) in jobSeekerList"
             :key="'dynamic-job-seeker-item' + index">
          <job-seeker-item
              :academic-level="item.employeeInformation.academicLevel"
              :self-introduction="item.employeeInformation.selfIntroduction"
              :expected-salary-range="item.employeeInformation.expectedSalaryRange"
              :employee-id="item.employeeInformation.employeeId"
              :gender="item.employeeInformation.gender"
              :last-login-time="item.createTime"
              :job-searching-status="item.employeeInformation.jobSearchingStatus"
              :age="item.employeeInformation.age"
              :name="item.employeeInformation.name"
              :avatar="item.employeeInformation.avatar"
              :expected-job-address-list="item.employeeInformation.expectedJobAddressList"
              :expected-job-list="item.employeeInformation.expectedJobList"
              :personal-high-light-list="item.employeeInformation.personalHighLightList"
              :is-red-envelope-application="isRedEnvelopeApplication"
              :red-envelope-process-status="item.redPocketStatus"
              :record-id="item.record_id"
              :remain-days="item.remainDays"
              :job-id="item.jobInformation.id"
              :job-name="item.jobInformation.name"
              :city="item.jobInformation.city"
              :school-name="item.employeeInformation.school"
              :is-first-communication="!item.employeeInformation.communicateFlag"
              :curr-nav-page="currNavPage"
              :status-is-show="true"
              :is-show-communicated-status="false"
              @clickLeft="goJobSeekerDetailed"
              @clickRight="toChatWith"
              @agreeApply="agreeApply"
              @rejectApply="rejectApply"
              @terminate="handleTerminate"
          >
          </job-seeker-item>
        </div>

        <template v-if="currNavPage === '4'">
          <div :class="index < jobSeekerList.length - 1?'dynamic-job-seeker-item2 bottom-border':'dynamic-job-seeker-item2'" v-for="(item, index) in jobSeekerList"
               :key="'dynamic-job-seeker2' + index">
            <div class="interview-item-warp" v-if="item && item.employeeInformation">
              <div class="top">
                <div class="title">
                  面试人：<span @click="goJobSeekerDetailed(item.employeeInformation.employeeId, '', '', item.jobInformation.id, item.jobInformation.name)">{{item.employeeInformation.name}}<i class="el-icon-arrow-right"></i></span>
                </div>
                <div class="time">
                  面试时间：{{item.interviewTime}}
                </div>
                <div class="place">
                  面试地点：{{item.interviewAddress}}
                </div>
              </div>
              <div class="bottom">
                <div class="bottom-left">
                  面试的岗位：{{item.jobInformation.city}} · {{item.jobInformation.name}}
                </div>
                <div class="bottom-right">
                  {{item.createTime}}
                </div>
              </div>
            </div>
          </div>
        </template>

        <div v-if="jobSeekerList && jobSeekerList.length > 0" class="pagination">
          <el-pagination
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
        </div>

        <div class="jon-list-none" v-if="isLobListNone">
          <img src="@/assets/icon/empty.png">
          <div class="text">
            数据为空，去<span @click="toJobPromotion">去推广</span>引起更多求职者的兴趣吧
          </div>
        </div>

      </div>

    </div>

    <div class="right">

      <dynamic-entry @goEnterpriseDynamic="changeCurrNavPage"></dynamic-entry>

    </div>

    <el-dialog
        title="提示"
        :visible.sync="redPocketCollisionVisible"
        custom-class="red-pocket-collision-visible-dialog"
        width="600px">
      <div class="redPocketCollision_warp">对方已有红包流程在进行中，是否确认继续</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="redPocketCollisionVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="redPocketCollisionSubmit">确 认</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="terminateDialog"
        width="300px"
    >
      <div style="text-align: center;font-size: 16px; color: #333333;">确定要中断该红包吗？中断后剩余的红包金额会返还</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="terminateDialog = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="terminate">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="rejectTerminateDialog"
        width="300px"
    >
      <div style="text-align: center;font-size: 16px; color: #333333;">确定拒绝吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectTerminateDialog = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="rejectTerminate">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="agreeTerminateDialog"
        width="300px"
    >
      <div style="text-align: center;font-size: 16px; color: #333333;">确定要同意该红包申请吗？同意之后红包流程就会开始</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="agreeTerminateDialog = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="agreeTerminate">确 定</el-button>
      </span>
    </el-dialog>

    <buy-additional-communicate-time ref="buyAdditionalCommunicateTimeRef"
                                     :job-id="buyAdditionalCommunicateTimeJobId"
                                     :employee-id="buyAdditionalCommunicateTimeEmployeeId"
                                     @success="purchasedSuccessfully"
    ></buy-additional-communicate-time>

  </div>
</template>

<script>

import DynamicJobSeekerItem from "@/components/dynamicJobSeekerItem/dynamicJobSeekerItem";

let defaultProfilePhoto = require('@/assets/icon/personal-avatar.png')
import share from '@/share/share'
import DynamicEntry from "@/components/dynamicEntry/dynamicEntry";
import {
  MyPublishedJobApi,
  GetHrSeeMeRecordApi,
  GetHrSeeEmployeeRecordApi,
  CommunicatedEmployeeRecordApi,
  GetHrCollectMeRecordApi,
  GetHrCollectEmployeeRecordApi,
  GetHandleRedPocketApplyApi,
  ParamTerminateRedPocketApi,
  GetGiveInterviewInvitationRecordApi,
  GetHrRedPocketApplyRecordApi, JobHrSeeJobDetailApi,
} from "@/request/api";
import JobSeekerItem from "@/components/jobSeekerItem/jobSeekerItem";
import {mapMutations} from "vuex";
import BuyAdditionalCommunicateTime from "@/components/buyAdditionalCommunicateTime/buyAdditionalCommunicateTime";
import LazySelect from "@/components/lazySelect/lazySelect";

export default {
  name: "enterpriseDynamic",
  components: {LazySelect, BuyAdditionalCommunicateTime, JobSeekerItem, DynamicJobSeekerItem, DynamicEntry},
  data() {
    return {
      currNavPage: '',
      btnLoading: false,
      jobFilter: '',
      jobFilter2: '',
      jobFilter3: '',
      profilePhoto: '',
      username: '',
      total: 0,
      currentPage: 1,
      pageSize: 5,
      isRedEnvelopeApplication: false,
      jobSeekerList: [],
      dynamicLoading: false,
      isLobListNone: false,
      jobOptionsPage: {
        pageNumber: 1,
        pageSize: 20,
      },
      jobOptionsNoMore: true,
      jobValueOptions: [],
      jobValueOptions2: [],
      jobValueOptions3: [
        {
          label: '全部状态',
          value: ''
        },
        {
          label: '待确认',
          value: 1
        },
        {
          label: '已拒绝',
          value: 2
        },
        {
          label: '进行中',
          value: 3
        },
        {
          label: '已中断',
          value: 4
        },
        {
          label: '已领完',
          value: 5
        },
        {
          label: '红包冲突',
          value: 6
        }
      ],
      jobName: '',
      redPocketCollisionVisible: false,
      newRecordId: '',
      terminateDialog: false,
      terminateRecordId: '',
      rejectTerminateDialog: false,
      rejectTerminateRecordId: '',
      agreeTerminateDialog: false,
      agreeTerminateRecordId: '',

      buyAdditionalCommunicateTimeJobId: '',
      buyAdditionalCommunicateTimeJobName: '',
      buyAdditionalCommunicateTimeSessionId: '',
      buyAdditionalCommunicateTimeEmployeeId: '',

      isFirst: true,
      isFirstCommunication: '',
      jobSeekerItemCallBack: null,

      currentPositionInformationDetails: null,  // 当前hr代表的的岗位信息
    }
  },
  computed: {
    hrCurrentRepresentative() {
      return this.$store.state.hrCurrentRepresentative
    },
  },
  async mounted() {
    // let loading = share.loadingShow({
    //   id: '#enterprise-dynamic-tab6',
    // })
    // loading.close()
    let index = this.$route.query.index || ''
    let jobFilter = this.$route.query.jobFilter || ''

    let currNavPage = sessionStorage.getItem('currNavPage')
    if (index && index !== '' && index !== 'null' && index !== null) {
      this.currNavPage = index
    } else {
      this.currNavPage = currNavPage || '1'
    }
    sessionStorage.setItem('currNavPage', this.currNavPage)

    let currentPage = sessionStorage.getItem('currentPage')
    if (currentPage) {
      this.currentPage = parseInt(currentPage) || 1
    }
    sessionStorage.setItem('currentPage', this.currentPage)

    await this.loadMyPublishedJob()
    // if (this.currNavPage !== '6') {
      if (this.jobValueOptions.length > 0) {
        if (jobFilter && jobFilter !== '') {
          this.jobFilter = parseInt(jobFilter)
          this.jobValueOptions.forEach((item, index) => {
            if (item.value === this.jobFilter) {
              this.jobName = item.label
            }
          })
        }else {
          this.jobFilter = this.jobValueOptions[0].value
          this.jobName = this.jobValueOptions[0].label
        }
      } else {
        this.jobFilter = ''
      }
    // }

    await this.loadJobSeekerListData()
  },
  watch: {
    currNavPage: {
      handler:function () {
        if (this.currNavPage === '6') {
          this.isRedEnvelopeApplication = true
        }else {
          this.isRedEnvelopeApplication = false
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations(['setCurrentSessionId']),
    initJobFilter2() {
      if (this.jobValueOptions2.length > 0) {
        if (this.jobFilter2 !== '') {
          this.jobValueOptions2.forEach((item, index) => {
            if (item.value === this.jobFilter2) {
              this.jobName = item.label
            }
          })
        }else {
          this.jobFilter2 = this.jobValueOptions2[0].value
          this.jobName = this.jobValueOptions2[0].label
        }
      } else {
        this.jobFilter2 = ''
      }
    },
    async loadMyPublishedJob() {
      const res = await MyPublishedJobApi({
        pageNum: this.jobOptionsPage.pageNumber,
        pageSize: this.jobOptionsPage.pageSize,
        jobStatus: 3,
      })

      if (res) {
        if (this.jobOptionsPage.pageNumber >= res.numOfPages) {
          this.jobOptionsNoMore = true
        }else {
          this.jobOptionsNoMore = false
        }
        let newArr = [
          {
            label: '全部岗位',
            value: ''
          }
        ]
        let arr = res.jobList
        arr.forEach((item, index) => {
          let obj = {
            label: item.jobName,
            value: item.jobId
          }
          newArr.push(obj)
        })
        this.jobValueOptions = newArr

        if (this.hrCurrentRepresentative.value) {
          const res = await JobHrSeeJobDetailApi({
            jobId: this.hrCurrentRepresentative.value,
          })
          if (res && res.jobInformation && !res.jobInformation.isClose && res.jobInformation.jobStatus === 3) {
            let salary = ''
            if (res.jobInformation.salaryRange.length > 0) {
              salary = parseInt(res.jobInformation.salaryRange[0]) / 1000 + 'k-' + parseInt(res.jobInformation.salaryRange[1]) / 1000 + 'k'
            } else {
              salary = '面议'
            }
            let address = res.jobInformation.jobAddress[1] + res.jobInformation.jobAddress[2]
            let obj = {
              jobName: res.jobInformation.jobName,
              salary: salary,
              address: address,
            }
            this.currentPositionInformationDetails = obj
          }
        }
      }
      this.jobOptionsPage.pageNumber++
    },
    async loadMoreMyPublishedJob(callback) {
      if (this.jobOptionsNoMore) {
        return;
      }
      const res = await MyPublishedJobApi({
        pageNum: this.jobOptionsPage.pageNumber,
        pageSize: this.jobOptionsPage.pageSize,
        jobStatus: 3,
      })
      if (res) {
        if (this.jobOptionsPage.pageNumber >= res.numOfPages) {
          this.jobOptionsNoMore = true
        }else {
          this.jobOptionsNoMore = false
        }
        let newArr = []
        let arr = res.jobList
        arr.forEach((item, index) => {
          let obj = {
            label: item.jobName,
            value: item.jobId
          }
          newArr.push(obj)
        })
        this.jobValueOptions = this.jobValueOptions.concat(newArr)
      }
      this.jobOptionsPage.pageNumber++
      callback()
    },
    async loadJobSeekerListData() {
      this.dynamicLoading = true
      this.jobSeekerList = []
      let res = []
      if (this.currNavPage === '1') {
        res = await GetHrSeeMeRecordApi({
          jobId: this.jobFilter,
          pageNum: this.currentPage,
          pageSize: this.pageSize
        })
      }
      if (this.currNavPage === '2') {
        res = await GetHrSeeEmployeeRecordApi({
          jobId: this.jobFilter,
          pageNum: this.currentPage,
          pageSize: this.pageSize
        })
      }
      if (this.currNavPage === '3') {
        res = await GetHrCollectMeRecordApi({
          jobId: this.jobFilter,
          pageNum: this.currentPage,
          pageSize: this.pageSize
        })
      }
      // if (this.currNavPage === '4') {
      //   res = await CommunicatedEmployeeRecordApi({
      //     jobId: this.jobFilter,
      //     pageNum: this.currentPage,
      //     pageSize: this.pageSize
      //   })
      // }
      if (this.currNavPage === '4') {
        res = await GetGiveInterviewInvitationRecordApi({
          jobId: this.jobFilter,
          finished: '',
          pageNum: this.currentPage,
          pageSize: this.pageSize
        })
      }
      if (this.currNavPage === '5') {
        res = await GetHrCollectEmployeeRecordApi({
          jobId: this.jobFilter,
          pageNum: this.currentPage,
          pageSize: this.pageSize
        })
      }
      if (this.currNavPage === '6') {
        res = await GetHrRedPocketApplyRecordApi({
          jobId: this.jobFilter,
          applyStatus: this.jobFilter3,
          allRecord: '',
          pageNum: this.currentPage,
          pageSize: this.pageSize
        })
      }
      if (this.currNavPage === '7') {
        res = await CommunicatedEmployeeRecordApi({
          jobId: this.jobFilter,
          pageNum: this.currentPage,
          pageSize: this.pageSize
        })
      }

      if (res) {
        this.total = res.numOfRecords
        this.jobSeekerList = res.recordList

        if (res.recordList.length <= 0) {
          this.isLobListNone = true
        } else {
          this.isLobListNone = false
        }
      }else {
        if ((!res || res.recordList.length <= 0) && this.isFirst) {
          this.currentPage = 1
          sessionStorage.setItem('currentPage', this.currentPage)
          this.isFirst = false
          await this.loadJobSeekerListData()
        }
      }
      this.isFirst = false
      this.dynamicLoading = false
    },
    currNavPageChange() {
      this.currentPage = 1
      sessionStorage.setItem('currNavPage', this.currNavPage)
      sessionStorage.setItem('currentPage', this.currentPage)
      if (this.currNavPage === '6') {
        this.initJobFilter2()
      }

      this.loadJobSeekerListData()
    },
    jobFilterChange(value) {
      this.jobFilter = value
      this.jobValueOptions.forEach((item, index) => {
        if (item.value === this.jobFilter) {
          this.jobName = item.label
        }
      })
      this.currentPage = 1
      sessionStorage.setItem('currentPage', this.currentPage)
      this.loadJobSeekerListData()
    },
    jobFilterChange2() {
      this.jobValueOptions2.forEach((item, index) => {
        if (item.value === this.jobFilter2) {
          this.jobName = item.label
        }
      })
      this.currentPage = 1
      sessionStorage.setItem('currentPage', this.currentPage)
      this.loadJobSeekerListData()
    },
    jobFilterChange3() {
      // this.jobValueOptions3.forEach((item, index) => {
      //   if (item.value === this.jobFilter2) {
      //     this.jobName = item.label
      //   }
      // })
      this.currentPage = 1
      sessionStorage.setItem('currentPage', this.currentPage)
      this.loadJobSeekerListData()
    },
    changeCurrNavPage(key, index) {
      if (index) {
        this.currNavPage = index
      } else {
        this.$router.push({
          path: key
        })
      }

      sessionStorage.setItem('currNavPage', this.currNavPage)

      if (this.currNavPage === '6') {
        this.initJobFilter2()
      }
      this.loadJobSeekerListData()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      sessionStorage.setItem('currentPage', this.currentPage)
      this.loadJobSeekerListData()
    },
    goJobSeekerDetailed(employeeId, schoolName, recordId, jobId, jobName) {
      if (this.currNavPage === '6') {
        this.$router.push({
          path: '/applicationProcess',
          query: {
            id: employeeId,
            recordId: recordId,
          }
        })
      }else {
        this.$router.push({
          path: '/resumeDetails',
          query: {
            id: employeeId,
            jobId: jobId,
            jobName: jobName,
          }
        })
      }

    },
    toChatWith(toSessionId, jobId, jobName, employeeId, isFirstCommunication, coverWithMosaics, callBack) {
      this.buyAdditionalCommunicateTimeJobId = jobId
      this.buyAdditionalCommunicateTimeJobName = jobName
      this.buyAdditionalCommunicateTimeSessionId = toSessionId
      this.buyAdditionalCommunicateTimeEmployeeId = employeeId
      this.isFirstCommunication = isFirstCommunication || coverWithMosaics
      /**
       * 将列表项中的回调函数地址传给变量
       * */
      this.jobSeekerItemCallBack = callBack
      this.$nextTick(() => {
        this.$refs.buyAdditionalCommunicateTimeRef.init()
      })
    },
    /**
     * 主动沟通购买成功
     * */
    purchasedSuccessfully() {
      let obj = {
        jobId: this.buyAdditionalCommunicateTimeJobId,
        jobName: this.buyAdditionalCommunicateTimeJobName,
      }
      let notTaken = this.isFirstCommunication
      this.jobSeekerItemCallBack()
      share.toChatWindow(this.buyAdditionalCommunicateTimeSessionId, obj, '/chatSectionHomeHr', notTaken, this.currentPositionInformationDetails)
      this.$emit('changeHeaderMenu', 2)
    },
    async agreeApply(employeeId, recordId) {
      this.agreeTerminateRecordId = recordId
      this.agreeTerminateDialog = true
    },
    async rejectApply(employeeId, recordId) {
      this.rejectTerminateRecordId = recordId
      this.rejectTerminateDialog = true
    },
    handleTerminate(employeeId, recordId) {
      this.terminateRecordId = recordId
      this.terminateDialog = true
    },
    async terminate() {
      this.btnLoading = true
      const res = await ParamTerminateRedPocketApi({
        recordId: this.terminateRecordId,
      })

      this.btnLoading = false
      this.terminateDialog = false
      if (res) {
        share.message.success('操作成功')
        await this.loadJobSeekerListData()
      }
    },
    async rejectTerminate() {
      this.btnLoading = true
      const res = await GetHandleRedPocketApplyApi({
        recordId: this.rejectTerminateRecordId,
        action: 'reject'
      })

      this.btnLoading = false
      this.rejectTerminateDialog = false
      if (res) {
        share.message.success('操作成功')
        await this.loadJobSeekerListData()
      }
    },
    async agreeTerminate() {
      this.btnLoading = true
      this.newRecordId = this.agreeTerminateRecordId
      const res = await GetHandleRedPocketApplyApi({
        recordId: this.agreeTerminateRecordId,
        action: 'confirm'
      })

      this.btnLoading = false
      this.agreeTerminateDialog = false
      if (res.message !== 'warning') {
        share.message.success('操作成功')
        await this.loadJobSeekerListData()
      }else {
        this.redPocketCollisionVisible = true
      }
    },
    toJobPromotion() {
      // this.$router.push({
      //   path: '/postJob'
      // })
      this.$router.push('/jobPromotion')
    },
    async redPocketCollisionSubmit() {
      this.btnLoading = true
      const res = await GetHandleRedPocketApplyApi({
        recordId: this.newRecordId,
        action: 'stillConfirm'
      })

      this.btnLoading = false
      this.agreeTerminateDialog = false
      this.redPocketCollisionVisible = false
      if (res) {
        share.message.success('已同意，等待求职者同意')
        await this.loadJobSeekerListData()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.enterpriseDynamic_warp {
  width: 100%;
  height: 100%;
  background-color: #f4f2f3;
  display: flex;
  justify-content: space-between;

  .left {
    width: 780px;
    height: 100%;
    background-color: #ffffff;

    .header-tab {
      height: 40px;
      width: 100%;
      background-color: #ffffff;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .header-tab-left {
        width: 500px;

        & /deep/ .el-tabs {
          margin-left: 10px;
          position: relative;
          top: 2px;

          .el-tabs__header {
            margin-bottom: 0px;
          }

          .el-tabs__active-bar {
            height: 3px;
            background-color: #e20000;
          }

          .el-tabs__item {
            padding: 0 10px;
          }

          .el-tabs__item.is-active {
            color: #e20000;
          }

          .el-tabs__nav-wrap::after {
            background: none;
          }

        }

      }

      .header-tab-right {
        height: 100%;
        width: 300px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 20px;
        box-sizing: border-box;

        .lazySelect_warp {
          width: 120px;
          margin-left: 20px;
        }

        .el-select {
          width: 120px;
        }

        & /deep/ .el-input__inner {
          border: 1px solid #e20000;
        }

      }

    }

    .content {
      margin-top: 3px;
      width: 100%;
      padding: 10px 20px 70px 20px;
      box-sizing: border-box;
      position: relative;

      .dynamic-job-seeker-item {
        width: 100%;
        height: 140px;
        background-color: #f6f6f6;
        border-bottom: 1px solid #e1e1e1;
        margin: 10px 0;
      }

      .bottom-border {
        border-bottom: 10px solid #F2F2F2;
      }
      .dynamic-job-seeker-item2 {
        width: 100%;
        height: 150px;

        .interview-item-warp {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;

          .top {
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            .title {
              font-size: 20px;
              margin-bottom: 10px;
              color: #303030;
              cursor: pointer;
              i {
                color: #409eff;
              }
            }
            .time {
              font-size: 16px;
              color: #303030;
              margin-bottom: 10px;
            }
            .place {
              font-size: 16px;
              color: #303030;
            }
          }
          .bottom {
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            color: #bdbdbd;
            border-top: 1px solid #F2F2F2;
            .bottom-left {
              flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .bottom-right {
              margin-left: 20px;
            }
          }
        }
      }

      .pagination {
        width: 100%;
        height: 60px;
        position: absolute;
        bottom: 0px;
        left: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .jon-list-none {
        width: 100%;
        height: 410px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 100px;
          height: 100px;
          margin-bottom: 10px;
        }

        .text {
          font-size: 16px;
          color: #cdcdcd;

          span {
            color: #e20000;
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }

    }

  }

  .right {
    flex: 1;
    //min-height: 600px;
    margin-left: 10px;
    background-color: #f4f2f3;

  }

}
</style>
