<template>
  <div class="chatPage_warp">

    <div class="header">
      <page-header :curr-page="currHeaderMenu" @changeCurrPage="changeCurrPage" backgroundColor="#eeeeee"></page-header>
    </div>

    <div class="content">
      <router-view @changeHeaderMenu="changeHeaderMenu"></router-view>
    </div>

  </div>
</template>

<script>
import PageHeader from "@/components/pageHeader/pageHeader";
import {GetConflictRedPocketApplyApi, ConfirmConflictRedPocketApi, RejectConflictRedPocketApi} from '@/request/api'
import share from "@/share/share";
export default {
  name: "chatPage",
  components: {PageHeader},
  data() {
    return {
      currHeaderMenu: 4,
      conflictRedPocketVisible: false,
    }
  },
  computed: {
    userType() {
      return this.$store.state.userInfo.userType || ''
    },
    isLogin() {
      let state = ''
      let token = this.$store.state.token || ''
      if (token && token !== '' && token !== 'null') {
        state = true
      }else {
        state = false
      }
      return state
    }
  },
  mounted() {
  },
  methods: {
    changeCurrPage(num) {
      this.currHeaderMenu = num
    },
    changeHeaderMenu(num) {
      this.currHeaderMenu = num
    }
  }
}
</script>

<style lang="less" scoped>
.chatPage_warp {
  width: 100%;
  height: 100%;
  background-color: #f4f2f3;
  min-width: 1400px;
  //min-height: 700px;

  .header {
    width: 100%;
    height: 61px;
  }

  .content {
    padding-top: 30px;
    padding-bottom: 30px;
    box-sizing: border-box;
    width: 1000px;
    margin: 0 auto;
    height: calc(100% - 61px);
    background-color: #f4f2f3;
  }

}
</style>
