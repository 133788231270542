<template>
  <div class="jobSeekerCardItem_warp" @click="goJobDetails">
    <div class="name">
      {{employeeName}}
    </div>
    <div class="education">
      {{academicLevel}}
    </div>
    <div class="bottom">
      <div class="jobName">
        <span>
          {{expectedCategoryList}}
        </span>
      </div>

    </div>
    <div class="bottom">
      <div class="place">
        <span>{{expectedSalary}}</span>
      </div>
      <div class="place">
        <span>{{expectedJobAddressList}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "jobSeekerCardItem",
  props: {
    employeeName: {  // 岗位名
      type: String,
      default: ''
    },
    academicLevel: {  // 学历
      type: String,
      default: ''
    },
    expectedCategoryList: {  // 期望岗位
      type: String,
      default: ''
    },
    expectedSalaryRange: {  // 期望工资范围
      type: Array,
      default: () => []
    },
    expectedJobAddressList: {  // 期望工作地
      type: String,
      default: ''
    },
    employeeId: {
      type: [String, Number],
      default: ''
    },
    schoolName: {
      type: String,
      default: ''
    }
  },
  computed: {
    expectedSalary() {
      let str = ''
      if (this.expectedSalaryRange && this.expectedSalaryRange.length > 0) {
        // str = this.expectedSalaryRange[0] + '-' + this.expectedSalaryRange[1]
        str = parseInt(this.expectedSalaryRange[0])/1000 + 'k-' + parseInt(this.expectedSalaryRange[1])/1000 + 'k'
      }else {
        str = '面议'
      }
      return str
    }
  },
  methods: {
    goJobDetails() {
      this.$emit('goJobDetails', this.employeeId, this.schoolName)
    }
  }
}
</script>

<style lang="less" scoped>
.jobSeekerCardItem_warp {
  width: 100%;
  height: 100%;
  cursor: pointer;

  .name {
    color: #e72727;
    font-size: 16px;
    font-weight: bold;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .education {
    height: 30px;
    line-height: 30px;
    font-size: 10px;
    color: #454545;
  }

  .bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 10px;

    .jobName {
      color: #e72727;

      span {
        display: inline-block;
        padding: 0 5px;
        height: 13px;
        line-height: 13px;
        border-right: 1px solid #e72727;

        &:first-child {
          padding-left: 0px;
        }

        &:last-child {
          padding-right: 0px;
          border-right: 0px;
        }
      }
    }

    .place {
      color: #7f7f7f;
    }

  }

}
</style>
