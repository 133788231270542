<template>
  <div class="jobCardItem_warp" :style="disable?'':'opacity: 0.5;'" @click="goJobDetailed">
    <div class="name">
      {{jobName}}
    </div>
    <div class="company">
      {{enterpriseName}}
    </div>
    <div class="bottom">
      <div class="salary">
        {{salary}}
      </div>
      <div class="place" v-if="jobAddress">
<!--          {{jobAddress[0]}}·{{jobAddress[1]}}·{{jobAddress[2]}}-->
        {{jobAddress[2]}}
      </div>
    </div>
  </div>
</template>

<script>
import share from "@/share/share";

export default {
  name: "jobCardItem",
  props: {
    jobName: {
      type: String,
      default: '',
    },
    jobStatus: {
      type: [String, Number],
      default: 3
    },
    isClose: {
      type: Boolean,
      default: false,
    },
    jobAddress: {
      type: Array,
      default: () => []
    },
    salaryRange: {
      type: Array,
      default: () => []
    },
    enterpriseName: {
      type: String,
      default: '',
    },
    jobId: {
      type: [String, Number],
      default: 0
    },
    enterpriseStatus: {
      type: [String, Number],
      default: 1
    }
  },
  computed: {
    salary() {
      let str = ''
      if (this.salaryRange && this.salaryRange.length > 0) {
        // str = this.salaryRange[0] + '-' + this.salaryRange[1]
        str = parseInt(this.salaryRange[0])/1000 + 'k-' + parseInt(this.salaryRange[1])/1000 + 'k'
      }else {
        str = '面议'
      }
      return str
    },
    disable() {
      if (this.jobStatus === 3 && this.isClose === false && this.enterpriseStatus === 1) {
        return true
      }else {
        return false
      }
    }
  },
  methods: {
    goJobDetailed() {
      if (this.enterpriseStatus !== 1) {
        share.message.warning('该岗位的企业信息异常')
        return
      }

      this.$emit('goJobDetailed', this.jobId)
    }
  }
}
</script>

<style lang="less" scoped>
.jobCardItem_warp {
  width: 100%;
  height: 100%;
  cursor: pointer;

  .name {
    color: #e72727;
    width: 200px;
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }

  .company {
    height: 30px;
    width: 200px;
    line-height: 30px;
    font-size: 10px;
    color: #454545;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }

  .bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 10px;

    .salary {
      color: #e72727;
    }

    .place {
      color: #7f7f7f;
    }

  }

}
</style>
