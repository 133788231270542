<template>
  <div class="dynamicJobItem_warp">

    <div class="left" :style="disable?'':'opacity: 0.5;'">

      <div class="avatar">
        <el-avatar size="large" :src="newAvatarUrl"></el-avatar>
        <div class="name">
          {{hrName}}
        </div>
<!--        <el-button v-if="isCommunicated" size="mini" @click="goCommunication">继续沟通</el-button>-->
        <el-button size="mini" @click.stop="goCommunication">开始沟通</el-button>
      </div>

    </div>

    <div class="right">
      <el-row :gutter="20">
        <el-col :span="10" :style="disable?'':'opacity: 0.5;'">
          <div class="right-content" @click="clickLeft">
            <div class="top">
              {{jobName}}
            </div>
            <div class="bottom">
              <div class="bottom-item">
                {{salary}}
              </div>
              <div class="bottom-item">
                {{academicRequirement}}
              </div>
              <div class="bottom-item">
                {{newExperienceRequirementRange}}
              </div>
            </div>
            <div class="bottom">
              <template v-if="jobWelfareList && jobWelfareList.length > 0">
                <span v-if="index < 5" v-for="(item, index) in jobWelfareList" :key="'jobWelfareList' + index">
                  {{item}}<span v-if="index < jobWelfareList.length - 1 && index < 4">，</span>
                </span>
              </template>
            </div>
          </div>
        </el-col>
        <el-col :span="8" :style="disable?'':'opacity: 0.5;'">
          <div class="right-content" @click="clickRight">
            <div class="top">
              <div class="top-item" v-if="enterpriseAddress">
                <div class="enterpriseName">{{enterpriseName}}</div>
<!--                <div>[{{enterpriseAddress[0]}} · {{enterpriseAddress[1]}} · {{enterpriseAddress[2]}}]</div>-->
                <div v-if="enterpriseAddress[2]">[{{enterpriseAddress[2]}}]</div>
              </div>
            </div>
<!--            <div class="bottom">-->
<!--              不需要融资-->
<!--            </div>-->
<!--            <div class="bottom">-->
<!--              {{salaryRange[0]}}-{{salaryRange[1]}}人-->
<!--            </div>-->
          </div>
        </el-col>
        <el-col :span="6">
          <div class="right-content" @click="clickRight" v-if="!redEnvelopeApplication" :style="disable?'':'opacity: 0.5;'">
            <div class="icon">
              <img :src="newEnterpriseLogoUrl">
              <div class="red-envelopes">
                <red-envelopes v-if="jobType === 1" :number="amount"></red-envelopes>
              </div>
            </div>
          </div>
          <div class="right-content" @click="toRedEnvelopeApplicationPage" v-if="redEnvelopeApplication">
            <div class="btn-item" v-if="redEnvelopeProcessStatus === 1">
              <div class="redEnvelopeProcessStatus1">
                <div>待确认</div>
                <div>(点击查看详情)</div>
              </div>
            </div>
            <div class="btn-item" v-if="redEnvelopeProcessStatus === 2">
              <div class="redEnvelopeProcessStatus2">
                <div>已拒绝</div>
                <div>(点击查看详情)</div>
              </div>
            </div>
            <div class="btn-item" v-if="redEnvelopeProcessStatus === 3">
              <div class="redEnvelopeProcessStatus3">
                <div>进行中</div>
                <div>(点击查看详情)</div>
              </div>
            </div>
            <div class="btn-item" v-if="redEnvelopeProcessStatus === 4">
              <div class="redEnvelopeProcessStatus4">
                <div>已中断</div>
                <div>(点击查看详情)</div>
              </div>
            </div>
            <div class="btn-item" v-if="redEnvelopeProcessStatus === 5">
              <div class="redEnvelopeProcessStatus5">
                <div>已领完</div>
                <div>(点击查看详情)</div>
              </div>
            </div>
            <div class="btn-item" v-if="redEnvelopeProcessStatus === 6">
              <div class="redEnvelopeProcessStatus3">
                <div>红包冲突</div>
                <div>(点击查看详情)</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
import RedEnvelopes from "@/components/redEnvelopes/redEnvelopes";
import {GetMyCommunicationInfoApi} from "@/request/api";
import {JobSeekerGreetings} from "@/share/greetings";
import {experience} from "@/share/shareData";
import share from "@/share/share";
export default {
  name: "dynamicJobItem",
  components: {RedEnvelopes},
  props: {
    hrName: {
      type: String,
      default: '',
    },
    jobName: {
      type: String,
      default: '',
    },
    jobStatus: {
      type: [String, Number],
      default: 3
    },
    isClose: {
      type: Boolean,
      default: false,
    },
    academicRequirement: {
      type: String,
      default: '',
    },
    enterpriseLogo: {
      type: String,
      default: '',
    },
    enterpriseName: {
      type: String,
      default: '',
    },
    experienceRequirementRange: {
      type: Array,
      default: () => []
    },
    salaryRange: {
      type: Array,
      default: () => []
    },
    jobWelfareList: {
      type: Array,
      default: () => []
    },
    hrAvatar: {
      type: String,
      default: '',
    },
    enterpriseAddress: {
      type: Array,
      default: () => []
    },
    jobId: {
      type: [String, Number],
      default: '',
    },
    hrId: {
      type: [String, Number],
      default: '',
    },
    hrGender: {
      type: String,
      default: '',
    },
    amount: {
      type: [Number, String],
      default: 0,
    },
    jobType: {
      type: Number,
      default: 0,
    },
    enterpriseId: {
      type: [Number, String],
      default: 0,
    },
    jobAddress: {
      type: Array,
      default: () => []
    },
    redEnvelopeApplication: {
      type: Boolean,
      default: false,
    },
    redEnvelopeProcessStatus: {
      type: [Number, String],
      default: 1,
    },
    recordId: {
      type: [Number, String],
      default: '',
    },
    enterpriseStatus: {
      type: [String, Number],
      default: 1
    },
    isRedPacketJob: {
      type: Boolean,
      default: true
    },
  },
  data () {
    return {
    }
  },
  computed: {
    userType() {
      return this.$store.state.userInfo.userType || ''
    },
    defAvatarUrl() {
      if (this.userType === 'hr') {
        return 'https://cdn.jiaxinzp.com/jxzp/hr_avatar_male.png'
      }else {
        return 'https://cdn.jiaxinzp.com/jxzp/employee_avatar_male.png'
      }
    },
    newAvatarUrl() {
      if (this.hrAvatar) {
        return share.initImageUrl(this.hrAvatar)
      }else {
        return this.defAvatarUrl
      }
    },
    newEnterpriseLogoUrl() {
      if (this.enterpriseLogo) {
        return share.initImageUrl(this.enterpriseLogo)
      } else {
        return this.enterpriseLogo
      }
    },
    newExperienceRequirementRange() {
      let str = ''
      if (this.experienceRequirementRange && this.experienceRequirementRange.length > 0) {
        let str2 = this.experienceRequirementRange[0] + '-' + this.experienceRequirementRange[1]

        experience.forEach((item, index) => {
          if (item.value === str2) {
            str = item.label + '经验'
          }
        })
      }else {
        str = '经验不限'
      }
      return str
    },
    salary() {
      let str = ''
      if (this.salaryRange && this.salaryRange.length > 0) {
        // str = this.salaryRange[0] + '-' + this.salaryRange[1]
        str = parseInt(this.salaryRange[0])/1000 + 'k-' + parseInt(this.salaryRange[1])/1000 + 'k'
      }else {
        str = '面议'
      }
      return str
    },
    isLogin() {
      let token = this.$store.state.token
      let state = false
      if (token && token !== '' && token !== null && token !== 'null') {
        state = true
      }
      return state
    },
    disable() {
      if (this.isRedPacketJob) {
        return true
      }else {
        if (this.jobStatus === 3 && this.isClose === false  && this.enterpriseStatus === 1) {
          return true
        }else {
          return false
        }
      }
    }
  },
  mounted() {
  },
  methods: {

    goCommunication() {
      if (this.enterpriseStatus !== 1) {
        if (this.isRedPacketJob) {
          share.message.warning('该企业信息异常，但不影响红包领取，可正常查看红包流程')
        }else {
          share.message.warning('该岗位的企业信息异常')
        }
        return
      }

      let toSessionId = 'hr-' + this.hrId

      this.$emit('goCommunication', toSessionId)
    },
    clickLeft() {
      if (this.enterpriseStatus !== 1) {
        if (this.isRedPacketJob) {
          share.message.warning('该企业信息异常，但不影响红包领取，可正常查看红包流程')
        }else {
          share.message.warning('该岗位的企业信息异常')
        }
        return
      }
      this.$emit('clickLeft', this.jobId)
    },
    clickRight() {
      if (this.enterpriseStatus !== 1) {
        if (this.isRedPacketJob) {
          share.message.warning('该企业信息异常，但不影响红包领取，可正常查看红包流程')
        }else {
          share.message.warning('该岗位的企业信息异常')
        }
        return
      }
      this.$emit('clickRight', this.enterpriseId)
    },
    toRedEnvelopeApplicationPage() {
      // if (this.jobStatus !== 3 || this.isClose !== false) {
      //   share.message.warning('该岗位已关闭或已删除')
      //   return
      // }
      this.$emit('toRedEnvelopeApplicationPage', this.enterpriseId, this.recordId, this.jobId)
    }
  }
}
</script>

<style lang="less" scoped>
.dynamicJobItem_warp {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    height: 100%;
    width: 80px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .avatar {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #303030;

      .el-avatar--large {
        width: 50px;
        height: 50px;
        margin-bottom: 5px;
      }

      .el-button {
        border: 1px solid #e20000;
        color: #e20000;
      }

    }

    .name {
      text-align: center;
      margin-bottom: 5px;
      font-size: 16px;
      width: 100px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }

  }

  .right {
    flex: 1;
    //height: 90px;
    background-color: #fafbfd;

    .right-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      font-size: 12px;
      padding: 10px 10px;
      box-sizing: border-box;
      cursor: pointer;

      .enterpriseName {
        width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      >div {
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .top {
        font-size: 14px;
        color: #303030;

        .top-item:last-child {
          font-size: 12px;
          color: #838485;
          margin-left: 20px;
          >div:first-child {
            margin-bottom: 5px;
          }
        }

      }

      .bottom {
        display: flex;
        color: #838485;
        margin-top: 5px;
        flex-wrap: wrap;

        .bottom-item {
          height: 12px;
          line-height: 12px;
          padding: 0 5px;
          border-right: 1px solid #838485;
          &:first-child {
            padding-left: 0px;
          }
          &:last-child {
            border-right: 0px;
          }
        }

      }

      .icon {
        width: 70px;
        height: 70px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }

        .red-envelopes {
          width: 60px;
          height: 60px;
          position: absolute;
          right: -20px;
          top: -20px;
          transform: rotate(-30deg)
        }

      }

      .btn-item {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 20px;
        box-sizing: border-box;

        >div{
          padding: 5px 10px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .redEnvelopeProcessStatus1 {
          background-color: #d2d2d2;
          color: #676767;
          border: 1px solid #a0a0a0;
        }

        .redEnvelopeProcessStatus2 {
          background-color: #d2d2d2;
          color: #676767;
          border: 1px solid #a0a0a0;
        }

        .redEnvelopeProcessStatus3 {
          color: #e20000;
          border: 1px solid #e20000;
        }

        .redEnvelopeProcessStatus4, .redEnvelopeProcessStatus5 {
          background-color: #d2d2d2;
          color: #676767;
          border: 1px solid #a0a0a0;
        }

      }

    }

  }

}
</style>
