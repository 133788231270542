import { get, post } from './http'

/**
 * 获取验证码
 */
export const SendMsgCodeApi = param => post('/utils/send-msg-code/', param)

/**
 * 发送邮箱验证码
 */
export const SendEmailCodeApi = param => post('/utils/send-email-code/', param)

/**
 * 求职者注册
 */
export const EmployeeRegisterApi = param => post('/login-and-register/employee-register/', param)

/**
 * hr注册
 */
export const HrRegisterApi = param => post('/login-and-register/hr-register/', param)

/**
 * 求职者登录
 */
export const EmployeeLoginApi = param => post('/login-and-register/employee-login/', param)

/**
 * hr登录
 */
export const HrLoginApi = param => post('/login-and-register/hr-login/', param)

/**
 * 根据token获取头像
 */
export const GetEmployeeAvatarApi = (obj) => get('/employee/employee-avatar/', obj)

/**
 * 根据token修改头像
 */
export const UpDataEmployeeAvatarApi = (param) => post('/employee/employee-avatar/', param)

/**
 * 根据token登录
 */
export const LoginWithUserTokenApi = param => post('/token/login-with-user-token/', param)

/**
 * 获取指定地区的积分和资金总额
 */
export const GetAreaCoinApi = obj => get('/system-information/get-coin-fund/', obj)

/**
 * 获取岗位列表
 */
export const SearchJobApi = param => post('/job/search-job/', param)

/**
 * 获取求职者查看过的岗位列表
 */
export const SeeJobRecordApi = obj => get('/employee/see-job-record/', obj)

/**
 * 获取求职者的简历详情
 */
export const GetResumeEditApi = obj => get('/employee/resume-edit/', obj)

/**
 * 修改求职者的简历详情
 */
export const UpDataResumeEditApi = param => post('/employee/resume-edit/', param)

/**
 * 获取求职者的简历卡片数据
 */
export const PostEmployeeSendPersonalCardApi = param => post('/employee/send-personal-card/', param)

/**
 * 求职者获取当前区域自己的积分和财富值
 */
export const GetWealthApi = obj => get('/employee/get-wealth/', obj)

/**
 * 求职者财富提现
 */
export const WealthWithdrawalApi = param => post('/employee/wealth-withdrawal/', param)

/**
 * 求职者请求领取到财富
 */
export const PostWealthApi = param => post('/employee/get-wealth/', param)

/**
 * 获取求职者领取积分到财富记录
 */
export const GetWealthRecordApi = obj => get('/employee/get-wealth-record/', obj)

/**
 * 获取求职者财富提现记录
 */
export const GetWealthWithdrawalRecordApi = obj => get('/employee/get-wealth-withdrawal-record/', obj)

/**
 * 获取当前积分单价
 */
export const GetCoinUnitPriceApi = obj => get('/system-information/get-coin-unit-price/', obj)

/**
 * 获取岗位详情
 */
export const JobDetailApi = obj => get('/job/job-detail/', obj)

/**
 * 获取企业详情
 */
export const GetEnterpriseDetailApi = obj => get('/enterprise/enterprise-detail/', obj)

/**
 * 修改企业信息
 */
export const PostEnterpriseDetailApi = param => post('/enterprise/enterprise-detail/', param)

/**
 * 添加/删除求职者收藏岗位
 */
export const CollectJobRecordApi = param => post('/employee/collect-job-record/', param)

/**
 * 删除求职者收藏岗位
 */
export const CancelJobCollectByJobIdApi = param => post('/employee/cancel-job-collect-by-job-id/', param)


/**
 * 获取指定企业下的所有岗位列表
 */
export const GetAllJobListApi = obj => get('/enterprise/get-all-job-list/', obj)

/**
 * 获取指定企业下的所有普通岗位列表
 */
export const GetNormalJobListApi = obj => get('/enterprise/get-normal-job-list/', obj)

/**
 * 获取指定企业下的所有红包岗位列表
 */
export const GetRedPocketJobListApi = obj => get('/enterprise/get-red-pocket-job-list/', obj)

/**
 * 获取求职者的状态统计数据
 */
export const StatusStatisticsApi = obj => get('/employee/status-statistics/', obj)

/**
 * 获取求职者获取积分记录列表
 */
export const GetCoinRecordApi = obj => get('/employee/get-coin-record/', obj)

/**
 * 获取求职者的看过我记录
 */
export const SeeMeRecordApi = obj => get('/employee/see-me-record/', obj)

/**
 * 获取求职者沟通过岗位记录
 */
export const CommunicatedJobRecordApi = obj => get('/employee/communicated-job-record/', obj)

/**
 * 获取收藏了我的企业信息列表
 */
export const CollectMeRecordApi = obj => get('/employee/collect-me-record/', obj)

/**
 * 获取求职者收藏岗位信息列表
 */
export const GetCollectJobRecordApi = obj => get('/employee/collect-job-record/', obj)

/**
 * 获取求职者获取面试邀请记录
 */
export const GetInterviewInvitationRecordApi = obj => get('/employee/get-interview-invitation-record/', obj)

/**
 * 获取当前手机号
 */
export const GetChangeLoginPhoneApi = obj => get('/employee/change-login-phone/', obj)

/**
 * 获取账户管理页面信息
 */
export const AccountManagementApi = obj => get('/employee/account-management/', obj)

/**
 * 求职者修改密码
 */
export const ChangePasswordApi = param => post('/employee/change-password/', param)

/**
 * HR修改密码
 */
export const HrChangePasswordApi = param => post('/hr/change-password/', param)

/**
 * 求职者修改手机号
 */
export const PostChangeLoginPhoneApi = param => post('/employee/change-login-phone/', param)

/**
 * 求职者修改绑定邮箱
 */
export const ChangeEmailApi = param => post('/employee/change-email/', param)

/**
 * 获取当前可用的业务地区列表
 */
export const GetAreaListApi = obj => get('/system-information/get-area-list/', obj)

/**
 * 获取hr头像
 */
export const GetHrAvatarApi = obj => get('/hr/hr-avatar/', obj)

/**
 * 修改hr头像
 */
export const PostHrAvatarApi = param => post('/hr/hr-avatar/', param)

/**
 * 获取我发布的所有岗位列表
 */
export const MyPublishedJobApi = obj => get('/hr/my-published-job/', obj)

/**
 * 获取hr看过的求职者记录列表
 */
export const SeeEmployeeRecordApi = obj => get('/hr/see-employee-record/', obj)

/**
 * 查找求职者统一接口
 */
export const SearchEmployeeApi = param => post('/employee/search-employee/', param)

/**
 * 获取hr状态
 */
export const HrStatusApi = obj => get('/hr/hr-status/', obj)

/**
 * 搜索企业
 */
export const SearchEntepriseApi = obj => get('/enterprise/search-enteprise/', obj)

/**
 * 获取hr的状态统计数据
 */
export const HrStatusStatisticsApi = obj => get('/hr/status-statistics/', obj)

/**
 * 修改企业信息
 */
export const EditInformationApi = param => post('/enterprise/edit-information/', param)

/**
 * 获取企业信息
 */
export const GetEditInformationApi = obj => get('/enterprise/edit-information/', obj)

/**
 * 修改企业图片
 */
export const EnterprisePhotoApi = param => post('/enterprise/enterprise-photo/', param)

/**
 * 修改企业视频
 */
export const EnterpriseVideoApi = param => post('/enterprise/enterprise-video/', param)

/**
 * 获取我发布的红包岗位列表
 */
export const MyPublishedRedPocketJobApi = obj => get('/hr/my-published-red-pocket-job/', obj)

/**
 * hr申请加入指定企业
 */
export const JoinEnterpriseApi = param => post('/hr/join-enterprise/', param)

/**
 * 修改企业Logo
 */
export const EnterpriseLogoApi = param => post('/enterprise/enterprise-logo/', param)

/**
 * 企业注册
 */
export const EnterpriseRegisterApi = param => post('/login-and-register/enterprise-register/', param)

/**
 * 判断该企业是否通过审核
 */
export const EnterpriseIsPassCheckApi = obj => get('/enterprise/is-pass-check/', obj)

/**
 * 实名认证
 */
export const RealNameAuthenticationApi = param => post('/employee/real-name-authentication/', param)

/**
 * 获取指定岗位下沟通过的求职者记录列表
 */
export const CommunicatedEmployeeRecordApi = obj => get('/hr/communicated-employee-record/', obj)

/**
 * 获取指定岗位下看过我的求职者记录列表
 */
export const GetHrSeeMeRecordApi = obj => get('/hr/see-me-record/', obj)

/**
 * 获取指定岗位下收藏过我的求职者记录列表
 */
export const GetHrCollectMeRecordApi = obj => get('/hr/collect-me-record/', obj)

/**
 * 获取指定岗位下收藏过我的求职者记录列表
 */
export const GetHrSeeEmployeeRecordApi = obj => get('/hr/see-employee-record/', obj)

/**
 * 获取指定岗位下我收藏过的求职者记录列表
 */
export const GetHrCollectEmployeeRecordApi = obj => get('/hr/collect-employee-record/', obj)

/**
 * 获取指定红包岗位申请记录
 */
export const GetHrRedPocketApplyRecordApi = obj => get('/hr/red-pocket-apply-record/', obj)

/**
 * 获取求职者详细信息
 */
export const GetHrEmployeeDetailApi = obj => get('/employee/employee-detail/', obj)

/**
 * 增加/删除收藏求职者
 */
export const HrAddOrDeleteEmployeeRecordApi = param => post('/hr/collect-employee-record/', param)

/**
 * 获取我发布的普通岗位列表
 */
export const HrMyPublishedNormalJobApi = obj => get('/hr/my-published-normal-job/', obj)

/**
 * 获取我发布的红包岗位列表
 */
export const HrMyPublishedRedPocketJobApi = obj => get('/hr/my-published-red-pocket-job/', obj)

/**
 * 获取岗位详情（hr专用）
 */
export const JobHrSeeJobDetailApi = obj => get('/job/hr-see-job-detail/', obj)

/**
 * 获取要修改的红包岗位信息
 */
export const GetJobEditRedPocketJobApi = obj => get('/job/edit-red-pocket-job/', obj)

/**
 * 修改该红包岗位信息
 */
export const PostJobEditRedPocketJobApi = param => post('/job/edit-red-pocket-job/', param)

/**
 * 获取要修改的普通岗位信息
 */
export const GetJobEditNormalJobApi = obj => get('/job/edit-normal-job/', obj)

/**
 * 修改该普通岗位信息
 */
export const PostJobEditNormalJobApi = param => post('/job/edit-normal-job/', param)

/**
 * 获取申请加入企业记录列表
 */
export const GetHrCheckJoinEnterpriseApplyApi = obj => get('/hr/check-join-enterprise-apply/', obj)

/**
 * 审核hr加入企业申请
 */
export const PostHrCheckJoinEnterpriseApplyApi = param => post('/hr/check-join-enterprise-apply/', param)

/**
 * 获取招聘数据-周报-本周数据
 */
export const GetHrRecruitmentStatisticsWeekDataApi = obj => get('/hr/recruitment-statistics-week-data/', obj)

/**
 * 获取招聘数据-日数据
 */
export const GetHrRecruitmentStatisticsDayDataApi = obj => get('/hr/recruitment-statistics-day-data/', obj)

/**
 * 获取hr当前个人信息
 */
export const GetHrChangeSelfInformationApi = obj => get('/hr/change-self-information/', obj)

/**
 * 修改hr个人信息
 */
export const PostHrChangeSelfInformationApi = param => post('/hr/change-self-information/', param)

/**
 * 修改hr个人手机号
 */
export const PostHrChangePhoneApi = param => post('/hr/change-phone/', param)

/**
 * 获取招聘数据-周报-近期趋势
 */
export const GetHrRecruitmentStatisticsRecentTrendApi = obj => get('/hr/recruitment-statistics-recent-trend/', obj)

/**
 * 获取招聘数据-月报
 */
export const GetHrRecruitmentStatisticsMonthDataApi = obj => get('/hr/recruitment-statistics-month-data/', obj)

/**
 * 获取未付款的红包岗位信息
 */
export const GetJobGetUnpaidRedPocketJobInfoApi = obj => get('/job/get-unpaid-red-pocket-job-info/', obj)

/**
 * 提交支付宝支付订单数据
 */
export const GetAlipayGenerateOrderApi = obj => get('/alipay/generate-order/', obj)

/**
 * 提交举报
 */
export const PostSubmitReportApi = param => post('/system-information/submit-report/', param)

/**
 * hr通过求职者id取消收藏的求职者
 */
export const PostHrCancelEmployeeCollectByEmployeeIdApi = param => post('/hr/cancel-employee-collect-by-employee-id/', param)

/**
 * 开启岗位
 */
export const OpenJobApi = param => post('/job/open-job/', param)

/**
 * 关闭岗位
 */
export const CloseJobApi = param => post('/job/close-job/', param)

/**
 * 处理红包申请
 */
export const GetHandleRedPocketApplyApi = param => post('/hr/handle-red-pocket-apply/', param)

/**
 * 获取指定岗位下发布面试邀请的求职者记录列表
 */
export const GetGiveInterviewInvitationRecordApi = obj => get('/hr/give-interview-invitation-record/', obj)

/**
 * 中断红包申请
 */
export const ParamTerminateRedPocketApi = param => post('/hr/terminate-red-pocket/', param)

/**
 * 获取指定红包岗位红包申请详情
 */
export const GetRedPocketApplyDetailApi = obj => get('/hr/red-pocket-apply-detail/', obj)

/**
 * 获取可开票订单记录
 */
export const GetCanInvoicingOrderApi = obj => get('/order/get-can-invoicing-order/', obj)

/**
 * 获取开票记录
 */
export const GetInvoicedRecordApi = obj => get('/order/get-invoiced-record/', obj)

/**
 * 获取用户信息
 */
export const GetUserInfoApi = obj => get('/utils/get-user-info/', obj)

/**
 * 获取hr的账户余额
 */
export const GetHrGetHrMoneyApi = obj => get('/hr/get-hr-money/', obj)

/**
 * 获取充值/消费记录
 */
export const GetMoneyChangeRecordApi = obj => get('/hr/get-money-change-record/', obj)

/**
 * hr余额提现
 */
export const PostHrMoneyWithdrawalApi = param => post('/hr/money-withdrawal/', param)

/**
 * 提交支付宝支付订单数据
 */
export const AlipayGenerateOrderApi = param => post('/alipay/generate-order/', param)

/**
 * hr获取求职者在聊天页面的个人信息
 */
export const GetEmployeeCommunicationInfoApi = obj => get('/employee/get-employee-communication-info/', obj)

/**
 * 求职者获取求职者在聊天页面的个人信息
 */
export const GetMyCommunicationInfoApi = obj => get('/employee/get-my-communication-info/', obj)

/**
 * hr查看自己在聊天页面的信息
 */
export const HrGetMyCommunicationInfoApi = obj => get('/hr/get-my-communication-info/', obj)

/**
 * 获取hr信息
 */
export const HrGetHrInformationApi = obj => get('/hr/get-hr-information/', obj)

/**
 * 求职者申请岗位红包
 */
export const EmployeeApplyRedPocketApi = param => post('/employee/apply-red-pocket/', param)

/**
 * 判断hr是否可以沟通
 */
export const ProductCanHrCommunicateApi = obj => get('/product/can-hr-communicate/', obj)

/**
 * 购买额外的沟通次数
 */
export const PostProductBuyAdditionalCommunicateTimeApi = param => post('/product/buy-additional-communicate-time/', param)

/**
 * 获取主动沟通加油包套餐模板列表
 */
export const GetActiveCommunicationTemplateListApi = obj => get('/product/get-active-communication-template-list/', obj)

/**
 * 获取时长包套餐模板列表
 */
export const GetDurationPackageTemplateListApi = obj => get('/product/get-duration-package-template-list/', obj)

/**
 * 获取Hr会员套餐模板列表
 */
export const GetProductGetHrVipTemplateListApi = obj => get('/product/get-hr-vip-template-list/', obj)

/**
 * 获取岗位推广套餐模板列表
 */
export const GetJobPromotionTemplateListApi = obj => get('/product/get-job-promotion-template-list/', obj)

/**
 * 获取红包岗位红包申请记录列表
 */
export const GetRedPocketApplyRecordApi = obj => get('/employee/get-red-pocket-apply-record/', obj)

/**
 * 求职者查看红包申请详情
 */
export const GetEmployeeSeeRedPocketApplyDetailApi = obj => get('/employee/employee-see-red-pocket-apply-detail/', obj)

/**
 * 普通岗位升级为红包岗位
 */
export const UpdateRedPocketJobApi = param => post('/job/update-red-pocket-job/', param)

/**
 * 购买主动沟通套餐
 */
export const BuyActiveCommunicationApi = param => post('/product/buy-active-communication/', param)

/**
 * 购买岗位推广
 */
export const ProductBuyJobPromotionApi = param => post('/product/buy-job-promotion/', param)

/**
 * 判断求职者是否注册后编辑过简历
 */
export const isEditedResumeApi = obj => get('/employee/is-edited-resume/', obj)

/**
 * 获取冲突的红包申请
 */
export const GetConflictRedPocketApplyApi = obj => get('/employee/get-conflict-red-pocket-apply/', obj)

/**
 * 求职者确认接收冲突红包
 */
export const ConfirmConflictRedPocketApi = param => post('/employee/confirm-conflict-red-pocket/', param)

/**
 * 求职者拒绝接受冲突红包
 */
export const RejectConflictRedPocketApi = param => post('/employee/reject-conflict-red-pocket/', param)

/**
 * 给求职者发送面试邀请
 */
export const GiveInterviewInvitationApi = param => post('/hr/give-interview-invitation/', param)

/**
 * 获取个人亮点选项列表
 */
export const PostPersonalHighLightOptionListApi = param => post('/employee/get-personal-high-light-option-list/', param)

/**
 * hr微信登录
 */
export const HrWechatLoginApi = param => post('/login-and-register/hr-wechat-login/', param)

/**
 * 求职者微信登录
 */
export const EmployeeWechatLoginApi = param => post('/login-and-register/employee-wechat-login/', param)

/**
 * hr微信注册
 */
export const HrWechatRegisterApi = param => post('/login-and-register/hr-wechat-register/', param)

/**
 * 求职者微信注册
 */
export const EmployeeWechatRegisterApi = param => post('/login-and-register/employee-wechat-register/', param)

/**
 * 求职者绑定微信
 */
export const EmployeeBindWechatApi = param => post('/employee/bind-wechat/', param)

/**
 * Hr绑定微信
 */
export const HrBindWechatApi = param => post('/hr/bind-wechat/', param)


/**
 * 获取微信的access_token
 */
export const GetWechatAccessTokenApi = obj => get('/utils/get-wechat-access-token/', obj)

/**
 * 获取微信个人信息
 */
export const GetWechatUserInfoApi = obj => get('/utils/get-wechat-user-info/', obj)

/**
 * 获取微信 Native 支付的二维码链接
 */
export const GetWechatPayNativeApi = obj => get('/wechat-pay/wechat-pay-native/', obj)

/**
 * 查询微信支付订单状态
 */
export const GetWechatPayQueryOrApi = param => post('/wechat-pay/query-order/', param)

/**
 * 获取岗位信息
 * */
export const GetJobEditJobApi = obj => get('/job/edit-job/', obj)

/**
 * 修改岗位基本信息
 * */
export const PostJobEditJobApi = param => post('/job/edit-job/', param)

/**
 * 删除岗位
 */
export const PostJobDeleteJobApi = param => post('/job/delete-job/', param)

/**
 * HR绑定银行卡号
 */
export const PostHrBindDankCardNumApi = param => post('/hr/bind-bank-card-num/', param)

/**
 * 求职者绑定银行卡号
 */
export const BindBankCardNumApi = param => post('/employee/bind-bank-card-num/', param)

/**
 * 求职者忘记密码
 * */
export const PostEmployeeForgetPasswordApi = param => post('/employee/forget-password/', param)

/**
 * Hr忘记密码
 * */
export const PostHrForgetPasswordApi = param => post('/hr/forget-password/', param)

/**
 * 获取邀请码
 * */
export const GetEmployeeGetInviteCodeApi = obj => get('/employee/get-invite-code/', obj)

/**
 * 记录求职者主动沟通hr
 * */
export const PostEmployeeContactHrApi = param => post('/employee/contact-hr/', param)

/**
 * 检查企业名字是否重复
 * */
export const GetEnterpriseCheckEnterpriseNameApi = obj => get('/enterprise/check-enterprise-name/', obj)

/**
 * 获取当前推广币余额
 * */
export const GetJobPromoteBuyPromoteCoinApi = obj => get('/job-promote/buy-promote-coin/', obj)

/**
 * 购买推广币
 * */
export const PostJobPromoteBuyPromoteCoinApi = param => post('/job-promote/buy-promote-coin/', param)

/**
 * 获取岗位推广列表
 * */
export const GetJobPromoteJobPromoteListApi = obj => get('/job-promote/job-promote-list/', obj)

/**
 * 新增岗位推广
 * */
export const PostJobPromoteAddJobPromoteApi = param => post('/job-promote/add-job-promote/', param)

/**
 * 获取岗位推广详细信息
 * */
export const GetJobPromoteJobPromoteDetailApi = obj => get('/job-promote/job-promote-detail/', obj)

/**
 * 编辑岗位推广
 * */
export const PostJobPromoteEditJobPromoteApi = param => post('/job-promote/edit-job-promote/', param)

/**
 * 开启岗位推广
 * */
export const PostJobPromoteOpenJobPromoteApi = param => post('/job-promote/open-job-promote/', param)

/**
 * 关闭岗位推广
 * */
export const PostJobPromoteCloseJobPromoteApi = param => post('/job-promote/close-job-promote/', param)

/**
 * 删除岗位推广
 * */
export const PostJobPromoteDeleteJobPromoteApi = param => post('/job-promote/delete-job-promote/', param)

/**
 * 推广数据统计
 * */
export const GetGobPromotePromoteStatisticsApi = obj => get('/job-promote/promote-statistics/', obj)

/**
 * 获取招呼语
 * */
export const GetHrGreetingMessageManagementApi = obj => get('/hr/greeting-message-management/', obj)

/**
 * 管理招呼语
 * */
export const PostHrGreetingMessageManagementApi = param => post('/hr/greeting-message-management/', param)

/**
 * 岗位推广操作记录
 * */
export const GetJobPromoteOperateHistoryListApi = obj => get('/job-promote/operate-history-list/', obj)

/**
 * 获取hr会员状态
 * */
export const GetHrGetHrVipStatusApi = obj => get('/hr/get-hr-vip-status/', obj)

/**
 * 发布岗位前的检查
 */
export const GetHrBeforePublishJobCheckApi = obj => get('/hr/before-publish-job-check/', obj)

/**
 * 购买Hr会员
 * */
export const PostProductBuyHrVipApi = param => post('/product/buy-hr-vip/', param)

/**
 * 发布、支付
 * */
export const PostJobPayJobApi = param => post('/job/pay-job/', param)

/**
 * hr解绑银行卡
 * */
export const PostHrUnbindBankCardNumApi = param => post('/hr/unbind-bank-card-num/', param)

/**
 * 求职者解绑银行卡
 * */
export const PostEmployeeUnbindBankCardNumApi = param => post('/employee/unbind-bank-card-num/', param)

/**
 * 获取岗位发布缓存
 */
export const GetHrJobPublishCacheApi = obj => get('/hr/job-publish-cache/', obj)

/**
 * 设置岗位发布缓存
 * */
export const PostHrJobPublishCacheApi = param => post('/hr/job-publish-cache/', param)

/**
 * 获取加入企业申请记录
 */
export const GetHrJoinEnterpriseApplyApi = obj => get('/hr/join-enterprise-apply/', obj)

/**
 * 撤销加入企业申请
 * */
export const PostHrJoinEnterpriseApplyApi = param => post('/hr/join-enterprise-apply/', param)

/**
 * 设置发票缓存
 * */
export const PostHrInvoiceCacheApi = param => post('/hr/invoice-cache/', param)

/**
 * 获取发票缓存
 */
export const GetHrInvoiceCacheApi = obj => get('/hr/invoice-cache/', obj)

/**
 * 申请开发票
 * */
export const PostOrderApplyInvoiceApi = param => post('/order/apply-invoice/', param)

/**
 * 获取热门地区列表
 */
export const GetRegionGetPopularRegionListApi = obj => get('/region/get-popular-region-list/', obj)

/**
 * 获取岗位类别信息列表
 */
export const GetJobGetJobCategoryListApi = obj => get('/job/get-job-category-list/', obj)

/**
 * 搜索岗位类别信息
 */
export const GetJobSearchJobCategoryApi = obj => get('/job/search-job-category/', obj)

/**
 * 检查短信验证码
 */
export const PostUtilsCheckMsgCodeApi = param => post('/utils/check-msg-code/', param)

/**
 * 检查邮箱是否可以使用
 */
export const PostUtilsCheckEmailCanUseApi = param => post('/utils/check-email-can-use/', param)

/**
 * 求职者投递简历调用的接口
 */
export const PostEmployeePostResumeApi = param => post('/employee/post-resume/', param)
