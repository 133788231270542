<template>
  <div class="hrData_warp" id="hrData_warp_id">

    <div class="left" v-loading="pageLoading">

      <div class="avatar">
        <div class="title">
          个人信息
        </div>
        <div class="main">
          <div class="main-title">
            修改头像
          </div>
          <div class="upload-btn" @click="uploadHeadPortrait">
            <img v-if="imgDataUrl"
                 :src="imgDataUrl">
            <div v-else>
              <i class="el-icon-plus"></i>
            </div>
          </div>

          <my-upload field="image"
                     :width="110"
                     :height="110"
                     img-format="jpg"
                     :url="uploadUrl"
                     :max-size="1024 * 20"
                     :compressed-size="1024 * 20"
                     :headers="headers"
                     :noCircle="false"
                     :modelValue.sync="show"
                     @src-file-set="srcFileSet"
                     @crop-success="cropSuccess"
                     @crop-upload-success="cropUploadSuccess"
                     @crop-upload-fail="cropUploadFail"
          ></my-upload>
        </div>

        <div class="information-main">
          <div class="form">
            <el-form ref="informationFormRef" :model="informationForm" :rules="formRules" label-width="80px">
              <el-form-item label="姓名" prop="name">
                <el-input v-model.trim="informationForm.name" clearable></el-input>
              </el-form-item>
              <el-form-item label="性别" prop="gender">
                <el-radio v-model="informationForm.gender" label="male">男</el-radio>
                <el-radio v-model="informationForm.gender" label="female">女</el-radio>
              </el-form-item>
              <el-form-item label="微信（仅用于聊天时快捷发送）"  label-width="240px">
                <el-input v-model.trim="informationForm.wechat" clearable></el-input>
              </el-form-item>
              <el-form-item label="">
                <div class="btn">
                  <el-button :loading="btnLoading" @click="informationFormSubmit">保 存</el-button>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>

      </div>

      <div class="set-greetings-card" v-loading="setGreetingsCardLoading">
        <div class="title">
          <span>沟通时自动发送发招呼语</span>
          <el-switch
              v-if="hrCurrentRepresentative && hrCurrentRepresentative.value"
              v-model="automaticGreeting" @change="automaticGreetingChange">
          </el-switch>
        </div>
        <div class="set-greetings" v-if="automaticGreeting">
          <el-tabs v-model="setGreetingsTabActiveName" @tab-click="setGreetingsTabActiveNameClick">
            <el-tab-pane label="通用" name="0">
              <div class="table">
                <div class="tabs">
                  <div :class="currentTableTab === 0?'tab tab-active':'tab'" @click="currentTableTab = 0">推荐</div>
                  <div :class="currentTableTab === 1?'tab tab-active':'tab'" @click="currentTableTab = 1">自定义</div>
                </div>
                <el-radio-group v-model="selectGreetings" @change="selectGreetingsChange">
                  <template v-if="currentTableTab === 0">
                    <div v-for="(item, index) in listOfRecommendedGreetings" class="row" v-if="index > 0" :key="'greeting' + index">
                      <el-radio :label="item.id">
                        <span class="message-span">{{item.message}}</span>
                      </el-radio>
                    </div>
                  </template>
                  <div class="row" v-if="currentTableTab === 1 && listOfRecommendedGreetings.length > 0">
                    <el-radio :label="listOfRecommendedGreetings[0].id">
                      <span class="message-span">{{listOfRecommendedGreetings[0].message}}</span>
                    </el-radio>
                  </div>
                </el-radio-group>

                <div class="editor-greetings-value" v-if="currentTableTab === 1">
                  <div class="input">
                    <el-input v-model="editorGreetingsValue" placeholder="请输入招呼语" maxlength="100" clearable></el-input>
                  </div>
                  <div class="btn">
                    <el-button @click="editorGreetingsValueSubmit">保存</el-button>
                  </div>
                </div>

              </div>

            </el-tab-pane>
            <el-tab-pane label="按岗位设置招呼语" name="1">

              <div class="cards">
                <div class="card" v-for="(item, index) in  listOfSpecialGreetings" :key="'greetings' + index">
                  <div class="job-name">
                    {{item.jobName}}：
                  </div>
                  <div class="value">
                    {{item.message}}
                  </div>
                  <div class="actions">
                    <div class="action" @click.stop="setGreetingsDialogShow('edit', item.id, item.jobName, item.message)">
                      <i class="el-icon-edit"></i>
                    </div>
                    <div class="action" @click.stop="deleteGreetings(item.id)">
                      <i class="el-icon-delete"></i>
                    </div>
                  </div>
                </div>
                <div class="btn" @click.stop="setGreetingsDialogShow('add')">
                  <div class="icon">
                    <i class="el-icon-circle-plus-outline"></i>
                  </div>
                  <div class="text">
                    添加打招呼语
                  </div>
                </div>
              </div>

            </el-tab-pane>
          </el-tabs>
        </div>
      </div>

      <div class="information">
        <div class="title">
          修改手机号
        </div>
        <div class="phone">
          <div class="phone-label">手机号</div> <el-input class="input" disabled v-model.trim="currPhone" clearable></el-input> <el-button @click="phoneModelIsShow = true">修 改</el-button>
        </div>
      </div>

      <div class="information">
        <div class="title">
          修改密码
        </div>

        <div class="phone">
          <div class="phone-label">密码</div> <el-input class="input" disabled v-model.trim="currPassword" clearable></el-input> <el-button @click="passwordModelIsShow = true">修 改</el-button>
        </div>

      </div>

      <div class="information">
        <div class="title">
          换绑微信（用于微信快捷登录）
        </div>

        <div class="phone">
          <div class="phone-label">昵称</div> <el-input class="input" disabled v-model.trim="informationForm.bindWechat" placeholder="空" clearable></el-input> <el-button @click="toBindWechat">修 改</el-button>
        </div>

      </div>

      <div class="information">
        <div class="title">
          绑定银行卡
        </div>

        <div class="phone">
          <div class="phone-label">银行卡号</div> <el-input class="input" disabled v-model.trim="cardNum" clearable></el-input> <el-button v-if="!bankCardNum" @click="bankCardModelIsShow = true">绑 定</el-button> <el-button v-if="bankCardNum" @click="toUnbindCardNum">解 绑</el-button>
        </div>

      </div>

    </div>

    <div class="right">
      <dynamic-entry @goEnterpriseDynamic="changeCurrNavPage"></dynamic-entry>
    </div>

    <!-- 绑定银行卡弹窗 -->
    <el-dialog
        title="绑定银行卡"
        :visible.sync="bankCardModelIsShow"
        width="600px"
        :before-close="bankCardModelClose"
        custom-class="accountManagement_dialog"
    >

      <div class="dialog-content">

        <div class="steps">
          <el-steps :active="bankCardModelActive" finish-status="success">
            <el-step title="第一步"></el-step>
            <el-step title="第二步"></el-step>
          </el-steps>
        </div>

        <div class="main" v-if="bankCardModelActive <= 1">
          <el-form
              :rules="formRules"
              ref="bankCardModelCodeRef"
              :model="bankCardModelCodeForm"
              key="bankCardModelFormCodeRule"
              label-width="80px"
          >
            <div class="form-item">
              <span class="msg">您当前绑定的手机号是{{currPhone}}</span>
              <el-form-item label="验证码" prop="customMsgCode">
                <el-input v-model="bankCardModelCodeForm.customMsgCode" clearable></el-input>
              </el-form-item>
              <div class="get-code">
                <div class="btn" @click="bankCardModelCodeFormGetCode">
                  <get-code ref="bankCardModelCodeFormGetCodeRef" :phone="phone" :is-not-verify="true"></get-code>
                </div>
              </div>
            </div>
          </el-form>
        </div>

        <div class="main" v-else>
          <el-form
              :rules="formRules"
              ref="bankCardModelPhoneRef"
              :model="bankCardModelPhoneForm"
              key="bankCardModelFormPhoneRule"
              label-width="120px"
              style="flex-direction: column;"
          >
            <div class="form-item">
              <el-form-item label="持卡人姓名" prop="name">
                <el-input v-model="bankCardModelPhoneForm.name" clearable></el-input>
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item label="持卡人手机号" prop="phone">
                <el-input v-model="bankCardModelPhoneForm.phone" clearable></el-input>
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item label="银行卡号" prop="bankCardNum">
                <el-input v-model="bankCardModelPhoneForm.bankCardNum" clearable></el-input>
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item label="所属银行">
                <div>
                  {{bankBelong}}
                </div>
              </el-form-item>
            </div>
          </el-form>
        </div>

      </div>

      <span slot="footer">
        <el-button v-if="bankCardModelActive <= 1" @click="bankCardModelNext">下一步</el-button>
        <el-button v-else type="primary" :loading="bankCardModelLoading" @click="bankCardModelSubmit">完 成</el-button>
      </span>
    </el-dialog>

    <!-- 解绑银行卡弹窗 -->
    <el-dialog
        title="提示"
        :visible.sync="unbindBankCardVisible"
        width="400px"
    >
      <div style="text-align: center;font-size: 16px; color: #333333;">确定要解绑银行卡？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="unbindBankCardVisible = false">取 消</el-button>
        <el-button type="primary" :loading="bankCardModelLoading" @click="unbindBankCardSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改手机号弹窗 -->
    <el-dialog
        title="修改手机号"
        :visible.sync="phoneModelIsShow"
        width="600px"
        :before-close="phoneModelClose"
        custom-class="accountManagement_dialog"
    >

      <div class="dialog-content">

        <div class="steps">
          <el-steps :active="phoneModelActive" finish-status="success">
            <el-step title="第一步"></el-step>
            <el-step title="第二步"></el-step>
          </el-steps>
        </div>

        <div class="main" v-if="phoneModelActive <= 1">
          <el-form
              :rules="formRules"
              ref="phoneModelCodeRef"
              :model="phoneModelCodeForm"
              key="phoneModelFormCodeRule"
              label-width="80px"
          >
            <div class="form-item">
              <span class="msg">您当前绑定的手机号是{{currPhone}}</span>
              <el-form-item label="验证码" prop="customMsgCode">
                <el-input v-model="phoneModelCodeForm.customMsgCode" clearable></el-input>
              </el-form-item>
              <div class="get-code">
                <div class="btn" @click="phoneModelCodeFormGetCode">
                  <get-code ref="phoneModelCodeFormGetCodeRef" :phone="phone" :is-not-verify="true"></get-code>
                </div>
              </div>
            </div>
          </el-form>
        </div>

        <div class="main" v-else>
          <el-form
              :rules="formRules"
              ref="phoneModelPhoneRef"
              :model="phoneModelPhoneForm"
              key="phoneModelFormPhoneRule"
              label-width="80px"
          >
            <div class="form-item">
              <el-form-item label="手机号" prop="phone">
                <el-input v-model="phoneModelPhoneForm.phone" clearable></el-input>
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item label="验证码" prop="msg_code">
                <el-input v-model="phoneModelPhoneForm.msg_code" clearable></el-input>
              </el-form-item>
              <div class="get-code">
                <div class="btn" @click="phoneModelPhoneFormGetCode">
                  <get-code ref="phoneModelPhoneFormGetCodeRef" :phone="phoneModelPhoneForm.phone" :is-not-verify="true"></get-code>
                </div>
              </div>
            </div>
          </el-form>
        </div>

      </div>

      <span slot="footer">
        <el-button v-if="phoneModelActive <= 1" @click="phoneModelNext">下一步</el-button>
        <el-button v-else type="primary" :loading="phoneModelLoading" @click="phoneModelSubmit">完 成</el-button>
      </span>
    </el-dialog>

    <!-- 修改密码弹窗 -->
    <el-dialog
        title="修改密码"
        :visible.sync="passwordModelIsShow"
        width="600px"
        :before-close="passwordModelClose"
        custom-class="accountManagement_dialog"
    >

      <div class="dialog-content">

        <div class="dialog-tab">
          <div :class="passwordModeFormNav === 1?'tab tab-checked':'tab'" @click="passwordModeFormNav = 1">
            旧密码验证
          </div>
          <div :class="passwordModeFormNav === 2?'tab tab-checked':'tab'" @click="passwordModeFormNav = 2">
            手机号验证
          </div>
        </div>

        <div class="main" v-if="passwordModeFormNav === 1">
          <el-form
              :rules="modelRules"
              ref="passwordModelPasswordRef"
              :model="passwordModePasswordForm"
              key="passwordModeFormPasswordRule"
              label-width="80px"
          >
            <div class="form-item">
              <el-form-item label="旧密码" prop="oldPassword">
                <el-input v-model="passwordModePasswordForm.oldPassword" clearable></el-input>
              </el-form-item>
              <div class="desc"></div>
            </div>

            <div class="form-item">
              <el-form-item label="新密码" prop="password">
                <el-input type="password" v-model="passwordModePasswordForm.password" clearable></el-input>
              </el-form-item>
              <div class="desc">
                <div>
                  <div :class="complexity >= 1?'top top-checked1':'top'"></div>
                  弱
                </div>
                <div>
                  <div :class="complexity >= 2?'top top-checked2':'top'"></div>
                  中
                </div>
                <div>
                  <div :class="complexity >= 3?'top top-checked3':'top'"></div>
                  强
                </div>
              </div>
            </div>

            <div class="form-item">
              <el-form-item label="重复密码" prop="repeatPassword">
                <el-input type="password" v-model="passwordModePasswordForm.repeatPassword" clearable></el-input>
              </el-form-item>
              <div class="desc"></div>
            </div>

          </el-form>
        </div>


        <div class="main" v-if="passwordModeFormNav === 2">
          <el-form
              :rules="modelRules"
              ref="passwordModelPhoneRef"
              :model="passwordModePhoneForm"
              key="passwordModeFormPhoneRule"
              label-width="80px"
          >
            <div class="form-item">
              <span class="msg">您当前绑定的手机号是{{currPhone}}</span>
              <el-form-item label="验证码" prop="customMsgCode">
                <el-input v-model="passwordModePhoneForm.customMsgCode" clearable></el-input>
              </el-form-item>
              <div class="get-code">
                <div class="btn" @click="passwordModelGetCode">
                  <get-code ref="passwordModePhoneFormGetCodeRef" :phone="phone" :is-not-verify="true"></get-code>
                </div>
              </div>
            </div>

            <div class="form-item">
              <el-form-item label="新密码" prop="password">
                <el-input type="password" v-model="passwordModePhoneForm.password" clearable></el-input>
              </el-form-item>
              <div class="desc">
                <div>
                  <div :class="complexity >= 1?'top top-checked1':'top'"></div>
                  弱
                </div>
                <div>
                  <div :class="complexity >= 2?'top top-checked2':'top'"></div>
                  中
                </div>
                <div>
                  <div :class="complexity >= 3?'top top-checked3':'top'"></div>
                  强
                </div>
              </div>
            </div>

            <div class="form-item">
              <el-form-item label="重复密码" prop="repeatPassword">
                <el-input type="password" v-model="passwordModePhoneForm.repeatPassword" clearable></el-input>
              </el-form-item>
              <div class="desc"></div>
            </div>

          </el-form>
        </div>

      </div>

      <span slot="footer">
        <el-button @click="passwordModelClose">取 消</el-button>
        <el-button type="primary" :loading="passwordModeFormSubmitLoading" @click="passwordModelSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 绑定微信前验证弹窗 -->
    <el-dialog
        title="绑定微信验证"
        :visible.sync="weChatModelIsShow"
        width="600px"
        custom-class="accountManagement_dialog"
    >
      <div class="dialog-content">
        <div class="main">
          <el-form
              :rules="modelRules"
              ref="weChatModelCodeRef"
              :model="weChatModelCodeForm"
              key="weChatModelFormCodeRule"
              label-width="80px"
          >
            <div class="form-item">
              <span class="msg">您当前绑定的手机号是{{currPhone}}</span>
              <el-form-item label="验证码" prop="customMsgCode">
                <el-input v-model.trim="weChatModelCodeForm.customMsgCode" clearable></el-input>
              </el-form-item>
              <div class="get-code">
                <div class="btn" @click="weChatModelCodeFormGetCode">
                  <get-code ref="weChatModelCodeFormGetCodeRef" :phone="phone" :is-not-verify="true"></get-code>
                </div>
              </div>
            </div>
          </el-form>
        </div>
      </div>

      <span slot="footer">
        <el-button type="primary" @click="weChatModelSubmit">完 成</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="按岗位设置招呼语"
        :visible.sync="setGreetingsDialogVisible"
        width="600px"
        custom-class="set-greetings-dialog-warp"
        :before-close="setGreetingsDialogClose">
      <div class="set-greetings-dialog-content">
        <div class="select">
          岗位：
          <lazy-select :options="jobOptions" :disabled="setGreetingsDialogType === 'edit'" :new-value="selectJob" :no-more="jobOptionsNoMore" @loadData="loadMoreMyPublishedJob" @change="chooseJobValueClick"></lazy-select>
        </div>
        <div class="input">
          <el-input v-model="editorSpecificGreetingsValue" placeholder="请输入招呼语" maxlength="100"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setGreetingsDialogClose">取 消</el-button>
        <el-button type="primary" :loading="setGreetingsDialogBtnLoading" @click="setGreetingsDialogSubmit">保 存</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import myUpload from 'vue-image-crop-upload'
import DynamicEntry from "@/components/dynamicEntry/dynamicEntry";
import {formRules} from '@/share/formRules'
import {BASE_URL, WEB_BASE_URL} from "@/config/baseURL";
import GetCode from "@/components/getCode/getCode";
import {
  GetHrChangeSelfInformationApi,
  PostHrChangeSelfInformationApi,
  PostHrChangePhoneApi,
  HrChangePasswordApi,
  GetWechatAccessTokenApi,
  GetWechatUserInfoApi,
  HrBindWechatApi,
  PostHrBindDankCardNumApi,
  MyPublishedJobApi,
  PostHrGreetingMessageManagementApi,
  GetHrGreetingMessageManagementApi,
  JobHrSeeJobDetailApi,
  PostHrUnbindBankCardNumApi, PostUtilsCheckMsgCodeApi
} from "@/request/api";
import {mapMutations} from "vuex";
import share from "@/share/share";
import banksName from "@/share/banksName";
import {get} from "@/request/http";
import LazySelect from "@/components/lazySelect/lazySelect";
import {Debounce} from "@/until/until"
export default {
  name: "hrData",
  components: {LazySelect, GetCode, DynamicEntry, myUpload},
  data() {
    return {
      pageLoading: false,
      hrId: '',
      imgDataUrl: '',
      uploadUrl: BASE_URL + '/user-files/upload-image/',
      headers: {},
      show: false,
      informationForm: {
        name: '',
        gender: '',
        wechat: '',
        bindWechat: '',
      },
      btnLoading: false,
      phone: '',

      bankCardNum: '',

      bankCardModelIsShow: false,
      bankCardModelLoading: false,
      bankCardModelActive: 1,
      bankCardModelCodeForm: {
        customMsgCode: '',
      },
      bankCardModelPhoneForm: {
        name: '',
        bankCardNum: '',
        phone: ''
      },

      unbindBankCardVisible: false,

      formRules: {
        ...formRules,
        customMsgCode: [
          {required: true, validator: this.msgCodeRule}
        ],
      },

      password: '',

      phoneModelIsShow: false,
      phoneModelLoading: false,
      phoneModelActive: 1,
      phoneModelCodeForm: {
        customMsgCode: '',
      },
      phoneModelPhoneForm: {
        phone: '',
        msg_code: '',
      },

      passwordModelIsShow: false,  // 修改密码弹窗
      passwordModePasswordForm: {
        oldPassword: '',
        password: '',
        repeatPassword: '',
      },
      passwordModePhoneForm: {
        customMsgCode: '',
        password: '',
        repeatPassword: '',
      },

      weChatModelIsShow: false,
      weChatModelCodeForm: {
        customMsgCode: '',
      },


      modelRules: {
        ...formRules,
        customMsgCode: [
          {required: true, validator: this.msgCodeRule}
        ],
        repeatPassword: [  // 再次输入密码
          {required: true, validator: this.repeatPassword}
        ],
      },
      passwordModeFormNav: 2,
      passwordModeFormSubmitLoading: false,
      complexity: 0,
      loading: null,
      wxCode: '',

      bankBelong: '',
      setGreetingsTabActiveName: '0',
      currentTableTab: 0,
      selectGreetings: 0,
      editorGreetingsValue: '',
      editorSpecificGreetingsValue: '',
      setGreetingsDialogBtnLoading: false,
      setGreetingsCardLoading: false,
      setGreetingsDialogVisible: false,
      setGreetingsDialogType: '',
      jobList: [],
      jobOptions: [],
      selectJob: '',
      newSelectJob: '',
      jobOptionsPage: {
        pageNumber: 1,
        pageSize: 20,
      },
      jobOptionsNoMore: true,
      currentPositionInformationDetails: {  // 当前hr选中的岗位详细信息
        jobName: '销售代表(示例职位)',
        salary: '5K-8K(示例薪资)',
        address: '常州-新北（区域示例）',
      },
      automaticGreeting: true,  // 是否开启自动打招呼语
      listOfRecommendedGreetings: [],  // 推荐招呼语列表
      listOfSpecialGreetings: [],  // 特殊招呼语列表
      exclusionsList: [],
    }
  },
  computed: {
    aonuoChat() {
      return this.$store.state.aonuoChat
    },
    currPhone() {
      let str = ''
      if (this.phone === '' || this.phone === null) {
        str = '空'
      }else {
        let str1 = this.phone.slice(0, 3)
        let str2 = this.phone.slice(7, 11)
        str = str1 + '****' + str2
      }
      return str
    },
    currPassword() {
      let str = '密码未设置'
      if (this.password) {
        str = '密码已设置，不可见'
      }
      return str
    },
    myAvatar() {
      return this.$store.state.userInfo.profilePhoto
    },
    cardNum() {
      let str = ''
      if (this.bankCardNum === '' || this.bankCardNum === null) {
        str = '空'
      }else {
        let str1 = this.bankCardNum.slice(0, 4)
        let str2 = this.bankCardNum.slice(this.bankCardNum.length - 4, this.bankCardNum.length)
        str = '已绑定（' + str1 + '****' + str2 + '）'
      }
      return str
    },
    automaticGreetingIsOpen() {
      return this.$store.state.automaticGreetingIsOpen
    },
    greetingsList() {
      return this.$store.state.greetingsList
    },
    automaticGreetingsForSpecificPositions() {
      return this.$store.state.automaticGreetingsForSpecificPositions
    },
    currentAutomaticGreetings() {
      return this.$store.state.currentAutomaticGreetings
    },
    hrCurrentRepresentative() {
      return this.$store.state.hrCurrentRepresentative
    }
  },
  watch: {
    'passwordModePhoneForm.password': {
      handler: function() {
        let num = share.checkStrong(this.passwordModePhoneForm.password)
        this.complexity = num
      },
    },
    'passwordModePasswordForm.password': {
      handler: function() {
        let num = share.checkStrong(this.passwordModePasswordForm.password)
        this.complexity = num
      },
    },
    passwordModeFormNav() {
      if (this.passwordModeFormNav === 2) {
        this.passwordModePasswordForm.oldPassword = ''
        this.passwordModePasswordForm.password = ''
        this.passwordModePasswordForm.repeatPassword = ''
        this.$nextTick(()=>{
          this.$refs.passwordModelPhoneRef.resetFields()
        })
      }else {
        this.passwordModePhoneForm.customMsgCode = ''
        this.passwordModePhoneForm.password = ''
        this.passwordModePhoneForm.repeatPassword = ''
        this.$nextTick(()=>{
          this.$refs.passwordModelPasswordRef.resetFields()
        })
      }
    },
    'bankCardModelPhoneForm.bankCardNum': {
      handler: function() {
        if (this.bankCardModelPhoneForm.bankCardNum.length > 5) {
          this.VueDebounce({vm: this})
        }
      },
    }
  },
  async mounted() {
    this.pageLoading = true
    await this.loadData()
    this.pageLoading = false

    let local = location.href
    let code = share.getUrlCode(local, 'code')
    if (code && code !== '' && code !== 'null') {
      let stateUrl = share.getUrlCode(local, 'state')

      let state = localStorage.getItem('wx_bind_state')
      if (stateUrl && state && (stateUrl.toString() === state.toString())) {
        this.wxCode = code
        this.toH5WeChatLogin()
      }else {
        share.message.error('微信授权登录失效')
      }
    }
    localStorage.removeItem('wx_bind_state')
  },
  methods: {
    ...mapMutations(['setUserinfo', 'setToken', 'setHRCurrentRepresentative',
      'setAutomaticGreetingIsOpen', 'setGreetingsList', 'setAutomaticGreetingsForSpecificPositions',
      'setCurrentAutomaticGreetings']),
    VueDebounce: Debounce(({vm}) => {
      vm['loadCardInfo']()
    }, 500),
    async loadData() {
      const res = await Promise.all([this.getHrChangeSelfInformation(), this.loadMyPublishedJobData()])
    },
    async loadMyPublishedJobData() {
      const res = await MyPublishedJobApi({
        jobStatus: 3,
        pageNum: this.jobOptionsPage.pageNumber,
        pageSize: this.jobOptionsPage.pageSize,
      })
      let hrSelectValue = ''
      let hrSelectLabel = ''
      if (res) {
        this.jobList = res.jobList
        if (this.jobOptionsPage.pageNumber >= res.numOfPages) {
          this.jobOptionsNoMore = true
        }else {
          this.jobOptionsNoMore = false
        }
        if (res.jobList.length > 0) {
          if (this.hrCurrentRepresentative.value) {
            const res2 = await JobHrSeeJobDetailApi({
              jobId: this.hrCurrentRepresentative.value,
            })
            if (res2 && res2.jobInformation && !res2.jobInformation.isClose && res2.jobInformation.jobStatus === 3) {
              hrSelectValue = this.hrCurrentRepresentative.value
              hrSelectLabel = this.hrCurrentRepresentative.label
            }else {
              hrSelectValue = res.jobList[0].value
              hrSelectLabel = res.jobList[0].label
            }
          }else {
            hrSelectValue = res.jobList[0].value
            hrSelectLabel = res.jobList[0].label
          }
        }else {
          hrSelectValue = ''
          hrSelectLabel = ''
        }
      }
      this.jobOptionsPage.pageNumber++
      this.setHRCurrentRepresentative({
        hrCurrentRepresentative: {
          value: hrSelectValue,
          label: hrSelectLabel
        }
      })
      this.initGreeting()
    },
    async loadMoreMyPublishedJob(callback) {
      if (this.jobOptionsNoMore) {
        return;
      }
      const res = await MyPublishedJobApi({
        pageNum: this.jobOptionsPage.pageNumber,
        pageSize: this.jobOptionsPage.pageSize,
        jobStatus: 3,
      })
      if (res) {
        if (this.jobOptionsPage.pageNumber >= res.numOfPages) {
          this.jobOptionsNoMore = true
        }else {
          this.jobOptionsNoMore = false
        }
        this.jobList = this.jobList.concat(res.jobList)

        let jobOptions = []
        this.jobList.forEach((item, index) => {
          if (this.exclusionsList.indexOf(item.jobId) === -1) {
            let obj = {
              value: item.jobId,
              label: item.jobName
            }
            jobOptions.push(obj)
          }
        })
        this.jobOptions = jobOptions
      }
      this.jobOptionsPage.pageNumber++
      callback()
    },
    async chooseJobValueClick(value) {
      this.selectJob = value
    },
    initGreeting() {
      this.automaticGreeting = this.automaticGreetingIsOpen  // 自动打招呼是否开启
      this.selectGreetings = this.currentAutomaticGreetings  // 当前选中的打招呼id
      let listOfRecommendedGreetings = []
      this.greetingsList.forEach((item, index) => {  // 推荐招呼语列表
        let str = share.replacementStringArgument(item.message, this.currentPositionInformationDetails)
        listOfRecommendedGreetings.push({
          id: item.id,
          message: str
        })
      })
      this.listOfRecommendedGreetings = listOfRecommendedGreetings
      let listOfSpecialGreetings = []
      this.exclusionsList = []
      this.automaticGreetingsForSpecificPositions.forEach((item, index) => {  // 特殊招呼语列表
        let str = share.replacementStringArgument(item.message, this.currentPositionInformationDetails)
        listOfSpecialGreetings.push({
          id: item.id,
          jobName: item.jobName,
          message: str
        })
        this.exclusionsList.push(item.id)
      })
      this.listOfSpecialGreetings = listOfSpecialGreetings
      if (this.greetingsList.length > 0) {
        this.editorGreetingsValue = this.greetingsList[0].message
      }
      let jobOptions = []
      this.jobList.forEach((item, index) => {
        if (this.exclusionsList.indexOf(item.jobId) === -1) {
          let obj = {
            value: item.jobId,
            label: item.jobName
          }
          jobOptions.push(obj)
        }
      })
      if (jobOptions.length > 0) {
        this.selectJob = jobOptions[0].value
      }
      this.jobOptions = jobOptions
    },
    async getHrChangeSelfInformation() {
      const res = await GetHrChangeSelfInformationApi()

      if (res) {
        this.imgDataUrl = this.myAvatar
        this.hrId = res.hrInformation.hrId
        this.informationForm.gender = res.hrInformation.gender
        this.informationForm.name = res.hrInformation.name
        this.informationForm.wechat = res.hrInformation.wechat
        this.informationForm.bindWechat = res.hrInformation.bindWechat || ''
        this.phone = res.hrInformation.phone
        this.bankCardNum = res.hrInformation.bankCardNum || ''
        this.password = res.hrInformation.password
      }
    },
    async loadCardInfo() {
      let cardNo = this.bankCardModelPhoneForm.bankCardNum
      const res = await get(`https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?cardNo=${cardNo}&cardBinCheck=true`, '')
      if (res.data.validated) {
        let t = res.data.bank
        this.bankBelong = banksName[t]
      }else {
        this.bankBelong = ''
      }
    },

    passwordModelGetCode() {
      this.$refs.passwordModePhoneFormGetCodeRef.getCode()
    },
    async msgCodeRule(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入验证码'))
      } else {
        const res = await PostUtilsCheckMsgCodeApi({
          check: true,
          phone: this.phone,
          msgCode: value,
        })
        if (res && res.res) {
          callback()
        } else {
          callback(new Error('验证码错误'))
        }
      }
    },
    repeatPassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (this.passwordModeFormNav === 1) {
        if (this.passwordModePasswordForm.password !== value) {
          callback(new Error('两次密码输入不一致'))
        } else {
          callback()
        }

      } else {
        if (this.passwordModePhoneForm.password !== value) {
          callback(new Error('两次密码输入不一致'))
        }else {
          callback()
        }
      }
    },
    uploadHeadPortrait() {
      this.show = !this.show
    },
    srcFileSet(fileName, fileType, fileSize) {
    },
    cropSuccess(imgDataUrl, field) {
    },
    cropUploadSuccess(jsonData, field) {
      this.imgDataUrl = jsonData.data.url;

    },
    cropUploadFail(status, field) {
    },
    changeCurrNavPage(key, index) {
      this.$router.push({
        path: key,
        query: {
          index: index
        }
      })
    },

    bankCardModelClose() {
      if (this.bankCardModelActive === 1) {
        this.$refs.bankCardModelCodeRef.resetFields()
      }else {
        this.$refs.bankCardModelPhoneRef.resetFields()
      }
      this.bankCardModelActive = 1
      this.bankCardModelCodeForm.customMsgCode = ''
      this.bankCardModelPhoneForm.name = ''
      this.bankCardModelPhoneForm.bankCardNum = ''
      this.bankCardModelPhoneForm.phone = ''
      this.bankBelong = ''
      this.bankCardModelIsShow = false
    },
    bankCardModelCodeFormGetCode() {
      this.$refs.bankCardModelCodeFormGetCodeRef.getCode()
    },
    bankCardModelNext() {
      this.$refs.bankCardModelCodeRef.validate(res => {
        if (res) {
          this.bankCardModelActive ++
        }
      })
    },
    bankCardModelSubmit() {
      this.$refs.bankCardModelPhoneRef.validate(async res => {
        if (res) {
          this.bankCardModelLoading = true

          const res2 = await PostHrBindDankCardNumApi({
            name: this.bankCardModelPhoneForm.name,
            bankCardNum: this.bankCardModelPhoneForm.bankCardNum,
            msgCode: this.bankCardModelCodeForm.customMsgCode,
            phone: this.bankCardModelPhoneForm.phone
          })
          if (res2.code && res2.code === 440) {
            share.message.error(res2.data.message)
          } else {
            share.message.success('恭喜你，绑定银行卡成功！')
          }

          await this.getHrChangeSelfInformation()

          this.bankCardModelClose()
          this.bankCardModelLoading = false
        }
      })
    },

    phoneModelCodeFormGetCode() {
      this.$refs.phoneModelCodeFormGetCodeRef.getCode()
    },
    phoneModelPhoneFormGetCode() {
      if (this.phoneModelPhoneForm.phone  === this.phone) {
        share.message.error('新手机号不能与旧手机号相同')
        return
      }
      this.$refs.phoneModelPhoneFormGetCodeRef.getCode()
    },
    phoneModelNext() {
      this.$refs.phoneModelCodeRef.validate(res => {
        if (res) {
          this.phoneModelActive ++
        }
      })
    },
    phoneModelClose() {
      if (this.phoneModelActive === 1) {
        this.$refs.phoneModelCodeRef.resetFields()
      }else {
        this.$refs.phoneModelPhoneRef.resetFields()
      }
      this.phoneModelActive = 1
      this.phoneModelCodeForm.customMsgCode = ''
      this.phoneModelPhoneForm.msg_code = ''
      this.phoneModelPhoneForm.phone = ''
      this.phoneModelIsShow = false
    },
    phoneModelSubmit() {
      this.$refs.phoneModelPhoneRef.validate(async res => {
        if (res) {
          this.phoneModelLoading = true

          if (this.phoneModelPhoneForm.phone  === this.phone) {
            share.message.error('新手机号不能与旧手机号相同')
            return
          }

          const res2 = await PostHrChangePhoneApi({
            old_msg_code: this.phoneModelCodeForm.customMsgCode,
            new_msg_code: this.phoneModelPhoneForm.msg_code,
            newPhone: this.phoneModelPhoneForm.phone,
          })
          if (res2) {
            share.message.success('修改成功')
            await this.getHrChangeSelfInformation()
          }
          // this.phoneModelIsShow = false
          this.phoneModelClose()
          this.phoneModelLoading = false
        }
      })
    },
    informationFormSubmit() {
      this.$refs.informationFormRef.validate(async res => {
        if (res) {
          this.btnLoading = true

          // let strIndex = this.imgDataUrl.indexOf('images')

          let logoPath = share.initImageUrlPath(this.imgDataUrl)

          const res2 = await PostHrChangeSelfInformationApi({
            hrId: this.hrId,
            name: this.informationForm.name,
            avatarPath: logoPath,
            gender: this.informationForm.gender,
            wechat: this.informationForm.wechat,
          })

          this.btnLoading = false

          if (res2) {
            let url = share.initImageUrl(this.imgDataUrl)
            this.setUserinfo({
              profilePhoto:  url,
              username: this.informationForm.name
            })
            share.message.success('保存成功')
          }
        }
      })
    },

    passwordModelClose() {
      if (this.passwordModeFormNav === 1) {
        this.$refs.passwordModelPasswordRef.resetFields()
      }else if (this.passwordModeFormNav === 2) {
        this.$refs.passwordModelPhoneRef.resetFields()
      }
      this.passwordModePasswordForm.oldPassword = ''
      this.passwordModePasswordForm.password = ''
      this.passwordModePasswordForm.repeatPassword = ''
      this.passwordModePhoneForm.customMsgCode = ''
      this.passwordModePhoneForm.password = ''
      this.passwordModePhoneForm.repeatPassword = ''
      this.passwordModelIsShow = false
    },
    passwordModelSubmit() {
      if (this.passwordModeFormNav === 1) {

        this.$refs.passwordModelPasswordRef.validate(async res => {
          if (res) {
            this.passwordModeFormSubmitLoading = true

            const res2 = await HrChangePasswordApi({
              action: 'oldPassword',
              oldPassword: this.passwordModePasswordForm.oldPassword,
              newPassword: this.passwordModePasswordForm.password,
              confirmPassword: this.passwordModePasswordForm.repeatPassword,
            })
            if (res2) {
              share.message.success('密码修改成功！请重新登录')
              this.userExit()
            }
            this.passwordModelIsShow = false
            this.passwordModeFormSubmitLoading = false
          }
        })

      }else if (this.passwordModeFormNav === 2) {

        this.$refs.passwordModelPhoneRef.validate(async res => {
          if (res) {
            this.passwordModeFormSubmitLoading = true

            const res2 = await HrChangePasswordApi({
              action: 'msgCode',
              msgCode: this.passwordModePhoneForm.customMsgCode,
              newPassword: this.passwordModePhoneForm.password,
              confirmPassword: this.passwordModePhoneForm.repeatPassword,
            })
            if (res2) {
              share.message.success('修改成功')
              this.userExit()
            }
            this.passwordModelIsShow = false
            this.passwordModeFormSubmitLoading = false
          }
        })

      }
    },

    toBindWechat() {
      this.weChatModelCodeForm.customMsgCode = ''
      this.weChatModelIsShow = true
    },

    weChatModelCodeFormGetCode() {
      this.$refs.weChatModelCodeFormGetCodeRef.getCode()
    },

    weChatModelSubmit() {
      this.$refs.weChatModelCodeRef.validate(async res => {
        if (res) {
          this.beginBindWechat()
        }
      })
    },

    beginBindWechat() {
      let timestamp = new Date().getTime()
      let num = Math.round(Math.random() * 10)
      let state = timestamp + '' + num

      localStorage.setItem('wx_bind_state', state)
      let redirect_uri = WEB_BASE_URL + 'hrData'
      window.location.href = 'https://open.weixin.qq.com/connect/qrconnect?appid=wxfe42628884a0a90a&redirect_uri=' + encodeURIComponent(redirect_uri) + '&response_type=code&scope=snsapi_login&state=' + state
    },

    async toH5WeChatLogin() {
      this.loading = share.loadingShow({
        id: '#hrData_warp_id',
        text: '绑定中',
        background: '#f4f2f3',
      })

      const res = await GetWechatAccessTokenApi({
        code: this.wxCode,
        platform: 'web',
      })

      if (res.data && res.data.access_token) {
        const res2 = await GetWechatUserInfoApi({
          accessToken: res.data.access_token,
          openId: res.data.openid
        })


        if (res2.data) {
          let info = {
            ...res2.data,
            unionId: res2.data.unionid,
            openId: res2.data.openid
          }
          const res3 = await HrBindWechatApi({
            unionId: info.unionId || info.unionid,
            name: info.nickName || info.nickname,
          })

          if (res3) {
            this.loading.close()
            share.message.success('绑定微信账号成功')
            this.pageLoading = true
            await this.getHrChangeSelfInformation()
            this.pageLoading = false
          }else {
            this.loading.close()
            share.message.error('绑定微信账号失败')
          }

        }else {
          this.loading.close()
          share.message.error('获取微信用户信息失败')
        }

      }else {
        this.loading.close()
        share.message.error('获取登录微信授权失败')
      }
    },

    /* 用户退出 */
    userExit() {
      this.aonuoChat.logout()
      share.loginOut()
      this.setUserinfo({
        userType: 'employee',
      })
      this.$router.push('/login')
    },

    setGreetingsTabActiveNameClick() {

    },
    /**
     * 改变是否开启打招语状态
     * */
    async automaticGreetingChange() {
      this.setGreetingsCardLoading = true
      let isOpen = 0
      if (this.automaticGreeting) {
        isOpen = 1
      }
      const res = await PostHrGreetingMessageManagementApi({
        isOpen: isOpen
      })
      if (res) {
        this.setAutomaticGreetingIsOpen({
          automaticGreetingIsOpen: this.automaticGreeting
        })
      }
      this.setGreetingsCardLoading = false
    },
    /**
     * 改变选中的打招语
     * */
    async selectGreetingsChange() {
      this.setGreetingsCardLoading = true
      let param = {
        optionId: this.selectGreetings,
      }
      if (this.selectGreetings === 0) {
        param.message = this.listOfRecommendedGreetings[0].message
      }
      const res = await PostHrGreetingMessageManagementApi(param)
      if (res) {
        this.setCurrentAutomaticGreetings({
          currentAutomaticGreetings: this.selectGreetings
        })
      }
      this.setGreetingsCardLoading = false
    },

    async editorGreetingsValueSubmit() {
      if (!this.editorGreetingsValue) {
        share.message.warning('请输入招呼语')
        return
      }
      this.setGreetingsCardLoading = true
      const res = await PostHrGreetingMessageManagementApi({
        optionId: 0,
        message: this.editorGreetingsValue,
      })
      if (res) {
        share.message.success('修改成功')
      }
      await this.getGreetingMessageManagement()
      // await this.loadMyPublishedJobData()
      this.initGreeting()
      this.setGreetingsCardLoading = false
    },

    setGreetingsDialogShow(type, id = '', jobName, message = '') {
      this.setGreetingsDialogType = type
      if (type === 'edit') {
        this.jobList.unshift({
          jobId: id,
          jobName: jobName
        })
        this.jobOptions.unshift({
          value: id,
          label: jobName
        })
        this.selectJob = parseInt(id)
      }else {
        if (this.jobOptions.length > 0) {
          this.selectJob = this.jobOptions[0].value
        }else {
          this.selectJob = ''
        }
      }
      this.editorSpecificGreetingsValue = message
      this.setGreetingsDialogVisible = true
    },

    setGreetingsDialogClose() {
      this.setGreetingsDialogVisible = false
      this.editorSpecificGreetingsValue = ''
      if (this.setGreetingsDialogType === 'edit') {
        this.jobList.shift()
        this.jobOptions.shift()
      }
      this.selectJob = ''
    },

    async setGreetingsDialogSubmit() {
      if (!this.editorSpecificGreetingsValue) {
        share.message.warning('请输入招呼语')
        return
      }
      this.setGreetingsDialogBtnLoading = true
      const res = await PostHrGreetingMessageManagementApi({
        jobId: this.selectJob,
        message: this.editorSpecificGreetingsValue,
      })

      if (res) {
        if (this.setGreetingsDialogType === 'add') {
          share.message.success('招呼语新增成功')
        }
        if (this.setGreetingsDialogType === 'edit') {
          share.message.success('招呼语编辑成功')
        }
        await this.getGreetingMessageManagement()
        // await this.loadMyPublishedJobData()
        this.initGreeting()
      }
      this.setGreetingsDialogBtnLoading = false
      this.setGreetingsDialogClose()
    },
    /**
     * 获取打招呼设置
     * */
    async getGreetingMessageManagement() {
      const res = await GetHrGreetingMessageManagementApi()
      if (res) {
        this.setAutomaticGreetingIsOpen({
          automaticGreetingIsOpen: res.greetingMessageConf.isOpen
        })
        this.setGreetingsList({
          greetingsList: res.systemGreetingMessageList
        })
        this.setAutomaticGreetingsForSpecificPositions({
          automaticGreetingsForSpecificPositions: res.jobGreetingMessageList
        })
        this.setCurrentAutomaticGreetings({
          currentAutomaticGreetings: res.greetingMessageConf.optionId
        })
      }
    },
    /**
    * 删除打招呼语
    * */
    async deleteGreetings(id) {
      this.setGreetingsCardLoading = true
      const res = await PostHrGreetingMessageManagementApi({
        jobId: id,
      })
      if (res) {
        share.message.success('删除成功')
      }
      await this.getGreetingMessageManagement()
      // await this.loadMyPublishedJobData()
      this.initGreeting()
      this.setGreetingsCardLoading = false
    },
    toUnbindCardNum() {
      this.unbindBankCardVisible = true
    },
    async unbindBankCardSubmit() {
      this.bankCardModelLoading = true
      const res = await PostHrUnbindBankCardNumApi()

      if (res) {
        share.message.success('解绑成功')
        this.getHrChangeSelfInformation()
      }
      this.bankCardModelLoading = false
      this.unbindBankCardVisible = false
    },
  }
}
</script>

<style lang="less" scoped>
.hrData_warp {
  width: 100%;
  //height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #f4f2f3;

  .left {
    width: 780px;
    //height: 100%;
    background-color: #f4f2f3;

    .avatar {
      width: 100%;
      background-color: #ffffff;

      .title {
        height: 40px;
        border-bottom: 1px solid #d2d2d2;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0px 20px;
        box-sizing: border-box;
      }

      .main {
        width: 100%;
        color: #3a3a3a;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 30px 30px;
        box-sizing: border-box;

        .main-title {
          font-size: 14px;
          margin-right: 20px;
        }

        .upload-btn {
          width: 110px;
          height: 110px;
          border: 1px dashed #d9d9d9;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:hover {
            border-color: #409EFF;

            > div {
              color: #409EFF;
            }
          }

          > img {
            width: 100%;
            height: 100%;
          }

          > div {
            font-size: 30px;
            color: #8c939d;
          }

        }
      }

      .information-main {
        width: 100%;
        padding: 20px 50px 10px 20px;
        box-sizing: border-box;

        .btn {
          height: 40px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .el-button {
            padding: 10px 25px;
            background-color: #e20000;
            color: #ffffff;
          }
        }
      }
    }

    .information {
      width: 100%;
      margin-top: 20px;
      background-color: #ffffff;

      .title {
        height: 40px;
        border-bottom: 1px solid #d2d2d2;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0px 20px;
        box-sizing: border-box;
      }

      .phone {
        width: 100%;
        padding: 40px 50px 40px 40px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .input {
          width: 520px;
        }

        .phone-label {
          width: 80px;
        }

        .el-button {
          margin-left: 40px;
          padding: 12px 30px;
        }
      }

    }

    .set-greetings-card {
      width: 100%;
      margin-top: 20px;
      background-color: #ffffff;

      .title {
        height: 40px;
        border-bottom: 1px solid #d2d2d2;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0px 20px;
        box-sizing: border-box;
        span {
          margin-right: 10px;
        }
        .hint {
          font-size: 12px;
          color: #e20000;
        }
      }

      .set-greetings {
        width: 100%;
        padding: 20px 50px;
        box-sizing: border-box;

        .table {
          width: 100%;
          .tabs {
            width: calc(100% - 2px);
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border: 1px solid #efefef;
            .tab {
              margin-right: 30px;
              padding: 10px 20px;
              box-sizing: border-box;
              cursor: pointer;
            }
            .tab-active {
              color: #409eff;
            }
          }
          .el-radio-group {
            width: 100%;
            .row {
              width: 100%;
              padding: 15px 20px;
              box-sizing: border-box;
              border-bottom: 1px solid #efefef;
              border-right: 1px solid #efefef;
              border-left: 1px solid #efefef;
              label {
                width: 100%;
                display: inline-flex;
                & /deep/ .el-radio__label {
                  //flex: 1;
                  word-break: break-all;
                  .message-span {
                    width: 100%;
                    line-height: 18px;
                    white-space: normal;
                  }
                }
              }
            }
          }

          .editor-greetings-value {
            width: 100%;
            background-color: #fafafb;
            padding: 20px 40px;
            box-sizing: border-box;

            .btn {
              width: 100%;
              text-align: right;
              margin-top: 20px;
            }
          }
        }
        .cards {
          width: 100%;
          .card {
            background-color: #fafafb;
            padding: 15px 20px;
            box-sizing: border-box;
            margin-bottom: 20px;
            border-radius: 5px;
            position: relative;
            .job-name {
              font-size: 14px;
              color: #8f8f8f;
              margin-bottom: 5px;
            }
            .value {
              font-size: 16px;
              word-break: break-all;
            }
            .actions {
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: space-between;
              position: absolute;
              right: 0;
              top: 0;
              .action {
                width: 25px;
                height: 25px;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                &:hover {
                  background-color: #cbcbcb;
                }
              }
            }
          }
          .btn {
            width: 100%;
            height: 50px;
            padding: 0 20px;
            box-sizing: border-box;
            border: 1px solid #efefef;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            .icon {
              margin-right: 10px;
            }
          }
        }

      }
    }
  }

  .right {
    flex: 1;
    //min-height: 600px;
    margin-left: 10px;
    background-color: #f4f2f3;
  }

}
</style>

<style lang="less">
.set-greetings-dialog-warp {
  .set-greetings-dialog-content {
    width: 100%;
    .select {
      margin-bottom: 20px;
    }
  }
}
</style>
