<template>
  <div class="redEnvelopes_warp">
    <img src="@/assets/icon/red-envelopes3.png">
    <div class="number" :style="'font-size: ' + fontsize + ';'">{{number}}</div>
  </div>
</template>

<script>
export default {
  name: "redEnvelopes",
  props: {
    number: {
      type: [Number, String],
      default: 0
    },
    fontsize: {
      type: [String],
      default: '14px'
    }
  },
}
</script>

<style lang="less" scoped>
.redEnvelopes_warp {
  width: 100%;
  height: 100%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  .number {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 999;
    width: 100%;
    height: 100%;
    color: #ffffff;
    //font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20%;
    box-sizing: border-box;
  }

}
</style>