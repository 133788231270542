import { Loading } from 'element-ui'
import {Message} from "element-ui";
import store from '../store/index'
import {areas, cityUnderDirectJurisdiction, enumeratePagePath} from "./shareData";
import {base64TransformFile} from "@/share/base64TransformFile";
const re = /^[1-9]+[0-9]*]*$/
export default {

    /**
     * 删除字符串左右两边空格
     * */
    trim(str) {
        let re = /(^\s*)|(\s*$)/g
        return str.toString().replace(re, "")
    },

    /* 全局加载 */
    loadingShow(dom){
        const loading = Loading.service({
            lock: true,  // lock的修改符--默认是false
            text: dom.text || '加载中',  // 显示在加载图标下方的加载文案
            background: dom.background || 'hsla(0,0%,100%,.9)',  // 遮罩层颜色
            target: document.querySelector(dom.id)  // loadin覆盖的dom元素节点
        });
        return loading
        //成功回调函数停止加载
        // loading.close()
    },

    /* 手机号验证 */
    phoneRules(rule, value, callback) {
        let ab = /^1[3456789]\d{9}$/
        if (value === '') {
            callback(new Error('手机号不能为空'))
        } else if (ab.test(value) === false) {
            callback(new Error('手机号格式错误'))
        } else {
            callback()
        }
    },

    /* 金额验证 */
    amountRules(rule, value, callback) {
        if (value === '') {
            callback(new Error('金额不能为空'))
        } else if (value < 10) {
            callback(new Error('金额不能小于10元'))
        } else {
            callback()
        }
    },
    /* 金额验证 */
    amountRules2(rule, value, callback) {
        if (value === '') {
            callback(new Error('金额不能为空'))
        } else if (value < 1) {
            callback(new Error('金额不能小于1元'))
        } else {
            callback()
        }
    },
    /* 金额验证 */
    amountRules3(rule, value, callback) {
        if (value === '') {
            callback(new Error('金额不能为空'))
        } else if (!re.test(value)) {
            callback(new Error('金额只能为正整数'))
        } else {
            callback()
        }
    },

    /* 招聘人数验证 */
    peopleNumberRules(rule, value, callback) {
        // let re = /^[1-9]+[0-9]*]*$/
        if (value === '') {
            callback(new Error('招聘人数不能为空'))
        } else if (!re.test(value)) {
            callback(new Error('招聘人数只能为正整数'))
        } else {
            callback()
        }
    },

    /* 判断密码强度 */
    checkStrong(sValue) {
        let modes = 0;
        //正则表达式验证符合要求的
        if(sValue.length < 1) return modes;
        if(/\d/.test(sValue)) modes++; //数字
        if(/[a-z]/.test(sValue)) modes++; //小写
        if(/[A-Z]/.test(sValue)) modes++; //大写
        if(/\W/.test(sValue)) modes++; //特殊字符

        //逻辑处理
        switch(modes) {
            case 1:
                return 1;
                break;
            case 2:
                return 2;
                break;
            case 3:
            case 4:
                return sValue.length < 10 ? 3 : 4
                break;
        }
        return modes;
    },

    /**
     * 初始化图片链接
     * */
    initImageUrl(url) {
        let randomNumber = Math.round(Math.random() * 1000)
        let timestamp = new Date().getTime()
        return url + '?' + randomNumber + '' + timestamp
    },

    /**
     * 格式化图片链接为服务器接受的格式
     * */
    initImageUrlPath(url) {
        let strIndex = url.indexOf('media')
        let strIndex2 = url.length
        let index = url.indexOf('?')
        if (index !== -1) {
            strIndex2 = index
        }
        let urlPath = url.slice(strIndex + 6, strIndex2)  // 格式化图片

        return urlPath
    },

    /**
     * 获取链接中的值
     * */
    // getUrlCode (name) {
    //     return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    // },
    getUrlCode (path, name) {
        // return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
        let reg = new RegExp("(^|\\?|&)" + name + "=([^&]*)(\\s|&|$)", "i");
        if (reg.test(path))
            return unescape(RegExp.$2.replace(/\+/g, " "));
        return "";
    },

    /**
     * 获取定位
     * */
    getLocation(callback) {

        this.mountBMap((res) => {
            this.returnLocation(res, callback)
        })

    },

    /**
     * h5 端创挂载位置信息对象
     * */
    mountBMap(callback) {

        let BMap = store.state.BMap

        console.log('BMap', BMap)

        let geolocation = new BMap.Geolocation()
        this.h5GetLocation(geolocation, callback)

        // if (!window.BMap) {
        //     console.log('获取中')
        //     MP('YhUXfgnpgY0Q1GfOLbX8aFzxzqI9XOCN').then(BMap=> {
        //         let geolocation = new BMap.Geolocation()
        //         this.h5GetLocation(geolocation, callback)
        //     })
        // }else {
        //     console.log('获取中')
        //     let geolocation = new window.BMap.Geolocation()
        //     this.h5GetLocation(geolocation, callback)
        // }
    },

    /**
     * h5 端获取定位信息
     * */
    h5GetLocation(obj, callback) {
        obj.getCurrentPosition(res => {
            console.log('getLocationSuccess', res)
            callback({
                latitude: res.latitude,  // 经度
                longitude: res.longitude,  // 维度
                province: res.address.province,
                city: res.address.city
            })
        })
    },

    /**
     * 获取市 code
     * */
    getCityCode(arr, province, city) {
        let provinceArr = this.getProvince(arr, province)
        let cityCode = ''
        provinceArr.forEach((item, index) => {
            if (item.label === city) {
                cityCode = item.value
            }
        })
        return cityCode
    },

    /**
     * 根据省名获取省下的所有市
     * */
    getProvince(arr, province) {
        let children = []
        arr.forEach((item, index) => {
            if (item.label === province) {
                children = item.children
            }
        })
        return children
    },

    /**
     * 返回获得的定位信息
     * */
    returnLocation(res, callback) {
        let [latitude, longitude, province, city, cityCode] = [res.latitude, res.longitude, res.province, res.city, '']

        let isCityUnderDirectJurisdiction = false
        let isCityUnderDirectJurisdictionCode = ''
        cityUnderDirectJurisdiction.forEach((item, index) => {
            if (item.label.indexOf(city) !== -1) {
                isCityUnderDirectJurisdiction = true
                isCityUnderDirectJurisdictionCode = item.value
            }
        })

        if (isCityUnderDirectJurisdiction) {
            cityCode = isCityUnderDirectJurisdictionCode
        } else {
            cityCode = this.getCityCode(areas, province, city)
        }

        callback({
            latitude: latitude,
            longitude: longitude,
            province: province,
            city: city,
            cityCode: cityCode
        })
    },

    /**
     * 退出登录
     * */
    loginOut() {
        store.commit('setUserinfo', {
            username: '',
            gender: '',
            userType: '',
            profilePhoto: '',
            birthday: ''
        })

        localStorage.removeItem('JXZP_User_Token')

        store.commit('setToken', {
            token: '',
        })
        console.log(store.state.userInfo)
        console.log(store.state.token)

        store.commit('setMessageUnreadCount', {
            messageUnreadCount: 0,
        })
        store.commit('setJoinUnreadCount', {
            joinUnreadCount: 0,
        })
        // store.commit('setAonuoChatReady', {
        //     aonuoChatReady: false,
        // })
        store.commit('setMyChatUserId', {
            myChatUserId: '',
        })
        store.commit('setChatSessionId', {
            chatSessionId: '',
        })
        store.commit('setChatSessionInfo', {
            chatSessionInfo: null,
        })
        store.commit('setChatSession', {
            chatSession: null,
        })
        store.commit('setShareId', {
            shareId: '',
        })
        store.commit('setShareJobId', {
            shareJobId: '',
        })
        store.commit('setShareArea', {
            shareArea: '',
        })
        store.commit('setHRCurrentRepresentative', {
            hrCurrentRepresentative: '',
        })
    },

    /**
     * 去单聊聊天页面
     * */
    async toChatWindow(sessionId, param, path, notTaken, currentPositionInformation) {
        let params = {
            name: '单聊',
            toUserId: sessionId,
            type: 1,
            jobInformation: ''
        }

        if (param && param.jobId && param.jobName) {
            params.jobInformation = JSON.stringify({
                jobId: param.jobId?Number(param.jobId):param.jobId,
                jobName: param.jobName,
            })
        }

        console.log('-----------------createConversation----------------', params)
        const res = await store.state.aonuoChat.createConversation(params)
        console.log('aonuoChat_session', res)
        if (res) {
            store.commit('setChatSessionId', {
                chatSessionId: res.session.session_id
            })
            store.commit('setChatSessionInfo', {
                chatSessionInfo: res.session.session_info,
            })
            store.commit('setChatSession', {
                chatSession: res.session,
            })
            if (store.state.userInfo.userType === 'hr' && store.state.automaticGreetingIsOpen && notTaken) {
                let text = this.getGreetingMessage(param.jobId, currentPositionInformation)
                let aonuoChat = store.state.aonuoChat
                let fileId = this.getFileId()
                let obj = {
                    fileId: fileId,
                    toSession: res.session.session_id,
                    text: text
                }
                let content = aonuoChat.createTextMessage(obj)
                aonuoChat.sendMessage(content)
            }

            if (!notTaken) {
                window.selfRouter(path, param)
            }
        }
    },

    /**
     * 生成唯一的 fileId
     * */
    getFileId() {
        let nowDate = new Date()
        let nowTime = nowDate.getTime()
        // let fileId = 'uploadFiling' + nowTime + '' + Math.round(Math.random()*100)
        let fileId = nowTime + '' + Math.round(Math.random()*100)
        return fileId
    },

    /**
    * 显示提示消息
    * */
    message: {
        success: (msg) => {
            Message.success({
                message: msg,
                duration: 1500,
                showClose: true,
            })
        },
        error: (msg) => {
            Message.error({
                message: msg,
                duration: 1500,
                showClose: true,
            })
        },
        warning: (msg) => {
            Message.warning({
                message: msg,
                duration: 1500,
                showClose: true,
            })
        },
        info: (msg) => {
            Message.info({
                message: msg,
                duration: 1500,
                showClose: true,
            })
        }
    },

    /**
     * 获取系统消息指定的页与实际的枚举关系
     * */
    getEnumeratePagePath(page, param) {
        let obj = {
            path: '',
            param: '',
        }
        enumeratePagePath.forEach((item, index) => {
            if (item.page === page) {
                if (item.page === 'jobManagement') {

                    if (param.jobType === '1' || param.jobType === 1) {
                        obj.path = '/redEnvelopeJobList'
                    }else {
                        obj.path = '/hotJobList'
                    }

                }else {
                    obj.path = item.path
                }
                if (item.page !=='jobManagement') {
                    obj.param = param
                }
            }
        })

        return obj
    },

    /**
     * 图片 base64 转为 file 文件
     * */
    base64TransformFile(base64Data, imgName) {
        return base64TransformFile(base64Data, imgName)
    },

    /**
     * 图片转换为 base64
     * */
    imageToBase64(img) {
        return new Promise((resolve) => {
            let reader = new FileReader();
            reader.readAsDataURL(img); //转化二进制流，异步方法
            reader.onload = (e) => {
                // console.log(e.target.result,'-----res')
                resolve(e.target.result) ;
            };
        });
    },

    /**
     * 替换掉字符串中的参数
     * */
    replacementStringArgument(str = '', obj = {}) {
        let newStr = str
        let keysArr = Object.keys(obj)
        keysArr.forEach((item, index) => {
            let reg = new RegExp('{' + item + '}', 'g')
            newStr = newStr.replace(reg, obj[item])
        })
        return newStr
    },

    /**
     * 获取常用语
     * */
    getGreetingMessage(jobId, currentPositionInformation) {
        let currentAutomaticGreetings = store.state.currentAutomaticGreetings  // 当前选中的打招呼语
        let automaticGreetingsForSpecificPositions = store.state.automaticGreetingsForSpecificPositions  // 针对特定岗位的问候语列表
        let greetingsList = store.state.greetingsList  // 常用问候语列表
        let text = ''
        if (jobId !== '' && jobId !== undefined) {
            try {
                automaticGreetingsForSpecificPositions.forEach((item, index) => {
                    if (item.id === jobId) {
                        text = item.message
                        throw new Error()
                    }
                })
            }catch (e) {
            }
            if (text === '') {
                try {
                    greetingsList.forEach((item, index) => {
                        if (item.id === currentAutomaticGreetings) {
                            text = item.message
                            throw new Error()
                        }
                    })
                }catch (e) {
                }
            }
        }
        return this.replacementStringArgument(text, currentPositionInformation)
    },
}
