<template>
  <div class="enterpriseInformation_warp">

    <div class="enterpriseInformation-left" v-if="!applyingJoinEnterprise">

      <div class="actions">
        <el-button type="text" @click="cancelJoin" v-if="isJoin">
          <img src="@/assets/icon/back.png">返回
        </el-button>
        <el-button type="text" @click="goEditEnterpriseInformation" v-if="!isJoin && isAdmin">
          <img src="@/assets/icon/edit.png">编辑
        </el-button>

        <div class="text" v-if="!enterpriseIsPass && !isJoin">
          审核中
        </div>

      </div>


      <div class="content">

        <div class="enterprise-information">

          <div class="enterprise-information-left">

            <img :src="newEnterpriseLogoUrl">

          </div>

          <div class="personal-information-right">

            <div class="name">
              {{ enterpriseData.enterprise_name }}
            </div>

            <div class="information">
              <div class="scale" v-if="enterpriseData.enterpriseSizeRange">
                {{enterpriseSizeRange}}
              </div>
              <div class="industry">
                {{ enterpriseData.industry }}
              </div>
            </div>

          </div>

        </div>

        <div class="company-profile card">

          <div class="title">
            企业介绍
          </div>

          <div class="main">

            <div class="text">
              {{ enterpriseData.enterpriseIntroduction }}
            </div>

          </div>

        </div>

        <div class="company-album">

          <div class="title">
            企业相册
          </div>

          <div class="main">
            <el-carousel trigger="click" height="300px">
              <el-carousel-item v-for="(item, index) in albumList" :key="'albumList' + index">
                <div class="img" @click="previewAlbumImg(item.src, item.type)">
                  <img v-if="item.type === 'photo'" :src="item.src"/>
                  <video width="100%" height="300" v-if="item.type === 'video'" :src="item.src">
                    您的浏览器不支持 video 标签。
                  </video>
                  <div v-if="item.type === 'video'" class="video-mask">
                    <img v-if="item.type === 'video'" class="play-icon" src="../../assets/icon/play-button.png">
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>

          <el-dialog title="相册" :visible.sync="previewAlbumImgIsShow" custom-class="previewAlbumImgDialog">
            <img v-if="previewType === 'photo'" width="100%" :src="previewAlbumImgUrl" alt="">
            <video v-if="previewType === 'video'" style="max-width: 100%;" controls autoplay :src="previewAlbumImgUrl">
              您的浏览器不支持 video 标签。
            </video>
          </el-dialog>

        </div>

        <div class="business-information card">

          <div class="title">
            工商信息
          </div>

          <div class="main">

            <div class="text-item">
              企业全称：{{ enterpriseData.enterprise_name }}
            </div>
            <div class="text-item">
              企业性质：{{ enterpriseData.enterpriseProperty }}
            </div>
            <div class="text-item">
              融资状态：{{ financingStatusStr }}
            </div>
            <div class="text-item" v-if="enterpriseData.enterpriseSizeRange">
              企业规模：{{ enterpriseSizeRange }}
            </div>
            <div class="text-item">
              企业行业：{{ enterpriseData.industry }}
            </div>
            <div class="text-item">
              法定代表人：{{ enterpriseData.legalPerson }}
            </div>
            <div class="text-item">
              注册资本：{{ enterpriseData.registeredCapital }}万
            </div>
            <!--            <div class="text-item">-->
            <!--              同一社会信用代码：{{enterpriseData.creditCode}}-->
            <!--            </div>-->
            <div class="text-item">
              成立日期：{{ enterpriseData.establishDate }}
            </div>
            <!--            <div class="text-item">-->
            <!--              经营范围：{{enterpriseData.businessScope}}-->
            <!--            </div>-->

          </div>

        </div>

        <div class="company-address card">

          <div class="title">
            企业地址
          </div>

          <div class="main">

            <div class="text" v-if="enterpriseData.enterpriseAddress">
              {{ enterpriseData.enterpriseAddress[0] }}{{ enterpriseData.enterpriseAddress[1] }}{{ enterpriseData.enterpriseAddress[2] }}{{ enterpriseData.detailAddress }}
            </div>

          </div>

        </div>

      </div>

      <div class="btn" v-if="isJoin">
        <el-button @click="cancelJoin">取 消</el-button>
        <el-button @click="determineJoin">申请加入</el-button>
      </div>

    </div>

    <div class="enterpriseInformation-left2" v-if="applyingJoinEnterprise" v-loading="hrJoinEnterpriseApplyListPageLoading">
      <div class="content">
        <div class="apply-join-item" v-for="(item, index) in hrJoinEnterpriseApplyList" :key="'apply-join-item' + index">
          <div class="left">
            <div class="time">
              {{item.recordInfo.create_time}}
            </div>
            <div class="title">
              {{item.enterpriseInfo.enterpriseName}}
            </div>
          </div>
          <div class="right">
            <el-button v-if="item.recordInfo.apply_status === '待确认'" @click="handleClickItem(item)">撤销申请</el-button>
          </div>
          <div class="status">
            {{item.recordInfo.apply_status}}
          </div>
        </div>

        <div class="empty" v-if="hrJoinEnterpriseApplyList.length <= 0">
          空
        </div>
      </div>
    </div>

    <div class="enterpriseInformation-right">

      <dynamic-entry @goEnterpriseDynamic="goEnterpriseDynamic"></dynamic-entry>

    </div>


    <el-dialog
        title="您还没有企业资料"
        :visible="enterpriseInformationIsNull"
        width="500px"
        custom-class="enterprise-information-dialog"
        :before-close="beforeClose"
    >
      <div class="content">
        <div class="join">
          <el-button @click="joinEnterprise">
            加入企业
          </el-button>
        </div>
        <div class="create">
          <el-button @click="createEnterprise">
            创建企业
          </el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="withdrawTheApplication"
        width="350px">
      <div style="text-align: center">
        <p style="font-size: 20px; font-weight: bold; color: #3a3a3a;">确定撤销此申请吗？</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="withdrawTheApplication = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="toWithdrawTheApplication">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import DynamicEntry from "@/components/dynamicEntry/dynamicEntry";
import {
  GetEnterpriseDetailApi,
  HrStatusApi,
  JoinEnterpriseApi,
  EnterpriseIsPassCheckApi,
  GetHrJoinEnterpriseApplyApi, PostHrJoinEnterpriseApplyApi
} from '@/request/api'
import {financingSituation, enterpriseScale} from '@/share/shareData'
import share from "@/share/share";

export default {
  name: "enterpriseInformation",
  components: {DynamicEntry},
  data() {
    return {
      enterpriseInformationIsNull: false,
      enterpriseId: '',
      applyingJoinEnterprise: false,
      isAdmin: false,
      albumList: [],
      enterpriseData: {},
      previewAlbumImgIsShow: false,
      previewType: 'photo',
      previewAlbumImgUrl: '',
      isJoin: false,
      enterpriseIsPass: true,
      withdrawTheApplication: false,
      showItemId: '',
      btnLoading: false,
      hrJoinEnterpriseApplyListPageLoading: false,
      hrJoinEnterpriseApplyList: [],
    }
  },
  computed: {
    financingStatusStr() {
      let str = ''
      financingSituation.forEach((item, index) => {
        if (item.value === this.enterpriseData.financingStatus) {
          str = item.label
        }
      })
      return str
    },
    enterpriseSizeRange() {
      let str = this.enterpriseData.enterpriseSizeRange[0] + '-' + this.enterpriseData.enterpriseSizeRange[1]
      let label = ''
      enterpriseScale.forEach((item, index) => {
        if (item.value === str) {
          label = item.label
        }
      })
      return label
    },
    newEnterpriseLogoUrl() {
      return share.initImageUrl(this.enterpriseData.logo)
    },
  },
  async mounted() {
    let isJoin = this.$route.query.isJoin
    this.enterpriseId = this.$route.query.id || this.$route.query.enterpriseId
    if (isJoin === true || isJoin === 'true') {
      this.isJoin = true
    } else {
      this.isJoin = false
    }
    if (this.isJoin) {
      this.enterpriseInformationIsNull = false
      await this.loadData(this.enterpriseId)
    } else {
      await this.loadHrStatus()
      if (this.enterpriseId) {
        await this.loadData(this.enterpriseId)
      }
    }
    if (!this.isJoin) {
      this.enterpriseIsPassCheck()
    }

  },
  methods: {
    async loadHrJoinEnterpriseApplyData() {
      this.hrJoinEnterpriseApplyListPageLoading = true
      const res = await GetHrJoinEnterpriseApplyApi({
        pageNum: 1,
        pageSize: 1000,
      })
      this.hrJoinEnterpriseApplyListPageLoading = false

      if (res) {
        this.hrJoinEnterpriseApplyList = res.recordInfoList
      }
    },
    async loadHrStatus() {
      const res = await HrStatusApi()

      if (res) {
        this.isAdmin = res.isAdmin
        this.applyingJoinEnterprise = res.applyingJoinEnterprise
        if (res.enterpriseId) {
          this.enterpriseInformationIsNull = false
          this.enterpriseId = res.enterpriseId
        } else {
          if (!this.applyingJoinEnterprise) {
            this.enterpriseInformationIsNull = true
          }else {
            this.loadHrJoinEnterpriseApplyData()
          }
        }

      }
    },
    async loadData(id) {
      const res2 = await GetEnterpriseDetailApi({
        enterpriseId: id
      })

      if (res2) {
        this.enterpriseData = res2.enterpriseInformation

        let enterpriseVideoList = res2.enterpriseInformation.enterpriseVideoList
        let enterprisePhotoList = res2.enterpriseInformation.enterprisePhotoList
        enterpriseVideoList = this.inAlbumList(enterpriseVideoList, 'video')
        enterprisePhotoList = this.inAlbumList(enterprisePhotoList, 'photo')
        this.albumList = enterprisePhotoList.concat(enterpriseVideoList)
      }
    },
    async enterpriseIsPassCheck(id) {
      const res = await EnterpriseIsPassCheckApi()
      if (res) {
        this.enterpriseIsPass = res.isPassCheck
      }
    },
    inAlbumList(arr, type) {
      let newArr = JSON.parse(JSON.stringify(arr))
      if (type === 'video') {
        newArr.forEach((item, index) => {
          item.src = item.video
          item.type = type
        })
      }
      if (type === 'photo') {
        newArr.forEach((item, index) => {
          item.src = item.photo
          item.type = type
        })
      }
      return newArr
    },
    beforeClose() {
      this.$nextTick(() => {
        // this.$router.push({
        //   path: '/chatSectionHomeHr',
        //   query: {
        //     currHeaderMenu: 2,
        //   }
        // })
        this.$router.push('/jobSeeker')
      })
    },
    joinEnterprise() {
      this.enterpriseInformationIsNull = false
      this.$router.push({
        path: '/searchEnterprise'
      })
    },
    createEnterprise() {
      this.enterpriseInformationIsNull = false
      this.$router.push({
        path: '/editEnterpriseInformation',
        query: {
          isCreate: 'true'
        }
      })
    },
    previewAlbumImg(url, type) {
      this.previewType = type
      this.previewAlbumImgUrl = url
      this.previewAlbumImgIsShow = true
    },
    goEditEnterpriseInformation() {
      this.$router.push({
        path: '/editEnterpriseInformation',
        query: {
          isCreate: 'false'
        }
      })
    },
    goEnterpriseDynamic(key, index) {
      this.$router.push({
        path: key,
        query: {
          index: index
        }
      })
    },
    cancelJoin() {
      this.$router.go(-1)
    },
    async determineJoin() {
      const res = await JoinEnterpriseApi({
        enterpriseId: this.enterpriseId,
      })

      if (res) {
        share.message.success('已提交申请，请等待审核通过的通知')
        await this.$router.push({
          path: '/chatSectionHomeHr',
        })
      }
    },
    handleClickItem(data) {
      this.showItemId = data.recordInfo.id
      this.withdrawTheApplication = true
    },
    async toWithdrawTheApplication() {
      this.btnLoading = true
      const res = await PostHrJoinEnterpriseApplyApi({
        id: this.showItemId
      })
      this.btnLoading = false
      this.withdrawTheApplication = false

      if (res) {
        share.message.success('撤销成功')
        this.loadHrJoinEnterpriseApplyData()
      }
    },

  }
}
</script>

<style lang="less" scoped>
.enterpriseInformation_warp {
  width: 100%;
  //height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #f4f2f3;

  .enterpriseInformation-left {
    width: 780px;
    //height: 100%;
    background-color: #ffffff;

    .actions {
      width: 100%;
      height: 40px;
      margin: 0 auto;
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #bfbfbf;

      .el-button {
        color: #707070;
        padding: 2px 10px;
        font-size: 18px;

        & /deep/ span {
          width: 100%;
          height: 25px;
          line-height: 25px;

          img {
            width: 25px;
            height: 25px;
            position: relative;
            top: 5px;
            margin-right: 5px;
          }
        }

      }

      .text {

      }

    }

    .content {
      width: 100%;
      padding: 30px 30px;
      box-sizing: border-box;

      .enterprise-information {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .enterprise-information-left {
          width: 120px;
          height: 120px;
          margin: 0 30px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .personal-information-right {
          flex: 1;

          .name {
            font-size: 22px;
            color: #1e1e1e;
            margin-bottom: 10px;
          }

          .information {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 16px;
            color: #343434;

            .scale {
              margin-right: 20px;
            }
          }
        }

      }

      .card {
        width: 100%;
        padding: 20px 30px;
        box-sizing: border-box;

        .title {
          width: 80px;
          font-size: 18px;
          font-weight: bold;
          color: #252525;
        }

        .main {
          padding-top: 10px;
          font-size: 16px;
          box-sizing: border-box;
          color: #5b5c5f;
        }

      }

      .company-profile {
        margin-top: 20px;
        width: 100%;
        background-color: #f9f9f9;


        .text {
          width: 100%;
          word-wrap: break-word;
          word-break: break-all;
          white-space: pre-wrap;
          text-indent: -4px;
        }

      }

      .company-album {
        margin-top: 20px;
        padding: 20px 30px;
        box-sizing: border-box;

        .title {
          width: 80px;
          font-size: 18px;
          font-weight: bold;
          color: #252525;
        }

        .main {
          width: 100%;
          margin-top: 10px;
          background-color: #e7e7e7;

          & /deep/ .img {
            width: 100%;
            height: 100%;
            cursor: zoom-in;
            text-align: center;
            position: relative;

            img {
              max-height: 100%;
              max-width: 100%;
            }

            .video-mask {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0px;
              left: 0px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #000000;
              opacity: 0.6;

              .play-icon {
                width: 50px;
                height: 50px;
              }

            }

          }

        }
      }

      .business-information {
        width: 100%;
        background-color: #f9f9f9;

        .main {
          .text-item {
            font-size: 16px;
            height: 25px;
            line-height: 25px;
          }
        }

      }

      .company-address {
        width: 100%;
        background-color: #f9f9f9;

        .main {
          .text {
            width: 100%;
            font-size: 16px;
            line-height: 25px;
            word-wrap: break-word;
            word-break: break-all;
          }
        }
      }

    }

    .btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .el-button {
        background-color: #e20000;
        border: 1px solid #e20000;
        color: #ffffff;
      }
    }

  }

  .enterpriseInformation-left2 {
    width: 780px;
    //height: 100%;
    background-color: #ffffff;
    .content {
      width: 100%;
      .apply-join-item {
        width: 100%;
        padding: 30px 30px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #efefef;
        position: relative;
        .left {
          flex: 1;
          .time {
            font-size: 14px;
          }
          .title {
            font-size: 22px;
            padding-top: 10px;
            box-sizing: border-box;
          }
        }
        .right {
          margin-left: 20px;
        }
        .status {
          width: 200px;
          text-align: right;
          padding: 2px 10px;
          box-sizing: border-box;
          position: absolute;
          right: 0;
          bottom: 0;
          font-size: 14px;
          background-color: #d3d3d3;
          color: #525252;
          border-top-left-radius: 40px;
        }
      }
      .empty {
        width: 100%;
        height: 630px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: bold;
        color: #e1e1e1;
      }
    }
  }

  .enterpriseInformation-right {
    flex: 1;
    //min-height: 600px;
    margin-left: 10px;
    background-color: #f4f2f3;
  }

}
</style>
<style lang="less">
.enterprise-information-dialog {
  .content {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .el-button {
        width: 100%;
        height: 100%;
        font-size: 18px;
        margin: 0 5px;
      }
    }
  }
}
</style>
