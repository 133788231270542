<template>
  <div class="personalInformation_warp">

    <div class="information-card" v-loading="pageLoading1">

      <div class="left">

        <div class="left-top">
          <el-avatar size="large" :src="profilePhoto"></el-avatar>
          <div class="text">
            <div>
              你好！{{username}}
            </div>
            <div>
              当前积分：{{coin}}分
            </div>
            <div @click="goSharePage">
              邀请好友
            </div>
          </div>
        </div>

        <div class="left-bottom" @click="goMyResume">
          <img src="@/assets/icon/resume.png">编辑在线简历
        </div>

      </div>

      <div class="right">

        <div class="right-top">

          <div class="dynamic-item" @click="goDynamic('4')">
            <div class="number">
              {{userStatistics.communicationCount}}
            </div>
            <div class="title">
              沟通过
            </div>
          </div>

          <div class="dynamic-item" @click="goDynamic('5')">
            <div class="number">
              {{userStatistics.getInterviewInvitationCount}}
            </div>
            <div class="title">
              面试邀请
            </div>
          </div>

          <div class="dynamic-item" @click="goDynamic('6')">
            <div class="number">
              {{userStatistics.collectCount}}
            </div>
            <div class="title">
              收藏
            </div>
          </div>

        </div>

        <div class="right-bottom">

          <div class="dynamic-item" @click="goDynamic('1')">
            <div class="title">
              看过我
            </div>
            <div class="number">
              {{userStatistics.seeMeCount}}
            </div>
          </div>

          <div class="dynamic-item" @click="goDynamic('2')">
            <div class="title">
              我看过
            </div>
            <div class="number">
              {{userStatistics.seeJobCount}}
            </div>
          </div>

          <div class="dynamic-item" @click="goDynamic('3')">
            <div class="title">
              对我感兴趣
            </div>
            <div class="number">
              {{userStatistics.collectMeCount}}
            </div>
          </div>

          <div class="dynamic-item" @click="goDynamic('7')">
            <div class="title">
              红包申请
            </div>
            <div class="number">
              {{userStatistics.applyRedPocketCount}}
            </div>
          </div>

        </div>

      </div>

    </div>

    <div class="content">

      <div class="search">

        <div class="title">
          为你优选
        </div>

        <div class="search-input">
          <search-input @searchSubmit="searchSubmit"></search-input>
        </div>

      </div>

      <div class="main">

        <div class="advertisement" v-if="isAdvertisement"></div>

        <div class="job-list" v-loading="pageLoading2">

          <div class="job-item" v-for="(item, index) in jonList" :key="'job-item' + index">
            <personal-information-job-item
                :job-type="item.jobInformation.jobType"
                :red-pocket-per-person="item.jobInformation.redPocketPerPerson"
                :salary-range="item.jobInformation.salaryRange"
                :job-name="item.jobInformation.jobName"
                :job-address="item.jobInformation.jobAddress"
                :job-welfare="item.jobInformation.jobWelfare"
                :experience-requirement-range="item.jobInformation.experienceRequirementRange"
                :academic-requirement="item.jobInformation.academicRequirement"
                :enterprise-name="item.enterpriseInformation.enterpriseName"
                :enterprise-size-range="item.enterpriseInformation.enterpriseSizeRange"
                :financing-status="item.enterpriseInformation.financingStatus"
                :industry="item.enterpriseInformation.industry"
                :hr-name="item.enterpriseInformation.hrName"
                :job-id="item.jobInformation.jobId"
                :enterprise-id="item.enterpriseInformation.enterpriseId"
                :hr-id="item.hrInformation.hrId"
                :online-status="item.hrInformation.onlineStatus"
                @clickLeft="clickLeft"
                @clickRight="clickRight"
                @clickCenter="(toSessionId) => clickCenter(toSessionId, item.jobInformation.jobId, item.jobInformation.jobName)"
            ></personal-information-job-item>
          </div>

          <div class="empty" v-if="jonList.length <= 0">
            空
          </div>

          <div class="change-batch" v-if="jonList.length > 0">
            <el-button @click="changeBatch">
              <div class="text">
                <img class="icon" src="@/assets/icon/change-batch.png">换一批
              </div>
            </el-button>
          </div>

        </div>

      </div>

    </div>

    <el-dialog
        title="提示"
        :visible.sync="toMyResumeDialogVisible"
        width="350px">
      <div style="text-align: center">
        <p style="font-size: 20px; font-weight: bold; color: #3a3a3a;">请先去完善简历</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="toMyResumeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="goMyResume">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import SearchInput from "@/components/searchInput/searchInput";
import PersonalInformationJobItem from "@/components/jobItem/personalInformationJobItem";
import {
  StatusStatisticsApi,
  GetWealthApi,
  SearchJobApi,
  PostEmployeeContactHrApi,
  isEditedResumeApi
} from '@/request/api'
import {mapMutations} from "vuex";
import share from "@/share/share";
export default {
  name: "personalInformation",
  components: {PersonalInformationJobItem, SearchInput},
  data() {
    return {
      pageLoading1: true,
      pageLoading2: true,
      isAdvertisement: false,  // 广告
      userStatistics: {
        getInterviewInvitationCount: 0,
        collectCount: 0,
        collectMeCount: 0,
        communicationCount: 0,
        seeJobCount: 0,
        seeMeCount: 0,
        applyRedPocketCount: 0,
      },
      coin: 0,
      pageSize: 5,
      pageNum: 1,
      numOfPages: 0,
      searchValue: '',

      jonList: [],

      toMyResumeDialogVisible: false,
    }
  },
  computed: {
    profilePhoto() {
      return this.$store.state.userInfo.profilePhoto
    },
    username() {
      return this.$store.state.userInfo.username
    },
    isEditedResume() {
      return this.$store.state.isEditedResume || ''
    },
    isLogin() {
      let token = this.$store.state.token
      let state = false
      if (token && token !== '' && token !== null && token !== 'null') {
        state = true
      }
      return state
    },
    place() {
      return this.$store.state.userInfo.place
    }
  },
  watch: {
    place() {
      this.loadData()
    }
  },
  mounted() {
    this.loadIsEditedResume()
    this.loadData()
  },
  methods: {
    ...mapMutations(['setCurrentSessionId', 'setIsEditedResume']),
    async loadIsEditedResume() {
      const res = await isEditedResumeApi()

      if (res) {
        this.setIsEditedResume({
          isEditedResume: res.isEditedResume
        })
      }
    },
    async loadData() {
      this.pageLoading1 = true
      const res = await StatusStatisticsApi()

      if (res) {
        this.userStatistics.getInterviewInvitationCount = res.statistics.GetInterviewInvitationCount || 0
        this.userStatistics.collectCount = res.statistics.collectCount || 0
        this.userStatistics.collectMeCount = res.statistics.collectMeCount || 0
        this.userStatistics.communicationCount = res.statistics.communicationCount || 0
        this.userStatistics.seeJobCount = res.statistics.seeJobCount || 0
        this.userStatistics.seeMeCount = res.statistics.seeMeCount || 0
        this.userStatistics.applyRedPocketCount = res.statistics.applyRedPocketCount || 0
      }
      await this.loadWealthData()
      this.pageLoading1 = false
      this.loadJobsData()
    },
    async loadWealthData() {
      const res2 = await GetWealthApi({
        area: this.place.value
      })

      if (res2) {
        this.coin = res2.coin || 0
      }
    },
    async loadJobsData() {
      this.pageLoading2 = true
      let jobType = [0, 1]
      let address = []
      if (this.place.value) {
        address.push(this.place.value)
      }

      const res3 = await SearchJobApi({
        jobType: JSON.stringify(jobType),
        address: JSON.stringify(address),
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        searchInput: this.searchValue,
      })

      if (res3){
        this.jonList = res3.jobs
        this.numOfPages = res3.numOfPages
      }
      this.pageLoading2 = false
    },
    goMyResume() {
      this.$router.push({
        path: '/myResume',
      })
    },
    goDynamic(index) {
      this.$router.push({
        path: '/personalDynamic',
        query: {
          index: index
        }
      })
    },
    changeBatch() {
      if (this.pageNum >= this.numOfPages) {
        this.pageNum = 1
      }else {
        this.pageNum++
      }
      this.loadJobsData()
    },
    searchSubmit(value) {
      this.searchValue = value
      this.pageNum = 1
      this.loadJobsData()
    },
    goSharePage() {
      this.$router.push({
        path: '/pointsCollection'
      })
    },
    clickLeft(id) {
      this.$router.push({
        path: '/jobDetailed',
        query: {
          id: id
        }
      })
    },
    clickRight(id) {
      this.$router.push({
        path: '/companyDetailed',
        query: {
          id: id
        }
      })
    },
    clickCenter(toSessionId, jobId, jobName) {

      if (!this.isLogin) {
        share.message.warning('请先去登录')
        return;
      }

      if (!this.isEditedResume) {
        this.toMyResumeDialogVisible = true
        return
      }

      PostEmployeeContactHrApi({
        jobId: jobId
      })

      share.toChatWindow(toSessionId, {jobId: jobId, jobName: jobName}, '/chatSectionHome')

    }

  }
}
</script>

<style lang="less" scoped>
.personalInformation_warp {
  width: 100%;
  //height: 100%;
  background-color: #f4f2f3;

  .information-card {
    width: 100%;
    height: 300px;
    padding: 40px 40px;
    box-sizing: border-box;
    background-image: url("../../assets/icon/personalInformationBg.png");
    background-repeat: no-repeat;
    background-position-x : center;
    background-size: 60% 60%;
    background-position-y : bottom;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 5px #cbcbcb;

    .left {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .left-top {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        & /deep/ .el-avatar--large {
          width: 150px;
          height: 150px;
          margin: 0 30px 0 0;
        }

        .text {
          color: #4f4f4f;
          >div:nth-child(1) {
            font-size: 35px;
            height: 60px;
            line-height: 60px;
          }
          >div:nth-child(2) {
            font-size: 16px;
            height: 40px;
            line-height: 40px;
          }
          >div:nth-child(3) {
            font-size: 16px;
            color: #e20000;
            text-decoration: underline;
            cursor: pointer;
          }
        }

      }

      .left-bottom {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #4f4f4f;
        font-size: 16px;
        line-height: 20px;
        cursor: pointer;
        margin-left: 5px;

        img {
          width: 30px;
          height: 30px;
          margin-right: 5px;
        }

      }

    }

    .right {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;

      .right-top {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .dynamic-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          font-size: 16px;
          margin: 0 15px;
          height: 45px;
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }

          .title {
            color: #424242;
          }

          .number {
            color: #303030;
          }

        }

      }

      .right-bottom {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .dynamic-item {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #c2c2c2;
          padding-bottom: 2px;
          margin-bottom: 10px;
          cursor: pointer;

          .title {
            color: #424242;
            margin-right: 60px;
          }

          .number {
            color: #303030;
          }

        }

      }

    }

  }

  .content {
    width: 100%;
    //height: 100%;

    .search {
      width: 100%;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-size: 16px;
        color: #e20000;
        border-bottom: 2px solid #e20000;
        height: 100%;
        margin-left: 10px;
        margin-bottom: 20px;
        display: flex;
        align-items: flex-end;
      }

      .search-input {
        width: 500px;
      }

    }

    .main {
      width: 100%;
      //height: 100%;
      display: flex;
      justify-content: space-between;

      .advertisement {
        width: 250px;
        background-color: #ffffff;
        margin-right: 5px;
      }

      .job-list {
        flex: 1;

        .empty {
          width: 100%;
          height: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          font-weight: bold;
          color: #e1e1e1;
        }

        .job-item {
          background-color: #ffffff;
          margin-bottom: 5px;
        }

        .change-batch {
          width: 100%;
          height: 60px;
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;

          & /deep/ .el-button {
            .text {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              .icon {
                width: 15px;
                height: 15px;
                margin-right: 5px;
              }

            }
          }

        }

      }

    }

  }

}
</style>
