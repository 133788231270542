import { render, staticRenderFns } from "./personalInformationJobItem.vue?vue&type=template&id=8bd05a74&scoped=true"
import script from "./personalInformationJobItem.vue?vue&type=script&lang=js"
export * from "./personalInformationJobItem.vue?vue&type=script&lang=js"
import style0 from "./personalInformationJobItem.vue?vue&type=style&index=0&id=8bd05a74&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bd05a74",
  null
  
)

export default component.exports