<template>
  <div class="promotionManagement_warp" v-loading="pageLoading">
    <div class="header">
      <el-tabs v-model="tabActiveName" type="card" @tab-click="tabHandleClick">
        <el-tab-pane label="推广管理" name="1">
        </el-tab-pane>
        <el-tab-pane label="推广报表" name="2">
        </el-tab-pane>
      </el-tabs>
      <div class="btn">
        <el-button type="text" @click="addJobPromotion">
          <span style="color: #e20000;">点击新增推广</span>
          <el-tooltip class="item" effect="light" placement="bottom-end">
            <div slot="content">岗位推广是佳薪职聘为招聘者提供的一款按点击效果计费的推广服务。<br/>
              开启推广后，会免费展示在岗位所属地区及类别/招聘者选择的地区及类别的列表上方，能受到更多求职者的关注；<br/>
              且只有获得推广效果（点击）才扣费；<br/>
              您还可以可根据需求灵活设置预算、推广范围，成本可控；.推广效果数据化，推广费用清晰化。
            </div>
            <i class="el-icon-question"></i>
          </el-tooltip>

        </el-button>
      </div>
    </div>
    <div class="content" v-if="tabActiveName === '1'">
      <div class="filter">
        <div class="select">
          <el-select v-model="promotionStatus" placeholder="请选择" @change="promotionStatusSelectChange">
            <el-option
                v-for="(item, index) in promotionStatusOptions"
                :key="'promotion' + index"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>

        <div class="input">
          <el-input placeholder="请输入内容" v-model="searchValue">
            <el-button type="" slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </div>
      </div>
      <div class="actions">
        <div class="actions-left">
          以下为今日数据 {{nowDateStr}}
          <el-button type="text" size="medium"><span style="color: #6bc8ff;" @click="toHistoricalOperationRecords">查看历史操作记录</span></el-button>
        </div>
        <div class="actions-right">
          <el-button type="text" size="medium" @click="promotionStatusBatchChange(2)"><span style="color: #e20000;">批量开启</span></el-button>
          <el-button type="text" size="medium" @click="promotionStatusBatchChange(1)"><span style="color: #e20000;">批量暂停</span></el-button>
          <el-button type="text" size="medium"><span style="color: #e20000;" @click="batchDeletePrompt">批量删除</span></el-button>
        </div>
      </div>
      <div class="table">
        <el-table
            :data="promotionTableData"
            @selection-change="promotionTableSelectionChange"
            v-loading="table1Loading"
            style="width: 1010px;"
        >
          <el-table-column
              type="selection"
              width="35">
          </el-table-column>
          <el-table-column
              prop="jobName"
              label="推广岗位"
              width="">
          </el-table-column>
          <el-table-column
              prop="promotionStatus"
              label="推广状态"
              width="120">
            <template slot-scope="scope">
              <div class="promotion-status" v-if="scope.row.promotionStatus === 1">
                岗位推广中
              </div>
              <div class="promotion-status" v-if="scope.row.promotionStatus === 2">
                推广暂停中
              </div>
              <div class="promotion-status" v-if="scope.row.promotionStatus === 3">
                <div class="desc">(每日预算不足)</div>
                <div>推广暂停中</div>
              </div>
              <div class="promotion-status" v-if="scope.row.promotionStatus === 4">
                <div class="desc">(推广币余额不足)</div>
                <div>推广暂停中</div>
              </div>
              <div class="promotion-status" v-if="scope.row.promotionStatus === 5">
                <div class="desc">(不在推广时间内)</div>
                <div>推广暂停中</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="promotionArea"
              label="推广地区">
            <template slot-scope="scope">
              <div v-if="scope.row.promotionArea && scope.row.promotionArea.length > 0 && scope.row.promotionArea.length <= 2">
                <span>{{scope.row.promotionArea.join('、')}}</span>
              </div>
              <el-tooltip :content="scope.row.promotionArea.join('、')" placement="bottom" effect="light"
                          v-if="scope.row.promotionArea && scope.row.promotionArea.length > 0 && scope.row.promotionArea.length > 2">
                <div v-if="scope.row.promotionArea && scope.row.promotionArea.length > 0 && scope.row.promotionArea.length > 2">
                  <span v-for="(item, index) in scope.row.promotionArea" :key="'area' + index" v-if="index <= 1">{{item}}<span v-if="index === 0">、</span></span>
                  <span>...</span>
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
              prop="promotionJobCategory"
              label="推广岗位类别"
              width="110">
            <template slot-scope="scope">
              <div v-if="scope.row.promotionJobCategory && scope.row.promotionJobCategory.length > 0 && scope.row.promotionJobCategory.length <= 2">
                <span>{{scope.row.promotionJobCategory.join('、')}}</span>
              </div>
              <el-tooltip :content="scope.row.promotionJobCategory.join('、')" placement="bottom" effect="light"
                          v-if="scope.row.promotionJobCategory && scope.row.promotionJobCategory.length > 0 && scope.row.promotionJobCategory.length > 2">
                <div v-if="scope.row.promotionJobCategory && scope.row.promotionJobCategory.length > 0 && scope.row.promotionJobCategory.length > 2">
                  <span v-for="(item, index) in scope.row.promotionJobCategory" :key="'job' + index" v-if="index <= 1">{{item}}<span v-if="index === 0">、</span></span>
                  <span>...</span>
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
              prop="endDate"
              label="结束日期"
              width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.endDate === ''">
                持续推广
              </div>
              <div v-if="scope.row.endDate !== ''">
                {{scope.row.endDate}}
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="dailyBudget"
              label="单日预算">
          </el-table-column>
          <el-table-column
              prop="actualConsumption"
              label="实际消费">
          </el-table-column>
          <el-table-column
              prop="impressions"
              label="展示量">
          </el-table-column>
          <el-table-column
              prop="clicks"
              label="点击量">
          </el-table-column>
          <el-table-column
              prop="averagePrice"
              label="平均价格">
            <template slot-scope="scope">
              <div>
                <span>{{scope.row.averagePrice}} 元</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="interestedInMe"
              label="对我感兴趣"
              width="90">
          </el-table-column>
          <el-table-column
              prop="activeDelivery"
              label="主动投递">
          </el-table-column>
          <el-table-column
              label="操作"
              fixed="right"
              width="90">
            <template slot-scope="scope">
              <div>
                <el-button type="text" size="small" @click="toPromoteDetails(scope.row.id, scope.row)"><span style="color: #e20000;">详情</span></el-button>
                <el-button type="text" size="small" @click="toModifyPromote(scope.row.id, scope.row)"><span style="color: #e20000;">修改</span></el-button>
              </div>
              <div>
                <el-button type="text" size="small"
                           v-if="scope.row.promotionStatus !== 2"
                           @click="promotionStatusChange(scope.row.id, scope.row.promotionStatus, scope.row)">
                  <span style="color: #e20000;">暂停</span>
                </el-button>
                <el-button type="text" size="small"
                           v-if="scope.row.promotionStatus === 2"
                           @click="promotionStatusChange(scope.row.id, scope.row.promotionStatus, scope.row)">
                  <span style="color: #e20000;">恢复</span>
                </el-button>
                <el-button type="text" size="small"><span style="color: #e20000;" @click="deletePrompt(scope.row.id, scope.row.promotionStatus, scope.row)">删除</span></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination" v-if="promotionTableData.length > 0">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page.currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="page.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="page.total">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="content" v-if="tabActiveName === '2'">
      <div class="filter">
        <div class="filter-left">
          <div class="select">
            <el-select v-model="selectJob" placeholder="请选择" @change="selectJobChange">
              <el-option
                  v-for="(item, index) in jobsOptions"
                  :key="'jobs' + index"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="date">
            <el-date-picker
                v-model="dataRange"
                type="daterange"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :clearable="false"
                :picker-options="pickerOptions"
                @change="dataRangeChange">
            </el-date-picker>
          </div>
        </div>

        <div class="filter-right">
          <download-excel
              class = "export-excel-wrapper"
              :data = "json_data"
              :fields = "json_fields"
              name = "推广报表导出.xls">
            <el-button>导出数据</el-button>
          </download-excel>

        </div>
      </div>
      <div class="table">
        <el-table
            :data="reportData"
            v-loading="table2Loading"
            style="width: 1010px;"
            stripe>
          <el-table-column
              prop="date"
              label="展示时间"
              width="">
          </el-table-column>
          <el-table-column
              prop="totalCost"
              label="总消费">
          </el-table-column>
          <el-table-column
              prop="discount"
              label="优惠">
          </el-table-column>
          <el-table-column
              prop="actualCost"
              label="实际消费">
          </el-table-column>
          <el-table-column
              prop="displayNum"
              label="展示量">
          </el-table-column>
          <el-table-column
              prop="clickNum"
              label="点击量">
          </el-table-column>
          <el-table-column
              prop="collectNum"
              label="对我感兴趣">
          </el-table-column>
          <el-table-column
              prop="postNum"
              label="主动投递">
          </el-table-column>
          <el-table-column
              prop="clickRate"
              label="点击率">
            <template slot-scope="scope">
              <div v-if="scope.row.clickRate !== undefined">
                <span>{{(parseFloat(scope.row.clickRate) * 100).toFixed(2) + '%'}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="postRate"
              label="投递率">
            <template slot-scope="scope">
              <div v-if="scope.row.postRate !== undefined">
                <span>{{(parseFloat(scope.row.postRate) * 100).toFixed(2) + '%'}}</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog
        title="提示"
        :visible.sync="deletePromotionDialogVisible"
        width="400px"
    >
      <span>确定要删除{{deletePromotionIdArr.length}}条推广吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deletePromotionDialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="deletePromotionDialogSubmit">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {
  GetGobPromotePromoteStatisticsApi,
  GetJobPromoteJobPromoteListApi,
  MyPublishedJobApi,
  PostJobPromoteCloseJobPromoteApi, PostJobPromoteDeleteJobPromoteApi,
  PostJobPromoteOpenJobPromoteApi
} from "@/request/api";
import share from "@/share/share";
import JsonExcel from 'vue-json-excel';

export default {
  name: "promotionManagement",
  components: {'download-excel': JsonExcel},
  data () {
    return {
      pageLoading: false,
      table1Loading: false,
      table2Loading: false,
      tabActiveName: '1',
      promotionStatus: '',
      promotionStatusOptions: [
        {
          label: '全部推广状态',
          value: ''
        },
        {
          label: '推广中状态',
          value: 1
        },
        {
          label: '暂停中状态',
          value: 2
        },
      ],
      searchValue: '',
      promotionTableData: [],
      promotionTableSelected: [],
      page: {
        currentPage: 1,
        total: 0,
        pageSize: 10,
      },
      jobPromoteInfoList: [],
      selectJob: '',
      jobsOptions: [],
      dataRange: [],
      reportData: [],
      json_data: [],
      json_fields: {},
      deletePromotionDialogVisible: false,
      deletePromotionIdArr: [],
      btnLoading: false,
      startDate: null,
      pickerOptions: {},
      nowDateStr: '',
    }
  },
  async mounted() {
    let date = new Date()
    this.nowDateStr = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    this.pageLoading = true
    await this.getJobPromoteJobPromoteList()
    // await this.loadMyPublishedJobData()
    // if (this.tabActiveName === '1') {
    //   await this.getJobPromoteJobPromoteList()
    // }
    this.initDataRange()
    this.pageLoading = false
  },
  methods: {
    initDataRange() {
      if (this.selectJob !== undefined && this.selectJob !== '') {
        let jobPromoteInfo = null
        this.jobPromoteInfoList.forEach((item, index) => {
          if (item.id === this.selectJob) {
            jobPromoteInfo = item
          }
        })
        if (jobPromoteInfo === null) {
          return
        }
        let nowDate = new Date()
        let endDateStr = nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getDate()
        this.startDate = new Date(jobPromoteInfo.createTime)
        let startDateStr = this.startDate.getFullYear() + '-' + (this.startDate.getMonth() + 1) + '-' + this.startDate.getDate()
        this.pickerOptions.disabledDate = (time) => {
          return time.getTime() < this.startDate.getTime() - 1 * 24 * 60 * 60 * 1000
        }
        this.dataRange = [startDateStr, endDateStr]
      }else {
        this.pickerOptions = {}
        this.dataRange = []
      }
    },
    /**
    * 获取推广列表
    * */
    async getJobPromoteJobPromoteList() {
      const res = await GetJobPromoteJobPromoteListApi({
        pageNum: this.page.currentPage,
        pageSize: this.page.pageSize,
        filterStatus: this.promotionStatus,
        filterJobName: this.searchValue,
      })

      if (res) {
        this.jobPromoteInfoList = res.jobPromoteInfoList
        let arr = []
        let jobsOptions = []
        res.jobPromoteInfoList.forEach((item, index) => {
          let dailyBudget = 0
          if (item.dailyBudget) {
            dailyBudget = parseFloat(item.dailyBudget)
          }
          let tableRowData = {
            id: item.id,
            jobName: item.job.name,
            promotionStatus: item.status,
            promotionArea: item.promoteAreaLabelList,  // 推广地区
            promotionJobCategory: item.promoteCategory,  // 推广岗位类别
            endDate: item.endDate,  // 结束日期
            dailyBudget: dailyBudget,  // 单日预算
            actualConsumption: item.statistics.actual_cost,  // 实际消费
            impressions: item.statistics.display_num,  // 展示量
            clicks: item.statistics.click_num,  // 点击量
            averagePrice: item.statistics.average_price,  // 平均价格
            interestedInMe: item.statistics.collect_num,  // 对我感兴趣
            activeDelivery: item.statistics.post_num,  // 主动投递
            createTime: item.createTime,
            abnormalType: item.abnormalType,  // 推广异常类型, 0:正常,1:企业信息异常,2:岗位信息异常
          }
          arr.push(tableRowData)
          jobsOptions.push({
            label: item.job.name,
            value: item.id,
          })
        })

        this.page.total = res.totalNum
        this.promotionTableData = arr

        this.jobsOptions = jobsOptions
        if (jobsOptions.length > 0) {
          this.selectJob = jobsOptions[0].value
        }

      }
      return res
    },
    /**
    * 推广数据统计
    * */
    async getGobPromotePromoteStatistics() {
      let startDate = '', endDate = '';
      if (this.dataRange.length > 1) {
        startDate = this.dataRange[0]
        endDate = this.dataRange[1]
      }

      const res = await GetGobPromotePromoteStatisticsApi({
        jobPromoteId: this.selectJob,
        startDate: startDate,
        endDate: endDate,
      })

      if (res) {
        let reportData = []
        let json_fields = {
          '日期': 'date',
          '总消费': 'totalCost',
          '优惠': 'discount',
          '实际消费': 'actualCost',
          '展示量': 'displayNum',
          '点击量': 'clickNum',
          '对我感兴趣': 'clickNum',
          '主动投递': 'postNum',
          '点击率': 'clickRate',
          '投递率': 'postRate',
        }
        let json_data = []
        res.promoteStatisticsList.forEach((item, index) => {
          let obj = {
            date: item.date,  // 日期
            totalCost: item.data.total_cost,  // 总消费
            discount: item.data.discount,  // 优惠
            actualCost: item.data.actual_cost,  // 实际消费
            displayNum: item.data.display_num,  // 展示量
            clickNum: item.data.click_num,  // 点击量
            collectNum: item.data.collect_num,  // 对我感兴趣
            postNum: item.data.post_num,  // 主动投递
            clickRate: item.data.click_rate,  // 点击率
            postRate: item.data.post_rate,  // 投递率
          }
          reportData.push(obj)
          json_data.push(obj)
        })
        this.reportData = reportData

        this.json_fields = json_fields
        this.json_data = json_data
      }
    },
    async tabHandleClick() {
      if (this.tabActiveName === '1') {
        this.table1Loading = true
        await this.getJobPromoteJobPromoteList()
        this.table1Loading = false
      }
      if (this.tabActiveName === '2') {
        this.table2Loading = true
        await this.getGobPromotePromoteStatistics()
        this.table2Loading = false
      }
      this.initDataRange()
    },
    async selectJobChange() {
      this.table2Loading = true
      this.initDataRange()
      await this.getGobPromotePromoteStatistics()
      this.table2Loading = false
    },
    async dataRangeChange() {
      this.table2Loading = true
      await this.getGobPromotePromoteStatistics()
      this.table2Loading = false
    },
    addJobPromotion() {
      this.$router.push({
        path: '/newJobPromotion',
      })
    },
    async promotionStatusSelectChange() {
      this.table1Loading = true
      await this.getJobPromoteJobPromoteList()
      this.table1Loading = false
    },
    async search() {
      this.table1Loading = true
      await this.getJobPromoteJobPromoteList()
      this.table1Loading = false
    },
    promotionTableSelectionChange(value) {
      this.promotionTableSelected = value
    },
    async handleSizeChange(num) {
      this.page.pageSize = num
      this.table1Loading = true
      await this.getJobPromoteJobPromoteList()
      this.table1Loading = false
    },
    async handleCurrentChange(num) {
      this.page.currentPage = num
      this.table1Loading = true
      await this.getJobPromoteJobPromoteList()
      this.table1Loading = false
    },
    toPromoteDetails(id, data) {
      // this.$router.push({
      //   path: '/promoteDetails',
      //   query: {
      //     jobPromoteId: id
      //   }
      // })
      // if (data.abnormalType === 1) {
      //   share.message.error('您的企业信息异常')
      //   return;
      // }
      // if (data.abnormalType === 2) {
      //   share.message.error('该岗位处于关闭中，请先开放岗位')
      //   return;
      // }
      this.tabActiveName = '2'
      this.selectJob = id
      this.selectJobChange()
    },
    toModifyPromote(id, data) {
      if (data.abnormalType === 1) {
        share.message.error('您的企业信息异常')
        return;
      }
      if (data.abnormalType === 2) {
        share.message.error('该岗位处于关闭中，请先开放岗位')
        return;
      }
      this.$router.push({
        path: '/newJobPromotion',
        query: {
          isModifyStatus: 'yes',
          jobPromoteId: id
        }
      })
    },
    /**
    * 推广状态改变
    * */
    async promotionStatusChange(jobPromoteId, promotionStatus, data) {
      if (data.abnormalType === 1) {
        share.message.error('您的企业信息异常')
        return;
      }
      if (data.abnormalType === 2) {
        share.message.error('该岗位处于关闭中，请先开放岗位')
        return;
      }

      this.table1Loading = true
      let arr = [jobPromoteId]
      let res = null
      if (promotionStatus === 2) {
        res = await PostJobPromoteOpenJobPromoteApi({
          jobPromoteIdList: JSON.stringify(arr)
        })
      }
      if (promotionStatus !== 2) {
        res = await PostJobPromoteCloseJobPromoteApi({
          jobPromoteIdList: JSON.stringify(arr)
        })
      }
      this.table1Loading = false
      if (res) {
        share.message.success('操作成功')
      }
      this.getJobPromoteJobPromoteList()
    },
    /**
    * 推广状态批量改变
    * */
    async promotionStatusBatchChange(promotionStatus) {
      if (this.promotionTableSelected.length <= 0) {
        share.message.warning('请至少选择一条记录')
        return
      }

      let arr = []
      this.promotionTableSelected.forEach((item, index) => {
        arr.push(item.id)
      })
      this.table1Loading = true
      let res = null
      if (promotionStatus === 2) {
        res = await PostJobPromoteOpenJobPromoteApi({
          jobPromoteIdList: JSON.stringify(arr)
        })
      }
      if (promotionStatus === 1) {
        res = await PostJobPromoteCloseJobPromoteApi({
          jobPromoteIdList: JSON.stringify(arr)
        })
      }
      this.table1Loading = false
      if (res) {
        share.message.success('操作成功')
      }
      this.getJobPromoteJobPromoteList()
    },
    /**
     * 删除推广
     * */
    async deletePrompt(jobPromoteId, promotionStatus, data) {
      this.deletePromotionIdArr = [jobPromoteId]
      this.deletePromotionDialogVisible = true
    },
    /**
    * 确定删除推广
    * */
    async deletePromotionDialogSubmit() {
      this.table1Loading = true
      this.btnLoading = true
      const res = await PostJobPromoteDeleteJobPromoteApi({
        jobPromoteIdList: JSON.stringify(this.deletePromotionIdArr)
      })
      this.table1Loading = false
      this.btnLoading = false
      this.deletePromotionDialogVisible = false
      if (res) {
        share.message.success('删除成功')
      }
      this.getJobPromoteJobPromoteList()
    },
    /**
     * 批量删除推广
     * */
    async batchDeletePrompt() {
      if (this.promotionTableSelected.length <= 0) {
        share.message.warning('请至少选择一条记录')
        return
      }
      let arr = []
      this.promotionTableSelected.forEach((item, index) => {
        arr.push(item.id)
      })
      this.deletePromotionIdArr = arr
      this.deletePromotionDialogVisible = true
    },
    toHistoricalOperationRecords() {
      this.$router.push({
        path: '/historicalOperationRecords',
      })
    },
  }
}
</script>

<style lang="less" scoped>
.promotionManagement_warp {
  width: 100%;
  .header {
    width: 100%;
    position: relative;
    .btn {
      position: absolute;
      right: 20px;
      top: 0px;
      .el-icon-question {
        margin-left: 6px;
        position: relative;
        top: -4px;
      }
    }
  }
  .content {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;

    .filter {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .filter-left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .select {
          margin-right: 20px;
        }
      }
    }

    .actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .actions-left {
        font-size: 14px;
        .el-button {
          margin-left: 20px;
        }
      }
      .actions-right {

      }
    }

    .table {
      width: 100%;

      .promotion-status {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .desc {
          font-size: 12px;
        }
      }

      .pagination {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
