<template>
  <div class="promoteDetails_warp">
    <div class="header">
      <div class="header-left">
        昨日数据
      </div>
      <div class="header-right">
        数据更新至：{{yesterday}}
      </div>
    </div>
    <div class="content">
      <div class="yesterday-data">
        <div class="item">
          <div class="desc">
            获得浏览量
          </div>
          <div class="num">
            {{clickNum}}
          </div>
        </div>
        <div class="item">
          <div class="desc">
            获得简历量
          </div>
          <div class="num">
            {{postNum}}
          </div>
        </div>
        <div class="item">
          <div class="desc">
            在线沟通量
          </div>
          <div class="num">
            {{communicationNum}}
          </div>
        </div>
      </div>
      <div class="main">
        <div class="table">
          <div class="filter">
            <el-date-picker
                v-model="dataRange"
                type="daterange"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份"
                @change="dataRangeChange">
            </el-date-picker>
          </div>
          <div id="myChart" class="chart" v-loading="myChartLoading">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {GetGobPromotePromoteStatisticsApi} from "@/request/api";

export default {
  name: "promoteDetails",

  data() {
    return {
      myChartLoading: false,
      tabsActiveName: '1',
      jobPromoteId: '',
      yesterday: '',
      dataRange: [],
      communicationNum: 0,  // 在线沟通量
      postNum: 0,  // 获得简历量
      clickNum: 0,  // 获得浏览量
      chartOption: {  // eChart的Options
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['获得浏览量', '获得简历量', '在线沟通量']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            key: 'clickNum',
            name: '获得浏览量',
            type: 'line',
            data: []
          },
          {
            key: 'postNum',
            name: '获得简历量',
            type: 'line',
            data: []
          },
          {
            key: 'communicationNum',
            name: '在线沟通量',
            type: 'line',
            data: []
          },
        ]
      },
    }
  },
  async mounted() {
    this.myChartLoading = true

    if (this.$route.query.jobPromoteId) {
      this.jobPromoteId = this.$route.query.jobPromoteId
    }

    let nowDate = new Date()
    let endDateStr = nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getDate()
    let startDate = new Date(nowDate.setDate(nowDate.getDate() - 7))
    let startDateStr = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate()
    this.dataRange = [startDateStr, endDateStr]
    let nowDate2 = new Date()
    let yesterday = new Date(nowDate2.setDate(nowDate2.getDate() - 1))
    this.yesterday = yesterday.getFullYear() + '-' + (yesterday.getMonth() + 1) + '-' + yesterday.getDate()

    await this.loadData()
    // await this.loadYesterdayData()
    // await this.loadPeriodDateData()

    setTimeout(() => {
      this.myChartLoading = false
      this.drawLine()
    }, 500)
  },
  methods: {
    async loadData() {
      const res = Promise.all([this.loadYesterdayData(), this.loadPeriodDateData()])

    },
    /**
    * 获取昨日数据
    * */
    async loadYesterdayData() {
      const res = await this.getGobPromotePromoteStatistics([this.yesterday, this.yesterday])

      if (res && res.promoteStatisticsList[0]) {
        this.communicationNum = res.promoteStatisticsList[0].data.communication_num  // 在线沟通量
        this.postNum = res.promoteStatisticsList[0].data.post_num  // 获得简历量
        this.clickNum = res.promoteStatisticsList[0].data.click_num  // 获得简历量
      }
      return res
    },
    /**
    * 获取指定期间内的数据
    * */
    async loadPeriodDateData() {
      const res = await this.getGobPromotePromoteStatistics(this.dataRange)

      if (res) {
        let [xAxisDataArr, postNumArr, clickNumArr, communicationNumArr] = [[], [], [], []]
        res.promoteStatisticsList.forEach((item, index) => {
          xAxisDataArr.push(item.date)
          postNumArr.push(item.data.post_num)
          clickNumArr.push(item.data.click_num)
          communicationNumArr.push(item.data.communication_num)
        })

        this.chartOption.xAxis.data = xAxisDataArr
        this.chartOption.series[0].data = clickNumArr
        this.chartOption.series[1].data = postNumArr
        this.chartOption.series[2].data = communicationNumArr
      }
      return res
    },
    /**
     * 推广数据统计
     * */
    async getGobPromotePromoteStatistics(dataRange) {
      const res = await GetGobPromotePromoteStatisticsApi({
        jobPromoteId: this.jobPromoteId,
        startDate: dataRange[0],
        endDate: dataRange[1],
      })

      return res
    },
    async dataRangeChange() {
      this.myChartLoading = true
      await this.loadPeriodDateData()
      this.$nextTick(() => {
        this.myChartLoading = false
        this.drawLine()
      })
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      // 绘制图表
      myChart.setOption(this.chartOption)
    },
  }
}
</script>

<style lang="less" scoped>
.promoteDetails_warp {
  background-color: #f5f5fa;
  padding: 20px 20px;
  box-sizing: border-box;

  .header {
    border-bottom: 1px solid #f4f4f4;
    background-color: #FFFFFF;
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-left {
      font-size: 16px;
      font-weight: bold;
    }

    .header-right {
      font-size: 12px;
    }
  }

  .content {
    width: 100%;

    .yesterday-data {
      width: 100%;
      background-color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .item {
        flex: 1;
        padding: 40px 0 30px 0;
        box-sizing: border-box;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        .desc {
          font-size: 12px;
        }
        .num {
          font-size: 28px;
          font-weight: bold;
          margin-left: 5px;
        }
      }
    }

    .main {
      width: 100%;
      margin-top: 20px;
      background-color: #FFFFFF;
      .tabs {
        padding: 0 20px;
        box-sizing: border-box;
      }
      .table {
        width: 100%;
        padding: 0px 20px 20px 20px;
        box-sizing: border-box;
        .filter {
          width: 100%;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        .chart {
          width: 100%;
          height: 450px;
        }
      }
    }

  }
}
</style>
