/*
* 手机号正则表达式
* */
export const phoneRegular = /^1[3456789]\d{9}$/

/*
* 邮箱正则表达式
* */
export const emailRegular = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/

/*
* 正整数正则表达式
* */
export const intRegular =  /^[1-9]\d*$/

/*
* 删除左右两边空格正则表达式
* */
export const trimRegular = /(^\s*)|(\s*$)/g