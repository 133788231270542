<template>
  <div class="dynamicEntry_warp">

    <div class="card" v-loading="pageLoading">

      <div class="right-top">
        <el-avatar size="large" :src="profilePhoto"></el-avatar>
        <span class="username">
          <div class="vip-icon" v-if="isVip">
            <el-tooltip effect="light" :content="vipInformation" placement="left">
              <img src="@/assets/icon/vip.png">
            </el-tooltip>
          </div>
          {{username}}
        </span>
        <el-button size="mini" @click="goPostJob">发布新岗位</el-button>

      </div>

      <div class="right-bottom">

        <div class="dynamic-item" @click="goEnterpriseDynamic('/hotJobList')">
          <div class="number">
            {{statistics.normalJobNum}}
          </div>
          <div class="title">
            普通岗位
          </div>
        </div>

        <div class="dynamic-item" @click="goEnterpriseDynamic('/redEnvelopeJobList')">
          <div class="number">
            {{statistics.redPocketJobNum}}
          </div>
          <div class="title">
            红包岗位
          </div>
        </div>

      </div>

    </div>

    <div class="card">
      <account-card></account-card>
    </div>

    <div class="card">

      <div class="dynamic-item" @click="goEnterpriseDynamic('/enterpriseDynamic', '1')">
        <div class="title">
          看过我
        </div>
        <div class="number">
          {{statistics.seeMeCount}}
        </div>
      </div>

      <div class="dynamic-item" @click="goEnterpriseDynamic('/enterpriseDynamic', '2')">
        <div class="title">
          我看过
        </div>
        <div class="number">
          {{statistics.seeEmployeeCount}}
        </div>
      </div>

      <div class="dynamic-item" @click="goEnterpriseDynamic('/enterpriseDynamic', '3')">
        <div class="title">
          对我感兴趣
        </div>
        <div class="number">
          {{statistics.collectMeCount}}
        </div>
      </div>

      <div class="dynamic-item" @click="goEnterpriseDynamic('enterpriseDynamic', '7')">
        <div class="title">
          沟通过
        </div>
        <div class="number">
          {{statistics.communicationCount}}
        </div>
      </div>

      <div class="dynamic-item" @click="goEnterpriseDynamic('/enterpriseDynamic', '4')">
        <div class="title">
          面试邀请
        </div>
        <div class="number">
          {{statistics.interviewInvitationCount}}
        </div>
      </div>

      <div class="dynamic-item" @click="goEnterpriseDynamic('/enterpriseDynamic', '5')">
        <div class="title">
          收藏
        </div>
        <div class="number">
          {{statistics.collectCount}}
        </div>
      </div>

      <div class="dynamic-item" @click="goEnterpriseDynamic('/enterpriseDynamic', '6')">
        <div class="title">
          红包申请
        </div>
        <div class="number">
          {{statistics.redPocketApplyCount}}
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import AccountCard from "@/components/accountCard/accountCard";
let defaultProfilePhoto = require('@/assets/icon/profile-photo.png')
import {GetHrGetHrVipStatusApi, HrStatusStatisticsApi} from '@/request/api'
export default {
  name: "dynamicEntry",
  components: {AccountCard},
  props: {
    onPostJobPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pageLoading: false,
      statistics: {},
      isVip: false,
      vipInformation: '',
      remainDays: 0,  // 剩余有效期天数
      remainJobNum: 0,  // 剩余可用在线岗位数
      remainActiveCommunicationTimes: 0,  // 今日剩余可主动沟通数
    }
  },
  computed: {
    isLogin() {
      let token = this.$store.state.token
      let state = false
      if (token && token !== '' && token !== null && token !== 'null') {
        state = true
      }else {
        state = false
      }
      return state
    },
    username() {
      return this.$store.state.userInfo.username || ''
    },
    profilePhoto() {
      return this.$store.state.userInfo.profilePhoto || defaultProfilePhoto
    }
  },
  async mounted() {
    this.pageLoading = true
    await this.loadData()
    this.pageLoading = false
  },
  methods: {
    async loadData() {
      await Promise.all([this.getHrStatusStatistics(), this.getHrGetHrVipStatus()])
    },
    async getHrStatusStatistics() {
      const res = await HrStatusStatisticsApi()

      if (res) {
        this.statistics = res.statistics
      }
    },
    async getHrGetHrVipStatus() {
      const res = await GetHrGetHrVipStatusApi()

      if (res) {
        this.isVip = res.isVip  // 是否是会员
        this.remainDays = res.remainDays  // 剩余有效期天数
        this.remainJobNum = res.remainJobNum  // 剩余可用在线岗位数
        this.remainActiveCommunicationTimes = res.remainActiveCommunicationTimes  // 今日剩余可主动沟通数
        this.vipInformation = `当前会员剩余天数${this.remainDays}天，剩余可在线岗位数量${this.remainJobNum}个，今日剩余主动沟通数量${this.remainActiveCommunicationTimes}次`
      }
    },
    goEnterpriseDynamic(key, index) {
      this.$emit('goEnterpriseDynamic', key, index)
    },
    goPostJob() {
      if (this.onPostJobPage) {
        this.$emit('goPostJob')
      }else {
        this.$router.push({
          path: '/postJob'
        })
      }
    },
  }
}
</script>

<style lang="less" scoped>
.dynamicEntry_warp {
  width: 100%;
  height: 100%;


  .card {
    background-color: #ffffff;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:first-child {
      padding: 40px 40px;
      box-sizing: border-box;
      font-size: 14px;

      .right-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        & /deep/ .el-avatar--large {
          width: 100px;
          height: 100px;
          margin-bottom: 10px;
        }

        & /deep/ .el-button {
          color: #ffffff;
          background-color: #e20000;
          margin: 10px 0px 20px 0px;
        }

        .username {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .vip-icon {
          width: 25px;
          height: 25px;
          margin-right: 5px;
          cursor: pointer;
          display: inline-block;

          img {
            width: 100%;
            height: 100%;
          }
        }


      }

      .right-bottom {
        display: flex;
        align-items: center;
        justify-content: center;

        .dynamic-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 15px;
          cursor: pointer;

          .number {
            margin-bottom: 5px;
            color: #303030;
          }

          .title {
            color: #b5b5b5;
          }

          &:last-child {
            margin-right: 0px;
          }

        }

      }
    }

    &:last-child {
      font-size: 14px;
      padding: 30px 30px;
      box-sizing: border-box;

      .dynamic-item {
        width: 150px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #dcdcdc;
        margin-bottom: 15px;
        cursor: pointer;

        .title {
          color: #b5b5b5;
        }

        .number {
          color: #303030;
        }

      }

    }

  }
}
</style>
