<template>
  <div class="recharge_warp">
    <div class="title">
      余额充值
    </div>
    <div class="content">
      <div class="content-title">
        充值数量
      </div>
      <div class="main">

        <el-row :gutter="20">

          <el-col :span="6">
            <div :class="checkedId === 1?'main-item main-item-checked':'main-item'" @click="itemChecked(1, 100)">
              <span>100 RMB</span>
            </div>
          </el-col>

          <el-col :span="6">
            <div :class="checkedId === 2?'main-item main-item-checked':'main-item'" @click="itemChecked(2, 200)">
              <span>200 RMB</span>
            </div>
          </el-col>

          <el-col :span="6">
            <div :class="checkedId === 3?'main-item main-item-checked':'main-item'" @click="itemChecked(3, 500)">
              <span>500 RMB</span>
            </div>
          </el-col>

          <el-col :span="6">
            <div :class="checkedId === 4?'main-item main-item-checked':'main-item'" @click="itemChecked(4, 800)">
              <span>800 RMB</span>
            </div>
          </el-col>

          <el-col :span="6">
            <div :class="checkedId === 5?'main-item main-item-checked':'main-item'" @click="itemChecked(5, 1200)">
              <span>1200 RMB</span>
            </div>
          </el-col>

          <el-col :span="6">
            <div :class="checkedId === 6?'main-item main-item-checked':'main-item'" @click="itemChecked(6, 1500)">
              <span>1500 RMB</span>
            </div>
          </el-col>

          <el-col :span="6">
            <div :class="checkedId === 7?'main-item main-item-checked':'main-item'" @click="itemChecked(7, 2000)">
              <span>2000 RMB</span>
            </div>
          </el-col>

          <el-col :span="6">
            <div :class="checkedId === 8?'main-item main-item-checked':'main-item'" @click="itemChecked(8)">
              <el-input type="number" v-model="customAmount" placeholder="自定义充值数额" :min="1">
<!--                <template slot="prepend">¥</template>-->
<!--                <template slot="append">元</template>-->
              </el-input>
            </div>
          </el-col>

        </el-row>

      </div>

      <div class="btn">
        <el-button @click="returnJobPromotion">返 回</el-button>
        <el-button @click="toPay">支 付</el-button>
      </div>

    </div>

    <el-dialog
        :visible.sync="withdrawalAmountModel"
        :before-close="withdrawalAmountModelBeforeClose"
        width="550px"
        custom-class="withdrawalAmountModel"
    >
      <div class="content">

        <div class="card">
          <div class="title">
            请选择充值方式
          </div>
          <div class="main">
            <div class="icons">
<!--              <el-radio v-model="withdrawalAmountMode" label="1" border>-->
<!--                <img class="icon" src="@/assets/icon/alipay.png">-->
<!--              </el-radio>-->
              <el-radio v-model="withdrawalAmountMode" label="2" border>
                <img class="icon" src="@/assets/icon/weChat2.png">
              </el-radio>
<!--              <el-radio v-model="withdrawalAmountMode" label="3" border>-->
<!--                <img class="icon" src="@/assets/icon/bankCard.png">-->
<!--              </el-radio>-->
            </div>

            <div class="dialog_information">

              <div class="pay-amount" v-if="!payQrcode">
                <span>金额：</span>{{amount}}RMB
              </div>

              <div class="btn" v-if="!payQrcode">
                <el-button :loading="toPayLoading" @click="withdrawalAmountModelSubmit">充 值</el-button>
              </div>
              <div class="protocol" v-if="!payQrcode">
                充值的金额将在2h内到账
<!--                <el-checkbox v-model="protocol">-->
<!--                  充值的金额将在2h内到账，由于个人原因导致未到账<br>则后果自负-->
<!--                </el-checkbox>-->
              </div>

              <div class="qrcode-warp" v-if="payQrcode">
                <div id="payQrcode" class="pay-qrcode" ref="qrCodeUrl" v-if="payQrcode">

                </div>
              </div>

            </div>

          </div>

        </div>

      </div>
    </el-dialog>

  </div>
</template>

<script>
import {AlipayGenerateOrderApi, GetWechatPayNativeApi, GetWechatPayQueryOrApi} from '@/request/api'
import share from "@/share/share";
import QRCode from "qrcodejs2";
export default {
  name: "recharge",
  data() {
    return {
      checkedId: 1,
      customAmount: '',
      amount: 100,
      payUrl: '',
      toPayLoading: false,

      withdrawalAmountModel: false,
      withdrawalAmountMode: '2',
      protocol: true,
      withdrawalAmountLoading: false,
      payQrcode: false,
      payQrcodeUrl: '',

      orderNum: '',
      timer: null,
    }
  },
  watch: {
    customAmount: {
      handler: function () {
        if (this.customAmount) {
          let amountInput = this.customAmount.toString()
          if (amountInput.indexOf('.') !== -1) {
            let arr = amountInput.split('.')
            if (arr[1].length > 2) {
              arr[1] = arr[1].slice(0, 2)
              this.$nextTick(() => {
                this.customAmount = arr[0] + '.' + arr[1]
              })
            }
          }
        }

      }
    },
    withdrawalAmountModel() {
      if (this.timer !== null) {
        clearInterval(this.timer)
        this.timer = null
      }
    }
  },
  methods: {
    itemChecked(id, amount) {
      this.checkedId = id
      if (this.checkedId !== 8) {
        this.amount = amount
      }
    },
    toPay() {
      if (this.checkedId === 8) {
        this.amount = this.customAmount
      }
      if (this.amount === '' || this.amount < 1) {
        share.message.error('充值金额不能低于1元')
        return
      }
      this.withdrawalAmountModel = true
    },
    toRescue() {
      window.location.href = this.payUrl
    },
    returnJobPromotion() {
      this.$router.go(-1)
    },
    withdrawalAmountModelBeforeClose() {
      this.toPayLoading = false
      this.withdrawalAmountModel = false
      this.payQrcode = false
    },
    creatQrCode() {
      let qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.payQrcodeUrl, // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    async withdrawalAmountModelSubmit() {

      if (this.withdrawalAmountMode === '1') {
        this.toPayLoading = true
        const res = await AlipayGenerateOrderApi({
          subject: '充值余额',
          totalAmount: this.amount,
        })

        this.payUrl = res.payUrl
        await this.toRescue()
      }

      if (this.withdrawalAmountMode === '2') {
        this.toPayLoading = true
        const res = await GetWechatPayNativeApi({
          subject: '充值余额',
          totalAmount: this.amount,
        })

        if (res.code === 200) {
          let data = JSON.parse(res.message)

          this.orderNum = res.orderNum
          this.payQrcodeUrl = data.code_url
          this.toPayLoading = false
          this.payQrcode = true
          this.$nextTick(() => {
            this.creatQrCode()
            this.GetWechatPayQueryOr()
          })

        }else {
          this.toPayLoading = false
          this.payQrcode = false
        }

      }

    },
    async GetWechatPayQueryOr() {
      const res = await GetWechatPayQueryOrApi({
        orderNum: this.orderNum,
      })

      if(res && res.orderInfo && res.orderInfo.length >= 2) {
        let orderInfo = JSON.parse(res.orderInfo[1])
        if (orderInfo.trade_state !== 'SUCCESS') {
          this.timer = setInterval( async ()=>{
            const res2 = await GetWechatPayQueryOrApi({
              orderNum: this.orderNum,
            })
            if(res2 && res2.orderInfo && res.orderInfo.length >= 2) {
              let orderInfo2 = JSON.parse(res2.orderInfo[1])
              if (orderInfo2.trade_state === 'SUCCESS') {
                clearInterval(this.timer)
                this.timer = null
                this.withdrawalAmountModel = false
                this.$router.push('/jobPromotion')
              }
            }
          },1000)
        }

      }

    }

  }
}
</script>

<style lang="less" scoped>
.recharge_warp {
  width: 100%;

  .title {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 30px;
    box-sizing: border-box;
    border-bottom: 1px solid #bfbfbf;
    color: #3a3a3a;
  }

  .content {
    width: 100%;

    .content-title {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #9b9b9b;
      padding: 0 30px;
      box-sizing: border-box;
      margin-bottom: 0px;
    }

    .main {
      width: 100%;
      padding: 0 30px;
      box-sizing: border-box;

      .main-item {
        width: 100%;
        height: 100px;
        margin-bottom: 10px;
        border: 2px solid #d2d2d2;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
        color: #3a3a3a;
        cursor: pointer;

        span {
          color: #3a3a3a;
          font-weight: bold;
        }

        &:last-child {
          .el-input {
            width: 100%;
            height: 100%;
            font-size: 20px;

            & /deep/ .el-input__inner {
              width: 100%;
              height: 100%;
              border: none;
            }
          }
        }

      }

      .main-item-checked {
        border: 2px solid #e20000;

        span {
          color: #e20000;
        }
      }

    }
  }

  .btn {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 30px;
    box-sizing: border-box;

    .el-button {
      padding: 10px 30px;
      color: #e20000;
      border: 1px solid #e20000;
    }
  }

}
</style>
