<template>
  <div class="chatMessageList_warp">

    <div class="message-header">
      <div class="title">
        {{pageTitle}}
      </div>
    </div>

    <div class="current-job-card" v-if="currentJobCardShow">
      <div class="left">
        <span>当前沟通岗位</span>
        <span class="highlight" v-if="currentJobCardData && currentJobCardData.jobName">{{currentJobCardData.jobName}}</span>
        <span v-if="currentJobCardData && currentJobCardData.salaryRange">{{currentJobCardData.salaryRange}}</span>
        <span v-if="currentJobCardData && currentJobCardData.jobAddress && currentJobCardData.jobAddress.length > 0">
          {{currentJobCardData.jobAddress[1]}} - {{currentJobCardData.jobAddress[2]}}
        </span>
      </div>
      <div class="right" v-if="currentJobCardData && currentJobCardData.jobId">
        <el-button type="text" @click="toJobDetails(currentJobCardData.jobId)">
          <span>查看详情</span>
        </el-button>
      </div>
    </div>

<!--      <scroll-view class="list" scroll-y :scroll-top='scrollTop' :lower-threshold="15" @scrolltolower="loadMore"-->
<!--                   :scroll-with-animation="true"-->
<!--                    @touchstart="listTouchstart">-->

    <div class="list-warp" id="messageContentScroll">
      <div class="list">

        <div v-for="(item, index) in listData" :key="'cell-' + index"  :class="'cell message-item-' + item.id">

          <!--    时间戳      -->
          <div class="message-time" v-if="item.timeIsShow">
            <div class="text-warp">
              <span class="text">{{item.create_timestamp | time}}</span>
            </div>
          </div>

          <div class="message-warp">
            <div class="message-avatar">
              <img v-if="avatarImgShow(item)"
                   class="avatar-img" :src="initAvatarUrl(chatSessionInfo.avatar)" @click="clickThitherPicture" />
            </div>
            <div :class="item.sender === myChatUserId?'message-item-body message-item-body-my':'message-item-body message-item-body-other'"
                 :style="item.sender === myChatUserId?'direction: rtl;':''">

              <!--    系统提示消息      -->
              <div class="system-messages" v-if="item.content.type === 'system' && !item.is_recall">
                <span class="text">{{item.content.text}}</span>
              </div>

              <!--    消息撤回提示      -->
              <div class="recall-messages" v-if="item.is_recall">
                <span class="text" v-if="item.sender === myChatUserId">消息已撤回</span>
                <span class="text" v-if="item.sender !== myChatUserId">对方消息已撤回</span>
              </div>

              <!--    文本消息      -->
              <div class="text-message" v-if="item.content.type === 'text' && !item.is_recall">
                <div class="message-value">
                  <div class="text-message-warp">
                    <div class="text" :style="item.sender === myChatUserId?'direction: ltr;':''" @mousedown="(e) => messageOnmousedown(e, item, 'text-message-warp')">{{item.content.text}}
                      <div class="message-status" v-if="item.sender === myChatUserId">
                        <img class="message-loading-icon" v-if="item.content.uploading"
                             src="../../assets/icon/loading.gif">
                        <img class="message-error-icon" v-if="item.is_error"
                             src="../../assets/icon/send-message-error.png">
                        <div class="message-status-text-warp" v-if="!item.content.uploading && !item.content.is_error">
                          <span class="message-status-text" v-if="!item.content.uploading && !item.is_error && item.have_read">已读</span>
                          <span class="message-status-text" v-if="!item.content.uploading && !item.is_error && !item.have_read">未读</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--    红包申请文本消息      -->
              <div class="text-message" v-if="item.content.type === 'apply-red-envelope-text' && !item.is_recall">
                <div class="message-value">
                  <div class="text-message-warp">
                    <div class="text" :style="item.sender === myChatUserId?'direction: ltr;':''" @mousedown="(e) => messageOnmousedown(e, item, 'text-message-warp')">{{item.content.data}}
                      <div class="message-status" v-if="item.sender === myChatUserId">
                        <img class="message-loading-icon" v-if="item.content.uploading"
                             src="../../assets/icon/loading.gif">
                        <img class="message-error-icon" v-if="item.is_error"
                             src="../../assets/icon/send-message-error.png">
                        <div class="message-status-text-warp" v-if="!item.content.uploading && !item.content.is_error">
                          <span class="message-status-text" v-if="!item.content.uploading && !item.is_error && item.have_read">已读</span>
                          <span class="message-status-text" v-if="!item.content.uploading && !item.is_error && !item.have_read">未读</span>
                        </div>
                      </div>
                    </div>
                    <div class="text-url" @click="toApplyRedEnvelope">
                      点击前往红包申请 》
                    </div>
                  </div>
                </div>
              </div>

              <!--    人工客服正在赶来和过期的消息      -->
              <div class="text-message" v-if="(item.content.type === 'call_human_customer_service' || item.content.type === 'call_human_customer_service_timed_out') && !item.is_recall">
                <div class="message-value">
                  <div class="text-message-warp">
                    <div class="text" :style="item.sender === myChatUserId?'direction: ltr;':''" @mousedown="(e) => messageOnmousedown(e, item, 'text-message-warp')">{{item.content.text}}
                      <div class="message-status" v-if="item.sender === myChatUserId">
                        <img class="message-loading-icon" v-if="item.content.uploading"
                             src="../../assets/icon/loading.gif">
                        <img class="message-error-icon" v-if="item.is_error"
                             src="../../assets/icon/send-message-error.png">
                        <div class="message-status-text-warp" v-if="!item.content.uploading && !item.content.is_error">
                          <span class="message-status-text" v-if="!item.content.uploading && !item.is_error && item.have_read">已读</span>
                          <span class="message-status-text" v-if="!item.content.uploading && !item.is_error && !item.have_read">未读</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--    图片消息      -->
              <div class="image-message" v-if="item.content.type === 'image' && !item.is_recall">
                <div class="message-value">
                  <div class="image-warp" @mousedown="(e) => messageOnmousedown(e, item, 'image-warp')">
                    <img class="image" :src="item.content.url" @click="toPreviewImage(item.content.url)" />
                    <div class="message-status" v-if="item.sender === myChatUserId">
                      <img class="message-loading-icon" v-if="item.content.uploading"
                           src="../../assets/icon/loading.gif">
                      <img class="message-error-icon" v-if="item.is_error"
                           src="../../assets/icon/send-message-error.png">
                      <div class="message-status-text-warp" v-if="!item.content.uploading && !item.content.is_error">
                        <span class="message-status-text" v-if="!item.content.uploading && !item.is_error && item.have_read">已读</span>
                        <span class="message-status-text" v-if="!item.content.uploading && !item.is_error && !item.have_read">未读</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--    语言消息      -->
              <div class="voice-message" v-if="item.content.type === 'voice' && !item.is_recall">
                <div class="message-value">
                  <div class="voice-warp">
                    <div class="voice" @mousedown="(e) => messageOnmousedown(e, item, 'voice-warp')">
                      <m-audio :src="item.content.url"></m-audio>
                      <div class="message-status" v-if="item.sender === myChatUserId">
                        <img class="message-loading-icon" v-if="item.content.uploading"
                             src="../../assets/icon/loading.gif">
                        <img class="message-error-icon" v-if="item.is_error"
                             src="../../assets/icon/send-message-error.png">
                        <div class="message-status-text-warp" v-if="!item.content.uploading && !item.content.is_error">
                          <span class="message-status-text" v-if="!item.content.uploading && !item.is_error && item.have_read">已读</span>
                          <span class="message-status-text" v-if="!item.content.uploading && !item.is_error && !item.have_read">未读</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--    岗位详情卡片消息      -->
              <div class="job-card-message" v-if="item.content.type === 'job-card' && !item.is_recall">
                <div class="message-value">
                  <div class="job-card-warp" @mousedown="(e) => messageOnmousedown(e, item, 'job-card-warp')">
                    <div class="job-card" @click="toJobDetails(item.content.data.jobId)">
                      <div class="title">
                        <div class="name">
                          {{item.content.data.jobName}}
                        </div>
                        <div class="salary">
                          {{item.content.data.salaryRange}}
                        </div>
                      </div>
                      <div class="enterprise">
                        {{item.content.data.companyName}}
                      </div>
                      <div class="tag">
                        <div class="tag-item2" v-if="item.content.data && item.content.data.jobType === 1">
                          红包岗位
                        </div>
                        <div class="tag-item" v-if="item.content.data && item.content.data.jobAddress && item.content.data.jobAddress.length >= 2">
                          {{item.content.data.jobAddress[1]}} · {{item.content.data.jobAddress[2]}}
                        </div>
                        <div class="tag-item" v-if="item.content.data && item.content.data.experienceRequirementRange">
                          {{item.content.data.experienceRequirementRange}}
                        </div>
                        <div class="tag-item" v-if="item.content.data && item.content.data.academicRequirement">
                          {{item.content.data.academicRequirement}}
                        </div>
                        <div class="tag-item" v-if="item.content.data && item.content.data.ageRequirementRange">
                          {{item.content.data.ageRequirementRange}}
                        </div>
                      </div>
                      <div class="describe" v-if="item.content.data && item.content.data.description">
                        <div class="describe-title">
                          岗位职责：
                        </div>
                        <div class="describe-title-content">{{item.content.data.description}}</div>
                      </div>
                      <div class="welfare" v-if="item.content.data && item.content.data.jobWelfare.length > 0">
                        <div class="welfare-item" v-for="(welfare, welfareIndex) in item.content.data.jobWelfare" :key="'welfare' + welfareIndex">
                          {{welfare}}
                        </div>
                      </div>
                    </div>
                    <div class="message-status" v-if="item.sender === myChatUserId">
                      <img class="message-loading-icon" v-if="item.content.uploading"
                           src="../../assets/icon/loading.gif">
                      <img class="message-error-icon" v-if="item.is_error"
                           src="../../assets/icon/send-message-error.png">
                      <div class="message-status-text-warp" v-if="!item.content.uploading && !item.content.is_error">
                        <span class="message-status-text" v-if="!item.content.uploading && !item.is_error && item.have_read">已读</span>
                        <span class="message-status-text" v-if="!item.content.uploading && !item.is_error && !item.have_read">未读</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--    简历详情卡片消息      -->
              <div class="resume-card-message" v-if="item.content.type === 'resume-card' && !item.is_recall">
                <div class="message-value">
                  <div class="resume-card-warp" @mousedown="(e) => messageOnmousedown(e, item, 'resume-card-warp')">
                    <div class="resume-card" @click="toResumePage">
                      <div class="title">
                        <div class="name">
                          {{item.content.data.name}}
                        </div>
                        <div class="salary">
                          {{item.content.data.expectedSalaryRange}}
                        </div>
                      </div>
                      <div class="job-expectations">
                        <div class="place" v-if="item.content.data && item.content.data.expectedJobAddressList && item.content.data.expectedJobAddressList.length > 0">
                          <span class="place-text">期望地区：</span>
                          <span class="place-text" v-for="(expectArea, expectAreaIndex) in item.content.data.expectedJobAddressList" :key="'expectArea' + expectAreaIndex">{{expectArea}}</span>
                        </div>
                        <div class="job" v-if="item.content.data && item.content.data.expectedJobList && item.content.data.expectedJobList.length > 0">
                          <span class="job-text">期望岗位：</span>
                          <span class="job-text" v-for="(jobName, jobNameIndex) in item.content.data.expectedJobList" :key="'jobName' + jobNameIndex">{{jobName}}</span>
                        </div>
                      </div>
                      <div class="information">
                        <div class="information-item" v-if="item.content.data && item.content.data.gender">
                          <img class="information-item-img" src="@/assets/icon/gender.png" />
                          <span class="information-item-text">{{item.content.data.gender}}</span>
                        </div>
                        <div class="information-item" v-if="item.content.data && item.content.data.age">
                          <img class="information-item-img" src="@/assets/icon/birthday.png" />
                          <span class="information-item-text">{{item.content.data.age}}</span>
                        </div>
                        <div class="information-item" v-if="item.content.data && item.content.data.workExperienceYears">
                          <img class="information-item-img" src="@/assets/icon/work-experience.png" />
                          <span class="information-item-text">{{item.content.data.workExperienceYears}}</span>
                        </div>
                        <div class="information-item" v-if="item.content.data && item.content.data.academicLevelMax">
                          <img class="information-item-img" src="@/assets/icon/education.png" />
                          <span class="information-item-text">{{item.content.data.academicLevelMax}}</span>
                        </div>
                        <div class="information-item" v-if="item.content.data && item.content.data.jobSearchingStatus">
                          <img class="information-item-img" src="@/assets/icon/clock.png" />
                          <span class="information-item-text">{{item.content.data.jobSearchingStatus}}</span>
                        </div>
                        <div class="information-item" v-if="item.content.data && item.content.data.employeePhone">
                          <img class="information-item-img" src="@/assets/icon/phone4.png" />
                          <span class="information-item-text">{{item.content.data.employeePhone}}</span>
                        </div>
                      </div>
                      <div class="describe" v-if="item.content.data && item.content.data.selfIntroduction">
                        <div class="describe-title">
                          自我介绍：
                        </div>
                        <div class="describe-title-content">{{item.content.data.selfIntroduction}}</div>
                      </div>
                      <div class="welfare" v-if="item.content.data && item.content.data.personalHighLightList && item.content.data.personalHighLightList.length > 0">
                        <div class="welfare-item" v-for="(highlight, highlightIndex) in item.content.data.personalHighLightList" :key="'highlights' + highlightIndex">
                          {{highlight}}
                        </div>
                      </div>
                    </div>
                    <div class="message-status" v-if="item.sender === myChatUserId">
                      <img class="message-loading-icon" v-if="item.content.uploading"
                           src="../../assets/icon/loading.gif">
                      <img class="message-error-icon" v-if="item.is_error"
                           src="../../assets/icon/send-message-error.png">
                      <div class="message-status-text-warp" v-if="!item.content.uploading && !item.content.is_error">
                        <span class="message-status-text" v-if="!item.content.uploading && !item.is_error && item.have_read">已读</span>
                        <span class="message-status-text" v-if="!item.content.uploading && !item.is_error && !item.have_read">未读</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--    当前应聘岗位详情卡片消息      -->
              <div class="current-job-card-message" v-if="item.content.type === 'current-job-card'">
                <span class="text">{{item.content.data.markedWords}}</span>
              </div>

              <!--    图片或文字或图文卡片      -->
              <div class="image-or-text-card-message" v-if="item.content.type === 'image-or-text-card' && !item.is_recall">
                <div class="message-value">
                  <div class="image-or-text-card-warp" @mousedown="(e) => messageOnmousedown(e, item, 'image-or-text-card-warp')">
                    <div class="image-or-text-card">
                      <div class="card-title" v-if="item.content && item.content.data && item.content.data.title">
                        {{item.content.data.title}}
                      </div>
                      <div class="img" v-if="item.content && item.content.data && item.content.data.imgUrl">
                        <img :src="item.content.data.imgUrl">
                      </div>
                      <div class="msg" v-if="item.content && item.content.data && item.content.data.msg">
                        {{item.content.data.msg}}
                      </div>
                      <div class="action" v-if="item.content && item.content.data && item.content.data.btnText" @click="clickCardAction(item.content)">
                        <div class="action-left">
                          {{item.content.data.btnText}}
                        </div>
                        <div class="action-right">
                          <img src="../../assets/icon/right-arrow.png">
                        </div>
                      </div>
                    </div>
                    <div class="message-status" v-if="item.sender === myChatUserId">
                      <img class="message-loading-icon" v-if="item.content.uploading"
                           src="../../assets/icon/loading.gif">
                      <img class="message-error-icon" v-if="item.is_error"
                           src="../../assets/icon/send-message-error.png">
                      <div class="message-status-text-warp" v-if="!item.content.uploading && !item.content.is_error">
                        <span class="message-status-text" v-if="!item.content.uploading && !item.is_error && item.have_read">已读</span>
                        <span class="message-status-text" v-if="!item.content.uploading && !item.is_error && !item.have_read">未读</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--    文字卡片      -->
              <div class="text-card-message" v-if="item.content.type === 'text-card' && !item.is_recall">
                <div class="message-value">
                  <div class="text-card-warp" @mousedown="(e) => messageOnmousedown(e, item, 'text-card-warp')">
                    <div class="text-card">
                      <div class="card-title">
                        {{item.content.data.title}}
                      </div>
                      <div class="msg" v-if="item.content && item.content.data && item.content.data.msg">
                        {{item.content.data.msg}}
                      </div>
                      <div class="action" v-if="item.content && item.content.data && item.content.data.btnText" @click="clickCardAction(item.content)">
                        <div class="action-left">
                          {{item.content.data.btnText}}
                        </div>
                        <div class="action-right">
                          <img src="../../assets/icon/right-arrow.png">
                        </div>
                      </div>
                    </div>
                    <div class="message-status" v-if="item.sender === myChatUserId">
                      <img class="message-loading-icon" v-if="item.content.uploading"
                           src="../../assets/icon/loading.gif">
                      <img class="message-error-icon" v-if="item.is_error"
                           src="../../assets/icon/send-message-error.png">
                      <div class="message-status-text-warp" v-if="!item.content.uploading && !item.content.is_error">
                        <span class="message-status-text" v-if="!item.content.uploading && !item.is_error && item.have_read">已读</span>
                        <span class="message-status-text" v-if="!item.content.uploading && !item.is_error && !item.have_read">未读</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--    hr不能操作的文字卡片      -->
              <div class="hr-no-action-card-message" v-if="item.content.type === 'hr-no-action-card' && !item.is_recall">
                <div class="message-value">
                  <div class="hr-no-action-card-warp" @mousedown="(e) => messageOnmousedown(e, item, 'hr-no-action-card-warp')">
                    <div class="hr-no-action-card">
                      <div class="card-title">
                        {{item.content.data.title}}
                      </div>
                      <div class="msg" v-if="item.content && item.content.data && item.content.data.msg">
                        {{item.content.data.msg}}
                      </div>
                      <div class="action" v-if="userType === 'employee' && item.content && item.content.data && item.content.data.btnText" @click="clickCardAction(item.content)">
                        <div class="action-left">
                          {{item.content.data.btnText}}
                        </div>
                        <div class="action-right">
                          <img src="../../assets/icon/right-arrow.png">
                        </div>
                      </div>
                    </div>
                    <div class="message-status" v-if="item.sender === myChatUserId">
                      <img class="message-loading-icon" v-if="item.content.uploading"
                           src="../../assets/icon/loading.gif">
                      <img class="message-error-icon" v-if="item.is_error"
                           src="../../assets/icon/send-message-error.png">
                      <div class="message-status-text-warp" v-if="!item.content.uploading && !item.content.is_error">
                        <span class="message-status-text" v-if="!item.content.uploading && !item.is_error && item.have_read">已读</span>
                        <span class="message-status-text" v-if="!item.content.uploading && !item.is_error && !item.have_read">未读</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--    问题卡片消息      -->
              <div class="questions-card-message" v-if="item.content.type === 'questions-card' && !item.is_recall">
                <div class="message-value">
                  <div class="questions-card-warp">
                    <div class="questions-card">
                      <div class="questions-card-title">
                        <span>{{item.content.data.title}}</span>
                      </div>
                      <div class="question" v-for="(item, index) in item.content.data.list" :key="'question' + index">
                        <span v-if="item === '呼叫人工客服'" @click="sendMsg(item)" class="specific-question">{{item}}</span>
                        <span v-if="item !== '呼叫人工客服'" @click="sendMsg(item)">{{item}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--    答案卡片消息      -->
              <div class="answer-card-message" v-if="item.content.type === 'answer-card' && !item.is_recall">
                <div class="message-value">
                  <div class="answer-card-warp">
                    <div class="answer-card">
                      <div class="answer-card-title">
                        <span class="answer-card-title-text">{{item.content.data.title}}</span>
                      </div>
                      <div class="answer-card-text-warp" v-html="textInit(item.content.data.text)"></div>
                      <div class="answer-card-btn" v-if="item.content && item.content.data && item.content.data.btnText">
                        <span class="answer-card-btn-text" @click="answerCardBtnClick(item.content.data.toAction)">{{item.content.data.btnText}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="message-avatar">
              <img v-if="myAvatarImgShow(item)"
                   class="avatar-img" :src="myAvatar" @click="clickMyPicture" />
            </div>
          </div>

        </div>

        <!--   更多数据   -->
        <div class="cell">
          <div class="load-more-wrap">
            <span class="span" @click="loadMessageListData">
              <i v-if="loadMoreStatus === 'loading'" class="el-icon-loading"></i>
            {{loadingText}}
            </span>
          </div>
        </div>

      </div>
    </div>


    <preview-image-or-video title="" :preview-is-show.sync="previewIsShow" type="image" :url="previewUrl"></preview-image-or-video>

  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import share from '../../share/share'
import PreviewImageOrVideo from "@/components/previewImage/previewImageOrVideo";
import {JobDetailApi, JobHrSeeJobDetailApi} from "@/request/api";
import {experience} from "@/share/shareData";
import defaultProfilePhoto from "@/assets/icon/profile-photo.png";
export default {
  name: 'chatMessageList',
  components: {PreviewImageOrVideo},
  props: {
    isTemporaryCustomerService: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      pageTitle: '',  // 页面title信息
      currentJobCardData: null,  // 当前会话岗位信息
      readyNum: 0,
      /*
      * 消息列表相关
      * */
      listData: [],  // 消息列表
      loadMoreStatus: 'more',  // more、noMore、loading
      loadingText: '上拉显示更多',  // 没有更多数据了 、 正在加载...
      isEnoughData: false,  // 是否有足够的数据可供页面滚动
      scrollDiv: null,
      scrollTop: 0,
      isFirst: true,  // 是否是第一次请求数据

      /*
      * 长按撤回相关
      * */
      messageMenuTargetDom: null,
      messageActionLoading: false,  // 撤回中

      /*
      * 图片预览相关
      * */
      previewIsShow: false,
      previewUrl: '',

      /* 招聘端 */
      toActionOptionsHR: {
        bindWeChat: '/hrData',  // 去绑定微信号
        changeLoginPhoneNumber: '/hrData',  // 修改登录手机号
        changePassword: '/hrData',  // 修改密码
        logOut: '',  // 退出登录
        cancelAccount : '',  // 点我注销账号
        publishJob: '/postJob',  // 发布岗位
        setGreetings: '/hrData',  // 设置打招呼语
        reviewInterviewNotes: '/enterpriseDynamic',  // 查看面试记录
        promoteJobs: '/newJobPromotion',  // 去推广岗位
        publishRedPacketJob: '/postJob',  // 发布红包岗位
      },

      /* 求职端 */
      toActionOptions: {
        bindWeChat: '/accountManagement',  // 去绑定微信号
        changeLoginPhoneNumber: '/accountManagement',  // 修改登录手机号
        changePassword: '/accountManagement',  // 修改密码
        logOut: '',  // 退出登录
        cancelAccount: '',  // 注销账号
        editResume: '/editMyResume',  // 编辑简历
        manageJobExpectations: '/editMyResume',  // 管理求职期望
        jobSearch: '/jobHunting',  // 看工作
        redPacketJobSearch: '/unfoldJobHunting',  // 看红包岗位
        myWealth: '/wealthWithdrawal',  // 我的财富
        wealthDetailed: '/wealthWithdrawal',  // 查看领取记录
        invitationToRegister: '/pointsCollection',  // 邀请好友注册
        integralAcquisitionRecord: '/pointsCollection',  // 积分获取记录
        creditsExchange: '/wealthWithdrawal',  // 兑换积分
        redemptionRecord: '/wealthWithdrawal',  // 积分兑换记录
        withdrawalOfWealth: '/wealthWithdrawal',  // 提现财富
        wealthWithdrawalRecords: '/wealthWithdrawal',  // 财富提现记录
      },
    }
  },
  computed: {
    hrCurrentRepresentative() {
      return this.$store.state.hrCurrentRepresentative
    },
    jobId() {
      return this.hrCurrentRepresentative.value
    },
    userType() {
      return this.$store.state.userInfo.userType
    },
    myAvatar() {
      return this.$store.state.userInfo.profilePhoto || defaultProfilePhoto
    },
    aonuoChat() {
      return this.$store.state.aonuoChat
    },
    aonuoChatReady() {
      return this.$store.state.aonuoChatReady
    },
    myChatUserId() {
      if (this.isTemporaryCustomerService) {
        return this.$store.state.temporaryMyChatUserId
      }else {
        return this.$store.state.myChatUserId
      }
    },
    chatSessionId() {
      return this.$store.state.chatSessionId
    },
    chatSession() {
      return this.$store.state.chatSession
    },
    chatSessionInfo() {
      return this.$store.state.chatSessionInfo
    },
    currentJobCardShow() {
      if (this.currentJobCardData && this.userType === 'employee' && this.chatSessionInfo && this.chatSessionInfo.user_id !== '000001' && this.chatSessionInfo.user_id !== '000002') {
        return true
      }else {
        return false
      }
    },
    defAvatarUrl() {
      if (this.userType === 'hr') {
        return 'https://cdn.jiaxinzp.com/jxzp/hr_avatar_male.png'
      }else {
        return 'https://cdn.jiaxinzp.com/jxzp/employee_avatar_male.png'
      }
    },
  },
  filters: {
    time (value) {  // 时间格式转换
      // let time = value
      // time = time.replace(/-/g,':').replace(' ',':')
      // time = time.split(':')
      // let date = new Date(time[0],(time[1]-1),time[2],time[3],time[4],time[5])
      let date = new Date(parseInt(value))
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? "0" + m : m
      let d = date.getDate()
      d = d < 10 ? "0" + d : d
      let h = date.getHours()
      h = h < 10 ? "0" + h : h
      let minute = date.getMinutes()
      let second = date.getSeconds()
      minute = minute < 10 ? "0" + minute : minute
      second = second < 10 ? "0" + second : second
      return m + "月" + d + "日 " + h + ":" + minute
    },
  },
  watch: {
    aonuoChatReady: {
      handler: async function () {
        if (this.aonuoChatReady) {
          this.readyNum++
          await this.setMessageRead()
          this.loadMessageUnreadCount()
        }
      },
    },
    readyNum: {
      handler: async function () {
        if (this.readyNum >= 1 && this.aonuoChatReady && this.listData.length === 0) {
          if (this.chatSessionId !== undefined && this.chatSessionId !== '' && this.chatSessionId !== null && this.chatSessionId !== 'null') {
            await this.init()
          }
        }
      },
      immediate: true
    },
    async chatSessionId() {
      this.listData = []
      await this.setMessageRead()
      this.loadMessageUnreadCount()
      if (this.chatSessionId !== undefined && this.chatSessionId !== '' && this.chatSessionId !== null && this.chatSessionId !== 'null') {
        if (this.userType === 'employee' && this.chatSessionInfo.name) {
          this.pageTitle = this.chatSessionInfo.name
        }else if (this.userType === 'hr' && this.chatSessionInfo.job_information.jobName) {
          this.pageTitle = this.chatSessionInfo.name + '(' + this.chatSessionInfo.job_information.jobName + ')'
        }else {
          this.pageTitle = this.chatSessionInfo.name
        }
        await this.init()
      }
    },
  },
  async mounted() {
    window.oncontextmenu = (e) => {
      //取消默认的浏览器自带右键 很重要！！
      e.preventDefault();
    }
    //鼠标左键任意位置单击, 关闭右键菜单
    window.onclick = (e) => {
      if (this.messageMenuTargetDom) {
        let domArr = document.getElementsByClassName('messageMenuTargetDom')
        if (domArr && domArr.length > 0) {
          this.messageMenuTargetDom.removeChild(domArr[0])
          this.messageMenuTargetDom = null
        }
      }
    }

    /**
     * 放置全局监听事件
     * */
    this.$bus.$on('MESSAGE_RECEIVED', this.messageReceived3)
    this.$bus.$on('MESSAGE_SEND_SUCCESS', this.messageSendSuccess3)
    this.$bus.$on('MESSAGE_BLOCKED_ERROR', this.messageBlockedError3)
    this.$bus.$on('MESSAGE_READ_BY_PEER', this.messageReadByPeer3)
    this.$bus.$on('MESSAGE_REVOKED', this.messageRevoked3)

    //获取节点
    this.scrollDiv = document.getElementById('messageContentScroll')
    //绑定事件
    this.scrollDiv.addEventListener('scroll', () => {
      this.scrollTop = this.scrollDiv.scrollTop
      this.scrollEvent()
    })

    if (this.aonuoChatReady) {
      this.readyNum++
      await this.setMessageRead()
      this.loadMessageUnreadCount()
    }

    if (this.chatSessionId !== undefined && this.chatSessionId !== '' && this.chatSessionId !== null && this.chatSessionId !== 'null') {
      if (this.userType === 'employee' && this.chatSessionInfo.name) {
        this.pageTitle = this.chatSessionInfo.name
      }else if (this.userType === 'hr' && this.chatSessionInfo.job_information.jobName) {
        this.pageTitle = this.chatSessionInfo.name + '(' + this.chatSessionInfo.job_information.jobName + ')'
      }else {
        this.pageTitle = this.chatSessionInfo.name
      }
    }
  },
  destroyed() {
    this.scrollDiv.removeEventListener('scroll', this.scrollEvent)
    this.$bus.$off('MESSAGE_RECEIVED', this.messageReceived3)
    this.$bus.$off('MESSAGE_SEND_SUCCESS', this.messageSendSuccess3)
    this.$bus.$off('MESSAGE_BLOCKED_ERROR', this.messageBlockedError3)
    this.$bus.$off('MESSAGE_READ_BY_PEER', this.messageReadByPeer3)
    this.$bus.$off('MESSAGE_REVOKED', this.messageRevoked3)
  },
  methods: {
    ...mapMutations(['setChatSession', 'setChatSessionId', 'setMessageUnreadCount', 'setUserinfo']),
    initAvatarUrl(avatar) {
      if (avatar) {
        return share.initImageUrl(avatar)
      }else {
        return this.defAvatarUrl
      }
    },
    /**
     * 消息通道收到一条消息时触发
     * */
    async messageReceived3(data) {
      console.log('消息通道收到了一条消息1', data)
      if(data.session.session_id === this.chatSessionId) {
        if (data.content.type === 'call_human_customer_service') {
          this.$emit('customerServiceTypeChange', true)
        } else if (data.content.type === 'call_human_customer_service_timed_out') {
          this.$emit('customerServiceTypeChange', false)
        }
        let timeIsShow = true
        if (this.listData.length > 0 && data.create_timestamp - this.listData[0].create_timestamp < 5 * 60 * 1000) {
          timeIsShow = false
        }
        let message = {
          id: data.id,
          content: data.content,
          sender: data.sender,
          create_time: data.create_time,
          create_timestamp: data.create_timestamp,
          timeIsShow: timeIsShow,
          have_read: data.have_read,
          is_error: false,
          read_time: data.read_time,
        }
        this.listData.unshift(message)

        this.$nextTick(() => {
          this.goBottom()
        })
      }
      await this.setMessageRead()
      this.loadMessageUnreadCount()
    },
    /**
     * 消息通道发送一条消息成功时触发
     * */
    messageSendSuccess3(data) {
      console.log('消息通道发送一条消息成功1', data)
      if(data.session.session_id === this.chatSessionId) {
        let i = 0
        try{
          this.listData.forEach((item, index) => {
            if (item.content.id === data.content.id) {
              this.listData.splice(index, 1)
              throw Error()
            }
          })
        }catch(e){
          console.log("结束了")
        }
        let timeIsShow = true
        if (this.listData.length > 0 && data.create_timestamp - this.listData[0].create_timestamp < 5 * 60 * 1000) {
          timeIsShow = false
        }
        let message = {
          id: data.id,
          content: data.content,
          sender: data.sender,
          create_time: data.create_time,
          create_timestamp: data.create_timestamp,
          timeIsShow: timeIsShow,
          have_read: data.have_read,
          is_error: false,
          read_time: data.read_time,
        }
        this.listData.unshift(message)
      }
    },
    /**
     * 被拉黑导致消息发送失败的响应
     * */
    async messageBlockedError3(data) {
      console.log('被拉黑导致消息发送失败的响应1', data)
      if(data.session.session_id === this.chatSessionId) {
        let i = 0
        try{
          this.listData.forEach((item, index) => {
            if (item.content.id) {
              let num1  = parseInt(item.content.id.slice(0, item.content.id.length - 2))  // 去掉两位随机数
              let num2  = parseInt(data.content.id.slice(0, data.content.id.length - 2))  // 去掉两位随机数
              if (num1 < num2 - 60000) {
                i++
                if (i > 6) {
                  throw Error()
                }
              } else if (item.content.id === data.content.id) {
                this.listData.splice(index, 1)
                throw Error()
              }
            }else {
              throw Error()
            }
          })
        }catch(e){
          console.log("结束了")
        }
        let timeIsShow = true
        if (this.listData.length > 0 && data.create_timestamp - this.listData[0].create_timestamp < 5 * 60 * 1000) {
          timeIsShow = false
        }
        let message = {
          id: data.id,
          content: data.content,
          sender: data.sender,
          create_time: data.create_time,
          create_timestamp: data.create_timestamp,
          timeIsShow: timeIsShow,
          have_read: data.have_read,
          is_error: true,
          read_time: data.read_time,
        }
        this.listData.unshift(message)
        share.message.error('消息已发出，但被对方拒收了')
      }
    },
    /**
     * 收到对端已读消息的通知
     * */
    async messageReadByPeer3(data) {
      console.log('收到对端已读消息的通知1', data)
      if (data.session.session_id === this.chatSessionId) {
        this.listData.forEach((item, index) => {
          if (!item.have_read) {
            item.have_read = true
          }
        })
      }
    },
    /**
     * 收到消息被撤回的通知
     * */
    async messageRevoked3(data) {
      console.log('收到消息被撤回的通知1', data)
      if (data.session.session_id === this.chatSessionId) {
        try{
          this.listData.forEach((item, index) => {
            if (item.id === data.id) {
              this.$set(item, 'is_recall', true)
              throw Error()
            }
          })
        }catch(e){
          console.log("结束了")
        }
      }
    },
    async init() {
      await this.loadMessageListData()
      if (this.userType === 'employee' && this.chatSessionInfo && this.chatSessionInfo.user_id !== '000001' && this.chatSessionInfo.user_id !== '000002') {
        await this.loadCurrentJobCardData()
      }
      this.$nextTick(() => {
        this.goBottom()
        this.isFirst = false
      })
    },
    /**
     * 获取当前应聘岗位卡片数据
     * */
    async loadCurrentJobCardData() {
      const res = await JobDetailApi({
        jobId: this.chatSessionInfo.job_information.jobId
      })
      if (res) {
        let obj = {
          jobId: res.jobInformation.jobId,  // 岗位id
          jobType: res.jobInformation.jobType,  // 岗位类型，枚举值
          jobName: res.jobInformation.jobName,  // 岗位名字
          jobAddress: res.jobInformation.jobAddress,  // 工作地点列表
          detailAddress: res.jobInformation.detailAddress,  // 详细地址
          salaryRange: res.jobInformation.salaryRange,  // 薪资待遇
          experienceRequirementRange: res.jobInformation.experienceRequirementRange,  // 工作经验
          ageRequirementRange: res.jobInformation.ageRequirementRange,  // 年龄要求
          academicRequirement: res.jobInformation.academicRequirement,  // 学历要求
          jobWelfare: res.jobInformation.jobWelfare,  // 岗位福利
          description: res.jobInformation.description,  // 岗位描述
        }
        obj = this.hrInitCardData(obj)
        this.currentJobCardData = obj
        console.log('currentJobCardData', this.currentJobCardData)
      }else {
        this.currentJobCardData = null
      }
    },
    hrInitCardData(data) {
      let obj = data
      if (obj.salaryRange && obj.salaryRange.length > 1) {
        obj.salaryRange = parseInt(obj.salaryRange[0])/1000 + 'k-' + parseInt(obj.salaryRange[1])/1000 + 'k'
      }else {
        obj.salaryRange = '面议'
      }
      if (obj.experienceRequirementRange && obj.experienceRequirementRange.length > 1) {
        let value = obj.experienceRequirementRange[0] + '-' + obj.experienceRequirementRange[1]
        experience.forEach((item, index) => {
          if (item.value === value) {
            obj.experienceRequirementRange = item.label + '经验'
          }
        })
      }else {
        obj.experienceRequirementRange = '经验不限'
      }
      if (obj.ageRequirementRange && obj.ageRequirementRange.length > 1) {
        obj.ageRequirementRange = obj.ageRequirementRange[0] + '岁-' + obj.ageRequirementRange[1] + '岁'
      }else {
        obj.ageRequirementRange = '年龄不限'
      }
      return obj
    },
    /**
     * 设置会话已读
     * */
    async setMessageRead() {
      if (this.chatSessionId !== '' && this.chatSessionId !== undefined && this.chatSessionId !== null) {
        const res = await this.aonuoChat.setMessageRead(this.chatSessionId)
      }
    },
    /**
     * 获取未读消息数量会话已读
     * */
    async loadMessageUnreadCount() {
      const res = await this.aonuoChat.getMessageUnreadCount()
      if (res) {
        this.setMessageUnreadCount({
          messageUnreadCount: res.unread_count
        })
      }
    },
    /**
    * 获取消息列表
    * */
    async loadMessageListData() {
      this.loadMoreStatus = 'loading'
      this.loadingText = '正在加载...'
      let className = ''
      if (this.listData.length > 0) {
        className = 'message-item-' + this.listData[this.listData.length - 1].id
      }
      let scrollHeight = this.scrollDiv.scrollHeight
      let offset = this.listData.length
      let length = 15
      const res = await this.aonuoChat.getMessageList(this.chatSessionId, offset, length)
      let num_of_chat_logs = 0
      if (res) {
        let list = res.chat_log_info_list
        num_of_chat_logs = res.num_of_chat_logs
        list = this.initList(list)
        this.listData = [...this.listData, ...list]
      }
      if (this.listData.length >= 15) {
        this.isEnoughData = true
      }
      this.$nextTick(() => {
        setTimeout(() => {
          if (num_of_chat_logs > this.listData.length) {
            this.loadMoreStatus = 'more'
            this.loadingText = '上拉显示更多'
          }else {
            this.loadMoreStatus = 'noMore'
            this.loadingText = '没有更多数据了'
          }
        }, 500)
        if (!this.isFirst) {
          this.fixedMessagePosition(className, scrollHeight)
        }
      })
    },
    /**
     * 初始化聊天记录列表
     * */
    initList(arr) {
      let newArr = arr
      if (newArr.length > 1) {
        newArr.forEach((item, index) => {
          if (index < newArr.length - 1) {
            if (item.create_timestamp - newArr[index + 1].create_timestamp < 60 * 5 * 1000) {
              item.timeIsShow = false
            }else {
              item.timeIsShow = true
            }
          }else {
            item.timeIsShow = true
          }
        })
      }else if (newArr.length === 1) {
        newArr[0].timeIsShow = true
      }
      return arr
    },
    /**
     * 列表滚动时触发
     * */
    scrollEvent() {
      if (this.messageMenuTargetDom) {
        let domArr = document.getElementsByClassName('messageMenuTargetDom')
        if (domArr && domArr.length > 0) {
          this.messageMenuTargetDom.removeChild(domArr[0])
          this.messageMenuTargetDom = null
        }
      }
      if (this.isFirst) {
        return;
      }
      if (!this.isEnoughData || this.loadMoreStatus === 'loading' || this.loadMoreStatus === 'noMore') {
        return;
      }
      if (this.scrollTop !== '' && this.scrollTop <= 0) {
        this.loadMessageListData()
      }
    },
    /**
     * 回到列表底部
     * */
    goBottom() {
      this.scrollDiv.scrollTop = 9999999
    },
    /**
    * 固定消息列表
    * */
    fixedMessagePosition(className, scrollHeight) {
      let currScrollHeight = this.scrollDiv.scrollHeight
      this.scrollDiv.scrollTop = currScrollHeight - scrollHeight + this.scrollTop
    },
    /**
     * 图片视频预览
     * */
    toPreviewImage(url) {
      this.previewUrl = url
      this.previewIsShow = true
    },

    /**
     * 为消息列表头部添加数据
     * */
    listUnshift(obj) {
      this.listData.unshift(obj)
      setTimeout(() => {
        // this.$nextTick(() => {
        this.goBottom()
        // })
      }, 100)
    },
    /**
     * 为消息列表删除数据
     * */
    listDelItem(obj) {
      this.listData.forEach((item, index) => {
        if (item.content.id === obj.content.id) {
          this.listData.splice(index, 1)
        }
      })
    },

    /**
     * 点击对方头像
     * */
    clickThitherPicture() {
      if (this.chatSessionInfo.user_id === '000001' || this.chatSessionInfo.user_id === '000002') {
        return;
      }
      if (this.userType === 'hr') {
        let employeeId = this.chatSessionInfo.user_id.split('-')[1]
        this.hrToJobSeekerDetails(employeeId)
      }
      if (this.userType === 'employee') {
        let jobId = this.chatSessionInfo.job_information.jobId
        this.toJobDetails(jobId)
      }
    },

    /**
     * 点击我的头像
     * */
    clickMyPicture() {
      if (this.chatSessionInfo.user_id === '000001' || this.chatSessionInfo.user_id === '000002') {
        return;
      }
      if (this.userType === 'hr') {
        let jobId = this.chatSessionInfo.job_information.jobId
        this.hrToHrSeeJobDetails(jobId)
      }
      if (this.userType === 'employee') {
        this.employeeToJobSeekerResume()
      }
    },

    /**
     * 去岗位详情页
     * */
    toJobDetails(jobId) {
      if (this.userType === 'hr') {
        this.hrToHrSeeJobDetails(jobId)
      }
      if (this.userType === 'employee') {
        this.employeeToJobDetails(jobId)
      }
    },

    /**
     * 去简历页
     * */
    toResumePage() {
      if (this.userType === 'hr') {
        let employeeId = this.chatSessionInfo.user_id.split('-')[1]
        this.hrToJobSeekerDetails(employeeId)
      }
      if (this.userType === 'employee') {
        this.employeeToJobSeekerResume()
      }
    },

    /**
     * 求职者去岗位详情页
     * */
    async employeeToJobDetails(jobId) {
      const res = await JobDetailApi({
        jobId: jobId
      })

      if (res && res.enterpriseInformation.enterpriseStatus === 1) {
        this.$router.push({
          path: '/jobDetailed',
          query: {
            id: jobId
          }
        })
      }else {
        share.message.warning('该HR的企业信息异常')
      }
    },

    /**
     * 求职者去简历详情页
     * */
    employeeToJobSeekerResume() {
      this.$router.push('/myResume')
    },

    /**
     * hr去简历详情页
     * */
    hrToJobSeekerDetails(employeeId) {
      if (!this.jobId) {
        share.message.warning('您当前没有发布岗位')
        return
      }
      this.$router.push({
        path: '/resumeDetails',
        query: {
          id: employeeId,
          jobId: this.jobId,
        }
      })
    },

    /**
     * hr去岗位详情页
     * */
    hrToHrSeeJobDetails(jobId) {
      this.$router.push({
        path: '/hrSeeJobDetailed',
        query: {
          id: jobId
        }
      })
    },


    /**
     * 点击消息时鼠标按压事件
     * */
    messageOnmousedown(e, data, className) {
      if (data.sender !== this.myChatUserId) {
        return
      }
      if (e.button === 2) {
        if (this.messageMenuTargetDom) {
          let domArr = document.getElementsByClassName('messageMenuTargetDom')
          if (domArr && domArr.length > 0) {
            this.messageMenuTargetDom.removeChild(domArr[0])
            this.messageMenuTargetDom = null
          }
        }
        this.$nextTick(() => {
          let targetDom = e.path[0]
          while (targetDom.className.indexOf(className) === -1) {
            targetDom = targetDom.parentNode
          }
          this.messageMenuTargetDom = targetDom
          let right = targetDom.offsetWidth + 20

          let dom = document.createElement('div')
          dom.className = 'messageMenuTargetDom'
          dom.style.cssText = `width: 100px; height: 30px; position: absolute; right: ${right}px; top: 0px; white-space: normal; direction: rtl;`
          let innerHTML1 = `
<div style="width: 100px; height: 30px; display: flex; align-items: center; justify-content: center;
background-color: #4b4b4b; color: #FFFFFF; white-space: normal; direction: rtl; border-radius: 5px; position: relative;">
  <span class="messageItemActions" style="flex: 1; text-align: center; line-height: 20px; font-size: 12px; cursor: pointer;" data-messageid="${data.id}" data-actiontype="1">复制</span>
  <div style="width: 1px; height: 20px; background-color: #ffffff;"></div>
  <span class="messageItemActions" style="flex: 1; text-align: center; line-height: 20px; font-size: 12px; cursor: pointer;" data-messageid="${data.id}" data-actiontype="2">撤回</span>
  <div class="arrows" style="position: absolute; right: -18px; top: 5px; height: 0; width: 0;
  border: 10px solid #4b4b4b; border-color: transparent transparent transparent #4b4b4b;"></div>
</div>
`
          let innerHTML2 = `
<div style="width: 100px; height: 30px; display: flex; align-items: center; justify-content: center;
background-color: #4b4b4b; color: #FFFFFF; white-space: normal; direction: rtl; border-radius: 5px; position: relative;">
  <span class="messageItemActions" style="flex: 1; text-align: center; line-height: 20px; font-size: 12px; cursor: pointer;" data-messageid="${data.id}" data-actiontype="2">撤回</span>
  <div class="arrows" style="position: absolute; right: -18px; top: 5px; height: 0; width: 0;
  border: 10px solid #4b4b4b; border-color: transparent transparent transparent #4b4b4b;"></div>
</div>
`
          if (data.content.type === 'text') {
            dom.innerHTML = innerHTML1
          } else {
            dom.innerHTML = innerHTML2
          }
          targetDom.appendChild(dom)

          this.$nextTick(() => {
            let messageItemActionsDom = document.getElementsByClassName('messageItemActions')
            for (let i = 0; i < messageItemActionsDom.length; i++) {
              messageItemActionsDom[i].onclick = this.toMessageRevoked
            }
          })

        })
      }
    },

    /**
     * 长按菜单
     * */
    async toMessageRevoked(e) {
      e.stopPropagation()
      if (this.messageActionLoading) {
        return
      }
      this.messageActionLoading = true
      let messageId = e.target.dataset.messageid
      let actionType = e.target.dataset.actiontype
      /*
      * 撤回消息
      * */
      if (actionType === '2') {
        const res = await this.aonuoChat.postRecallMessage(messageId)
        if (res.code === 400) {
          share.message.warning(res.message)
        }
      }
      /*
      * 复制文本
      * */
      if (actionType === '1') {
        let text = ''
        try{
          this.listData.forEach((item, index) => {
            if (item.id === parseInt(messageId)) {
              text = item.content.text
              throw Error()
            }
          })
        }catch(e){
          console.log("结束了")
        }
        // 传复制的文本
        let copyipt = document.createElement("input");
        copyipt.setAttribute("value", text);
        document.body.appendChild(copyipt);
        copyipt.select();
        document.execCommand("copy");
        document.body.removeChild(copyipt);
      }

      if (this.messageMenuTargetDom) {
        let domArr = document.getElementsByClassName('messageMenuTargetDom')
        if (domArr && domArr.length > 0) {
          this.messageMenuTargetDom.removeChild(domArr[0])
          this.messageMenuTargetDom = null
        }
      }
      this.messageActionLoading = false
    },

    /**
     * 去卡片指定的页面
     * */
    clickCardAction(obj) {
      if (obj.data && obj.data.toPage) {
        let data = share.getEnumeratePagePath(obj.data.toPage, obj.data.param)
        this.$router.push({
          path: data.path,
          query: data.param
        })
      }
    },

    /**
    * 发送自定义消息
    * */
    sendMsg(msg) {
      this.$emit('sendQuestion', msg)
    },

    /**
     * 答案页按钮点击
     * */
    answerCardBtnClick(toAction) {
      if (this.userType === 'hr') {
        if (toAction === 'logOut') {
          this.setUserinfo({
            place: {
              value: '',
              label: ''
            },
          })
          this.aonuoChat.logout()
          share.loginOut()
          this.setUserinfo({
            userType: 'employee',
          })
          this.$router.push('/login')
        }else if (toAction === 'cancelAccount') {
          share.message.warning('请在佳薪职聘APP中注销账号')
        }else if (toAction === 'reviewInterviewNotes') {
          sessionStorage.setItem('currNavPage', '4')
          let url = this.toActionOptionsHR[toAction]
          this.$router.push({
            path: url,
          })
        }else if (toAction === 'publishRedPacketJob') {
          let url = this.toActionOptionsHR[toAction]
          this.$router.push({
            path: url,
            query: {
              jobType: '1'
            }
          })
        }else {
          let url = this.toActionOptionsHR[toAction]
          this.$router.push({
            path: url,
          })
        }
      }else {
        if (toAction === 'logOut') {
          this.aonuoChat.logout()
          share.loginOut()
          this.setUserinfo({
            userType: 'employee',
          })
          this.$router.push('/login')
        }else if (toAction === 'cancelAccount') {
          share.message.warning('请在佳薪职聘APP中注销账号')
        }else if (toAction === 'redPacketJobSearch') {
          sessionStorage.setItem('activePageIndex', '2')
          let url = this.toActionOptions[toAction]
          this.$router.push({
            path: url,
          })
        } else {
          let url = this.toActionOptions[toAction]
          this.$router.push({
            path: url,
          })
        }

      }

    },

    /**
     * 初始化文本
     * */
    textInit (value) {  // 时间格式转换
      return value.replace(/\\n/g, '<br>')
    },

    /**
     * 去红包申请页
     * */
    toApplyRedEnvelope() {
      if (this.userType === 'hr') {
        this.hrToApplyRedEnvelope()
      }
      if (this.userType === 'employee') {
        this.employeeToApplyRedEnvelope()
      }
    },

    /**
     * hr去红包申请页
     * */
    hrToApplyRedEnvelope() {
      this.$router.push({
        path: '/enterpriseDynamic',
        query: {
          index: '6'
        }
      })
      this.$emit('changeHeaderMenu', 3)
    },

    /**
     * 求职者去红包申请页
     * */
    employeeToApplyRedEnvelope() {
      this.$router.push({
        path: '/personalDynamic',
        query: {
          index: '7',
          currHeaderMenu: 5
        }
      })

      this.$emit('changeHeaderMenu', 5)
    },

    /*
    * 判断头像是否显示
    * */
    avatarImgShow(item) {
      return item.sender !== this.myChatUserId && item.content.type !== 'current-job-card' && item.content.type !== 'system' && !item.is_recall
    },
    /*
    * 判断头像是否显示
    * */
    myAvatarImgShow(item) {
      return item.sender === this.myChatUserId && item.content.type !== 'current-job-card' && item.content.type !== 'system' && !item.is_recall
    },

  }

}
</script>

<style lang="less" scoped>
.chatMessageList_warp {
  width: 100%;
  flex: 1;
  overflow: hidden;
  background-color: #FFFFFF;
  border-right: 1px solid #DCDFE6;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .message-header {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #eaeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    background-color: #FFFFFF;
    .title {
      color: #1b1b1b;
      font-size: 16px;
    }
  }

  .current-job-card {
    width: 100%;
    height: 30px;
    border-bottom: 1px solid #eaeaea;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    font-size: 14px;
    z-index: 999;
    .left {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      span {
        margin-right: 10px;
      }
      .highlight {
        color: #e20000;
      }
    }
    .right {
      span {
        color: #e20000;
      }
    }
  }

  .list-warp {
    width: 100%;
    flex: 1;
    overflow-y: scroll;

    .list {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      direction: rtl;
      transform: rotate(180deg);

      .cell {
        width: 100%;
        direction: ltr;
        transform: rotate(180deg);

        /* 时间戳消息 */
        .message-time {
          width: 100%;
          padding-bottom: 20px;
          box-sizing: border-box;
          .text-warp {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .text {
              font-size: 14px;
              color: #999999;
              direction: ltr;
            }
          }
        }

        .message-warp {
          width: 100%;
          display: flex;
          flex-direction: row;
          padding-bottom: 20px;
          box-sizing: border-box;

          /*   头像   */
          .message-avatar {
            .avatar-img {
              width: 40px;
              height: 40px;
              border-radius: 4px;
              cursor: pointer;
            }
          }

          /*  消息内容  */
          .message-item-body {
            flex: 1;
            display: flex;
            flex-direction: row;

            /* 系统提示消息 */
            .system-messages {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              .text {
                font-size: 14px;
                color: #999999;
                direction: ltr;
              }
            }

            /* 撤回消息提示 */
            .recall-messages {
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              .text {
                font-size: 14px;
                color: #999999;
              }
            }

            /* 文本消息 */
            .text-message {
              flex: 1;
              box-sizing: border-box;
              .message-value {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                box-sizing: border-box;
                .text-message-warp {
                  box-sizing: border-box;
                  position: relative;
                  .text {
                    max-width: 430px;
                    padding: 8px 12px;
                    box-sizing: border-box;
                    border-radius: 5px;
                    font-size: 18px;
                    color: #232323;
                    white-space: pre-wrap;
                    position: relative;

                    .message-status {
                      position: absolute;
                      top: 50%;
                      transform: translateY(-50%);
                      .message-loading-icon {
                        width: 20px;
                        height: 20px;
                      }
                      .message-error-icon {
                        width: 20px;
                        height: 20px;
                      }
                      .message-status-text-warp {
                        background-color: #fff6f6;
                        padding: 4px 4px;
                        box-sizing: border-box;
                        border-radius: 4px;
                        font-size: 14px;
                        .message-status-text {
                          color: #BBBBBB;
                        }
                      }
                    }
                  }
                  .text-url {
                    cursor: pointer;
                    text-align: right;
                    color: #63A7FF;
                    font-size: 14px;
                    padding-top: 2px;
                    direction: ltr;
                  }
                }
              }
            }

            /* 图片消息 */
            .image-message {
              flex: 1;
              box-sizing: border-box;
              .message-value {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                box-sizing: border-box;
                .image-warp {
                  position: relative;
                  .image {
                    max-width: 430px;
                    cursor: pointer;
                  }
                  .message-status {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    .message-loading-icon {
                      width: 20px;
                      height: 20px;
                    }
                    .message-error-icon {
                      width: 20px;
                      height: 20px;
                    }
                    .message-status-text-warp {
                      background-color: #fff6f6;
                      padding: 4px 4px;
                      box-sizing: border-box;
                      border-radius: 4px;
                      font-size: 14px;
                      .message-status-text {
                        color: #BBBBBB;
                      }
                    }
                  }
                }
              }
            }

            /* 语音消息 */
            .voice-message {
              flex: 1;
              .message-value {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                box-sizing: border-box;
                .voice-warp {
                  box-sizing: border-box;
                  position: relative;
                  .voice {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    cursor: pointer;
                    .x-audio-wrap {
                      width: 130px;
                      justify-content: center;
                      & /deep/ .x-dot {
                        top: 3px;
                        left: 0px;
                      }
                      & /deep/ .x-time {
                        color: #FFFFFF;
                      }
                    }
                    .message-status {
                      position: absolute;
                      top: 50%;
                      transform: translateY(-50%);
                      .message-loading-icon {
                        width: 20px;
                        height: 20px;
                      }
                      .message-error-icon {
                        width: 20px;
                        height: 20px;
                      }
                      .message-status-text-warp {
                        background-color: #fff6f6;
                        padding: 4px 4px;
                        box-sizing: border-box;
                        border-radius: 4px;
                        font-size: 14px;
                        .message-status-text {
                          color: #BBBBBB;
                        }
                      }
                    }
                  }
                }
              }
            }

            /* 岗位详情卡片消息 简历详情卡片消息 */
            .job-card-message, .resume-card-message {
              flex: 1;
              box-sizing: border-box;
              .message-value {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                .job-card-warp, .resume-card-warp {
                  cursor: pointer;
                  position: relative;
                  .job-card, .resume-card {
                    //width: 430px;
                    width: 350px;
                    border-radius: 10px;
                    background-color: #FFFFFF;
                    border: 1px solid #d9d9d9;
                    padding: 10px 20px;
                    box-sizing: border-box;
                    .title {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      margin-bottom: 10px;
                      .name {
                        margin-right: 20px;
                        font-size: 20px;
                        font-weight: bold;
                        direction: ltr;
                      }
                      .salary {
                        text-align: right;
                        min-width: 100px;
                        color: #E82727;
                        font-size: 20px;
                      }
                    }
                    .enterprise {
                      width: 100%;
                      font-size: 18px;
                      direction: ltr;
                      margin-bottom: 10px;
                    }
                    .tag {
                      display: flex;
                      flex-wrap: wrap;
                      .tag-item {
                        background-color: #d7d7d7;
                        border-radius: 4px;
                        padding: 4px 10px;
                        box-sizing: border-box;
                        margin-right: 10px;
                        margin-bottom: 10px;
                        border: 1px solid #d7d7d7;
                        font-size: 16px;
                      }
                      .tag-item2 {
                        background-color: #FFFFFF;
                        border-radius: 4px;
                        padding: 4px 10px;
                        box-sizing: border-box;
                        margin-right: 10px;
                        margin-bottom: 10px;
                        border: 1px solid #E82727;
                        font-size: 16px;
                        color: #E82727;
                      }
                    }
                    .job-expectations {
                      margin-bottom: 10px;
                      .place, .job {
                        display: flex;
                        flex-wrap: wrap;
                        margin-bottom: 10px;
                        .place-text, .job-text {
                          font-size: 16px;
                          margin-right: 10px;
                          direction: ltr;
                        }
                      }
                    }
                    .information {
                      display: flex;
                      flex-wrap: wrap;
                      margin-bottom: 10px;

                      .information-item {
                        margin-right: 20px;
                        margin-bottom: 4px;
                        display: flex;
                        align-items: center;

                        .information-item-img {
                          width: 20px;
                          height: 20px;
                          margin-right: 4px;
                        }
                        .information-item-text {
                          font-size: 18px;
                        }
                      }
                    }
                    .describe {
                      width: 100%;
                      margin-bottom: 10px;
                      .describe-title {
                        font-size: 18px;
                        direction: ltr;
                      }
                      .describe-title-content {
                        font-size: 18px;
                        direction: ltr;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      }
                    }
                    .welfare {
                      width: 100%;
                      display: flex;
                      flex-wrap: wrap;
                      .welfare-item {
                        background-color: #d7d7d7;
                        border-radius: 4px;
                        padding: 4px 10px;
                        box-sizing: border-box;
                        margin-right: 10px;
                        margin-bottom: 10px;
                        font-size: 16px;
                      }
                    }
                  }
                  .message-status {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    .message-loading-icon {
                      width: 20px;
                      height: 20px;
                    }
                    .message-error-icon {
                      width: 20px;
                      height: 20px;
                    }
                    .message-status-text-warp {
                      background-color: #fff6f6;
                      padding: 4px 4px;
                      box-sizing: border-box;
                      border-radius: 4px;
                      font-size: 14px;
                      .message-status-text {
                        color: #BBBBBB;
                      }
                    }
                  }
                }
              }
            }

            /* 当前沟通的岗位详情卡片消息 */
            .current-job-card-message {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              .text {
                font-size: 14px;
                color: #999999;
                direction: ltr;
              }
            }

            /* 图片或文字或图文卡片 */
            .image-or-text-card-message, .text-card-message, .hr-no-action-card-message {
              flex: 1;
              box-sizing: border-box;
              .message-value {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                .image-or-text-card-warp, .text-card-warp, .hr-no-action-card-warp {
                  cursor: pointer;
                  position: relative;
                  .image-or-text-card, .text-card, .hr-no-action-card {
                    //width: 430px;
                    width: 350px;
                    border-radius: 10px;
                    background-color: #FFFFFF;
                    border: 1px solid #d9d9d9;
                    padding: 10px 20px;
                    box-sizing: border-box;
                    cursor: pointer;
                    .card-title {
                      text-align: left;
                      font-size: 16px;
                      font-weight: bold;
                      padding: 10px 10px 0 10px;
                      box-sizing: border-box;
                      margin-bottom: 4px;
                    }
                    .img {
                      max-width: 100%;

                      img {
                        max-width: 100%;
                      }
                    }
                    .msg {
                      padding: 0px 10px;
                      box-sizing: border-box;
                      font-size: 12px;
                      color: #5E5E5E;
                      text-align: left;
                      margin-bottom: 4px;
                    }
                    .action {
                      width: 100%;
                      height: 40px;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      border-top: 1px solid #dddddd;
                      padding: 0px 10px;
                      box-sizing: border-box;
                      .action-left {
                        color: #63A7FF;
                      }
                      .action-right {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                          width: 20px;
                          height: 20px;
                        }
                      }
                    }
                  }
                  .message-status {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    .message-loading-icon {
                      width: 20px;
                      height: 20px;
                    }
                    .message-error-icon {
                      width: 20px;
                      height: 20px;
                    }
                    .message-status-text-warp {
                      background-color: #fff6f6;
                      padding: 4px 4px;
                      box-sizing: border-box;
                      border-radius: 4px;
                      font-size: 14px;
                      .message-status-text {
                        color: #BBBBBB;
                      }
                    }
                  }
                }
              }
            }

            /* 问题卡片消息 */
            .questions-card-message {
              flex: 1;
              box-sizing: border-box;
              .message-value {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                .questions-card-warp {
                  cursor: pointer;
                  .questions-card {
                    width: 300px;
                    border-radius: 10px;
                    background-color: #FFFFFF;
                    border: 1px solid #d9d9d9;
                    padding: 10px 20px;
                    box-sizing: border-box;
                    .questions-card-title {
                      font-size: 18px;
                      font-weight: bold;
                      padding-bottom: 10px;
                      border-bottom: 1px solid #F2F2F2;
                      margin-bottom: 10px;
                    }
                    .question {
                      color: #23a09e;
                      font-size: 16px;
                      margin: 10px 0;
                      .specific-question {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 30px;
                        width: 130px;
                        border: 1px solid #23a09e;
                        border-radius: 20px;
                      }
                    }
                  }
                }
              }
            }

            /* 答案卡片消息 */
            .answer-card-message {
              flex: 1;
              box-sizing: border-box;
              .message-value {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                .answer-card-warp {
                  cursor: pointer;
                  .answer-card {
                    width: 300px;
                    border-radius: 10px;
                    background-color: #FFFFFF;
                    border: 1px solid #d9d9d9;
                    padding: 10px 20px;
                    box-sizing: border-box;
                    .answer-card-title {
                      font-size: 18px;
                      font-weight: bold;
                      padding-bottom: 10px;
                      border-bottom: 1px solid #F2F2F2;
                      margin-bottom: 10px;
                    }
                    .answer-card-text-warp {
                      font-size: 16px;
                      white-space: pre-line;
                      line-height: 30px;
                    }
                    .answer-card-btn {
                      margin-top: 10px;
                      .answer-card-btn-text {
                        font-size: 16px;
                        color: #63A7FF;
                        text-decoration: underline;
                      }
                    }
                  }
                }
              }
            }

          }

          /*  别人发的 消息内容  */
          .message-item-body-other {

            /* 文本消息 */
            .text-message {
              padding-left: 10px;
              .message-value {
                .text-message-warp {
                  .text {
                    background-color: #f2f5fa;
                  }
                  .text-url {
                  }
                }
              }
            }

            /* 图片消息 */
            .image-message {
              padding-left: 10px;
            }

            /* 语音消息 */
            .voice-message {
              padding-left: 10px;
              .message-value {
                .voice-warp {
                  .voice {
                    .x-audio-wrap {
                      background-color: #f2f5fa;
                    }
                  }
                }
              }
            }

            /* 岗位详情卡片消息 */
            .job-card-message, .resume-card-message {
              padding-left: 10px;
              .message-value {
                .job-card-warp, .resume-card-warp {
                  .job-card, .resume-card {
                    .title {
                      flex-direction: row;
                    }
                    .tag {
                      flex-direction: row;
                    }
                    .job-expectations {
                      .place,.job {
                        flex-direction: row;
                      }
                    }
                    .information {
                      flex-direction: row;
                      .information-item {
                        flex-direction: row;
                      }
                    }
                    .welfare {
                      flex-direction: row;
                    }
                  }
                }
              }
            }

            /* 图片或文字或图文卡片 */
            .image-or-text-card-message, .text-card-message, .hr-no-action-card-message {
              padding-left: 10px;
              .message-value {
                .image-or-text-card-warp, .text-card-warp, .hr-no-action-card-warp {
                  .image-or-text-card, .text-card, .hr-no-action-card {
                    .action {
                      flex-direction: row;
                    }
                  }
                }
              }
            }

            /* 问题卡片消息 */
            .questions-card-message {
              padding-left: 20px;
            }

            /* 答案卡片消息 */
            .answer-card-message {
              padding-left: 20px;
            }

          }

          /*  我发的 消息内容  */
          .message-item-body-my {

            /* 文本消息 */
            .text-message {
              padding-right: 10px;
              .message-value {
                .text-message-warp {
                  .text {
                    background-color: #00B26A;
                  }
                  .message-status {
                    left: -50px;
                  }
                  .text-url {
                  }
                }
              }
            }

            /* 图片消息 */
            .image-message {
              padding-right: 10px;
              .message-value {
                .image-warp {
                  .message-status {
                    left: -50px;
                  }
                }
              }
            }

            /* 语音消息 */
            .voice-message {
              padding-right: 10px;
              .message-value {
                .voice-warp {
                  .voice {
                    .x-audio-wrap {
                      background-color: #00B26A;
                    }
                    .message-status {
                      left: -50px;
                    }
                  }
                }
              }
            }

            /* 岗位详情卡片消息 */
            .job-card-message, .resume-card-message {
              padding-right: 10px;
              .message-value {
                .job-card-warp, .resume-card-warp {
                  .job-card, .resume-card {
                    .title {
                      flex-direction: row-reverse;
                    }
                    .tag {
                      flex-direction: row-reverse;
                    }
                    .job-expectations {
                      .place,.job {
                        flex-direction: row-reverse;
                      }
                    }
                    .information {
                      flex-direction: row-reverse;
                      .information-item {
                        flex-direction: row-reverse;
                      }
                    }
                    .welfare {
                      flex-direction: row-reverse;
                    }
                  }
                  .message-status {
                    left: -50px;
                  }
                }
              }
            }


            /* 图片或文字或图文卡片 */
            .image-or-text-card-message, .text-card-message, .hr-no-action-card-message {
              padding-right: 10px;
              .message-value {
                .image-or-text-card-warp, .text-card-warp, .hr-no-action-card-warp {
                  .image-or-text-card, .text-card, .hr-no-action-card {
                    .action {
                      flex-direction: row-reverse;
                    }
                  }
                  .message-status {
                    left: -50px;
                  }
                }
              }
            }

          }
        }
      }

      .load-more-wrap {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #999999;

        .span {
          cursor: pointer;
        }

        .el-icon-loading {
          margin-right: 2px;
        }
      }
    }
  }

}
</style>
