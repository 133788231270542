<template>
  <div class="dynamicJobSeekerItem_warp">
    <div class="title">
      <div class="information-item">
        {{name}}
      </div>
      <div class="information-item">
        {{age}}岁
      </div>
      <div class="information-item">
        {{workExperienceYears}}
      </div>
      <div class="information-item">
        {{academicLevelMax}}
      </div>
      <div class="information-item">
        {{jobSearchingStatus}}
      </div>
      <div class="information-item">
        {{time}}
      </div>
    </div>
    <div class="content">
      <el-row :gutter="20">

        <el-col :span="5">
          <div class="avatar">
            <img :src="avatar">
          </div>
        </el-col>

        <el-col :span="6">
          <div class="brief-introduction">
            <div class="job-expectations">
              <div class="job-expectations-title">
                求职期望：
              </div>
              <div class="job-expectations-content">
                <template v-for="(item, index) in jobIntentionList">
                  <div class="job-expectations-item" :key="'jobIntentionList' + index" v-if="item.jobAddress">
                    {{item.jobAddress[item.jobAddress.length - 1]}} | {{item.expectedCategory}}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :span="9">
          <div class="work-experience">
            <div class="work-experience-item" v-for="(item2, index2) in workExperienceList" :key="'workExperienceList' + index2">
              <div class="time">
                {{item2.startDate}} - {{item2.endDate}}
              </div>
              <div class="enterprise-name">
                {{item2.enterpriseName}}
              </div>
              <div class="job-name">
                {{item2.jobName}}
              </div>
            </div>
          </div>
        </el-col>

        <el-col :span="4">
          <div class="btn">
            <el-button>立即沟通</el-button>
          </div>
        </el-col>

      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "dynamicJobSeekerItem",
  props: {
    recordId: {
      type: [String, Number],
      default: '',
    },
    avatar: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
    gender: {
      type: String,
      default: '',
    },
    age: {
      type: [Number, String],
      default: '',
    },
    workExperienceYears: {  // 工作经验年数
      type: [Number, String],
      default: '',
    },
    academicLevelMax: {  // 最高学历
      type: String,
      default: '',
    },
    jobSearchingStatus: {  // 求职状态
      type: [String, Number],
      default: '',
    },
    jobIntentionList: {  // 工作意向列表
      type: Array,
      default: () => []
    },
    workExperienceList: {  // 工作经历
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.dynamicJobSeekerItem_warp {
  width: 100%;
  height: 100%;

  .title {
    font-size: 16px;
    width: 100%;
    height: 25px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    .information-item {
      color: #8b8b8b;
      font-size: 14px;
      margin-right: 10px;

      &:first-child{
        margin-right: 20px;
        font-size: 16px;
        color: #323232;
      }

    }
  }

  .content {
    width: 100%;
    height: calc(100% - 20px);
    margin-top: 5px;
    background-color: #f6f6f6;
    border-radius: 16px;
    box-shadow: 0 5px 10px #d4cfcf;

    .el-row {
      height: 100%;
      width: 100%;

      .el-col {
        height: 100%;
      }
    }

    .avatar {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #323232;
      }
    }

    .brief-introduction {
      color: #3a3a3a;
      font-size: 14px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

    }

    .job-expectations {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .job-expectations-title {
        margin-right: 5px;
      }

      .job-expectations-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .job-expectations-item {
          color: #e20000;
        }
      }
    }

    .work-experience {
      font-size: 14px;
      color: #3a3a3a;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .work-experience-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        >div {
          padding: 0 5px;
          &:first-child {
            padding-left: 0px;
          }
          &:last-child {
            padding-right: 0px;

          }
        }
      }
    }

    .btn {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .el-button {
        padding: 8px 15px;
        background-color: #e20000;
        color: #ffffff;
      }
    }

  }
}
</style>
