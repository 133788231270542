<template>
  <div class="getCode_warp">
    <div class="btn">
      <i v-if="getCodeLoading" class="el-icon-loading" style="margin-right: 5px;"></i>{{ codeText }}
    </div>
    <Vcode :show="vCodeIsShow" @success="success" @close="close"/>
  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";
import {SendMsgCodeApi, SendEmailCodeApi} from "@/request/api";
import share from "@/share/share";

export default {
  name: "getCode",
  components: {
    Vcode
  },
  props: {
    phone: {  // 手机号
      type: String,
      default: ''
    },
    isNotVerify: {  // 是否进行人机验证
      type: Boolean,
      default: false
    },
    mailbox: {  // 邮箱
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: 'phone'
    }
  },
  data() {
    return {
      codeText: '获取验证码',
      vCodeIsShow: false,
      getCodeLoading: false,
      sentCode: false,
      timer: null,
      timeOut: 60,
      count: '',
    }
  },
  methods: {
    getCode() {
      if (this.sentCode === true) {
        share.message.warning('请勿频繁获取')
        return
      }
      if (this.isNotVerify) {
        this.success()
      }else {
        this.vCodeIsShow = true
      }
    },
    async success() {
      this.vCodeIsShow = false

      this.sentCode = true
      this.getCodeLoading = true
      this.codeText = '发送中'

      let res = ''
      if (this.status === 'phone') {
        let ab = /^1[3456789]\d{9}$/
        if (this.phone === '') {
          share.message.error('请输入手机号')
          this.sentCode = false
          this.getCodeLoading = false
          this.codeText = '获取验证码'
          return
        }else if (ab.test(this.phone) === false) {
          share.message.error('手机号格式错误')
          this.sentCode = false
          this.getCodeLoading = false
          this.codeText = '获取验证码'
          return
        }

        res = await SendMsgCodeApi({
          phone: this.phone
        })

      }else {

        let ab = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/
        if (this.mailbox === '') {
          share.message.error('请输入邮箱')
          this.sentCode = false
          this.getCodeLoading = false
          this.codeText = '获取验证码'
          return
        }else if (ab.test(this.mailbox) === false) {
          share.message.error('邮箱格式错误')
          this.sentCode = false
          this.getCodeLoading = false
          this.codeText = '获取验证码'
          return
        }

        res = await SendEmailCodeApi({
          email: this.mailbox
        })

      }

      if (res) {
        this.getCodeLoading = false
        this.count = this.timeOut
        this.codeText = '(' + this.count + 's)' + '后获取'
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= this.timeOut) {
            this.count--
            this.codeText = '(' + this.count + 's)' + '后获取'
          } else {
            clearInterval(this.timer)
            this.codeText = '获取验证码'
            this.timer = null
            this.sentCode = false
          }
        }, 1000)
      }else {
        this.codeText = '获取验证码'
        this.timer = null
        this.getCodeLoading = false
        this.sentCode = false
      }

      this.$emit('success')
    },
    close() {
      this.vCodeIsShow = false
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.getCode_warp {
  width: 100%;
  height: 100%;
  .btn {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
</style>