<template>
  <div class="companyPublishedJobItem_warp" @click="goJobDetailed">

    <div class="left">

      <div class="top">
        <div class="title">
          {{jobName}}
        </div>
        <div class="place" v-if="jobAddress">
<!--          [{{jobAddress[0]}} · {{jobAddress[1]}} · {{jobAddress[2]}}]-->
          [{{jobAddress[2]}}]
        </div>
      </div>

      <div class="bottom">
        <div class="salary">
          {{salary}}
        </div>
        <div class="experience" v-if="experienceRequirementRange">
          {{experienceRequirement}}
        </div>
        <div class="education">
          {{academicRequirement}}
        </div>
<!--        <div class="date">-->
<!--          发布于：{{publishTime}}-->
<!--        </div>-->
      </div>

    </div>

    <div class="right">
      <el-button v-if="isRedEnvelopesJob === 0" @click.stop="toCommunicate">开始沟通</el-button>
      <el-button v-if="isRedEnvelopesJob === 1" @click.stop="toCommunicate">申请红包</el-button>
      <div class="red-envelopes" v-if="isRedEnvelopesJob === 1">
        <red-envelopes :number="amount"></red-envelopes>
      </div>
    </div>

  </div>
</template>

<script>
import RedEnvelopes from "@/components/redEnvelopes/redEnvelopes";
import {GetMyCommunicationInfoApi} from "@/request/api";
import {JobSeekerGreetings} from "@/share/greetings";
import {experience} from "@/share/shareData";
export default {
  name: "companyPublishedJobItem",
  components: {RedEnvelopes},
  props: {
    isRedEnvelopesJob: {
      type: Number,
      default: 0,
    },
    amount: {
      type: [Number, String],
      default: 0,
    },
    jobName: {
      type: String,
      default: ''
    },
    jobAddress: {
      type: Array,
      default: () => []
    },
    salaryRange: {
      type: Array,
      default: () => []
    },
    experienceRequirementRange: {
      type: Array,
      default: () => []
    },
    academicRequirement: {
      type: String,
      default: ''
    },
    publishTime: {
      type: String,
      default: ''
    },
    jobId: {
      type: [Number, String],
      default: ''
    },
    hrId: {
      type: [Number, String],
      default: ''
    },
    enterpriseName: {
      type: String,
      default: ''
    },
    hrName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      // myInfo: {
      //   age: '',  // 求职者年龄
      //   education: '',  // 求职者学历
      //   expectedJobName: '',  // 求职者期望职位
      //   expectedPlace: '',  // 求职期望职位
      //   expectedSalaryRange: '',  // 求职者期望薪资
      //   jobSeekerName: '',  // 求职者姓名
      //   schoolName: '',  // 求职者教育经历的学校
      //   workExperience: '',  // 求职者工作经验
      //   employeeId: '',  // 求职者id
      // }
    }
  },
  computed: {
    salary() {
      let str = ''
      if (this.salaryRange && this.salaryRange.length > 0) {
        // str = this.salaryRange[0] + '-' + this.salaryRange[1]
        str = parseInt(this.salaryRange[0])/1000 + 'k-' + parseInt(this.salaryRange[1])/1000 + 'k'
      }else {
        str = '面议'
      }
      return str
    },
    experienceRequirement() {
      let str = ''
      if (this.experienceRequirementRange && this.experienceRequirementRange.length > 0) {
        let str2 = this.experienceRequirementRange[0] + '-' + this.experienceRequirementRange[1]

        experience.forEach((item, index) => {
          if (item.value === str2) {
            str = item.label + '经验'
          }
        })
      }else {
        str = '经验不限'
      }
      return str
    },
    userType() {
      return this.$store.state.userInfo.userType || ''
    },
    isLogin() {
      let token = this.$store.state.token
      let state = false
      if (token && token !== '' && token !== null && token !== 'null') {
        state = true
      }
      return state
    },
  },
  mounted() {
    // this.GetMyCommunicationInfo()
  },
  methods: {
    // async GetMyCommunicationInfo() {
    //
    //   if (!this.isLogin) {
    //     return
    //   }
    //
    //   const res = await GetMyCommunicationInfoApi({
    //     jobId: this.jobId
    //   })
    //
    //   if (res) {
    //     this.myInfo.age = res.age
    //     this.myInfo.education = res.education
    //     this.myInfo.expectedJobName = res.expectedJobName
    //     this.myInfo.expectedPlace = res.expectedPlace
    //     this.myInfo.expectedSalaryRange = res.expectedSalaryRange
    //     this.myInfo.jobSeekerName = res.jobSeekerName
    //     this.myInfo.schoolName = res.schoolName
    //     this.myInfo.workExperience = res.workExperience
    //     this.myInfo.employeeId = res.employeeId
    //     console.log('GetMyCommunicationInfoApi', this.myInfo)
    //   }
    // },
    goJobDetailed() {
      this.$emit('goJobDetailed', this.jobId)
    },
    toCommunicate() {

      // let workExperience = ''
      // if (this.myInfo.workExperience <= 0) {
      //   workExperience = '无经验'
      // }else {
      //   workExperience = this.myInfo.workExperience + '年经验'
      // }
      //
      // let expectedSalaryRange = ''
      // if (this.myInfo.expectedSalaryRange && this.myInfo.expectedSalaryRange.length > 0) {
      //   expectedSalaryRange = this.myInfo.expectedSalaryRange[0] + '-' + this.myInfo.expectedSalaryRange[1]
      // }else {
      //   expectedSalaryRange = '面议'
      // }
      //
      // let information = {
      //   hr: {
      //     enterpriseName: this.enterpriseName,
      //     hrPosition: '人事',
      //     hrName: this.hrName,
      //     jobName: this.jobName,
      //     salaryRange: this.salary,
      //     place: this.jobAddress[this.jobAddress.length - 1],
      //   },
      //   employee: {
      //     jobSeekerName: this.myInfo.jobSeekerName,
      //     age: this.myInfo.age + '岁',
      //     education: this.myInfo.education,
      //     workExperience: workExperience,
      //     schoolName: this.myInfo.schoolName,
      //     jobName: this.jobName,
      //     expectedJobName: this.myInfo.expectedJobName,
      //     expectedSalaryRange: expectedSalaryRange,
      //     expectedPlace: this.myInfo.expectedPlace,
      //     jobId: this.jobId,
      //     employeeId: this.myInfo.employeeId,
      //   }
      // }
      //
      let toSessionId = 'hr-' + this.hrId
      // let content = JobSeekerGreetings

      this.$emit('toCommunicate', toSessionId)
    }
  }
}
</script>

<style lang="less" scoped>
.companyPublishedJobItem_warp {
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .left {
    height: 100%;
    flex: 1;
    margin-right: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 14px;
    padding-left: 40px;

    .top {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;

      .title {
        font-size: 16px;
        font-weight: bold;
        margin-right: 30px;
        color: #262627;
      }

      .place {
        color: #868686;
      }

    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #868686;

      >div {
        padding: 0 8px;
        border-right: 1px solid #868686;
        height: 15px;
        line-height: 15px;

        &:nth-child(3), &:nth-child(4) {
          border-right: 0px;
        }

      }

      .salary {
        color: #e22626;
        padding-left: 0px;
      }

    }

  }

  .right {
    width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    & /deep/ .el-button {
      color: #e20000;
      border: 1px solid #e20000;
    }

    .red-envelopes {
      width: 60px;
      height: 60px;
      position: absolute;
      left: 80px;
      top: 10px;
      transform:rotate(-30deg);
    }

  }

}
</style>
