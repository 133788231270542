<template>
  <!-- 页面头部组件的背景颜色 -->
  <div class="pageHeader_warp" :style="'background-color: ' + backgroundColor + ';'">

    <el-row :gutter="0">

      <el-col :span="8">
        <div class="header-left" v-if="userType === 'employee'">

          <!-- 积分 -->
          <div class="header-left-item" v-if="!showPhone" @click="headerClickLeft(1)">
            <div class="header-left-item-icon">
              <img src="@/assets/icon/gift.png">
            </div>
            <div class="header-left-item-title">
              积分:{{coin}}
            </div>
          </div>

          <!-- 奖金总额 -->
          <div class="header-left-item" v-if="!showPhone" @click="headerClickLeft(2)">
            <div class="header-left-item-icon">
              <img src="@/assets/icon/gold-coin.png">
            </div>
            <div class="header-left-item-title">
              奖金总额:{{fund}}
            </div>
          </div>

          <!-- 当前地区 -->
          <div class="header-left-item" v-if="!showPhone && isShowPlace">
            <div class="header-left-item-icon">
              <img src="@/assets/icon/location.png">
            </div>
            <div class="header-left-item-title">
              <el-select v-model="selectValue" placeholder="地区" size="mini" @change="handleChange" style="width: 100px;">
                <el-option
                  v-for="(item, index) in areasOptions"
                  :key="'areasOptions' + index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>

        </div>

        <div class="header-left" v-if="userType === 'hr'">
          <!-- 当前地区 -->
          <div class="header-left-item" v-if="!showPhone && currPage === 1">
            <div class="header-left-item-icon">
              <img src="@/assets/icon/location.png">
            </div>
            <div class="header-left-item-title">
                <el-cascader
                    size="mini"
                    popper-class="login-page-header-left-choose-area"
                    expand-trigger="hover"
                    :show-all-levels="false"
                    placeholder="地区"
                    :options="areasOptions2"
                    :props="{ value: 'value', label: 'label', expandTrigger: 'hover', multiple: true }"
                    v-model="selectValue2"
                    @change="handleChange2"
                    collapse-tags
                    clearable
                >
                </el-cascader>
            </div>
          </div>
        </div>
      </el-col>

      <el-col :span="8">
        <div class="header-center" v-if="userType === 'employee'">
          <div v-if="showMenu" :class="currPage === 1?'center-item center-item-checked':'center-item'" @click="changeCurrPage(1)">首页</div>
          <div v-if="showMenu" :class="currPage === 2?'center-item center-item-checked':'center-item'" @click="changeCurrPage(2)">找工作</div>
          <div v-if="showMenu" :class="currPage === 3?'center-item center-item-checked':'center-item'" @click="changeCurrPage(3)">领现金</div>
          <div v-if="showMenu" :class="currPage === 4?'center-item center-item-checked':'center-item'" @click="changeCurrPage(4)">消息通知<div class="point" v-if="messageUnreadCount && messageUnreadCount > 0"></div></div>
          <div v-if="showMenu" :class="currPage === 5?'center-item center-item-checked':'center-item'" @click="changeCurrPage(5)">个人中心</div>
        </div>
        <div class="header-center" v-if="userType === 'hr'">
          <div v-if="showMenu" :class="currPage === 1?'center-item center-item-checked':'center-item'" @click="changeCurrPage(1)">招人才</div>
          <div v-if="showMenu" :class="currPage === 2?'center-item center-item-checked':'center-item'" @click="changeCurrPage(2)">消息通知<div class="point" v-if="messageUnreadCount && messageUnreadCount > 0"></div></div>
          <div v-if="showMenu" :class="currPage === 3?'center-item center-item-checked':'center-item'" @click="changeCurrPage(3)">企业中心<div class="point" v-if="joinUnreadCount && joinUnreadCount > 0"></div></div>
        </div>
      </el-col>

      <el-col :span="8">
        <!-- 未显示客服手机号时的头部右侧区域 -->
        <div class="header-right" v-if="!showPhone">

          <!-- 登录 -->
          <div class="header-right-item">
            <div class="header-right-item-icon" v-if="!isLogin">
              <img src="@/assets/icon/user.png">
            </div>
            <div class="header-right-item-title header-right-item-login" @click="headerClick(1)" v-if="!isLogin">
              登录
            </div>

            <div class="header-right-item-title header-right-item-exit" @click="headerClick(1)" v-if="isLogin">

              <el-dropdown trigger="click" @command="userExit">

                <div class="header-right-item-title header-right-item-exit">
                  <el-avatar class="profile-photo" size="small">
                    <img :src="profilePhoto" />
                  </el-avatar>
                  {{username}}
                </div>

                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="userExit">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>

            </div>

          </div>

          <!-- 消息通知 -->
          <!--      <div class="header-right-item">-->
          <!--        <div class="header-right-item-icon">-->
          <!--          <img src="@/assets/icon/volume.png">-->
          <!--        </div>-->
          <!--        <div class="header-right-item-title" @click="headerClick(2)">-->
          <!--          消息通知-->
          <!--        </div>-->
          <!--      </div>-->

          <!-- 客服 -->
          <div class="header-right-item" @click="headerClick(2)">
            <div class="header-right-item-icon">
              <img src="@/assets/icon/customer-service.png">
            </div>
            <div class="header-right-item-title">
              客服
            </div>
          </div>

        </div>

        <!-- 显示客服手机号时的头部右侧区域 -->
        <div class="phone-right" v-if="showPhone">
            <img src="@/assets/icon/phone.png">客户服务热线：15312596395
        </div>
      </el-col>

    </el-row>

  </div>
</template>

<script>
/* 引用全国所有地区 JSON 数据 */
import { areas, cityUnderDirectJurisdiction } from "@/share/shareData"
import {mapMutations} from "vuex"
import { MP } from './loadBMap.js'
import {GetAreaCoinApi, GetAreaListApi, JobHrSeeJobDetailApi} from '@/request/api'
import share from "@/share/share";
import {Open_Map} from "@/config/config";
let defaultProfilePhoto = require('@/assets/icon/profile-photo.png')

export default {
  name: "pageHeader",
  props: {
    currPage: {
      type: [String, Number],
      default: 1
    },
    /* 背景颜色 */
    backgroundColor: {
      type: String,
      default: '#ffffff'
    },
    /* 是否显示客服电话 */
    showPhone: {
      type: Boolean,
      default: false
    },
    /* 是否显示选择地区 */
    isShowPlace: {
      type: Boolean,
      default: true
    },
    /* 是否显示menu菜单 */
    showMenu: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      areasOptions: [],
      areasOptions2: [],
      selectValue: '',  // 当前选中的地区
      selectLabel: '',
      selectValue2: [],
      selectLabel2: '',
      // ak:'YhUXfgnpgY0Q1GfOLbX8aFzxzqI9XOCN',  //百度地图秘钥
      fund: 0,  // 奖金总额
      coin: 0,  // 积分

      newTimer: null,

      isReady: 0,
      isReady2: 0,
      getLocationValue: {},
    }
  },
  computed: {
    isLogin() {
      let token = this.$store.state.token
      let state = false
      if (token && token !== '' && token !== null && token !== 'null') {
        state = true
      }else {
        state = false
      }
      return state
    },
    userType() {
      return this.$store.state.userInfo.userType  // employee:雇员，hr:雇主
    },
    username() {
      return this.$store.state.userInfo.username || ''
    },
    profilePhoto() {
      return this.$store.state.userInfo.profilePhoto || defaultProfilePhoto
    },
    BMap() {
      return this.$store.state.BMap
    },
    // isChangePlace() {
    //   return this.$store.state.isChangePlace
    // }

    place() {
      return this.$store.state.userInfo.place
    },
    aonuoChat() {
      return this.$store.state.aonuoChat
    },
    messageUnreadCount() {
      return this.$store.state.messageUnreadCount
    },
    joinUnreadCount() {
      return this.$store.state.joinUnreadCount
    },
    hrCurrentRepresentative() {
      return this.$store.state.hrCurrentRepresentative
    },
    // hrPublishJobList() {
    //   return this.$store.state.hrPublishJobList
    // },
    hrSelectArea() {
      return this.$store.state.hrSelectArea
    },
  },
  watch: {
    // selectedArea: {
    //   handler: function () {
    //     if (this.selectedArea && this.selectedArea !== '') {
    //       this.$emit('selectedArea', this.selectedArea)
    //     }
    //   },
    //   immediate: true
    // }

    place: {
      handler: function () {
        if (this.userType !== 'hr') {
          this.loadData()
        }
      },
      immediate: true
    },
    BMap: {
      handler: function () {
        if (Open_Map && this.BMap) {
          console.log('当前位置信息', this.place)
          if (this.place && this.place.value !== '') {
            this.getLocationValue = {
              cityCode: this.place.value
            }
            this.isReady++
          }else {
            this.getLocation()
          }
        }
      },
      immediate: true
    },
    isReady: {
      handler: function () {
        if (Open_Map && this.isReady >= 2) {
          console.log('触发了isReady', this.isReady)
          this.initPlace()
        }
      },
      immediate: true
    },
    'hrCurrentRepresentative.value': {
      handler: async function () {
        if (this.hrCurrentRepresentative && this.hrCurrentRepresentative.value) {
          const res = await JobHrSeeJobDetailApi({
            jobId: this.hrCurrentRepresentative.value,
          })
          if (res && res.jobInformation && !res.jobInformation.isClose && res.jobInformation.jobStatus === 3) {
            let jobAddressCode = res.jobInformation.jobAddressCode
            let province = this.getArrCode(areas, jobAddressCode[0])
            console.log('province', province)
            let city = this.getArrCode(province.children, jobAddressCode[1])
            console.log('city', city)
            // this.cityCode = jobAddressCode[1]
            this.setHrSelectCityCode({
              hrSelectCityCode: jobAddressCode[1]
            })
            this.areasOptions2 = city.children
            if (this.hrSelectArea && this.hrSelectArea.length > 0) {
              this.selectValue2 = JSON.parse(JSON.stringify(this.hrSelectArea))
              console.log('selectValue2', this.selectValue2)
              if (this.selectValue2 && this.selectValue2.length > 0 && this.areasOptions2 && this.areasOptions2.length > 0) {
                let state = false
                this.areasOptions2.forEach((item, index) => {
                  if (item.value === this.selectValue2[0]) {
                    state = true
                  }
                })
                if (!state) {
                  this.selectValue2 = []
                }
              }else {
                this.selectValue2 = []
              }
            }
            console.log('selectValue2', this.selectValue2)
            console.log('-------------2------------', this.areasOptions2)
          }

          // this.hrPublishJobList.forEach((item, index) => {
          //   if (item.jobId === this.hrCurrentRepresentative.value ) {
          //     let jobAddressCode = item.jobAddressCode
          //     let province = this.getArrCode(areas, jobAddressCode[0])
          //     console.log('province', province)
          //     let city = this.getArrCode(province.children, jobAddressCode[1])
          //     console.log('city', city)
          //     // this.cityCode = jobAddressCode[1]
          //     this.setHrSelectCityCode({
          //       hrSelectCityCode: jobAddressCode[1]
          //     })
          //     this.areasOptions2 = city.children
          //
          //     if (this.hrSelectArea && this.hrSelectArea.length > 0) {
          //       this.selectValue2 = JSON.parse(JSON.stringify(this.hrSelectArea))
          //       console.log('selectValue2', this.selectValue2)
          //       if (this.selectValue2 && this.selectValue2.length > 0 && this.areasOptions2 && this.areasOptions2.length > 0) {
          //         let state = false
          //         this.areasOptions2.forEach((item, index) => {
          //           if (item.value === this.selectValue2[0]) {
          //             state = true
          //           }
          //         })
          //         if (!state) {
          //           this.selectValue2 = []
          //         }
          //       }else {
          //         this.selectValue2 = []
          //       }
          //     }
          //
          //     console.log('selectValue2', this.selectValue2)
          //     console.log('-------------2------------', this.areasOptions2)
          //   }
          // })
          this.$nextTick(() => {
            this.$emit('chooseJobValueChangeSuccess')
          })
        }
        this.isReady2++
      },
      // immediate: true
    },
    // 'hrCurrentRepresentative.value': {
    //   handler: function () {
    //     // this.placeValue = {
    //     //   value: [],
    //     //   label: []
    //     // }
    //   },
    //   immediate: true
    // }
    isReady2() {
      if (this.isReady2 >= 2) {
        this.setHrSelectArea({
          hrSelectArea: this.selectValue2
        })
      }
    }
  },
  async mounted() {

    if (this.userType === 'hr') {

      if (this.hrSelectArea && this.hrSelectArea.length > 0) {
        this.selectValue2 = JSON.parse(JSON.stringify(this.hrSelectArea))
        console.log('selectValue2', this.selectValue2)
        if (this.selectValue2 && this.selectValue2.length > 0 && this.areasOptions2 && this.areasOptions2.length > 0) {
          let state = false
          this.areasOptions2.forEach((item, index) => {
            if (item.value === this.selectValue2[0]) {
              state = true
            }
          })
          if (!state) {
            this.selectValue2 = []
          }
        }else {
          this.selectValue2 = []
        }
      }

      console.log('selectValue2', this.selectValue2)
      console.log('-------------1------------', this.areasOptions2)

    }else {
      await this.loadCity()
      if (!Open_Map) {
        if (this.place && this.place.value) {
          this.selectValue = this.place.value || ''
          this.selectLabel = this.place.label || ''
          if (this.areasOptions.length > 0) {
            let state = false
            this.areasOptions.forEach((item, index) => {
              if (item.value == this.selectValue) {
                state = true
              }
            })
            if (!state) {
              this.selectValue = this.areasOptions[0].value || ''
              this.selectLabel = this.areasOptions[0].label || ''
            }
          }
        }else {
          if (this.areasOptions.length >= 1) {
            this.selectValue = this.areasOptions[0].value || ''
            this.selectLabel = this.areasOptions[0].label || ''
          }else {
            this.selectValue = ''
            this.selectLabel = ''
          }

        }
        let obj = {
          value: this.selectValue,
          label: this.selectLabel
        }
        this.setUserinfo({
          place: obj
        })

      }
    }
    this.isReady2++
  },
  methods: {
    ...mapMutations(['setUserinfo', 'setToken', 'setAreasOptions', 'setHrSelectArea', 'setHrSelectCityCode', 'setJobSeekerFirst']),
    async loadCity() {
      const res = await GetAreaListApi()
      console.log('-------获取city list---------', res)
      if (res) {
        this.areasOptions = res.areaList
        this.setAreasOptions({
          areasOptions: res.areaList
        })
        this.isReady++
      }
    },

    initPlace() {
      if (this.areasOptions && this.areasOptions.length <= 0) {
        console.log('---------获取热门城市列表失败------------')
        return
      }
      console.log('---------获取热门城市列表成功------------')

      if (this.getLocationValue && this.getLocationValue.cityCode && this.getLocationValue.cityCode !== '') {
        console.log('-------------定位成功---------------')
        let state = false
        this.areasOptions.forEach((item, index) => {
          if (item.value === this.getLocationValue.cityCode) {
            console.log('--------------已定位-----------')
            state = true
            this.selectValue = item.value || ''
            this.selectLabel = item.label || ''
          }
        })
        if (!state && this.areasOptions.length > 0) {
          console.log('------------定位地区没有开放-------------')
          this.selectValue = this.areasOptions[0].value || ''
          this.selectLabel = this.areasOptions[0].label || ''
        }
      }else {
        console.log('-------------定位失败-----------------')
        this.selectValue = this.areasOptions[0].value || ''
        this.selectLabel = this.areasOptions[0].label || ''
      }

      this.setVuexPlace(this.selectValue, this.selectLabel)
    },

    setVuexPlace(value, label) {
      let obj = {
        value: value || '',
        label: label || ''
      }
      this.setUserinfo({
        place: obj
      })
    },

    getLocation() {
      console.log('-------开始获取位置----------')
      share.getLocation((res) => {
        console.log('-------位置获取成功----------', res)
        this.getLocationValue = res

        this.isReady++
      })
    },

    /**
    * 获取区域积分和资金总额
    * */
    async loadData() {

      const res = await GetAreaCoinApi({
        area: this.place.value
      })
      if(res) {
        this.fund = res.CoinFundInformation.fund
        this.coin = res.CoinFundInformation.coin
      }
    },

    /**
    * 选择地区时触发父组件changeArea方法
    * */
    handleChange(value) {
      this.areasOptions.forEach((item, index) => {
        if (item.value === value) {
          this.selectValue = item.value
          this.selectLabel = item.label
          this.setVuexPlace(this.selectValue, this.selectLabel)
        }
      })
      this.$emit('areaChange')
    },
    handleChange2(value) {
      this.selectValue2.forEach((item, index) => {
        this.selectValue2[index] = item[0]
      })

      this.setHrSelectArea({
        hrSelectArea: this.selectValue2
      })

      // if (this.selectValue2 && this.selectValue2.length > 0) {
      //   let area = this.getAreasCode(this.selectValue2)
      //   this.selectLabel2 = area.label
      //   this.setVuexPlace(area.value, area.label)
      // }else {
      //   this.selectLabel2 = ''
      //   this.setVuexPlace('', '')
      // }
      //
      this.$emit('areaChange')
    },
    getAreasCode() {
      let area = null
      if (this.selectValue2[0]) {
        this.areasOptions2.forEach((item, index) => {
          if (item.value === this.selectValue2[0]) {
            area = item
          }
        })
      }
      if (this.selectValue2[1]) {
        area.children.forEach((item, index) => {
          if (item.value === this.selectValue2[1]) {
            area = item
          }
        })
      }
      if (this.selectValue2[2]) {
        area.children.forEach((item, index) => {
          if (item.value === this.selectValue2[2]) {
            area = item
          }
        })
      }
      return area
    },
    getArrCode(arr, code) {
      let value = {}
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].value === code) {
          value = arr[i]
          break
        }
      }
      return value
    },

    /* 点击右侧按钮时触发父组件actionClick方法 */
    headerClick(num) {
      if (num === 1) {
        if (!this.isLogin) {
          this.$router.push('/login')
        }
      }
      if (num === 2) {

        if (!this.isLogin) {
          this.$router.push('/temporaryCustomerService')
          return
        }

        if (this.userType === 'hr') {
          let obj = {
            currHeaderMenu: 2
          }
          share.toChatWindow('000001', obj, '/chatSectionHomeHr')
        }else {
          share.toChatWindow('000001', '', '/chatSectionHome')
        }

      }
    },

    changeCurrPage(num) {
      this.$emit('changeCurrPage', num)
      if (num === 1) {
        if (this.userType === 'employee') {
          this.$router.push('/')
        }
        if (this.userType === 'hr') {
          this.$router.push('/jobSeeker')
        }
      }
      if (num === 2) {
        if (this.userType === 'employee') {
          this.$router.push('/jobHunting')
        }
        if (this.userType === 'hr') {
          sessionStorage.setItem('enterpriseCenterLeftMenuIndex', 'chatSectionHomeHr')
          this.$router.push({
            path: '/chatSectionHomeHr',
            query: {
              currHeaderMenu: 2,
            }
          })
        }
      }
      if (num === 3) {
        if (this.userType === 'employee') {
          sessionStorage.setItem('personalCenterLeftMenuIndex', 'wealthWithdrawal')
          this.$router.push({
            path: '/wealthWithdrawal',
            query: {
              currHeaderMenu: 3
            }
          })
        }
        if (this.userType === 'hr') {
          sessionStorage.setItem('enterpriseCenterLeftMenuIndex', 'enterpriseInformation')
          this.$router.push({
            path: '/enterpriseCenter',
            query: {
              currHeaderMenu: 3,
            }
          })
        }
      }
      if (num === 4) {
        if (this.userType === 'employee') {
          this.$router.push('/chatSectionHome')
        }
      }
      if (num === 5) {
        if (this.userType === 'employee') {
          sessionStorage.setItem('personalCenterLeftMenuIndex', 'personalInformation')
          this.$router.push({
            path: '/personalInformation',
            query: {
              currHeaderMenu: 5
            }
          })
        }
      }
    },
    headerClickLeft(num) {
      if (this.userType === 'employee') {
        if (num === 1) {
          this.$router.push({
            path: '/pointsCollection',
          })
        }else {
          this.$router.push({
            path: '/wealthWithdrawal',
          })
        }
      }
    },

    /* 用户退出 */
    userExit(data) {
      if (data === 'userExit') {
        this.setJobSeekerFirst({
          jobSeekerFirst: true
        })
        this.setUserinfo({
          place: {
            value: '',
            label: ''
          },
        })

        if (this.userType === 'hr') {
          this.setHrSelectArea({
            hrSelectArea: [],
          })
          this.setHrSelectCityCode({
            hrSelectCityCode: '',
          })
        }

        this.aonuoChat.logout()
        share.loginOut()

        this.setUserinfo({
          userType: 'employee',
        })

        this.$router.push('/login')
      }

    },

  }
}
</script>

<style lang="less" scoped>
/* 页面头部组件样式 */
.pageHeader_warp {
  height: 61px;
  width: 100%;

  /* 页面头部组件左侧样式 */

  .header-left {
    height: 61px;
    width: 100%;
    display: flex;
    align-items: center;

    .header-left-item {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 20px;
      cursor: pointer;

      .header-left-item-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .header-left-item-title {
        color: #8a8a8a;
        font-size: 12px;

        .el-cascader {
          width: 150px;
        }

      }
    }
  }

  .header-center {
    width: 100%;
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: center;

    .center-item {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 16px;
      margin: 0 20px;
      color: #373737;
      font-weight: bold;
      cursor: pointer;
      position: relative;

      .point {
        width: 8px;
        height: 8px;
        position: absolute;
        right: -10px;
        top: 20px;
        border-radius: 50%;
        border: 2px solid #ffffff;
        background-color: #e20000;
      }
    }

    .center-item-checked {
      color: #e20000;
    }

  }

  /* 页面头部组件右侧样式 */

  .header-right {
    height: 61px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    /* 右侧项 */

    .header-right-item {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 40px;
      cursor: pointer;

      /* 右侧项中的图标样式 */

      .header-right-item-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      /* 右侧项中的文本样式 */

      .header-right-item-title {
        color: #8a8a8a;
        font-size: 12px;
        cursor: pointer;
      }

      /* 右侧项中的登录按钮样式 */

      .header-right-item-login {
        width: 50px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 10px;
        border: 1px solid #8a8a8a;
        cursor: pointer;
      }

      /* 右侧项中的登录后按钮样式 */

      .header-right-item-exit {
        cursor: pointer;
        display: flex;
        align-items: center;

        & /deep/ .el-avatar {
          margin-right: 5px;
          background-color: #ffffff;
        }

      }

    }
  }

  /* 页面头部组件右侧客服电话样式 */

  .phone-right {
    height: 61px;
    width: 100%;
    color: #d61a16;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    padding-right: 25px;
    box-sizing: border-box;

    img {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
  }

}
</style>
<style lang="less">
/* 选择区域组件样式 */
.login-page-header-left-choose-area {
  /* 选择区域组件展开时的高度最大为400px */
  .el-cascader-menu {
    max-height: 400px !important;
  }
}
</style>
