<template>
  <div class="versionNumber_warp">
    {{Version_Number}}
  </div>
</template>

<script>
import {Version_Number} from '@/config/config'
export default {
  name: "versionNumber",
  data() {
    return {
      Version_Number: Version_Number
    }
  }
}
</script>

<style lang="less" scoped>
.versionNumber_warp {
  
}
</style>